import React from 'react';

import Man from '../../../../icons/image/Man';
import Woman from '../../../../icons/image/Woman';
import { TYPES, useShowModal } from '../../../../actions/modal';
import { Circle } from '../../../../icons';
import { seatStyle } from '../useStyles';

const NormalColor = '#01579B';
const UnFilterColor = '#AEB8BF';
const ssrCicleColor = '#FFA600';

const getSeatIcon = (seat) => {
    if (!seat.passenger) {
        return '';
    }

    return seat.passenger.gender ? (
        <Man
            width="46"
            height="46"
            color={seat.passenger.isFiltering ? NormalColor : UnFilterColor}
            style={{
                position: 'absolute',
                width: '75px',
                height: '75px',
                top: '50%',
                left: '50%',
                marginTop: '-18px',
                marginLeft: '-23px',
            }}
        />
    ) : (
        <Woman
            width="46"
            height="46"
            color={seat.passenger.isFiltering ? NormalColor : UnFilterColor}
            style={{
                position: 'absolute',
                width: '75px',
                height: '75px',
                top: '50%',
                left: '50%',
                marginTop: '-18px',
                marginLeft: '-23px',
            }}
        />
    );
};

export const Seat = ({ seat, isAisleExist = false, isMobile }) => {
    const showModal = useShowModal();
    const handleClick = React.useCallback(() => {
        showModal(TYPES.PASSENGER, { data: seat });
    }, [seat]);
    const isSsr = seat.passenger && seat.passenger.ssrsList;

    return (
        <article key={seat.id} className="passenger-grid__seat" onClick={handleClick} {...(isMobile && { style: seatStyle })}>
            <div className="passenger-grid__icon">
                {isSsr ? (
                    <div className="passenger-grid__ssr">
                        <Circle width="15" height="15" color={ssrCicleColor} />
                    </div>
                ) : (
                    ''
                )}
                {getSeatIcon(seat)}
            </div>
            <div className="passenger-grid__seat-name">
                <span>{`${!isAisleExist ? seat.row : ''}${seat.name}`}</span>
            </div>
        </article>
    );
};
