import React from 'react';
import { get } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';

import { Panel } from '../../Panel/Panel';
import { CustomField } from '../../CustomField/CustomField';
import MaterialTextField from '../../MaterialTextField/MaterialTextField';
import { checkRequired } from '../tools';
import { useStyles } from './useStyles';
import { useFieldsDeclaration } from '../../../selectors/declaration';
import { useSingleConvert } from '../../hooks/useMeasureConverter';

const fields = [
    { id: 'expenseAfterLanding', label: 'Taxi IN', order: 10 },
    { id: 'expenseLandLanding', label: 'Ground', order: 11 },
    { id: 'remainLanding', label: 'Remain', order: 12 },
    { id: 'refuelingLanding', label: 'Refueling', order: 13 },
];

function FuelLandingPanel({ onEditField, onEditCommentField }) {
    const classes = useStyles();
    const declaration = useFieldsDeclaration();
    const convert = useSingleConvert();
    const { fuelComment, ...rest } = useSelector((state) => {
        const currentLeg = get(state, 'screen.currentLeg');
        const currentData = get(state, `sendedData[${currentLeg}]`, {});
        const landing = get(currentData, 'landing', {});

        return {
            expenseAfterLanding: landing.expenseAfterLanding,
            expenseLandLanding: landing.expenseLandLanding,
            remainLanding: landing.remainLanding,
            refuelingLanding: landing.refuelingLanding,
            fuelComment: landing.fuelComment,
            declaration,
        };
    }, shallowEqual);

    const fieldList = React.useMemo(
        () =>
            (fields &&
                fields.sort((a, b) => {
                    const orderPos1 = get(declaration, `landing[${a.id}].order`, a.order);
                    const orderPos2 = get(declaration, `landing[${b.id}].order`, b.order);

                    return orderPos1 >= orderPos2 ? 1 : -1;
                })) ||
            [],
        [fields, declaration]
    );

    const handleChangeComment = React.useCallback((evt) => onEditCommentField('fuelComment', evt.currentTarget.value), []);
    const fieldsValidator = React.useMemo(() => ({ type: 'decimal', min: 0, max: convert(296000), precision: [7, 1] }), [convert]);

    return (
        <Panel title="Fuel remain info" className={classes.wrapper}>
            <div className={classes.wrapper}>
                {fieldList.map(({ id, label }) => {
                    const defaultValue = rest[id];

                    return (
                        <CustomField
                            key={`${defaultValue}:${id}`}
                            label={label}
                            name={id}
                            onEditField={onEditField}
                            required={checkRequired(declaration, id)}
                            defaultValue={defaultValue}
                            validator={fieldsValidator}
                            className={classes.field}
                            type="number"
                        />
                    );
                })}
                <MaterialTextField
                    label="Additional fuel info"
                    placeholder="Additional fuel info"
                    name="fuelComment"
                    onBlur={handleChangeComment}
                    key="fuelComment"
                    fullWidth={true}
                    defaultValue={fuelComment}
                    multiline={true}
                    required={checkRequired(declaration, 'fuelComment')}
                    style={{
                        order: get(declaration, 'takeOff["comment"]', 14),
                    }}
                    minRows={1}
                    maxRows={3}
                    className={classes.comment}
                />
            </div>
        </Panel>
    );
}

const FuelLandingPanelMemoized = React.memo(FuelLandingPanel);

export { FuelLandingPanelMemoized as FuelLandingPanel };
