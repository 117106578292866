export function checkRequired(declaration, fieldName) {
    const takeOff = declaration.takeOff || {};

    return takeOff[fieldName] && takeOff[fieldName].required;
}

export const getDefaultValue = (name, props) => {
    const {
        takeOffWeightMax = 0,
        landingWeightMax = 0,
        tripFuel = 0,
        zeroFuelWeightMax = 0,
        totalOnBoard = 0,
        expenseLandTakeoff = 0,
        expenseBeforeTakeoff = 0,
        payLoadReal = 0,
        maxLoading = 0,
    } = props;

    const tof = totalOnBoard - expenseLandTakeoff - expenseBeforeTakeoff;

    switch (name) {
        case 'takeOffFuel': {
            const res = +totalOnBoard - +expenseLandTakeoff - +expenseBeforeTakeoff;

            return res < 0 ? 0 : res;
        }
        case 'allowedTakeOffWeight':
            return Math.min(takeOffWeightMax, landingWeightMax + tripFuel, zeroFuelWeightMax + tof);
        case 'underload':
            return +maxLoading - +payLoadReal;
    }

    return props[name];
};
