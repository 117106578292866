import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Aviabit } from '../../icons';
import { LoginForm } from './LoginForm/LoginForm';
import version from '../../service/version';
import { ProgressIndicatorOverlay } from '../Progress/ProgressIndicatorOverlay/ProgressIndicatorOverlay';
import { MessageDialog } from '../MessageDialog/MessageDialog';
import { AppSettings } from './AppSettings/AppSettings';
import { Typography } from '../material-ui/Typography';
import { login, setSettings, SHOW_ERROR } from '../../actions/login';
import { showModal, TYPES } from '../../actions/modal';
import { useStyles } from './useStyles';

import './LoginPage.scss';
import { PrevStepButton } from './PrevStepButton/PrevStepButton';
import { LoginStep, useStep } from './hooks/useStep';

export const LoginPage = memo(() => {
    const { error = '', backendUrl, ssl, backupInterval } = useSelector((state) => state.login || {});
    const dispatch = useDispatch();
    const classes = useStyles();
    const { step, showStep } = useStep();

    const handleLogin = useCallback((username, password, confirmationCode, onError) => {
        dispatch({
            type: SHOW_ERROR,
            error: '',
        });
        dispatch(login({ login: username, password, confirmationCode, onError }));
    }, []);

    const handleChangeSettings = useCallback((backendUrl, ssl, backupInterval) => {
        dispatch(setSettings(backendUrl, ssl, backupInterval));
    }, []);

    const handleShowRestoreModal = useCallback((props) => {
        dispatch(showModal(TYPES.RESTORE, props));
    }, []);

    return (
        <div className="login-page">
            <ProgressIndicatorOverlay />
            <AppSettings
                onChangeSettings={handleChangeSettings}
                onRestore={handleShowRestoreModal}
                backendUrl={backendUrl}
                ssl={ssl}
                backupInterval={backupInterval}
            />
            {step !== LoginStep.LOGIN && <PrevStepButton onClick={showStep} />}
            <div className={classes.inner}>
                <div className={classes.form} style={{ background: 'rgba(26, 56, 87, 0.3)' }}>
                    <div className={classes.companyInfo} style={{ background: 'rgba(40, 80, 120, 1)' }}>
                        <Aviabit width={120} height={120} />
                        <Typography className={classes.title} variant="h1">
                            AviaBit EFB
                        </Typography>
                    </div>
                    <LoginForm error={error} onLogin={handleLogin} step={step} showStep={showStep} theme="DARK" />
                </div>
            </div>
            <footer className={classes.footer}>
                <Typography className={classes.copyright}>
                    <a
                        href="http://www.aviabit.ru/"
                        title='Сайт компании "Авиабит"'
                        className={classes.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        &copy;
                        {`${version.copyright}`}
                    </a>
                </Typography>
                <Typography className={classes.version}>{version.toString()}</Typography>
            </footer>
            <MessageDialog />
        </div>
    );
});
