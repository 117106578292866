import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import { Box } from '../../material-ui/Box';
import { Typography } from '../../material-ui/Typography';
import { useStyles } from './useStyles';
import { LinearProgress } from '../../material-ui/LinearProgress';

const normalise = (value, min, max) => ((value - min) * 100) / (max - min);

const ProgressIndicatorLinear = () => {
    const { max = 100, value = 50, min = 0, visibility = true } = useSelector((state) => get(state, 'linearLoading', {}));
    const currentValue = Math.round(normalise(value >= max ? max : value, min, max));
    const classes = useStyles();

    return (
        visibility && (
            <div className={classes.container}>
                <div className={classes.inner}>
                    <Box position="relative" display="flex" alignItems="center" className={classes.box}>
                        <Box width="100%" mr={1}>
                            <LinearProgress variant="determinate" value={currentValue} classes={{ root: classes.progress }} />
                        </Box>
                        <Box minWidth={35}>
                            <Typography variant="caption" component="div" className={classes.text}>{`${currentValue}%`}</Typography>
                        </Box>
                    </Box>
                </div>
            </div>
        )
    );
};

const ProgressIndicatorLinearMemoized = React.memo(ProgressIndicatorLinear);

export { ProgressIndicatorLinearMemoized as ProgressIndicatorLinear };
