import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    actionIcon: {
        top: 1,
        right: theme.spacing(2),
        display: 'flex',
        transform: 'none',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            position: 'relative',
            top: 0,
            right: 0,
            display: 'flex',
            flex: '1 1 100%',
            justifyContent: 'flex-end',
            transform: 'none',
        },
    },
    downloadStatus: {
        fontSize: '14px',
    },
    listItem: {
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
    },
    primary: {
        display: 'block',
        fontWeight: 'bold',
        fontSize: theme.textField.textFontSize,
    },
    secondaryText: {
        display: 'block',
        height: 'auto',
        overflowY: 'auto',
        fontSize: theme.textField.labelFontSize,
        marginTop: '12px',
        color: theme.textField.textColor,
        fontWeight: 'normal',
    },
}));

export { useStyles };
