import { makeStyles } from '@material-ui/styles';
import { darkFullWhite } from '../../service/getMuiThemeStyle';

export const useStyles = (tabWidth) =>
    makeStyles((theme) => ({
        appBar: {
            display: 'flex',
            zIndex: 1510,
        },
        checkListTabs: {
            position: 'fixed',
            top: '64px',
            right: 0,
            left: 0,
        },
        tabLabel: {
            fontSize: '16px',

            ['@media only screen and (min-width: 780px)']: {
                '&.MuiTab-root': {
                    maxWidth: '450px',
                },
            },
        },
        checklist: {
            position: 'fixed',
            top: `calc(64px + ${tabWidth}px)`,
            bottom: 0,
            right: 0,
            left: 0,
            marginTop: '3px',
            zIndex: 1500,

            fontFamily: theme.fontFamily,
            fontSize: '22px !important',

            backgroundColor: theme.paper.backgroundColor,
            color: theme.palette.textColor,

            overflowY: 'scroll',
            '-webkit-overflow-scrolling': 'touch',
            transition: 'all 195ms cubic-bezier(0.4, 0.0, 1, 1)',
            '-webkit-user-select': 'none',
            '-moz-user-select': 'none',
            '-ms-user-select': 'none',
            userSelect: 'none',

            ['@media only screen and (max-device-width: 1024px) and (orientation: portrait)']: {
                fontSize: '18px !important',
            },
        },
        container: {
            padding: 0,
        },
        title: {
            marginLeft: theme.spacing(1),
            marginRight: 'auto',

            fontSize: '20px',
            color: darkFullWhite,
        },
        toolbar: {
            paddingLeft: 4,
            paddingRight: theme.spacing(1),
        },
    }));
