import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    actions: {
        padding: theme.spacing(1, 2),

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1, 0.5),
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        minWidth: 300,

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
            flexDirection: 'column',
            flexWrap: 'nowrap',
            minWidth: 300,
        },
    },
    formControl: {
        flexBasis: '50%',

        [theme.breakpoints.down('xs')]: {
            flexBasis: theme.spacing(9),
        },
    },
    paper: {
        maxWidth: 680,
    },
    title: {
        padding: theme.spacing(2, 3, 1),

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2, 1, 1),
        },
    },
    toggler: {
        flexBasis: '100%',
        justifyContent: 'flex-end',
        margin: theme.spacing(0, 0, 1),

        [theme.breakpoints.down('xs')]: {
            flexBasis: theme.spacing(9),
        },
    },
}));

export { useStyles };
