import { get } from 'lodash';
import { showConfirmDialog } from './messageDialog';
import { setPoints } from './navigation';
import { createImage, removeImage } from './images';
import { getCurrentLeg } from '../selectors/screen';
import { Weight } from '../reducers/systemOfMeasurement';
import { sendOtherData } from './sended-data';

const GET_OPF_LIST = 'GET_OPF_LIST';
const ON_CHANGE_TAKE_OFF_TIME = 'ON_CHANGE_TAKE_OFF_TIME';
const SET_OFP = 'SET_OFP';
const RESIGN_OFP = 'RESIGN_OFP';

function onChangeTime(name, value) {
    return (dispatch, getState) => {
        const { currentUser, currentLeg } = getState().screen;

        return dispatch({
            type: ON_CHANGE_TAKE_OFF_TIME,
            name,
            value,
            legId: currentLeg,
            userId: currentUser.id,
        });
    };
}

function getServicePassengerWeight(crewCount = 0, passengerTemplates = []) {
    const MAX_WEIGHT = 80;
    let weight = 0;

    passengerTemplates.forEach((item) => {
        if (item.weight > weight) {
            weight = item.weight;
        }
    });
    weight = weight === 0 ? MAX_WEIGHT : weight;
    return crewCount * weight;
}

function setOfp(ofpId, signature, signComment) {
    return (dispatch, getState) => {
        const state = getState();
        const { currentTask, currentLeg, currentFlight, currentUser } = state.screen;
        const currentOfpId = get(state, `sendedData[${currentLeg}].ofpId`);
        const currentOfp = state.tasks.list[currentTask].flights[currentFlight].legs[currentLeg].ofps[ofpId];
        const prevOfpSignature = get(state, `sendedData[${currentLeg}].signature`);
        const { navPoints } = currentOfp;
        const userId = currentUser.id;
        const { data, contentType } = signature;
        const imgName = `sign-ofp_${ofpId}`;
        const leg = getCurrentLeg(state) || {};
        const crews = get(leg, 'crew');
        const crewCount = Object.keys(crews || {}).filter((crewId) => crews[crewId].crewType >= 2).length;
        const passengerTemplates = get(state, 'login.user.data.passengersTemplates');
        const extraCrewWeight = getServicePassengerWeight(crewCount, passengerTemplates);
        const options = get(state.routesDeclaration, 'options', {});
        const { dimension, fields } = get(state, 'systemOfMeasurement', { dimension: Weight.KG, fields: [] });

        let msg = {
            title: 'Confirmation dialog',
            message:
                'You have already selected the CFP, and it has not been completed yet. Selecting another CFP will delete all the entered data about the navigation points. Would you still like to select another CFP?',
        };

        if (currentOfpId && currentOfpId !== ofpId) {
            dispatch(showConfirmDialog(msg)).then((isOk) => {
                if (isOk) {
                    dispatch(
                        createImage(imgName, data, contentType, {
                            createImmediately: true,
                            rewrite: true,
                        })
                    ).then((isOk) => {
                        if (isOk) {
                            dispatch(sendOtherData([ofpId]));
                        }
                    });
                    dispatch({
                        type: SET_OFP,
                        ofpId,
                        legId: currentLeg,
                        signature: imgName,
                        userId,
                        currentOfp,
                        signComment,
                        extraCrewWeight,
                        options,
                        dimension,
                        fieldsForConvert: fields,
                    });
                    dispatch(setPoints(currentLeg, navPoints, userId));
                    dispatch(removeImage(prevOfpSignature));
                }
            });
        } else {
            dispatch(removeImage(prevOfpSignature));
            dispatch(createImage(imgName, data, contentType, { createImmediately: true, rewrite: true })).then(() => {
                dispatch(sendOtherData([ofpId]));
            });
            dispatch({
                type: SET_OFP,
                ofpId,
                legId: currentLeg,
                signature: imgName,
                userId,
                currentOfp,
                signComment,
                extraCrewWeight,
                options,
                dimension,
                fieldsForConvert: fields,
            });
            dispatch(setPoints(currentLeg, navPoints, userId));
        }
    };
}

function resignOfp(ofpId, signature, signComment) {
    return (dispatch, getState) => {
        const state = getState();
        const { currentLeg, currentUser } = state.screen;
        const currentOfpId = get(state, `sendedData[${currentLeg}].ofpId`);
        const prevOfpSignature = get(state, `sendedData[${currentLeg}].signature`);
        const weightUnits = get(state, `sendedData[${currentLeg}].weightUnits`, Weight.KG);
        const userId = currentUser.id;

        if (currentOfpId !== ofpId) {
            return dispatch(setOfp(ofpId, signature, signComment));
        }
        const imgName = `sign-ofp_${ofpId}`;
        const { data, contentType } = signature;
        dispatch(createImage(imgName, data, contentType, { createImmediately: true, rewrite: true })).then(() =>
            dispatch(sendOtherData([ofpId]))
        );
        dispatch({
            type: RESIGN_OFP,
            legId: currentLeg,
            ofpId,
            userId,
            signComment,
            weightUnits,
            signature: imgName,
        });
        dispatch(removeImage(prevOfpSignature));
    };
}

export { GET_OPF_LIST, ON_CHANGE_TAKE_OFF_TIME, SET_OFP, RESIGN_OFP, onChangeTime, setOfp, resignOfp };
