import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { get } from 'lodash';
import moment from 'moment';

import { getCurrentFlight, getFlights, getFlightTaskLegIds, getPreviosTaskLegs, getUserLegs, getPreviousPersonnelWork } from './screen';
import { getCheckListWarnings } from './checkList';
import { checkTimesIsCorrect, getTimeFormat } from '../service/NavPointsUtils';
import { getTimeDiff } from '../service/DateTimeUtils';
import { sortByEtd, sortCrew } from '../service/sort';
import { CrewTypes } from '../service/constants';
import { Weight } from '../reducers/systemOfMeasurement';

export const getCurrentSendedLeg = createSelector(
    (state) => get(state, 'screen.currentLeg'),
    (state) => state.sendedData,
    (currentLegId, sendedData) => {
        return get(sendedData, `[${currentLegId}]`, {});
    }
);

export const getCurrentSendedTakeOff = createSelector(getCurrentSendedLeg, (currentLeg) => {
    return get(currentLeg, 'takeOff', {});
});

export const getCurrentSendedLanding = createSelector(getCurrentSendedLeg, (currentLeg) => {
    return get(currentLeg, 'landing', {});
});

export const getNeedToConvert = createSelector(
    (state) => get(state, 'systemOfMeasurement.needToConvert', null),
    (needToConvert) => needToConvert
);

export const getIsOfpChanged = createSelector(
    (state) => get(state, 'systemOfMeasurement.isOfpChanged', false),
    (isOfpChanged) => isOfpChanged
);

export const getAppWeightUnits = createSelector(
    (state) => get(state, 'systemOfMeasurement.dimension', Weight.KG),
    (dimension) => dimension
);

export const getFieldsForConvert = createSelector(
    (state) => get(state, 'systemOfMeasurement.fields', []),
    (fields) => fields
);
export const getSendedDataConvertedTo = createSelector(
    (state) => get(state, 'screen.currentLeg', null),
    (state) => get(state, 'sendedData', {}),
    (legId, sendedData) => (legId && sendedData[legId] ? sendedData[legId].convertedTo : null)
);

export const getCfpWeightUnits = createSelector(
    (state) => get(state, 'screen.ofpId', null),
    (state) => get(state, 'sendedData', {}),
    (state) => get(state, 'screen.currentLeg', null),
    (state) => getCurrentFlight(state),
    (ofpId, sendedData, currentLegId, currentFlight) => {
        const ofpIdFormSendedData = currentLegId && (sendedData[currentLegId] || {}).ofpId;
        const currentOfpId = ofpId || ofpIdFormSendedData;

        return get(currentFlight, `legs[${currentLegId}].ofps[${currentOfpId}].weightUnits`, Weight.KG);
    }
);

export const getSendedLegs = createSelector(
    (state) => get(state, 'sendedData'),
    (data) => {
        const res = {};

        data &&
            Object.keys(data).forEach((key) => {
                const leg = data[key];

                res[key] = {
                    hasChanges: leg.hasChanges || leg.hasTelegramSignChanges || leg.hasOfpSignatureChanges || false,
                };
            });

        return res;
    }
);

export const getCurrentPoints = createSelector(getCurrentSendedLeg, (leg) => {
    return get(leg, 'points', []);
});

export const getCurrentPointIds = createSelector(getCurrentSendedLeg, (leg) => {
    return get(leg, 'points', [])
        .reduce((res, point, pos) => {
            if (!point.routeNumber && !point.isDeleted) {
                res.push(`${pos}|${point.pointNumber}`);
            }

            return res;
        }, [])
        .join(',');
});

function checkFields(fields, leg) {
    let res = [];
    fields &&
        Object.keys(fields).forEach((fieldKey) => {
            const { required = false, title } = fields[fieldKey] || {};

            if (fieldKey !== 'landing' && fieldKey !== 'takeOff') {
                if ((required && leg[fieldKey] === undefined) || leg[fieldKey] === null) {
                    res.push(title || fieldKey);
                }
            } else {
                res = res.concat(checkFields(fields[fieldKey], leg[fieldKey]));
            }
        });

    return res;
}

function checkTimes(fields, leg) {
    const landingField = get(fields, 'landing', {});
    const takeOffField = get(fields, 'takeOff', {});
    const fieldLaTo = { ...landingField, ...takeOffField };
    const { endWorkTime, landingTime, stopEngineTime } = leg['landing'] || {};
    const { startEngineTime, startWorkTime, takeOffTime } = leg['takeOff'] || {};
    const time = { endWorkTime, landingTime, stopEngineTime, startEngineTime, startWorkTime, takeOffTime };
    const {
        isStartWorkAfterStartEngine,
        isStartEngineAfterTakeOff,
        isTakeOffAfterLanding,
        isLandingAfterStopEngine,
        isStopEngineAfterEndWork,
    } = checkTimesIsCorrect(time);

    return {
        startEngineTime: isStartWorkAfterStartEngine ? [fieldLaTo['startWorkTime'].title, fieldLaTo['startEngineTime'].title] : [],
        takeOffTime: isStartEngineAfterTakeOff ? [fieldLaTo['startEngineTime'].title, fieldLaTo['takeOffTime'].title] : [],
        landingTime: isTakeOffAfterLanding ? [fieldLaTo['takeOffTime'].title, fieldLaTo['landingTime'].title] : [],
        stopEngineTime: isLandingAfterStopEngine ? [fieldLaTo['landingTime'].title, fieldLaTo['stopEngineTime'].title] : [],
        endWorkTime: isStopEngineAfterEndWork ? [fieldLaTo['stopEngineTime'].title, fieldLaTo['endWorkTime'].title] : [],
    };
}

function checkInformationFormFields(declaration, partName, formData) {
    let res = [];
    const fields = declaration && declaration[`${partName}Declaration`];
    const form = get(formData, `[${partName}].informationForm`, {});

    fields &&
        fields.forEach((field) => {
            const { required = false, id, title } = field || {};

            if (required && (form[id] === undefined || form[id] === null || form[id] === '')) {
                res.push(title);
            }
        });

    return res;
}

function checkSpecialField(declaration, formData) {
    let res = [];
    const isPilotFlightRequired = get(declaration, 'options.isPilotFlightRequired');
    const isCrewInfoRequired = get(declaration, 'options.isCrewInfoRequired');
    if (isPilotFlightRequired) {
        const pilotFlightTakeOff = get(formData, 'takeOff.informationForm.pilotFlight');
        const pilotFlightLanding = get(formData, 'landing.informationForm.pilotFlight');
        if (!pilotFlightTakeOff) {
            res.push('Pilot flight take-off');
        }
        if (!pilotFlightLanding) {
            res.push('Pilot flight landing');
        }
    }
    if (isCrewInfoRequired && !(formData && formData.bufferCrew && Object.keys(formData.bufferCrew).length > 0)) {
        res.push('Crew info on summary page');
    }

    return res;
}

export const getRequiredFieldWarnings = createSelector(
    (state) => get(state, 'sendedData'),
    (state) => get(state, 'routesDeclaration', {}),
    (data, declaration) => {
        const res = {};

        data &&
            Object.keys(data).forEach((legId) => {
                const leg = data[legId] || {};

                let fieldWarning = checkFields(declaration.fieldsDeclaration, leg);
                fieldWarning = fieldWarning.concat(checkInformationFormFields(declaration, 'takeOff', leg));
                fieldWarning = fieldWarning.concat(checkInformationFormFields(declaration, 'landing', leg));
                fieldWarning = fieldWarning.concat(checkSpecialField(declaration, leg));
                if (fieldWarning.length > 0) {
                    res[legId] = fieldWarning;
                }
            });

        return res;
    }
);

export const getTimesWarnings = createSelector(
    (state) => get(state, 'sendedData'),
    (state) => get(state, 'routesDeclaration', {}),
    (data, declaration) => {
        const res = {};

        data &&
            Object.keys(data).forEach((legId) => {
                const leg = data[legId] || {};
                res[legId] = checkTimes(declaration.fieldsDeclaration, leg);
            });

        return res;
    }
);

export function getLegWorkInfo(data, legId, prevLegId, isPrevLegExist) {
    const currentLeg = data[legId];
    const {
        stopEngineTime,
        landingTime,
        endWorkTime,
        timeWorkParking = 0,
        timeWorkDelay,
        landings,
        landingsOnDevices,
        distance,
        timeOnDevices,
        timeFlightNight,
        timeBiologicalNight,
    } = get(currentLeg, 'landing', {});
    const { startEngineTime, takeOffTime, startWorkTime } = get(currentLeg, 'takeOff', {});
    const prevEndWorkTime = (isPrevLegExist !== undefined || !prevLegId) && get(data, `[${prevLegId}].landing.endWorkTime`);

    return {
        blockTime: getTimeDiff(startEngineTime, stopEngineTime),
        flightTime: getTimeDiff(takeOffTime, landingTime),
        workTime: getTimeDiff(startWorkTime, endWorkTime),
        timeWorkAfter: getTimeDiff(stopEngineTime, endWorkTime),
        timeWorkBefore: getTimeDiff(startWorkTime, startEngineTime) - timeWorkParking,
        timeEngineWorkAfter: getTimeDiff(landingTime, stopEngineTime),
        timeEngineWorkBefore: getTimeDiff(startEngineTime, takeOffTime),
        startWorkTime,
        startEngineTime,
        takeOffTime,
        landingTime,
        stopEngineTime,
        endWorkTime,
        timeWorkParking,
        timeWorkDelay,
        timeWorkRest: getTimeDiff(prevEndWorkTime, startWorkTime),
        landings,
        landingsOnDevices,
        distance,
        timeOnDevices,
        timeFlightNight,
        timeBiologicalNight,
    };
}

export const getPreviewWorkTime = createSelector(
    (state) => state.screen,
    (state) => state.sendedData,
    (screen, sendedData) => {
        const legInfo = getLegWorkInfo(sendedData, screen.currentLeg);

        return {
            blockTime: getTimeFormat(legInfo.blockTime),
            flightTime: getTimeFormat(legInfo.flightTime),
            workTime: getTimeFormat(legInfo.workTime),
            timeWorkAfter: getTimeFormat(legInfo.timeWorkAfter),
            timeWorkBefore: getTimeFormat(legInfo.timeWorkBefore),
            timeEngineWorkAfter: getTimeFormat(legInfo.timeEngineWorkAfter),
            timeEngineWorkBefore: getTimeFormat(legInfo.timeEngineWorkBefore),
        };
    }
);

export const getSubtotalWorkTime = createSelector(
    (state) => state.sendedData,
    getPreviosTaskLegs,
    getUserLegs,
    getFlightTaskLegIds,
    (sendedData, legs, userLegs, legIds) => {
        const result = legs.reduce((res, leg) => {
            const legNumber = legIds.indexOf(leg.id);
            const prevLegId = legNumber > 0 && legIds[legNumber - 1];
            const legInfo = getLegWorkInfo(sendedData, leg.id, prevLegId, userLegs[prevLegId]);
            // Есди сотрудник не работает на участке, то не учитываем его в промежуточной сумме
            if (userLegs[leg.id] === undefined) {
                return res;
            }

            Object.keys(legInfo).forEach((field) => {
                res[field] = (res[field] || 0) + (legInfo[field] || 0);
            });

            return res;
        }, {});

        return {
            blockTime: getTimeFormat(result.blockTime),
            flightTime: getTimeFormat(result.flightTime),
            workTime: getTimeFormat(result.workTime),
            timeWorkAfter: getTimeFormat(result.timeWorkAfter),
            timeWorkBefore: getTimeFormat(result.timeWorkBefore),
            timeEngineWorkAfter: getTimeFormat(result.timeEngineWorkAfter),
            timeEngineWorkBefore: getTimeFormat(result.timeEngineWorkBefore),
        };
    }
);

export const getSendedDataPreview = createSelector(
    (state) => get(state, 'sendedData'),
    getFlights,
    getUserLegs,
    getFlightTaskLegIds,
    (sendedData, flights, userLegs, legIds) => {
        const legs =
            flights &&
            Object.keys(flights).reduce((res, flightId) => {
                const flight = flights[flightId] || {};
                const aircraft = flight && flight.pln;
                const legs = flight.legs || {};
                Object.keys(legs).forEach((legId) => {
                    const leg = legs[legId] || {};

                    if (userLegs[legId] === CrewTypes.PCT_CABINSTAFF) {
                        // ONLY FOR PILOT
                        const airportTakeOff = get(leg, 'airportTakeOff', {});
                        const airportLanding = get(leg, 'airportLanding', {});
                        const { bufferCrew, hasChanges } = sendedData[legId] || {};
                        const pos = aircraft.indexOf('#');
                        const pln = aircraft && pos !== -1 && aircraft.slice(pos + 1);
                        const legNumber = legIds.indexOf(leg.id);
                        const prevLegId = legNumber > 0 && legIds[legNumber - 1];
                        const legInfo = getLegWorkInfo(sendedData, leg.id, prevLegId, userLegs[prevLegId]) || {};

                        res[legId] = {
                            ...legInfo,
                            id: legId && +legId,
                            flightNum: flight.num,
                            pln: pln,
                            apTo: airportTakeOff.code,
                            apLa: airportLanding.code,
                            etd: leg.etd,
                            crew: leg.crew,
                            hasChanges,
                            bufferCrew: { ...bufferCrew },
                        };
                    }
                });
                return res;
            }, {});
        return (
            legs &&
            Object.keys(legs)
                .map((key) => legs[key])
                .sort((a, b) => (moment.utc(a.etd) > moment.utc(b.etd) ? 1 : -1))
        );
    }
);

export const getSendedDataPreviewTotal = createSelector(getSendedDataPreview, (legs) => {
    return legs
        ? legs.reduce((res, leg) => {
              const fields = [
                  'landings',
                  'landingsOnDevices',
                  'distance',
                  'timeFlightNight',
                  'timeBiologicalNight',
                  'flightTime',
                  'blockTime',
                  'timeWorkParking',
                  'timeWorkDelay',
                  'timeWorkRest',
                  'workTime',
              ];
              fields.forEach((field) => {
                  res[field] = (res[field] || 0) + (leg[field] || 0);
              });
              return res;
          }, {})
        : {};
});

function getPrevTaskEndWorkTime(prevLegBufferCrew, previousPersonnelWork, persId) {
    return (
        !prevLegBufferCrew[persId] &&
        previousPersonnelWork &&
        previousPersonnelWork[persId] &&
        previousPersonnelWork[persId].tasks.length > 0 &&
        previousPersonnelWork[persId].tasks[previousPersonnelWork[persId].tasks.length - 1].dateWorkEnd
    );
}

export function getBufferCrewValues(bufCrew, prevEndWorkTime) {
    const { flight = 0, engineWorkAfter = 0, engineWorBefore = 0, workAfter = 0, workBegin, timeTakeoff, crewType, work } = bufCrew;

    if (crewType === CrewTypes.PCT_PAXSTAFF) {
        const workEnd = moment.utc(workBegin).add(work || 0, 'seconds');

        return {
            workBegin,
            workEnd,
            timeTakeoff,
            block: 0,
            flight: 0,
        };
    }

    const block = bufCrew && flight + engineWorkAfter + engineWorBefore;
    const engineStart = timeTakeoff && moment.utc(timeTakeoff).add(-1 * engineWorBefore, 'seconds');
    const engineStop = engineStart && moment.utc(engineStart).add(block || 0, 'seconds');
    const workEnd = engineStop && moment.utc(engineStop).add(workAfter || 0, 'seconds');
    const timeWorkRest = prevEndWorkTime && getTimeDiff(prevEndWorkTime, workBegin);

    return {
        workBegin,
        engineStart,
        engineStop,
        workEnd,
        block,
        rest: timeWorkRest,
    };
}

export const getBufferCrew = createSelector(
    getSendedDataPreview,
    (state) => get(state, 'crew'),
    getUserLegs,
    getFlightTaskLegIds,
    (state) => get(state, 'routesDeclaration.summary.showDHC', false),
    getPreviousPersonnelWork,
    (legs, crewList, userLegs, legIds, showDHC, previousPersonnelWork) => {
        const fields = [
            'landings',
            'landingsOnDevices',
            'distance',
            'night',
            'biologicalNight',
            'flight',
            'block',
            'workAfter',
            'workBefore',
            'parking',
            'delay',
            'rest',
            'dist',
            'independent',
            'timeOnDevices',
        ];
        const crew =
            legs &&
            legs.reduce((res, leg) => {
                const { bufferCrew, id } = leg || {};
                const legNumber = legIds.indexOf(id);
                const prevLegId = legNumber > 0 && legIds[legNumber - 1];
                const prevLegData = legs && legs.find((item) => item.id === prevLegId);
                const prevLegBufferCrew = get(prevLegData, 'bufferCrew', {});

                if (userLegs[leg.id] === CrewTypes.PCT_CABINSTAFF) {
                    // ONLY FOR PILOT
                    bufferCrew &&
                        Object.keys(bufferCrew).forEach((persId) => {
                            const prevTaskEndWorkTime = getPrevTaskEndWorkTime(prevLegBufferCrew, previousPersonnelWork, persId);
                            const { workEnd: prevEndWorkTime } = getBufferCrewValues(prevLegBufferCrew[persId] || {});

                            const bufCrew = bufferCrew[persId];
                            const crewId = Object.keys(leg.crew || {}).find((key) => +leg.crew[key].id === +persId);
                            const legCrew = leg.crew[crewId];
                            const crew = crewList[persId];
                            const { workBegin, engineStart, engineStop, workEnd, block, rest } = getBufferCrewValues(
                                bufCrew,
                                prevLegBufferCrew[persId] ? prevEndWorkTime : prevTaskEndWorkTime
                            );

                            // если есть ограничение на отображение Dead head crew, то не выводим по ним информацию. При этом разносим ее в базу.
                            if (crew && legCrew && bufCrew && (showDHC || bufCrew.crewType !== CrewTypes.PCT_PAXSTAFF)) {
                                if (!res[persId]) {
                                    res[persId] = { ...crew, legs: {}, total: { chairs: {} }, previousTasks: [] };
                                }
                                res[persId].legs[leg.id] = {
                                    legId: leg.id,
                                    flightNum: leg.flightNum,
                                    apTo: leg.apTo,
                                    apLa: leg.apLa,
                                    etd: leg.etd,
                                    ...bufCrew,
                                    rest,
                                    engineStart,
                                    engineStop,
                                    workEnd,
                                    block,
                                };
                                res[persId].total['block'] = (res[persId].total['block'] || 0) + block;
                                res[persId].total['work'] = (res[persId].total['work'] || 0) + (getTimeDiff(workBegin, workEnd) || 0);
                                const crewType = legCrew.crewType;
                                if (res[persId].total.crewType > crewType || res[persId].total.crewType === undefined) {
                                    res[persId].total = { ...res[persId].total, ...legCrew };
                                }

                                if (!res[persId].total.chairs[crewType]) {
                                    res[persId].total.chairs[crewType] = legCrew.chairEng;
                                }
                                fields.forEach((field) => {
                                    res[persId].total[field] = (res[persId].total[field] || 0) + (bufCrew[field] || 0);
                                });
                            }
                        });
                }
                return res;
            }, {});

        if (crew && previousPersonnelWork) {
            Object.keys(previousPersonnelWork).forEach((persId) => {
                if (crew[persId]) {
                    crew[persId].previousTasks = previousPersonnelWork[persId].tasks;
                    crew[persId].total['previousWork'] = previousPersonnelWork[persId].timeWork;
                }
            });
        }

        return (
            crew &&
            Object.keys(crew)
                .map((key) => {
                    const item = crew[key];
                    item.legs =
                        item.legs &&
                        Object.keys(item.legs)
                            .map((legKey) => item.legs[legKey])
                            .sort((a, b) => (moment.utc(a.workBegin) > moment.utc(b.workBegin) ? 1 : -1));
                    return item;
                })
                .sort(sortCrew)
        );
    }
);

export const getFlightTaskComments = createSelector(
    getFlights,
    (state) => state.sendedData,
    getUserLegs,
    (flights = {}, sendedData, userLegs) => {
        const flightTaskComments = Object.keys(flights).reduce((res, flightId) => {
            const flight = flights[flightId] || {};
            flight.legs &&
                Object.keys(flight.legs).forEach((legId) => {
                    if (userLegs[legId] === CrewTypes.PCT_CABINSTAFF) {
                        // ONLY FOR PILOT
                        const { airportTakeOff = {}, airportLanding = {}, airportNumber, etd } = flight.legs[legId] || {};
                        const comment = get(sendedData[legId], 'landing.bufferComment');
                        if (comment) {
                            res.push({
                                flight: `${flight.num} ${airportTakeOff.code}-${airportLanding.code}`,
                                comment,
                                airportNumber,
                                etd,
                            });
                        }
                    }
                });

            return res;
        }, []);

        return flightTaskComments.sort(sortByEtd);
    }
);

export const getCurrentDutyInfo = createSelector(
    getFlights,
    (state) => state.sendedData,
    getUserLegs,
    (flights = {}, sendedData, userLegs) => {
        const dutyInfo = Object.keys(flights).reduce(
            (res, flightId) => {
                const flight = flights[flightId] || {};
                flight.legs &&
                    Object.keys(flight.legs).forEach((legId) => {
                        if (userLegs[legId] !== undefined) {
                            let startWorkTime = get(sendedData[legId], 'takeOff.startWorkTime');
                            let endWorkTime = get(sendedData[legId], 'landing.endWorkTime');
                            startWorkTime = startWorkTime && moment.utc(startWorkTime);
                            endWorkTime = endWorkTime && moment.utc(endWorkTime);

                            if (startWorkTime && endWorkTime && startWorkTime <= endWorkTime) {
                                res.dutyTime += endWorkTime.diff(startWorkTime, 'minutes');
                            } else if (startWorkTime && !endWorkTime && (!res.startWorkTime || startWorkTime > res.startWorkTime)) {
                                res.startWorkTime = startWorkTime;
                            }
                        }
                    });

                return res;
            },
            { dutyTime: 0, startWorkTime: null }
        );

        return dutyInfo;
    }
);

export const getDataForSending = createSelector(
    (state) => get(state, 'sendedData'),
    getFlights,
    getRequiredFieldWarnings,
    getTimesWarnings,
    getCheckListWarnings,
    (sendedData, flights, warnings, timeWarnings, checkListWarnings = {}) => {
        const legs =
            flights &&
            Object.keys(flights).reduce((res, flightId) => {
                const flight = flights[flightId];
                const legs = flight.legs || {};

                Object.keys(legs).forEach((legId) => {
                    const leg = legs[legId] || {};
                    const airportTakeOff = get(leg, 'airportTakeOff', {});
                    const airportLanding = get(leg, 'airportLanding', {});
                    const routeIata =
                        (airportTakeOff.codeIata &&
                            airportLanding.codeIata &&
                            `(${airportTakeOff.codeIata || ''}-${airportLanding.codeIata || ''})`) ||
                        '';
                    const route = `${airportTakeOff.code || ''}-${airportLanding.code || ''}` || '';

                    res[legId] = {
                        id: legId,
                        flightNum: flight.num,
                        pln: flight.pln,
                        route: `${route} ${routeIata}`,
                        etd: leg.etd,
                        hasChanges: (sendedData[legId] && sendedData[legId].hasChanges && !!sendedData[legId].ofpId) || false,
                        warnings: warnings[legId] || [],
                        timeWarnings: timeWarnings[legId] || [],
                        isCheckListNotCompleted: !!checkListWarnings[legId],
                    };
                });

                return res;
            }, {});

        return (
            legs &&
            Object.keys(legs)
                .map((key) => legs[key])
                .sort((a, b) => (moment.utc(a.etd) > moment.utc(b.etd) ? 1 : -1))
        );
    }
);

export const useDataForSending = () => useSelector(getDataForSending);

export const getWeightUnits = createSelector(getCurrentSendedLeg, (currentLeg) => {
    return currentLeg ? currentLeg.weightUnits : Weight.KG;
});
