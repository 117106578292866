import grey from '@material-ui/core/colors/grey';
import common from '@material-ui/core/colors/common';
import pink from '@material-ui/core/colors/pink';
import blue from '@material-ui/core/colors/blue';
import yellow from '@material-ui/core/colors/yellow';
import orange from '@material-ui/core/colors/orange';
import deepOrange from '@material-ui/core/colors/deepOrange';
import cyan from '@material-ui/core/colors/cyan';
import red from '@material-ui/core/colors/red';

const grey800 = grey['800'];
const grey700 = grey['700'];
const black = common.black;
const grey100 = grey['100'];
const fullWhite = common.white;
const pinkA200 = pink.A200;
const grey300 = grey['300'];
const pinkA400 = pink.A400;
const blue500 = blue['500'];
const blue300 = blue['300'];
const grey200 = grey['200'];
const grey900 = grey['900'];
const blue100 = blue['100'];
const yellow100 = yellow['100'];
const blue800 = blue['800'];
const orange100 = orange['100'];
const deepOrange100 = deepOrange['100'];
const grey400 = grey['400'];
const cyan500 = cyan['500'];
const cyan700 = cyan['700'];
const blue700 = blue['700'];
const red400 = red['400'];
const blue900 = blue['900'];

export const darkGrey100 = 'rgb(220, 220, 220)';
export const darkFullWhite = 'rgb(234, 234, 234)';

const muiThemeLightStyle = {
    appBar: {
        color: cyan500,
        textColor: fullWhite,
        colorLight: '#c0e0ff',
    },
    bottomNavigation: {
        unselectedFontSize: 16,
        unselectedColor: black,
        selectedFontSize: 16,
        height: 60,
    },
    chip: {
        fontSize: 18,
    },
    crew: {
        cabin: 'rgba(0, 188, 212, 0.3)',
        salon: 'rgba(230, 74, 25, 0.2)',
    },
    icon: {
        color: grey800,
        active: cyan500,
        stroke: grey700,
        contrastColor: blue500,
        attention: '#fff200',
        warning: '#ff0000',
        staticWhite: darkFullWhite,
    },
    importantComment: {
        background: '#f8dce6',
    },
    flatButton: {
        primaryTextColor: cyan500,
        disabledTextColor: grey700,
        secondaryTextColor: black,
        invertColor: fullWhite,
        fontSize: '18px',
        warningColor: 'rgba(255, 135, 135, 0.3)',
    },
    library: {
        requiredColor: 'rgba(200, 0, 0, 0.3)',
        changedColor: 'rgba(255, 255, 0, 0.3)',
    },
    listItem: {
        hoverColor: grey300,
        disabledColor: grey200,
        removedColor: deepOrange100,
    },
    messageDialog: {
        errorColor: 'rgba(212, 86, 86, 0.4)',
        successColor: 'rgba(86, 212, 86, 0.4)',
    },
    notice: {
        airportGroup: {
            departure: 'green',
            altDeparture: 'orange',
            enroute: '#504ef4',
            landing: 'green',
            altLanding: 'orange',
            fir: 'red',
            additional: 'violet',
        },
        airportBackgroundColor: 'rgba(66, 66, 66, 0.7)',
        numberColor: pinkA200,
        accentColor: pinkA200,
        textColor: fullWhite,
        ifrColor: 'rgba(230, 35, 35, 1)',
        vfrColor: 'rgba(20, 170, 35, 1)',
        scopeColor: 'rgba(70, 135, 240, 1)',
        assignmentColor: 'rgba(66, 66, 66, 0.7)',
        favoriteColor: cyan500,
        newColor: '#029d14',
    },
    ofp: {
        selected: '#ade8af',
    },
    palette: {
        type: 'light',
        primary1Color: cyan500,
        primary2Color: cyan700,
        primary3Color: blue700,
        accent1Color: pinkA200,
        accent2Color: grey200,
        accent3Color: grey700,
        textColor: black,
        secondaryTextColor: grey800,
        canvasColor: grey100,
        disabledColor: grey700,
        warningColor: red400,
        borderColor: grey300,
        error: {
            light: '#e57373',
            main: '#f44336',
            dark: '#d32f2f',
        },
        primary: {
            main: '#1976d2',
            contrastText: darkFullWhite,
        },
        secondary: {
            main: '#e53935',
            contrastText: darkFullWhite,
        },
        text: {
            secondary: 'rgba(0,0,0,0.7)',
        },
    },
    paper: {
        backgroundColor: grey100,
    },
    point: {
        warning: orange100,
        direct: yellow100,
        current: 'rgba(0, 188, 212, 0.25)',
        edit: blue100,
        caution: deepOrange100,
        labelWarning: '#ff0000',
        labelGreen: '#00820f',
        editLabelWarning: '#9e0000',
        editLabelGreen: '#005a0f',
        directed: 'lightgrey',
        abeam: '#4CAF50',
        calculation: 'grey',
    },
    raisedButton: {
        fontSize: '18px',
    },
    reports: {
        editColor: blue100,
    },
    stepper: {
        fontSize: '18px',
    },
    signaturePad: {
        color: 'black',
        backgroundColor: 'transparent',
    },
    subheader: {
        color: grey700,
    },
    table: {
        secondaryColor: grey200,
    },
    textField: {
        floatingLabelColor: blue700,
        textColor: black,
        disabledTextColor: blue700,
        highLightTextColor: blue900,
        textFontSize: '18px',
        labelFontSize: '16px',
    },
    toggle: {
        thumbOnColor: '#3ad4ea',
        thumbOffColor: grey400,
        trackOnColor: '#69daea',
        trackOffColor: fullWhite,
    },
    toolbar: {
        iconColor: grey700,
    },
};

const muiThemeDarkStyle = {
    appBar: {
        color: '#002544',
        textColor: darkGrey100,
        colorLight: '#0f4982',
    },
    badge: {
        secondaryTextColor: darkGrey100,
    },
    bottomNavigation: {
        unselectedFontSize: 16,
        unselectedColor: darkGrey100,
        selectedFontSize: 16,
        height: 60,
    },
    chip: {
        fontSize: 18,
    },
    crew: {
        cabin: 'rgba(33, 150, 243, 0.8)',
        salon: 'rgba(230, 74, 25, 0.6)',
    },
    datePicker: {
        textColor: darkGrey100,
        headerColor: blue500,
    },
    icon: {
        color: grey200,
        active: blue500,
        stroke: grey400,
        contrastColor: blue500,
        attention: '#fff200',
        warning: '#ff0000',
        staticWhite: darkFullWhite,
    },
    importantComment: {
        background: '#061d2e',
    },
    flatButton: {
        primaryTextColor: darkGrey100,
        disabledTextColor: grey700,
        buttonFilterColor: grey200,
        invertColor: darkGrey100,
        fontSize: '18px',
        warningColor: 'rgba(255, 135, 135, 0.3)',
    },
    library: {
        requiredColor: 'rgba(200, 0, 0, 0.3)',
        changedColor: 'rgba(255, 255, 0, 0.3)',
    },
    listItem: {
        hoverColor: grey700,
        disabledColor: grey800,
        removedColor: '#8f3112',
    },
    messageDialog: {
        errorColor: 'rgba(212, 86, 86, 0.7)',
        successColor: 'rgba(86, 212, 86, 0.7)',
    },
    notice: {
        airportGroup: {
            departure: 'rgba(0, 128, 0, 0.6)',
            altDeparture: 'rgba(255, 165, 0, 0.7)',
            enroute: 'rgba(80, 78, 244, 0.6)',
            landing: 'rgba(0, 128, 0, 0.6)',
            altLanding: 'rgba(255, 165, 0, 0.7)',
            fir: 'rgba(255, 0, 0, 0.7)',
            additional: 'rgba(238, 130, 238, 0.8)',
        },
        airportBackgroundColor: 'rgba(80, 80, 80, 1)',
        numberColor: 'rgba(170, 50, 80, 1)',
        accentColor: pinkA200,
        textColor: darkFullWhite,
        ifrColor: '#9e0000',
        vfrColor: '#00820f',
        scopeColor: '#504ef4',
        assignmentColor: grey700,
        favoriteColor: '#00c9ff',
        newColor: '#00b600',
    },
    ofp: {
        selected: '#38833b',
    },
    palette: {
        type: 'dark',
        primary1Color: blue500,
        primary2Color: blue500,
        primary3Color: blue300,
        accent1Color: pinkA200,
        accent2Color: pinkA400,
        accent3Color: blue300,
        textColor: darkFullWhite,
        secondaryTextColor: 'rgba(255, 255, 255, 0.7)',
        alternateTextColor: grey900,
        canvasColor: grey900,
        borderColor: 'rgba(255, 255, 255, 0.3)',
        disabledColor: 'rgba(255, 255, 255, 0.7)',
        pickerHeaderColor: 'rgba(255, 255, 255, 0.12)',
        clockCircleColor: 'rgba(255, 255, 255, 0.12)',
        warningColor: red400,
        error: {
            light: '#e57373',
            main: '#f44336',
            dark: '#d32f2f',
        },
        primary: {
            main: '#1976d2',
            contrastText: darkFullWhite,
        },
        secondary: {
            main: '#e53935',
            contrastText: darkFullWhite,
        },
        text: {
            secondary: 'rgba(255,255,255,0.7)',
        },
        // info: {
        //     contrastText: darkFullWhite,
        // },
        // error: {
        //     contrastText: darkFullWhite,
        // },
    },
    paper: {
        backgroundColor: '#303030',
    },
    point: {
        warning: '#51402b',
        direct: '#c88c00',
        current: 'rgba(0, 188, 212, 0.25)',
        edit: blue800,
        caution: '#8f3112',
        labelWarning: '#ffaaaa',
        labelGreen: '#96ff96',
        editLabelWarning: '#ff8787',
        editLabelGreen: '#a0faaa',
        directed: 'rgba(255, 255, 255, 0.2)',
        abeam: '#4CAF50',
        calculation: '#c3c3c3',
    },
    raisedButton: {
        primaryTextColor: darkFullWhite,
        fontSize: '18px',
    },
    reports: {
        editColor: '#213b63',
    },
    signaturePad: {
        color: 'black',
        backgroundColor: 'transparent',
    },
    stepper: {
        textColor: darkFullWhite,
        inactiveIconColor: grey700,
        fontSize: '18px',
    },
    table: {
        backgroundColor: '#303030',
        secondaryColor: grey900,
    },
    tabs: {
        textColor: 'rgba(255, 255, 255, 0.7)',
        selectedTextColor: darkFullWhite,
    },
    textField: {
        floatingLabelColor: blue300,
        textColor: darkFullWhite,
        disabledTextColor: blue300,
        textFontSize: '18px',
        labelFontSize: '16px',
    },
    timePicker: {
        textColor: darkFullWhite,
        headerColor: blue500,
    },
    toggle: {
        thumbOnColor: blue800,
        thumbOffColor: grey400,
        trackOnColor: blue700,
        trackOffColor: darkFullWhite,
    },
    toolbar: {
        color: darkFullWhite,
        backgroundColor: grey800,
        iconColor: blue100,
    },
};

const getMuiThemeStyle = (type) => {
    return type === 'dark' ? muiThemeDarkStyle : muiThemeLightStyle;
};

export { getMuiThemeStyle };
