import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { NavigationRouteHeaderRow } from './NavigationRouteHeaderRow/NavigationRouteHeaderRow';
import { getColumnList } from '../../../../selectors/declaration';
import { useStyles } from './useStyles';
import { useCalcHeaderWidhts } from '../../hooks';

const NavigationRouteHeader = ({ widths = {}, onChangeHeaderWidth }) => {
    const classes = useStyles();
    const columnsDeclaration = useSelector(getColumnList);
    const mobileEnrouteMode = useSelector((state) => get(state, 'screen.mobileEnrouteMode', false));
    const columns = React.useMemo(() => columnsDeclaration.concat([{ isMenu: true }]), [columnsDeclaration]);
    const { ref } = useCalcHeaderWidhts(onChangeHeaderWidth);

    return (
        <tbody className={classnames('enr__table-inner', classes.header)} ref={ref}>
            {[0, 1, 2].map((item) => (
                <NavigationRouteHeaderRow {...{ columns, widths, mobileEnrouteMode }} key={item} index={item} />
            ))}
        </tbody>
    );
};

const NavigationRouteHeaderMemoized = React.memo(NavigationRouteHeader);

export { NavigationRouteHeaderMemoized as NavigationRouteHeader };
