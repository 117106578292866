import { setHtmlOverflow } from '../components/hooks/useScrollFix';

const TOGGLE_CONFIRM = 'TOGGLE_CONFIRM';

let confirmResultResolver;

function showConfirmDialog({ title, message, btnCancelVisibility = true, options = {} }) {
    return (dispatch) => {
        dispatch({ type: TOGGLE_CONFIRM, isVisible: true, title, message, btnCancelVisibility, options });
        setHtmlOverflow(true);
        return new Promise((resolver) => {
            confirmResultResolver = resolver;
        });
    };
}

function closeConfirmDialog(isOk) {
    return (dispatch) => {
        dispatch({ type: TOGGLE_CONFIRM, isVisible: false });
        setHtmlOverflow(false);
        confirmResultResolver(isOk);
    };
}

export { TOGGLE_CONFIRM, showConfirmDialog, closeConfirmDialog };
