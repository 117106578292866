import { LOGOUT } from '../actions/screen';
import { TOGGLE_CONFIRM } from '../actions/messageDialog';

const initialState = {
    visibility: false,
    title: '',
    text: '',
    btnCancelVisibility: true,
    options: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_CONFIRM:
            return {
                ...state,
                visibility: action.isVisible,
                title: action.title || '',
                text: action.message || '',
                btnCancelVisibility: action.btnCancelVisibility === undefined ? true : action.btnCancelVisibility,
                options: { ...(action.options || {}) },
            };

        case LOGOUT:
            return initialState;

        default:
            return state;
    }
};
