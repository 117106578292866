import React, { memo, useCallback } from 'react';

import { ArrowBack } from '../../material-ui/ArrowBack';
import { LoginStep } from '../hooks/useStep';
import { IconButton } from '../../material-ui/IconButton';
import { useStyles } from './useStyles';

export const PrevStepButton = memo(({ onClick }) => {
    const classes = useStyles();
    const clickHandler = useCallback(() => onClick(LoginStep.LOGIN), [onClick]);

    return (
        <IconButton onClick={clickHandler} className={classes.button}>
            <ArrowBack viewBox="5 5 14 14" width={25} height={25} fontSize="inherit" className={classes.icon} />
        </IconButton>
    );
});
