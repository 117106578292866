import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import crew from './crew';
import documents from './documents';
import defects from './defects';
import gps from './gps';
import images from './images';
import { filterReducer } from './filter';
import flightPage from './flightPage';
import library from './library';
import linearLoading from './linearLoading';
import login from './login';
import messageDialog from './messageDialog';
import notices from './notices';
import progressIndicator from './progressIndicator';
import { modalReducer } from './modal';
import navigation from './navigation';
import { notificationsReducer } from './notifications';
import reports from './reports';
import restoreFiles from './restoreFiles';
import routesDeclaration from './routesDeclaration';
import screen from './screen';
import sendedData from './sended-data';
import storeStage from './storeStage';
import { systemOfMeasurement } from './systemOfMeasurement';
import tasks from './tasks';
import telegrams from './telegrams';
import telex from './telex';
import textDialog from './textDialog';
import users from './users';
import version from './version';
import wizard from './wizard';
import { online } from './online';
import checkLists from './checkList';

const createRootReducer = (history) =>
    combineReducers({
        screen,
        sendedData,
        gps,
        restoreFiles,
        login,
        tasks,
        library,
        users,
        crew,
        documents,
        defects,
        telegrams,
        // TODO добавлено с 2.8.0, убрать старые телеграммы после перехода на нее всех
        telex,
        notices,
        routesDeclaration,
        version,
        reports,
        storeStage,
        textDialog,
        filter: filterReducer,
        router: connectRouter(history),
        wizard,
        navigation,
        notifications: notificationsReducer,
        images,
        modal: modalReducer,
        progressIndicator,
        messageDialog,
        linearLoading,
        flightPage,
        systemOfMeasurement,
        online,
        checkLists,
    });

export { createRootReducer };
