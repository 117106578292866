import { makeStyles } from '@material-ui/core/styles';

import { lightBlue } from '../../../ThemeProvider';

export const useStyles = makeStyles((theme) => ({
    comment: {
        color: theme.textField.highLightTextColor,
        fontWeight: 600,
        fontSize: '16px',
    },
    menuWrapper: {
        width: 48,
        verticalAlign: 'middle',
        textAlign: 'center',
    },
    titleInner: {
        fontSize: '16px',
        fontWeight: 600,
        ...(theme.palette.type === 'dark' && { color: lightBlue }),
    },
}));
