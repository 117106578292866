import React from 'react';
import moment from 'moment';

import { CustomTimePicker } from '../../CustomTimePicker/CustomTimePicker';
import { removeSeconds } from '../../../service/DateTimeUtils';
import { calculateWidth, getUnderlineType } from './tools';
import { Typography } from '../../material-ui/Typography';
import { useStyles } from './useStyles';
import { CustomField } from '../../CustomField/CustomField';

const PaletteField = ({
    id,
    title,
    length,
    multiline,
    type: declarationType,
    required = false,
    onChange,
    defaultValue,
    onFocus,
    fields,
}) => {
    const { type, validator, maxLimit } = fields.find((item) => item.id === id) || {};
    const minRows = (multiline || {}).minRows || 1;
    const maxRows = (multiline || {}).maxRows || 1;
    const classes = useStyles();

    const handleChangeTime = React.useCallback(
        (event, value) => {
            onChange(id, value ? removeSeconds(value) : null);
        },
        [id, onChange]
    );

    const underlineType = getUnderlineType(defaultValue, maxLimit);

    return declarationType === 'date' ? (
        <div
            className={classes.container}
            style={{
                flexBasis: calculateWidth(length),
            }}
        >
            <CustomTimePicker
                name={id}
                label={title.toUpperCase()}
                placeholder={title.toUpperCase()}
                fullWidth={true}
                {...{ onFocus, required }}
                onChange={handleChangeTime}
                style={{ width: '100%' }}
                value={defaultValue ? moment.utc(defaultValue) : null}
            />
        </div>
    ) : declarationType === 'text' ? (
        <Typography
            className={classes.subtitle}
            style={{
                flexBasis: calculateWidth(length),
                fontWeight: 600,
            }}
        >
            {title}
        </Typography>
    ) : (
        <CustomField
            key={`${defaultValue}:${id}`}
            label={title.toUpperCase()}
            name={id}
            {...{ minRows, maxRows, type, onFocus, required }}
            multiline={Boolean(multiline)}
            placeholder={title.toUpperCase()}
            validator={validator}
            className={classes.container}
            defaultValue={defaultValue}
            onEditField={onChange}
            InputProps={{
                className: classes[`input${underlineType}`],
            }}
            style={{
                flexBasis: calculateWidth(length),
            }}
        />
    );
};

const PaletteFieldMemoized = React.memo(PaletteField);

export { PaletteFieldMemoized as PaletteField };
