import * as React from 'react';

import { stopPropagation } from '../service/eventHandlers';

function useAnchorEl() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpenMenu = React.useCallback((event) => {
        stopPropagation(event);
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    return [anchorEl, handleOpenMenu, handleClose];
}

export { useAnchorEl };
