import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    icon: {
        fill: theme.icon.staticWhite,
    },
    toolbar: {
        paddingLeft: '4px',
        paddingRight: '4px',
    },
}));

export { useStyles };
