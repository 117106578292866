import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { Panel } from '../Panel/Panel';
import { LibraryItem } from './LibraryItem/LibraryItem';
import { sortLibrary } from '../../service/sort';
import { MoreVertIconContrast } from '../../icons/MoreVertIconContrast';
import { useStyles } from './useStyles';
import { List, ListItem, ListItemText } from '../material-ui/List';
import { downloadLibraryFolder, downloadRequiredLibraryItems, getLibrary } from '../../actions/library';

const LibraryPage = () => {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = React.useState('');
    const [showRequired, setShowRequired] = React.useState(false);
    const classes = useStyles();
    const libraryItems = useSelector((state) => get(state, 'library.library', {}), shallowEqual);

    const isSearchMode = searchText !== '';

    const handleSearch = React.useCallback((searchText) => {
        setSearchText(searchText);
    }, []);

    const handleToggleRequiredFilter = React.useCallback(() => {
        setShowRequired((prevState) => !prevState);
    }, []);

    const handleDownloadAll = React.useCallback(() => {
        dispatch(downloadLibraryFolder(0));
    }, []);

    const handleDownloadRequired = React.useCallback(() => {
        dispatch(downloadRequiredLibraryItems());
    }, []);

    const handleGetLibrary = React.useCallback(() => {
        dispatch(getLibrary(true));
    }, []);

    const handleFilter = React.useCallback((searchText, key) => {
        const item = key.toLowerCase();

        return searchText === '' || item.indexOf(searchText.toLowerCase()) !== -1;
    }, []);

    const iconMenuItems = React.useMemo(
        () => [
            {
                name: 'Download required',
                callback: handleDownloadRequired,
            },
            {
                name: 'Download all',
                callback: handleDownloadAll,
            },
            {
                name: 'Reload',
                callback: handleGetLibrary,
            },
        ],
        []
    );

    const libraryItemArray = React.useMemo(
        () =>
            Object.keys(libraryItems)
                .reduce((res, key) => {
                    const item = libraryItems[key];
                    const itemName = item.name.toLowerCase();
                    if (itemName.indexOf(searchText.toLowerCase()) !== -1 && (!showRequired || item.needUpdate || item.hasUpdates)) {
                        res.push(item);
                    }

                    return res;
                }, [])
                .sort(sortLibrary),
        [libraryItems, isSearchMode, showRequired, searchText]
    );

    const librariItemList = React.useMemo(
        () =>
            (libraryItemArray || []).reduce((res, item) => {
                if (item.lvl === 0 || isSearchMode || showRequired) {
                    res.push(
                        <LibraryItem
                            key={item.id}
                            id={item.id}
                            data={item}
                            libraryItems={libraryItems}
                            primaryText={`${item.name}`}
                            isSearchMode={isSearchMode}
                        />
                    );
                }

                return res;
            }, []),
        [libraryItemArray]
    );

    return (
        <div className={classes.library}>
            <Panel
                title="Library"
                toggle={{
                    label: 'Show required only',
                    defaultToggled: showRequired,
                    onToggle: handleToggleRequiredFilter,
                    isControlled: true,
                }}
                iconMenuImg={<MoreVertIconContrast isContrast={true} />}
                iconMenuItems={iconMenuItems}
                search={{
                    placeholder: 'Search',
                    filter: handleFilter,
                    dataSource: libraryItemArray,
                    maxSearchResults: 10,
                    dataSourceConfig: {
                        text: 'name',
                        value: 'id',
                    },
                    value: searchText,
                    onSearch: handleSearch,
                }}
                toolbarStyle={{ height: 'auto' }}
            >
                <List>
                    {librariItemList.length > 0 ? (
                        librariItemList
                    ) : (
                        <ListItem disabled={true}>
                            <ListItemText primary="There are not any documents" />
                        </ListItem>
                    )}
                </List>
            </Panel>
        </div>
    );
};

const LibraryPageMemoized = React.memo(LibraryPage);

export { LibraryPageMemoized as LibraryPage };
