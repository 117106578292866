import React from 'react';

import { IconButton } from '../../material-ui/IconButton';

const PanelIcon = (props) => {
    const { onIconClick, icon } = props;

    return (
        <IconButton onClick={onIconClick} style={{ padding: '8px' }}>
            {icon}
        </IconButton>
    );
};

const PanelIconMemoized = React.memo(PanelIcon);

export { PanelIconMemoized as PanelIcon };
