import React, { useContext } from 'react';
import classnames from 'classnames';

import { Drawer } from '../material-ui/Drawer';
import { useStyles } from './useStyles';
import { AttachmentContent } from './AttachmentContent/AttachmentContent';
import { SidebarContext } from '../PageLayout/ContextProvider';

const AttachmentSidebar = () => {
    const {
        sidebarVisibility: { attachmentSidebarVisibility },
        handleToggleAttachmentSidebar,
    } = useContext(SidebarContext);
    const classes = useStyles();

    const handleRequestClose = React.useCallback(() => {
        handleToggleAttachmentSidebar(false);
    }, [handleToggleAttachmentSidebar]);

    return (
        <Drawer
            open={attachmentSidebarVisibility}
            anchor="right"
            onClose={handleRequestClose}
            className={classnames(classes.drawerClass, {
                [classes.drawerOpenClass]: attachmentSidebarVisibility,
                [classes.drawerCloseClass]: !attachmentSidebarVisibility,
            })}
            classes={{
                paper: classnames(
                    {
                        [classes.drawerOpenClass]: attachmentSidebarVisibility,
                        [classes.drawerCloseClass]: !attachmentSidebarVisibility,
                    },
                    classes.drawerPaper
                ),
            }}
        >
            <AttachmentContent onClose={handleRequestClose} />
        </Drawer>
    );
};

const AttachmentSidebarMemoized = React.memo(AttachmentSidebar);

export { AttachmentSidebarMemoized as AttachmentSidebar };
