import React from 'react';
import { get } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';

import { NavigationRouteItem } from '../NavigationRouteItem/NavigationRouteItem';
import { getNavigationRouteFieldsCount } from '../../../selectors/declaration';
import { getCurrentPointIds, getCurrentSendedLeg } from '../../../selectors/sended-data';

import './NavigationRoutes.scss';

const NavigationRoutes = ({ onMenuOpen }) => {
    const { columnsCount, mobileEnrouteMode, offset, currentPosition } = useSelector((state) => {
        const mobileEnrouteMode = get(state, 'screen.mobileEnrouteMode', false);
        const offset = get(state, 'gps.offset', 0);
        const columnsCount = getNavigationRouteFieldsCount(state);
        const currentPosition = get(getCurrentSendedLeg(state), 'currentPosition');

        return {
            currentPosition,
            columnsCount,
            mobileEnrouteMode,
            offset,
        };
    }, shallowEqual);

    const pointListStr = useSelector(getCurrentPointIds);
    const { points, pointHash } = React.useMemo(
        () =>
            pointListStr.split(',').reduce(
                (res, item) => {
                    const [pos, id] = item.split('|');
                    res.points.push(pos);
                    res.pointHash[pos] = +id;

                    return res;
                },
                { points: [], pointHash: {} }
            ),
        [pointListStr]
    );

    return (
        <>
            {points &&
                points.map((point, pos) => (
                    <NavigationRouteItem
                        key={`${pointHash[pos]}_${pos}`}
                        pos={+points[pos]}
                        columnsCount={columnsCount}
                        mobileEnrouteMode={mobileEnrouteMode}
                        offset={offset}
                        current={+currentPosition === +pointHash[+points[pos]]}
                        onMenuOpen={onMenuOpen}
                    />
                ))}
        </>
    );
};

const NavigationRoutesMemoized = React.memo(NavigationRoutes);

export { NavigationRoutesMemoized as NavigationRoutes };
