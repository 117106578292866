import React from 'react';

import { Eraser } from '../../icons';
import { IconButton } from '../material-ui/IconButton';
import { useStyles } from './useStyles';
import { useHookWithRefCallback } from './tools';
import { useDarkTheme } from '../../selectors/screen';

const SignaturePad = ({ onClear, onInitSignature }) => {
    const isDarkTheme = useDarkTheme();
    const classes = useStyles({ isDarkTheme });

    const [signatureRef] = useHookWithRefCallback(onInitSignature, isDarkTheme);

    return (
        <div className={classes.wrapper}>
            <canvas id="signature-pad" className={classes.canvas} ref={signatureRef}></canvas>
            <IconButton tooltip="Clear" onClick={onClear} className={classes.eraseBtn}>
                <Eraser />
            </IconButton>
        </div>
    );
};

const SignaturePadMemoized = React.memo(SignaturePad);

export { SignaturePadMemoized as SignaturePad };
