class CustomError extends Error {
    constructor({ message, code, stack, data, path, text, method, info }) {
        super(message);
        this.type = '';
        this.stack = stack || new Error().stack;
        this.errorCode = code;
        this.path = path;
        this.data = data;
        this.isFetchError = false;
        this.response = null;
        this.text = text || '';
        this.method = method;
        this.info = info;
    }
}

export { CustomError };
