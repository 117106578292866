import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
    form: {
        display: 'flex',
        padding: '0 4px',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
}));
