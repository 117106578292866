import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import classnames from 'classnames';

import { Panel } from '../../Panel/Panel';
import { CustomField } from '../../CustomField/CustomField';
import { getTotalOnBoard } from './tools';
import { checkRequired } from '../tools';
import { useStyles } from './useStyles';
import { editField } from '../../../actions/take-off';
import { getCurrentSendedLeg } from '../../../selectors/sended-data';
import { AdditionalField } from './AdditionalField/AdditionalField';
import { useSingleConvert } from '../../hooks/useMeasureConverter';

const fields = [
    { id: 'remainTakeoff', label: 'Before', order: 1 },
    { id: 'refuelingTakeoff', label: 'Refueling', order: 10 },
    { id: 'totalOnBoard', label: 'Block', order: 11, disabled: true },
    { id: 'expenseLandTakeoff', label: 'Ground', order: 12 },
    { id: 'expenseBeforeTakeoff', label: 'Taxi OUT', order: 13 },
];

const FuelPanel = ({ declaration }) => {
    const dispatch = useDispatch();
    const props = useSelector((state) => {
        const currentLeg = getCurrentSendedLeg(state);
        const takeOff = get(currentLeg, 'takeOff', {});
        const excludeGround = get(state, 'routesDeclaration.options.excludeGround', false);

        return {
            excludeGround,
            fuelComment: get(currentLeg, 'landing.fuelComment', ''),
            onBoardFuel: currentLeg.onBoardFuel,
            remainTakeoff: takeOff.remainTakeoff,
            refuelingTakeoff: takeOff.refuelingTakeoff,
            expenseLandTakeoff: takeOff.expenseLandTakeoff,
            expenseBeforeTakeoff: takeOff.expenseBeforeTakeoff,
            block: getTotalOnBoard({ ...takeOff, excludeGround }),
        };
    }, shallowEqual);
    const classes = useStyles();
    const fuelFieldValidator = { type: 'decimal', min: 0, max: useSingleConvert()(296000), precision: [7, 1] };

    const fieldList = React.useMemo(
        () =>
            (fields &&
                fields.sort((a, b) => {
                    const orderPos1 = get(declaration, `takeOff[${a.id}].order`, a.order);
                    const orderPos2 = get(declaration, `takeOff[${b.id}].order`, b.order);

                    return orderPos1 >= orderPos2 ? 1 : -1;
                })) ||
            [],
        [fields, declaration]
    );

    const handleEditField = React.useCallback(
        (name, value) => dispatch(editField(name, value === null || value === undefined ? null : +value)),
        []
    );

    return (
        <Panel title="Fuel info" className={classes.wrapper} style={{ margin: '0 0 12px', flexGrow: 1 }}>
            <div className={classes.wrapper}>
                {fieldList.map(({ id, label, disabled }, index) => {
                    const defaultValue = props[id];

                    return (
                        <CustomField
                            key={`${defaultValue}:${id}`}
                            label={label}
                            name={id}
                            onEditField={handleEditField}
                            defaultValue={defaultValue}
                            validator={fuelFieldValidator}
                            disabled={disabled}
                            required={checkRequired(declaration, id)}
                            className={classnames(classes.field, index === fieldList.length - 1 && classes.lastField)}
                            type="number"
                            {...(id === 'totalOnBoard' && {
                                disabled: true,
                                helperText: props.onBoardFuel,
                                defaultValue: props.block,
                            })}
                        />
                    );
                })}
                <AdditionalField
                    defaultValue={props.fuelComment}
                    required={get(declaration, 'landing["fuelComment"].required', false)}
                    className={classes.comment}
                />
            </div>
        </Panel>
    );
};

const FuelPanelMemoized = React.memo(FuelPanel);

export { FuelPanelMemoized as FuelPanel };
