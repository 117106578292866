import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { CircularProgress } from '../../material-ui/CircularProgress';
import { useStyles } from './useStyles';
import { Typography } from '../../material-ui/Typography';

const ProgressIndicatorOverlay = () => {
    const classes = useStyles();
    const { isLoading, text } = useSelector((state) => get(state, 'progressIndicator', {}));

    return (
        isLoading && (
            <div className={classes.container}>
                <div className={classes.inner}>
                    <CircularProgress size={120} />
                    <Typography className={classes.text}>{text}</Typography>
                </div>
            </div>
        )
    );
};

const ProgressIndicatorOverlayMemoized = React.memo(ProgressIndicatorOverlay);

export { ProgressIndicatorOverlayMemoized as ProgressIndicatorOverlay };
