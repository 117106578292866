import moment from 'moment';

import { clonePoint } from '../tools';
import { NavType } from '../../../components/NavigationRoutes/constants';

export const getInitialData = (point, fieldList, isNewPoint) => {
    return {
        ...clonePoint(fieldList, point),
        ...(isNewPoint && {
            ato: null,
            dist: null,
            pointNumber: point.pointNumber,
            isUserAdd: 1,
            comment: '',
        }),
        type: isNewPoint ? NavType.FLIGHT : point.type,
        dateCreate: moment.utc(isNewPoint ? undefined : point.dateCreate),
        eto: point.eto ? moment.utc(point.eto) : null,
        ato: isNewPoint ? null : point.ato ? moment.utc(point.ato) : moment.utc(),
    };
};
