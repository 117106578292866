import { GET_ROUTE_DECLARATION, SET_CUSTOM_ROUTE_SETTINGS } from '../actions/declaration';
import { MIN_ALLOWABLE_DISTANCE_TO_ACCEPT } from '../actions/gps';
import { LOGOUT } from '../actions/screen';
import { TimePickerType } from '../components/CustomTimePicker/CustomTimePicker';

const initialState = {
    timePickerType: TimePickerType.MATERIAL,
    allowedDistance: MIN_ALLOWABLE_DISTANCE_TO_ACCEPT,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ROUTE_DECLARATION:
            return { ...state, ...action.routesDeclaration };
        case SET_CUSTOM_ROUTE_SETTINGS:
            return {
                ...state,
                customRouteSettings: action.customRouteSettings,
                allowedDistance: action.allowedDistance,
                selectionType: action.selectionType,
                timePickerType: action.timePickerType,
            };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};
