import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import Refresh from '../../icons/image/Refresh';

const ProgressIcon = ({ ...props }) => {
    const isLoading = useSelector((state) => get(state, 'progressIndicator.isLoading', false));

    return <Refresh {...props} className={`refresh-icon  ${isLoading ? 'refresh-icon--spin' : ''}`} />;
};

const ProgressIconMemoized = React.memo(ProgressIcon);

export { ProgressIconMemoized as ProgressIcon };
