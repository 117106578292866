import React from 'react';

import BaseIcon from '../BaseIcon';

const Report = (props) => (
    <BaseIcon {...props} viewBox="0 0 512.001 512.001">
        <path
            d="M206.682,245.773H104.086c-8.796,0-15.927,7.131-15.927,15.927c0,8.796,7.131,15.927,15.927,15.927h102.595
            c8.796,0,15.927-7.131,15.927-15.927C222.609,252.904,215.478,245.773,206.682,245.773z"
        />
        <path
            d="M206.682,301.15H104.086c-8.796,0-15.927,7.131-15.927,15.927c0,8.796,7.131,15.927,15.927,15.927h102.595
            c8.796,0,15.927-7.131,15.927-15.927C222.609,308.281,215.478,301.15,206.682,301.15z"
        />
        <path
            d="M206.682,356.526H104.086c-8.796,0-15.927,7.131-15.927,15.927s7.131,15.927,15.927,15.927h102.595
            c8.796,0,15.927-7.131,15.927-15.927S215.478,356.526,206.682,356.526z"
        />
        <path
            d="M382.617,245.773H266.976c-8.796,0-15.927,7.131-15.927,15.927v110.753c0,8.796,7.131,15.927,15.927,15.927h115.641
            c8.796,0,15.927-7.131,15.927-15.927V261.7C398.544,252.904,391.413,245.773,382.617,245.773z M366.69,356.526h-83.786v-78.899
            h83.786V356.526z"
        />
        <path
            d="M496.073,122.919h-46.458V68.525c0-8.796-7.131-15.927-15.927-15.927H167.64c-17.709-18.772-42.799-30.509-70.582-30.509
            C43.54,22.089,0,65.628,0,119.146c0,30.902,14.517,58.476,37.089,76.264v245.245c0,27.161,22.097,49.257,49.257,49.257H455.48
            h4.492h2.771c27.161,0,49.257-22.096,49.257-49.257V138.847C512,130.05,504.869,122.919,496.073,122.919z M31.854,119.146
            c0-35.954,29.25-65.203,65.204-65.203c35.953,0,65.204,29.25,65.204,65.204c0,35.954-29.25,65.204-65.204,65.204
            C61.104,184.351,31.854,155.1,31.854,119.146z M86.346,458.057c-9.597,0.001-17.403-7.806-17.403-17.402V212.047
            c8.903,2.699,18.342,4.158,28.115,4.158c12.485,0,24.426-2.371,35.399-6.684h250.162c8.796,0,15.927-7.131,15.927-15.927
            s-7.131-15.927-15.927-15.927H174.436c6.24-8.231,11.203-17.482,14.582-27.457h112.266c8.796,0,15.927-7.131,15.927-15.927
            c0-8.796-7.131-15.927-15.927-15.927H194.105c-0.097-11.932-2.356-23.357-6.406-33.903h230.06v54.394v313.347
            c0,1.994,0.158,3.952,0.459,5.863H86.346z M480.146,440.655c0,9.596-7.807,17.403-17.403,17.403h-2.771h-4.492
            c-3.233,0-5.864-2.631-5.864-5.863V154.774h30.531V440.655z"
        />
        <path
            d="M116.428,110.651h-13V100.38c0-8.796-7.131-15.927-15.927-15.927s-15.927,7.131-15.927,15.927v26.198
            c0,8.796,7.131,15.927,15.927,15.927h28.927c8.796,0,15.927-7.131,15.927-15.927C132.355,117.782,125.224,110.651,116.428,110.651z"
        />
    </BaseIcon>
);

export default Report;
