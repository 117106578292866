import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import { NestedListItem } from '../../NestedListItem/NestedListItem';
import { documentsSort, sortObjByField } from '../../../service/sort';
import { DocumentItem } from '../../DocumentsPage/DocumentItem/DocumentItem';
import { DocumentPrefix } from '../../../service/constants';

const Limitations = ({ defects, classes, primary, style }) => {
    const items = defects.sort(documentsSort);

    return (
        <NestedListItem
            showSign={false}
            inset={false}
            style={style}
            className={classes.listItem}
            primary={primary}
            disabled={!items.length}
        >
            {defects.sort(sortObjByField('name')).map(({ id, name }) => (
                <DocumentItem key={id} id={`${DocumentPrefix.DEFECT}${id}`} primaryText={name} className={classes.nested} />
            ))}
        </NestedListItem>
    );
};

const styles = (theme) => ({
    listItem: {
        borderTop: `1px solid ${theme.palette.border}`,
        fontSize: '18px',
        lineHeight: '18px',
        paddingLeft: '0px !important',
    },
    nested: {
        paddingLeft: theme.spacing(4),
        color: theme.palette.text.primary,
    },
});

const StyledLimitations = withStyles(styles)(Limitations);

export { StyledLimitations as Limitations };
