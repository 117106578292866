import { makeStyles } from '@material-ui/core/styles';
import { darkFullWhite } from '../../../service/getMuiThemeStyle';

export const useStyles = makeStyles(() => ({
    container: {
        position: 'fixed',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        opacity: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        zIndex: 1400,
    },
    inner: {
        position: 'absolute',
        width: '100%',
        top: 'calc(50% - 80px)',
        textAlign: 'center',
    },
    text: {
        fontSize: 32,
        color: darkFullWhite,
    },
}));
