import React from 'react';

import BaseIcon from '../BaseIcon';

const CheckAltitude = (props) => (
    <BaseIcon {...props} viewBox="0 0 40 40" strokeWidth="3px" stroke={props.color}>
        <line x1="1.18" y1="4.5" x2="13" y2="4.5" />
        <line x1="1" y1="17.5" x2="13" y2="17.5" />
        <line x1="16.5" y1="3" x2="16.5" y2="37" />
        <line x1="16" y1="35.5" x2="28" y2="35.5" />
        <line x1="34.5" y1="4.88" x2="34.5" y2="35" />
        <polygon points="34.5,34.81 33,31.65 31.5,28.5 34.5,28.5 37.5,28.5 36,31.65 " />
        <polygon points="34.5,5.5 36,8.65 37.5,11.8 34.5,11.8 31.5,11.8 33,8.65 " />
        <line x1="1.5" y1="3" x2="1.5" y2="37" />
    </BaseIcon>
);

export default CheckAltitude;
