import React from 'react';
import { useTheme } from '@material-ui/core/styles';

const MAX_LENGTH = 9;

const RouteName = (props) => {
    const { value = '', directed = false } = props;
    let route = value;
    const theme = useTheme();
    const style = {
        color: theme.point.abeam,
    };
    if (route.length > MAX_LENGTH) {
        route = route.substring(0, MAX_LENGTH);
    }

    return (
        <>
            {directed ? <span style={style}>AB</span> : ''}
            {route}
        </>
    );
};

export { RouteName };
