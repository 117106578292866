import { makeStyles } from '@material-ui/styles';

import { SIDEBAR_WIDTH } from './NoticeSidebar';

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        display: 'none',
        width: SIDEBAR_WIDTH,
    },
    drawerOpen: {
        display: 'block',
    },
    list: { padding: theme.spacing(8, 0) },
}));

export { useStyles };
