import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    const {
        table: { secondaryColor },
    } = theme;

    return {
        lastRow: {
            '& > td': {
                paddingTop: 0,
                paddingBottom: 5,
            },
        },
        row: {
            '& > td': {
                paddingTop: 5,
                paddingBottom: 0,
            },
        },
        secondaryRow: {
            background: secondaryColor,
        },
    };
});
