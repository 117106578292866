import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => {
    return {
        airport: {
            flex: '1 0 200px',
            minHeight: 25,
            padding: '0 3px',
            fontWeight: 500,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        container: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            boxSizing: 'border-box',
            // borderLeft: this.props.border,
        },
        iata: {
            flex: '0 0 70px',
            minHeight: 25,
            padding: '0 6px',
            opacity: 0.9,
        },
        icao: {
            flex: '0 0 70px',
            minHeight: 25,
            padding: '0 6px',
            fontWeight: 500,
        },
        listItem: {
            fontSize: '18px',
            lineHeight: '18px',
        },
        notice: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            padding: 0,
            boxSizing: 'border-box',
        },
        region: {
            flex: '1 0 200px',
            minHeight: 25,
            padding: '0 3px',
            opacity: 0.9,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
    };
});

export { useStyles };
