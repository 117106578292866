import React from 'react';
import { useDispatch } from 'react-redux';

import { FuelLandingPanel } from './FuelLandingPanel/FuelLandingPanel';
import { AdditionalInfoPanel } from './AdditionalInfoPanel/AdditionalInfoPanel';
import { useStyles } from './useStyles';
import { editField } from '../../actions/landing';
import { EndWorkTime } from './EndWorkTime/EndWorkTime';

import './EndInfoPage.scss';

const EndInfoPage = () => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleEditField = React.useCallback(
        (name, value) => {
            dispatch(editField(name, value === null || value === undefined ? null : +value));
        },

        []
    );
    const handleEditCommentField = React.useCallback((name, value) => dispatch(editField(name, value)), []);

    return (
        <div className={classes.wrapper}>
            <FuelLandingPanel onEditField={handleEditField} onEditCommentField={handleEditCommentField} />
            <EndWorkTime />
            <AdditionalInfoPanel onEditField={handleEditField} onEditCommentField={handleEditCommentField} />
        </div>
    );
};

const EndInfoPageMemoized = React.memo(EndInfoPage);

export { EndInfoPageMemoized as EndInfoPage };
