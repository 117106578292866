import developPluginsBundle from './develop/bundle';
import cordovaPluginsBundle from './cordova/bundle';
import { getUrl } from './tools';

const getUrlFromConfig = () => {
    const serverURL = window.cordova.plugins.EmmAppConfig.getValue('serverURL');
    const ssl = window.cordova.plugins.EmmAppConfig.getValue('ssl');
    if (ssl) {
        localStorage.setItem('ssl', ssl);
    }

    if (serverURL) {
        localStorage.setItem('backendUrl', serverURL);
    }
};

export default (defaultUrl) =>
    new Promise((resolve) => {
        if (window.cordova) {
            document.addEventListener(
                'deviceready',
                () => {
                    window.powermanagement.acquire();

                    getUrlFromConfig(defaultUrl);
                    //navigator.geolocation.getCurrentPosition(onSuccess, onError);
                    //window.StatusBar && window.StatusBar.backgroundColorByHexString('#333');
                    // В WkWebView есть проблемы с инициализацией cookie при первом запуске. Решаем проблему плагином.
                    if (window.wkWebView) {
                        window.wkWebView.injectCookie(getUrl());
                    }
                    window.cordova.plugins.Keyboard.hideKeyboardAccessoryBar(false);
                    resolve(cordovaPluginsBundle);
                },
                false
            );
            window.addEventListener('native.keyboardhide', () => {
                document.activeElement.blur();
            });
        } else {
            resolve(developPluginsBundle);
        }
    });
