import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    cancel: {
        color: theme.palette.warningColor,
        fontWeight: '500',
    },
    container: {
        display: 'inline-block',
        borderRadius: '4px',
        padding: '4px 4px 4px',
        margin: '0 6px 3px 0',
        verticalAlign: 'middle',
        backgroundColor: theme.appBar.colorLight,
    },
    number: {
        fontWeight: '600',
    },
    route: {
        fontStyle: 'italic',
        fontWeight: '500',
    },
}));

export { useStyles };
