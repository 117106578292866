import React from 'react';

import { StartInfoPage } from '../StartInfoPage/StartInfoPage';
import { LoadingPage } from '../LoadingPage/LoadingPage';
import { TakeOffPage } from '../TakeOffPage/TakeOffPage';
import { LandingPage } from '../LandingPage/LandingPage';
import { EndInfoPage } from '../EndInfoPage/EndInfoPage';
import { NavigationRoutesPage } from '../NavigationRoutes/NavigationRoutesPage';
import { StepName, StepNumber, WizardStep } from './constants';

export const wizardSteps = [
    { number: StepNumber[WizardStep.START], name: StepName[WizardStep.START], content: <StartInfoPage /> },
    { number: StepNumber[WizardStep.LOAD_FUEL], name: StepName[WizardStep.LOAD_FUEL], content: <LoadingPage />, checkDisable: true },
    { number: StepNumber[WizardStep.DEP], name: StepName[WizardStep.DEP], content: <TakeOffPage />, checkDisable: true },
    {
        number: StepNumber[WizardStep.NAV_ROUTE],
        name: StepName[WizardStep.NAV_ROUTE],
        content: <NavigationRoutesPage />,
        checkDisable: true,
    },
    { number: StepNumber[WizardStep.ARR], name: StepName[WizardStep.ARR], content: <LandingPage />, checkDisable: true },
    { number: StepNumber[WizardStep.TOTAL], name: StepName[WizardStep.TOTAL], content: <EndInfoPage />, checkDisable: true },
];
