import React from 'react';

import BaseIcon from '../BaseIcon';

const Divert = (props) => (
    <BaseIcon {...props} viewBox="0 0 387.375 387.375">
        <path
            d="M339.645,91.529c6.318-5.554,6.93-15.183,1.382-21.493c-2.706-3.079-5.53-6.097-8.491-9.034c0-0.007,0-0.007,0-0.007
			c0-0.008-0.014-0.008-0.014-0.008c0-0.008,0-0.008,0-0.008v-0.007C297.057,25.517,249.907,5.986,199.754,5.986
			c-45.038,0-87.638,15.798-121.541,44.704L52.415,24.9c-3.852-3.852-9.456-5.339-14.706-3.941
			c-5.264,1.406-9.367,5.51-10.766,10.766l-26.42,98.612c-1.414,5.257,0.088,10.862,3.939,14.706
			c2.883,2.899,6.779,4.461,10.766,4.461c1.307,0,2.631-0.171,3.939-0.521l98.613-26.423c5.247-1.404,9.351-5.509,10.764-10.765
			c1.412-5.257-0.09-10.862-3.94-14.706L99.802,72.28c28.088-23.204,63.045-35.843,99.951-35.843
			c42.021,0,81.526,16.371,111.252,46.087c0,0,0,0,0,0.008c0.015,0,0.015,0,0.015,0.007c2.48,2.477,4.847,5.019,7.138,7.606
			c3.004,3.427,7.211,5.174,11.435,5.174C333.16,95.32,336.76,94.071,339.645,91.529z"
        />
        <path
            d="M157.912,345.48c-6.794-1.874-13.528-4.224-19.999-6.98c-7.731-3.286-16.684,0.305-19.984,8.035
			c-3.299,7.74,0.298,16.685,8.047,19.979c7.717,3.293,15.746,6.097,23.863,8.327c1.354,0.371,2.705,0.549,4.044,0.549
			c6.677,0,12.817-4.438,14.677-11.195C170.79,356.089,166.015,347.703,157.912,345.48z"
        />
        <path
            d="M227.916,348.588c-6.928,1.248-14.006,2.052-21.038,2.362c-8.401,0.373-14.9,7.488-14.527,15.89
			c0.357,8.17,7.091,14.549,15.195,14.549c0.224,0,0.461-0.007,0.686-0.016c8.401-0.379,16.846-1.329,25.113-2.824
			c8.281-1.502,13.77-9.42,12.268-17.694C244.125,352.58,236.154,347.057,227.916,348.588z"
        />
        <path
            d="M344.416,131.793c2.767,6.462,5.115,13.182,7.005,19.985c1.858,6.75,7.985,11.174,14.661,11.174
			c1.339,0,2.705-0.179,4.06-0.55c8.104-2.238,12.863-10.624,10.631-18.729c-2.244-8.11-5.055-16.14-8.354-23.857
			c-3.316-7.731-12.255-11.315-19.984-8.015C344.699,115.111,341.115,124.063,344.416,131.793z"
        />
        <path
            d="M292.301,321.05c-5.711,4.163-11.732,7.956-17.902,11.286c-7.406,3.991-10.172,13.226-6.187,20.631
			c2.751,5.1,8.001,8,13.429,8c2.438,0,4.905-0.588,7.209-1.822c7.376-3.984,14.572-8.52,21.396-13.486
			c6.794-4.959,8.282-14.481,3.33-21.276C308.625,317.593,299.094,316.091,292.301,321.05z"
        />
        <path
            d="M359.02,262.071c-7.438-3.985-16.641-1.204-20.624,6.2c-3.333,6.186-7.124,12.215-11.257,17.91
			c-4.951,6.803-3.449,16.326,3.346,21.27c2.707,1.972,5.842,2.915,8.951,2.915c4.699,0,9.339-2.178,12.328-6.273
			c4.949-6.804,9.471-14.007,13.455-21.396C369.189,275.283,366.424,266.056,359.02,262.071z"
        />
        <path
            d="M372.816,186.187c-0.223-0.008-0.447-0.016-0.685-0.016c-8.087,0-14.823,6.387-15.196,14.558
			c-0.312,7.062-1.1,14.146-2.35,21.055c-1.484,8.281,4.016,16.192,12.299,17.688c0.905,0.162,1.813,0.244,2.705,0.244
			c7.227,0,13.65-5.166,14.973-12.534c1.487-8.244,2.424-16.688,2.797-25.104C387.732,193.672,381.219,186.559,372.816,186.187z"
        />
        <path
            d="M88.504,305.035c-5.949-5.946-15.583-5.946-21.531,0c-5.949,5.94-5.949,15.584,0,21.531
			c2.942,2.943,5.977,5.776,9.055,8.482c2.885,2.543,6.468,3.785,10.037,3.785c4.236,0,8.445-1.748,11.449-5.177
			c5.547-6.317,4.922-15.938-1.398-21.492C93.528,309.891,90.986,307.512,88.504,305.035z"
        />
    </BaseIcon>
);

export default Divert;
