import React from 'react';

import BaseIcon from '../BaseIcon';

const Complete = (props) => (
    <BaseIcon {...props} viewBox="0 0 426.667 426.667">
        <path
            d="M213.333,0C95.518,0,0,95.514,0,213.333s95.518,213.333,213.333,213.333
            c117.828,0,213.333-95.514,213.333-213.333S331.157,0,213.333,0z M174.199,322.918l-93.935-93.931l31.309-31.309l62.626,62.622
            l140.894-140.898l31.309,31.309L174.199,322.918z"
        />
    </BaseIcon>
);

export default Complete;
