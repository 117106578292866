import React from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { Filter } from '../Filter/Filter';
import { Typography } from '../../material-ui/Typography';
import { FilterComponents } from '../FilterComponents/FilterComponents';
import { useFilter } from '../../../selectors/filter';
import { FilterTypes, initialFilterState } from '../../../reducers/filter';
import { setFilters } from '../../../actions/filter';
import { useData } from '../hooks/useData';
import { useStyles } from '../hooks/useStyles';
import { pageFiltersSet, filterSettings } from '../constants';

const getInitialState = (filtersSet, initialFilters, filters, selectData) =>
    filtersSet &&
    Object.values(filtersSet).reduce((res, { name }) => {
        const filterName = filterSettings[name].name;

        //Очистка отсутствующего параметра фильтрации
        if (filters[filterName] && filters[filterName].length && (!selectData || !selectData[name] || !selectData[name].length)) {
            res = {
                ...res,
                [filterName]: initialFilters[filterName],
            };
        }

        return res;
    }, filters);

export const FilterContent = React.memo(({ handleFiltersClose }) => {
    const pathname = useSelector((state) => get(state, 'router.location.pathname'));
    const page = pathname.substring(1).toUpperCase();
    const dispatch = useDispatch();
    const filterType = FilterTypes[page];
    const filtersSet = pageFiltersSet[filterType];
    const filters = useFilter(filterType);
    const initialFilters = React.useMemo(() => ({ ...initialFilterState[filterType] }), [filterType]);
    const selectData = useData(filterType);
    const initFilterInputs = React.useMemo(() => getInitialState(filtersSet, initialFilters, filters, selectData), [filters, selectData]);
    const [filterInputs, setFilterInputs] = React.useState(initFilterInputs);

    const classes = useStyles();

    React.useEffect(() => setFilterInputs(initFilterInputs), [filters, selectData]);

    const handleOnFilter = React.useCallback(() => {
        dispatch(setFilters(filterType, filterInputs));
        handleFiltersClose();
    }, [filterInputs, handleFiltersClose, filterType]);

    const handleClearFilter = React.useCallback(
        () =>
            setFilterInputs((prevState) => ({
                ...prevState,
                ...initialFilters,
            })),
        [filterInputs, initialFilters]
    );

    return selectData && Object.keys(selectData).length ? (
        <Filter className={classes.filter} onFilter={handleOnFilter} onClear={handleClearFilter}>
            <div className={classes.margin} />
            {filtersSet ? (
                <FilterComponents
                    filtersSet={filtersSet}
                    filterInputs={filterInputs}
                    setFilterInputs={setFilterInputs}
                    selectData={selectData}
                    classes={classes}
                />
            ) : (
                ''
            )}
        </Filter>
    ) : (
        <Typography className={classes.noDataString}>No Select Data</Typography>
    );
});
