import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
    return {
        actions: {
            padding: theme.spacing(2),
            '& button': {
                height: theme.spacing(7.5),
            },

            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column-reverse',
                alignItems: 'stretch',
                padding: theme.spacing(1),

                '& button': {
                    margin: theme.spacing(0.6),
                },
                '& button:not(:first-child)': {
                    marginLeft: theme.spacing(0.6),
                },
            },
        },
        content: {
            padding: theme.spacing(0, 1),

            [theme.breakpoints.down('sm')]: {
                padding: 0,
            },
        },
        item: {
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(0, 1),
            },
        },
        list: {
            padding: 0,
        },
        title: {
            padding: theme.spacing(1, 1, 1),
        },
    };
});

export { useStyles };
