import React from 'react';

export const SidebarContext = React.createContext(null);

export const SidebarProvider = React.memo(({ children }) => {
    const [sidebarVisibility, setSidebarVisibility] = React.useState({
        notificationSidebarVisibility: false,
        navigationPanelVisibility: false,
        attachmentSidebarVisibility: false,
        filterSidebarVisibility: false,
    });

    const handleToggleAttachmentSidebar = React.useCallback((value) => {
        setSidebarVisibility((prevState) => ({
            ...prevState,
            attachmentSidebarVisibility: value === undefined ? !prevState.attachmentSidebarVisibility : value,
        }));
    }, []);

    const handleToggleNavigationPanel = React.useCallback(() => {
        setSidebarVisibility((prevState) => ({
            ...prevState,
            navigationPanelVisibility: !prevState.navigationPanelVisibility,
        }));
    }, []);

    const handleToggleNotificationSidebar = React.useCallback(() => {
        setSidebarVisibility((prevState) => ({
            ...prevState,
            notificationSidebarVisibility: !prevState.notificationSidebarVisibility,
        }));
    }, []);

    const handleToggleFilterSidebar = React.useCallback((value) => {
        setSidebarVisibility((prevState) => ({
            ...prevState,
            filterSidebarVisibility: value === undefined ? !prevState.filterSidebarVisibility : value,
        }));
    }, []);

    const handleCloseSidebar = React.useCallback(() => {
        setSidebarVisibility({
            notificationSidebarVisibility: false,
            navigationPanelVisibility: false,
        });
    }, []);

    const handleCloseAllSidebar = React.useCallback(() => {
        setSidebarVisibility({
            notificationSidebarVisibility: false,
            navigationPanelVisibility: false,
            attachmentSidebarVisibility: false,
            filterSidebarVisibility: false,
        });
    }, []);

    const value = React.useMemo(
        () => ({
            sidebarVisibility,
            setSidebarVisibility,
            handleToggleAttachmentSidebar,
            handleToggleNavigationPanel,
            handleToggleNotificationSidebar,
            handleToggleFilterSidebar,
            handleCloseSidebar,
            handleCloseAllSidebar,
        }),
        [sidebarVisibility]
    );

    return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
});
