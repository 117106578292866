import React from 'react';

import { DialogControls } from '../../components/DialogControls/DialogControls';
import { useHideModal } from '../../actions/modal';
import { Email, Person, Phone } from '../../icons';
import { List, ListItem, ListItemIcon, ListItemText } from '../../components/material-ui/List';
import { Dialog } from '../../components/material-ui/Dialog';
import { Avatar } from '../../components/material-ui/Avatar';
import { useMobileWidth } from '../../components/hooks/useMobileWidth';
import { DialogActions, DialogContent } from '../../components/material-ui/Dialog';
import { useStyles } from './useStyles';

const EmployeeModal = React.forwardRef((props, ref) => {
    const { url = '', fullName, staffTree, chair, phone, email } = props;
    const hideModal = useHideModal();
    const { isMobilePortrait } = useMobileWidth();
    const classes = useStyles();
    const actions = React.useMemo(
        () => [
            {
                label: 'OK',
                color: 'primary',
                onClick: hideModal,
            },
        ],
        []
    );

    const phones = (phone && phone.match(/(?:\+7|8)?[\- ]?\(?\d{3}\)?\ ?[-\ \d]{7,10}/gi)) || []; //eslint-disable-line
    const emails = (email && email.match(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}/gi)) || []; //eslint-disable-line

    return (
        <Dialog
            open={true}
            onClose={hideModal}
            ref={ref}
            fullScreen={isMobilePortrait}
            fullWidth={true}
            maxWidth="sm"
            classes={{ paper: classes.paper }}
        >
            <DialogContent className={classes.content}>
                <div className={classes.avatarWrapper}>
                    <Avatar src={url} className={classes.avatar}>
                        <Person width={180} height={180} />
                    </Avatar>
                </div>
                <List className={classes.list}>
                    {fullName && (
                        <ListItem key={1}>
                            <ListItemText primary={fullName.toUpperCase()} classes={{ primary: classes.fullName }} />
                        </ListItem>
                    )}
                    {staffTree && (
                        <ListItem key={2}>
                            <ListItemText primary={staffTree} className={classes.staffTree} />
                        </ListItem>
                    )}
                    {chair && (
                        <ListItem key={3}>
                            <ListItemText primary={chair} className={classes.staffTree} />
                        </ListItem>
                    )}
                    {phone && (
                        <ListItem key={4}>
                            <ListItemIcon>
                                <Phone width={30} height={30} className={classes.icon} />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <span>
                                        {phones.map((phone, index) => (
                                            <span className={classes.link} key={`${index}_${phone}`}>
                                                <a href={`tel:${phone}`} className={classes.text}>
                                                    {phone}
                                                </a>
                                            </span>
                                        ))}
                                    </span>
                                }
                            />
                        </ListItem>
                    )}
                    {email && (
                        <ListItem key={5}>
                            <ListItemIcon>
                                <Email width={30} height={30} className={classes.icon} />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <span>
                                        {emails.map((email, index) => (
                                            <p className={classes.link} key={`${index}_${email}`}>
                                                <a href={`mailto:${email}`} className={classes.text}>
                                                    {email}
                                                </a>
                                            </p>
                                        ))}
                                    </span>
                                }
                            />
                        </ListItem>
                    )}
                </List>
            </DialogContent>
            <DialogActions>
                <DialogControls actions={actions} />
            </DialogActions>
        </Dialog>
    );
});

const EmployeeModalMemoized = React.memo(EmployeeModal);

export { EmployeeModalMemoized as EmployeeModal };
