const BackupSingleton = (function () {
    let isActive = false;

    return {
        check: function () {
            return isActive;
        },
        on: function () {
            isActive = true;
        },
        off: function () {
            isActive = false;
        },
    };
})();

export default BackupSingleton;
