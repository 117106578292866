import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    col: {
        width: '70px',
        fontSize: '16px',
        padding: '10px 0',
    },
    col2: {
        width: '200px',
        fontSize: '16px',
        padding: '10px 0',
    },
    col3: {
        fontSize: '16px',
        width: '300px',
        padding: '10px 0',
    },
    col4: {
        fontSize: '16px',
        width: '100px',
        padding: '10px 0',
    },
    comment: {
        opacity: '0.8',
        padding: 0,
    },
}));
