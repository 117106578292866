import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { useHideModal } from '../../actions/modal';
import { setOfp } from '../../actions/startInfo';
import { SignaturePopup } from '../../components/SignaturePopup/SignaturePopup';
import { checkCaptain } from '../../selectors/screen';
import { getCurrentSendedLeg } from '../../selectors/sended-data';

const Signature = React.forwardRef(({ ofpId }, ref) => {
    const hideModal = useHideModal();
    const dispatch = useDispatch();
    const { isCurrentUserCaptain, comment } = useSelector((state) => {
        const isCurrentUserCaptain = checkCaptain(state);
        const leg = getCurrentSendedLeg(state);
        const comment = leg && leg.signComment;

        return {
            comment,
            isCurrentUserCaptain,
        };
    }, shallowEqual);

    const handleSelectOfp = React.useCallback(
        (signature, comment) => {
            dispatch(setOfp(ofpId, signature, comment));
        },
        [ofpId]
    );

    const handleHideSignaturePopup = React.useCallback(() => {
        hideModal();
    }, []);

    return (
        <SignaturePopup
            ref={ref}
            visibility={true}
            text={'Signature popup text'}
            title={'OFP select confirm'}
            onSign={handleSelectOfp}
            onClose={handleHideSignaturePopup}
            signComment={comment}
            showComment={isCurrentUserCaptain}
            ofpId={ofpId}
        />
    );
});

const SignatureMemoized = React.memo(Signature);

export { SignatureMemoized as Signature };
