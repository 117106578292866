import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { NavigationRouteHeader } from '../NavigationRouteHeader/NavigationRouteHeader';
import { getColumnList } from '../../../../selectors/declaration';
import { NavRoutesContext } from '../../ContextProvider';

const FIXED_FIELDS_HEIGHT = 156;

const NavigationRoutesWrapperContent = () => {
    const mobileEnrouteMode = useSelector((state) => get(state, 'screen.mobileEnrouteMode', false));
    const columnsDeclaration = useSelector(getColumnList);
    const [{ isFixedHeaderVisible, fixedHeaderWidth, fixedHeaderTopOffset }, changeState] = React.useState({
        isFixedHeaderVisible: false,
        fixedHeaderWidth: 0,
        fixedHeaderTopOffset: 0,
    });
    const { headerWidths } = useContext(NavRoutesContext);

    const handleWindowResize = React.useCallback(() => {
        changeState((prevState) => ({
            ...prevState,
            isFixedHeaderVisible: false,
        }));
    }, []);

    const handleDocumentScroll = React.useCallback(() => {
        const content = document.querySelector('.page-layout__content');
        const table = document.getElementsByClassName('enr__table')[0];
        const { offsetTop: offsetTopOrigin, offsetWidth } = table;
        const offsetTop = offsetTopOrigin - FIXED_FIELDS_HEIGHT;

        changeState((prevState) => {
            if (content.scrollTop >= offsetTop - 1 && !prevState.isFixedHeaderVisible) {
                return {
                    isFixedHeaderVisible: true,
                    fixedHeaderWidth: offsetWidth,
                    fixedHeaderTopOffset: offsetTop + 1,
                };
            } else if (content.scrollTop < offsetTop && prevState.isFixedHeaderVisible) {
                return {
                    ...prevState,
                    isFixedHeaderVisible: false,
                };
            }

            return prevState;
        });
    }, [columnsDeclaration]);

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    React.useEffect(() => {
        const content = document.querySelector('.page-layout__content');
        if (content) {
            content.addEventListener('scroll', handleDocumentScroll);
        }

        return () => {
            if (content) {
                content.removeEventListener('scroll', handleDocumentScroll);
            }
        };
    }, [handleDocumentScroll]);

    return (
        <table
            className="enr__fixed-header"
            style={{
                position: 'fixed',
                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.1)',
                visibility: isFixedHeaderVisible ? 'visible' : 'hidden',
                marginTop: `-${fixedHeaderTopOffset}px`,
                width: `${fixedHeaderWidth}px`,
            }}
        >
            {headerWidths && <NavigationRouteHeader {...{ columnsDeclaration, widths: headerWidths, mobileEnrouteMode }} />}
        </table>
    );
};

const NavigationRoutesWrapperContentMemoized = React.memo(NavigationRoutesWrapperContent);

export { NavigationRoutesWrapperContentMemoized as NavigationRoutesWrapperContent };
