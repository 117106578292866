import { LOGOUT } from '../actions/screen';
import { GET_RESTORE_FILES } from '../actions/login';

const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_RESTORE_FILES: {
            const newState = [].concat(action.fileList || []);

            return newState;
        }
        case LOGOUT:
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
