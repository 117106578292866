import { useMemo } from 'react';
import { get, isNil } from 'lodash';
import { useSelector } from 'react-redux';

import { Weight } from '../../reducers/systemOfMeasurement';
import { StepNumber, WizardStep } from '../FlightWizardPage/constants';
import { getCurrentOfp } from '../../selectors/screen';
import { getSystemOfMeasurementOption } from '../../selectors/config';
import { getAppWeightUnits } from '../../selectors/sended-data';

const useShowSystemMeasureToggler = (pathname) => {
    const currentWeightUnits = useSelector(getAppWeightUnits);
    const isUsedCfpWeightUnits = useSelector(getSystemOfMeasurementOption);
    const wizardStep = useSelector((state) => get(state, 'wizard.wizardStep', 0));
    const isFlightInfoPage = pathname.substring(1) === 'flight-info';
    const { weightUnits: cfpWeightUnits = null } = useSelector(getCurrentOfp) || {};
    const showSystemMeasureToggler =
        isUsedCfpWeightUnits &&
        isFlightInfoPage &&
        cfpWeightUnits === Weight.LBS &&
        (wizardStep === StepNumber[WizardStep.LOAD_FUEL] || wizardStep === StepNumber[WizardStep.TOTAL]);

    const weightUnitsForBadge = useMemo(() => {
        if (wizardStep === StepNumber[WizardStep.ARR]) {
            return cfpWeightUnits === Weight.LBS ? currentWeightUnits : Weight.KG;
        }
        if (wizardStep === StepNumber[WizardStep.NAV_ROUTE]) {
            return cfpWeightUnits;
        }

        return null;
    }, [wizardStep, cfpWeightUnits, currentWeightUnits]);
    const showWeightUnitsBadge = isUsedCfpWeightUnits && isFlightInfoPage && !isNil(weightUnitsForBadge);

    return { showSystemMeasureToggler, showWeightUnitsBadge, currentWeightUnits, weightUnitsForBadge };
};

export { useShowSystemMeasureToggler };
