import React from 'react';

import { useStyles } from '../useStyles';

function PreliminaryInfoRow({ item = {} }) {
    const classes = useStyles();

    return (
        <tr>
            <td className={classes.columnMain}>
                <div>
                    <div>{item.airportTakeOff.code}</div>
                    <div>{item.airportLanding.code}</div>
                </div>
            </td>
            <td className={classes.column}>{item.prePlaneComponovkaInfo}</td>
            <td className={classes.column}>{item.prePlaneInfo}</td>
            <td className={classes.column}>{item.preFuelInfo}</td>
            <td className={classes.column}>{item.preFuelInfoReal}</td>
            <td className={classes.column}>
                <div>
                    <div>{item.prePassengerInfo}</div>
                    <div>{item.prePassengerFirstLAInfo}</div>
                </div>
            </td>
            <td className={classes.column}>
                <div>
                    <div>{item.preLoadInfo}</div>
                    <div>{item.preLoadFirstLAInfo}</div>
                </div>
            </td>
        </tr>
    );
}

export { PreliminaryInfoRow };
