import { makeStyles } from '@material-ui/core/styles';

import { grey750 } from '../ThemeProvider';

export const useStyles = makeStyles((theme) => {
    const { stroke } = theme.icon;

    const color = theme.palette.type === 'light' ? theme.palette.grey['700'] : theme.palette.accent3Color;

    return {
        filterIconActive: {
            color: theme.palette.primary.main,
        },
        icon: {
            color: theme.icon.color,
            stroke,
        },
        legControls: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginLeft: theme.spacing(1.5),
        },
        simple: {
            fontSize: '18px',
            lineHeight: '18px',
        },
        titleDate: {
            ['@media only screen and (max-width: 780px)']: {
                display: 'none',
            },
        },
        titleRoute: {
            ['@media only screen and (max-width: 400px)']: {
                display: 'none',
            },
        },
        titleStart: {
            ['@media only screen and (max-width: 480px)']: {
                display: 'none',
            },
        },
        toolbar: {
            display: 'flex',
            flexBasis: '100%',
            height: '46px',
            minHeight: '46px',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            background: theme.palette.type === 'light' ? theme.palette.grey['300'] : grey750,
        },
        toolbarWrapper: {
            position: 'fixed',
            display: 'flex',
            width: '100%',
            paddingTop: 64,
            zIndex: 990,
            flexShrink: 0,
            boxShadow: '0 0 3px grey',

            ['@media only screen and (max-width: 480px)']: {
                overflowX: 'scroll',
                overflowY: 'hidden',
            },
        },
        toolbarTitle: {
            fontSize: '18px',
            color,
            flexGrow: 1,

            ['@media only screen and (max-width: 800px)']: {
                width: 335,
            },

            ['@media only screen and (max-width: 570px)']: {
                width: 310,
            },

            ['@media only screen and (max-width: 500px)']: {
                width: 230,
            },

            ['@media only screen and (max-width: 400px)']: {
                width: 130,
            },
        },
    };
});
