import React from 'react';
import classnames from 'classnames';
import { get } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { SelectCrewPanel } from '../../SelectCrewPanel/SelectCrewPanel';
import { useStyles } from './useStyles';
import { getCurrentSendedLanding } from '../../../selectors/sended-data';
import { getCurrentLegCabinCrew } from '../../../selectors/screen';
import { editInformationField } from '../../../actions/landing';

const CrewPanel = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const pilotFlight = useSelector((state) => {
        const landing = getCurrentSendedLanding(state);
        const isPilotFlightRequired = get(state, 'routesDeclaration.options.isPilotFlightRequired', false);
        const cabinCrew = getCurrentLegCabinCrew(state);

        return {
            items: cabinCrew,
            value: get(landing, 'informationForm.pilotFlight'),
            isRequired: isPilotFlightRequired,
        };
    }, shallowEqual);

    const handleChangePilotFlight = React.useCallback((value) => {
        dispatch(editInformationField('pilotFlight', value ? value : null));
    }, []);

    return (
        <SelectCrewPanel
            {...pilotFlight}
            title="Pilot flying"
            onChange={handleChangePilotFlight}
            className={classnames(classes.panel, classes.specialPanel)}
        />
    );
};

const CrewPanelMemoized = React.memo(CrewPanel);

export { CrewPanelMemoized as CrewPanel };
