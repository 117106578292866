import { LOGOUT } from '../actions/screen';
import { TOGGLE_GPS, CHANGE_GPS_POSITION, GPS_ERROR, SET_OFFSET } from '../actions/gps';

const initialState = {
    isGpsOn: false,
    position: {},
    successTime: null,
    offset: 0,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_GPS:
            return { ...state, position: { ...state.position }, isGpsOn: action.value };
        case CHANGE_GPS_POSITION: {
            const newState = { ...state, position: { ...(action.position || {}) } };
            if (action.successTime) {
                delete newState.lastErrorTime;
                newState.successTime = action.successTime;
            } else {
                newState.lastErrorTime = +new Date();
            }

            return newState;
        }
        case GPS_ERROR:
            return { ...state, lastErrorTime: +new Date() };
        case SET_OFFSET:
            return { ...state, offset: action.offset };
        case LOGOUT:
            return { ...initialState };
        default:
            return state;
    }
};
