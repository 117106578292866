const UPDATE_CREW = 'UPDATE_CREW';

function updateCrew(crew) {
    return {
        type: UPDATE_CREW,
        crew,
    };
}

export { updateCrew, UPDATE_CREW };
