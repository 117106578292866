import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { DialogActions, DialogAdaptive, DialogContent, DialogTitle } from '../../components/material-ui/Dialog';
import { InputLabel } from '../../components/material-ui/InputLabel';
import { Input } from '../../components/material-ui/Input';
import { useStyles } from './useStyles';
import { FieldTypes } from '../../components/hooks/useForm/validate';
import { useHideModal } from '../../actions/modal';
import { DialogControls } from '../../components/DialogControls/DialogControls';
import { FormControl } from '../../components/material-ui/FormControl';
import { Select } from '../../components/material-ui/Select';
import { MenuItem } from '../../components/material-ui/Menu';
import { ListItemText } from '../../components/material-ui/List';
import { useForm } from '../../components/hooks/useForm/useForm';
import { TextField } from '../../components/material-ui/TextField';
import { changeUser } from '../../actions/tasks';
import { getCurrentTaskSelector } from '../../selectors/screen';
import { pushError } from '../../actions/notifications';

const fields = {
    userId: { title: 'userId', type: FieldTypes.NUMBER },
    password: { title: 'Password', type: FieldTypes.STRING },
};

const ChangeUser = React.forwardRef((props, ref) => {
    const hideModal = useHideModal();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { users, currentUserId } = useSelector((state) => {
        const users = get(state, 'users.list', {});
        const currentUserId = get(state, 'screen.currentUser.id');
        const currentTask = getCurrentTaskSelector(state);
        const taskUsers = get(currentTask, 'users', []).map((item) => users[item]);

        return { currentUserId, users: taskUsers };
    });

    const handleSuccess = React.useCallback((data) => {
        const result = dispatch(changeUser(data.userId, data.password));
        if (result.isOk) {
            hideModal();
        } else {
            dispatch(
                pushError({
                    header: 'Incorrect password',
                    error: 'Incorrect user password, please try again later',
                })
            );
        }
    }, []);

    const { inputs, handleInputChange, handleSubmit, status } = useForm({
        onSuccess: handleSuccess,
        initialData: {
            userId: currentUserId,
        },
        fields,
    });
    const userId = get(inputs, 'userId', 0);
    const password = get(inputs, 'password');

    const actions = React.useMemo(
        () => [
            { label: 'Cancel', onClick: hideModal },
            {
                label: 'OK',
                color: 'primary',
                type: 'submit',
                disabled: !password,
            },
        ],
        [Boolean(password)]
    );
    const handleFlightChange = React.useCallback((event) => {
        handleInputChange(event);
    }, []);

    return (
        <DialogAdaptive open={true} maxWidth="md" fullScreenBreakpoint="sm">
            <DialogTitle className={classes.title}>{'Change user dialog'}</DialogTitle>
            <form ref={ref} onSubmit={handleSubmit}>
                <DialogContent className={classes.content}>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="userId">User</InputLabel>
                        <Select name="userId" value={userId} onChange={handleFlightChange} input={<Input id="userId" />}>
                            {users &&
                                users.map((user) => (
                                    <MenuItem key={user.id} value={user.id}>
                                        <ListItemText primary={`${user.name} (${user.login})`} />
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            id="password"
                            name="password"
                            label="Password"
                            value={password}
                            onChange={handleInputChange}
                            type="password"
                            error={Boolean(status.password)}
                            helperText={status.password}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder="Enter password"
                            className={classes.field}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <DialogControls actions={actions} />
                </DialogActions>
            </form>
        </DialogAdaptive>
    );
});

export { ChangeUser };
