import { getNextLeg } from '../selectors/screen';
const EDIT_LANDING_INFORMATION_FIELD = 'EDIT_LANDING_INFORMATION_FIELD';
const ON_CHANGE_LANDING_TIME = 'ON_CHANGE_LANDING_TIME';
const EDIT_LANDING_FIELD = 'EDIT_LANDING_FIELD';

function editInformationField(name, value) {
    return (dispatch, getState) => {
        const { currentUser, currentLeg } = getState().screen;

        return dispatch({
            type: EDIT_LANDING_INFORMATION_FIELD,
            name,
            value,
            legId: currentLeg,
            userId: currentUser.id,
        });
    };
}

function onChangeTime(name, value) {
    return (dispatch, getState) => {
        const state = getState();
        const { currentUser, currentLeg } = state.screen;
        const nextLeg = getNextLeg(state);

        return dispatch({
            type: ON_CHANGE_LANDING_TIME,
            name,
            value: value && new Date(value).toISOString(),
            legId: currentLeg,
            userId: currentUser.id,
            nextLegId: nextLeg && nextLeg.id,
        });
    };
}

function editField(name, value) {
    return (dispatch, getState) => {
        const { currentUser, currentLeg } = getState().screen;

        return dispatch({
            type: EDIT_LANDING_FIELD,
            name,
            value: value !== undefined && value !== null ? value : null,
            legId: currentLeg,
            userId: currentUser.id,
        });
    };
}

export { EDIT_LANDING_INFORMATION_FIELD, ON_CHANGE_LANDING_TIME, EDIT_LANDING_FIELD, editInformationField, onChangeTime, editField };
