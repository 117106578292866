import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
    return {
        crewWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            flex: '1 1 100%',
        },
    };
});

export { useStyles };
