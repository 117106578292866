import React from 'react';

import { ListItem, ListItemText } from '../../material-ui/List';
import { NotificationItem } from './NotificationItem/NotificationItem';

const getText = (selectedIndex) => {
    if (selectedIndex === 0) {
        return 'There are not any documents';
    }

    if (selectedIndex === 1) {
        return 'There are not any AC limitations';
    }

    return 'There are not any library items';
};

const NotificationItems = ({ items = [], selectedIndex }) => {
    return items.length > 0 ? (
        items.map((item) => <NotificationItem key={item.id} {...item} selectedIndex={selectedIndex} />)
    ) : (
        <ListItem key={0} button={true} disabled={true}>
            <ListItemText primary={getText(selectedIndex)} />
        </ListItem>
    );
};

const NotificationItemsMemoized = React.memo(NotificationItems);

export { NotificationItemsMemoized as NotificationItems };
