import React from 'react';

import { Switch } from '../../material-ui/Switch';
import { FormControlLabel } from '../../material-ui/FormControlLabel';
import { useStyles } from './useStyles';

const PanelToggleControl = (props) => {
    // TODO remove toggleStyle
    const { label, defaultToggled, onToggle, toggleStyle = {} } = props;
    const classes = useStyles();

    return (
        <div className={classes.wrapper} style={toggleStyle}>
            <FormControlLabel
                classes={{ label: classes.label }}
                control={<Switch checked={defaultToggled} onChange={onToggle} color="primary" />}
                label={label}
                labelPlacement="start"
            />
        </div>
    );
};

const PanelToggleControlMemoized = React.memo(PanelToggleControl);

export { PanelToggleControlMemoized as PanelToggleControl };
