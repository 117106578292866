import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { Panel } from '../../Panel/Panel.jsx';
import { toggleMobileEnrouteMode } from '../../../actions/screen';
import { NavigationRoutesWrapperContent } from './NavigationRoutesWrapperContent/NavigationRoutesWrapperContent';

const NavigationRoutesWrapper = ({ children }) => {
    const dispatch = useDispatch();
    const mobileEnrouteMode = useSelector((state) => get(state, 'screen.mobileEnrouteMode', false));
    const handleToggleMobileEnrouteMode = React.useCallback(() => dispatch(toggleMobileEnrouteMode()), []);

    return (
        <div className="enr">
            <Panel
                title="Nav routes"
                className="enr__panel"
                toggle={{
                    label: 'Full mode',
                    defaultToggled: !mobileEnrouteMode,
                    onToggle: handleToggleMobileEnrouteMode,
                    isControlled: true,
                }}
            >
                <NavigationRoutesWrapperContent />
                <table className="enr__table">{children}</table>
            </Panel>
        </div>
    );
};

const NavigationRoutesWrapperMemoized = React.memo(NavigationRoutesWrapper);

export { NavigationRoutesWrapperMemoized as NavigationRoutesWrapper };
