import React from 'react';

import { CheckListItem } from '../CheckListItem/CheckListItem';
import { Typography } from '../../../components/material-ui/Typography';
import { useStyles } from './useStyles';

export const CheckListContent = React.memo(({ checkListGroups, checkedItems, backgroundColor, toggleCheckItem, isTouch }) => {
    const classes = useStyles();

    return Object.values(checkListGroups).reduce((res, checkListGroup, groupIndex) => {
        const { number: groupNumber, name: groupName, items: groupItems } = checkListGroup;
        if (groupItems && groupItems.length > 0) {
            const items = groupItems.map((item) => (
                <CheckListItem
                    key={`${groupIndex}_${item.templateItemId}`}
                    item={item}
                    checked={checkedItems[item.templateItemId]}
                    toggleCheckItem={toggleCheckItem}
                    isTouch={isTouch}
                />
            ));

            if (items.length > 0) {
                res.push(
                    <section id={groupNumber} key={`${groupIndex}`}>
                        <Typography style={{ backgroundColor }} className={classes.sticky}>
                            <span className={classes.group}>{groupName}</span>
                        </Typography>
                        {items}
                    </section>
                );
            }
        }

        return res;
    }, []);
});
