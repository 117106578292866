import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { DialogControls } from '../DialogControls/DialogControls';
import { closeConfirmDialog } from '../../actions/messageDialog';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '../material-ui/Dialog';
import { useStyles } from './useStyles';
import { Typography } from '../material-ui/Typography';

export const MessageDialogTypes = {
    DEFAULT: 0,
    ERROR: 1,
    SUCCESS: 2,
};

const MessageDialog = () => {
    const { btnCancelVisibility, title, visibility, text, options = {} } = useSelector((state) => get(state, 'messageDialog', {}));
    const { type = MessageDialogTypes.DEFAULT, cancelTitle = 'Cancel', okTitle = 'OK' } = options;
    const dispatch = useDispatch();
    const classes = useStyles(type);

    const handleCancel = React.useCallback(() => {
        dispatch(closeConfirmDialog(false));
    }, []);

    const handleOk = React.useCallback(() => {
        dispatch(closeConfirmDialog(true));
    }, []);

    const titleClassName = React.useMemo(() => {
        if (type === MessageDialogTypes.DEFAULT) {
            return classes.title;
        }

        return classes[`title${type === MessageDialogTypes.ERROR ? 'Error' : 'Success'}`];
    }, [type]);

    const actions = React.useMemo(() => {
        const data = btnCancelVisibility ? [{ label: cancelTitle, onClick: handleCancel }] : [];
        const okButton = { label: okTitle, color: 'primary', onClick: handleOk };
        if (options.okWarning) {
            okButton.className = classes.warningButton;
        }
        data.push(okButton);

        return data;
    }, [btnCancelVisibility, options.okWarning]);

    return (
        <Dialog open={visibility} className={classes.wrapper}>
            <DialogTitle className={titleClassName}>{title}</DialogTitle>
            <DialogContent className={classes.content}>
                <Typography>{text}</Typography>
            </DialogContent>
            <DialogActions>
                <DialogControls actions={actions} />
            </DialogActions>
        </Dialog>
    );
};

const MessageDialogMemoized = React.memo(MessageDialog);

export { MessageDialogMemoized as MessageDialog };
