import { createSelector } from 'reselect';
import { get } from 'lodash';
import { getCurrentLeg } from './screen';
import { useSelector } from 'react-redux';

export const getDefectsNotifications = createSelector(
    (state) => get(state, 'defects'),
    (defects) => {
        const defectList = [];
        Object.keys(defects || {}).forEach((key) => {
            const defect = defects[key];
            if (!defect.isDownloaded) {
                defectList.push(defect);
            }
        });

        return defectList;
    }
);

const getDefects = createSelector(
    getCurrentLeg,
    (state) => get(state, 'defects', {}),
    (leg = {}, defects) => {
        return get(leg, 'defectIds', []).map((defectId) => defects[defectId]);
    }
);

export const useDefects = () => useSelector(getDefects);
