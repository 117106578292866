import React from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import { useStyles } from './useStyles';
import { Button } from '../../material-ui/Button';
import { ListItemText, ListItem, ListItemSecondaryAction } from '../../material-ui/List';
import { TYPES, useShowModal } from '../../../actions/modal';
import { getTelegram, preloadTelegramSignatureUrl } from '../../../actions/telegrams';
import { getTelex, preloadTelexSignatureUrl } from '../../../actions/telex';
import { setInitialOfp } from '../../../actions/systemOfMeasurement';

const Ofp = ({ id, telegramId, isCurrent, primary, secondary, isPlnChanged }) => {
    const classes = useStyles(isCurrent)();
    const dispatch = useDispatch();
    const showModal = useShowModal();
    const isSupportTelex = Boolean(useSelector((state) => get(state, 'telex', null)));
    const preloadSignatureUrl = isSupportTelex ? preloadTelexSignatureUrl : preloadTelegramSignatureUrl;
    const getCurrentTelegram = isSupportTelex ? getTelex : getTelegram;

    React.useEffect(() => {
        if (isCurrent) {
            dispatch(setInitialOfp(id));
        }
    }, [isCurrent]);

    const handleShowSignature = React.useCallback(
        (e) => {
            e.preventDefault();
            showModal(TYPES.SIGNATURE, { ofpId: id });
        },
        [id]
    );

    const handleShow = React.useCallback(() => {
        dispatch(preloadSignatureUrl(`sign-ofp_${id}`));
        dispatch(getCurrentTelegram({ id: telegramId, name: 'OFP', showPreview: isCurrent, ofpId: id }));
    }, [id, telegramId, isCurrent]);

    return (
        <ListItem className={classnames(isCurrent ? classes.selectedListStyle : classes.listStyle, isPlnChanged && classes.deprecated)}>
            <ListItemText
                {...{ primary, secondary }}
                primaryTypographyProps={{ className: classes.text }}
                secondaryTypographyProps={{ className: classes.text }}
            />
            <ListItemSecondaryAction className={classes.actions}>
                <>
                    <Button variant="contained" onClick={handleShow}>
                        Show
                    </Button>
                    {isCurrent || (
                        <Button variant="contained" color="primary" onClick={handleShowSignature} className={classes.selectButton}>
                            Select
                        </Button>
                    )}
                </>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

const OfpMemoized = React.memo(Ofp);

export { OfpMemoized as Ofp };
