import { UPDATE_CREW } from '../actions/crew';
import { LOGOUT } from '../actions/screen';
const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_CREW: {
            const newState = { ...state };

            Object.keys(action.crew).forEach((key) => {
                newState[key] = {
                    ...newState[key],
                    ...action.crew[key],
                };
            });

            return newState;
        }
        case LOGOUT:
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
