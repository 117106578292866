import React from 'react';

import BaseIcon from '../BaseIcon';

const Add = (props) => (
    <BaseIcon {...props} viewBox="0 0 42 42">
        <polygon points="42,19 23,19 23,0 19,0 19,19 0,19 0,23 19,23 19,42 23,42 23,23 42,23 " />
    </BaseIcon>
);

export default Add;
