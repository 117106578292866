import { makeStyles } from '@material-ui/styles';

import { darkFullWhite } from '../../service/getMuiThemeStyle';

const useStyles = makeStyles((theme) => ({
    appBar: {
        display: 'flex',
    },
    closeBtn: {
        marginLeft: theme.spacing(1),
    },
    content: {
        paddingTop: 56,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
    },
    signatureWrapper: {
        display: 'flex',
        flexDirection: 'row',
        margin: '64px 0 24px',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    switchContainer: {
        flexGrow: 1,
        color: darkFullWhite,
    },
}));

export { useStyles };
