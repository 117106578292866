import React from 'react';

import BaseIcon from '../BaseIcon';

export const CheckboxCheck = (props) => (
    <BaseIcon {...props} viewBox="0 0 58 58">
        <path d="M49 48H6.817V6.799H39.125V-.031H6.817C3.03.037-.148 2.539-.081 6.866v41.073C.011 52.538 3.494 54.949 6.71 55H49.036C52.446 54.981 55.955 51.522 56 47.839V21h-7v25.106z" />
        <path
            fill="#00b600"
            d="M31.297 41.74c-1.17 1.588-2.992 2.564-4.962 2.661-1.97.098-3.878-.696-5.198-2.162L10.825 29.076c-1.88-2.085-1.713-5.299.372-7.179 2.087-1.88 5.299-1.712 7.179.374l8.369 9.288c.124.138.304.212.489.203.186-.009.356-.102.467-.25L44.553 5.372c1.665-2.26 4.847-2.741 7.108-1.075 2.259 1.666 2.739 4.848 1.073 7.107L31.297 41.74z"
        />
    </BaseIcon>
);
