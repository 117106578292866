import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
    return {
        actions: {
            padding: theme.spacing(2),

            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(1),
            },
        },
        biologicalNight: {
            margin: theme.spacing(1, 0),
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(0, 2),

            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(0, 1),
            },
        },
        crew: {
            marginTop: 0,
            fontSize: 20,
            fontWeight: 'bold',
        },
        comment: {
            margin: theme.spacing(1, 0),
        },
        commentInput: {
            height: 'auto',
        },
        dates: {
            display: 'flex',
            margin: theme.spacing(1, 0),

            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
            },
        },
        independent: {
            margin: theme.spacing(1, 0),
        },
        title: {
            padding: theme.spacing(2),

            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(1),
            },
        },
    };
});

export { useStyles };
