import { SET_DATA_BACKUP_STATUS } from '../actions/storeStage';
import { LOGOUT } from '../actions/screen';

const initialState = {
    isDataBackuped: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA_BACKUP_STATUS: {
            return { ...state, isDataBackuped: action.isDataBackuped };
        }
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};
