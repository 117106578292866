import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
    const {
        palette: { warningColor, borderColor },
        textField,
    } = theme;

    return {
        actions: {
            padding: theme.spacing(1, 2),

            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(1, 0.5),
            },
        },
        content: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            minWidth: 300,
        },
        contentStyle: {
            width: '100%',
            border: `2px solid ${borderColor}`,
        },
        formControl: {
            margin: theme.spacing(0.5, 0),
            flexBasis: '25%',
        },
        listStyle: {
            fontSize: textField.textFontSize,
        },
        menuListStyle: {
            fontSize: '18px',
        },
        primaryText: {
            fontSize: textField.textFontSize,
            lineHeight: textField.textFontSize,
            fontWeight: '600',
        },
        secondaryText: {
            fontSize: textField.labelFontSize,
            color: textField.textColor,
            fontWeight: 'normal',
        },
        title: {
            padding: theme.spacing(2, 2, 1),

            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(2, 1, 1),
            },
        },
        warning: {
            color: warningColor,
        },
    };
});

export { useStyles };
