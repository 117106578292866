import * as React from 'react';
import moment from 'moment';
import { KeyboardTimePicker } from '@material-ui/pickers';

import { IconButton } from '../IconButton';
import Remove from '../../../icons/image/Remove';
import { stopPropagation } from '../../../service/eventHandlers';
import { validateDate } from './tools';

const CustomKeyboardTimePicker = (props) => {
    const { value, onChange, label, showClear = true, onClear, ...restProps } = props;
    const [selectedDate, changeSelectedDate] = React.useState(value ? moment.utc(value) : null);
    const ref = React.useRef();

    React.useEffect(() => {
        changeSelectedDate(value);
    }, [value]);

    const handleClear = React.useCallback((event) => {
        stopPropagation(event);
        if (onClear) {
            onClear();
        } else if (onChange) {
            onChange(event, null);
        }
        changeSelectedDate(null);
    }, []);

    const handleBlur = React.useCallback((event) => {
        if (event.target) {
            const isDateValid = validateDate(event.target.value, 'HH:mm');
            if (ref && ref.current && !isDateValid) {
                return ref.current.focus();
            }
            onChange(event, moment.utc(event.target.value, 'HH:mm', true));
        }
    }, []);

    return (
        <KeyboardTimePicker
            {...restProps}
            ampm={false}
            variant="inline"
            emptyLabel="HH:mm"
            label={label}
            value={selectedDate}
            onChange={changeSelectedDate}
            InputLabelProps={{
                shrink: true,
            }}
            inputProps={{
                ref,
                onBlur: handleBlur,
            }}
            InputProps={
                showClear
                    ? {
                          endAdornment: (
                              <IconButton onClick={handleClear}>
                                  <Remove width="16" height="16" />
                              </IconButton>
                          ),
                      }
                    : ''
            }
            InputAdornmentProps={{
                position: 'start',
                style: {
                    display: 'none',
                },
            }}
        />
    );
};

const KeyboardTimePickerMemoized = React.memo(CustomKeyboardTimePicker);

export { KeyboardTimePickerMemoized as KeyboardTimePicker };
