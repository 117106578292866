import React from 'react';

import BaseIcon from '../BaseIcon';
import './Aviabit.scss';

const Aviabit = (props) => (
    <BaseIcon {...props} style={{ width: props.width, height: props.height }} viewBox="0 0 532.2 380.2">
        <g id="Рамка">
            <path
                className="st0"
                d="M410.3,317.1H130.5c-6.6,0-12-5.4-12-12V25.3c0-6.6,5.4-12,12-12h279.8c6.6,0,12,5.4,12,12v279.8
                        C422.3,311.7,416.9,317.1,410.3,317.1z"
            />
            <path
                className="st1"
                d="M396.9,305.5h-253c-7.6,0-13.8-6.2-13.8-13.8v-253c0-7.6,6.2-13.8,13.8-13.8h253c7.6,0,13.8,6.2,13.8,13.8v253
                        C410.7,299.3,404.5,305.5,396.9,305.5z"
            />
        </g>
        <g className="st2">
            <g className="st3">
                <line className="st4" x1="347.6" y1="118.8" x2="346.5" y2="149.4" />
                <path className="st4" d="M378.3,118.8" />
                <polyline
                    className="st4"
                    points="347.6,118.8 398.5,238.1 347.6,118.8 296.7,-0.6 347.6,118.8 228.3,169.7 347.6,118.8 467,67.8
                            347.6,118.8 370.3,147.5 347.6,118.8 324.9,90 347.6,118.8 318.9,141.5 347.6,118.8 376.3,96.1 347.6,118.8 443.5,217.6
                            347.6,118.8 251.7,19.9 347.6,118.8 248.7,214.7 347.6,118.8 446.5,22.8 347.6,118.8 389.9,141.8 347.6,118.8 305.3,95.7
                            347.6,118.8 324.6,161.1 347.6,118.8 370.7,76.4 347.6,118.8 392.3,126.8 347.6,118.8 303,110.7 347.6,118.8 342.2,148.9
                            347.6,118.8 355.7,74.1 347.6,118.8 439.1,202.5 347.6,118.8 256.1,35 347.6,118.8 263.9,210.3 347.6,118.8 431.4,27.3
                            347.6,118.8 458.5,76.5 347.6,118.8 236.8,161.1 347.6,118.8 389.9,229.6 347.6,118.8 305.3,7.9 347.6,118.8 409.6,139.3
                            347.6,118.8 		"
                />
                <polyline
                    className="st4"
                    points="285.7,98.2 347.6,118.8 327.1,180.7 347.6,118.8 368.2,56.8 347.6,118.8 370,131 347.6,118.8
                            325.3,106.5 347.6,118.8 335.4,141.1 347.6,118.8 359.9,96.4 347.6,118.8 407.4,165.7 347.6,118.8 287.9,71.9 347.6,118.8
                            300.7,178.5 347.6,118.8 394.5,59 347.6,118.8 428,88.9 347.6,118.8 267.3,148.6 347.6,118.8 377.5,199.1 347.6,118.8 317.8,38.4
                            347.6,118.8 356.9,136.3 347.6,118.8 338.3,101.2 347.6,118.8 330.1,128 347.6,118.8 365.1,109.5 347.6,118.8 423.8,110.5
                            347.6,118.8 271.5,127 347.6,118.8 347.6,144.3 347.6,118.8 339.4,42.6 347.6,118.8 396.5,162 347.6,118.8 298.7,75.5
                            347.6,118.8 304.4,167.7 347.6,118.8 390.9,69.9 347.6,118.8 436.5,184.5 347.6,118.8 258.8,53 347.6,118.8 281.9,207.6
                            347.6,118.8 413.4,29.9 		"
                />
                <radialGradient id="SVGID_1_" cx="378.262" cy="118.7544" r="0" gradientUnits="userSpaceOnUse">
                    <stop offset="0.15" style={{ stopColor: '#FFFFFF' }} />
                    <stop offset="0.2124" style={{ stopColor: '#DED0D0' }} />
                    <stop offset="0.2924" style={{ stopColor: '#B7999B' }} />
                    <stop offset="0.3616" style={{ stopColor: '#9B7174' }} />
                    <stop offset="0.4164" style={{ stopColor: '#8A595C' }} />
                    <stop offset="0.45" style={{ stopColor: '#845053' }} />
                    <stop offset="0.4874" style={{ stopColor: '#7B4649' }} />
                    <stop offset="0.6461" style={{ stopColor: '#582021' }} />
                    <stop offset="0.7725" style={{ stopColor: '#430909' }} />
                    <stop offset="0.85" style={{ stopColor: '#3B0000' }} />
                    <stop offset="0.8578" style={{ stopColor: '#3F0302' }} />
                    <stop offset="0.866" style={{ stopColor: '#4C0B08' }} />
                    <stop offset="0.8742" style={{ stopColor: '#601912' }} />
                    <stop offset="0.8825" style={{ stopColor: '#7D2C21' }} />
                    <stop offset="0.891" style={{ stopColor: '#A34533' }} />
                    <stop offset="0.8993" style={{ stopColor: '#D06349' }} />
                    <stop offset="0.9" style={{ stopColor: '#D4664B' }} />
                    <stop offset="0.909" style={{ stopColor: '#B5513C' }} />
                    <stop offset="0.9218" style={{ stopColor: '#90382A' }} />
                    <stop offset="0.9351" style={{ stopColor: '#71241A' }} />
                    <stop offset="0.9491" style={{ stopColor: '#59140F' }} />
                    <stop offset="0.9638" style={{ stopColor: '#480906' }} />
                    <stop offset="0.9799" style={{ stopColor: '#3E0202' }} />
                    <stop offset="1" style={{ stopColor: '#3B0000' }} />
                </radialGradient>
                <path className="st5" d="M378.3,118.8" />
                <radialGradient id="SVGID_2_" cx="347.6364" cy="118.7544" r="25.5213" gradientUnits="userSpaceOnUse">
                    <stop offset="0.15" style={{ stopColor: '#FFFFFF' }} />
                    <stop offset="0.2824" style={{ stopColor: '#C2C2C2' }} />
                    <stop offset="0.4253" style={{ stopColor: '#888888' }} />
                    <stop offset="0.5636" style={{ stopColor: '#575757' }} />
                    <stop offset="0.6936" style={{ stopColor: '#313131' }} />
                    <stop offset="0.8134" style={{ stopColor: '#161616' }} />
                    <stop offset="0.9192" style={{ stopColor: '#060606' }} />
                    <stop offset="1" style={{ stopColor: '#000000' }} />
                </radialGradient>
                <circle className="st6" cx="347.6" cy="118.8" r="25.5" />
                <polyline
                    className="st7"
                    points="347.6,118.8 398.5,238.1 347.6,118.8 296.7,-0.6 347.6,118.8 228.3,169.7 347.6,118.8 467,67.8
                            347.6,118.8 370.3,147.5 347.6,118.8 324.9,90 347.6,118.8 318.9,141.5 347.6,118.8 376.3,96.1 347.6,118.8 443.5,217.6
                            347.6,118.8 251.7,19.9 347.6,118.8 248.7,214.7 347.6,118.8 446.5,22.8 347.6,118.8 389.9,141.8 347.6,118.8 305.3,95.7
                            347.6,118.8 324.6,161.1 347.6,118.8 370.7,76.4 347.6,118.8 392.3,126.8 347.6,118.8 303,110.7 347.6,118.8 339.6,163.4
                            347.6,118.8 355.7,74.1 347.6,118.8 439.1,202.5 347.6,118.8 256.1,35 347.6,118.8 263.9,210.3 347.6,118.8 431.4,27.3
                            347.6,118.8 458.5,76.5 347.6,118.8 236.8,161.1 347.6,118.8 389.9,229.6 347.6,118.8 305.3,7.9 347.6,118.8 409.6,139.3
                            347.6,118.8 285.7,98.2 347.6,118.8 327.1,180.7 347.6,118.8 368.2,56.8 347.6,118.8 370,131 347.6,118.8 325.3,106.5
                            347.6,118.8 335.4,141.1 347.6,118.8 359.9,96.4 347.6,118.8 407.4,165.7 347.6,118.8 287.9,71.9 347.6,118.8 300.7,178.5
                            347.6,118.8 394.5,59 347.6,118.8 428,88.9 347.6,118.8 267.3,148.6 347.6,118.8 377.5,199.1 347.6,118.8 317.8,38.4 347.6,118.8
                            356.9,136.3 347.6,118.8 338.3,101.2 347.6,118.8 330.1,128 347.6,118.8 365.1,109.5 347.6,118.8 423.8,110.5 347.6,118.8
                            271.5,127 347.6,118.8 355.9,157 347.6,118.8 339.4,42.6 347.6,118.8 396.5,162 347.6,118.8 298.7,75.5 347.6,118.8 304.4,167.7
                            347.6,118.8 390.9,69.9 347.6,118.8 436.5,184.5 347.6,118.8 258.8,53 347.6,118.8 281.9,207.6 347.6,118.8 413.4,29.9 		"
                />
            </g>
        </g>
        <g id="Board">
            <ellipse
                transform="matrix(0.7376 -0.6753 0.6753 0.7376 -58.3363 207.099)"
                className="st8"
                cx="237.3"
                cy="178.6"
                rx="13.4"
                ry="5.9"
            />
            <path
                className="st9"
                d="M234,183.9c5.4-9.2,11.7-14.9,14-16.9c36.6-32.1,69.6-68.3,106.3-100.2c0,0,0.2-0.2,0.4-0.4
                        c2.4-2,6.3-5.3,11.2-6.1c1.5-0.2,4.5-0.4,8.3,1.1c2.7-1.3,12.9-5.8,15.4-2.8c0.9,1.1,0.5,2.9,0,4.6c-3.3,11.3-8.7,17.1-8.7,17.1
                        c-6.8,7.3-44.4,46.2-96.8,99.1c-43.1,31.5-55.7,36.6-58.2,33.9c-0.2-0.3-0.5-0.8,0.4-5.8C227.7,200.1,229,192.4,234,183.9z"
            />
            <path
                className="st10"
                d="M283.3,134.1c-11.1-5.9-22.1-11.8-33.2-17.7c-2.7,2.2-5.3,4.5-8,6.7c-4.9-2.3-9.8-4.5-14.8-6.8
                        c-2.5,2.2-5,4.3-7.5,6.5c5.4,2.6,10.8,5.2,16.2,7.8c-1.3,1.1-2.6,2.2-3.9,3.4c10.2,6.2,20.4,12.4,30.6,18.6
                        C269.7,146.4,276.5,140.2,283.3,134.1z"
            />
            <path
                className="st11"
                d="M260.8,184.8c0.2-0.1,0.3-0.2,0.5-0.3c11.1-9.8,22.2-19.8,33.5-30.1c24-22.1,46.7-44.1,68.2-65.9
                        c0.1-0.1,0.2-0.2,0.2-0.3c5-6.6,10.1-13.3,15.1-19.9c0.5-0.6,0.5-1.3,0.5-2.2c0.1-2.6-1.5-3.9-0.9-5.2c0.1-0.3,0.4-0.6,2.9-1.4
                        c3.7-1.1,6.4-1.8,7.7-0.5c0.2,0.2,0.3,0.5,0.4,0.7c0.7,1.4,0.2,2.8-0.5,4.7c-1.3,3.6-1.9,5.4-2,5.6c-0.6,1.3-1.6,3.1-2.9,5
                        c0,0-1.2,2.4-3,4.5c-6.4,7.5-12.3,13.2-18.1,19.5c-10.7,11.7-6.2,6.5-37.2,37.4c-9.8,9.8-11.4,12.2-19.8,20.7
                        c-8.8,9-15.9,17.4-20.7,22.6c-0.2,0.2-0.3,0.3-0.5,0.4c-43.9,29.1-56.6,34-57.5,33c0,0,0.1,0.8,0.1,0.7c0,0-0.5-4.4-0.3-5.5
                        C227.2,205.9,240.8,196.3,260.8,184.8z"
            />
            <path
                className="st12"
                d="M257.3,198.8c-1.7-2.5-7-1.7-9.9-0.7c-1.3,0.5-3.1,1.8-6.6,4.5c-5.2,4-5.9,4.8-6.4,5.6c-0.4,0.7-3,5.2-1,7.9
                        c1.8,2.4,6,1.8,6.9,1.7c2.4-0.4,4.3-1.7,8.1-4.5c3.2-2.3,4.8-3.5,6.1-5.1C254.9,207.7,259.4,201.9,257.3,198.8z"
            />
            <path
                className="st8"
                d="M230.3,207.6c-16.5,0.3-33,0.6-49.6,0.9c0-5.4,0-10.9,0-16.3c2.4-2.1,4.9-4.1,7.3-6.2c16.8,0,33.5,0,50.3,0
                        C235.6,193.2,233,200.4,230.3,207.6z"
            />
            <path
                className="st12"
                d="M294.1,243.5c3.2-3.1,6.5-6.2,9.7-9.4c0-27.8,0-54.6,0-82.4c-8,7.4-16,14.9-24,22.3c-0.1,13-0.2,26-0.3,39
                        c1-0.8,2.1-1.6,3.1-2.3c0.2,21.4,0.4,42.7,0.6,64.1c3.4-3.4,6.8-6.8,10.2-10.2C293.7,257.5,293.9,250.5,294.1,243.5z"
            />

            <ellipse
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -42.9132 243.6024)"
                className="st13"
                cx="272.6"
                cy="173.6"
                rx="2.7"
                ry="2.7"
            />
            <path
                className="st14"
                d="M180,247.4c1.1-1.9,7-11.5,8.1-13.3c0,0,0.3-0.4,0.3-0.4c-0.4-15.7-0.4-31.2-0.4-46.7c-2.3,1.8-4.7,3.5-7,5.3
                        C180.7,210.6,180.4,229,180,247.4z"
            />
            <path className="st15" d="M156.8,177.7" />
            <path
                className="st16"
                d="M162.3,173.2c8.6,4.3,17.2,8.5,25.8,12.8c-2.3,2.1-4.7,4.2-7,6.3c-9.5-5-19-10-28.5-15
                        C155.8,175.8,159.1,174.5,162.3,173.2z"
            />
            <polygon className="st11" points="286,206.5 280.6,211.3 280.8,173.8 285.6,169.2 	" />
            <path
                className="st11"
                d="M262.8,151.5c1.4-1.1,2.8-2.3,4.2-3.4c-9.9-5.8-19.8-11.7-29.7-17.5c-1.1,1.1-2.3,2.2-3.4,3.3
                        C243.6,139.7,253.2,145.6,262.8,151.5z"
            />
            <polygon className="st11" points="229.4,207 189.1,207.6 189.1,203.3 231.2,202.1 	" />
            <polygon className="st11" points="181.9,243 180.7,245.1 181.6,192.2 183.2,191.5 	" />
            <path
                className="st17"
                d="M256.9,199c1.2,2,0.7,4.6-1.2,5.6c-1.8,1.1-4.3,0.3-5.5-1.8c-1.2-2-0.7-4.6,1.2-5.6
                        C249.3,198.5,254.4,194.7,256.9,199z"
            />
            <path
                className="st18"
                d="M248.1,208.1c3.3-0.5,4.2-0.2,4.4,0.2c0.5,1-3.3,3.5-6.2,5c-4.8,2.4-9.3,2.8-9.3,2.5
                        c-0.1-0.3,3.7-0.8,7.5-3.8C246.1,210.6,247.3,209.2,248.1,208.1z"
            />
            <path
                className="st18"
                d="M231,185.1c-1.4,0.3-2.7,0.3-3.2-0.4c-1-1.5,2.1-5.4,4.2-7.6c3.6-3.7,7.7-5.3,7.8-5.1
                        c0.1,0.2-3.6,1.7-6.2,5.4C231.8,180.1,231.2,182.9,231,185.1z"
            />
            <ellipse transform="matrix(0.866 -0.5 0.5 0.866 -39.6501 166.2504)" className="st13" cx="290.4" cy="157.1" rx="2.7" ry="2.7" />
            <ellipse transform="matrix(0.866 -0.5 0.5 0.866 -44.6445 162.9442)" className="st13" cx="281.7" cy="164.8" rx="2.7" ry="2.7" />
            <ellipse transform="matrix(0.866 -0.5 0.5 0.866 -34.8059 169.3289)" className="st13" cx="298.6" cy="149.6" rx="2.7" ry="2.7" />
            <ellipse transform="matrix(0.866 -0.5 0.5 0.866 -30.0686 172.0948)" className="st13" cx="306.1" cy="142.2" rx="2.7" ry="2.7" />
            <ellipse transform="matrix(0.866 -0.5 0.5 0.866 -25.853 174.7898)" className="st13" cx="313.2" cy="135.6" rx="2.7" ry="2.7" />
            <ellipse transform="matrix(0.866 -0.5 0.5 0.866 -20.9195 177.4871)" className="st13" cx="320.7" cy="127.8" rx="2.7" ry="2.7" />
            <ellipse transform="matrix(0.866 -0.5 0.5 0.866 -15.8064 180.535)" className="st13" cx="329" cy="119.8" rx="2.7" ry="2.7" />
            <ellipse transform="matrix(0.866 -0.5 0.5 0.866 -10.8716 183.5077)" className="st13" cx="337" cy="112" rx="2.7" ry="2.7" />
            <path
                d="M369.4,61.2c3-0.1,5.9-0.3,8.9-0.4c0.4,0.6,1.6,2.9,1.1,5.9c-0.8,4-4.2,5.8-4.6,6c-0.7-0.6-1.4-1.2-2.2-1.8
                        c0.2-0.4,1.6-3.1,0.3-6.1C371.9,62.4,369.9,61.4,369.4,61.2z"
            />
            <ellipse transform="matrix(0.866 -0.5 0.5 0.866 -6.2315 186.7365)" className="st13" cx="345.3" cy="105" rx="2.7" ry="2.7" />
            <ellipse transform="matrix(0.866 -0.5 0.5 0.866 -1.6296 189.2515)" className="st13" cx="352.3" cy="97.7" rx="2.7" ry="2.7" />
            <ellipse transform="matrix(0.866 -0.5 0.5 0.866 3.3576 192.5562)" className="st13" cx="361" cy="90" rx="2.7" ry="2.7" />
            <ellipse transform="matrix(0.866 -0.5 0.5 0.866 8.4775 195.487)" className="st13" cx="369" cy="81.9" rx="2.7" ry="2.7" />
        </g>
        <g id="Arms">
            <line className="st19" x1="131.2" y1="76.3" x2="72.5" y2="178.4" />
            <path className="st20" d="M19,180.8c-10.2-11.3-11.6-12.8-7.7-8.5" />
            <path className="st21" d="M74,186.6c0,0-58.6,23.9-58,0.2" />
            <path
                className="st22"
                d="M70.2,174.7c-17.5,0-34.9,0-52.4,0c-6.4,0-6.4,10,0,10c17.5,0,34.9,0,52.4,0C76.6,184.7,76.6,174.7,70.2,174.7 L70.2,174.7z"
            />

            <line className="st19" x1="131.1" y1="165.5" x2="72.5" y2="267.5" />
            <path className="st20" d="M19,270c-10.2-11.3-11.6-12.8-7.7-8.5" />
            <path className="st21" d="M74,275.8c0,0-58.6,23.9-58,0.2" />
            <path
                className="st22"
                d="M70.2,263.8c-17.5,0-34.9,0-52.4,0c-6.4,0-6.4,10,0,10c17.5,0,34.9,0,52.4,0C76.6,273.8,76.6,263.8,70.2,263.8 L70.2,263.8z"
            />

            <line className="st19" x1="132" y1="256.9" x2="73.3" y2="359" />
            <path className="st20" d="M19.8,361.4c-10.2-11.3-11.6-12.8-7.7-8.5" />
            <path className="st21" d="M74.8,367.2c0,0-58.6,23.9-58,0.2" />
            <path
                className="st22"
                d="M71,355.2c-17.5,0-34.9,0-52.4,0c-6.4,0-6.4,10,0,10c17.5,0,34.9,0,52.4,0C77.4,365.2,77.4,355.2,71,355.2L71,355.2z"
            />

            <line className="st19" x1="404.3" y1="75.7" x2="462.9" y2="177.8" />
            <path className="st20" d="M516.5,180.2c10.2-11.3,11.6-12.8,7.7-8.5" />
            <path className="st21" d="M461.4,186c0,0,58.6,23.9,58,0.2" />
            <path
                className="st22"
                d="M465.3,184.1c17.5,0,34.9,0,52.4,0c6.4,0,6.4-10,0-10c-17.5,0-34.9,0-52.4,0C458.8,174.1,458.8,184.1,465.3,184.1 L465.3,184.1z"
            />

            <line className="st19" x1="404.3" y1="164.9" x2="462.9" y2="266.9" />
            <path className="st20" d="M516.5,269.4c10.2-11.3,11.6-12.8,7.7-8.5" />
            <path className="st21" d="M461.5,275.1c0,0,58.6,23.9,58,0.2" />
            <path
                className="st22"
                d="M465.3,273.2c17.5,0,34.9,0,52.4,0c6.4,0,6.4-10,0-10c-17.5,0-34.9,0-52.4,0C458.9,263.2,458.9,273.2,465.3,273.2 L465.3,273.2z"
            />

            <line className="st19" x1="403.5" y1="256.3" x2="462.1" y2="358.3" />
            <path className="st20" d="M515.7,360.8c10.2-11.3,11.6-12.8,7.7-8.5" />
            <path className="st21" d="M460.7,366.6c0,0,58.6,23.9,58,0.2" />
            <path
                className="st22"
                d="M464.5,364.6c17.5,0,34.9,0,52.4,0c6.4,0,6.4-10,0-10c-17.5,0-34.9,0-52.4,0C458.1,354.6,458,364.6,464.5,364.6 L464.5,364.6z"
            />
        </g>
    </BaseIcon>
);

export default Aviabit;
