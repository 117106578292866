import React from 'react';
import { get } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Panel } from '../../Panel/Panel';
import { sortPassengerTemplate } from '../../../service/sort';
import { PassengerInfoField } from '../PassengerInfoField/PassengerInfoField';
import MaterialTextField from '../../MaterialTextField/MaterialTextField';
import { useStyles } from './useStyles';
import { Typography } from '../../material-ui/Typography';
import { getCurrentSendedTakeOff } from '../../../selectors/sended-data';
import { editPassengerInfo } from '../../../actions/take-off';
import { useSingleConvert } from '../../hooks/useMeasureConverter';

const PassengerPanel = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const convertValue = useSingleConvert();
    const {
        showPassengerClasses = false,
        showPassengerLoad = false,
        showBaggage = false,
        showLuggage = false,
        showHandLuggage = false,
    } = useSelector((state) => get(state, 'routesDeclaration.options', {}));
    const passengerTemplates = useSelector((state) => get(state, 'login.user.data.passengersTemplates'), shallowEqual);
    const passengerInfo = useSelector((state) => {
        const takeOff = getCurrentSendedTakeOff(state);

        return takeOff.passengerInfo || {};
    }, shallowEqual);
    const handleEditPassengerInfo = React.useCallback(
        (id, passengerClass, passengerType, name, value) => {
            const template =
                passengerTemplates.find((item) => item.cls.id === passengerClass.id && item.type.id === passengerType.id) || {};
            dispatch(
                editPassengerInfo(
                    id,
                    passengerClass,
                    passengerType,
                    name,
                    value,

                    convertValue(template.weight)
                )
            );
        },
        [passengerTemplates, convertValue]
    );
    const data = React.useMemo(
        () =>
            passengerTemplates &&
            Object.keys(passengerTemplates)
                .sort(sortPassengerTemplate(passengerTemplates))
                .filter((item) => {
                    return passengerTemplates[item].cls.code === 1 || showPassengerClasses;
                }),
        [passengerTemplates, showPassengerClasses]
    );

    return (
        <Panel title="Passenger info" className={classes.wrapper} key={1}>
            <div className={classes.row}>
                {showPassengerClasses && <Typography className={classes.tableHeader}>Class</Typography>}
                <Typography className={classes.tableHeader}>Type</Typography>
                <Typography className={classes.tableHeader}>Number</Typography>
                {(showPassengerLoad || showBaggage) && <Typography className={classes.tableHeader}>Baggage</Typography>}
                {(showPassengerLoad || showLuggage) && <Typography className={classes.tableHeader}>Extra</Typography>}
                {(showPassengerLoad || showHandLuggage) && <Typography className={classes.tableHeader}>Cabin</Typography>}
            </div>
            {data.map((key) => {
                const passengerItem = passengerTemplates[key];
                const passengerItemData =
                    passengerInfo[
                        Object.keys(passengerInfo).filter((item) => {
                            const res = passengerInfo[item];

                            return (
                                res.type.id === passengerItem.type.id &&
                                res.cls.id === passengerItem.cls.id &&
                                (passengerItem.cls.code === 1 || showPassengerClasses)
                            );
                        })[0]
                    ] || {};

                return (
                    <div className={classes.row} key={key}>
                        {showPassengerClasses && (
                            <MaterialTextField
                                label=""
                                name="cls"
                                fullWidth={true}
                                key={`${passengerItem.cls.name}:passengerItem.cls.name`}
                                defaultValue={passengerItem.cls.name}
                                disabled={true}
                                inputProps={{ className: classes.input }}
                                // underlineDisabledStyle={styles.underlineDisabledStyle}
                                // inputStyle={styles.disabledInputStyle}
                                className={classes.field}
                            />
                        )}
                        <MaterialTextField
                            label=""
                            name="type"
                            disabled={true}
                            fullWidth={true}
                            key={`${passengerItem.type.name}:passengerItem.type.name`}
                            defaultValue={passengerItem.type.name}
                            inputProps={{ className: classes.input }}
                            // underlineDisabledStyle={styles.underlineDisabledStyle}
                            // inputStyle={styles.disabledInputStyle}
                            className={classes.field}
                        />
                        <PassengerInfoField
                            key={`${key}:passengerItemData.number`}
                            name={'number'}
                            passengerInfo={passengerItem}
                            defaultValue={passengerItemData.number}
                            editPassengerInfo={handleEditPassengerInfo}
                            recordId={key}
                            // styles={styles}
                            validator={{ type: 'smallint', min: 0, max: 853 }}
                        />
                        {(showPassengerLoad || showBaggage) && (
                            <PassengerInfoField
                                key={`${key}:passengerItemData.baggage`}
                                name={'baggage'}
                                passengerInfo={passengerItem}
                                defaultValue={passengerItemData.baggage}
                                editPassengerInfo={handleEditPassengerInfo}
                                recordId={key}
                                // styles={styles}
                                validator={{ type: 'decimal', min: 0, max: 32000, precision: [7, 2] }}
                            />
                        )}
                        {(showPassengerLoad || showLuggage) && (
                            <PassengerInfoField
                                key={`${key}:passengerItemData.luggage`}
                                name={'luggage'}
                                passengerInfo={passengerItem}
                                defaultValue={passengerItemData.luggage}
                                editPassengerInfo={handleEditPassengerInfo}
                                recordId={key}
                                // styles={styles}
                                validator={{ type: 'decimal', min: 0, max: 32000, precision: [7, 2] }}
                            />
                        )}
                        {(showPassengerLoad || showHandLuggage) && (
                            <PassengerInfoField
                                key={`${key}:passengerItemData.handLuggage`}
                                name={'handLuggage'}
                                passengerInfo={passengerItem}
                                defaultValue={passengerItemData.handLuggage}
                                editPassengerInfo={handleEditPassengerInfo}
                                recordId={key}
                                // styles={styles}
                                validator={{ type: 'decimal', min: 0, max: 32000, precision: [7, 2] }}
                            />
                        )}
                    </div>
                );
            })}
        </Panel>
    );
};

const PassengerPanelMemoized = React.memo(PassengerPanel);

export { PassengerPanelMemoized as PassengerPanel };
