import { get } from 'lodash';

import { showConfirmDialog } from './messageDialog';
import { createImage, getImageUrl } from './images';
import { dataBufferToBlob, uint8ArrayToBlob } from '../service/ImageUtils';
import { MessageDialogTypes } from '../components/MessageDialog/MessageDialog';
import { getFileExtensions, isAndroid } from '../service/utils';
import { showModal, TYPES } from './modal';
import { sortObjByField } from '../service/sort';
import { checkCaptain } from '../selectors/screen';
import { TelegramPrefix } from '../service/constants';

const SIGN_TELEGRAM = 'SIGN_TELEGRAM';
const MAX_TELEGRAM_SIGN_RESOLUTION = 400;
const TELEGRAM_ATTACHMENT_FOLDER = 'telexAttachments';

function createTelegram(id, text, contentType = 'text/plain') {
    return (dispatch, getState, { plugins }) => {
        const blob = new Blob([text], { type: contentType });

        return plugins.file.write(id, blob).then(() => {
            return id;
        });
    };
}

function fileConverter(data, contentType) {
    if (data instanceof Uint8Array) {
        return uint8ArrayToBlob(data, contentType);
    } else {
        return dataBufferToBlob(data.data, contentType);
    }
}

function createTelegramAttachment({ id, data, contentType = 'application/pdf' }, dispatch, plugins) {
    const blob = fileConverter(data, contentType);

    return plugins.file[isAndroid() ? 'writeAndroidDownloadFile' : 'write'](
        `${TELEGRAM_ATTACHMENT_FOLDER}_${id}${getFileExtensions(contentType)}`,
        blob,
        TELEGRAM_ATTACHMENT_FOLDER
    ).then(() => id);
}

function getTelegram({ id, name, showPreview = true, ofpId }) {
    return (dispatch, getState, { plugins }) => {
        return new Promise((res) => {
            const state = getState();
            const {
                telegrams,
                screen: { currentLeg },
                sendedData,
            } = state;
            const telegram = telegrams[id];
            const isCfp = telegram && telegram.name === 'CFP';
            const signComment = get(sendedData[currentLeg], 'signComment');
            const signatureName = isCfp ? get(sendedData[currentLeg], 'signature') : `sign_telegram_${id}`;
            const isCurrentUserCaptain = checkCaptain(state);
            const { attachments = {} } = telegram || {};

            return Promise.all([Promise.resolve(attachments), dispatch(preloadTelegramSignatureUrl(signatureName))])
                .then(([attachments]) => {
                    const { images } = getState();
                    const signaturePreview = showPreview && signatureName && get(images, `[${signatureName}].url`);

                    return plugins.file.readFileAsString(`telegram_${id}`).then((text) => {
                        dispatch(
                            showModal(TYPES.TELEGRAM_VIEWER, {
                                title: name,
                                message: text,
                                signaturePreview,
                                // Показываем только если не OFP или конкретная OFP
                                isPreviewEditable: !isCfp || !!ofpId,
                                signatureName: signatureName,
                                attachments,
                                ofpId,
                                ...(isCfp && { signComment }),
                                isCurrentUserCaptain,
                            })
                        );
                        res();
                    });
                })
                .catch((err) => {
                    console.log(err);
                    const msg = {
                        title: 'Show telegram error',
                        message: 'Cannot show telegram. Please try again later',
                        btnCancelVisibility: false,
                    };
                    dispatch(showConfirmDialog(msg));
                });
        });
    };
}

function signTelegram(signature, signatureName = '') {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const state = getState();
            const { data, contentType } = signature;
            const id = signatureName && (signatureName.match(/[0-9]+$/g) || [])[0];
            const telegramId = get(state.telegrams[id], 'id');

            return (
                id && telegramId
                    ? dispatch(
                          createImage(signatureName, data, contentType, {
                              createImmediately: true,
                              rewrite: true,
                              needCompression: true,
                              maxResolution: MAX_TELEGRAM_SIGN_RESOLUTION,
                          })
                      )
                    : Promise.reject()
            )
                .then(() => {
                    const {
                        images,
                        screen: { currentLeg },
                    } = getState();
                    const url = get(images, `[${signatureName}].url`);

                    dispatch({
                        type: SIGN_TELEGRAM,
                        id: telegramId,
                        signature: signatureName,
                        signaturePreview: url,
                        legId: currentLeg,
                    });

                    return resolve(url);
                })
                .catch((err) => {
                    dispatch(
                        showConfirmDialog({
                            title: 'Sign telegram error',
                            message: 'Cannot sign telegram. Please try again later',
                            btnCancelVisibility: false,
                        })
                    );
                    console.log(err);
                    reject();
                });
        });
    };
}

function preloadTelegramSignatureUrl(id) {
    return (dispatch, getState) => {
        const signature = getState().images[id];
        if (signature) {
            return dispatch(getImageUrl(id));
        }

        return Promise.resolve();
    };
}

function showAttachment(id, onShow, extension, folder = TELEGRAM_ATTACHMENT_FOLDER) {
    const fileName = `${TelegramPrefix.ATTACHMENT}${id}`;
    return (dispatch, getState, { plugins }) => {
        plugins.pdf.show(fileName, onShow, extension, folder).catch(() => {
            setTimeout(
                () =>
                    dispatch(
                        showConfirmDialog({
                            title: 'Error message',
                            message: "Can't open a file. Maybe it is not loaded.",
                            btnCancelVisibility: false,
                            options: {
                                type: MessageDialogTypes.ERROR,
                            },
                        })
                    ),
                1000
            );
        });
    };
}

function getAttachments(attachments = {}) {
    return Object.keys(attachments)
        .map((id) => {
            const { name, changeTime, contentType } = attachments[id] || {};
            const extension = getFileExtensions(contentType);
            const disabled = !extension;

            return {
                id,
                name,
                changeTime,
                disabled,
                extension,
            };
        })
        .sort(sortObjByField('name'));
}

export {
    SIGN_TELEGRAM,
    createTelegram,
    getTelegram,
    signTelegram,
    preloadTelegramSignatureUrl,
    createTelegramAttachment,
    showAttachment,
    getAttachments,
};
