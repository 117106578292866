import { LOGOUT } from '../actions/screen';
import { SET_WIZARD_STEP } from '../actions/wizard';
import { SET_CURRENT_LEG } from '../actions/legs';

const initialState = {
    wizardStep: 0,
    prevWizardStep: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_WIZARD_STEP:
            return Object.assign({}, state, { wizardStep: action.step, prevWizardStep: state.wizardStep });
        case SET_CURRENT_LEG:
            return Object.assign({}, state, { wizardStep: 0, prevWizardStep: null });
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};
