import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
    return {
        text: {
            paddingRight: 50,
        },
        menu: {
            right: '8px',
        },
    };
});

export { useStyles };
