import React from 'react';

import { IconButton } from '../../material-ui/IconButton';
import { MoreVertIconContrast } from '../../../icons/MoreVertIconContrast';
import { Menu, MenuItem } from '../../material-ui/Menu';
import { Typography } from '../../material-ui/Typography';
import { stopPropagation } from '../../../service/eventHandlers';
import { useAnchorEl } from '../../hooks/useAnchorEl';

const LibraryItemMenu = React.forwardRef((props, ref) => {
    const { onDownload, onRemove } = props;
    const [anchorEl, handleOpenMenu, handleClose] = useAnchorEl();
    const menuListStyle = {
        fontSize: '18px',
    };
    const handleDownload = React.useCallback((evt) => {
        stopPropagation(evt);
        onDownload();
        handleClose();
    }, []);

    const handleRemove = React.useCallback((evt) => {
        stopPropagation(evt);
        onRemove();
        handleClose();
    }, []);

    return (
        <span ref={ref}>
            <IconButton
                style={{
                    width: '48px',
                    height: '48px',
                    padding: 0,
                }}
                onClick={handleOpenMenu}
            >
                <MoreVertIconContrast />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <MenuItem style={menuListStyle} onClick={handleDownload}>
                    <Typography>Download</Typography>
                </MenuItem>
                <MenuItem style={menuListStyle} onClick={handleRemove}>
                    <Typography>Remove</Typography>
                </MenuItem>
            </Menu>
        </span>
    );
});

const LibraryItemMenuMemoized = React.memo(LibraryItemMenu);

export { LibraryItemMenuMemoized as LibraryItemMenu };
