import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    formControl: {
        top: theme.spacing(-1),
        left: theme.spacing(1.5),
    },
    legend: {
        fontSize: '18px',
    },
    selectIcon: {
        right: '7px',
    },
}));
