import { getPrevLeg } from '../selectors/screen';

const EDIT_TAKE_OFF_INFORMATION_FIELD = 'EDIT_TAKE_OFF_INFORMATION_FIELD';
const ON_CHANGE_TAKE_OFF_TIME = 'ON_CHANGE_TAKE_OFF_TIME';
const EDIT_TAKE_OFF_FIELD = 'EDIT_TAKE_OFF_FIELD';
const EDIT_PASSENGER_INFO = 'EDIT_PASSENGER_INFO';
const EDIT_LOAD_INFO = 'EDIT_LOAD_INFO';
const TOGGLE_TOTAL_EDITABLE = 'TOGGLE_TOTAL_EDITABLE';

function editInformationField(name, value) {
    return (dispatch, getState) => {
        const { currentUser, currentLeg } = getState().screen;
        return dispatch({
            type: EDIT_TAKE_OFF_INFORMATION_FIELD,
            name,
            value,
            legId: currentLeg,
            userId: currentUser.id,
        });
    };
}

function onChangeTime(name, value) {
    return (dispatch, getState) => {
        const state = getState();
        const { currentUser, currentLeg } = state.screen;
        const prevLeg = getPrevLeg(state);

        return dispatch({
            type: ON_CHANGE_TAKE_OFF_TIME,
            name,
            value: value && new Date(value).toISOString(),
            legId: currentLeg,
            userId: currentUser.id,
            prevLegId: prevLeg && prevLeg.id,
        });
    };
}

function editField(name, value) {
    return (dispatch, getState) => {
        const { currentUser, currentLeg } = getState().screen;
        return dispatch({
            type: EDIT_TAKE_OFF_FIELD,
            name,
            value: value !== undefined && value !== null ? value : null,
            legId: currentLeg,
            userId: currentUser.id,
        });
    };
}

function editPassengerInfo(id, passengerClass, passengerType, name, value, weight = 0) {
    return (dispatch, getState) => {
        const { currentUser, currentLeg } = getState().screen;

        return dispatch({
            type: EDIT_PASSENGER_INFO,
            id,
            passengerClass,
            passengerType,
            name,
            value,
            weight,
            legId: currentLeg,
            userId: currentUser.id,
        });
    };
}

function editLoadInfo(id, loadType, name, value) {
    return (dispatch, getState) => {
        const { currentUser, currentLeg } = getState().screen;

        return dispatch({
            type: EDIT_LOAD_INFO,
            id,
            loadType,
            name,
            value,
            legId: currentLeg,
            userId: currentUser.id,
        });
    };
}

function toggleTotalEditable() {
    return (dispatch, getState) => {
        const { currentLeg } = getState().screen;

        return dispatch({ type: TOGGLE_TOTAL_EDITABLE, legId: currentLeg });
    };
}

export {
    EDIT_TAKE_OFF_INFORMATION_FIELD,
    ON_CHANGE_TAKE_OFF_TIME,
    EDIT_TAKE_OFF_FIELD,
    EDIT_PASSENGER_INFO,
    EDIT_LOAD_INFO,
    TOGGLE_TOTAL_EDITABLE,
    editInformationField,
    onChangeTime,
    editField,
    editPassengerInfo,
    editLoadInfo,
    toggleTotalEditable,
};
