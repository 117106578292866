import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    icon: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        marginRight: theme.spacing(1),
        padding: 0,
    },
    listItem: {
        fontSize: '18px',
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
    },
    text: {
        lineHeight: 2,
    },
}));

export { useStyles };
