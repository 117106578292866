import React from 'react';

import BaseIcon from '../BaseIcon';

const RightRefresh = (props) => (
    <BaseIcon {...props} viewBox="0 0 400 399.999">
        <path
            d="M361.796,209.654c-7.887,0-14.279,6.393-14.279,14.275c0,81.342-66.172,147.518-147.519,147.518
            c-81.342,0-147.515-66.176-147.515-147.518c0-76.521,58.575-139.585,133.239-146.796v32.863c0,5.099,2.725,9.812,7.137,12.362
            c2.21,1.274,4.677,1.914,7.139,1.914c2.466,0,4.927-0.639,7.136-1.914l82.904-47.857c4.42-2.548,7.137-7.26,7.137-12.361
            c0-5.1-2.717-9.815-7.137-12.363L207.134,1.913c-4.418-2.551-9.855-2.551-14.275,0c-4.412,2.548-7.137,7.264-7.137,12.363v34.212
            c-90.426,7.298-161.791,83.167-161.791,175.44c0,97.085,78.984,176.07,176.066,176.07c97.088,0,176.071-78.985,176.071-176.07
            C376.068,216.047,369.679,209.654,361.796,209.654z"
        />
    </BaseIcon>
);

export default RightRefresh;
