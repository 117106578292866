import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { Status } from '../SidebarLegItem/SidebarLegItem';
import { SidebarFlightItem } from '../SidebarFlightItem/SidebarFlightItem';
import { getSortedFlights, getUserLegs } from '../../../selectors/screen';
import { getSendedLegs } from '../../../selectors/sended-data';
import { setCurrentLeg } from '../../../actions/legs';
import { redirectPage } from '../../../actions/screen';

function SidebarFlights(props) {
    const { onClickFlight, onCloseSidebar } = props;
    const dispatch = useDispatch();
    const flights = useSelector(getSortedFlights);
    const legsWithChanges = useSelector(getSendedLegs);
    const userLegs = useSelector(getUserLegs);
    const canUseCfpWeightUnits = useSelector((state) => _.get(state, 'systemOfMeasurement.canUseCfpWeightUnits', false));

    const handleClickSidebarItem = React.useCallback(
        (legId, path) => {
            dispatch(setCurrentLeg(legId));
            dispatch(redirectPage(path));
            onCloseSidebar();
        },
        [canUseCfpWeightUnits]
    );

    const handleGetLegStatus = React.useCallback(
        (id) => {
            const leg = legsWithChanges[id];
            if (leg) {
                return leg.hasChanges ? Status.EDIT : Status.SEND;
            }

            return Status.NONE;
        },
        [legsWithChanges]
    );

    return flights ? (
        flights.map((flight) => (
            <SidebarFlightItem
                key={flight.id}
                getLegStatus={handleGetLegStatus}
                onClickSidebarItem={handleClickSidebarItem}
                {...{ userLegs, onClickFlight, flight }}
            />
        ))
    ) : (
        <></>
    );
}

const SidebarFlightsMemoized = React.memo(SidebarFlights);

export { SidebarFlightsMemoized as SidebarFlights };
