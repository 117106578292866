import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import { get } from 'lodash';

import { PrivateRoute } from '../service/PrivateRoute';
import { FlightTaskPage } from '../components/FlightTaskPage/FlightTaskPage';
import { FlightPage } from '../components/FlightPage/FlightPage';
import { DocumentsPage } from '../components/DocumentsPage/DocumentsPage';
import { FlightWizardPage } from '../components/FlightWizardPage/FlightWizardPage';
import { ReportsPage } from '../components/ReportsPage/ReportsPage';
import { EmployersPage } from '../components/EmployersPage/EmployersPage';
import { LibraryPage } from '../components/LibraryPage/LibraryPage';
import { PassengerPage } from '../components/PassengerPage/PassengerPage';
import { SummaryTaskPage } from '../components/SummaryTaskPage/SummaryTaskPage';

const MainContainer = () => {
    const isAuthenticated = useSelector((state) => {
        const userId = get(state, 'login.user.id');

        return !!userId || false;
    });

    return (
        <Switch>
            <PrivateRoute path="/flight-task" component={FlightTaskPage} auth={isAuthenticated} />
            <PrivateRoute path="/flight" component={FlightPage} auth={isAuthenticated} />
            <PrivateRoute path="/documents" component={DocumentsPage} auth={isAuthenticated} />
            <PrivateRoute path="/flight-info" component={FlightWizardPage} auth={isAuthenticated} />
            <PrivateRoute path="/reports" component={ReportsPage} auth={isAuthenticated} />
            <PrivateRoute path="/crew" component={EmployersPage} auth={isAuthenticated} />
            <PrivateRoute path="/library" component={LibraryPage} auth={isAuthenticated} />
            <PrivateRoute path="/passengers" component={PassengerPage} auth={isAuthenticated} />
            <PrivateRoute path="/summary-task" component={SummaryTaskPage} auth={isAuthenticated} />
            {isAuthenticated ? <Redirect to="/flight-task" push /> : <Redirect to="/login" push />}
        </Switch>
    );
};

const MainContainerMemoized = React.memo(MainContainer);

export { MainContainerMemoized as MainContainer };
