import { STATE_COUNT } from './StateManager';
import { DocumentPrefix, MessageType } from './constants';
import { getErrorMessage, isAndroid } from './utils';
import errorLogsSender from './ErrorLogsSender';

class ApiManager {
    constructor(backendFacade, plugins) {
        this.__backend = backendFacade;
        this.__plugins = plugins;
    }

    setNewBackendFacade(backendFacade) {
        this.__backend = backendFacade;
    }

    /**
     * Сохраняет редактируемую информацию на бэкенд
     * @param userId
     * @param legsData
     * @param reports
     */
    sendData(userId, legsData, taskSignatures, telegramSignatures) {
        return this.__backend
            .post('set-data', {
                userId,
                legsData,
                taskSignatures,
                telegramSignatures,
            })
            .catch((err) => {
                // промис c участием не должен заваливаться.
                console.log(err);
                const error = new Error(getErrorMessage(err, MessageType.SEND_DATA_API), { cause: { err } });
                errorLogsSender.addLog(error);

                return {
                    error: true,
                };
            });
    }

    sendOtherData(userId, taskSignatures, telegramSignatures, ofpSignatureList, legsData) {
        return this.__backend
            .post('set-short', {
                userId,
                taskSignatures,
                telegramSignatures,
                ofpSignatureList,
                legsData,
            })
            .catch((err) => {
                // промис c участием не должен заваливаться.
                console.log(err);
                const error = new Error(getErrorMessage(err, MessageType.SEND_OTHER_DATA_API), { cause: { err } });
                errorLogsSender.addLog(error);

                return {
                    error: true,
                };
            });
    }

    sendReport(userId, report) {
        return this.__backend.post('report', { userId, report }).catch((err) => {
            // промис c участием не должен заваливаться.
            console.log(err);
            const error = new Error(getErrorMessage(err, MessageType.SEND_REPORT_API), { cause: { err } });
            errorLogsSender.addLog(error);

            return {
                error: true,
            };
        });
    }

    removeReports(userId, reportIds) {
        if (!reportIds) {
            return Promise.resolve();
        }

        return this.__backend.delete('reports', { userId, reportIds }).catch((err) => {
            // промис c участием не должен заваливаться.
            console.log(err);
            const error = new Error(getErrorMessage(err, MessageType.REMOVE_REPORT_API), { cause: { err } });
            errorLogsSender.addLog(error);

            return {
                reportIds,
                error: true,
            };
        });
    }

    /**
     * Загружает данные полетного задания
     */
    getTaskData(id, userId, syncTime) {
        return this.__backend.post('task-data', { userId, id, syncTime }).catch((err) => {
            console.log(err);
            const error = new Error(getErrorMessage(err, MessageType.GET_TASK_DATA_API), { cause: { err } });
            errorLogsSender.addLog(error);
            throw error;
            // throw Error('Failed to load the task data');
        });
    }

    /**
     * Загружает данные пользователя
     */
    getUserData(userId) {
        return this.__backend.post('user-data', { userId }).catch((err) => {
            console.log(err);
            const error = new Error(getErrorMessage(err, MessageType.GET_USER_DATA_API), { cause: { err } });
            errorLogsSender.addLog(error);
            throw error;
            // throw Error('Failed to load the user\'s info');
        });
    }

    /**
     * Загружает доклады пользователя
     */
    getUserReports(userId, dateFrom, dateTo) {
        return this.__backend.post('user-data/reports', { userId, dateFrom, dateTo }).catch((err) => {
            console.log(err);
            const error = new Error(getErrorMessage(err, MessageType.GET_USER_REPORTS_API), { cause: { err } });
            errorLogsSender.addLog(error);
            throw error;
            // throw Error('Failed to load the user\'s reports');
        });
    }

    /**
     * Загружает данные пользователя
     */
    getUserPlanFlight(userId) {
        return this.__backend
            .post('user-data/plan-flights', { userId })
            .then(() => {
                throw new Error('Text error');
            })
            .catch((err) => {
                console.log(err);
                throw new Error(getErrorMessage(err, MessageType.GET_USER_PLAN_FLIGHT_API), { cause: { err } });
                // throw Error('Failed to load the user\'s plan flights');
            });
    }

    /**
     * Загружает библиотеку пользователя
     */
    getLibrary(userId, syncTime) {
        return this.__backend.get(`library/${userId}${syncTime ? '/' + syncTime : ''}`).catch((err) => {
            console.log(err);
            const error = new Error(getErrorMessage(err, MessageType.GET_LIBRARY_API), { cause: { err } });
            errorLogsSender.addLog(error);
            throw error;
            // throw Error('Failed to load the library data');
        });
    }

    /**
     * Возвращает список полетных заданий
     */
    getTasksList(userId) {
        return this.__backend.get(`tasks/${userId}`).catch((err) => {
            console.log(err);
            const error = new Error(getErrorMessage(err, MessageType.GET_TASK_LIST_API), { cause: { err } });
            errorLogsSender.addLog(error);
            throw error;
            // throw Error('Failed to load the user\'s task list');
        });
    }

    downloadDefectFile(id) {
        return this.__backend
            .getFile(`defect/${id}`)
            .then((file) => {
                // if (file.size > 15000000) {
                //     throw Error(`The loading file ${id} is too large`);
                // }
                return this.__plugins.file[isAndroid() ? 'writeAndroidDownloadFile' : 'write'](`${DocumentPrefix.DEFECT}${id}.pdf`, file);
            })
            .catch((err) => {
                console.log(err);
                const error = new Error(getErrorMessage(err, MessageType.GET_DEFECT_DOC_API), { cause: { err } });
                errorLogsSender.addLog(error);
                throw error;
                // throw Error('Failed to download defect document');
            });
    }

    getFileList(path) {
        return this.__plugins.file
            .getFilesList(path)
            .then((list) => {
                // if(file.size > 15000000) {
                //     throw Error(`The loading file ${id} is too large`);
                // }

                return list;
            })
            .catch((err) => {
                console.log(err);
                const error = new Error(getErrorMessage(err, MessageType.GET_DOC_API), { cause: { err } });
                errorLogsSender.addLog(error);
                throw error;
            });
    }

    getDirectoryList(path) {
        return this.__plugins.file
            .getDirectoryList(path)
            .then((list) => list)
            .catch((err) => {
                console.log(err);
                const error = new Error(getErrorMessage(err, MessageType.GET_DIRECTORY_LIST_API), { cause: { err } });
                errorLogsSender.addLog(error);
                throw error;
            });
    }

    removeFile(fileName) {
        return this.__plugins.file
            .remove(fileName)
            .then(() => fileName)
            .catch((err) => {
                console.log(err);
                const error = new Error(getErrorMessage(err, MessageType.REMOVE_DOC_API), { cause: { err } });
                errorLogsSender.addLog(error);
                throw error;
            });
    }

    removeDirectory(dirName) {
        return this.__plugins.file
            .removeDirectory(dirName)
            .then(() => dirName)
            .catch((err) => {
                console.log(err);
                const error = new Error(getErrorMessage(err, MessageType.REMOVE_DIR_API), { cause: { err } });
                errorLogsSender.addLog(error);
                throw error;
            });
    }

    renameFile(currentName, newName, path) {
        return this.__plugins.file.rename(currentName, newName, path).catch((err) => {
            console.log(err);
            const error = new Error(getErrorMessage(err, MessageType.RENAME_FILE_API), { cause: { err } });
            errorLogsSender.addLog(error);
            throw error;
        });
    }

    downloadFile(id) {
        return this.__backend
            .getFile(`document/${id}`)
            .then((file) => {
                // if(file.size > 15000000) {
                //     throw Error(`The loading file ${id} is too large`);
                // }
                return this.__plugins.file[isAndroid() ? 'writeAndroidDownloadFile' : 'write'](`${id}.pdf`, file);
            })
            .catch((err) => {
                console.log(err);
                //throw Error('Failed to download document');
                const error = new Error(getErrorMessage(err, MessageType.GET_FILE_API), { cause: { err } });
                errorLogsSender.addLog(error);
                throw error;
            });
    }

    downloadLibraryItem(userId, id) {
        return this.__backend
            .getFile(`libraryItem/${userId}/${id}`)
            .then((file) => {
                // if (file.size > 15000000) {
                //     throw Error(`The loading file ${id} is too large`);
                // }
                return this.__plugins.file[isAndroid() ? 'writeAndroidDownloadFile' : 'write'](`${DocumentPrefix.LIBRARY}${id}.pdf`, file);
            })
            .catch((err) => {
                console.log(err);
                const error = new Error(getErrorMessage(err, MessageType.GET_LIBRARY_DOC_API), { cause: { err } });
                errorLogsSender.addLog(error);
                throw error;
                // throw Error('Failed to download library document');
            });
    }

    downloadRoutesDeclaration() {
        return this.__backend.get('routesDeclaration').catch((err) => {
            console.log(err);
            const error = new Error(getErrorMessage(err, MessageType.GET_NAV_ROUTE_DECLARAION_API), { cause: { err } });
            errorLogsSender.addLog(error);
            throw error;
            // throw Error('Failed to download navigation route declaration');
        });
    }

    sendLog(fileName, content) {
        this.__plugins.file.remove(fileName);
        return this.__backend.post('log-upload', { content }).catch((err) => {
            const error = new Error(getErrorMessage(err, MessageType.SEND_LOG_API), { cause: { err } });
            errorLogsSender.addLog(error);
        });
    }

    sendState(userId, name, data) {
        return this.__backend
            .post('state', { userId, name, data })
            .then((res) => res)
            .catch((err) => {
                console.log(err);
                const error = new Error(getErrorMessage(err, MessageType.SEND_STATE_API), { cause: { err } });
                errorLogsSender.addLog(error);
                return {
                    error: true,
                    message: err.message,
                };
            });
    }

    /**
     * Загружает НОТАМ
     */
    getNotice(code, syncTime) {
        return this.__backend.get(`notice/${code}${syncTime ? '/' + syncTime : ''}`).catch((err) => {
            console.log(err);
            const error = new Error(getErrorMessage(err, MessageType.GET_NOTICE_DATA_API), { cause: { err } });
            errorLogsSender.addLog(error);
            throw error;
            // throw Error('Failed to load the library data');
        });
    }

    /**
     * Загружает информацию по аэропорту
     */
    getAirportInfo(code) {
        return this.__backend.get(`notice-airport/${code}`).catch((err) => {
            console.log(err);
            const error = new Error(getErrorMessage(err, MessageType.GET_AIRPORT_INFO_API), { cause: { err } });
            errorLogsSender.addLog(error);
            throw error;
            // throw Error('Failed to load the library data');
        });
    }

    /**
     * Загружает чек-лист для участка
     */
    getCheckListData(legId, userId, crewType) {
        return this.__backend.post('checklist-data', { legId, userId, crewType }).catch((err) => {
            console.log(err);
            const error = new Error(getErrorMessage(err, MessageType.GET_CHECKLIST_DATA_API), { cause: { err } });
            errorLogsSender.addLog(error);
            throw error;
        });
    }

    login(username, password, confirmationCode, version) {
        const { device } = window;

        return this.__backend.post('auth/login', { username, password, confirmationCode, version, device });
    }

    logout(userId, version) {
        const { device } = window;

        this.__plugins.file.remove('state');
        for (let i = 1; i <= STATE_COUNT; i++) {
            this.__plugins.file.remove(`state${i}`);
        }
        return this.__backend.post('auth/logout', { userId, version, device });
    }
}

export default ApiManager;
