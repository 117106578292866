import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
    return {
        disabledIcon: {
            opacity: 0.5,
        },
        stepper: {
            display: 'flex',
            justifyContent: 'space-around',
            padding: theme.spacing(0, 2),
            alignItems: 'stretch',
            flexGrow: 1,
            height: 56,
        },
        stepperWrapper: {
            position: 'fixed',
            display: 'flex',
            width: '100%',
            margin: '0 auto',
            flexDirection: 'row',
            placeContent: 'center',
            alignItems: 'center',
            top: 110,
            zIndex: 11,
            boxShadow: theme.shadows[1],
        },
    };
});

export { useStyles };
