import { useDispatch } from 'react-redux';

const SET_FILTERS = 'SET_FILTERS';
const RESET_FILTERS = 'RESET_FILTERS';
const RESET_FILTERS_DATES = 'RESET_FILTERS_DATES';

const resetFiltersDates = (filterType) => ({ filterType, type: RESET_FILTERS_DATES });

const setFilters = (filterType, values) => ({ filterType, values, type: SET_FILTERS });

const useSetFilter = (filterType) => {
    const dispatch = useDispatch();

    return (values) => dispatch(setFilters(filterType, values));
};

export {
    SET_FILTERS, //
    RESET_FILTERS,
    RESET_FILTERS_DATES,
    resetFiltersDates,
    setFilters,
    useSetFilter,
};
