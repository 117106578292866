import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    actionTime: {
        display: 'flex',
        flex: '1 1 30%',

        ['@media (max-width: 990px)']: {
            flex: '1 1 40%',
        },
    },
    wrapper: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
}));

export { useStyles };
