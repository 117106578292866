import React from 'react';
import * as moment from 'moment';
import { get } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';

import { List, ListItem, ListItemText } from '../../material-ui/List';
import { Ofp } from '../Ofp/Ofp';
import { getCurrentSendedLeg } from '../../../selectors/sended-data';

const OfpList = () => {
    const ofps = useSelector((state) => {
        const { currentLeg, currentTask, currentFlight } = state.screen;
        const leg = get(state, `tasks.list[${currentTask}].flights[${currentFlight}].legs[${currentLeg}]`);

        return get(leg, 'ofps');
    }, shallowEqual);
    const selectedOfpId = useSelector((state) => {
        const leg = getCurrentSendedLeg(state);
        return leg && +leg.ofpId;
    });
    const legPlnId = useSelector((state) => {
        const { currentLeg, currentTask, currentFlight } = state.screen;
        const leg = get(state, `tasks.list[${currentTask}].flights[${currentFlight}.legs[${currentLeg}]`);

        return get(leg, 'plnID');
    }, shallowEqual);

    return (
        <List>
            {ofps && Object.keys(ofps).length > 0 ? (
                Object.keys(ofps).map((id) => {
                    const ofp = ofps[id];
                    const isPlnChanged = legPlnId !== ofp.plnID;
                    const isCurrent = selectedOfpId === +id;

                    return (
                        <Ofp
                            key={id}
                            id={id}
                            telegramId={ofp.telegramId}
                            primary={
                                <span>
                                    <span className="opf__title">{ofp.name}</span>
                                    <span>{` ${ofp.date ? `(${moment.utc(ofp.date).format('DD.MM.YY HH:mm')})` : ''}`}</span>
                                </span>
                            }
                            secondary={ofp.comment}
                            isCurrent={isCurrent}
                            isPlnChanged={isPlnChanged}
                        />
                    );
                })
            ) : (
                <ListItem disabled={true}>
                    <ListItemText primary="There are not any OFP's" />
                </ListItem>
            )}
        </List>
    );
};

const OfpListMemoized = React.memo(OfpList);

export { OfpListMemoized as OfpList };
