import React from 'react';

import { Popover } from '../material-ui/Popover';
import { IconButton } from '../material-ui/IconButton';
import { useStyles } from './useStyles';
import { ListItemIcon } from '../material-ui/List';
import { useDataBackupStatus } from '../../redux-selectors/storeStage';
import { Typography } from '../material-ui/Typography';
import { useAnchorEl } from '../hooks/useAnchorEl';

const BackupStatus = () => {
    const [anchorEl, handleOpenMenu, handleClose] = useAnchorEl();
    const isActive = useDataBackupStatus();
    const classes = useStyles(isActive)();

    return (
        <div>
            <IconButton className={classes.iconButton} onClick={handleOpenMenu}>
                <ListItemIcon className={classes.icon}>
                    <div className="indicator" />
                </ListItemIcon>
            </IconButton>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                onClose={handleClose}
            >
                <div className="version__container">
                    <Typography>{isActive ? 'The data are saved' : 'Not all data are saved'}</Typography>
                </div>
            </Popover>
        </div>
    );
};

const BackupStatusMemoized = React.memo(BackupStatus);

export { BackupStatusMemoized as BackupStatus };
