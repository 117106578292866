import { cloneDeep, uniqBy, get, find } from 'lodash';
import md5 from 'md5';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { setDefaultFlight } from './flights';
import { redirectPage } from './screen';
import { setLoadingState } from './progressIndicator';
import { showConfirmDialog } from './messageDialog';
import { TASK_MODEL } from '../service/Models';
import { clearSendedDataList, updateSendedDataFromBackend } from './sended-data';
import mergeModel from './../service/mergeModel';
import { getLibrary } from './library';
import { createImage, getImageUrl } from './images';
import { updateUsers } from './users';
import { getCurrentTaskSelector } from '../selectors/screen';
import { getRoutesDeclaration } from './declaration';
import { getUserData } from './login';
import { UNAUTHORIZED_HTTP_CODE } from '../service/Error';
import { createTelegram, createTelegramAttachment } from './telegrams';
import { createTelex, createTelexAttachment } from './telex';
import { updateCrew } from './crew';
import appVersion from '../service/version';
import { checkDocumentFormat, getErrorMessage, getFileStorePath } from '../service/utils';
import { MessageDialogTypes } from '../components/MessageDialog/MessageDialog';
import { updateRouteAirports } from './notices';
import { showModal, TYPES } from './modal';
import { UPDATE_CHECKLIST } from './checkList';
import { Weight } from '../reducers/systemOfMeasurement';
import { convertFields, LoadType } from '../service/measureConverter';
import { DocumentPrefix, DocumentTypes, MessageType } from '../service/constants';
import { StepNumber } from '../components/FlightWizardPage/constants';
import { OtherError } from '../service/errors/OtherError';
import errorLogsSender from '../service/ErrorLogsSender';
import { addWeightUnitsToHistory, clearWeightsUnitsHistory } from './systemOfMeasurement';

const UPDATE_DATA = 'UPDATE_DATA';
const GET_TASKS_LIST = 'GET_TASKS_LIST';
const SET_CURRENT_TASK = 'SET_CURRENT_TASK';
const SIGN_FLIGHT_TASK = 'SIGN_FLIGHT_TASK';
const SET_CURRENT_USER = 'SET_CURRENT_USER';
const UPDATE_DOCUMENTS = 'UPDATE_DOCUMENTS';
const UPDATE_DEFECTS = 'UPDATE_DEFECTS';
const UPDATE_TELEGRAMS = 'UPDATE_TELEGRAMS';
const UPDATE_TELEX = 'UPDATE_TELEX';
const REMOVE_DATA = 'REMOVE_DATA';
const CLEAR_TASK_SIGNATURE_STATUS = 'CLEAR_TASK_SIGNATURE_STATUS';
const GET_VERSION = 'GET_VERSION';

export const SignaturePrefix = {
    TASK: 'sign_',
    OFP: 'sign-ofp_',
};

function mergeTaskData(task, data, telegrams, telex) {
    const updatedTask = cloneDeep(task) || {};
    const updates = mergeModel(TASK_MODEL, updatedTask, data, telegrams, telex);
    updatedTask.syncTime = data.syncTime;

    return {
        updatedTask,
        updates,
    };
}

function downloadTaskData(taskId, userId, isFirstLoad) {
    return (dispatch, getState, { apiManager }) => {
        const state = getState();

        if (!taskId) {
            taskId = state.screen.currentTask;
        }

        let task = state.tasks.list[taskId] || {};

        dispatch(setLoadingState(true, 'Downloading task data...'));
        return apiManager.getTaskData(taskId, userId, task.syncTime, isFirstLoad);
    };
}

function loadTasksList() {
    return (dispatch, getState, { apiManager }) => {
        const state = getState();
        const userId = get(state, 'login.user.id');
        const tasks = get(state, 'tasks.list', {});

        return apiManager.getTasksList(userId).then((data) => {
            const actualTaskIds = [];
            let legsForDelete = [];
            const mergedTasks = data.tasks.reduce((res, task) => {
                const id = task.id;
                if (id && actualTaskIds.indexOf(id) === -1) {
                    actualTaskIds.push(+id);
                }
                const updatedTask = find(data.tasks, (item) => item.id === id);

                res[id] = mergeTaskData(tasks[id], updatedTask).updatedTask;
                if (tasks[id]) {
                    res[id].syncTime = tasks[id].syncTime;
                    delete res[id].removeDate;
                }

                return res;
            }, Object.assign({}, tasks));

            Object.keys(mergedTasks).forEach((key) => {
                if (mergedTasks[key].removeDate && new Date() - new Date(mergedTasks[key].removeDate) > 24 * 3600 * 1000) {
                    // Если прошло более суток, то можно удалять это ПЗ.

                    legsForDelete = [].concat(findTaskLegs(mergedTasks[key]));
                    delete mergedTasks[key];
                } else if (actualTaskIds.indexOf(+key) === -1) {
                    // Если задания не было в списке актуальных с сервера, то удаляе его как устаревшее.
                    mergedTasks[key].removeDate = new Date();
                }
            });

            Promise.all([dispatch(prepareUsersData(data.users))]).then(([updatedUsers]) => {
                dispatch(updateUsers(updatedUsers, data.syncTime));
            });

            if (legsForDelete.length > 0) {
                dispatch(clearSendedDataList(legsForDelete));
            }

            dispatch({ type: GET_TASKS_LIST, tasks: mergedTasks, syncTime: data.syncTime });
            dispatch(getVersion(data.version));
        });
    };
}

function getTasksList() {
    return (dispatch, getState) => {
        const state = getState();
        const userId = get(state, 'login.user.id');

        if (!userId) {
            dispatch(setLoadingState(false));
            dispatch(redirectPage('login'));

            return Promise.resolve([]);
        }
        dispatch(setLoadingState(true, 'Loading task list...'));

        return Promise.all([dispatch(getUserData()), dispatch(getRoutesDeclaration()), dispatch(getLibrary()), dispatch(loadTasksList())])
            .then(() => {
                dispatch(setLoadingState(false));
            })
            .catch((err) => {
                dispatch(setLoadingState(false));
                if (err.errorCode === UNAUTHORIZED_HTTP_CODE) {
                    dispatch(showModal(TYPES.RELOGIN, {}));
                } else {
                    const msg = {
                        title: 'Error message',
                        message: 'Failed download data. Please try again later!',
                        btnCancelVisibility: false,
                        options: {
                            type: MessageDialogTypes.ERROR,
                        },
                    };
                    dispatch(showConfirmDialog(msg));
                }
            });
    };
}

function findTaskLegs(task) {
    let legs = [];

    task &&
        task.flights &&
        Object.keys(task.flights).map((key) => {
            const flight = task.flights[key] || {};

            flight.legs &&
                Object.keys(flight.legs).forEach((key) => {
                    if (legs.indexOf(+key) === -1) {
                        legs.push(+key);
                    }
                });
        });

    return legs;
}

function prepareUsersData(usersList) {
    const users = Object.assign({}, usersList);
    // может быть много пользователей с одним личным делом. И тогда может произойти конфликт записи данных.
    // поэтому отслеживаем, отправили ли уже картинку для создания
    const alreadyRequested = [];

    return (dispatch) => {
        return Promise.all(
            Object.keys(users).map((key) => {
                const user = users[key];
                const res = { ...user };
                const { data, contentType } = res.avatar;

                if (data && contentType && alreadyRequested.indexOf(user.crewId) === -1) {
                    alreadyRequested.push(user.crewId);
                    return dispatch(createImage(`img_${user.crewId}`, data, contentType));
                }

                return Promise.resolve();
            })
        )
            .then(() => {
                Object.keys(users).forEach((key) => {
                    delete users[key].avatar;
                });

                return users;
            })
            .catch((err) => {
                console.log(err);
            });
    };
}

function prepareTaskData(task) {
    return (dispatch, getState, { plugins }) => {
        return Promise.all([
            prepareSignature(dispatch, task),
            ...prepareCrewImages(dispatch, task),
            ...prepareTelegrams(dispatch, plugins, task),
        ]).then(([taskSignatureImgId]) => {
            if (taskSignatureImgId) {
                task.signature = taskSignatureImgId;
            }
        });
    };
}

function prepareDocuments(task) {
    return (dispatch, getState, { apiManager }) => {
        // eslint-disable-line no-unused-vars
        let newDocuments = {};
        let newDefects = {};
        let newTelegrams = [];
        let newTelexes = [];

        const fileStorePath = getFileStorePath();

        const preloadedIdsPromise = apiManager.getFileList(fileStorePath).then((files) => {
            return Promise.resolve(
                files.reduce(
                    (res, { name, modificationTime }) => {
                        const { fileName, type, needChange } = checkDocumentFormat(name);
                        if (type === DocumentTypes.DOCUMENT) {
                            res.docIds[+fileName] = {
                                modificationTime: modificationTime,
                            };
                        } else if (type === DocumentTypes.DEFECT) {
                            res.defIds[fileName.replace(DocumentPrefix.DEFECT, '')] = {
                                modificationTime: modificationTime,
                            };
                        }

                        if (needChange) {
                            apiManager.renameFile(name, `${name}.pdf`, fileStorePath);
                        }

                        return res;
                    },
                    { docIds: {}, defIds: {} }
                )
            );
        });

        return Promise.all([preloadedIdsPromise, Promise.resolve(task)])
            .then(([{ docIds, defIds }, task]) => {
                task &&
                    task.flights &&
                    Object.keys(task.flights).map((key) => {
                        const flight = task.flights[key] || {};

                        flight.legs &&
                            Object.keys(flight.legs).map((key) => {
                                const leg = flight.legs[key] || {};
                                const { documents = [], defects = [], plnChanged = 0 } = leg;
                                // TODO добавлено с 2.8.0, убрать старые телеграммы после перехода на нее всех
                                const telegrams = leg.telegrams || [];
                                const telexes = leg.telex;

                                if (documents.length > 0) {
                                    // newDocuments = [...newDocuments, ...documents];
                                    leg.documentIds = [...(leg.documentIds || [])];
                                    documents.forEach((item) => {
                                        const { modificationTime } = docIds[item.id] || {};

                                        if (leg.documentIds.indexOf(item.id) === -1) {
                                            leg.documentIds.push(item.id);
                                        }

                                        if (!newDocuments[item.id]) {
                                            newDocuments[item.id] = item;
                                            newDocuments[item.id].isDownloaded = new Date(item.date) < modificationTime;
                                        }
                                    });
                                }

                                if (defects.length > 0) {
                                    // newDefects = [...newDefects, ...defects];
                                    leg.defectIds = [...((!plnChanged && leg.defectIds) || [])];
                                    defects.forEach((item) => {
                                        const { modificationTime } = defIds[item.id] || {};

                                        if (leg.defectIds.indexOf(item.id) === -1) {
                                            leg.defectIds.push(item.id);
                                        }

                                        if (!newDefects[item.id]) {
                                            newDefects[item.id] = item;
                                            newDefects[item.id].isDownloaded = new Date(item.date) < modificationTime;
                                        }
                                    });
                                }

                                if (!telexes && telegrams.length > 0) {
                                    newTelegrams = [...newTelegrams, ...telegrams];
                                    leg.telegramIds = [...(leg.telegramIds || [])];
                                    telegrams.forEach((item) => {
                                        if (leg.telegramIds.indexOf(item.id) === -1) {
                                            leg.telegramIds.push(item.id);
                                        }
                                    });
                                }

                                if (telexes && telexes.length > 0) {
                                    newTelexes = [...newTelexes, ...telexes];
                                    leg.telegramIds = [...(leg.telegramIds || [])];
                                    telexes.forEach((item) => {
                                        if (leg.telegramIds.indexOf(item.id) === -1) {
                                            leg.telegramIds.push(item.id);
                                        }
                                    });
                                }

                                delete leg.documents;
                                delete leg.defects;
                                delete leg.telegrams;
                                delete leg.telex;
                            });
                    });

                newTelegrams = uniqBy(newTelegrams, (item) => item.id);
                newTelexes = newTelexes ? uniqBy(newTelexes, (item) => item.id) : null;

                return {
                    newDocuments: (newDocuments && Object.keys(newDocuments).map((key) => newDocuments[key])) || [],
                    newDefects: (newDefects && Object.keys(newDefects).map((key) => newDefects[key])) || [],
                    newTelegrams,
                    newTelexes,
                };
            })
            .catch((err) => {
                // console.log(err);
                const errorWithCustomMessage = new Error(getErrorMessage(err, MessageType.LOAD_LIBRARY));
                //throw Error('Failed to load library');
                throw new OtherError(errorWithCustomMessage);
            });
    };
}

function prepareCrew(dispatch, task) {
    let crewList = {};

    task &&
        task.flights &&
        Object.keys(task.flights).map((key) => {
            const flight = task.flights[key] || {};

            flight.legs &&
                Object.keys(flight.legs).map((key) => {
                    const leg = flight.legs[key] || {};

                    leg.crew &&
                        Object.keys(leg.crew).forEach((key) => {
                            const crewItem = leg.crew[key];

                            const persId = crewItem.crewId || crewItem.id;
                            if (!crewList[persId]) {
                                crewList[persId] = { ...crewItem };
                                delete crewList[persId].id;
                                delete crewList[persId].type;
                                delete crewList[persId].crewType;
                                delete crewList[persId].chair;
                                delete crewList[persId].chairEng;
                                delete crewList[persId].chairRole;
                                delete crewList[persId].chairRoleEng;
                                delete crewList[persId].comment;
                                delete crewList[persId].orderNumber;
                                delete crewList[persId].minimum;
                                delete crewList[persId].ppls;
                                delete crewList[persId].pplsCode;
                                delete crewList[persId].pplsCodeEng;
                                delete crewList[persId].pplsEng;
                                delete crewList[persId].before;
                                delete crewList[persId].after;
                                delete crewList[persId].percent;
                                delete crewList[persId].splittedShift;
                            }
                            leg.crew[key] = {
                                id: persId,
                                crewType: crewItem.type !== undefined ? crewItem.type : crewItem.crewType,
                                chair: crewItem.chair,
                                chairEng: crewItem.chairEng,
                                chairRole: crewItem.chairRole,
                                chairRoleEng: crewItem.chairRoleEng,
                                comment: crewItem.comment,
                                orderNumber: crewItem.orderNumber,
                                minimum: crewItem.minimum,
                                ppls: crewItem.ppls,
                                pplsCode: crewItem.pplsCode,
                                pplsCodeEng: crewItem.pplsCodeEng,
                                pplsEng: crewItem.pplsEng,
                                before: crewItem.before,
                                after: crewItem.after,
                                percent: crewItem.percent,
                                splittedShift: crewItem.splittedShift,
                            };
                        });
                });
        });

    return dispatch(updateCrew(crewList));
}

function prepareTelegrams(dispatch, plugins, task) {
    return (
        task &&
        task.flights &&
        Object.keys(task.flights).map((key) => {
            const flight = task.flights[key] || {};

            flight.legs &&
                Object.keys(flight.legs).map((key) => {
                    const leg = flight.legs[key] || {};
                    // TODO добавлено с 2.8.0, убрать старые телеграммы после перехода на нее всех
                    const isSupportTelex = Boolean(leg.telex);
                    const telegrams = isSupportTelex ? leg.telex : leg.telegrams;

                    telegrams &&
                        Object.keys(telegrams).map((key) => {
                            const telegram = telegrams[key] || {};
                            const text = telegram.text;
                            const signature = { ...telegram.signature };
                            const files = [].concat(telegram.files);
                            delete telegram.files;
                            delete telegram.text;
                            telegram.attachments = files.reduce(
                                (res, { id, changeTime, contentType, name, type }) => {
                                    res[id] = {
                                        name,
                                        type,
                                        changeTime,
                                        contentType,
                                    };

                                    return res;
                                },
                                { ...(telegram.attachments || {}) }
                            );

                            const fileName = `telegram_${telegram.id}`;
                            if (!text) {
                                return;
                            }

                            return Promise.all([
                                dispatch(isSupportTelex ? createTelex(fileName, text) : createTelegram(fileName, text)),
                                prepareTelegramSignature(dispatch, { id: telegram.id, signature }),
                                ...files.map((file) =>
                                    isSupportTelex
                                        ? createTelexAttachment(file, dispatch, plugins)
                                        : createTelegramAttachment(file, dispatch, plugins)
                                ),
                            ]).catch((err) => {
                                const errorWithCustomMessage = new Error(getErrorMessage(err, MessageType.CREATE_TELEX_ATTACHMENT));
                                const error = new OtherError(errorWithCustomMessage);
                                errorLogsSender.addLog(error);
                            });
                        });
                });
        })
    );
}

function prepareTelegramSignature(dispatch, { id, signature = {} }) {
    const { data, contentType } = signature;
    if (!data || !contentType || !id) {
        return;
    }

    return dispatch(createImage(`sign_telegram_${id}`, data, contentType, { rewrite: true }));
}

function prepareCrewImages(dispatch, task) {
    return (
        task &&
        task.flights &&
        Object.keys(task.flights).map((key) => {
            const flight = task.flights[key] || {};

            flight.legs &&
                Object.keys(flight.legs).map((key) => {
                    const leg = flight.legs[key] || {};

                    leg.crew &&
                        Object.keys(leg.crew).map((key) => {
                            const crewItem = leg.crew[key] || {};
                            const { crewId, photo } = crewItem;
                            if (!photo) {
                                return;
                            }
                            const { data, contentType } = photo;
                            if (typeof photo === 'object' && (!data || !contentType)) {
                                delete crewItem.photo;
                                return;
                            }

                            crewItem.photo = `img_${crewId}`;
                            return dispatch(createImage(crewItem.photo, data, contentType));
                        });
                });
        })
    );
}

function prepareSignature(dispatch, { id, signature = {} }) {
    const { data, contentType } = signature;
    if (!data || !contentType || !id) {
        return;
    }

    return dispatch(createImage(`sign_${id}`, data, contentType, { createImmediately: true, rewrite: true }));
}

function notifyAboutPlnChanged(dispatch, isPlnChanged) {
    if (isPlnChanged) {
        dispatch(
            showConfirmDialog({
                title: 'The aircraft was changed',
                message: 'CFP received earlier may not match the plan',
                btnCancelVisibility: false,
                options: {
                    okTitle: 'Ok',
                },
            })
        );
    }
}

function askAboutConflict(dispatch, hasConflict) {
    if (!hasConflict) {
        return Promise.resolve(true);
    }

    return new Promise((res) => {
        setTimeout(() => {
            dispatch(
                showConfirmDialog({
                    title: 'Confirmation dialog',
                    message: 'Do you want to replace the local data with the external data? All the unsaved data will be lost.',
                    options: {
                        okTitle: 'Replace all',
                        okWarning: true,
                    },
                })
            ).then((hasConflictOk) => res(hasConflictOk));
        }, 1000);
    });
}

function checkPlnId(updatedTask) {
    let isPlnChanged = false;
    const updatedTaskFlights = get(updatedTask, 'flights', {});

    for (const flightID of Object.keys(updatedTaskFlights)) {
        const legs = updatedTaskFlights[flightID].legs ?? {};
        for (const leg of Object.values(legs)) {
            if (leg.plnChanged) {
                isPlnChanged = leg.plnChanged;
                break;
            }
        }

        if (isPlnChanged) {
            break;
        }
    }

    return isPlnChanged;
}

const convertNewData = (data, { weightDimension, fieldsForConvert, precision = {} }) => {
    const defaultPrecision = {
        fields: ['est', 'est_default', 'act', 'used', 'used_default'],
        toFixed: 2,
    };

    return convertFields({
        value: data,
        fieldsForConvert,
        weightDimension,
        options: {
            precision: { ...defaultPrecision, ...precision },
        },
        loadType: LoadType.DOWNLOAD,
    });
};

const convertSendedData = (sendedData, { convert, fieldsForConvert, dimension, wizardStep, addWeightUnitsToHistory }) => {
    Object.keys(sendedData).forEach((id) => {
        const isWeightUnitsLbs = sendedData[id].weightUnits === Weight.LBS;
        const currentDimension = wizardStep === StepNumber.NAV_ROUTE ? Weight.LBS : dimension;
        const weightUnitsState = {
            event: 'Load',
            appUnits: currentDimension,
            legConvertedToUnits: Weight.KG,
            onLoadUnitsChangeTo: -1,
        };
        if (isWeightUnitsLbs && currentDimension === Weight.LBS) {
            sendedData[id] = {
                ...convert(sendedData[id], {
                    weightDimension: currentDimension,
                    fieldsForConvert,
                }),
                convertedTo: Weight.LBS,
            };
            weightUnitsState.legConvertedToUnits = currentDimension;
            weightUnitsState.onLoadUnitsChangeTo = Weight.LBS;
        } else {
            sendedData[id].convertedTo = Weight.KG;
        }
        addWeightUnitsToHistory({ weightUnitsState, legID: id });
    });
};

function selectTask(taskId, { isNeedRedirect = true, isNeedDownload = true, isFirstLoad = false }) {
    return (dispatch, getState) => {
        const state = getState();
        const { sendedData } = state;
        const wizardStep = get(state, 'wizard.wizardStep', 0);
        const { dimension, fields: fieldsForConvert } = get(state, 'systemOfMeasurement', {
            dimension: Weight.KG,
            fields: [],
        });

        const userId = get(state, 'login.user.id');
        const task = state.tasks.list[taskId];
        const telex = state.telex;
        const telegrams = state.telegrams;
        if (!userId) {
            dispatch(redirectPage('login'));

            return Promise.resolve([]);
        }

        if (!task) {
            console.log('Could not select unknown task');
            // throw new Error('Couldn\'t select unknown task');
            return dispatch(setLoadingState(false));
        }

        const { syncTime } = task;
        if (!isNeedDownload && syncTime) {
            dispatch(setCurrentTask(taskId));
            dispatch(setDefaultFlight());
            dispatch(redirectPage('flight'));
            dispatch(setLoadingState(false));

            return Promise.resolve();
        }

        const libraryPromise = dispatch(getLibrary());
        const taskPromise = dispatch(downloadTaskData(taskId, userId, isFirstLoad));
        const userDataPromise = dispatch(getUserData());
        const declarationPromise = dispatch(getRoutesDeclaration());

        return Promise.all([taskPromise, libraryPromise, userDataPromise, declarationPromise])
            .then(([data]) => {
                if (data.version) {
                    const version = { ...data.version };
                    dispatch(getVersion(version));
                    delete data.version;
                }

                const { updatedTask, updates } = mergeTaskData(task, data, telegrams, telex);
                const newSendedData = get(updates, 'sendedData.ids', {});
                convertSendedData(newSendedData, {
                    fieldsForConvert,
                    dimension,
                    convert: convertNewData,
                    wizardStep,
                    addWeightUnitsToHistory: ({ weightUnitsState, legID }) =>
                        dispatch(addWeightUnitsToHistory({ weightUnitsState, legID })),
                });
                const hasConflict = hasSendedDataConflict(sendedData, newSendedData);
                const isPlnChanged = checkPlnId(updatedTask);
                notifyAboutPlnChanged(dispatch, isPlnChanged);
                if (updates.changes.length || !syncTime) {
                    Promise.all([
                        askAboutConflict(dispatch, hasConflict),
                        Promise.resolve(updates.routeAirports),
                        dispatch(prepareTaskData(updatedTask)),
                    ])
                        .then(([hasConflictOk, routeAirports]) => {
                            return Promise.all([
                                Promise.resolve(hasConflictOk),
                                dispatch(prepareDocuments(updatedTask)),
                                Promise.resolve(routeAirports),
                            ]);
                        })
                        .then(([hasConflictOk, { newDocuments, newDefects, newTelegrams, newTelexes }, routeAirports]) => {
                            prepareCrew(dispatch, updatedTask);

                            const operations = [
                                dispatch({ type: UPDATE_DOCUMENTS, data: newDocuments }),
                                dispatch({ type: UPDATE_DEFECTS, data: newDefects }),
                                dispatch({ type: UPDATE_TELEGRAMS, data: newTelegrams }),
                                dispatch({ type: UPDATE_TELEX, data: newTelexes }),
                                dispatch({ type: UPDATE_DATA, taskId, data: updatedTask }),
                                dispatch(updateRouteAirports(taskId, routeAirports)),
                                dispatch({ type: UPDATE_CHECKLIST, data: updates.checkList }),
                                dispatch(setLoadingState(false)),
                            ];

                            if (hasConflictOk) {
                                operations.push(dispatch(updateSendedDataFromBackend(newSendedData)));
                            }

                            if (isNeedRedirect) {
                                operations[operations.length] = dispatch(setCurrentTask(taskId));
                                operations[operations.length] = dispatch(dispatch(setDefaultFlight()));
                                operations[operations.length] = dispatch(redirectPage('flight'));
                            }

                            return Promise.all(operations);
                        })
                        .catch((err) => {
                            console.log(err);
                            const error = new Error(getErrorMessage(err, MessageType.GET_DATA), {
                                cause: err,
                            });
                            errorLogsSender.addLog(error);

                            setTimeout(() => {
                                dispatch(
                                    showConfirmDialog({
                                        title: 'Error message',
                                        message: 'Failed to get data from the server. Please try again later!',
                                        btnCancelVisibility: false,
                                        options: {
                                            type: MessageDialogTypes.ERROR,
                                        },
                                    })
                                );
                                dispatch(setLoadingState(false));
                            }, 1000);
                            throw error;
                        });
                } else {
                    dispatch(setLoadingState(false));
                    if (isNeedRedirect) {
                        dispatch(setCurrentTask(taskId));
                        dispatch(setDefaultFlight());
                        dispatch(redirectPage('flight'));
                    }
                }
            })
            .catch((err) => {
                if (err.errorCode === UNAUTHORIZED_HTTP_CODE) {
                    dispatch(setLoadingState(false));
                    dispatch(showModal(TYPES.RELOGIN, {}));
                    return;
                }

                if (syncTime && isNeedRedirect) {
                    dispatch(setCurrentTask(taskId));
                    dispatch(setDefaultFlight());
                    dispatch(redirectPage('flight'));
                    dispatch(setLoadingState(false));
                } else {
                    // Cannot re-open Dialog immediately, need timeout for it
                    setTimeout(() => {
                        dispatch(
                            showConfirmDialog({
                                title: 'Error message',
                                message: 'Failed to get data from the server. Please try again later!',
                                btnCancelVisibility: false,
                                options: {
                                    type: MessageDialogTypes.ERROR,
                                },
                            })
                        );
                        dispatch(setLoadingState(false));
                    }, 1000);
                    const error = new Error(getErrorMessage(err, MessageType.TASK_SELECT), {
                        cause: err,
                    });
                    errorLogsSender.addLog(error);
                    throw error;
                }
            });
    };
}

function getActualData(isFirstLoad = false) {
    return (dispatch, getState) => {
        const state = getState();
        const currentTaskId = get(state, 'screen.currentTask');

        return dispatch(selectTask(currentTaskId, { isNeedRedirect: false, isNeedDownload: true, isFirstLoad }));
    };
}

function hasSendedDataConflict(sendedData, newSendedData) {
    return Object.keys(sendedData).some((key) => {
        return sendedData[key].hasChanges && newSendedData[key] !== undefined;
    });
}

function setCurrentTask(taskId) {
    return { type: SET_CURRENT_TASK, taskId };
}

function setCurrentUser({ id, name, preview }) {
    return { type: SET_CURRENT_USER, id, name, preview };
}

function changeUser(id, password) {
    return (dispatch, getState) => {
        const state = getState();
        const currentTask = getCurrentTaskSelector(state);
        const users = state.users.list || {};
        const taskUsers = get(currentTask, 'users', []).map((item) => users[item]);
        const current = taskUsers.find((user) => user.id === id && user.password === md5(password));

        if (current) {
            dispatch(getImageUrl(`img_${current.crewId}`)).then((url) => {
                dispatch(
                    setCurrentUser({
                        id: current.id,
                        preview: url,
                        name: current.name,
                    })
                );
            });
        }

        return { isOk: !!current };
    };
}

function signFlightTask(taskId, signature) {
    return (dispatch) => {
        const { data, contentType } = signature;
        const imgName = `sign_${taskId}`;

        dispatch(createImage(imgName, data, contentType, { createImmediately: true, rewrite: true }));
        dispatch({ type: SIGN_FLIGHT_TASK, taskId, signature: imgName });
    };
}

function useSignFlightTask() {
    const dispatch = useDispatch();

    return (taskId, signature) => {
        const { data, contentType } = signature;
        const imgName = `sign_${taskId}`;

        dispatch(createImage(imgName, data, contentType, { createImmediately: true, rewrite: true }));
        dispatch({ type: SIGN_FLIGHT_TASK, taskId, signature: imgName });
    };
}

function preloadSignatureUrl(id) {
    return (dispatch, getState) => {
        const signature = get(getState().tasks, `list[${id}].signature`);
        if (signature && typeof signature === 'string') {
            dispatch(getImageUrl(signature));
        }
    };
}

function removeTask(taskId) {
    return (dispatch, getState) => {
        const task = get(getState(), `tasks.list[${taskId}]`, {});
        let legIds = [];
        task.flights &&
            Object.keys(task.flights).map((key) => {
                const flight = task.flights[key] || {};

                legIds = legIds.concat(Object.keys(flight.legs || {}).map((id) => +id));
            });

        return Promise.all([
            dispatch(
                showConfirmDialog({
                    title: 'Warning message',
                    message: 'Do you really want to remove ALL task data from device? We recommend to use if you have Internet',
                    options: {
                        okWarning: true,
                    },
                })
            ),
            Promise.resolve(legIds),
        ])
            .then(([isOk, legIds]) => {
                if (isOk) {
                    dispatch(clearWeightsUnitsHistory(legIds));
                    return dispatch(clearSendedDataList(legIds));
                }

                return Promise.reject();
            })
            .catch((err) => {
                console.log(err);
                setTimeout(() => {
                    dispatch(
                        showConfirmDialog({
                            title: 'Error message',
                            message: 'Failed to remove task!',
                            btnCancelVisibility: false,
                            options: {
                                type: MessageDialogTypes.ERROR,
                            },
                        })
                    );
                    dispatch(setLoadingState(false));
                }, 1000);
                const errorWithCustomMessage = new Error(getErrorMessage(err, MessageType.REMOVE_TASK));
                const error = new OtherError(errorWithCustomMessage);
                errorLogsSender.addLog(error);
            })
            .then(() => {
                dispatch({ type: REMOVE_DATA, taskId });
                dispatch(getTasksList());
            });
    };
}

function clearTaskSignatureStatus(currentTask) {
    return { type: CLEAR_TASK_SIGNATURE_STATUS, taskId: currentTask };
}

function getVersion(version = {}) {
    const actualVersion = { ...version };

    return (dispatch) => {
        const isActual = actualVersion && actualVersion.date && moment.utc(appVersion.date) >= moment.utc(actualVersion.date);

        return dispatch({
            type: GET_VERSION,
            version: {
                number: appVersion.NUMBER,
                date: appVersion.date,
                actualNumber: actualVersion.number,
                actualDate: actualVersion.date,
                description: actualVersion.description,
                isActual,
            },
        });
    };
}

export {
    UPDATE_DATA,
    GET_TASKS_LIST,
    SET_CURRENT_TASK,
    SIGN_FLIGHT_TASK,
    SET_CURRENT_USER,
    UPDATE_DOCUMENTS,
    UPDATE_DEFECTS,
    UPDATE_TELEGRAMS,
    UPDATE_TELEX,
    REMOVE_DATA,
    CLEAR_TASK_SIGNATURE_STATUS,
    GET_VERSION,
    downloadTaskData,
    getTasksList,
    selectTask,
    changeUser,
    setCurrentUser,
    setCurrentTask,
    getActualData,
    signFlightTask,
    useSignFlightTask,
    preloadSignatureUrl,
    removeTask,
    clearTaskSignatureStatus,
    // downloadDocument,
    // downloadDefect
};
