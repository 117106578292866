import { makeStyles } from '@material-ui/core/styles';

import { darkFullWhite } from '../../../service/getMuiThemeStyle';

export const useStyles = makeStyles((theme) => {
    return {
        box: {
            padding: theme.spacing(0, 1),
        },
        container: {
            position: 'fixed',
            height: 40,
            width: '100%',
            bottom: 0,
            left: 0,
            opacity: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            zIndex: 1400,
        },
        inner: {
            position: 'absolute',
            width: '100%',
            bottom: 6,
            textAlign: 'center',
        },
        progress: {
            height: 10,
            borderRadius: 5,
        },
        text: {
            fontSize: 16,
            color: darkFullWhite,
        },
    };
});
