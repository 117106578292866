import React from 'react';

import BaseIcon from '../BaseIcon';

const ColorStyle = {
    BEIGE: { fill: '#FFE9BF' },
    BROWN: { fill: '#73573B' },
    ORANGE: { fill: '#EDBA5A' },
    LIGHT_ORANGE: { fill: '#E3C07F' },
};

const Heic = (props) =>
(
    <BaseIcon {...props} viewBox="0 0 512 512">
        <path
            style={ColorStyle.BEIGE}
            d="m12.01.05h300.15c5.85 0 8.37-.7 12.55 3.32 22.5 21.62 45 43.24 67.49 64.87 4.34 4.16 3.77 6 3.77 12v325.11h-395.97v-393.29c0-6.61
            5.41-12.01 12.01-12.01z" />
        <path style={ColorStyle.BROWN}
            d="m0 405.35h396v97.11c0 5.51-4.5 10.01-10.01 10.01h-375.98c-5.51 0-10.01-4.5-10.01-10.01z" />
        <path style={ColorStyle.BEIGE}
            d="m75.2 497.73v-77.63h15.72v30.57h30.58v-30.57h15.72v77.63h-15.72v-33.94h-30.58v33.94zm78.31
            0v-77.63h57.47v13.12h-41.75v17.24h38.82v13.12h-38.82v21.03h43.26v13.12zm71.78 0v-77.63h15.72v77.63zm80.33-28.62 15.18 4.66c-2.33 8.55-6.21
            14.89-11.64 19.05-5.42 4.14-12.29 6.22-20.62 6.22-10.32 0-18.79-3.53-25.44-10.58-6.64-7.04-9.96-16.68-9.96-28.91 0-12.92 3.34-22.97
            10.01-30.13s15.45-10.73 26.33-10.73c9.51 0 17.23 2.81 23.15 8.44 3.55 3.32 6.18 8.09 7.95 14.33l-15.5
            3.68c-.92-4.01-2.84-7.19-5.75-9.52-2.93-2.33-6.47-3.49-10.62-3.49-5.77 0-10.45 2.06-14.03 6.2-3.6 4.12-5.38 10.81-5.38 20.06 0 9.8 1.77 16.79 5.29 20.95
            3.54 4.17 8.13 6.25 13.79 6.25 4.17 0 7.77-1.32 10.77-3.98 3-2.65 5.17-6.81 6.47-12.5z" />
        <path style={ColorStyle.BROWN}
            d="m67.9 97.6h260.2c5.5 0 10.01 4.5 10.01 10.01v178.44c-24.91-24-56.22-54.18-74.72-72.01-18.51-17.83-24.35-11.92-39.63 1.18-15.28
            13.09-12.11 10.38-18.17 15.57-10.11-9.58-13.6-12.89-30.32-28.73-16.71-15.85-23.72-14.11-42-.15l-75.38 57.56v-151.86c0-5.51 4.51-10.01 10.01-10.01zm268.75
            205.33c-.08.14-.17.27-.26.41.09-.14.18-.27.26-.41zm-1.15 1.54c-.11.12-.22.24-.33.35-.12.11-.23.22-.35.33.12-.11.23-.22.35-.33.11-.11.22-.23.33-.35zm-1.05
            1c-.12.1-.24.2-.37.29z" />
        <path style={ColorStyle.ORANGE}
            d="m57.89 259.47 75.38-57.56c18.28-13.96 25.29-15.7 42 .15 16.72 15.84 20.21 19.15 30.32 28.73 6.06-5.19 2.89-2.48 18.17-15.57 15.28-13.1 21.12-19.01
            39.63-1.18 18.5 17.83 49.81 48.01 74.72 72.01v11.7c0 5.51-4.51 10.01-10.01 10.01h-260.2c-5.5 0-10.01-4.5-10.01-10.01zm150.64-132.18c16.44 0 29.77 13.32 29.77
            29.76 0 16.43-13.33 29.76-29.77 29.76-16.43 0-29.76-13.33-29.76-29.76 0-16.44 13.33-29.76 29.76-29.76z" />
        <path style={ColorStyle.LIGHT_ORANGE}
            d="m317.09.02c2.78.09 4.83.67 7.62 3.35 22.5 21.62 45 43.24 67.49 64.87 2.97 2.85 3.64 4.61 3.77 7.38h-66.87c-6.61 0-12.01-5.41-12.01-12.01z" />
    </BaseIcon>
);

export default Heic;
