import { CANCEL_DIRECTION, DIRECT, MULTIEDIT_FROM, MULTIEDIT_TO, MULTIEDIT_POINTS, CANCEL_MULTIEDIT } from '../actions/navigation';

const initialState = { visiblePopup: {} };
export const navigatorInitialState = initialState;

export default (state = initialState, action) => {
    switch (action.type) {
        case MULTIEDIT_FROM:
            return Object.assign({}, state, { multieditFrom: action.pointPos });

        case MULTIEDIT_TO:
            return Object.assign({}, state, { multieditTo: action.pointPos });

        case CANCEL_MULTIEDIT:
        case MULTIEDIT_POINTS:
            return Object.assign({}, state, { multieditTo: undefined, multieditFrom: undefined });

        case CANCEL_DIRECTION:
        case DIRECT:
            state = Object.assign({}, state);
            state.directFromPos = undefined;
            return state;

        default:
            return state;
    }
};
