import { useDispatch } from 'react-redux';

const TOGGLE_SWITCHER = 'TOGGLE_SWITCHER';

const toggleSwitcher = (updatedSwitcher) => {
    return { type: TOGGLE_SWITCHER, value: updatedSwitcher };
};

function useToggleSwitcher() {
    const dispatch = useDispatch();

    return (updatedSwitcher) => {
        dispatch(toggleSwitcher(updatedSwitcher));
    };
}

export { TOGGLE_SWITCHER, useToggleSwitcher };
