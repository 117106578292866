import { UPDATE_CHECKLIST, TOGGLE_CHECKED_CHECKLISTITEM, UPDATE_SENDED_CHECKLIST } from '../actions/checkList';
import { CLEAR_SENDED_DATA_LIST } from '../actions/sended-data';
import { LOGOUT } from '../actions/screen';

const initialState = {};

export default (state = initialState, action) => {
    let newState = { ...state };

    switch (action.type) {
        case UPDATE_CHECKLIST: {
            newState.checkList = { ...newState.checkList };
            newState.checkListStatus = { ...newState.checkListStatus };

            Object.keys(action.data).forEach((id) => {
                const checkList = { ...action.data[id] };
                newState.checkListStatus[id] = { ...checkList.checkedItems };
                delete checkList.checkedItems;
                newState.checkList[id] = checkList;
            });

            return newState;
        }
        case TOGGLE_CHECKED_CHECKLISTITEM: {
            const checkList = newState.checkList[action.checkListId];
            const checkListStatus = newState.checkListStatus[action.checkListId];
            if (checkList && checkListStatus) {
                if (!checkList.createDate || !checkList.hasChanges) {
                    newState.checkList[action.checkListId] = {
                        ...newState.checkList[action.checkListId],
                        createDate: checkList.createDate ? checkList.createDate : new Date(),
                        hasChanges: true,
                    };
                }

                newState.checkListStatus[action.checkListId] = {
                    ...newState.checkListStatus[action.checkListId],
                    [action.itemId]: !checkListStatus[action.itemId],
                };

                return {
                    ...newState,
                    checkInfoShowed: true,
                };
            }

            return state;
        }
        case CLEAR_SENDED_DATA_LIST:
            if (action.ids) {
                action.ids.forEach((id) => {
                    Object.keys(newState.checkList).forEach((key) => {
                        if (newState.checkList[key].legId === id) {
                            delete newState.checkList[key];
                            delete newState.checkListStatus[key];
                        }
                    });
                });
            }

            return newState;
        case UPDATE_SENDED_CHECKLIST:
            Object.keys(action.legsData).forEach((legId) => {
                const checkListSended = action.legsData[legId].checkList;

                checkListSended &&
                    checkListSended.map((sendedItem) => {
                        const key = `${legId}_${sendedItem.templateID}`;
                        const checkList = newState.checkList[key];

                        if (checkList && checkList.groups && sendedItem.items) {
                            const keys = Object.keys(checkList.groups);
                            for (let i = 0; i < keys.length; i++) {
                                const items = checkList.groups[keys[i]].items;

                                const newItems = items.map((item) => {
                                    return {
                                        ...item,
                                        id: sendedItem.items[item.templateItemId],
                                    };
                                });

                                newState.checkList[key].groups[keys[i]].items = newItems;
                            }

                            newState.checkList[key] = {
                                ...newState.checkList[key],
                                id: sendedItem.id,
                                hasChanges: false,
                            };
                        }
                    });
            });

            return newState;
        case LOGOUT:
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
