import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { ActionTimeControl } from '../../ActionTimeControl/ActionTimeControl';
import { MoreVertIconContrast } from '../../../icons/MoreVertIconContrast';
import { getCurrentSendedTakeOff, getPreviewWorkTime } from '../../../selectors/sended-data';
import { Takeoff } from '../../../icons';
import { checkTimeIsCorrect } from '../../../service/NavPointsUtils';

const TakeOffTime = ({ className, onChangeTime }) => {
    const { timeEngineWorkBefore, value } = useSelector((state) => {
        const takeOff = getCurrentSendedTakeOff(state);
        const previewWorkTime = getPreviewWorkTime(state);

        return {
            value: takeOff.takeOffTime,
            timeEngineWorkBefore: previewWorkTime.timeEngineWorkBefore,
        };
    }, shallowEqual);

    const takeOffSummary = React.useMemo(() => {
        return [
            {
                title: 'Engine run before take off ',
                value: timeEngineWorkBefore,
                isCorrect: checkTimeIsCorrect(timeEngineWorkBefore),
            },
        ];
    }, [timeEngineWorkBefore]);

    return (
        <ActionTimeControl
            title="Take off time"
            buttonName="Take off"
            iconButton={<Takeoff width="30" height="30" />}
            iconMenuImg={<MoreVertIconContrast isContrast={true} />}
            text="Aircraft took off on"
            name="takeOffTime"
            value={value}
            summary={takeOffSummary}
            onChange={onChangeTime}
            className={className}
        />
    );
};

const TakeOffTimeMemoized = React.memo(TakeOffTime);

export { TakeOffTimeMemoized as TakeOffTime };
