import React, { forwardRef, memo, useCallback } from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { useHideModal } from '../../actions/modal';
import { Dialog, DialogContent, DialogTitle } from '../../components/material-ui/Dialog';
import { useStyles } from './useStyles';
import { useMobileWidth } from '../../components/hooks/useMobileWidth';
import { LoginForm } from '../../components/LoginPage/LoginForm/LoginForm';
import { relogin } from '../../actions/login';
import { useStep } from '../../components/LoginPage/hooks/useStep';

export const Relogin = memo(
    forwardRef((props, ref) => {
        const classes = useStyles();
        const hideModal = useHideModal();
        const { isMobilePortrait } = useMobileWidth();
        const dispatch = useDispatch();
        const { error } = useSelector((state) => get(state, 'login', {}));
        const { step, showStep } = useStep();

        const handleLogin = useCallback((username, password, confirmationCode, onError) => {
            dispatch(relogin({ username, password, confirmationCode, onError, onSuccess: hideModal }));
        }, []);

        return (
            <Dialog open={true} maxWidth="md" fullScreen={isMobilePortrait} onClose={hideModal}>
                <DialogTitle className={classes.title}>Login dialog</DialogTitle>
                <DialogContent className={classes.content} ref={ref}>
                    <LoginForm onLogin={handleLogin} error={error} dark={false} step={step} showStep={showStep} isWhiteColor={false} />
                </DialogContent>
            </Dialog>
        );
    })
);
