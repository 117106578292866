import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
    button: {
        position: 'absolute',
        top: '20px',
        left: '20px',
        fontSize: 25,
    },
    icon: {
        fill: theme.icon.staticWhite,
    },
}));
