import React from 'react';

import BaseIcon from '../BaseIcon';

const Attachment = ({ isDashed, ...props }) => (
    <BaseIcon
        {...props}
        viewBox="0 0 512 512"
        stroke={props.color}
        strokeWidth="2px"
        fillOpacity={isDashed ? '0.3' : '1'}
        strokeDasharray={isDashed ? '5%' : '0%'}
    >
        <path
            d="M446.661,37.298c-49.731-49.731-130.641-49.731-180.372,0L76.378,227.208c-5.861,5.861-5.861,15.356,0,21.217
            c5.861,5.861,15.356,5.861,21.217,0l189.91-189.91c36.865-36.836,101.073-36.836,137.938,0c38.023,38.023,38.023,99.901,0,137.924
            l-265.184,268.17c-22.682,22.682-62.2,22.682-84.881,0c-23.4-23.4-23.4-61.467,0-84.867l254.576-257.577
            c8.498-8.498,23.326-8.498,31.825,0c8.776,8.776,8.776,23.063,0,31.84L117.826,400.958c-5.06,5.06-5.06,16.156,0,21.217
            c5.861,5.861,15.356,5.861,21.217,0l243.952-246.954c20.485-20.485,20.485-53.789,0-74.273c-19.839-19.839-54.449-19.81-74.258,0
            L54.161,358.524c-34.826,34.826-34.826,92.474,0,127.301C71.173,502.837,93.781,512,117.825,512s46.654-9.163,63.651-26.174
            L446.66,217.655C496.391,167.924,496.391,87.028,446.661,37.298z"
        />
    </BaseIcon>
);

export default Attachment;
