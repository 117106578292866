import React from 'react';
import { useDispatch } from 'react-redux';

import { ListItem, ListItemText, ListItemSecondaryAction } from '../../material-ui/List';
import { Menu, MenuItem } from '../../material-ui/Menu';
import { MoreVertIconContrast } from '../../../icons/MoreVertIconContrast';
import DownloadAll from '../../../icons/image/DownloadAll';
import { FlightTaskRoute } from '../FlightTaskRoute/FlightTaskRoute';
import { IconButton } from '../../material-ui/IconButton';
import { getDownloadDateInfo } from './tools';
import { useStyles } from './useStyles';
import { stopPropagation } from '../../../service/eventHandlers';
import { useAnchorEl } from '../../hooks/useAnchorEl';
import { preloadSignatureUrl, removeTask, selectTask } from '../../../actions/tasks';
import { TYPES, useShowModal } from '../../../actions/modal';

const FlightTaskItem = ({ id, flights, primaryText, download }) => {
    const dispatch = useDispatch();
    const showModal = useShowModal();
    const classes = useStyles();
    const [anchorEl, handleOpenMenu, handleClose] = useAnchorEl();

    const handleClickFlightTask = React.useCallback((id, isNeedRedirect) => {
        dispatch(selectTask(id, { isNeedRedirect, isNeedDownload: false }))
            //TODO рисовать ошибку
            .catch((err) => console.log(err));
    }, []);

    const handleClickTask = React.useCallback(
        (evt) => {
            stopPropagation(evt);
            handleClickFlightTask(id, true);
            handleClose();
        },
        [id]
    );

    const handleClickDownload = React.useCallback(
        (evt) => {
            stopPropagation(evt);
            handleClickFlightTask(id, false);
            handleClose();
        },
        [id]
    );

    const handleShowFlightTask = React.useCallback(
        (evt) => {
            stopPropagation(evt);
            dispatch(preloadSignatureUrl(id));
            showModal(TYPES.FLIGHT_TASK, { title: 'Task title', id });
            handleClose();
        },
        [id]
    );

    const handleRemoveTask = React.useCallback(
        (evt) => {
            stopPropagation(evt);
            dispatch(removeTask(id));
            handleClose();
        },
        [id]
    );

    const handleMenuClick = React.useCallback((event) => {
        stopPropagation(event);
        handleOpenMenu(event);
    }, []);

    return (
        <>
            <ListItem id={id} className={classes.listItem} onClick={handleClickTask} button={true}>
                <ListItemText
                    primary={<span className={classes.primary}>{primaryText}</span>}
                    secondary={
                        <span className={classes.secondaryText}>
                            {flights.map((flight) => (
                                <FlightTaskRoute key={flight.id} {...flight} />
                            ))}
                        </span>
                    }
                />
                <ListItemSecondaryAction className={classes.actionIcon}>
                    <p className={classes.downloadStatus}>{download ? getDownloadDateInfo('Downloaded', download) : 'Not loaded'}</p>
                    {download ? (
                        <IconButton edge="end" onClick={handleMenuClick}>
                            <MoreVertIconContrast />
                        </IconButton>
                    ) : (
                        <IconButton edge="end" onClick={handleClickDownload}>
                            <DownloadAll />
                        </IconButton>
                    )}
                </ListItemSecondaryAction>
            </ListItem>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={handleClickTask}>Open task</MenuItem>
                <MenuItem onClick={handleShowFlightTask}>Show task title</MenuItem>
                <MenuItem onClick={handleRemoveTask}>Remove</MenuItem>
            </Menu>
        </>
    );
};

const FlightTaskItemMemoized = React.memo(FlightTaskItem);

export { FlightTaskItemMemoized as FlightTaskItem };
