import React from 'react';

import BaseIcon from '../BaseIcon';

const Man = (props) => (
    <BaseIcon
        {...props}
        viewBox="0 0 488.012 488.012"
        style={{
            display: 'block',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: props.style.marginTop || `-${props.width / 2}px`,
            marginLeft: props.style.marginLeft || `-${props.width / 2}px`,
        }}
    >
        <path
            d="M300.506,309.912L300.506,309.912v-20.5c0,0,64.6,2,95.3-29.3c0,0-44.6-12.6-36.6-93.9c8-81.2-10-152.5-75.3-146.5
            c0,0-28.3-34.1-84.6-12.6c-19.3,7.4-71.2,25.9-68.6,138.5c2.6,112.5-40,113.2-40,113.2s22,32,96.6,31.3v21l56.7,165.6l56.6-165.3
            L300.506,309.912z"
        />
        <path
            d="M452.806,479.112l-11.4-63c-3.7-20.4-16.9-37.9-35.5-47.1l-71.4-35.2c-4.1-2-8.1-4.3-12.1-6.5l22.5,66.4l-31.5-2.4
            l-69.4,85.4l-69.4-85.4l-31.5,2.4l23-66.4l-14.3,7.3l-69.7,34.4c-18.6,9.2-31.8,26.7-35.5,47.1l-11.4,63c-0.8,4.6,2.7,8.9,7.4,8.9
            h198.7h5.2h198.7C450.106,488.012,453.606,483.712,452.806,479.112z"
        />
    </BaseIcon>
);

export default Man;
