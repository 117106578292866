// В Safari на iOS есть проблема с тем, что скроллится фон при открытых диалогах и сайдбарах.
// Это фиксится добавлением в тэг html свойства overflow: hidden;
// Но в этом случае из-за -webkit-overflow-scrolling: touch в тэге body начинаются коллизии со всеми фиксированными элементами.
// Если отключить это свойство, то на устройствах прокрутка перестает быть плавной - это нереально бесит пользователей.
// Решение для верстки, где не было бы таких проблем я не нашел. Поэтому принял решение при открытии модалок проставлять в html свойство overflow: hidden.

export const setHtmlOverflow = (hidden = true) => {
    const document = window.document.querySelector('.page-layout__content');
    if (hidden) {
        document.classList.add('overflow-hidden');
    } else {
        document.classList.remove('overflow-hidden');
    }
};
