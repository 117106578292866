import React from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

import { getMuiThemeStyle } from '../service/getMuiThemeStyle';
import { useDarkTheme } from '../selectors/screen';

export const darkFullWhite = 'rgb(234, 234, 234)';
export const grey750 = '#535353';
export const black = 'black';
export const lightBlue = '#64b5f6';
const lightTheme = getMuiThemeStyle('light');
const darkTheme = getMuiThemeStyle('dark');

const muiThemeLight = createTheme({
    ...lightTheme,
    overrides: {
        MuiButton: {
            root: {
                fontSize: '18px',
            },
        },
        MuiIcon: {
            root: {
                color: black,
            },
        },
        MuiListItemIcon: {
            root: {
                color: 'rgba(0, 0, 0, 0.8)',
            },
        },
        MuiListItemText: {
            primary: {
                fontSize: '18px',
            },
            secondary: {
                color: 'rgba(0, 0, 0, 0.92)',
            },
        },
        MuiFormLabel: {
            root: {
                color: 'inherit',
                '&.Mui-disabled': {
                    color: '#1976d2',
                },
                '&.Mui-error': {
                    color: lightTheme.palette.primary.main,
                },
            },
        },
        MuiInput: {
            input: {
                '&::placeholder': {
                    opacity: 0.8,
                },
            },
            root: {
                fontSize: 18,
                height: 36,
                '&.Mui-disabled': {
                    color: '#1976d2',
                },
                '&.Mui-error': {
                    color: lightTheme.palette.error.main,
                },
            },
            underline: {
                '&::before': {
                    borderBottom: `1px solid ${lightTheme.palette.borderColor}`,
                },
                '&.Mui-disabled:before': {
                    borderBottomStyle: 'solid',
                },
                '&::after': {
                    borderBottom: `1px solid ${lightTheme.palette.borderColor}`,
                    transform: 'scale(1)',
                },
                '&.Mui-focused:after': {
                    borderBottom: `2px solid ${lightTheme.palette.primary.main}`,
                },
            },
        },
        MuiInputLabel: {
            root: {
                fontSize: 18,
                color: 'rgb(25, 118, 210)',
                '&.Mui-disabled': {
                    fontSize: 18,
                    color: '#1976d2',
                },
            },
        },
        MuiMenuItem: {
            root: {
                fontSize: 18,
            },
        },
        MuiFormHelperText: {
            root: {
                fontSize: 16,
                '&.Mui-disabled': {
                    color: '#1976d2',
                },
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: lightTheme.palette.primary.main,
            },
            toolbarLandscape: {
                maxWidth: 180,
            },
        },
        MuiTypography: {
            root: {
                color: black,
            },
        },
    },
});

const muiThemeDark = createTheme({
    ...darkTheme,
    overrides: {
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: '#171b4c',
            },
        },
        MuiButton: {
            root: {
                fontSize: '18px',
            },
        },
        MuiIcon: {
            root: {
                color: darkFullWhite,
            },
        },
        MuiListItemIcon: {
            root: {
                color: darkFullWhite,
                opacity: 0.8,
            },
        },
        MuiDrawer: {
            paper: {
                background: '#303030',
            },
        },
        MuiListItemText: {
            primary: {
                fontSize: '18px',
            },
            secondary: {
                color: darkFullWhite,
            },
        },
        MuiFormLabel: {
            root: {
                color: 'inherit',
                '&.MuitextColor-disabled': {
                    color: lightBlue,
                },
            },
        },
        MuiInput: {
            input: {
                '&::placeholder': {
                    opacity: 0.8,
                },
            },
            root: {
                fontSize: 18,
                height: 36,

                '&.Mui-disabled': {
                    color: lightBlue,
                },
                '&.Mui-error': {
                    color: lightTheme.palette.error.main,
                },
            },
            underline: {
                '&::before': {
                    borderBottom: `1px solid ${lightTheme.palette.borderColor}`,
                },
                '&.Mui-disabled:before': {
                    borderBottomStyle: 'solid',
                },
                '&::after': {
                    borderBottom: `1px solid ${lightTheme.palette.borderColor}`,
                    transform: 'scale(1)',
                },
                '&.Mui-focused:after': {
                    borderBottom: `2px solid ${lightTheme.palette.primary.main}`,
                },
            },
        },
        MuiInputLabel: {
            root: {
                fontSize: 18,
                color: 'rgb(100, 181, 246)',
                '&.Mui-disabled': {
                    fontSize: 18,
                    color: lightBlue,
                },
                '&.Mui-error': {
                    color: 'rgb(100, 181, 246)',
                },
            },
        },
        MuiMenuItem: {
            root: {
                fontSize: 18,
            },
        },
        MuiFormHelperText: {
            root: {
                fontSize: 16,
                '&.Mui-disabled': {
                    color: lightBlue,
                },
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: lightTheme.palette.primary.main,
            },
            toolbarLandscape: {
                maxWidth: 180,
            },
        },
        MuiTypography: {
            root: {
                color: darkFullWhite,
            },
        },
    },
});

const ThemeProvider = ({ children }) => {
    const darkTheme = useDarkTheme();
    const theme = darkTheme ? muiThemeDark : muiThemeLight;

    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
