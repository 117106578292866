import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
    const {
        palette: { warningColor },
        textField,
    } = theme;

    return {
        action: {
            right: 0,
        },
        icon: {
            [theme.breakpoints.down('sm')]: {
                minWidth: theme.spacing(4),
            },
        },
        item: {
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(1, 2),
            },
        },
        primaryText: {
            fontSize: textField.textFontSize,
            lineHeight: textField.textFontSize,
            fontWeight: '600',
        },
        secondaryText: {
            fontSize: textField.labelFontSize,
            color: textField.textColor,
            fontWeight: 'normal',
        },
        text: {
            [theme.breakpoints.down('sm')]: {
                paddingRight: 40,
            },
        },
        warningText: {
            color: warningColor,
        },
    };
});

export { useStyles };
