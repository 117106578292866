import { navigatorInitialState } from '../reducers/navigation';
import { get, cloneDeep } from 'lodash';
import { setDataBackupStatus } from '../actions/storeStage';

const DEFAULT_BACKUP_INTERVAL = 5; // sec
const MS_IN_MIN = 1000;
export const STATE_COUNT = 2;

export function getBackupInterval(interval) {
    let backupInterval = parseInt(interval);

    return backupInterval >= 1 && backupInterval <= 360 ? backupInterval : DEFAULT_BACKUP_INTERVAL;
}

export class StateManager {
    constructor(storage) {
        this._storage = storage;
        this.updateBackupInterval();
    }

    init(store) {
        this.store = store;
    }

    getBackupInterval = () => {
        return parseInt(localStorage.getItem('backupInterval')) || DEFAULT_BACKUP_INTERVAL;
    };

    _getStateFiles = () => {
        const { files: existedFiles = [] } = this;
        const states = [].concat(existedFiles);
        // Дополняем в случае отсутствия новыми ротационными файлами до нужного количества
        for (let i = 1; i <= STATE_COUNT; i++) {
            const item = `state${i}`;
            if (states.indexOf(item) === -1) {
                states.push(item);
            }
        }

        return states;
    };

    _getNextRewritableFile = () => {
        this.files = this._getStateFiles();
        const file = this.files.pop();
        this.files.unshift(file);

        return file;
    };

    saveStateToStorage(errorLogger) {
        try {
            const savedState = { ...cloneDeep(this.store.getState()) };
            // Попробуем убрать, чтобы сохраняло состояние
            // const currentUser = get(savedState, 'screen.currentUser');
            // savedState.screen = { ...screenInitialState, currentUser };
            savedState.navigation = { ...navigatorInitialState };
            // savedState.routing.location = '/flight-task';
            Object.keys(savedState.images || {}).forEach((item) => {
                delete savedState.images[item].url;
            });
            if (savedState.screen && savedState.screen.currentUser) {
                delete savedState.screen.currentUser.preview;
            }
            // remove not important store information
            delete savedState.textDialog;
            delete savedState.modal;
            delete savedState.messageDialog;
            delete savedState.linearLoading;
            // delete savedState.storeStage;   // не нужно затирать
            delete savedState.progressIndicator;
            // delete savedState.wizard;

            const stateSize = Object.keys(savedState).reduce((res, item) => {
                res += JSON.stringify(savedState[item]).length;
                return res;
            }, 0);

            console.log(`State saved. Size ${stateSize}`);
            // console.log('Show store size:');
            // Object.keys(savedState).forEach(item => {
            //     console.log(item, JSON.stringify(savedState[item]).length);
            // });

            const fileName = this._getNextRewritableFile();

            return this._storage
                .setItem(fileName, JSON.stringify(savedState))
                .then(() => {
                    this.store.dispatch(setDataBackupStatus(true));
                })
                .catch((err) => {
                    console.log(`saveStateToStorage. Write file '${fileName}' system error . Details: ${err.message}`);
                    errorLogger.addErrorManual({
                        ...err,
                        stack: err.stack,
                        message: `saveStateToStorage. Write file '${fileName}' system error . Details: ${err.message}`,
                    });
                    this.store.dispatch(setDataBackupStatus(false));
                });
        } catch (e) {
            console.log(e);
        }
    }

    startTimer = (errorLogger) => {
        if (this.backupIntervalId) {
            clearInterval(this.backupIntervalId);
        }

        const isDataBackuped = get(this.store.getState(), 'storeStage.isDataBackuped', true);
        if (isDataBackuped) {
            this.store.dispatch(setDataBackupStatus(false));
        }
        this.backupIntervalId = setTimeout(() => this.saveStateToStorage(errorLogger), this.backupInterval * MS_IN_MIN);
    };

    updateBackupInterval = () => {
        this.backupInterval = this.getBackupInterval();
    };

    prepareState(state) {
        let currentUser = null;
        if (state && state.screen) {
            state.screen.currentUser = currentUser || state.login.user;
        }
        state.storeStage = {
            isDataBackuped: true,
        };

        return state;
    }

    getStateFromStorage(errorLogger, iteration) {
        const promise = this._storage.getStateFiles();

        return promise.then((files) => {
            const fileList =
                (files &&
                    files
                        .filter((file) => file.name.indexOf('state') === 0)
                        .sort((a, b) => (new Date(a.modificationTime) < new Date(b.modificationTime) ? 1 : -1))) ||
                [];
            this.files = [].concat(fileList.map((file) => file.name));

            let getFile = (files = [], index, resolve, reject, prevError = {}) => {
                const prevErrorMessage = prevError.message || '';
                if (files.length === 0) {
                    console.log(`#${iteration}; ${prevErrorMessage} Files not found`);
                    return resolve({});
                }

                const [file, ...restFiles] = files;
                let message = index === 0 ? `#${iteration}; ` : prevErrorMessage;

                return this._storage
                    .getItem(file.name)
                    .then((state) => {
                        try {
                            const data = this.prepareState(JSON.parse(state));
                            message = `${message}File ${index} "${file.name}" found.`;
                            if (prevErrorMessage) {
                                errorLogger.addErrorManual({
                                    ...prevError,
                                    stack: prevError.stack,
                                    message,
                                });
                            }

                            console.log(message);
                            return resolve(data);
                        } catch (error) {
                            message = `${message}File ${index} "${file.name}" is bad. Details: ${error.message}; \n`;

                            return Promise.reject({
                                ...error,
                                stack: error.stack,
                                message,
                                isSynthetic: 1,
                            });
                        }
                    })
                    .catch((err) => {
                        if (restFiles.length === 0) {
                            if (err.isSynthetic) {
                                message = `Fatal: ${err.message}No more files; \n`;
                            } else {
                                message = `Fatal: ${message}File ${index} "${file.name}" error, no more files. Details: ${err.message}; \n`;
                            }

                            console.log(message);
                            return reject({
                                ...err,
                                stack: err.stack,
                                message,
                            });
                        }

                        if (!err.isSynthetic) {
                            message = `${message}File ${index} "${file.name}" error. Details: ${err.message}; \n`;
                        }

                        const error = {
                            ...err,
                            stack: err.stack,
                            message,
                        };
                        return getFile(restFiles, index + 1, resolve, reject, error);
                    });
            };

            return new Promise((resolve, reject) => {
                return getFile(fileList, 0, resolve, reject);
            });
        });
    }

    // getStateFromStorage(errorLogger, iteration) {
    //     const promise = this._storage.getStateFiles();
    //
    //     return promise.then((files) => {
    //         const fileList = files && files.filter(file => file.name.indexOf('state') === 0)
    //             .sort((a, b) => new Date(a.modificationTime) < new Date(b.modificationTime) ? 1 : -1) || [];
    //         let [lastFile, prevFile] = fileList || [];
    //
    //         return new Promise((resolve, reject) => {
    //             if(!lastFile) {
    //                 console.log(`#${iteration}; State files not found`);
    //                 return resolve({});
    //             }
    //
    //             // if(iteration < 3 ) {
    //             //     reject(new Error('test'));
    //             // }
    //
    //             return this._storage.getItem(lastFile.name)
    //                 .then(state => {
    //                     try {
    //                         const data = this.prepareState(JSON.parse(state));
    //                         console.log(`#${iteration}; State file '${lastFile.name}' found`);
    //                         this.fileName = lastFile.name;
    //                         return resolve(data);
    //                     }
    //                     catch (error) {
    //                         const message = `#${iteration}; First state "${lastFile.name}" is bad. Details: ${error.message}`;
    //                         console.log(message);
    //
    //                         return Promise.reject({
    //                             ...error,
    //                             stack: error.stack,
    //                             message,
    //                         });
    //                     }
    //                 })
    //                 .catch((err) => {
    //                     if (!prevFile) {
    //                         const message = `#${iteration}; First state error, second state is not exist. Details: ${err.message}`;
    //                         console.log(message);
    //
    //                         return reject({
    //                             ...err,
    //                             stack: err.stack,
    //                             message,
    //                         });
    //                     }
    //
    //                     return this._storage
    //                         .getItem(prevFile.name)
    //                         .then(state => {
    //                             try {
    //                                 const data = this.prepareState(JSON.parse(state));
    //                                 const message = `#${iteration}; First state "${lastFile.name}" error, second state "${prevFile.name}" found. Details: ${err.message}`;
    //                                 console.log(message);
    //                                 errorLogger.addErrorManual({
    //                                     ...err,
    //                                     stack: err.stack,
    //                                     message,
    //                                 });
    //                                 this.fileName = prevFile.name;
    //                                 return resolve(data);
    //                             }
    //                             catch (error) {
    //                                 const message = `#${iteration}; First state "${lastFile.name}" error, second state "${prevFile.name}" is bad. Details: ${err.message} ; ${error.message}`;
    //                                 console.log(message);
    //
    //                                 return reject({
    //                                     ...error,
    //                                     stack: error.stack,
    //                                     message,
    //                                 });
    //                             }
    //                         })
    //                         .catch((error) => {
    //                             const message = `#${iteration}; First state "'${lastFile.name}'" error, second state "'${prevFile.name}'" error. Details: ${err.message} ; ${error.message}`;
    //                             console.log(message);
    //
    //                             return reject({
    //                                 ...error,
    //                                 stack: error.stack,
    //                                 message,
    //                             });
    //                         });
    //                 });
    //         });
    //     });
    // }
}
