import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    actions: {
        padding: theme.spacing(1, 2),

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1, 0.5),
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1, 2),
        minWidth: 300,

        '&:first-child': {
            paddingTop: 0,
        },
    },
    error: {
        color: theme.palette.error.main,
    },
    formControl: {
        margin: theme.spacing(0.5, 0),
        flexBasis: '100%',
    },
    title: {
        padding: theme.spacing(2, 2, 1),

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2, 1, 1),
        },
    },
}));

export { useStyles };
