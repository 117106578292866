import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { VersionWarning, VersionWarningTypes } from '../VersionWarning/VersionWarning';

const AppBarVersion = ({ shortView, isMenu = false, onLogout }) => {
    const version = useSelector((state) => state.version || {}, shallowEqual);

    return (
        (isMenu || (!shortView && !version.isActual)) && (
            <VersionWarning
                onLogout={onLogout}
                version={version}
                mode={isMenu ? VersionWarningTypes.MENU_ITEM : VersionWarningTypes.ICON}
            />
        )
    );
};

const AppBarVersionMemoized = React.memo(AppBarVersion);

export { AppBarVersionMemoized as AppBarVersion };
