import React from 'react';

import { AirportNotice } from '../AirportNotice/AirportNotice';
import { Typography } from '../../../components/material-ui/Typography';
import { useStyles } from './useStyles';
import { Order, sortNotices } from '../../../service/sort';

const NoticeList = ({ routesAirports, toggleFavoriteNotice, toggleRecentNotice, backgroundColor }) => {
    const classes = useStyles();
    
    return routesAirports.reduce((res, { airportList = [], colorName }, groupIndex) => {
        Object.values(airportList || {}).forEach(({ airportNotices, ...airport }) => {
            if (airport && airport.data.length > 0) {
                const items = Object.values(airportNotices || {})
                    .sort(sortNotices({ name: 'series', order: Order.ASC }, { name: 'begin', order: Order.DESC }))
                    .map((notice, index) => (
                        <AirportNotice
                            key={`${groupIndex}_${airport.id}_${notice.id}`}
                            notice={notice}
                            isFirst={!index}
                            colorName={colorName}
                            toggleFavoriteNotice={toggleFavoriteNotice}
                            toggleRecentNotice={toggleRecentNotice}
                        />
                    ));

                if (items.length > 0) {
                    res.push(
                        <section id={airport.code} key={`${groupIndex}_ap_${airport.id}`}>
                            <Typography style={{ backgroundColor }} className={classes.sticky}>
                                <span className={classes.airport}>
                                    {`${airport.code} ${airport.nameEng}`}
                                </span>
                            </Typography>
                            {items}
                        </section>
                    );
                }
            }
        });

        return res;
    }, []);
};

const NoticeListMemoized = React.memo(NoticeList);

export { NoticeListMemoized as NoticeList };
