import React from 'react';

import { CheckListContent } from '../CheckListContent/CheckListContent';
import { DialogControls } from '../../../components/DialogControls/DialogControls';
import { ErrorBoundary } from '../../../components/ErrorBoundary/ErrorBoundary';
import { AppBar } from '../../../components/material-ui/AppBar';
import { Badge } from '../../../components/material-ui/Badge';
import { Toolbar } from '../../../components/material-ui/Toolbar';
import { Typography } from '../../../components/material-ui/Typography';
import { Tabs, Tab } from '../../../components/material-ui/Tabs';
import { useHideModal } from '../../../actions/modal';
import { useModalProps } from '../../../selectors/modals';
import { useTouch } from '../../../components/hooks/useTouch';
import { useCheckListModalContent } from './useCheckListModalContent';
import { useGetTabsHeight } from './useGetTabsHeight';
import { useStyles } from '../useStyles';

export const CheckListModalContent = React.memo(() => {
    const { title } = useModalProps();
    const { checkListId, checkLists, handleDownloadCheckList, handleToggleCheckItem, handleCheckListChange } = useCheckListModalContent();

    const tabsRef = React.useRef();
    const isTouch = useTouch();
    const tabsHeight = useGetTabsHeight(tabsRef);
    const hideModal = useHideModal();

    const actionList = React.useMemo(
        () => [
            {
                label: 'Get data',
                variant: 'text',
                onClick: handleDownloadCheckList,
            },
            {
                label: 'Close',
                variant: 'text',
                onClick: hideModal,
            },
        ],
        [handleDownloadCheckList, hideModal]
    );

    const classes = useStyles(tabsHeight)();

    return (
        <ErrorBoundary>
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Typography className={classes.title}>{title}</Typography>
                    <DialogControls actions={actionList} appBar={true} />
                </Toolbar>
            </AppBar>
            <div className={classes.checkListTabs} ref={tabsRef}>
                <Tabs
                    value={checkListId}
                    onChange={handleCheckListChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {Object.keys(checkLists).map((listKey, index) => (
                        <Tab
                            className={classes.tabLabel}
                            label={
                                <Badge color="secondary" variant="dot" invisible={checkLists[listKey].completed}>
                                    {checkLists[listKey].name}
                                </Badge>
                            }
                            value={listKey}
                            key={listKey}
                            index={index}
                        />
                    ))}
                </Tabs>
            </div>

            {Object.keys(checkLists).map((listKey) => {
                const checkList = checkLists[listKey];

                return (
                    checkListId === listKey && (
                        <div className={classes.checklist} key={checkList.templateID}>
                            <div className={classes.inner}>
                                <ErrorBoundary>
                                    <CheckListContent
                                        checkListGroups={checkList.groups}
                                        checkedItems={checkList.checkedItems}
                                        toggleCheckItem={handleToggleCheckItem}
                                        isTouch={isTouch}
                                    />
                                </ErrorBoundary>
                            </div>
                        </div>
                    )
                );
            })}
        </ErrorBoundary>
    );
});
