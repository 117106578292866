import React from 'react';
import classnames from 'classnames';
import { useMediaQuery } from '@material-ui/core';

import { useStyles } from './useStyles';
import { Drawer } from '../material-ui/Drawer';
import { List } from '../material-ui/List';
import { NotificationContent } from './NotificationContent/NotificationContent';

import '../Sidebar/Sidebar.scss';

const NotificationSidebar = (props) => {
    const { onCloseSidebar, open } = props;
    const classes = useStyles();
    const shortView = useMediaQuery('(max-width: 480px)');

    const handleClose = React.useCallback(() => {
        onCloseSidebar();
    }, []);

    return (
        <Drawer
            open={open}
            anchor="right"
            onClose={handleClose}
            className={classnames(classes.drawerClass, {
                [classes.drawerOpenClass]: open,
                [classes.drawerCloseClass]: !open,
            })}
            classes={{
                paper: classnames(
                    {
                        [classes.drawerOpenClass]: open,
                        [classes.drawerCloseClass]: !open,
                    },
                    classes.drawerPaper
                ),
            }}
            width={shortView ? 320 : 460}
        >
            <div className={classes.toolbar} />
            <List className={classes.list}>
                <NotificationContent shortView={shortView} />
            </List>
        </Drawer>
    );
};

const NotificationSidebarMemoized = React.memo(NotificationSidebar);

export { NotificationSidebarMemoized as NotificationSidebar };
