import React from 'react';
import { useSelector } from 'react-redux';

import { FilterTypes } from '../../../reducers/filter';
import { getCurrentLeg } from '../../../selectors/screen';
import { FilterKinds } from '../constants';

const calculateTotal = (item, type) => (item ? { ...item, count: item.count + 1 } : { value: type, text: type, count: 1 });

export const useData = (filterType) => {
    const [data, setData] = React.useState({});

    const currentLegData = useSelector(getCurrentLeg);
    const currentLeg = filterType === FilterTypes.PASSENGERS ? currentLegData || {} : null;

    React.useEffect(() => {
        const result =
            currentLeg &&
            currentLeg.passengerList &&
            Object.keys(currentLeg.passengerList).reduce(
                (res, key) => {
                    const { type, ssrsList } = currentLeg.passengerList[key];

                    res.isPassengerType = type ? true : res.isPassengerType;
                    res.passengerType[type] = calculateTotal(res.passengerType[type], type);

                    if (ssrsList) {
                        res.isSsrsList = true;

                        ssrsList.forEach((item) => {
                            if (item.code) {
                                res.ssrsList[item.code] = calculateTotal(res.ssrsList[item.code], item.code);
                            }
                        });
                    } else {
                        res.ssrsList[''] = calculateTotal(res.ssrsList[''], '');
                    }

                    return res;
                },
                { passengerType: {}, isPassengerType: false, ssrsList: {}, isSsrsList: false }
            );

        const sortCodes = (val1, val2) => val1.text.toLowerCase().localeCompare(val2.text.toLowerCase());

        setData(
            {
                [FilterKinds.PASSENGER_TYPE]: result && result.isPassengerType ? Object.values(result.passengerType).sort(sortCodes) : [],
                [FilterKinds.SSR]: result && result.isSsrsList ? Object.values(result.ssrsList).sort(sortCodes) : [],
            } || {}
        );
    }, [filterType, currentLeg]);

    return data;
};
