import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
    return {
        actions: {
            padding: theme.spacing(1, 2),

            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(1, 0.5),
            },
        },
        content: {
            padding: theme.spacing(1, 2),

            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(1, 0.5),
            },
        },
        item: {
            margin: theme.spacing(1),
        },
        value: {
            fontWeight: 'bold',
            marginLeft: theme.spacing(1),
        },
    };
});

export { useStyles };
