import React from 'react';

import { List } from '../material-ui/List';
import { ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction } from '../material-ui/List';
import { Collapse } from '../material-ui/Collapse';
import { NestedListIconContrast } from '../NestedListIconContrast/NestedListIconContrast';
import { stopPropagation } from '../../service/eventHandlers';
import { IconButton } from '../material-ui/IconButton';

const NestedListItem = (props) => {
    const [open, toggleOpen] = React.useState(false);
    const { showSign, style, onClick, primary, disabled, secondary, children, inset, icon, showIcon = false } = props;
    const handleToggle = React.useCallback((evt) => {
        stopPropagation(evt);
        toggleOpen((prevState) => !prevState);
    }, []);

    return (
        <>
            <ListItem onClick={onClick || handleToggle} disabled={disabled} style={style} button={true}>
                {showIcon && <ListItemIcon style={{ minWidth: '40px' }}>{showSign ? icon : <></>}</ListItemIcon>}
                <ListItemText inset={inset} primary={primary} secondary={secondary} />
                {children && children.length > 0 && (
                    <ListItemSecondaryAction>
                        <IconButton onClick={handleToggle}>
                            <NestedListIconContrast open={open} />
                        </IconButton>
                    </ListItemSecondaryAction>
                )}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {/* TODO add to List disablePadding={true} */}
                <List component="div" style={{ padding: 0 }}>
                    {children}
                </List>
            </Collapse>
        </>
    );
};

NestedListItem.defaultProps = {
    style: {},
    inset: true,
    showIcon: false,
};

const NestedListItemMemoized = React.memo(NestedListItem);

export { NestedListItemMemoized as NestedListItem };
