import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => {
    const {
        palette: { textColor, warningColor },
        textField,
    } = theme;

    return {
        button: {
            fontSize: '1.1em',
        },
        buttonContainer: {
            padding: theme.spacing(1),
        },
        comment: {
            margin: 0,
            lineHeight: '2em',
        },
        content: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            padding: 6,
        },
        default: {
            flexGrow: 1,
        },
        subHeader: {
            lineHeight: '2em',
            fontSize: textField.fontSize,
            paddingLeft: '6px',
            color: textColor,
            fontWeight: 'normal',
        },
        text: {
            color: textColor,
            fontSize: '1.1em',
            fontWeight: 600,
            lineHeight: '1em',
        },
        textIncorrect: {
            color: warningColor,
        },
        incorrectButton: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
        },
    };
});

export { useStyles };
