import React from 'react';

import { CustomTimePicker, TimePickerType } from '../../../components/CustomTimePicker/CustomTimePicker';
import { checkInputTimeSupport, removeSeconds } from '../../../service/DateTimeUtils';
import { Typography } from '../../../components/material-ui/Typography';
import { FormControlLabel } from '../../../components/material-ui/FormControlLabel';
import { Checkbox } from '../../../components/material-ui/Checkbox';

import './DateTimeSetting.scss';
import '../Setting.scss';

const DateTimeSetting = (props) => {
    const { onChange, timePickerType } = props;
    const [value, changeValue] = React.useState(null);
    const handleCheck = React.useCallback((type) => () => onChange(type), []);

    const handleChange = React.useCallback(
        (event, val) => {
            changeValue(removeSeconds(val));
        },
        [name]
    );

    // const handleChangeTest = React.useCallback((value) => {
    //     changeValue(removeSeconds(value));
    // }, []);

    return (
        <React.Fragment>
            <Typography variant="h5" gutterBottom={true}>
                Time picker type
            </Typography>
            <div className="datetime-settings">
                <div className="datetime-settings__time-group-item">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={timePickerType === TimePickerType.NATIVE}
                                onChange={handleCheck(TimePickerType.NATIVE)}
                                disabled={!checkInputTimeSupport()}
                                color="primary"
                            />
                        }
                        label="Native"
                    />
                    <CustomTimePicker
                        name="native_controlled"
                        value={value}
                        onChange={handleChange}
                        label="Time"
                        className="datetime-settings__time-picker"
                        timePickerType={TimePickerType.NATIVE}
                    />
                </div>
                <div className="datetime-settings__time-group-item">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={timePickerType === TimePickerType.SPECIAL}
                                onChange={handleCheck(TimePickerType.SPECIAL)}
                                color="primary"
                            />
                        }
                        label="Manual input"
                    />
                    <CustomTimePicker
                        name="special_controlled"
                        value={value}
                        onChange={handleChange}
                        label="Time"
                        className="datetime-settings__time-picker"
                        timePickerType={TimePickerType.SPECIAL}
                    />
                </div>
                <div className="datetime-settings__time-group-item">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={timePickerType === TimePickerType.MATERIAL}
                                onChange={handleCheck(TimePickerType.MATERIAL)}
                                color="primary"
                            />
                        }
                        label="With clock only"
                    />
                    <CustomTimePicker
                        name="material_controlled"
                        value={value}
                        onChange={handleChange}
                        label="Time"
                        className="datetime-settings__time-picker"
                        timePickerType={TimePickerType.MATERIAL}
                    />
                </div>
            </div>
            {/*<div className="datetime-settings">*/}
            {/*    <div className="datetime-settings__time-group-item">*/}
            {/*        <CustomTimePicker*/}
            {/*            name="special"*/}
            {/*            onChange={handleChangeTest}*/}
            {/*            label="Time"*/}
            {/*            className="datetime-settings__time-picker"*/}
            {/*            timePickerType={TimePickerType.NATIVE}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*    <div className="datetime-settings__time-group-item">*/}
            {/*        <CustomTimePicker*/}
            {/*            name="special"*/}
            {/*            onChange={handleChangeTest}*/}
            {/*            label="Time"*/}
            {/*            className="datetime-settings__time-picker"*/}
            {/*            timePickerType={TimePickerType.SPECIAL}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*    <div className="datetime-settings__time-group-item">*/}
            {/*        <CustomTimePicker*/}
            {/*            name="material"*/}
            {/*            onChange={handleChangeTest}*/}
            {/*            label="Time"*/}
            {/*            className="datetime-settings__time-picker"*/}
            {/*            timePickerType={TimePickerType.MATERIAL}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}
        </React.Fragment>
    );
};

DateTimeSetting.defaultProps = {
    onChange: () => {},
    timePickerType: TimePickerType.NATIVE,
};

const DateTimeSettingMemoized = React.memo(DateTimeSetting);

export { DateTimeSettingMemoized as DateTimeSetting };
