import React from 'react';
import { useDispatch } from 'react-redux';

import { DialogControls } from '../../components/DialogControls/DialogControls';
import { useHideModal } from '../../actions/modal';
import { sendData, sendOtherData } from '../../actions/sended-data';
import { DialogActions, DialogContent, DialogTitle } from '../../components/material-ui/Dialog';
import { Typography } from '../../components/material-ui/Typography';
import { List, ListItem, ListItemText } from '../../components/material-ui/List';
import { Dialog } from '../../components/material-ui/Dialog';
import { useStyles } from './useStyles';
import { useDataForSending } from '../../selectors/sended-data';
import { SendedDataItem } from './SendedDataItem/SendedDataItem';
import { useMobileWidth } from '../../components/hooks/useMobileWidth';

const SendedDataModal = React.forwardRef((props, ref) => {
    const [selectedItems, changeSelectedItems] = React.useState([]);
    const classes = useStyles();
    const hideModal = useHideModal();
    const dispatch = useDispatch();
    const { isMobilePortrait } = useMobileWidth();
    const legs = useDataForSending() || [];

    const handleSendData = React.useCallback(() => {
        dispatch(sendData(selectedItems));
        hideModal();
    }, [selectedItems]);

    const handleSendOtherData = React.useCallback(() => {
        dispatch(sendOtherData(selectedItems));
        hideModal();
    }, [selectedItems]);

    const handleCheck = React.useCallback(
        (id, isChecked) =>
            changeSelectedItems((prevState) => {
                const ids = prevState.slice();
                const pos = prevState.indexOf(id);
                if (!isChecked && pos !== -1) {
                    ids.splice(pos, 1);
                } else if (pos === -1) {
                    ids.push(id);
                }

                return ids;
            }),
        []
    );

    const isSelected = Boolean(selectedItems.length);

    const actions = React.useMemo(
        () => [
            { label: 'Cancel', onClick: hideModal },
            { label: 'Send signs and reports', color: 'primary', onClick: handleSendOtherData },
            {
                label: 'Send all data for selected legs',
                color: 'primary',
                onClick: handleSendData,
                disabled: !isSelected,
            },
        ],
        [isSelected, selectedItems]
    );

    return (
        <Dialog open={true} onClose={hideModal} ref={ref} fullScreen={isMobilePortrait}>
            <DialogTitle disableTypography={true} className={classes.title}>
                <Typography className={classes.title} variant="h6">
                    Select legs for sending or send only signatures and reports
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <List className={classes.list}>
                    {legs && legs.length > 0 ? (
                        legs.map(({ id, hasChanges, warnings, timeWarnings, isCheckListNotCompleted, route, flightNum, pln, etd }) => (
                            <SendedDataItem
                                key={id}
                                checked={selectedItems.indexOf(id) !== -1}
                                onCheck={handleCheck}
                                {...{ id, hasChanges, warnings, timeWarnings, isCheckListNotCompleted, route, flightNum, pln, etd }}
                            />
                        ))
                    ) : (
                        <ListItem key={0} className={classes.item}>
                            <ListItemText primary="There are not legs for sending" />
                        </ListItem>
                    )}
                </List>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <DialogControls actions={actions} />
            </DialogActions>
        </Dialog>
    );
});

export { SendedDataModal };
