import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    actions: {
        padding: theme.spacing(1, 2),

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1, 0.5),
        },
    },
    addInfo: {
        gridArea: 'comment2',
    },
    airport: {
        gridArea: 'airport',
    },
    content: {
        display: 'grid',
        padding: theme.spacing(1, 2),
        gridTemplateAreas: `
            'flight flight flight flight flight flight flight flight flight flight flight flight'
            'group group group group group group type type type type type type'
            'userDate userDate userDate userDate userDate airport airport airport airport value value value'
            'comment comment comment comment comment comment comment comment comment comment comment comment'
            'comment2 comment2 comment2 comment2 comment2 comment2 comment2 comment2 comment2 comment2 comment2 comment2'
            'files files files files files files files files files files files files'`,
        gridTemplateColumns: '8.33% 8.33% 8.33% 8.33% 8.33% 8.33% 8.33% 8.33% 8.33% 8.33% 8.33% 8.33%',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1, 0.5),
            gridTemplateAreas: `
            'flight'
            'group'
            'type'
            'userDate'
            'airport'
            'value'
            'comment'
            'comment2'
            'files'`,
            gridTemplateColumns: '100%',
        },
        '&:first-child': {
            paddingTop: 0,
        },
    },
    error: {
        color: theme.palette.error.main,
    },
    input: {
        height: '1.4em',
    },
    files: {
        gridArea: 'files',
    },
    flight: {
        gridArea: 'flight',
    },
    formControl: {
        margin: theme.spacing(0.5, 0),
        flexBasis: '100%',
    },
    generalInfo: {
        marginTop: -theme.spacing(1),
        gridArea: 'comment',
    },
    group: {
        gridArea: 'group',
    },
    textarea: {
        '& .MuiInput-root': {
            height: 'auto',
        },
    },
    title: {
        padding: theme.spacing(2, 2, 1),

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2, 1, 1),
        },
    },
    type: {
        gridArea: 'type',
    },
    userDate: {
        gridArea: 'userDate',
    },
    value: {
        gridArea: 'value',
    },
    valueInput: {
        [theme.breakpoints.up('sm')]: {
            width: 150,
        },
    },
}));

export { useStyles };
