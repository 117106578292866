import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { getRouteFieldsCount } from '../service/NavPointsUtils';

export const getColumnList = createSelector(
    (state) => get(state, 'routesDeclaration.columnsDeclaration', []),
    (state) => get(state, 'routesDeclaration.customRouteSettings', {}),
    (columns, settings) => {
        if (Object.keys(settings).length === 0) {
            return columns;
        }
        const customColumns = columns.map((column, index) => ({
            ...column,
            mobile: settings[index],
        }));

        return customColumns;
    }
);

export const getNavigationRouteFieldsCount = createSelector(
    getColumnList,
    (state) => get(state, 'screen.mobileEnrouteMode', false),
    (columns, mobileEnrouteMode) => {
        return getRouteFieldsCount(columns, mobileEnrouteMode);
    }
);

export const getFieldsDeclaration = createSelector(
    (state) => get(state, 'routesDeclaration.fieldsDeclaration', {}),
    (fieldsDeclaration) => fieldsDeclaration
);

export const useColumnList = () => useSelector(getColumnList);
export const useFieldsDeclaration = () => useSelector(getFieldsDeclaration);
