import React from 'react';

import BaseIcon from '../BaseIcon';

const ReportAdd = (props) => (
    <BaseIcon {...props} viewBox="0 0 60 60">
        <path d="M12.5,24h25c0.552,0,1-0.448,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.448-1,1S11.948,24,12.5,24z" />
        <path d="M12.5,16h10c0.552,0,1-0.448,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.448-1,1S11.948,16,12.5,16z" />
        <path d="M12.5,32h25c0.552,0,1-0.448,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.448-1,1S11.948,32,12.5,32z" />
        <path d="M29.5,38h-17c-0.552,0-1,0.448-1,1s0.448,1,1,1h17c0.552,0,1-0.448,1-1S30.052,38,29.5,38z" />
        <path d="M26.5,46h-14c-0.552,0-1,0.448-1,1s0.448,1,1,1h14c0.552,0,1-0.448,1-1S27.052,46,26.5,46z" />
        <path
            d="M48.5,34.363V14.586L33.914,0H1.5v60h44c7.168,0,13-5.832,13-13C58.5,40.866,54.224,35.723,48.5,34.363z M34.5,3.414
            L45.086,14H34.5V3.414z M38.578,58H3.5V2h29v14h14v18.044C46.158,34.015,45.826,34,45.5,34c-7.168,0-13,5.832-13,13
            c0,0.399,0.025,0.792,0.06,1.183c0.008,0.091,0.017,0.181,0.027,0.272c0.043,0.382,0.098,0.76,0.173,1.131
            c0.009,0.044,0.021,0.087,0.03,0.131c0.072,0.338,0.159,0.67,0.257,0.998c0.025,0.082,0.048,0.165,0.074,0.246
            c0.113,0.352,0.239,0.698,0.38,1.037c0.027,0.064,0.057,0.126,0.084,0.189c0.129,0.296,0.269,0.585,0.419,0.869
            c0.036,0.068,0.07,0.137,0.107,0.205c0.175,0.317,0.363,0.626,0.564,0.927c0.046,0.069,0.094,0.135,0.141,0.203
            c0.183,0.264,0.375,0.521,0.576,0.77c0.038,0.047,0.074,0.096,0.113,0.143c0.231,0.278,0.475,0.544,0.728,0.801
            c0.062,0.063,0.125,0.124,0.189,0.186c0.245,0.239,0.496,0.471,0.759,0.69c0.023,0.02,0.045,0.041,0.069,0.06
            c0.282,0.232,0.577,0.449,0.879,0.658c0.073,0.051,0.147,0.1,0.221,0.149C38.427,57.897,38.501,57.951,38.578,58z M45.5,58
            c-6.065,0-11-4.935-11-11s4.935-11,11-11c0.312,0,0.62,0.021,0.926,0.047c0.291,0.028,0.592,0.066,0.909,0.119l0.443,0.074
            C52.753,37.293,56.5,41.716,56.5,47C56.5,53.065,51.565,58,45.5,58z"
        />
        <path
            d="M51.5,46h-5v-5c0-0.552-0.448-1-1-1s-1,0.448-1,1v5h-5c-0.552,0-1,0.448-1,1s0.448,1,1,1h5v5c0,0.552,0.448,1,1,1
            s1-0.448,1-1v-5h5c0.552,0,1-0.448,1-1S52.052,46,51.5,46z"
        />
    </BaseIcon>
);

export default ReportAdd;
