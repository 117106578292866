import React from 'react';

import { OfpList } from './OfpList/OfpList';
import { Panel } from '../Panel/Panel';
import { StartWorkTime } from './StartWorkTime/StartWorkTime';

import './StartInfoPage.scss';

const StartInfoPage = () => {
    return (
        <div className="start-info">
            <Panel title="OFP list" className="start-info__ofp-list">
                <OfpList />
            </Panel>
            <div className="start-info__panels">
                <StartWorkTime />
            </div>
        </div>
    );
};

export { StartInfoPage };
