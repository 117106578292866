import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'block',
        width: '100%',
    },
    secondaryText: {
        fontSize: theme.textField.textFontSize,
        color: theme.textField.textColor,
        fontWeight: 'normal',
    },
}));

export { useStyles };
