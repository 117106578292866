import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    actions: {
        padding: theme.spacing(1, 2),

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1, 0.5),
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        minWidth: 300,
        padding: theme.spacing(1, 2),

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
        },
    },
    radio: {
        padding: theme.spacing(1, 0),
        fontSize: '18px',
    },
    title: {
        padding: theme.spacing(2, 2, 1),

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2, 1, 1),
        },
    },
    wrapper: {
        flexGrow: 1,
    },
}));

export { useStyles };
