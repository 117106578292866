import React from 'react';

import BaseIcon from '../BaseIcon';

const Star = (props) => (
    <BaseIcon {...props} viewBox="0 0 612 612">
        <polygon
            points="387.674,238.179 305.583,0 221.154,236.26 0,238.179 181.82,389.121 116.475,612 305.583,476.692 494.69,612
		429.318,390.567 612,238.179"
        />
    </BaseIcon>
);

export default Star;
