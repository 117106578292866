import React from 'react';

import BaseIcon from '../BaseIcon';

const AirModeOff = (props) => (
    <BaseIcon {...props} viewBox="0 0 510 510">
        <path
            d="M293.25,178.5V38.25C293.25,17.85,275.4,0,255,0c-20.4,0-38.25,17.85-38.25,38.25v94.35l198.9,198.9l81.6,25.5v-51
			L293.25,178.5z M38.25,84.15l127.5,127.5L12.75,306v51l204-63.75V433.5l-51,38.25V510L255,484.5l89.25,25.5v-38.25l-51-38.25
			v-94.35L438.6,484.5l33.15-33.15L71.4,51L38.25,84.15z"
        />
    </BaseIcon>
);

export default AirModeOff;
