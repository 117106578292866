import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { MoreVertIconContrast } from '../../../icons/MoreVertIconContrast';
import { TYPES, useShowModal } from '../../../actions/modal';
import { cancelDeleteReportData, deleteReportData } from '../../../actions/reports';
import { ListItem, ListItemSecondaryAction, ListItemText } from '../../material-ui/List';
import { IconButton } from '../../material-ui/IconButton';
import { Menu, MenuItem } from '../../material-ui/Menu';
import { useStyles } from './useStyles';
import { useAnchorEl } from '../../hooks/useAnchorEl';

const ReportListItem = (props) => {
    const { report } = props;
    const {
        id,
        flightName,
        flightDate,
        number,
        createdDate,
        safetyGroupTypeName,
        safetyTypeName,
        statusType,
        statusDate,
        closedDate,
        receiptDate,
        isRemoved,
        isEdited,
        isNew,
    } = report;
    const showModal = useShowModal();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [anchorEl, handleOpenMenu, handleClose] = useAnchorEl();
    const primary = `${flightName} (${moment(flightDate).format('DD.MM.YYYY')}) Report № ${number || '–'} (${moment
        .utc(createdDate)
        .format('DD.MM.YYYY HH:mm')})`;
    const secondaryText = `${safetyGroupTypeName} / ${safetyTypeName}`;
    const statusText = closedDate
        ? `Closed (${moment.utc(closedDate).format('DD.MM.YYYY HH:mm')})`
        : statusType
            ? `${statusType} (${moment.utc(statusDate).format('DD.MM.YYYY HH:mm')})`
            : '';
    const className = isRemoved
        ? classes.listItemRemoved
        : receiptDate
            ? classes.listItemDisabled
            : isEdited || isNew
                ? classes.listItemEdited
                : {};

    const handleClick = React.useCallback(() => {
        showModal(TYPES.REPORT, {
            report,
            title: 'Edit report dialog',
        });
        handleClose();
    }, [report]);

    const handleDelete = React.useCallback(() => {
        dispatch(deleteReportData(report.id));
        handleClose();
    }, [report.id]);

    const handleCancelDelete = React.useCallback(() => {
        dispatch(cancelDeleteReportData(report.id));
        handleClose();
    }, [report.id]);

    const handleDetailClick = React.useCallback(() => {
        showModal(TYPES.REPORT_DETAIL, { report });
        handleClose();
    }, [report]);

    return (
        <>
            <ListItem id={id} disabled={!!receiptDate || isRemoved} button={true} className={className} onClick={handleClick}>
                <ListItemText
                    primary={
                        <span className={classes.reportWrapper}>
                            <span className={classes.report}>{primary}</span>
                            <span className={classes.status}>{statusText}</span>
                        </span>
                    }
                    secondary={<span className={classes.secondaryText}>{secondaryText}</span>}
                />
                <ListItemSecondaryAction>
                    <IconButton style={{ padding: '9px' }} onClick={handleOpenMenu}>
                        <MoreVertIconContrast />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {!isRemoved && <MenuItem onClick={handleDetailClick}>Show details</MenuItem>}
                {!isRemoved && (
                    <MenuItem onClick={handleDelete} disabled={!!receiptDate}>
                        Delete report
                    </MenuItem>
                )}
                {isRemoved && <MenuItem onClick={handleCancelDelete}>Cancel deletion</MenuItem>}
            </Menu>
        </>
    );
};

const ReportListItemMemoized = React.memo(ReportListItem);

export { ReportListItemMemoized as ReportListItem };
