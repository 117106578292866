import * as React from 'react';
import Slide from '@material-ui/core/Slide';

const CustomSlide = React.forwardRef((props, ref) => {
    return <Slide ref={ref} {...props} />;
});

const TransitionUp = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

export { TransitionUp, CustomSlide as Slide };
