import React from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';

import { TYPES, useShowModal } from '../../../../actions/modal';
import { useStyles } from './useStyles';
import { Typography } from '../../../material-ui/Typography';
import { MoreVertIconContrast } from '../../../../icons/MoreVertIconContrast';
import { IconButton } from '../../../material-ui/IconButton';

const NavigationRouteHold = ({ columnsCount, point, offset, pos, onMenuOpen }) => {
    const dispatch = useDispatch();
    const showModal = useShowModal();
    const classes = useStyles();
    const { startHoldTime, endHoldTime, act, comment } = point;
    const dataClassName = `enr__tr${comment ? '' : '  enr__tr--end'}`;

    const handleClick = () => dispatch(showModal(TYPES.HOLD, { point, pos }));

    const handleOpenMenu = (event) => {
        onMenuOpen(event, {
            point,
            offset,
            pos,
        });
    };

    const rows = [
        <tr className="enr__tr  enr__tr--bordered" key={0}>
            <td className="enr__td" colSpan={columnsCount - 1}>
                <Typography className={classes.title}>Being in the HOLD</Typography>
            </td>
            <td className={classnames('enr__td', 'enr__table-menu', classes.menuWrapper)} rowSpan={2}>
                <IconButton style={{ padding: '9px' }} onClick={handleOpenMenu}>
                    <MoreVertIconContrast />
                </IconButton>
            </td>
        </tr>,
        <tr className={dataClassName} key={1}>
            <td className={classnames('enr__td', classes.text)} colSpan={columnsCount - 3}>
                <div className="enr__td-wrapper">
                    <Typography display="inline" className={classnames('enr__td', classes.simple)}>
                        Start time:{' '}
                    </Typography>
                    <Typography display="inline" className={classnames('enr__td', classes.bold)}>
                        {startHoldTime ? moment.utc(startHoldTime).format('DD.MM.YYYY HH:mm') : 'missing'}
                    </Typography>
                    <Typography display="inline" className={classnames('enr__td', 'enr__td--start', classes.simple)}>
                        {'End time: '}
                    </Typography>
                    <Typography display="inline" className={classnames('enr__td', classes.bold)}>
                        {endHoldTime ? moment.utc(endHoldTime).format('DD.MM.YYYY HH:mm') : 'missing'}
                    </Typography>
                </div>
            </td>
            <td className={classnames('enr__td', classes.bold)} colSpan={2}>
                <Typography display="inline">{act ? (+act / 1000).toFixed(2) : ''}</Typography>
            </td>
        </tr>,
    ];

    if (comment) {
        rows.push(
            <tr className="enr__tr  enr__tr--end" key={2}>
                <td className="enr__td  enr__td--multiple_row" colSpan={columnsCount}>
                    {comment}
                </td>
            </tr>
        );
    }

    return (
        <tbody className="enr__item" onClick={handleClick}>
            {rows}
        </tbody>
    );
};

export { NavigationRouteHold };
