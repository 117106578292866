import { CustomError } from './CustomError';

class OtherError extends CustomError {
    constructor({ message, code, stack, data, url, text, method, info }) {
        super({ message, code, stack, data, url, text, method, info });
        this.type = 'Other Error';
    }
}

export { OtherError };
