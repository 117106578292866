import moment from 'moment';

const version = () => ({
    NUMBER: '4.1.12',
    date: moment('2024-07-31'),
    copyright: `АвиаБит 2000-${moment().year()}`,
    toString: function () {
        return `v${this.NUMBER} / ${this.date.format('DD.MM.YY')}`;
    },
    toObject: function () {
        const ver = this.NUMBER.split('.');

        return {
            version: ver[1],
            subversion: ver[2],
            date: this.date.toISOString(),
            fullVersion: ver,
        };
    },
});

export default version();
