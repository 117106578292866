import React from 'react';

import BaseIcon from '../BaseIcon';

const Send = (props) => (
    <BaseIcon {...props} viewBox="0 0 16 16">
        <path d="M6 5v6h4v-6h2l-4-5-4 5z" />
        <path d="M13 2h-2l0.9 1h0.4l2.6 8h-3.9v2h-6v-2h-3.9l2.6-8h0.4l0.9-1h-2l-3 9v5h16v-5z" />
    </BaseIcon>
);

export default Send;
