import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withSnackbar } from 'notistack';

import { SnackMessage } from './SnackMessages/SnackMessages';
import { NotificationTypes, removeNotification } from '../../actions/notifications';

function Notifications({ enqueueSnackbar }) {
    const [displayed, changeDisplayed] = React.useState([]);
    const dispatch = useDispatch();
    const notifications = useSelector((state) => state.notifications || []);

    const storeDisplayed = (key) => {
        changeDisplayed([...displayed, key]);
    };

    notifications.forEach(({ message, key, variant, details }) => {
        setTimeout(() => {
            if (displayed.indexOf(key) > -1) {
                return;
            }
            // Display notification using notistack
            enqueueSnackbar(message, {
                persist: variant === NotificationTypes.ERROR,
                autoHideDuration: 5000,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
                preventDuplicate: true,
                content: (id) => <SnackMessage id={id} message={message} variant={variant} details={details} />,
            });
            // Add notification's key to the local state
            storeDisplayed(key);
            // Dispatch action to remove the notification from the redux store
            dispatch(removeNotification(key));
        }, 1);
    });

    return null;
}

const withSnackbarNotifications = withSnackbar(Notifications);

export { withSnackbarNotifications as Notifications };
