import React from 'react';

import { getPointFontSize } from '../../../../../service/NavPointsUtils';
import { PointCell } from '../PointCell/PointCell';
import { PointMenuTypes } from '../../../RouteItemMenu/RouteItemMenu';
import { MoreVertIconContrast } from '../../../../../icons/MoreVertIconContrast';
import { IconButton } from '../../../../material-ui/IconButton';

const PointRow = (props) => {
    const {
        point,
        columnsDeclaration,
        mobileEnrouteMode = false,
        showMenu,
        pos,
        index,
        offset,
        multieditFrom,
        multieditTo,
        onMenuOpen,
    } = props;
    const isCommentExist = Boolean(point.comment);

    const fontSize = getPointFontSize(mobileEnrouteMode, columnsDeclaration);
    const columns = columnsDeclaration.filter((item) => !item.hidden && ((item.mobile && mobileEnrouteMode) || !mobileEnrouteMode)).slice();
    let className = 'enr__tr';

    switch (index) {
        case 0:
            className += ' enr__tr--bordered';
            break;
        case 2:
            if (!isCommentExist && props.point && props.point.isDirectionFrom) {
                className += ' enr__tr--end-bordered';
            } else if (!isCommentExist) {
                className += ' enr__tr--end';
            }
            break;
    }

    const handleOpenMenu = (event) => {
        onMenuOpen(event, {
            point,
            offset,
            pos,
            multieditFrom,
            multieditTo,
            type: point.isRemoveFromDirecting ? PointMenuTypes.DIRECTED : PointMenuTypes.POINT,
        });
    };

    return (
        <tr key={index} className={className} style={{ fontSize }}>
            {columns.map((column, pos) => {
                const setColSpan = pos === columns.length - 1 && index >= 2;

                return <PointCell key={pos} declaration={column.items[index]} {...{ pos, setColSpan, point }} />;
            })}
            {index === 0 && showMenu && (
                <td className="enr__td" rowSpan={2} style={{ width: '48px', verticalAlign: 'middle', textAlign: 'center', padding: 0 }}>
                    <IconButton style={{ padding: '9px' }} onClick={handleOpenMenu}>
                        <MoreVertIconContrast />
                    </IconButton>
                </td>
            )}
        </tr>
    );
};

export { PointRow };

//
// handleChangeDirection = () => {
//     const {
//         point: { isRemoveFromDirecting },
//     } = this.props;
//
//     this.props.actions.showDirectModal({
//         title: 'direct',
//         pointPosFrom: this.props.pos,
//         editMode: isRemoveFromDirecting,
//     });
// };
//
