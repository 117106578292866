import React from 'react';
import { get } from 'lodash';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../../components/material-ui/Dialog';
import { TextField } from '../../../components/material-ui/TextField';
import { MenuItem } from '../../../components/material-ui/Menu';
import { FormControl } from '../../../components/material-ui/FormControl';
import { useInputs } from '../../../components/hooks/useInputs';
import { Select } from '../../../components/material-ui/Select';
import { InputLabel } from '../../../components/material-ui/InputLabel';
import { Input } from '../../../components/material-ui/Input';
import { useStyles } from './useStyles';
import { Scope } from '../constants';
import { DialogControls } from '../../../components/DialogControls/DialogControls';

const FilterNoticeModal = (props) => {
    const { hideModal, open, onOk, data } = props;
    const initialData = React.useMemo(() => ({ scope: -1, ...data }), []);
    const { inputs, handleInputChange, setInputs } = useInputs({ initialData });
    const classes = useStyles();

    const handleOk = () => {
        onOk(inputs);
    };

    const handleChange = React.useCallback((dt) => {
        handleInputChange(dt);
    }, []);

    const handleReset = React.useCallback(() => {
        onOk(initialData);
        setInputs(initialData);
    }, []);

    const actionList = [
        {
            label: 'Close',
            onClick: hideModal,
        },
        {
            label: 'Clear',
            onClick: handleReset,
        },
        {
            label: 'Filter',
            color: 'primary',
            onClick: handleOk,
        },
    ];

    return (
        <Dialog open={open} className="notice-filter-dlg" style={{ zIndex: 1600 }}>
            <DialogTitle className={classes.title}>Filter dialog</DialogTitle>
            <DialogContent className={classes.container}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="scopeLabel">Scope</InputLabel>
                    <Select
                        labelId="scopeLabel"
                        id="scope"
                        name="scope"
                        value={+get(inputs, 'scope', -1)}
                        onChange={handleChange}
                        input={<Input />}
                        MenuProps={{ style: { zIndex: 1600 } }}
                    >
                        <MenuItem value={-1}>All</MenuItem>
                        {Object.keys(Scope).map((key) => {
                            const { nameEng } = Scope[key];

                            return (
                                <MenuItem value={+key} key={key}>
                                    {nameEng}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <TextField
                        name="number"
                        fullWidth={true}
                        label="Number"
                        value={get(inputs, 'number', '')}
                        onChange={handleInputChange}
                    />
                </FormControl>
                <FormControl className={classes.formControl}>
                    <TextField
                        name="text"
                        fullWidth={true}
                        label="Find text"
                        value={get(inputs, 'text', '')}
                        onChange={handleInputChange}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions className={classes.controls}>
                <DialogControls actions={actionList} />
            </DialogActions>
        </Dialog>
    );
};

const FilterNoticeModalMemoized = React.memo(FilterNoticeModal);

export { FilterNoticeModalMemoized as FilterNoticeModal };
