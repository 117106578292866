import { makeStyles } from '@material-ui/styles';

export const useStyles = (labelTextDate, labelTextTime) =>
    makeStyles(() => ({
        container: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        datePicker: {
            width: 'auto',
            flexBasis: '65%',
            height: labelTextDate ? '72px' : '40px',
        },
        timePicker: {
            width: 'auto',
            height: labelTextTime ? '72px' : '40px',
            minWidth: 110,
            flexBasis: '35%',
        },
    }));
