import React from 'react';

import BaseIcon from '../BaseIcon';

const Arrow = (props) => (
    <BaseIcon {...props} viewBox="0 0 404.257 404.257">
        <polygon points="386.257,114.331 202.128,252.427 18,114.331 0,138.331 202.128,289.927 404.257,138.331 " />
    </BaseIcon>
);

export default Arrow;
