import React from 'react';

const PanelControls = (props) => {
    const { controls } = props;

    return <div>{controls && controls.map((item) => item)}</div>;
};

const PanelControlsMemoized = React.memo(PanelControls);

export { PanelControlsMemoized as PanelControls };
