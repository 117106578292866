import React from 'react';

import BaseIcon from '../BaseIcon';

const Hold = (props) => (
    <BaseIcon {...props} viewBox="0 0 40 40" stroke={props.color} strokeWidth="3px">
        <path
            fill="none"
            d="M2 23.18c0,3.33 0,6.66 3,8.33 3,1.67 9,1.67 15,1.67 6,0 12,0 15,-1.67
            3,-1.67 3,-5 3,-8.33 0,-3.34 0,-6.67 -3,-8.34 -3,-1.66 -9,-1.66 -15,-1.66 -6,0 -12,0
            -15,1.66 -3,1.67 -3,5 -3,8.34z"
        />
        <circle cx="34.15" cy="14.35" r="3.85" />
        <polygon points="21,13.43 15.83,17.04 10.66,20.65 10.69,13.39 10.71,6.13 15.86,9.78 " />
    </BaseIcon>
);

export default Hold;
