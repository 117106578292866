import moment from 'moment';
import { get } from 'lodash';

import { getUrl } from '../../plugins/tools';
import { handleCatchError, handleReject, handleResponse } from './BackendRequestsFacadeViaFetch';
import { Method } from './constants';

const BACKEND_SEND_LOGS_PATH = 'save-app-logs';

class ErrorLogsSender {
    constructor() {
        this._store = null;
        this._filePlugin = null;
    }

    init(store, filePlugin) {
        this._store = store;
        this._filePlugin = filePlugin;
        this._sendLogs.bind(this);

        this._checkLogsForSend();

        if (!ErrorLogsSender.instance) {
            ErrorLogsSender.instance = this;
            Object.freeze(ErrorLogsSender.instance);
        }
        return ErrorLogsSender.instance;
    }

    addLog(log) {
        this._saveLog(log);
    }

    _getUrl() {
        return getUrl() + BACKEND_SEND_LOGS_PATH;
    }

    _checkLogsForSend() {
        const logs = get(this._store.getState(), 'logs', []);
        if (logs.length) {
            this._sendLogs();
        }
    }

    _saveLog(log) {
        if (this._store) {
            const store = this._store.getState();
            const { data, message, stack, ...rest } = log;
            const dateCreate = moment();
            const user = get(store, 'login.user.id');
            const { isGpsOn, position, offset } = get(store, 'gps', { isGpsOn: false, position: null, offset: 0 });
            const latitude = isGpsOn ? position.latitude : null;
            const longitude = isGpsOn ? position.longitude : null;
            const dataSize = Math.round(new TextEncoder().encode(JSON.stringify(data)).byteLength / 1024);
            this._writeError({
                ...rest,
                message,
                stack,
                dateCreate,
                user,
                isGpsOn,
                latitude,
                longitude,
                offset,
                dataSize,
            });
        }
    }

    _checkAuth() {
        return Boolean(get(this._store.getState(), 'login.user.id', null));
    }

    sendLogs() {
        this._filePlugin
            .getFilesList()
            .then((files) => {
                return Promise.all(
                    files
                        .filter((file) => file.name.indexOf('fetchError') === 0)
                        .map((file) => {
                            const fileName = file.name;
                            return this._filePlugin
                                .readFileAsString(fileName)
                                .then((content) => {
                                    return JSON.parse(content);
                                })
                                .catch((error) => console.log(error));
                        })
                );
            })
            .then((logs) => this._sendLogs(logs));
    }

    _sendLogs(logs) {
        const isAuth = this._checkAuth();
        if (logs.length && isAuth) {
            fetch(this._getUrl(), {
                body: JSON.stringify({ logs }),
                method: Method.POST,
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
                .then(
                    (res) => handleResponse(res, BACKEND_SEND_LOGS_PATH, Method.POST, logs),
                    (err) => handleReject(err, BACKEND_SEND_LOGS_PATH, Method.POST, logs)
                )
                .then(() => this._clearLogs())
                .catch((err) => handleCatchError(err, BACKEND_SEND_LOGS_PATH, Method.POST, logs));
        }
    }

    _clearLogs() {
        this._filePlugin.getFilesList().then((files) => {
            return Promise.all(
                files
                    .filter((file) => file.name.indexOf('fetchError') === 0)
                    .map((file) => {
                        const fileName = file.name;
                        this._filePlugin.remove(fileName).catch((error) => console.log(error));
                    })
            );
        });
    }

    _writeError({
        type,
        status,
        path,
        method,
        stack,
        message,
        text,
        dateCreate,
        isGpsOn,
        latitude,
        longitude,
        offset,
        dataSize,
        attachmentsSize,
        attachmentsCount,
        user,
    }) {
        const time = Date.now().toString();
        this._filePlugin
            .readFileAsString('device')
            .then((content) => {
                const { userId, version } = JSON.parse(content || {});

                this._filePlugin.write(
                    `fetchError-${time}`,
                    new Blob(
                        [
                            JSON.stringify({
                                time,
                                type,
                                status,
                                path,
                                method,
                                stack,
                                message,
                                text,
                                dateCreate,
                                isGpsOn,
                                latitude,
                                longitude,
                                offset,
                                dataSize,
                                attachmentsSize,
                                attachmentsCount,
                                version,
                                userId: userId || user,
                            }),
                        ],
                        {
                            type: 'application/json',
                        }
                    )
                );
            })
            .catch((err) => {
                console.log('Device data is undefined');
                this._filePlugin.write(
                    `action-${time}`,
                    new Blob(
                        [
                            JSON.stringify({
                                err,
                                stack,
                                time,
                            }),
                        ],
                        { type: 'application/json' }
                    )
                );
            });
    }
}

const ErrorLogsSenderInstanse = new ErrorLogsSender();

export default ErrorLogsSenderInstanse;
