import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { get } from 'lodash';

import { IconButton } from '../material-ui/IconButton';
import { Popover } from '../material-ui/Popover';
import { Switch } from '../material-ui/Switch';
import AirModeOn from '../../icons/image/AirModeOn';
import AirModeOff from '../../icons/image/AirModeOff';
import { TextFieldControlled } from '../TextFieldControlled';
import { getCoordinates } from '../../service/NavPointsUtils';
import { getGpsQuality, GpsQuality } from '../../selectors/gps';
import { useAnchorEl } from '../hooks/useAnchorEl';
import { toggleGps } from '../../actions/gps';
import { useStyles } from './useStyles';
import { FormControlLabel } from '../material-ui/FormControlLabel';
import { Typography } from '../material-ui/Typography';

const fields = [
    { id: 'latitude' },
    { id: 'longitude' },
    { id: 'altitude' },
    { id: 'speed' },
    { id: 'accuracy' },
    { id: 'altitudeAccuracy', name: 'Alt accuracy' },
];

const COEF = 1.852;

const getValue = (position, id) => {
    let value = +(position || {})[id];
    if (!value || isNaN(value)) {
        return '-';
    }

    switch (id) {
        case 'latitude':
            return getCoordinates(value, 'lat');
        case 'longitude':
            return getCoordinates(value, 'long');
        case 'altitude':
            return `${Math.round(value)} ft`;
        case 'speed': {
            const speed = Math.round((value * 3600) / (1000 * COEF));
            return `${speed} kn`;
        }
    }

    return Math.round(value);
};

const getGpsColor = (gpsQuality, theme) => {
    if (gpsQuality === GpsQuality.GOOD) {
        return theme.icon.staticWhite;
    } else if (gpsQuality === GpsQuality.NORMAL) {
        return theme.icon.attention;
    }

    return theme.icon.warning;
};

const GpsPanel = () => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [anchorEl, handleOpenMenu, handleClose] = useAnchorEl();

    const { isGpsOn, position, gpsQuality } = useSelector(
        (state) => ({
            isGpsOn: get(state, 'gps.isGpsOn', false),
            position: get(state, 'gps.position'),
            gpsQuality: getGpsQuality(state),
        }),
        shallowEqual
    );

    const handleToggleGPS = React.useCallback(() => {
        dispatch(toggleGps());
    }, []);

    return (
        <>
            <IconButton onClick={handleOpenMenu} className={classes.button}>
                {isGpsOn ? (
                    <AirModeOn width="24" height="24" color={getGpsColor(gpsQuality, theme)} />
                ) : (
                    <AirModeOff width="24" height="24" color={theme.icon.staticWhite} />
                )}
            </IconButton>
            <Popover
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <div className={classes.panel}>
                    <FormControlLabel
                        control={<Switch checked={isGpsOn} onChange={handleToggleGPS} color="primary" />}
                        label="Enable location"
                        labelPlacement="end"
                        classes={{ root: classes.checkbox }}
                    />
                    {fields.map(({ id, name }, index) => (
                        <TextFieldControlled
                            key={index}
                            name={id}
                            label={(name || id).toUpperCase()}
                            placeholder="-"
                            defaultValue={getValue(position, id)}
                            className={classes.panelItem}
                            margin="normal"
                            disabled={true}
                        />
                    ))}
                    <Typography className={classes.notice}>* location information for advisory only</Typography>
                </div>
            </Popover>
        </>
    );
};

const GpsPanelMemoized = React.memo(GpsPanel);
export { GpsPanelMemoized as GpsPanel };
