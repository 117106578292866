import React from 'react';
import { useDispatch } from 'react-redux';

import { TextField } from '../../../material-ui/TextField';
import * as landing from '../../../../actions/landing';

const AdditionalField = ({ required, defaultValue, className }) => {
    const dispatch = useDispatch();

    const handleChangeComment = React.useCallback((evt) => {
        dispatch(landing.editField('fuelComment', evt.target.value));
    }, []);

    return (
        <TextField
            label="Additional fuel info"
            placeholder="Additional fuel info"
            name="fuelComment"
            onBlur={handleChangeComment}
            key="fuelComment"
            fullWidth={true}
            defaultValue={defaultValue}
            multiline={true}
            className={className}
            InputLabelProps={{
                shrink: true,
            }}
            minRows={1}
            maxRows={3}
            required={required}
        />
    );
};

const AdditionalFieldMemoized = React.memo(AdditionalField);

export { AdditionalFieldMemoized as AdditionalField };
