import React from 'react';
import classnames from 'classnames';

import { PanelIconMenu } from './PanelIconMenu/PanelIconMenu';
import { PanelSearch } from './PanelSearch/PanelSearch';
import { PanelIcon } from './PanelIcon/PanelIcon';
import { PanelControls } from './PanelControls/PanelControls';
import { PanelToggleControl } from './PanelToggleControl/PanelToggleControl';
import { Typography } from '../material-ui/Typography';
import { Toolbar } from '../material-ui/Toolbar';
import { Paper } from '../material-ui/Paper';
import { useStyles } from './useStyles';

import './Panel.scss';

const Panel = (props) => {
    const {
        contentStyle,
        style,
        search,
        iconMenuImg,
        iconMenuItems,
        onIconClick,
        icon,
        leftIcon,
        rightText,
        controls,
        toggle,
        title,
        className,
        children,
        customToolbar,
        toolbarClassName,
        contentClassName,
    } = props;
    const classes = useStyles();

    return (
        <Paper className={classnames(className, 'panel')} style={style}>
            <Toolbar className={classnames('panel__toolbar', classes.toolbar, toolbarClassName)}>
                {customToolbar ||
                    (title && (
                        <div>
                            <Typography className={classes.toolbarTitle} display="inline">
                                {title}
                            </Typography>
                            {leftIcon && <PanelIcon {...{ onIconClick, icon: leftIcon }} />}
                        </div>
                    ))}
                {search && <PanelSearch {...search} />}
                {toggle && <PanelToggleControl {...toggle} />}
                {controls && <PanelControls {...{ controls }} />}
                {icon && <PanelIcon {...{ onIconClick, icon }} />}
                {rightText && <Typography>{rightText}</Typography>}
                {iconMenuImg && iconMenuItems && <PanelIconMenu {...{ iconMenuImg, iconMenuItems }} />}
            </Toolbar>
            <div className={classnames(classes.content, contentClassName)} style={contentStyle}>
                {children}
            </div>
        </Paper>
    );
};

const PanelMemoized = React.memo(Panel);

export { PanelMemoized as Panel };
