import React from 'react';
import classnames from 'classnames';

import { Button } from '../../material-ui/Button';
import { useStyles } from './useStyles';

const Filter = React.forwardRef(({ onFilter, children, onClear, className }, ref) => {
    const classes = useStyles();
    const classNames = classnames(classes.filter, className);

    return (
        <div className={classNames} ref={ref}>
            {children}
            <Button className={classes.filterButton} variant="contained" color="primary" onClick={onFilter}>
                filter
            </Button>
            <Button className={classes.filterClearButton} variant="contained" {...(onClear && { onClick: onClear })}>
                clear
            </Button>
        </div>
    );
});

const FilterMemorized = React.memo(Filter);

export { FilterMemorized as Filter };
