import { useDispatch } from 'react-redux';

const ADD_FIELDS = 'ADD_FIELDS';
const SET_DIMENSION = 'SET_DIMENSION';
const SET_NEED_TO_CONVERT = 'SET_NEED_TO_CONVERT';
const SET_INITIAL_OFP = 'SET_INITIAL_OFP';
const RESET_IS_OFP_CHANGED = 'RESET_IS_OFP_CHANGED';
const ADD_TO_HISTORY = 'ADD_TO_HISTORY';
const CLEAR_HISTORY_FOR_LEG = 'CLEAR_HISTORY_FOR_LEG';

const addFields = ({ fields }) => {
    return {
        type: ADD_FIELDS,
        update: {
            fields,
        },
    };
};

const setDimension = ({ dimension }) => ({
    type: SET_DIMENSION,
    dimension,
});

const setInitialOfp = (ofpId) => {
    return { type: SET_INITIAL_OFP, ofpId };
};

const resetIsOfpIdChanged = () => ({ type: RESET_IS_OFP_CHANGED });

const useResetIsOfpChanged = () => {
    const dispatch = useDispatch();

    return () => dispatch(resetIsOfpIdChanged());
};

const addWeightUnitsToHistory = ({ weightUnitsState, legID }) => ({
    type: ADD_TO_HISTORY,
    payload: { weightUnitsState, legID },
});
const useAddWeightUnitsToHistory = () => {
    const dispatch = useDispatch();

    return ({ weightUnitsState, legID }) => dispatch(addWeightUnitsToHistory({ weightUnitsState, legID }));
};

const clearWeightsUnitsHistory = (legIds) => ({ type: CLEAR_HISTORY_FOR_LEG, legIds });

export {
    addFields,
    addWeightUnitsToHistory,
    clearWeightsUnitsHistory,
    setDimension,
    setInitialOfp,
    resetIsOfpIdChanged,
    useResetIsOfpChanged,
    useAddWeightUnitsToHistory,
    ADD_FIELDS,
    ADD_TO_HISTORY,
    CLEAR_HISTORY_FOR_LEG,
    SET_NEED_TO_CONVERT,
    SET_DIMENSION,
    SET_INITIAL_OFP,
    RESET_IS_OFP_CHANGED,
};
