import { createSelector } from 'reselect';
import { get } from 'lodash';

import { getCurrentLeg } from './screen';
import { sortPassengerSeats } from '../service/sort';
import { FilterTypes } from '../reducers/filter';

const checkFilter = ({ type, ssrsList }, { passengerType, ssr }) => {
    let isFiltering = true;
    const ssrs = ssrsList && Object.values(ssrsList).map((i) => i.code);
    const isNoSsr = ssr && ssr.some((value) => value === '');

    if (passengerType && passengerType.indexOf(type) === -1) {
        isFiltering = false;
    } else if (ssr && ((!ssrs && !isNoSsr) || (ssrs && !ssr.some((i) => ssrs.includes(i))))) {
        isFiltering = false;
    }

    return isFiltering;
};

const getPassengerList = createSelector(
    getCurrentLeg,
    (state) => get(state, `filter[${FilterTypes.PASSENGERS}]`, {}),
    (currentLeg, { passengerType, ssr }) => {
        const filter = {
            passengerType: passengerType && passengerType.length ? passengerType : null,
            ssr: ssr && ssr.length ? ssr : null,
        };

        return (
            currentLeg &&
            currentLeg.passengerList &&
            Object.keys(currentLeg.passengerList).reduce((res, key) => {
                const item = currentLeg.passengerList[key];

                res[key] = {
                    ...item,
                    isFiltering: checkFilter(item, filter),
                };

                return res;
            }, {})
        );
    }
);

const getPlnCabin = createSelector(getCurrentLeg, (currentLeg) => {
    return currentLeg && currentLeg.plnCabin;
});

const getPassengerSeats = createSelector(getPassengerList, getPlnCabin, (passengerList, plnCabin) => {
    if (!passengerList || !plnCabin || plnCabin.length === 0) {
        return;
    }

    const list =
        passengerList &&
        Object.keys(passengerList).reduce((res, key) => {
            const item = passengerList[key];

            if (item.seats && !res[item.seats]) {
                res[item.seats] = item;
            }

            return res;
        }, {});

    const result = plnCabin.reduce((res, item) => {
        const { row, name } = item;
        const seats = row && name && `${row}${name}`;

        if (!res[row]) {
            res[row] = [];
        }

        res[row].push({ ...item, passenger: list[seats] });

        return res;
    }, {});

    Object.keys(result).forEach((key) => {
        result[key].sort(sortPassengerSeats);
    });

    return result;
});

const getPassengerListFilters = createSelector(getPassengerList, (passengerList) => {
    const takeOff = {};
    const landing = {};
    const types = [];
    const classes = [];
    passengerList &&
        Object.keys(passengerList).map((key) => {
            const item = passengerList[key];
            if (!takeOff[item.apToID]) {
                takeOff[item.apToID] = {
                    id: item.apToID,
                    name: item.apToName,
                    code: item.apToCode,
                };
            }
            if (!landing[item.apLaID]) {
                landing[item.apLaID] = {
                    id: item.apLaID,
                    name: item.apLaName,
                    code: item.apLaCode,
                };
            }
            if (item.type && types.indexOf(item.type) === -1) {
                types.push(item.type);
            }
            if (item.class && classes.indexOf(item.class) === -1) {
                classes.push(item.class);
            }
        });

    return {
        takeOff,
        landing,
        types,
        classes,
    };
});
export { getPassengerList, getPassengerListFilters, getPlnCabin, getPassengerSeats };
