import { convertDateToUTC } from '../../../service/DateTimeUtils';

const UnderlineType = {
    DEFAULT: 'Default',
    WELL_DONE: 'Done',
    BAD_WORK: 'Bad',
};

export const calculateWidth = (length) => {
    const SPACE_BETWEEN = 0.5;
    const GRID_LENGTH = 12;

    return `${(length * (100 - (GRID_LENGTH - 1) * SPACE_BETWEEN)) / GRID_LENGTH + (length - 1) * SPACE_BETWEEN}%`;
};

export const getTime = (value) => {
    return value && convertDateToUTC(new Date(value));
};

export const getUnderlineType = (value, limit) => {
    let underlineType = UnderlineType.NONE;
    if (!limit) {
        return;
    }
    const diff = value - limit;

    if (diff < 0) {
        underlineType = UnderlineType.WELL_DONE;
    } else if (diff > 0) {
        underlineType = UnderlineType.BAD_WORK;
    }

    return underlineType;
};
