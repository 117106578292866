import React from 'react';
import moment from 'moment';
import classnames from 'classnames';

import { Panel } from '../../Panel/Panel';
import { AirportInfoRow } from '../AirportInfoRow/AirportInfoRow';
import { useStyles } from '../useStyles';

function FlightCardAirportInfo(props) {
    const classes = useStyles();
    const [showUtc, toggleUtc] = React.useState(true);
    const handleToogleUtc = React.useCallback(() => {
        toggleUtc((prevState) => !prevState);
    }, []);

    const getDateField = (fieldName, obj) => {
        let field = showUtc ? fieldName : `${fieldName}_local`;
        const date = obj[field];

        return {
            date: date ? moment.utc(obj[field]).format('DD.MM') : '',
            time: date ? moment.utc(obj[field]).format('HH:mm') : '',
        };
    };
    const legs = React.useMemo(
        () => props.legs.map((data) => <AirportInfoRow key={data.key} getDateField={getDateField} item={data.item} />),
        [props.legs, showUtc]
    );

    return (
        <Panel
            title="Airport info"
            className="flight-info__panel  flight-info__panel--table"
            toggle={{
                label: 'UTC',
                defaultToggled: showUtc,
                onToggle: handleToogleUtc,
            }}
        >
            <div className="flight-info__inner">
                <table className="flight-info__table">
                    <thead>
                        <tr>
                            <th className={classnames(classes.columnMain, classes.tableHeaderCell)} rowSpan={2}>
                                Route
                            </th>
                            <th className={classnames(classes.column, classes.tableHeaderCell)} rowSpan={2}>
                                Stand
                            </th>
                            <th className={classnames(classes.columnSmall, classes.leftBorder, classes.tableHeaderCell)} rowSpan={2}>
                                STD
                            </th>
                            <th className={classnames(classes.columnSmall, classes.tableHeaderCell)} rowSpan={2}>
                                STA
                            </th>
                            <th className={classnames(classes.columnSmall, classes.leftBorder, classes.tableHeaderCell)} rowSpan={2}>
                                ETD
                            </th>
                            <th className={classnames(classes.columnSmall, classes.tableHeaderCell)} rowSpan={2}>
                                ETA
                            </th>
                            <th className={classnames(classes.columnSmall, classes.leftBorder, classes.tableHeaderCell)} rowSpan={2}>
                                ATD
                            </th>
                            <th className={classnames(classes.columnSmall, classes.tableHeaderCell)} rowSpan={2}>
                                ATOT
                            </th>
                            <th className={classnames(classes.columnSmall, classes.tableHeaderCell)} rowSpan={2}>
                                ALT
                            </th>
                            <th className={classnames(classes.columnSmall, classes.tableHeaderCell)} rowSpan={2}>
                                ATA
                            </th>
                            <th className={classnames(classes.columnSmall, classes.leftBorder, classes.tableHeaderCell)} rowSpan={2}>
                                Delay
                            </th>
                        </tr>
                        <tr>
                            <td className={classnames(classes.columnSmall, classes.leftBorder, classes.tableHeaderCell)}>TO</td>
                            <td className={classnames(classes.columnLarge, classes.tableHeaderCell)}>LA</td>
                        </tr>
                    </thead>
                    <tbody>{legs}</tbody>
                </table>
            </div>
        </Panel>
    );
}

export { FlightCardAirportInfo };
