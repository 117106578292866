export const UnderlineType = {
    NONE: 0,
    WELL_DONE: 1,
    BAD_WORK: 2,
};

export const getLabelValue = (id, label, props) => {
    if (id !== 'takeOffWeightMax' && id !== 'landingWeightMax' && id !== 'zeroFuelWeightMax') {
        return label;
    }

    const {
        takeOffWeightMax = 0,
        landingWeightMax = 0,
        tripFuel = 0,
        zeroFuelWeightMax = 0,
        totalOnBoard = 0,
        expenseLandTakeoff = 0,
        expenseBeforeTakeoff = 0,
    } = props;
    const tof = totalOnBoard - expenseLandTakeoff - expenseBeforeTakeoff;
    const ztw = zeroFuelWeightMax + tof;
    const tw = takeOffWeightMax;
    const ltw = landingWeightMax + tripFuel;

    if (ztw <= tw && ztw <= ltw && id === 'zeroFuelWeightMax') {
        return `${label}*`;
    }

    if (tw <= ztw && tw <= ltw && id === 'takeOffWeightMax') {
        return `${label}*`;
    }

    if (ltw <= tw && ltw <= ztw && id === 'landingWeightMax') {
        return `${label}*`;
    }

    return label;
};

export const getUnderlineType = (value1, value2) => {
    const diff = value1 - value2;

    if (diff < 0) {
        return UnderlineType.WELL_DONE;
    }
    if (diff > 0) {
        return UnderlineType.BAD_WORK;
    }

    return UnderlineType.NONE;
};
