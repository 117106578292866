import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { DialogControls } from '../../../components/DialogControls/DialogControls';
import { useHideModal } from '../../../actions/modal';
import { onCheckFuelInfo, selectAlternateRoutes } from '../../../actions/navigation';
import { getColumnList } from '../../../selectors/declaration';
import { NavigationRouteItem } from '../../../components/NavigationRoutes/NavigationRouteItem/NavigationRouteItem';
import { useStyles } from './useStyles';
import { Dialog, DialogContent } from '../../../components/material-ui/Dialog';
import { Tab, Tabs } from '../../../components/material-ui/Tabs';
import { AppBar } from '../../../components/material-ui/AppBar';
import { AlternateRouteHead } from './AlternateRouteHead/AlternateRouteHead';
import { Toolbar } from '../../../components/material-ui/Toolbar';
import { Typography } from '../../../components/material-ui/Typography';

const getRouteName = (route, index) => {
    const point = route.points[route.ids[route.ids.length - 1]] || {};

    return point.route || point.rnt ? `Divert to ${point.route || point.rnt}` : `Divert ${index + 1}`;
};

const AlternateRoute = React.forwardRef((props, ref) => {
    const { isNewPoint = false, pos } = props;
    const points = useSelector((state) => get(state, `sendedData[${get(state, 'screen.currentLeg')}].points`, []));
    const mobileEnrouteMode = useSelector((state) => get(state, 'screen.mobileEnrouteMode', false));
    const columnsDeclaration = useSelector(getColumnList);

    const routes = React.useMemo(
        () =>
            (points || []).reduce((res, point, pos) => {
                const routeNumber = point.routeNumber;
                if (!point.routeNumber) return res;

                let route = res[routeNumber];

                if (!route) {
                    res[routeNumber] = { points: { [pos]: point }, ids: [pos] };
                } else {
                    res[routeNumber].ids.push(pos);
                    res[routeNumber].points[pos] = point;
                }

                return res;
            }, {}),
        [points]
    );

    const [value, changeValue] = React.useState(Object.keys(routes).length > 0 && Object.keys(routes)[0]);
    const classes = useStyles();
    const hideModal = useHideModal();
    const dispatch = useDispatch();

    const handleSubmit = React.useCallback(() => {
        dispatch(selectAlternateRoutes(value, pos));
        dispatch(onCheckFuelInfo());
        hideModal();
    }, [pos, isNewPoint, value]);

    const handleChange = React.useCallback((event, newValue) => changeValue(newValue), []);

    const actions = React.useMemo(
        () => [
            { label: 'CANCEL', onClick: hideModal, variant: 'text' },
            { label: 'OK', color: 'primary', type: 'submit', variant: 'text' },
        ],
        []
    );

    return (
        <Dialog ref={ref} open={true} maxWidth="lg" fullScreen={true}>
            <form className={classes.form} onSubmit={handleSubmit}>
                <DialogContent className={classes.content} dividers={true}>
                    <AppBar position="static" className={classes.routes}>
                        <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
                            {Object.keys(routes).map((routeKey, index) => {
                                return <Tab label={getRouteName(routes[routeKey], index)} value={routeKey} key={routeKey} index={index} />;
                            })}
                        </Tabs>
                    </AppBar>
                    {Object.keys(routes).map((routeKey) => {
                        const route = routes[routeKey];

                        return (
                            value === routeKey && (
                                <table className="enr__table" key={route.id}>
                                    <AlternateRouteHead {...{ mobileEnrouteMode, columnsDeclaration }} />
                                    {route.ids.map((num) => (
                                        <NavigationRouteItem
                                            key={num}
                                            pos={num}
                                            columnsDeclaration={columnsDeclaration}
                                            mobileEnrouteMode={mobileEnrouteMode}
                                            directFromPos={-1}
                                            showMenu={false}
                                            disabled={true}
                                        />
                                    ))}
                                </table>
                            )
                        );
                    })}
                </DialogContent>
                <AppBar>
                    <Toolbar className={classes.appBar}>
                        <Typography className={classes.title}>Alternate routes</Typography>
                        <DialogControls actions={actions} appBar={true} />
                    </Toolbar>
                </AppBar>
            </form>
        </Dialog>
    );
});

export { AlternateRoute };
