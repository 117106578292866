import { UPDATE_TELEGRAMS } from '../actions/tasks';
import { LOGOUT } from '../actions/screen';
import { SIGN_TELEGRAM } from '../actions/telegrams';
import { CLEAR_SENDED_DATA_LIST } from '../actions/sended-data';
const initialState = {};

export default (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case UPDATE_TELEGRAMS: {
            action.data.forEach((item) => {
                const attachments = { ...((newState[item.id] && newState[item.id].attachments) || {}) };
                const { id, name, mailTime, telexCode } = item;
                newState[item.id] = {
                    id,
                    name,
                    mailTime,
                    telexCode,
                    attachments: { ...attachments, ...item.attachments },
                };
            });

            return newState;
        }
        case SIGN_TELEGRAM:
            if (action.signatureName) {
                newState[action.id] = { ...newState[action.id], signatureName: action.signatureName };
            }

            return newState;

        case CLEAR_SENDED_DATA_LIST: {
            const { removedTelegrams = [] } = action;
            removedTelegrams.forEach((id) => {
                delete newState[id];
            });

            return newState;
        }
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};
