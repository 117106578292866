import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';

import { useStyles } from './useStyles';
import { TextField } from '../../material-ui/TextField';
import { Lens } from '../../../icons';

// import Autocomplete from '@material-ui/lab/Autocomplete';

const TextfieldWithGreyUnderline = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
        },
    },
})(TextField);

const PanelSearch = (props) => {
    // TODO Переделать Autocomplete после перевода на материал v4
    const { placeholder, value, onSearch } = props; // dataSource, dataSourceConfig, maxSearchResults, filter,
    const classes = useStyles();

    const handleSearch = React.useCallback((event) => {
        onSearch(event.target.value);
    }, []);

    return (
        <div className={classes.wrapper}>
            <TextfieldWithGreyUnderline
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Lens width="20" height="20" className={classes.icon} />
                        </InputAdornment>
                    ),
                }}
                placeholder={placeholder}
                fullWidth={true}
                onChange={handleSearch}
                value={value}
            />
            {/*<Autocomplete*/}
            {/*    fullWidth={true}*/}
            {/*    hintText={hintText}*/}
            {/*    dataSource={dataSource}*/}
            {/*    dataSourceConfig={dataSourceConfig}*/}
            {/*    maxSearchResults={maxSearchResults}*/}
            {/*    filter={filter}*/}
            {/*    openOnFocus={false}*/}
            {/*    // underlineStyle={styles.underlineStyle}*/}
            {/*    onNewRequest={handleSearch}*/}
            {/*    // style={styles.style}*/}
            {/*    // listStyle={styles.style}*/}
            {/*    // textFieldStyle={styles.style}*/}
            {/*    onUpdateInput={(e) => {*/}
            {/*        if (e == '') {*/}
            {/*            onSearch('');*/}
            {/*        }*/}
            {/*    }}*/}
            {/*/>*/}
        </div>
    );
};

const PanelSearchMemoized = React.memo(PanelSearch);

export { PanelSearchMemoized as PanelSearch };
