import React from 'react';

import { getTimeHHmm } from '../../../service/DateTimeUtils';
import { getTimeFormat } from '../../../service/NavPointsUtils';
import { useStyles } from './useStyles';

function SummaryLegInfo({ leg, declaration, number }) {
    const classes = useStyles();
    if (!leg) {
        return;
    }
    const {
        night = false,
        biologicalNight = false,
        parking = false,
        delay = false,
        rest = false,
        dist = false,
        landings = false,
    } = declaration || {};
    const flightInfo = `${getTimeFormat(leg.flightTime)}${
        leg.flightTime !== leg.timeOnDevices && leg.timeOnDevices ? ` (${getTimeFormat(leg.timeOnDevices)})` : ''
    }`;
    const landingsInfo = `${leg.landings}${
        leg.landings !== leg.landingsOnDevices && leg.landingsOnDevices ? ` (${leg.landingsOnDevices})` : ''
    }`;

    return (
        <tbody {...(number % 2 !== 1 && { className: classes.secondaryRow })}>
            <tr className={classes.row}>
                <td>{leg.flightNum}</td>
                <td>{leg.apTo}</td>
                <td>{getTimeHHmm(leg.startWorkTime)}</td>
                <td>{getTimeHHmm(leg.startEngineTime)}</td>
                <td rowSpan={2}>{getTimeFormat(leg.blockTime)}</td>
                <td>{getTimeHHmm(leg.takeOffTime)}</td>
                <td rowSpan={2}>{flightInfo}</td>
                {night && <td rowSpan={2}>{getTimeFormat(leg.timeFlightNight)}</td>}
                {biologicalNight && <td rowSpan={2}>{getTimeFormat(leg.timeBiologicalNight)}</td>}
                {parking && <td rowSpan={2}>{getTimeFormat(leg.timeWorkParking)}</td>}
                {delay && <td rowSpan={2}>{getTimeFormat(leg.timeWorkDelay)}</td>}
                {rest && <td rowSpan={2}>{getTimeFormat(leg.timeWorkRest)}</td>}
                {dist && <td rowSpan={2}>{leg.distance}</td>}
                {landings && <td rowSpan={2}>{landingsInfo}</td>}
            </tr>
            <tr className={classes.lastRow}>
                <td>{leg.pln}</td>
                <td>{leg.apLa}</td>
                <td>{getTimeHHmm(leg.endWorkTime)}</td>
                <td>{getTimeHHmm(leg.stopEngineTime)}</td>
                <td>{getTimeHHmm(leg.landingTime)}</td>
            </tr>
        </tbody>
    );
}

export { SummaryLegInfo };
