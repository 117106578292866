import { isFunction } from 'lodash';

import file from './file';
import { isAndroid } from '../../app/service/utils';

const options = {
    title: 'Aviabit viewer',
    documentView: {
        closeLabel: 'Exit',
    },
    navigationView: {
        closeLabel: 'next',
    },
    email: {
        enabled: true,
    },
    print: {
        enabled: true,
    },
    openWith: {
        enabled: true,
    },
    bookmarks: {
        enabled: true,
    },
    search: {
        enabled: true,
    },
    autoClose: {
        onPause: true,
    },
};

function show(fileName, onShow, extension = '.pdf', folder) {
    if (isAndroid()) {
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-undef
            cordova.plugins.fileOpener2.showOpenWithDialog(
                // eslint-disable-next-line no-undef
                `${cordova.file.externalRootDirectory}/Download/Aviabit/${folder? `${folder}/`:''}${fileName}.pdf`,
                'application/pdf',
                {
                    error : function(e) {
                        console.log('Error status: ' + e.status + ' - Error message: ' + e.message);
                        reject();
                    },
                    success : function () {
                        // console.log('file opened successfully');
                        resolve();
                    },
                    position : [0, 0]
                }
            );
        });
    }

    return new Promise((resolve, reject) => {
        // console.log(`CONS: Read ${fileName} full path = ${file.getDocumentsPath()}${fileName}`);
        const path = `${file.getDocumentsPath()}${folder ? folder + '/' : ''}${fileName}${extension}`;
        //console.log(path);
        cordova.plugins.SitewaertsDocumentViewer.viewDocument(// eslint-disable-line no-undef
            path,
            'application/pdf',
            options,
            () => isFunction(onShow) && onShow(),
            resolve,
            reject,
            reject
        );
    });
}

export default {
    show,
};
