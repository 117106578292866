import * as React from 'react';
import { get } from 'lodash';

const useInputs = ({ initialData, reloadToggle, checkField, onCheckStatus }) => {
    const [inputs, setInputs] = React.useState({ ...initialData });
    const [status, changeStatus] = React.useState({});

    React.useEffect(() => {
        setInputs({ ...initialData });
    }, [reloadToggle]);

    const handleInputChange = React.useCallback(
        (event, value) => {
            if (event.persist) {
                event.persist();
            }
            const target = event.target;
            const label = get(target, 'label', '');
            const currentValue = value || target.value;
            setInputs((inputList) => {
                const newState = {
                    ...inputList,
                    [target.name]: target.type === 'checkbox' ? target.checked : currentValue,
                    [`${target.name}Label`]: label,
                };

                if (onCheckStatus) {
                    onCheckStatus(newState, changeStatus);
                }

                return newState;
            });
            if (checkField && target.type !== 'checkbox') {
                const field = checkField(target.name, currentValue);
                changeStatus((prevState) => ({ ...prevState, [target.name]: field }));
            }
        },
        [reloadToggle]
    );

    return { handleInputChange, inputs, setInputs, status, changeStatus };
};

export { useInputs };
