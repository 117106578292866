import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    badge: {
        top: -4,
        right: -2,
    },
    label: {
        minHeight: 56,
    },
    startIcon: {
        marginRight: theme.spacing(2),
    },
    text: {
        padding: theme.spacing(0, 2),
    },
}));

export { useStyles };
