import React, { forwardRef, useEffect, memo } from 'react';

import { useTimer } from '../hooks/useTimer';
import { sec2min } from '../../service/DateTimeUtils';

export const Timer = memo(
    forwardRef(({ seconds, onTimeEnd }, ref) => {
        const time = useTimer(seconds);

        useEffect(() => {
            if (time <= 0) {
                onTimeEnd(false);
            }
        }, [time]);

        return <span ref={ref}>{sec2min(time)}</span>;
    })
);
