import React from 'react';
import { get } from 'lodash';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../../components/material-ui/Dialog';
import { TextField } from '../../../components/material-ui/TextField';
import { DialogControls } from '../../../components/DialogControls/DialogControls';
import { useStyles } from './useStyles';

const ChooseAirportModal = (props) => {
    const { onOk, hideModal, open } = props;
    const [value, changeValue] = React.useState('');
    const classes = useStyles();
    const ref = React.useRef();
    const handleChange = React.useCallback((event) => {
        changeValue(event.target.value);
    }, []);

    const handleAdd = React.useCallback(() => {
        onOk(get(ref, 'current.value', ''));
        changeValue('');
        if (ref) {
            ref.current.value = '';
        }
    }, [ref]);

    const actionList = React.useMemo(
        () => [
            {
                label: 'Close',
                onClick: hideModal,
            },
            {
                label: 'Get data',
                color: 'primary',
                disabled: !get(ref, 'current.value', false),
                onClick: handleAdd,
            },
        ],
        [handleAdd, Boolean(get(ref, 'current.value', false))]
    );

    return (
        <Dialog open={open} style={{ zIndex: 1600 }}>
            <DialogTitle className={classes.title}>Request notices modal</DialogTitle>
            <DialogContent className={classes.container}>
                <TextField inputRef={ref} fullWidth={true} label="Airport ICAO code" value={value} onChange={handleChange} />
            </DialogContent>
            <DialogActions className={classes.controls}>
                <DialogControls actions={actionList} />
            </DialogActions>
        </Dialog>
    );
};

const ChooseAirportModalMemoized = React.memo(ChooseAirportModal);

export { ChooseAirportModalMemoized as ChooseAirportModal };
