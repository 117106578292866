import React, { useContext } from 'react';

import { NavigationRoutesWrapper } from './NavigationRoutesWrapper/NavigationRoutesWrapper';
import { NavigationRouteHeader } from './NavigationRoutesWrapper/NavigationRouteHeader/NavigationRouteHeader';
import { NavigationRoutes } from './NavigationRoutes/NavigationRoutes';
import { RouteItemMenu } from './RouteItemMenu/RouteItemMenu';
import { useAnchorEl } from '../hooks/useAnchorEl';
import { NavigationRoutesProvider, NavRoutesContext } from './ContextProvider';

const NavigationRoutesPage = () => {
    const [menuContext, setMenuContext] = React.useState({});
    const [anchorEl, handleOpenMenu, handleClose] = useAnchorEl();
    const { handleHeaderWidthsChange } = useContext(NavRoutesContext);

    const handleMenuClick = React.useCallback((event, newState) => {
        event.stopPropagation();
        handleOpenMenu(event);
        setMenuContext(newState);
    }, []);

    return (
        <NavigationRoutesWrapper>
            <NavigationRouteHeader onChangeHeaderWidth={handleHeaderWidthsChange} />
            <NavigationRoutes onMenuOpen={handleMenuClick} />
            {Boolean(anchorEl) && <RouteItemMenu {...menuContext} anchorEl={anchorEl} onClose={handleClose} />}
        </NavigationRoutesWrapper>
    );
};

const NavigationRoutesPageMemoized = React.memo(() => (
    <NavigationRoutesProvider>
        <NavigationRoutesPage />
    </NavigationRoutesProvider>
));

NavigationRoutesPageMemoized.dispalyName = 'NavigationRoutesPage';
export { NavigationRoutesPageMemoized as NavigationRoutesPage };
