import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        content: {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(0, 2, 3),

            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(0, 1, 1),
            },
        },
        title: {
            padding: theme.spacing(2),

            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(1),
            },
        },
    };
});

export { useStyles };
