import React from 'react';
import classnames from 'classnames';

import { TYPES, useShowModal } from '../../../../actions/modal';
import { useStyles } from './useStyles';
import { Typography } from '../../../material-ui/Typography';
import { IconButton } from '../../../material-ui/IconButton';
import { MoreVertIconContrast } from '../../../../icons/MoreVertIconContrast';

const NavigationRouteComment = ({ columnsCount, point, offset, pos, onMenuOpen }) => {
    const showModal = useShowModal();
    const classes = useStyles();

    const handleClick = React.useCallback(() => {
        showModal(TYPES.COMMENT, { point, pos });
    }, [point, pos]);

    const handleOpenMenu = (event) => {
        onMenuOpen(event, {
            point,
            offset,
            pos,
        });
    };

    return (
        <tbody className="enr__item" onClick={handleClick}>
            <tr className="enr__tr  enr__tr--bordered">
                <td className="enr__td" colSpan={columnsCount - 1}>
                    <Typography className={classes.titleInner}>Additional info</Typography>
                </td>
                <td className={classnames('enr__td', 'enr__table-menu', classes.menuWrapper)} rowSpan={2}>
                    <IconButton style={{ padding: '9px' }} onClick={handleOpenMenu}>
                        <MoreVertIconContrast />
                    </IconButton>
                </td>
            </tr>
            <tr className="enr__tr  enr__tr--end">
                <td className="enr__td  enr__td--multiple_row" colSpan={columnsCount - 1}>
                    <Typography className={classes.comment}>{point.comment}</Typography>
                </td>
            </tr>
        </tbody>
    );
};

export { NavigationRouteComment };
