import Engine from './image/Engine.jsx';
import Takeoff from './image/Takeoff.jsx';
import Aviabit from './image/Aviabit.jsx';
import Check from './image/Check.jsx';
import Person from './image/Person.jsx';
import Document from './image/Document.jsx';
import Clock from './image/Clock.jsx';
import Arrow from './image/Arrow.jsx';
import Filter from './image/Filter.jsx';
import Leg from './image/Leg.jsx';
import Pilot from './image/Pilot';
import Route from './image/Route';
import Info from './image/Info';
import FlightTask from './image/FlightTask';
import Report from './image/Report';
import Send from './image/Send';
import About from './image/About';
import Download from './image/Download';
import Phone from './image/Phone';
import Email from './image/Email';
import Settings from './image/Settings';
import Eraser from './image/Eraser';
import Library from './image/Library';
import Folder from './image/Folder';
import ReportAdd from './image/ReportAdd';
import Attachment from './image/Attachment';
import Passenger from './image/Passenger';
import Woman from './image/Woman';
import Man from './image/Man';
import Rubbish from './image/Rubbish';
import Comment from './image/Comment';
import Fuel from './image/Fuel';
import Add from './image/Add';
import Refresh from './image/Refresh';
import Divert from './image/Divert';
import Edit from './image/Edit';
import DownArrow from './image/DownArrow';
import Cancel from './image/Cancel';
import RightRefresh from './image/RightRefresh';
import Hold from './image/Hold';
import Direct from './image/Direct';
import CheckAltitude from './image/CheckAltitude';
import Summary from './image/Summary';
import UpArrow from './image/UpArrow';
import Down from './image/Down';
import Landing from './image/Landing';
import PilotFlight from './image/PilotFlight';
import Pdf from './image/Pdf';
import Limitation from './image/Limitation';
import DownloadAll from './image/DownloadAll';
import AirModeOn from './image/AirModeOn';
import AirModeOff from './image/AirModeOff';
import Complete from './image/Complete';
import Target from './image/Target';
import Left from './image/Left';
import Right from './image/Right';
import TwoArrowBifurcation from './image/TwoArrowBifurcation';
import Logout from './image/Logout';
import Lamp from './image/Lamp';
import ChangeUser from './image/ChangeUser';
import Remove from './image/Remove';
import Lens from './image/Lens';
import Star from './image/Star';
import Link from './image/Link';
import { CheckList } from './image/CheckList';
import { Circle } from './image/Circle';

export {
    About,
    Add,
    AirModeOn,
    AirModeOff,
    Arrow,
    Attachment,
    Aviabit,
    Cancel,
    ChangeUser,
    Check,
    CheckAltitude,
    Clock,
    Comment,
    Complete,
    Direct,
    Divert,
    Document,
    Down,
    DownArrow,
    Download,
    DownloadAll,
    Edit,
    Email,
    Engine,
    Eraser,
    Filter,
    FlightTask,
    Folder,
    Fuel,
    Info,
    Hold,
    Lamp,
    Landing,
    Left,
    Leg,
    Lens,
    Library,
    Limitation,
    Link,
    Logout,
    Man,
    Passenger,
    Pdf,
    Person,
    Phone,
    Pilot,
    PilotFlight,
    Refresh,
    Remove,
    Report,
    ReportAdd,
    Right,
    RightRefresh,
    Rubbish,
    Route,
    Send,
    Settings,
    Star,
    Summary,
    Takeoff,
    Target,
    TwoArrowBifurcation,
    UpArrow,
    Woman,
    CheckList,
    Circle,
};
