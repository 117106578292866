import React from 'react';
import classnames from 'classnames';

import { getCellData } from './tools';
import { useStyles } from './useStyles';
import { SplitedCell } from '../../../SplittedCell/SplittedCell';
import { Typography } from '../../../../material-ui/Typography';

const PointCell = (props) => {
    const { declaration, pos, setColSpan = false, point, convert } = props;
    const isArray = Array.isArray(declaration);
    const classes = useStyles();

    const { content, className, addition, key } = React.useMemo(
        () =>
            isArray
                ? declaration.reduce(
                      (res, item, index) => {
                          const { content, style, addition, key } = getCellData(item, point, classes, convert);
                          if (index === 0) {
                              res.style = style;
                              res.addition = addition;
                              res.key = key;
                          }
                          res.content.push(content);

                          return res;
                      },
                      { content: [] }
                  )
                : getCellData(declaration, point, classes, convert),
        [declaration, point, setColSpan, classes, convert]
    );

    return (
        <td key={key || pos} className={classnames('enr__td', className)} colSpan={setColSpan ? 2 : 1}>
            {isArray ? (
                <div className={classes.cellInner}>
                    {content.map((item, index, arr) => (
                        <SplitedCell key={index} part={arr.length} data={item} />
                    ))}
                </div>
            ) : (
                content && (
                    <Typography display="inline" className={classes.coloredData}>
                        {content}
                    </Typography>
                )
            )}
            {addition && (
                <Typography display="inline" className={classes.sup}>
                    <sup>{addition}</sup>{' '}
                </Typography>
            )}
        </td>
    );
};

export { PointCell };
