import React from 'react';

import BaseIcon from '../BaseIcon';

const Leg = (props) => (
    <BaseIcon {...props} viewBox="0 0 286.98 286.98">
        <path d="M32.64,126.533L8.604,98.724h21.993l37.305,24.867l-15.412,1.216c-4.704,0-9.152,0.453-13.252,1.258L32.64,126.533z" />
        <path
            d="M30.596,188.926H8.604l24.037-27.812l6.446,0.464c4.077,0.807,8.498,1.266,13.16,1.266c0.011,0,0.021,0,0.031,0
            l15.612,1.224L30.596,188.926z"
        />
        <polygon points="126.594,249.781 87.563,249.781 137.507,169.541 200.196,174.477" />
        <path
            d="M210.251,117.793c4.504-0.633,9.298-1.01,14.396-1.129l0.391-0.034c0.11,0.016,0.242,0.029,0.338,0.016l1.972-0.047
            c32.315,0,59.632,12.469,59.632,27.229c0,14.744-27.306,27.221-59.632,27.221l-1.972-0.058c-0.011,0-0.026,0-0.043,0
            c-0.11,0-0.205,0.005-0.316,0.016l-0.537-0.037c-4.957-0.11-9.756-0.485-14.418-1.139l-1.255-0.11
            c-0.454-0.507-1.018-0.907-1.74-0.949l-70.585-5.084c-0.488-0.042-0.931,0.074-1.329,0.264l-57.009-4.482
            c-0.411-0.327-0.907-0.549-1.458-0.58l-36.914-2.668c-11.372-2.331-18.591-7.558-18.591-12.393c0-4.836,7.23-10.081,18.71-12.396
            l36.795-2.658c0.541-0.037,1.021-0.264,1.432-0.577l58.314-4.591c0.016,0,0.034,0,0.045,0l70.601-5.091
            c0.548-0.037,1.033-0.253,1.46-0.583L210.251,117.793z"
        />
        <polygon points="126.594,37.515 200.517,113.146 137.724,118.088 87.563,37.515" />
        <path
            d="M2.7,37.515h68.296c1.49,0,2.7-1.21,2.7-2.7c0-1.49-1.21-2.7-2.7-2.7H2.7c-1.489,0-2.7,1.21-2.7,2.7
            C0,36.305,1.21,37.515,2.7,37.515z"
        />
        <path
            d="M2.7,63.613h68.296c1.49,0,2.7-1.21,2.7-2.7c0-1.489-1.21-2.7-2.7-2.7H2.7c-1.489,0-2.7,1.21-2.7,2.7
            C0,62.403,1.21,63.613,2.7,63.613z"
        />
        <path
            d="M2.7,228.762h68.296c1.49,0,2.7-1.213,2.7-2.7s-1.21-2.7-2.7-2.7H2.7c-1.489,0-2.7,1.213-2.7,2.7
            S1.21,228.762,2.7,228.762z"
        />
        <path
            d="M2.7,254.865h68.296c1.49,0,2.7-1.213,2.7-2.7s-1.21-2.7-2.7-2.7H2.7c-1.489,0-2.7,1.213-2.7,2.7
            S1.21,254.865,2.7,254.865z"
        />
    </BaseIcon>
);

export default Leg;
