import { showConfirmDialog } from './messageDialog';
import { setLoadingState } from './progressIndicator';
import { MessageDialogTypes } from '../components/MessageDialog/MessageDialog';
const UPDATE_DOCUMENTS_STATUS = 'UPDATE_DOCUMENTS_STATUS';

const GET_DOCUMENTS_LIST = 'GET_DOCUMENTS_LIST';

function showPdf(id, onShow, extension) {
    return (dispatch, getState, { plugins }) => {
        plugins.pdf.show(id, onShow, extension).catch(() => {
            setTimeout(
                () =>
                    dispatch(
                        showConfirmDialog({
                            title: 'Error message',
                            message: 'Can\'t open a file. Maybe it is not loaded.',
                            btnCancelVisibility: false,
                            options: {
                                type: MessageDialogTypes.ERROR,
                            },
                        })
                    ),
                1000
            );
        });
    };
}

function downloadDocuments(ids) {
    return (dispatch, getState, { apiManager }) => {
        return ids.reduce((promise, id) => {
            return promise.then((res) => {
                return apiManager
                    .downloadFile(id)
                    .then(() => {
                        res.downloaded.push(id);

                        return res;
                    })
                    .catch((err) => {
                        console.log(err);
                        res.errors.push(id);

                        return res;
                    });
            });
        }, Promise.resolve({ downloaded: [], errors: [] }));
    };
}

function downloadDocument(id, name) {
    return (dispatch, getState, { apiManager }) => {
        dispatch(setLoadingState(true, `Downloading document ${name}...`));

        return apiManager
            .downloadFile(id)
            .then(() => {
                dispatch({ type: UPDATE_DOCUMENTS_STATUS, data: { downloaded: [id] } });
                dispatch(setLoadingState(false));
            })
            .catch(() => {
                let msg = {
                    title: 'Error message',
                    message: 'The document have been uploaded correctly. Please try again later!',
                    btnCancelVisibility: false,
                    options: {
                        type: MessageDialogTypes.ERROR,
                    },
                };
                // // Cannot re-open Dialog immediately, need timeout fro it
                // setTimeout(() =>{
                dispatch(showConfirmDialog(msg));
                dispatch(setLoadingState(false));
            });
    };
}

function downloadAllDocuments(ids) {
    return (dispatch) => {
        dispatch(setLoadingState(true, 'Downloading documents...'));
        return Promise.all([dispatch(downloadDocuments(ids))])
            .then(([data]) => {
                dispatch({ type: UPDATE_DOCUMENTS_STATUS, data: data });
                if (data.errors.length || data.errors.length) {
                    let msg = {
                        title: 'Error message',
                        message: 'Not all the documents have been uploaded correctly. Please try again later!',
                        btnCancelVisibility: false,
                        options: {
                            type: MessageDialogTypes.ERROR,
                        },
                    };
                    // // Cannot re-open Dialog immediately, need timeout fro it
                    // setTimeout(() =>{
                    dispatch(showConfirmDialog(msg));
                    // }, 1000)
                }
                dispatch(setLoadingState(false));
            })
            .catch(() => {
                dispatch(setLoadingState(false));
                // throw new Error('Failed to load documents from the server. Please try again later!');
            });
    };
}

export { GET_DOCUMENTS_LIST, UPDATE_DOCUMENTS_STATUS, showPdf, downloadAllDocuments, downloadDocument };
