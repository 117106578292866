import * as React from 'react';
import Dropzone from 'react-dropzone';
import AddIcon from '@material-ui/icons/Add';

import { compressedMimeTypes, isImageHeic } from '../../../service/ImageUtils';
import { Typography } from '../../../components/material-ui/Typography';
import { Fab } from '../../../components/material-ui/Fab';
import { useStyles } from './useStyles';
import { ReportDropzoneImage } from '../ReportDropzoneImage/ReportDropzoneImage';
import { ReportDropzonePdf } from '../ReportDropzonePdf/ReportDropzonePdf';
import { ReportDropzoneHeic } from '../ReportDropzoneHeic/ReportDropzoneHeic';

const MAX_COUNT = 10;
const IMAGE_SIZE = 118;

const ReportDropzone = ({ images, onDelete, onDrop }) => {
    const classes = useStyles();

    return (
        <>
            <Typography className={classes.info}>
                Attachments {images.length ? `count =  ${images.length}` : ''} (jpeg, png, heic, pdf)
            </Typography>
            <div className={classes.container}>
                {images.length >= MAX_COUNT && (
                    <Typography className={classes.info}>{`Maximum attached images count is ${MAX_COUNT}`}</Typography>
                )}
                {images.length ? (
                    <div className={classes.inner}>
                        {images.map((img, index) => {
                            if (img.url || (img.preview && compressedMimeTypes.indexOf(img && img.type) !== -1)) {
                                return (
                                    <ReportDropzoneImage
                                        className={classes.preview}
                                        pos={img.pos}
                                        isNew={img.isNew}
                                        height={IMAGE_SIZE}
                                        key={img.id || index}
                                        src={img.url || img.preview}
                                        onDeleteImage={onDelete}
                                    />
                                );
                            } else if (isImageHeic(img.type, img.name)) {
                                return (
                                    <ReportDropzoneHeic
                                        key={img.id || index}
                                        width={IMAGE_SIZE}
                                        height={IMAGE_SIZE}
                                        pos={img.pos}
                                        isNew={img.isNew}
                                        onDelete={onDelete}
                                        enableFullScreenMode={false}
                                    />
                                );
                            } else {
                                return (
                                    <ReportDropzonePdf
                                        key={img.id || index}
                                        width={IMAGE_SIZE}
                                        height={IMAGE_SIZE}
                                        pos={img.pos}
                                        isNew={img.isNew}
                                        onDelete={onDelete}
                                        enableFullScreenMode={false}
                                    />
                                );
                            }
                        })}
                    </div>
                ) : (
                    <Typography className={classes.paragraph}>There are not any attachments</Typography>
                )}
                <Dropzone className={classes.dropzone} onDrop={onDrop} disabled={images.length >= MAX_COUNT}>
                    <Fab>
                        <AddIcon />
                    </Fab>
                </Dropzone>
            </div>
        </>
    );
};

const ImagesUploaderMemoized = React.memo(ReportDropzone);

export { ImagesUploaderMemoized as ReportDropzone };
