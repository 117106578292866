import makeStyles from '@material-ui/core/styles/makeStyles';

import { lightBlue } from '../../../ThemeProvider';

export const useStyles = makeStyles((theme) => ({
    bold: {
        color: theme.textField.highLightTextColor,
        fontWeight: '600',
        paddingLeft: 0,
    },
    caution: {
        backgroundColor: theme.point.caution,
    },
    menuWrapper: {
        width: '48px',
        verticalAlign: 'middle',
        textAlign: 'center',
    },
    simple: {
        paddingLeft: 0,
    },
    title: {
        fontSize: '16px',
        fontWeight: 600,
        ...(theme.palette.type === 'dark' && { color: lightBlue }),
    },
    text: {
        whiteSpace: 'pre',
        fontSize: '16px',
    },
}));
