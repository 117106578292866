import React from 'react';
import { get } from 'lodash';
import classnames from 'classnames';
import { shallowEqual, useSelector } from 'react-redux';

import { PointRow } from './PointRow/PointRow';
import { TYPES, useShowModal } from '../../../../actions/modal';
import { useStyles } from './useStyles';
import { getPointStyle } from './tools';
import { Typography } from '../../../material-ui/Typography';
import { getColumnList } from '../../../../selectors/declaration';

const NavigationRoutePoint = (props) => {
    const showModal = useShowModal();
    const classes = useStyles();
    const { point, pos, showMenu = true, directFromPos, mobileEnrouteMode, columnsCount, onMenuOpen, current, disabled = false } = props;

    const { columnsDeclaration, multieditFrom, multieditTo } = useSelector((state) => {
        const { multieditFrom, multieditTo } = get(state, 'navigation', {});
        const columnsDeclaration = getColumnList(state);

        return {
            current,
            columnsDeclaration,
            multieditFrom,
            multieditTo,
        };
    }, shallowEqual);

    const handleClick = () => {
        const { route, rnt } = point || {};
        if (!disabled) {
            showModal(TYPES.SHORT_POINT, {
                pos,
                point,
                title: `Edit point ${route || rnt || ''} ${rnt && route && rnt !== route ? `(${rnt})` : ''}`,
            });
        }
    };

    return (
        <tbody
            onClick={handleClick}
            className={classnames(
                'nav-routes__item',
                current && 'nav-routes__item--current',
                getPointStyle({
                    columnsDeclaration,
                    point,
                    pos,
                    current,
                    directFromPos,
                    multieditFrom,
                    multieditTo,
                    classes,
                })
            )}
            data-current={current}
        >
            <PointRow
                key={0}
                index={0}
                {...{
                    columnsDeclaration,
                    mobileEnrouteMode,
                    showMenu,
                    pos,
                    point,
                    multieditFrom,
                    multieditTo,
                    onMenuOpen,
                }}
            />
            <PointRow
                key={1}
                index={1}
                {...{
                    columnsDeclaration,
                    mobileEnrouteMode,
                    showMenu,
                    pos,
                    point,
                    multieditFrom,
                    multieditTo,
                    onMenuOpen,
                }}
            />
            <PointRow
                key={2}
                index={2}
                {...{
                    columnsDeclaration,
                    mobileEnrouteMode,
                    showMenu,
                    pos,
                    point,
                    multieditFrom,
                    multieditTo,
                    onMenuOpen,
                }}
            />
            {Boolean(point.comment) && (
                <tr className="nav-routes__table-row  nav-routes__table-row--end" key={3}>
                    <td className="nav-routes__table-cell  nav-routes__table-cell--multiple_row" colSpan={columnsCount}>
                        <Typography>{point.comment}</Typography>
                    </td>
                </tr>
            )}
        </tbody>
    );
};

export { NavigationRoutePoint };
