import React from 'react';
import classnames from 'classnames';

import { useStyles } from '../useStyles';

function AirportInfoRow({ styles = {}, item = {}, getDateField }) {
    const classes = useStyles();

    return (
        <tr>
            <td className={classes.columnMain}>
                <div>
                    <div>{item.airportTakeOff.code}</div>
                    <div>{item.airportLanding.code}</div>
                </div>
            </td>
            <td className={classes.column}>{item.stand}</td>
            <td className={classnames(classes.columnSmall, classes.leftBorder)}>
                <div>
                    <div style={styles.lightText}>{getDateField('std', item).date}</div>
                    <div>{getDateField('std', item).time}</div>
                </div>
            </td>
            <td className={classes.columnSmall}>
                <div>
                    <div style={styles.lightText}>{getDateField('sta', item).date}</div>
                    <div>{getDateField('sta', item).time}</div>
                </div>
            </td>
            <td className={classnames(classes.columnSmall, classes.leftBorder)}>
                <div>
                    <div style={styles.lightText}>{getDateField('etd', item).date}</div>
                    <div>{getDateField('etd', item).time}</div>
                </div>
            </td>
            <td className={classes.columnSmall}>
                <div>
                    <div style={styles.lightText}>{getDateField('eta', item).date}</div>
                    <div>{getDateField('eta', item).time}</div>
                </div>
            </td>
            <td className={classnames(classes.columnSmall, classes.leftBorder)} style={styles.tableCell}>
                <div>
                    <div style={styles.lightText}>{getDateField('atd', item).date}</div>
                    <div>{getDateField('atd', item).time}</div>
                </div>
            </td>
            <td className={classes.columnSmall}>
                <div>
                    <div style={styles.lightText}>{getDateField('atot', item).date}</div>
                    <div>{getDateField('atot', item).time}</div>
                </div>
            </td>
            <td className={classes.columnSmall}>
                <div>
                    <div style={styles.lightText}>{getDateField('alt', item).date}</div>
                    <div>{getDateField('alt', item).time}</div>
                </div>
            </td>
            <td className={classes.columnSmall}>
                <div>
                    <div style={styles.lightText}>{getDateField('ata', item).date}</div>
                    <div>{getDateField('ata', item).time}</div>
                </div>
            </td>
            <td className={classnames(classes.columnLarge, classes.leftBorder)}>
                <div>
                    <div>{item.delayTakeOffCode}</div>
                    <div>{item.delayTakeOff}</div>
                </div>
            </td>
            <td className={classnames(classes.columnLarge, classes.leftBorder)}>
                <div>
                    <div>{item.delayLandingCode}</div>
                    <div>{item.delayLanding}</div>
                </div>
            </td>
        </tr>
    );
}

export { AirportInfoRow };
