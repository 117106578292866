import React from 'react';

import { Box } from '../../../material-ui/Box';
import { Checkbox } from '../../../material-ui/Checkbox';
import { Chip } from '../../../material-ui/Chip';
import { InputLabel } from '../../../material-ui/InputLabel';
import { ListItemIcon, ListItemText } from '../../../material-ui/List';
import { MenuItem } from '../../../material-ui/Menu';
import { OutlinedInput } from '../../../material-ui/OutlinedInput';
import { Select } from '../../../material-ui/Select';
import { useSidebarSelect } from './useSidebarSelect';
import { getSelectListItems } from '../../tools';
import { useStyles } from './useStyles';

export const SidebarSelect = React.memo(({ data, settings, filterInputs, setFilterInputs, isMulti }) => {
    const items = getSelectListItems({ data, settings, filterInputs });
    const selectValues = filterInputs[settings.name];

    const { handleChangeSelect, handleChangeMultiSelect } = useSidebarSelect(items, setFilterInputs);

    const isAllSelected = (items.length > 0 && selectValues && items.length === selectValues.length) || !selectValues;

    const classes = useStyles();

    return (
        <>
            <InputLabel id={`select-label${settings.name}`} classes={{ formControl: classes.formControl }}>
                {settings.label}
            </InputLabel>
            <Select
                labelId={`select-label${settings.name}`}
                id={settings.name}
                name={settings.name}
                classes={{ icon: classes.selectIcon }}
                value={selectValues || ['0']}
                onChange={isMulti ? handleChangeMultiSelect : handleChangeSelect}
                input={<OutlinedInput label={settings.label} classes={{ root: classes.legend }} />}
                multiple={isMulti}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
                        {isAllSelected || !selected ? (
                            <Chip key="-1" label="All" />
                        ) : (
                            selected.map((value) => {
                                const item = items.find((item) => item.value === value);

                                return item && <Chip key={value} label={item.label} />;
                            })
                        )}
                    </Box>
                )}
                MenuProps={{ style: { zIndex: 1600 } }}
            >
                <MenuItem value={-1}>
                    <ListItemIcon>
                        <Checkbox checked={isAllSelected} />
                    </ListItemIcon>
                    <ListItemText primary="Select All" />
                </MenuItem>
                {items.map((item, index) => (
                    <MenuItem value={item.value} key={index}>
                        <ListItemIcon>
                            <Checkbox checked={selectValues && selectValues.includes(item.value)} />
                        </ListItemIcon>
                        <ListItemText primary={item.label} />
                        {item.count && <ListItemText secondary={item.count} secondaryTypographyProps={{ align: 'right' }} />}
                    </MenuItem>
                ))}
            </Select>
        </>
    );
});
