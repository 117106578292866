import { makeStyles } from '@material-ui/core/styles';

export const useStyles = (isCurrent) =>
    makeStyles((theme) => ({
        actions: {
            ['@media (max-width: 400px)']: {
                position: 'static',
                transform: 'none',
            },
        },
        listStyle: {
            padding: `${theme.spacing(1)}px 226px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
            minHeight: theme.spacing(7),
            background: !isCurrent ? theme.listItem.hoverColor : 'transparent',

            ['@media (max-width: 400px)']: {
                flexBasis: 'row',
                flexWrap: 'wrap',
                paddingRight: theme.spacing(2),
            },
        },
        selectButton: {
            marginLeft: theme.spacing(1),
        },
        selectedListStyle: {
            padding: `${theme.spacing(1)}px 226px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
            minHeight: theme.spacing(7),
            backgroundColor: theme.ofp.selected,

            ['@media (max-width: 400px)']: {
                flexBasis: 'row',
                flexWrap: 'wrap',
                paddingRight: theme.spacing(2),
            },
        },
        text: {
            fontSize: '18px',
            color: theme.palette.text.primary,
        },
        deprecated: {
            backgroundColor: 'rgba(255,0,0,0.5)',
        },
    }));
