import { makeStyles } from '@material-ui/styles';
import { darkFullWhite } from '../../../ThemeProvider';

export const useStyles = makeStyles((theme) => ({
    field: {
        marginTop: theme.spacing(2),
        color: darkFullWhite,

        '& input:-webkit-autofill': {
            '-webkit-box-shadow': 'none',
            '-webkit-text-fill-color': theme.palette.text.primary,
        },
    },
}));
