import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { DialogControls } from '../../../components/DialogControls/DialogControls';
import { useHideModal } from '../../../actions/modal';
import { findDirectionEnd, findDirectionStart, isActivePoint, pointToString } from '../../../service/NavPointsUtils';
import { directTo } from '../../../actions/navigation';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../../components/material-ui/Dialog';
import { Typography } from '../../../components/material-ui/Typography';
import { useStyles } from './useStyles';
import { useMobileWidth } from '../../../components/hooks/useMobileWidth';
import { Radio, RadioGroup } from '../../../components/material-ui/Radio';
import { FormControlLabel } from '../../../components/material-ui/FormControlLabel';

const Direct = React.forwardRef(({ editMode, ...rest }, ref) => {
    const dispatch = useDispatch();
    const hideModal = useHideModal();
    const classes = useStyles();
    const { isMobilePortrait } = useMobileWidth();
    const { points, positions } = useSelector((state) => {
        const currentLeg = get(state, 'screen.currentLeg');
        const points = get(state, `sendedData[${currentLeg}].points`);

        return {
            points,
            positions: points.reduce((res, point, pos) => {
                const text = pointToString(point);
                const isRouteExist = text.replace(/\s+/g, '') !== '';
                if (
                    isActivePoint(point) &&
                    isRouteExist &&
                    point.pointNumber > get(points, `[${rest.pointPosFrom}].pointNumber`) &&
                    !point.routeNumber &&
                    // обязательно должны быть координаты, иначе не пересчитать.
                    !!(point.lat && point.long)
                ) {
                    res.push(pos);
                }

                return res;
            }, []),
        };
    }, shallowEqual);

    const [{ pointPosFrom, pointPosTo }, changeSelection] = React.useState({
        pointPosFrom: editMode ? findDirectionStart(points, rest.pointPosFrom) : rest.pointPosFrom,
        pointPosTo: editMode ? findDirectionEnd(points, rest.pointPosTo) : null,
    });

    const handleSuccess = () => {
        dispatch(directTo(pointPosFrom, pointPosTo, editMode));
        hideModal();
    };

    const handleChange = React.useCallback((event) => {
        event.persist();
        if (event && event.target) {
            changeSelection((prevState) => ({ ...prevState, pointPosTo: +event.target.value }));
        }
    }, []);

    const pointFromName = React.useMemo(() => {
        let name = pointToString(points[pointPosFrom]);
        if (name.replace(/\s+/g, '') === '') {
            name = 'no name';
        }

        return name;
    }, [pointPosFrom]);

    const actions = [
        { label: 'CANCEL', onClick: hideModal },
        { label: 'OK', color: 'primary', disabled: !pointPosTo, onClick: handleSuccess },
    ];

    return (
        <Dialog ref={ref} open={true} maxWidth="md" fullScreen={isMobilePortrait} scroll="paper">
            <DialogTitle className={classes.title}>{editMode ? 'Edit direction' : 'Add direction'}</DialogTitle>
            <DialogContent className={classes.content} dividers={true}>
                <Typography variant="body1">
                    {editMode
                        ? 'Do you really want to change current direction? Please, select new destination.'
                        : `Do you really want to perform the operation "Direct to" from the point "${pointFromName}"? Please, select destination for direction.`}
                </Typography>
                {points ? (
                    <RadioGroup name="pointPosTo" value={pointPosTo || 0} onChange={handleChange} className={classes.wrapper}>
                        {positions.map(
                            (pos) =>
                                points[pos] && (
                                    <FormControlLabel
                                        value={pos}
                                        key={pos}
                                        control={<Radio color="primary" />}
                                        className={classes.radio}
                                        label={pointToString(points[pos])}
                                    />
                                )
                        )}
                    </RadioGroup>
                ) : (
                    <Typography>There are not any points to direct</Typography>
                )}
                <Typography color="error">{status.message}</Typography>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <DialogControls actions={actions} />
            </DialogActions>
        </Dialog>
    );
});

export { Direct };
