import React from 'react';
import moment from 'moment';
import { useTheme } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

import { IconButton } from '../material-ui/IconButton';
import { Popover } from '../material-ui/Popover';
import { Typography } from '../material-ui/Typography';
import { Button } from '../material-ui/Button';
import { MenuItem } from '../material-ui/Menu/MenuItem';
import { ListItemIcon } from '../material-ui/List';
import { useAnchorEl } from '../hooks/useAnchorEl';

import './VersionWarning.scss';

export const VersionWarningTypes = {
    ICON: 0,
    MENU_ITEM: 1,
};

const VersionWarning = (props) => {
    const { version, mode = VersionWarningTypes.ICON, onLogout } = props;
    const [anchorEl, handleOpenMenu, handleClose] = useAnchorEl();
    const theme = useTheme();

    const handleLogout = React.useCallback(() => {
        onLogout();
        handleClose();
    }, []);

    const { number = '', actualNumber = '', date, actualDate = '', description = '', isActual = true } = version || {};

    return (
        <>
            {mode === VersionWarningTypes.ICON ? (
                <IconButton onClick={handleOpenMenu} style={{ color: isActual ? theme.icon.staticWhite : '#D32F2F' }}>
                    {isActual ? <InfoIcon /> : <WarningIcon />}
                </IconButton>
            ) : (
                <MenuItem className="app-bar-menu__item" style={{ fontSize: '18px' }} onClick={handleOpenMenu}>
                    <ListItemIcon style={{ color: theme.icon.color }}>
                        <InfoIcon width="24" height="24" />
                    </ListItemIcon>
                    <Typography>Version</Typography>
                </MenuItem>
            )}
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                onClose={handleClose}
            >
                <div className="version__container">
                    <Typography variant="h5" className="version__title">
                        Version #{number} ({moment(date).format('DD.MM.YYYY')})
                    </Typography>
                    {!isActual && (
                        <Typography className="version__subtitle" variant="body1" paragraph={true}>
                            Actual version #{actualNumber} ({moment(actualDate).format('DD.MM.YYYY')})
                        </Typography>
                    )}
                    <Typography className="version__description" variant="body1" paragraph={true}>
                        {isActual ? 'You are using the latest version of the AviaBit EFB application.' : description}
                    </Typography>
                    {!isActual && (
                        <Button fullWidth={true} onClick={handleLogout} variant="contained" color="primary">
                            Sign out
                        </Button>
                    )}
                </div>
            </Popover>
        </>
    );
};

export { VersionWarning };
