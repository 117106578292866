class BackendRequestsFacade {
    constructor(backendUrl) {
        this.__backendUrl = backendUrl;
    }

    _getUrl(path) {
        return this.__backendUrl + path;
    }

    /**
     * Get запрос
     */

    // eslint-disable-next-line no-unused-vars
    get(path) {
        //eslint-disable-line
        throw new Error('Must be implemented in child');
    }

    /**
     * Возвращает blob файла
     * @param path
     */
    // eslint-disable-next-line no-unused-vars
    getFile(path) {
        //eslint-disable-line
        throw new Error('Must be implemented in child');
    }

    /**
     * Post запрос
     */
    // eslint-disable-next-line no-unused-vars
    post(path, data) {
        //eslint-disable-line
        throw new Error('Must be implemented in child');
    }

    /**
     * Delete запрос
     */
    // eslint-disable-next-line no-unused-vars
    delete(path, data) {
        //eslint-disable-line
        throw new Error('Must be implemented in child');
    }
}

export default BackendRequestsFacade;
