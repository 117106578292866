import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        arrow: { padding: theme.spacing(0.5) },
        divider: {
            width: 2,
            height: 24,
            backgroundColor: theme.palette.divider,
        },
        close: { padding: theme.spacing(0.5) },
    };
});
