import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
    comment: {
        margin: `0 ${theme.spacing(1)}px`,

        '& .MuiInput-root': {
            height: 'auto',
        },
    },
    disabled: {
        position: 'absolute',
        fontSize: '14px',
        lineHeight: '14px',
        top: '54px',
        color: theme.textField.floatingLabelColor,
    },
    disabledLabel: {
        fontSize: 16,
        color: theme.textField.floatingLabelColor,
    },
    field: {
        flex: '0 1 19%',
        ['@media (max-width:440px)']: {
            flexBasis: '48%',
        },
    },
    lastField: {
        ['@media (max-width:440px)']: {
            flex: '0 1 98%',
        },
    },
    required: {
        color: theme.palette.warningColor,
    },
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        paddingBottom: 2,
    },
}));
