import React from 'react';
import classnames from 'classnames';

import NoticesPreview from '../NoticesPreview.jpg';
import { Typography } from '../../../components/material-ui/Typography';
import { Toolbar } from '../../../components/material-ui/Toolbar';
import { Button } from '../../../components/material-ui/Button';
import { AppBar } from '../../../components/material-ui/AppBar';
import { useHideModal } from '../../../actions/modal';
import { useStyles } from './useStyles';

const NoticeModalPreview = () => {
    const hideModal = useHideModal();
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Typography className={classes.title}>NOTAM preview</Typography>
                    <Button onClick={hideModal} variant="contained" type="reset" className={classes.closeBtn}>
                        OK
                    </Button>
                </Toolbar>
            </AppBar>
            <div className={classnames(classes.wrapper, 'notice')}>
                <div modalClassName="notice">
                    <div className={classes.paper}>
                        <Typography className={classes.message}>
                            Access is denied. To get it, please contact your EFB administrator.
                        </Typography>
                        <img width="100%" height="auto" src={NoticesPreview} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export { NoticeModalPreview };
