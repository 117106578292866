import * as React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';

const CustomKeyboardDatePicker = (props) => {
    const { value, onChange, label, ...restProps } = props;
    const [selectedDate, changeSelectedDate] = React.useState(value);
    const event = React.useMemo(() => ({ target: { name: restProps.name } }), []);
    React.useEffect(() => {
        changeSelectedDate(value);
    }, [value]);
    const handleDateChange = React.useCallback((date) => {
        onChange(event, date);
        changeSelectedDate(date);
    }, []);

    return (
        <KeyboardDatePicker
            {...restProps}
            emptyLabel=""
            label={label}
            KeyboardButtonProps={{ style: { marginRight: '20px' } }}
            placeholder="YYYY-MM-DD"
            value={selectedDate}
            onChange={handleDateChange}
            InputAdornmentProps={{ fontSize: '18px', height: '27px' }}
            format="YYYY-MM-DD"
        />
    );
};

const KeyboardDatePickerMemoized = React.memo(CustomKeyboardDatePicker);

export { KeyboardDatePickerMemoized as KeyboardDatePicker };
