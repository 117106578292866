import * as React from 'react';

const NavRoutesContext = React.createContext(null);

const NavigationRoutesProvider = ({ children }) => {
    const [headerWidths, setWidths] = React.useState({});

    const handleHeaderWidthsChange = React.useCallback((widths) => {
        setWidths(widths);
    }, []);

    const value = React.useMemo(() => ({ headerWidths, handleHeaderWidthsChange }), [headerWidths]);

    return <NavRoutesContext.Provider value={value}>{children}</NavRoutesContext.Provider>;
};

export { NavigationRoutesProvider, NavRoutesContext };
