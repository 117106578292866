import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
    panel: {
        flex: '1 1 45%',
        paddingBottom: 2,
        ['@media (max-width:1000px)']: {
            flexBasis: '100%',
        },
    },
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        padding: '4 4 0 4',
    },
}));
