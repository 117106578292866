import * as React from 'react';

import { useWidth } from './useWidth';

const useMobileWidth = () => {
    const width = useWidth(); // sm, xs

    const isMobilePortrait = React.useMemo(() => width === 'xs', [width]);
    const isMobile = React.useMemo(() => (isMobilePortrait ? true : width === 'sm'), [width]);
    const isLaptop = React.useMemo(() => (isMobile ? true : width === 'md'), [width]);

    return { isMobile, isMobilePortrait, isLaptop, width };
};

export { useMobileWidth };
