import { LOGOUT } from '../actions/screen';
import { SET_LOADING_STATE } from '../actions/progressIndicator';

const initialState = {
    isLoading: false,
    text: '',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING_STATE:
            return {
                ...state,
                isLoading: action.isLoading,
                text: action.text,
            };

        case LOGOUT:
            return initialState;

        default:
            return state;
    }
};
