import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { MIN_ALLOWABLE_DISTANCE_TO_ACCEPT } from '../actions/gps';

const useTimePickerType = () => useSelector((state) => get(state, 'routesDeclaration.timePickerType'));

const useColumnsDeclaration = () =>
    useSelector((state) => {
        const declaration = get(state, 'routesDeclaration.columnsDeclaration');

        return declaration.reduce((res, item) => {
            item.items.forEach((data) => {
                if (data) {
                    res[data.id] = data.title;
                }
            });

            return res;
        }, {});
    });

const getAllowedDistance = createSelector(
    (state) => get(state, 'routesDeclaration.allowedDistance', MIN_ALLOWABLE_DISTANCE_TO_ACCEPT),
    (allowedDistance) => allowedDistance
);

export { useColumnsDeclaration, useTimePickerType, getAllowedDistance };
