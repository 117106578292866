import React from 'react';

export const useTimer = (seconds, interval = 1000) => {
    const [timeLeft, setTimeLeft] = React.useState(seconds);

    React.useEffect(() => {
        if (!timeLeft) {
            return;
        }

        const intervalId = setInterval(() => setTimeLeft(timeLeft - 1), interval);

        return () => clearInterval(intervalId);
    }, [timeLeft]);

    return timeLeft;
};
