import React from 'react';

import { validateState, getValue, VALIDATION_EMPTY_FIELD } from '../../hooks/useForm/validate';
import MaterialTextField from '../../MaterialTextField/MaterialTextField';
import { useStyles } from './useStyles';
import { editLoadInfo } from '../../../actions/take-off';
import { useDispatch } from 'react-redux';
import { checkExistValue } from '../../../service/utils';
import { useSingleConvert } from '../../hooks/useMeasureConverter';

const VALIDATOR_MAX_VALUE = 150000;
const LoadInfoField = (props) => {
    const dispatch = useDispatch();
    const { loadInfo, recordId, name, defaultValue, onChangeValue, required, validator } = props;
    const handleEditField = React.useCallback((id, loadType, name, value) => dispatch(editLoadInfo(id, loadType, name, value)), []);
    const classes = useStyles();
    const [errorText, changeError] = React.useState(required && !defaultValue ? 'Field is required' : '');
    const [value, setValue] = React.useState(defaultValue);
    const convert = useSingleConvert();
    const [field, changeField] = React.useState({
        name: '',
        title: '',
        type: 'number',
        validator: { type: 'decimal', min: 0, max: convert(VALIDATOR_MAX_VALUE), precision: [7, 1] },
    });

    const handleChange = React.useCallback((evt) => {
        setValue(evt.target.value);
    }, []);

    React.useEffect(() => {
        if (checkExistValue(defaultValue)) {
            setValue(defaultValue);
            changeError((validateState(validator, defaultValue, required) || {}).errorText);
        }
    }, [required, defaultValue]);

    React.useEffect(() => {
        const updatedValidator = { ...field.validator, max: convert(VALIDATOR_MAX_VALUE) };
        changeField((prevState) => ({
            ...prevState,
            name,
            title: name,
            validator: { ...(validator || updatedValidator), isRequired: required },
        }));
    }, [validator, required, defaultValue]);

    const handleInputFocus = React.useCallback(() => {
        if (errorText) {
            changeError('');
        }
    }, []);

    const handleInfoChange = React.useCallback(
        (evt) => {
            const { validator, isRequired = false } = field;
            const fieldValue = evt.currentTarget.value === '' ? undefined : getValue(validator, evt.currentTarget.value);
            const validateRes = validateState(validator, fieldValue, isRequired);

            if (validateRes !== null && validateRes.errorText !== VALIDATION_EMPTY_FIELD) {
                return changeError(validateRes.errorText);
            }

            handleEditField(recordId, loadInfo.type, evt.target.name, fieldValue);
            if (onChangeValue) {
                onChangeValue(fieldValue);
            }
        },
        [loadInfo, field]
    );

    return (
        <MaterialTextField
            label=""
            placeholder="0"
            name={name}
            fullWidth={true}
            value={value}
            onChange={handleChange}
            onBlur={handleInfoChange}
            onFocus={handleInputFocus}
            className={classes.field}
            helperText={errorText}
            error={Boolean(errorText)}
            inputProps={{ className: classes.input }}
            type="number"
        />
    );
};

const LoadInfoFieldMemoized = React.memo(LoadInfoField);

export { LoadInfoFieldMemoized as LoadInfoField };
