import React from 'react';
import moment from 'moment';

import { useStyles } from './useStyles';

const FlightTaskRoute = ({ num, cancel = false, date, route }) => {
    const classes = useStyles();

    return (
        <span className={classes.container}>
            <span className={classes.number}>{num}</span>
            {cancel && <span className={classes.cancel}>{' (CANCELED) '}</span>}
            <span className={classes.route}>{date && moment.utc(date).format(' DD.MM.YYYY')}</span>
            {route && ` ${route}`}
        </span>
    );
};

const FlightTaskRouteMemoized = React.memo(FlightTaskRoute);

export { FlightTaskRouteMemoized as FlightTaskRoute };
