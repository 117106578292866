import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
    button: {
        width: 60,
        height: 60,
    },
    checkbox: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 0,
    },
    notice: {
        margin: theme.spacing(1.5, 0, 0),
        color: theme.textField.disabledTextColor,
    },
    panel: {
        display: 'flex',
        width: 300,
        flexWrap: 'wrap',
        padding: theme.spacing(1.5, 2),

        ['@media only screen and (max-width: 480px)']: {
            width: 220,
            padding: '12px 6px',
        },
    },
    panelItem: {
        flex: '0 0 50%',
    },
}));
