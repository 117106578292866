import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => {
    const { palette, textField } = theme;

    return {
        column: {
            padding: '0 !important',
            textAlign: 'center !important',
            minWidth: 50,
            lineHeight: '22px',
            fontSize: textField.textFontSize,

            ['@media (max-width: 1300px)']: {
                width: 50,
            },
        },
        columnSmall: {
            padding: '0 !important',
            textAlign: 'center !important',
            minWidth: 35,
            lineHeight: '22px',
            fontSize: textField.textFontSize,
        },
        columnLarge: {
            padding: '0 !important',
            textAlign: 'center !important',
            minWidth: 60,
            lineHeight: '22px',
            fontSize: textField.textFontSize,

            ['@media (max-width: 1300px)']: {
                width: 60,
            },
        },
        columnMain: {
            padding: '5px !important',
            textAlign: 'left !important',
            width: 50,
            lineHeight: '22px',
            fontSize: textField.textFontSize,
        },
        leftBorder: {
            borderLeft: `1px solid ${theme.palette.borderColor}`,
        },
        tableHeaderCell: {
            color: palette.primary3Color,
            fontWeight: 'normal',
        },
        lightText: {
            color: palette.textColor,
        },
        page: {
            display: 'flex',
            alignItems: 'flexStart',
            flexWrap: 'wrap',
            height: 'auto',
        },
        text: {
            fontSize: textField.textFontSize,
            fontWeight: 600,
            padding: theme.spacing(0, 2),
        },
    };
});
