import React from 'react';
import { get } from 'lodash';

import { SignaturePad } from '../SignaturePad/SignaturePad';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../components/material-ui/Dialog';
import { DialogControls } from '../DialogControls/DialogControls';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import MaterialTextField from '../MaterialTextField/MaterialTextField';
import { Typography } from '../material-ui/Typography';
import { useStyles } from './useStyles';
import { dataBufferToObjectURL, dataURItoBuffer } from '../../service/ImageUtils';
import { resizeSignature } from './tools';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getCurrentSendedLeg } from '../../selectors/sended-data';
import { editField } from '../../actions/take-off';
import { useSingleConvert } from '../hooks/useMeasureConverter';
import { validateState } from '../hooks/useForm/validate';

const SignaturePopup = React.forwardRef(({ showComment, onClose, title, visibility, onSign, signComment, ofpId }, ref) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { decisionFuel } = useSelector((state) => {
        const currentLeg = getCurrentSendedLeg(state);
        const takeOff = get(currentLeg, 'takeOff', {});
        let decisionFuel = takeOff.decisionFuel;
        let refuelingTakeoff = takeOff.refuelingTakeoff;

        return {
            decisionFuel,
            refuelingTakeoff,
        };
    }, shallowEqual);

    const [comment, setComment] = React.useState(signComment);
    const [signature, setSignature] = React.useState();
    const fuelFieldValidator = { type: 'decimal', min: 0, max: useSingleConvert()(296000), precision: [7, 1] };
    const [refueling, setRefueling] = React.useState(decisionFuel);
    const [errorText, changeError] = React.useState((validateState(fuelFieldValidator, refueling, true) || {}).errorText);
    const commentRef = React.useRef();

    const handleRefuelingChange = React.useCallback(
        (event) => {
            const validateRes = validateState(fuelFieldValidator, event.target.value, true);
            setRefueling(event.target.value);
            changeError(validateRes ? validateRes.errorText : '');
        },
        [validateState, changeError, setRefueling]
    );

    const handleInputFocus = React.useCallback(() => {
        if (errorText) {
            changeError('');
        }
    }, [errorText]);

    const handleClearSignature = React.useCallback(() => {
        if (signature) {
            signature.clear();
        }
    }, [signature]);

    const handleSign = React.useCallback(() => {
        const dataimg = signature.toDataURL('image/png');
        const byteCharacters = dataURItoBuffer(dataimg);
        const preview = dataBufferToObjectURL(byteCharacters, byteCharacters.contentType);
        if (ofpId) {
            dispatch(editField('decisionFuel', +refueling));
            dispatch(editField('refuelingTakeoff', +refueling));
        }

        onSign(
            {
                data: byteCharacters.data,
                contentType: byteCharacters.contentType,
                preview: preview,
            },
            get(commentRef, 'current.value')
        );

        onClose();
    }, [commentRef, signature, refueling, ofpId]);

    const handleResizeCanvas = React.useCallback(() => {
        resizeSignature(signature);
    }, [signature]);

    React.useEffect(() => {
        window.addEventListener('resize', handleResizeCanvas);

        return () => window.removeEventListener('resize', handleResizeCanvas);
    }, [signature]);

    const handleClose = React.useCallback(() => {
        setComment('');
        onClose();
    }, []);

    const handleCommentChange = React.useCallback((event) => {
        setComment(event.target.value);
    }, []);

    const handleSetSignature = React.useCallback((data) => {
        resizeSignature(data);
        setSignature(data);
    }, []);

    const [signatureEmpty, setSignatureEmpty] = React.useState(!signature || signature.isEmpty());

    const handleUpdateActions = React.useCallback(() => {
        setSignatureEmpty(!signature || signature.isEmpty());
    }, [signature]);

    const actions = React.useMemo(
        () => [
            { label: 'Cancel', primary: true, onClick: handleClose },
            {
                label: ofpId ? 'Send' : 'OK',
                primary: true,
                onClick: handleSign,
                disabled: signatureEmpty || (ofpId && (!refueling || Boolean(errorText))),
            },
        ],
        [signatureEmpty, refueling, errorText]
    );

    React.useEffect(() => {
        if (signature) {
            signature.addEventListener('afterUpdateStroke', handleUpdateActions);
        }

        return () => {
            if (signature) {
                signature.removeEventListener('afterUpdateStroke', handleUpdateActions);
            }
        };
    });

    return (
        <Dialog open={visibility} onClose={onClose} fullWidth={true} maxWidth="lg">
            <DialogTitle className={classes.title}>{title}</DialogTitle>
            <DialogContent ref={ref}>
                <ErrorBoundary>
                    <SignaturePad onClear={handleClearSignature} onInitSignature={handleSetSignature} />
                    <Typography className={classes.info}>To confirm you should sign here and tap Ok</Typography>
                    {ofpId && (
                        <MaterialTextField
                            label={'Decision fuel, KG'}
                            name={'decisionFuel'}
                            fullWidth={true}
                            onChange={handleRefuelingChange}
                            value={refueling}
                            onFocus={handleInputFocus}
                            required={true}
                            className={classes.field}
                            type="number"
                            helperText={errorText}
                            error={Boolean(errorText)}
                        />
                    )}
                    {showComment && (
                        <MaterialTextField
                            inputRef={commentRef}
                            placeholder="Comment"
                            label="Comment"
                            name="signComment"
                            onChange={handleCommentChange}
                            fullWidth={true}
                            value={comment}
                            multiline={true}
                            minRows={1}
                            maxRows={2}
                            key="signComment"
                        />
                    )}
                </ErrorBoundary>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <DialogControls actions={actions} />
            </DialogActions>
        </Dialog>
    );
});

const SignaturePopupMemoized = React.memo(SignaturePopup);

export { SignaturePopupMemoized as SignaturePopup };
