import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import message from '../../service/Translations';
import { FlightTaskHeader } from './FlightTaskHeader/FlightTaskHeader';
import { FlightTaskCrewTable } from './FlightTaskCrewTable/FlightTaskCrewTable';
import { FlightTaskLegsTable } from './FlightTaskLegsTable/FlightTaskLegsTable';
import { useHideModal } from '../../actions/modal';
import { useSignFlightTask } from '../../actions/tasks';
import { SignaturePopup } from '../../components/SignaturePopup/SignaturePopup';
import { useStyles } from './useStyles';
import { Typography } from '../../components/material-ui/Typography';
import { Dialog, DialogContent, DialogTitle } from '../../components/material-ui/Dialog';
import { AppBar } from '../../components/material-ui/AppBar';
import { Toolbar } from '../../components/material-ui/Toolbar';
import { CrewTypes } from '../../service/constants';
import { DialogControls } from '../../components/DialogControls/DialogControls';
import { FlightTaskSwitch } from './FlightTaskSwitch/FlightTaskSwitch';
import { getFlightPageProps, useFlightPageSwitchers } from '../../selectors/flightPage';

export const FLIGHT_TASK = 'FLIGHT_TASK';

const FlightTaskModal = React.forwardRef((props, ref) => {
    const data = useSelector((state) => getFlightPageProps(state));
    const { id, flights, crew = [], signaturePreview, crewType } = data;
    const { isRu, isLocalTime } = useFlightPageSwitchers();
    const hideModal = useHideModal();
    const onSignFlightTask = useSignFlightTask();

    const [isPopupOpen, changeOpenPopup] = React.useState(false);

    const getDateField = React.useCallback(
        (fieldName, obj) => {
            let field = isLocalTime ? `${fieldName}_local` : fieldName;
            const date = obj[field];

            return {
                date: date ? moment.utc(obj[field]).format('DD.MM') : '',
                time: date ? moment.utc(obj[field]).format(' HH:mm') : '',
            };
        },
        [isLocalTime]
    );

    const getTextField = React.useCallback(
        (fieldName, obj) => {
            let field = isRu ? fieldName : `${fieldName}Eng`;

            return obj && obj[field];
        },
        [isRu]
    );

    const getMessage = React.useCallback(() => {
        let lang = isRu ? 'ru' : 'en';

        return message(lang);
    }, [isRu]);

    const showSignaturePopup = React.useCallback(() => {
        changeOpenPopup(true);
    }, []);

    const hideSignaturePopup = React.useCallback(() => {
        changeOpenPopup(false);
    }, []);

    const handleSignFlightTask = React.useCallback(
        (signature) => {
            onSignFlightTask(id, signature);
        },
        [id]
    );

    const chief = getTextField('fullName', crew && crew[0]);
    const operator = getTextField('operator', flights && flights[0]);
    const classes = useStyles();

    const switchers = React.useMemo(() => {
        return [
            {
                name: 'isRu',
                label: 'RU',
                defaultValue: true,
            },
            {
                name: 'isLocalTime',
                label: 'Local time',
                defaultValue: false,
            },
        ];
    }, []);

    const actionList = React.useMemo(
        () => [
            ...(crewType === CrewTypes.PCT_CABINSTAFF
                ? [
                      {
                          label: 'Sign',
                          variant: 'text',
                          type: 'reset',
                          onClick: showSignaturePopup,
                      },
                  ]
                : []),
            {
                label: 'Close',
                variant: 'text',
                type: 'reset',
                className: classes.closeBtn,
                onClick: hideModal,
            },
        ],
        []
    );

    return (
        <Dialog fullScreen={true} open={true} onClose={hideModal} scroll="paper">
            <DialogTitle>
                <AppBar>
                    <Toolbar className={classes.appBar}>
                        <div className={classes.switchContainer}>
                            {switchers.map((switcher) => (
                                <FlightTaskSwitch key={switcher.name} {...switcher} />
                            ))}
                        </div>
                        <DialogControls actions={actionList} appBar={true} />
                    </Toolbar>
                </AppBar>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <div ref={ref}>
                    <FlightTaskHeader {...data} getMessage={getMessage()} chief={chief} operator={operator} />
                    <FlightTaskCrewTable crew={crew} getMessage={getMessage()} getTextField={getTextField} />
                    <FlightTaskLegsTable flights={flights} getMessage={getMessage()} getDateField={getDateField} />
                    <div className={classes.signatureWrapper}>
                        <div>
                            <Typography>{chief}</Typography>
                            <Typography>{getMessage()`Chief`}</Typography>
                        </div>
                        <div>
                            {signaturePreview ? (
                                <img src={signaturePreview} width="300px" height="125px" title="Pilot signature" />
                            ) : (
                                <Typography>{getMessage()`Sign place`}</Typography>
                            )}
                        </div>
                        <Typography>
                            {flights &&
                                flights[0] &&
                                moment
                                    .utc(flights[0].date)
                                    .locale(isRu ? 'ru' : 'en')
                                    .format('DD MMMM YYYY')}
                        </Typography>
                    </div>
                    <SignaturePopup
                        visibility={isPopupOpen}
                        text={'Signature popup text'}
                        title={'Signing the flight task title'}
                        onSign={handleSignFlightTask}
                        onClose={hideSignaturePopup}
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
});

export default FlightTaskModal;
