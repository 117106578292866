import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    const {
        table: { secondaryColor },
    } = theme;

    return {
        cell: {
            paddingTop: 8,
            paddingBottom: 8,
        },
        highlightRow: {
            background: secondaryColor,
        },
    };
});
