import { get } from 'lodash';

import { setLoadingState } from './progressIndicator';
import { showConfirmDialog } from './messageDialog';
import { MessageDialogTypes } from '../components/MessageDialog/MessageDialog';
import { MessageType } from '../service/constants';
import { getErrorMessage } from '../service/utils';
import errorLogsSender from '../service/ErrorLogsSender';

export const UPDATE_CHECKLIST = 'UPDATE_CHECKLIST';
export const TOGGLE_CHECKED_CHECKLISTITEM = 'TOGGLE_CHECKED_CHECKLISTITEM';
export const UPDATE_SENDED_CHECKLIST = 'UPDATE_SENDED_CHECKLIST';

const downloadCheckListData =
    (legId, userId, crewType) =>
    (dispatch, getState, { apiManager }) => {
        dispatch(setLoadingState(true, 'Downloading check list data...'));

        return apiManager.getCheckListData(legId, userId, crewType);
    };

export const downloadCheckList = (legId, crewType) => (dispatch, getState) => {
    dispatch(setLoadingState(false));
    const state = getState();
    const userId = get(state, 'login.user.id');

    const checkPromise = dispatch(downloadCheckListData(legId, userId, crewType));

    return Promise.all([checkPromise])
        .then(([data]) => {
            if (!data) {
                return;
            }

            const checkLists = Object.keys(data).reduce((res, key) => {
                res[`${legId}_${key}`] = { legId, ...data[key] };

                return res;
            }, {});

            return Promise.all([dispatch({ type: UPDATE_CHECKLIST, data: checkLists }), dispatch(setLoadingState(false))]);
        })
        .catch((err) => {
            dispatch(
                showConfirmDialog({
                    title: 'Error message',
                    message: 'Failed to get data from the server. Please try again later!',
                    btnCancelVisibility: false,
                    options: {
                        type: MessageDialogTypes.ERROR,
                    },
                })
            );
            const error = new Error(getErrorMessage(err, MessageType.GET_CHECKLIST_DATA_API), {
                cause: err,
            });
            errorLogsSender.addLog(error);
            throw error;
        })
        .finally(() => dispatch(setLoadingState(false)));
};

export const toggleCheckedCheckListItem = (checkListId, itemId) => (dispatch) => {
    dispatch({
        type: TOGGLE_CHECKED_CHECKLISTITEM,
        checkListId,
        itemId,
    });
};

export const updateCheckListAfterSaving = (legsData) => ({
    type: UPDATE_SENDED_CHECKLIST,
    legsData,
});
