import React from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import moment from 'moment';

import { DialogControls } from '../../../components/DialogControls/DialogControls';
import { saveCommentData } from '../../../actions/navigation';
import { useHideModal } from '../../../actions/modal';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../../components/material-ui/Dialog';
import { TextField } from '../../../components/material-ui/TextField';
import { FormControl } from '../../../components/material-ui/FormControl';
import { useStyles } from './useStyles';
import { useMobileWidth } from '../../../components/hooks/useMobileWidth';
import { useForm } from '../../../components/hooks/useForm/useForm';
import { FieldTypes } from '../../../components/hooks/useForm/validate';
import { NavType } from '../../../components/NavigationRoutes/constants';

const fields = {
    comment: { title: 'Comment', type: FieldTypes.STRING, required: false },
};

const Comment = React.forwardRef((props, ref) => {
    const { isNewPoint = false, pos, point } = props;
    const classes = useStyles();
    const { isMobilePortrait } = useMobileWidth();
    const hideModal = useHideModal();
    const dispatch = useDispatch();
    const handleSuccess = React.useCallback(
        (data) => {
            dispatch(saveCommentData(pos, data, isNewPoint));
            hideModal();
        },
        [pos, isNewPoint]
    );

    const { inputs, handleInputChange, handleSubmit } = useForm({
        onSuccess: handleSuccess,
        initialData: {
            ...(isNewPoint
                ? {
                      isUserAdd: 1,
                      pointNumber: point.pointNumber,
                      dateCreate: moment.utc(),
                      comment: '',
                  }
                : point),
            type: NavType.COMMENT,
        },
        fields,
    });

    const actions = React.useMemo(
        () => [
            { label: 'CANCEL', onClick: hideModal },
            { label: 'OK', color: 'primary', type: 'submit' },
        ],
        []
    );

    return (
        <Dialog ref={ref} open={true} maxWidth="md" fullScreen={isMobilePortrait} classes={{ paper: classes.paper }}>
            <DialogTitle className={classes.title}>{isNewPoint ? 'Add comment' : 'Edit comment'}</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent className={classes.content}>
                    <FormControl className={classes.formControl}>
                        <TextField
                            name="comment"
                            label="Comment"
                            onChange={handleInputChange}
                            fullWidth={true}
                            multiline={true}
                            value={get(inputs, 'comment', '')}
                            minRows={1}
                            maxRows={5}
                            className={classes.textarea}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <DialogControls actions={actions} />
                </DialogActions>
            </form>
        </Dialog>
    );
});

export { Comment };
