import React from 'react';

import { Panel } from '../Panel/Panel.jsx';
import { Select } from '../material-ui/Select';
import PilotFlight from '../../icons/image/PilotFlight';
import { MenuItem } from '../material-ui/Menu';
import { sortCrewByName } from '../../service/sort';
import { Typography } from '../material-ui/Typography';
import { FormControl } from '../material-ui/FormControl';
import { FormHelperText } from '../material-ui/FormHelperText';
import { useStyles } from './useStyles';

import '../ActionTimeControl/ActionTimeControl.scss';

const SelectCrewPanel = (props) => {
    const { title, className, value, isRequired, onChange } = props;
    const isError = isRequired && !value;
    const error = isError ? 'Field is required' : '';
    const classes = useStyles();

    const handleChange = React.useCallback((event) => {
        onChange(event.target.value);
    }, []);

    const items = React.useMemo(() => (props.items && props.items.sort(sortCrewByName)) || [], [props.items]);

    return (
        <Panel title={title} className={className} leftIcon={<PilotFlight width="30" height="30" />}>
            <div className={classes.content}>
                <FormControl error={isError}>
                    <Select value={value || 0} onChange={handleChange} autoWidth={false} className={classes.select}>
                        <MenuItem value={0} key={0}>
                            <Typography>Not selected</Typography>
                        </MenuItem>
                        {items.map((item) => (
                            <MenuItem value={item.crewId} key={item.crewId}>
                                <Typography>{item.fullNameEng}</Typography>
                            </MenuItem>
                        ))}
                    </Select>
                    {isError && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
            </div>
        </Panel>
    );
};

const SelectCrewPanelMemoized = React.memo(SelectCrewPanel);

export { SelectCrewPanelMemoized as SelectCrewPanel };
