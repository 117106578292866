import React from 'react';

import { FlightTaskComment } from './FlightTaskComment/FlightTaskComment';
import { SummaryTaskPanel } from './SummaryTaskPanel/SummaryTaskPanel';
import { SummaryBufferCrewPanel } from './SummaryBufferCrewPanel/SummaryBufferCrewPanel';
import { Panel } from '../Panel/Panel';
import { useStyles } from './useStyles';

const SummaryTaskPage = () => {
    const classes = useStyles();

    return (
        <div className={classes.summary}>
            <Panel title="Flight task info" className={classes.legPanel}>
                <SummaryTaskPanel />
                <FlightTaskComment />
            </Panel>
            <SummaryBufferCrewPanel />
        </div>
    );
};

const SummaryTaskPageMemoized = React.memo(SummaryTaskPage);

export { SummaryTaskPageMemoized as SummaryTaskPage };
