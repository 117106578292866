// Создаём новый объект, затем через прототип делаем его наследником конструктора Error.
function HttpError(errorCode, message) {
    this.name = 'HttpError';
    this.errorCode = errorCode;
    this.message = message || 'Сообщение по умолчанию';
    this.stack = new Error().stack;
}
HttpError.prototype = Object.create(Error.prototype);
HttpError.prototype.constructor = HttpError;

export const UNAUTHORIZED_HTTP_CODE = 401;

export { HttpError };
