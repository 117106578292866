import { GET_LIBRARY, DOWNLOAD_LIBRARY_ITEMS, REMOVE_FILE, REMOVE_ALL_FILES } from '../actions/library';

import { LOGOUT } from '../actions/screen';

const initialState = {};

function checkDownloadStatus(list, id, withUpdate = false) {
    let library = Object.assign({}, list);

    if (library[id]) {
        library[id] = Object.assign({}, library[id], { isDownloaded: true, child: library[id].child.slice(0) });
        if (withUpdate) {
            delete library[id].needUpdate;
            delete library[id].hasUpdates;
        }
        const pid = library[id].pid;
        const isFolderDownloaded = Object.keys(library)
            .filter((key) => library[key].pid == pid)
            .every((key) => library[key].isDownloaded);

        if (isFolderDownloaded) {
            library = checkDownloadStatus(library, pid);
        }
    }

    return library;
}

function clearDownloadStatus(list) {
    let library = Object.assign({}, list);

    Object.keys(library).forEach((id) => {
        library[id] = Object.assign({}, library[id], { isDownloaded: false });
    });

    return library;
}

function removeItem(list, id) {
    let library = Object.assign({}, list);

    if (library[id] && library[id].pid) {
        library[id] = Object.assign({}, library[id], { isDownloaded: false, child: library[id].child.slice(0) });
        library = removeItem(library, library[id].pid);
    } else if (library[id]) {
        library[id] = Object.assign({}, library[id], { isDownloaded: false, child: library[id].child.slice(0) });
    }

    if (library[id] && library[id].req) {
        library[id].needUpdate = true;
    }

    return library;
}

function clearParentsDownloadStatus(list, id) {
    let library = { ...list };

    if (library[id]) {
        library[id] = { ...library[id], isDownloaded: false, child: library[id].child.slice(0) };
    }

    if (library[id] && library[id].pid) {
        library = clearParentsDownloadStatus(library, library[id].pid);
    }

    return library;
}

export default (state = initialState, action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case GET_LIBRARY: {
            newState.library = { ...action.library };
            newState.syncTime = action.syncTime;
            action.downloaded.forEach((id) => {
                newState.library = checkDownloadStatus(newState.library, id);

                return newState;
            });

            action.changedFolders.forEach((id) => {
                newState.library = clearParentsDownloadStatus(newState.library, id);

                return newState;
            });

            return newState;
        }
        case DOWNLOAD_LIBRARY_ITEMS:
            action.downloaded.forEach((id) => {
                newState.library = checkDownloadStatus(newState.library, id, true);

                return newState;
            });

            return Object.assign({}, newState);
        case REMOVE_FILE:
            newState.library = removeItem(newState.library, action.id);

            return newState;
        case REMOVE_ALL_FILES:
            newState.library = clearDownloadStatus(newState.library);

            return newState;
        case LOGOUT:
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
