import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';

import { getAttachments } from '../../../actions/telegrams';
import { NestedListItem } from '../../NestedListItem/NestedListItem';
import { ListItem, ListItemIcon, ListItemText } from '../../material-ui/List';
import Document from '../../../icons/image/Document';
import Complete from '../../../icons/image/Complete';

const TelegramItem = (props) => {
    const { code, style, telegrams, getStyle, onAttachmentClick, primaryText, disableAttachments } = props;
    const { signExist, mailTime, attachments = {} } = (telegrams && telegrams[code]) || {};
    const items = getAttachments(attachments);
    const text = `${primaryText}${items.length > 0 ? ` (${items.length} file${items.length > 1 ? 's' : ''})` : ''}`;
    const classes = useStyles();

    const handleClick = React.useCallback(() => {
        props.onClick(code);
    }, [code]);

    return (
        <NestedListItem
            showSign={signExist}
            onClick={handleClick}
            style={style || getStyle(!mailTime)}
            primary={text}
            secondary={mailTime && `${moment.utc(mailTime).format('DD.MM.YYYY HH:mm')}`}
            disabled={!mailTime}
            icon={<Complete className={classes.icon} width={24} height={24} />}
            inset={false}
            showIcon={true}
        >
            {items.map(({ id, disabled, name, extension, changeTime }) => (
                <ListItem
                    key={id}
                    disabled={disabled || disableAttachments}
                    style={getStyle(disabled)}
                    onClick={() => onAttachmentClick(id)}
                    button={true}
                    className={classes.nested}
                >
                    <ListItemIcon>
                        <Document width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText
                        primary={`${name}${extension}`}
                        secondary={changeTime && `${moment.utc(changeTime).format('DD.MM.YYYY HH:mm')}`}
                    />
                </ListItem>
            ))}
        </NestedListItem>
    );
};

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
        color: theme.palette.text.primary,
    },
    icon: {
        fill: '#00b600',
    },
    listIcon: { minWidth: '40px' },
}));

TelegramItem.defaultProps = {
    style: {},
    onClick: () => {},
    onAttachmentClick: () => {},
    getStyle: () => {},
    disableAttachments: false,
};

export { TelegramItem };
