import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
    },
    inner: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 58,
    },
    notice: {
        backgroundColor: theme.paper.backgroundColor,
        fontFamily: theme.fontFamily,
        color: theme.palette.textColor,
    },
    paper: {
        backgroundColor: theme.paper.backgroundColor,
        color: theme.palette.textColor,
    },
    // airportBackgroundColor,
    // iconColor,
}));

export { useStyles };
