import React from 'react';
import { useDispatch } from 'react-redux';

import { useHideModal } from '../../actions/modal';
import { DialogControls } from '../../components/DialogControls/DialogControls';
import { DateTimePicker } from '../../components/DateTimePicker/DateTimePicker';
import { getUserReports } from '../../actions/reports';
import { getTimeDiff } from '../../service/DateTimeUtils';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../components/material-ui/Dialog';
import { useStyles } from './useStyles';
import { Typography } from '../../components/material-ui/Typography';
import { useMobileWidth } from '../../components/hooks/useMobileWidth';
import { FilterTypes } from '../../reducers/filter';
import { useFilter } from '../../selectors/filter';
import { useSetFilter } from '../../actions/filter';

const ReportsLoading = React.forwardRef((props, ref) => {
    const { title, text } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const hideModal = useHideModal();
    const { isMobilePortrait } = useMobileWidth();
    const { dateFrom, dateTo } = useFilter(FilterTypes.REPORT_LOADING);
    const [state, changeState] = React.useState({ dateFrom, dateTo, errorText: '' });
    const setFilter = useSetFilter(FilterTypes.REPORT_LOADING);

    React.useEffect(
        () =>
            changeState({
                dateFrom,
                dateTo,
                errorText: '',
            }),
        [dateFrom, dateTo]
    );

    const handleChangeDate = React.useCallback(
        (name, date) =>
            changeState((prevState) => ({
                ...prevState,
                [name]: date,
            })),
        []
    );

    const getSelectError = React.useCallback(() => {
        if (state.dateFrom > state.dateTo) {
            return 'Date To must be later than date From.';
        }

        const diff = getTimeDiff(state.dateFrom, state.dateTo);
        if (diff && diff / (3600 * 24) >= 31) {
            return 'The period must not exceed 31 days.';
        }
    }, [state.dateFrom, state.dateTo]);

    const handleDownloadReports = React.useCallback(() => {
        const errorText = getSelectError();
        if (!errorText) {
            setFilter({
                dateFrom: state.dateFrom,
                dateTo: state.dateTo,
            });
            dispatch(getUserReports(state.dateFrom, state.dateTo));
            hideModal();
        } else {
            changeState((prevState) => ({ ...prevState, errorText }));
        }
    }, [getSelectError]);

    const actions = React.useMemo(
        () => [
            { label: 'Cancel', primary: true, onClick: hideModal },
            {
                label: 'OK',
                primary: true,
                onClick: handleDownloadReports,
            },
        ],
        [handleDownloadReports]
    );

    return (
        <Dialog ref={ref} open={true} maxWidth="md" fullScreen={isMobilePortrait}>
            <DialogTitle className={classes.title}>{title}</DialogTitle>
            <DialogContent className={classes.content}>
                <Typography className={classes.text}>{text}</Typography>
                <div className={classes.fields}>
                    <DateTimePicker
                        labelTextDate={'DATE FROM'}
                        labelTextTime={'TIME'}
                        name="dateFrom"
                        value={new Date(state.dateFrom)}
                        className={classes.auto}
                        onChange={handleChangeDate}
                    />
                    <DateTimePicker
                        labelTextDate={'DATE TO'}
                        labelTextTime={'TIME'}
                        name="dateTo"
                        value={new Date(state.dateTo)}
                        className={classes.auto}
                        onChange={handleChangeDate}
                    />
                </div>
                <Typography className={classes.errorText}>{state.errorText}</Typography>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <DialogControls actions={actions} />
            </DialogActions>
        </Dialog>
    );
});

const ReportsLoadingMemoized = React.memo(ReportsLoading);

export { ReportsLoadingMemoized as ReportsLoading };
