import React, { memo } from 'react';

import { Button } from '../../../material-ui/Button';
import { useStyles } from './useStyles';

const buttonLabels = ['Login', 'Send'];

export const SendButton = memo(({ onClick, step = 0, disabled }) => {
    const classes = useStyles();

    return (
        <Button variant="contained" onClick={onClick} className={classes.button} disabled={disabled}>
            {buttonLabels[step]}
        </Button>
    );
});
