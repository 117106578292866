import {
    DEFAULT_TELEX_FONT_SIZE,
    LOGOUT,
    REDIRECT_PAGE,
    SET_FONT_SIZE,
    TOGGLE_ENROUTE_MODE,
    TOGGLE_SNACK_NOTIFICATION,
    TOGGLE_THEME,
} from '../actions/screen';

import { SET_CURRENT_TASK } from '../actions/tasks';
import { SET_CURRENT_FLIGHT } from '../actions/flights';
import { SET_CURRENT_LEG } from '../actions/legs';
import { SET_CURRENT_USER } from '../actions/tasks';
import { SET_OFP } from '../actions/startInfo';
import { SET_INITIAL_OFP } from '../actions/systemOfMeasurement';

const initialState = {
    currentLeg: 0,
    error: '',
    user: {},
    darkTheme: false,
    mobileEnrouteMode: false,
    fontSize: DEFAULT_TELEX_FONT_SIZE,
    ofpId: null,
};

export const screenInitialState = initialState;

export default (state = initialState, action) => {
    let newState;

    switch (action.type) {
        case LOGOUT:
            return initialState;

        case SET_CURRENT_TASK:
            return Object.assign({}, state, { currentTask: action.taskId, currentLeg: 0 });

        case SET_CURRENT_USER:
            return Object.assign({}, state, {
                currentUser: { id: action.id, name: action.name, preview: action.preview },
            });

        case SET_CURRENT_FLIGHT:
            return Object.assign({}, state, { currentFlight: action.flightId });

        case SET_CURRENT_LEG:
            return Object.assign({}, state, { currentLeg: action.legId, currentFlight: action.flightId, ofpId: action.ofpId });
        case SET_INITIAL_OFP:
        case SET_OFP:
            return Object.assign({}, state, { ofpId: action.ofpId });
        case REDIRECT_PAGE:
            return Object.assign({}, state, { navigationPanelVisibility: false });
        case SET_FONT_SIZE:
            return { ...state, fontSize: action.payload };
        case TOGGLE_SNACK_NOTIFICATION:
            newState = Object.assign({}, state);

            newState.snackNotification = {
                isVisible: action.isVisible,
                message: action.message,
            };

            return newState;

        case TOGGLE_THEME:
            return { ...state, darkTheme: action.darkTheme };
        case TOGGLE_ENROUTE_MODE:
            return { ...state, mobileEnrouteMode: !state.mobileEnrouteMode };
        default:
            return state;
    }
};
