import React from 'react';
import { get } from 'lodash';

import { useStyles } from './useStyles';
import { NestedListItem } from '../../NestedListItem/NestedListItem';
import { documentsSort } from '../../../service/sort';
import { DocumentItem } from '../../DocumentsPage/DocumentItem/DocumentItem';

const AirportDocuments = ({ docs, style }) => {
    const classes = useStyles();

    return (
        <NestedListItem
            showSign={false}
            inset={false}
            style={style}
            className={classes.listItem}
            primary="Airport documents"
            disabled={!docs.length}
        >
            {docs.sort(documentsSort).map((doc, index) => (
                <DocumentItem
                    key={index}
                    id={doc.id}
                    primaryText={`${doc.airport} ${doc.type}`}
                    secondaryText={doc.description}
                    download={get(doc, 'syncTime')}
                    downloadFormat="DD.MM.YYYY HH:mm"
                    downloadPreifix=""
                    className={classes.nested}
                />
            ))}
        </NestedListItem>
    );
};

const AirportDocumentsMemoized = React.memo(AirportDocuments);

AirportDocumentsMemoized.displayName = 'AirportDocuments';

export { AirportDocumentsMemoized as AirportDocuments };
