import { getLegWorkInfo, getSendedDataPreview, getBufferCrewValues } from '../selectors/sended-data';
import { get } from 'lodash';
import { CrewTypes } from '../service/constants';
import { getTimeDiff } from '../service/DateTimeUtils';
import moment from 'moment';
import { getFlightTaskLegsList, getUsersEdgeLeg, getFlightTaskLegIds, getPersonnelFlightsTask } from '../selectors/screen';

const GENERATE_CREW_INFO = 'GENERATE_CREW_INFO';
const CHANGE_CREW_INFO = 'CHANGE_CREW_INFO';
const DEFAULT_BEFORE_TIME = 2400; // 40 min
const DEFAULT_AFTER_TIME = 1200; // 20 min
const DEFAULT_PERCENT = 100; // 100%
const MINIMUM_DELAY = 10;

function calculateBufferCrewCustom(custom, current) {
    const { workBegin: workBeginCustom } = custom;
    const workEndCustom =
        custom.workEnd || (workBeginCustom && custom.work && moment.utc(workBeginCustom).add(custom.work || 0, 'seconds'));

    const { workBegin, workAfter = 0, workBefore = 0, work } = current;
    const workBeforeCustom = workBefore - getTimeDiff(moment.utc(workBegin), moment.utc(workBeginCustom));
    const workEnd = workBegin && moment.utc(workBegin).add(work || 0, 'seconds');
    const workCustom = workBeginCustom && workEndCustom && getTimeDiff(moment.utc(workBeginCustom), moment.utc(workEndCustom));
    const workAfterCustom = workAfter - getTimeDiff(moment.utc(workEndCustom), moment.utc(workEnd));

    return {
        workBegin: workBeginCustom,
        workBefore: workBeforeCustom,
        workAfter: workAfterCustom,
        work: workCustom,
        biologicalNight: custom.biologicalNight,
    };
}

function calculatePassengerWorkTime(legInfo, props) {
    const {
        percent = DEFAULT_PERCENT,
        before = DEFAULT_BEFORE_TIME,
        after = DEFAULT_AFTER_TIME,
        isFirstLeg = false,
        isLastLeg = false,
    } = props;

    // Расчет рабочего времени пассажира
    const { timeWorkParking = 0, blockTime = 0, timeEngineWorkBefore = 0 } = legInfo;
    // Должен быть не меньше 40 минут, но в настоящий момент время перелета пассажиром не редактируется,
    // поэтому нет смысла его объединять со временем начала рабочего времени всего экипажа. Поэтому просто 40 мин. Аналогично и с посадкой.
    const workBefore = isFirstLeg ? before : 0;
    const workAfter = isLastLeg ? after : 0;
    const parking = isFirstLeg ? 0 : timeWorkParking;
    const beforeTakeoffTime = timeEngineWorkBefore + (isFirstLeg ? workBefore : parking);
    const workBegin = legInfo.takeOffTime && moment.utc(legInfo.takeOffTime).add(-1 * beforeTakeoffTime, 'seconds');

    return {
        workBegin,
        workBefore,
        workAfter,
        parking,
        flight: 0,
        engineWorBefore: 0,
        engineWorkAfter: 0,
        timeTakeoff: legInfo.takeOffTime,
        work: (blockTime + workBefore + workAfter + parking) * (percent / 100),
        dist: legInfo.distance,
    };
}

function calculateCrewWorkTime(prevBufferCrew, legInfo, override, props) {
    const {
        before = DEFAULT_BEFORE_TIME,
        after = DEFAULT_AFTER_TIME,
        isFirstLeg = false,
        isLastLeg = false,
        defaultCabinDuty = false,
        std,
        etd,
        crewType,
        splittedShift,
        previousLegWorkEnd,
        previousTaskWorkEnd,
        isSplittedShiftNextLeg,
        isExistNextWorkTask,
    } = props;
    const data = {
        workBegin: legInfo.startWorkTime,
        work: legInfo.workTime,
        timeTakeoff: legInfo.takeOffTime,
        dist: legInfo.distance,
        flight: legInfo.flightTime,
        // independent: legInfo.independent,
        night: legInfo.timeFlightNight,
        engineWorBefore: legInfo.timeEngineWorkBefore,
        engineWorkAfter: legInfo.timeEngineWorkAfter,
        biologicalNight: legInfo.timeBiologicalNight,
        workBefore: legInfo.timeWorkBefore,
        workAfter: legInfo.timeWorkAfter,
        rest: legInfo.timeWorkRest,
        parking: legInfo.timeWorkParking,
        delay: legInfo.timeWorkDelay,
        landings: legInfo.landings,
        landingsOnDevices: legInfo.landingsOnDevices,
        timeOnDevices: legInfo.timeOnDevices,
    };
    const isDefaultCabinDuty = (crewType === CrewTypes.PCT_SALONSTAFF && defaultCabinDuty) || false;

    if (isFirstLeg) {
        if (!isDefaultCabinDuty) {
            const diff = (data.parking || 0) + (data.delay || 0);
            if (previousTaskWorkEnd && splittedShift !== true) {
                data.workBegin = previousTaskWorkEnd;
                data.work = getTimeDiff(data.workBegin, legInfo.endWorkTime);
                data.workBefore = getTimeDiff(data.workBegin, legInfo.startEngineTime);
            } else {
                data.workBegin = data.workBegin && moment.utc(data.workBegin).add(diff, 'seconds');
                data.work = data.work && data.work - diff;
            }
        }
        data.rest = 0;
        data.parking = 0;
        data.delay = 0;
    }

    if (isDefaultCabinDuty) {
        const date = etd && std && Math.abs(moment.utc(etd).diff(moment.utc(std), 'hours')) > MINIMUM_DELAY ? etd : std;
        const endWorkTime = legInfo.stopEngineTime && moment.utc(legInfo.stopEngineTime).add(after, 'seconds');
        const beginWorkTime = std && etd && moment.utc(date).add(-before, 'seconds');
        if (
            isFirstLeg &&
            previousTaskWorkEnd &&
            (splittedShift !== true || (beginWorkTime && moment.utc(previousTaskWorkEnd) > moment.utc(beginWorkTime)))
        ) {
            data.workBegin = previousTaskWorkEnd;
        } else if (splittedShift && previousLegWorkEnd && moment.utc(previousLegWorkEnd) > moment.utc(beginWorkTime)) {
            data.workBegin = previousLegWorkEnd;
        } else if (isFirstLeg || splittedShift) {
            data.workBegin = beginWorkTime;
        }

        if (isSplittedShiftNextLeg || (isLastLeg && isExistNextWorkTask !== true)) {
            data.workAfter = after;
            data.work = getTimeDiff(data.workBegin, endWorkTime);
        } else if (isLastLeg && isExistNextWorkTask === true) {
            data.work = getTimeDiff(data.workBegin, legInfo.stopEngineTime);
        } else {
            data.work = getTimeDiff(data.workBegin, legInfo.endWorkTime);
        }

        data.workBefore = getTimeDiff(data.workBegin, legInfo.startEngineTime);
    }

    // Если были ручные изменения даты начала и окончания, то выполняем пересчет и оставляем введенные данные
    if (
        prevBufferCrew.isBlock &&
        !override &&
        (prevBufferCrew.workBegin !== legInfo.startWorkTime ||
            prevBufferCrew.work !== legInfo.workTime ||
            prevBufferCrew.biologicalNight !== legInfo.biologicalNight)
    ) {
        const customData = calculateBufferCrewCustom(prevBufferCrew, data);

        if (customData.workAfter >= 0 && customData.workBefore >= 0) {
            return { ...data, ...customData };
        } else {
            return { ...data, workBegin: customData.workBegin };
        }
    } else if (override) {
        data.isBlock = false;
    }

    return data;
}

function recalculateBufferCrew(prevBufferCrew, legInfo, override, props) {
    const { crewType } = props || {};

    // Расчет рабочего времени пассажира
    if (crewType === CrewTypes.PCT_PAXSTAFF) {
        return calculatePassengerWorkTime(legInfo, props);
    }

    return calculateCrewWorkTime(prevBufferCrew, legInfo, override, props);
}

function getFlightTaskNextLeg(legs, legIds, legId) {
    const nextLegNumber = legIds.indexOf(legId) + 1;
    return legIds.length > nextLegNumber && legs[legIds[nextLegNumber]];
}

function getPersonnelFlightInfo(personnelFlightsTask, persId, isFirstLeg, isLastLeg, defaultCabinDuty) {
    let previousTaskWorkEnd = null;
    let isExistNextWorkTask = false;
    const { previousFlights, nextFlights } = personnelFlightsTask.reduce(
        (res, item) => {
            if (item.personnelID === persId) {
                if (item.isTaskBefore) {
                    res.previousFlights.push(item);
                } else {
                    res.nextFlights.push(item);
                }
            }

            return res;
        },
        { previousFlights: [], nextFlights: [] }
    );

    if (isFirstLeg) {
        previousTaskWorkEnd = previousFlights.length > 0 ? previousFlights[previousFlights.length - 1].dateWorkEnd : null;
    }

    if (isLastLeg && defaultCabinDuty) {
        if (nextFlights.length > 0 && nextFlights[0].splittedShift !== true) {
            isExistNextWorkTask = true;
        }
    }

    return {
        previousTaskWorkEnd,
        isExistNextWorkTask,
    };
}

function getSplittedShiftNextLeg(nextLeg, persId) {
    let isSplittedShift = false;
    if (nextLeg && nextLeg.crew) {
        const id = Object.keys(nextLeg.crew).find((key) => {
            return get(nextLeg, `crew[${key}].id`) === persId;
        });

        const { splittedShift } = get(nextLeg, `crew[${id}]`, {});

        if (splittedShift === true) {
            isSplittedShift = true;
        }
    }

    return isSplittedShift;
}

function generateCrewInfo(override) {
    return (dispatch, getState) => {
        const state = getState();
        const defaultCabinDuty = get(state, 'routesDeclaration.options.defaultCabinDuty', false);
        const sendedLegs = getSendedDataPreview(state);
        const usersEdgeLeg = getUsersEdgeLeg(state);
        const legs = getFlightTaskLegsList(state);
        const crewList = state.crew || {};
        const userId = get(state.screen, 'currentUser.id');
        const personnelFlightsTask = getPersonnelFlightsTask(state);
        const legIds = getFlightTaskLegIds(state);
        let prevLegBufferCrew = {};
        const updateForSendedData =
            sendedLegs &&
            sendedLegs.map((leg) => {
                const std = get(legs[leg.id], 'std');
                const etd = get(legs[leg.id], 'etd');
                const bufferCrew = {};
                const pilotFlightTakeOff = get(state, `sendedData[${leg.id}].takeOff.informationForm.pilotFlight`);
                const pilotFlightLanding = get(state, `sendedData[${leg.id}].landing.informationForm.pilotFlight`);
                const legInfo = getLegWorkInfo(state.sendedData, leg.id);
                const nextLeg = getFlightTaskNextLeg(legs, legIds, leg.id);
                leg.crew &&
                    Object.keys(leg.crew).forEach((crewId) => {
                        const legCrew = leg.crew[crewId] || {};
                        const { id: persId, crewType, percent, before, after, splittedShift } = legCrew;
                        const isFirstLeg = get(usersEdgeLeg[persId], 'start.id') === leg.id;
                        const isLastLeg = get(usersEdgeLeg[persId], 'end.id') === leg.id;
                        const { previousTaskWorkEnd, isExistNextWorkTask } = getPersonnelFlightInfo(
                            personnelFlightsTask,
                            persId,
                            isFirstLeg,
                            isLastLeg,
                            defaultCabinDuty
                        );
                        const { workEnd: previousLegWorkEnd } = getBufferCrewValues(prevLegBufferCrew[persId] || {});
                        const isSplittedShiftNextLeg =
                            crewType === CrewTypes.PCT_SALONSTAFF && defaultCabinDuty ? getSplittedShiftNextLeg(nextLeg, persId) : false;
                        const workProps = {
                            crewType,
                            percent,
                            before,
                            after,
                            splittedShift,
                            isFirstLeg,
                            isLastLeg,
                            defaultCabinDuty,
                            std,
                            etd,
                            previousLegWorkEnd,
                            previousTaskWorkEnd,
                            isSplittedShiftNextLeg,
                            isExistNextWorkTask,
                        };
                        const previousBufferCrew = { ...leg.bufferCrew[persId] } || {};
                        const crew = crewList[persId];

                        if (crew && legInfo.startWorkTime && legInfo.workTime) {
                            const newData = recalculateBufferCrew(previousBufferCrew, legInfo, override, workProps);

                            bufferCrew[persId] = {
                                ...previousBufferCrew,
                                ...legCrew,
                                id: +crewId,
                                takeoff: pilotFlightTakeOff === persId,
                                landing: pilotFlightLanding === persId,
                                comment: previousBufferCrew.comment,
                                persId,
                                crewType,
                                userId,
                                ...newData,
                            };
                        }
                    });
                prevLegBufferCrew = bufferCrew;
                return {
                    id: leg.id,
                    hasChanges: leg.hasChanges,
                    bufferCrew,
                };
            }, {});
        return dispatch({
            type: GENERATE_CREW_INFO,
            updateForSendedData,
        });
    };
}

function changeCrewInfo(persId, legId, data) {
    return {
        type: CHANGE_CREW_INFO,
        persId,
        legId,
        data,
    };
}

export { generateCrewInfo, changeCrewInfo, calculateBufferCrewCustom, GENERATE_CREW_INFO, CHANGE_CREW_INFO };
