import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
    input: {
        textAlign: 'center',
        '&:before': {
            borderColor: 'red',
        },
    },
    headerCell: {
        flex: '1 1 25%',
        padding: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
        alignSelf: 'baseline',
        boxSizing: 'border-box',
        textAlign: 'center',
        lineHeight: '22px',
        color: theme.palette.primary.main,
    },
    field: {
        flex: '1 1 25%',
        alignSelf: 'baseline',
        boxSizing: 'border-box',
        textAlign: 'center',
        lineHeight: '22px',
        color: theme.palette.primary.main,
    },
    row: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        boxSizing: 'border-box',
        padding: '0 4px',
    },
    wrapper: {
        flex: '1 1 45%',
        paddingBottom: theme.spacing(1),
        ['@media (max-width:1000px)']: {
            flexBasis: '100%',
        },
    },
}));
