import React from 'react';
import { useDispatch } from 'react-redux';
import { default as NotificationsIcon } from '@material-ui/icons/Notifications';
import { default as MyLocationIcon } from '@material-ui/icons/MyLocation';

import Filter from '../../../icons/image/Filter';
import Add from '../../../icons/image/Add';
import Star from '../../../icons/image/Star';
import { Badge } from '../../../components/material-ui/Badge';
import { Paper } from '../../../components/material-ui/Paper';
import { Button } from '../../../components/material-ui/Button';
import { useStyles } from './useStyles';
import { toggleViewNotice } from '../../../actions/notices';

const NoticeFooterPanel = ({
    iconColor,
    favorites,
    nearest,
    recent,
    isRecentFiltered,
    isFavoritesFiltered,
    isNearestFiltered,
    onAddAirport,
    onFilter,
    isFilterSet,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const handleToggleFavorites = React.useCallback(() => {
        dispatch(toggleViewNotice('isFavoritesFiltered'));
    }, []);

    const handleToggleRecents = React.useCallback(() => {
        dispatch(toggleViewNotice('isRecentFiltered'));
    }, []);

    const handleToggleNearest = React.useCallback(() => {
        dispatch(toggleViewNotice('isNearestFiltered'));
    }, []);

    return (
        <Paper className="notice-footer" elevation={2}>
            <Button
                onClick={onAddAirport}
                classes={{
                    text: classes.text,
                    label: classes.label,
                }}
                startIcon={<Add width="20" height="20" color={iconColor} />}
            >
                Add
            </Button>
            <Button
                onClick={onFilter}
                color={isFilterSet ? 'primary' : 'default'}
                classes={{
                    text: classes.text,
                    label: classes.label,
                }}
                startIcon={<Filter width="24" height="24" color={iconColor} />}
            >
                Filter
            </Button>
            <Button
                color={isNearestFiltered ? 'primary' : 'default'}
                onClick={handleToggleNearest}
                classes={{
                    text: classes.text,
                    label: classes.label,
                    startIcon: classes.startIcon,
                }}
                startIcon={
                    <Badge badgeContent={nearest} color="secondary" max={999} classes={{ anchorOriginTopRightRectangle: classes.badge }}>
                        <MyLocationIcon />
                    </Badge>
                }
            >
                Nearest to route
            </Button>
            <Button
                color={isFavoritesFiltered ? 'primary' : 'default'}
                onClick={handleToggleFavorites}
                classes={{
                    text: classes.text,
                    label: classes.label,
                    startIcon: classes.startIcon,
                }}
                startIcon={
                    <Badge
                        badgeContent={favorites}
                        color="secondary"
                        max={999}
                        classes={{ anchorOriginTopRightRectangular: classes.badge }}
                    >
                        <Star width="24" height="24" />
                    </Badge>
                }
            >
                Favorites
            </Button>
            <Button
                color={isRecentFiltered ? 'primary' : 'default'}
                onClick={handleToggleRecents}
                classes={{
                    text: classes.text,
                    label: classes.label,
                    startIcon: classes.startIcon,
                }}
                startIcon={
                    <Badge badgeContent={recent} color="secondary" max={999} classes={{ anchorOriginTopRightRectangular: classes.badge }}>
                        <NotificationsIcon />
                    </Badge>
                }
            >
                New
            </Button>
        </Paper>
    );
};

NoticeFooterPanel.defaultProps = {
    isRecentlyFiltered: false,
    isFavoritesFiltered: false,
};

const NoticeFooterPanelMemoized = React.memo(NoticeFooterPanel);

export { NoticeFooterPanelMemoized as NoticeFooterPanel };
