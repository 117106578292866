import React from 'react';

import BaseIcon from '../BaseIcon';

const Down = (props) => (
    <BaseIcon {...props} viewBox="0 0 476.213 476.213">
        <polygon
            points="287.5,384.394 253.107,418.787 253.107,0 223.107,0 223.107,418.787 188.713,384.394 167.5,405.606
            238.107,476.213 308.713,405.606 "
        />
    </BaseIcon>
);

export default Down;
