import { makeStyles } from '@material-ui/core/styles';
import { getMuiThemeStyle } from '../../../service/getMuiThemeStyle';

const useStyles = makeStyles((theme) => {
    const styles = getMuiThemeStyle(theme.palette.type);
    const { warningColor, borderColor, textColor } = styles.palette;

    return {
        icon: {
            top: '0px',
        },
        listIcon: {
            minWidth: '40px',
        },
        iconCancelled: {
            top: '0px',
            fill: warningColor,
        },
        listItemWithBorder: {
            borderTop: `1px solid ${borderColor}`,
            fontSize: '18px',
            lineHeight: '18px',
        },
        cancelledText: {
            color: warningColor,

            '& span': {
                fontSize: '18px',
            },
        },
        text: {
            color: textColor,

            '& span': {
                fontSize: '18px',
            },
        },
    };
});

export { useStyles };
