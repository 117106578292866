import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(1),
    },
    controls: {
        padding: theme.spacing(0, 2, 2),
    },
    formControl: {
        margin: theme.spacing(1),
        flexBasis: '100%',
    },
    title: {
        padding: theme.spacing(2, 2, 0),
    },
}));
