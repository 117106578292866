import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    const {
        textField,
        listItem: { disabledColor, removedColor },
        reports: { editColor },
    } = theme;

    return {
        listItemDisabled: {
            backgroundColor: disabledColor,
        },
        listItemRemoved: {
            backgroundColor: removedColor,
        },
        listItemEdited: {
            backgroundColor: editColor,
        },
        report: {
            fontSize: textField.textFontSize,
            fontWeight: 600,
            flexGrow: 1,
        },
        reportWrapper: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            paddingRight: theme.spacing(7),
        },
        secondaryText: {
            fontSize: textField.textFontSize,
        },
        status: {
            fontSize: 14,
            flexGrow: 0,
            flexShrink: 0,
            order: 1,
        },
    };
});
