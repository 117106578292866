import React from 'react';

import { IconButton } from '../../material-ui/IconButton';
import { Menu, MenuItem } from '../../material-ui/Menu';
import { Typography } from '../../material-ui/Typography';
import { useAnchorEl } from '../../hooks/useAnchorEl';

const PanelIconMenu = (props) => {
    const { iconMenuImg, iconMenuItems } = props;
    const [anchorEl, handleOpenMenu, handleClose] = useAnchorEl();
    const handleClickItem = React.useCallback(
        (callback) => () => {
            callback();
            handleClose();
        },
        []
    );

    return (
        <div style={{ order: 10 }}>
            <IconButton onClick={handleOpenMenu} style={{ padding: '8px' }}>
                {iconMenuImg}
            </IconButton>
            <Menu
                id="paper-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                {iconMenuItems.map((item, pos) => {
                    return (
                        <MenuItem key={item.name + pos} onClick={handleClickItem(item.callback)} style={{ fontSize: '18px' }}>
                            <Typography>{item.name}</Typography>
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};

const PanelIconMenuMemoized = React.memo(PanelIconMenu);

export { PanelIconMenuMemoized as PanelIconMenu };
