import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { ErrorBoundary } from '../../ErrorBoundary/ErrorBoundary';
import { wizardSteps } from '../tools';

const WizardContent = () => {
    const wizardStep = useSelector((state) => get(state, 'wizard.wizardStep', 0));

    return <ErrorBoundary>{get(wizardSteps[wizardStep], 'content', '')}</ErrorBoundary>;
};

const WizardContentMemoized = React.memo(WizardContent);

export { WizardContentMemoized as WizardContent };
