import moment from 'moment';

export const sortObjByField = (fieldName, transform = (item) => item) => {
    return (obj1, obj2) => {
        if (!obj1 || !obj2) {
            throw TypeError('sortObjByField cannot resolve undefined object');
        }

        return transform(obj1[fieldName]) > transform(obj2[fieldName]) ? 1 : -1;
    };
};

export const documentsSort = (doc1, doc2) => {
    if (!doc1 || !doc2) {
        throw TypeError('documentsSort cannot resolve undefined object');
    }

    // сортируем по аэропортам и типам документов
    if (doc1.airport > doc2.airport || (doc1.airport == doc2.airport && doc1.docType > doc2.docType)) return 1;

    return -1;
};

export const sortLibrary = (a, b) => {
    if (a.lvl > b.lvl) {
        return 1;
    } else if (a.lvl < b.lvl) {
        return -1;
    }

    if (a.type > b.type) {
        return 1;
    } else if (a.type < b.type) {
        return -1;
    }

    if (a.prior > b.prior) {
        return 1;
    } else if (a.prior < b.prior) {
        return -1;
    }

    if (a.name > b.name) {
        return 1;
    } else {
        return -1;
    }
};

export const sortPassengerTemplate = (passengerTemplates) => {
    const template = Object.assign({}, passengerTemplates);
    return (key1, key2) => {
        const item1 = template[key1];
        const item2 = template[key2];

        if (item1.cls.name < item2.cls.name) {
            return 1;
        }

        if (item1.cls.name > item2.cls.name) {
            return -1;
        }

        if (item1.type.name > item2.type.name) {
            return 1;
        }

        if (item1.type.name < item2.type.name) {
            return -1;
        }
    };
};

export const sortWithParseInt = (val1, val2) => {
    const a = parseInt(val1);
    const b = parseInt(val2);
    if (a.length === b.length) {
        return a > b ? 1 : -1;
    }
    return a.length > b.length ? 1 : -1;
};

export const sortPassengerSeats = (val1, val2) => {
    const { row: row1, name: name1, type: type1 } = val1;
    const { row: row2, name: name2, type: type2 } = val2;
    if (row1 === row2) {
        if (name1 === name2) {
            return type1 > type2 ? 1 : -1;
        }

        return name1 > name2 ? 1 : -1;
    }
    return row1 > row2 ? 1 : -1;
};

export const sortCrew = (crew1, crew2) => {
    if (crew1.type === crew2.type) {
        return crew1.orderNumber > crew2.orderNumber ? 1 : -1;
    }
    return crew1.type > crew2.type ? 1 : -1;
};

export const sortFlightByAirportNumber = (flight1, flight2) => {
    return flight1.airportNumber > flight2.airportNumber ? 1 : -1;
};

export const sortSummaryPageCrew = (crew1, crew2) => {
    if (crew1.total.crewType !== crew2.total.crewType) {
        return crew1.total.crewType > crew2.total.crewType ? 1 : -1;
    }

    if (crew1.total.orderNumber !== crew2.total.orderNumber) {
        return crew1.total.orderNumber > crew2.total.orderNumber ? 1 : -1;
    }

    return crew1.fullName > crew2.fullName ? 1 : -1;
};

export const sortByEtd = (a, b) => {
    return moment(a.etd) > moment(b.etd) ? 1 : -1;
};

export const sortCrewByName = (crew1, crew2) => {
    return crew1.fullNameEng > crew2.fullNameEng ? 1 : -1;
};

export function reportSort(a, b) {
    if (moment(a.flightDate) < moment(b.flightDate)) return 1;
    else if (moment(a.flightDate) === moment(b.flightDate) && a.flightName < b.flightName) return 1;
    else if (
        moment(a.flightDate) === moment(b.flightDate) &&
        a.flightName === b.flightName &&
        moment(a.createdDate) < moment(b.createdDate)
    )
        return 1;

    return -1;
}

export const sortLoadFields = ({ name: name1, priority: priority1 = '' }, { name: name2, priority: priority2 = '' }) => {
    if (priority1 !== priority2) {
        return priority1 > priority2 ? 1 : -1;
    }

    return name1 > name2 ? 1 : -1;
};

export const sortByTwoField = (field1, field2) => (a, b) => {
    if (!a[field1] && !b[field1]) {
        return a[field2] - b[field2];
    }
    if (a[field1] && !b[field1]) {
        return 1;
    }
    if (!a[field1] && b[field1]) {
        return -1;
    }

    return a[field1] - b[field1] || a[field2] - b[field2];
};

export const Order = {
    ASC: 0,
    DESC: 1,
};

export const sortNotices = (field1, field2) => (a, b) => {
    const { name: name1, order: order1 } = field1;
    const { name: name2, order: order2 } = field2;
    const valueA1 = a[name1];
    const valueA2 = a[name2];
    const valueB1 = b[name1];
    const valueB2 = b[name2];

    if (valueA1 === valueB1) {
        if (order2 === Order.ASC) {
            return moment(valueA2).isBefore(valueB2) ? -1 : moment(valueA2).isBefore(valueB2) ? 1 : 0;
        }
        if (order2 === Order.DESC) {
            return moment(valueA2).isAfter(valueB2) ? -1 : moment(valueA2).isAfter(valueB2) ? 1 : 0;
        }
    }

    if (order1 === Order.ASC) {
        return valueA1 < valueB1 ? -1 : 1;
    }
    if (order1 === Order.DESC) {
        return valueA1 < valueB1 ? 1 : -1;
    }
};

export const sortTelexList = (a, b) => {
    if (a.telexID === b.telexID) {
        return a.recordType < b.recordType ? -1 : 1;
    }

    return a.telexCode < b.telexCode ? -1 : a.telexCode > b.telexCode ? 1 : 0;
};
