import { makeStyles } from '@material-ui/styles';

import { darkFullWhite } from '../../../ThemeProvider';

export const useStyles = makeStyles((theme) => ({
    field: ({ isWhiteColor }) => ({
        marginTop: theme.spacing(2),
        ...(isWhiteColor
            ? {
                  color: darkFullWhite,
              }
            : {}),

        '& input:-webkit-autofill': {
            '-webkit-box-shadow': 'none',
            '-webkit-text-fill-color': theme.palette.text.primary,
        },
    }),
    icon: ({ isWhiteColor }) =>
        isWhiteColor
            ? {
                  color: darkFullWhite,
              }
            : {},
    sendingInfo: ({ isWhiteColor }) => ({
        display: 'inline-flex',
        ...(isWhiteColor
            ? {
                  color: darkFullWhite,
              }
            : {}),
    }),
}));
