import React from 'react';

import BaseIcon from '../BaseIcon';

const AirModeOn = (props) => (
    <BaseIcon {...props} viewBox="0 0 510 510">
        <path
            d="M497.25,357v-51l-204-127.5V38.25C293.25,17.85,275.4,0,255,0c-20.4,0-38.25,17.85-38.25,38.25V178.5L12.75,306v51
			l204-63.75V433.5l-51,38.25V510L255,484.5l89.25,25.5v-38.25l-51-38.25V293.25L497.25,357z"
        />
    </BaseIcon>
);

export default AirModeOn;
