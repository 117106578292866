import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { get } from 'lodash';
import * as moment from 'moment';

import { getFlightTaskLegIds, getUserLegs } from '../screen';
import { CrewTypes } from '../../service/constants';
import { getAllowedDistance } from '../routesDeclaration';
import { getPointsDistance } from '../../service/NavPointsUtils';
import { getCurrentSendedLeg } from '../sended-data';
import { sortByTwoField } from '../../service/sort';

export const airportGroups = {
    0: { name: 'Departure', colorName: 'departure' },
    1: { name: 'Alternates departure', colorName: 'altDeparture' },
    2: { name: 'En-Route', colorName: 'enroute' },
    3: { name: 'Landing', colorName: 'landing' },
    4: { name: 'Alternates arrival', colorName: 'altLanding' },
    5: { name: 'FIR', colorName: 'fir' },
    6: { name: 'Additions', colorName: 'additional' },
};

const getNoticeGroup = createSelector(
    (state) => get(state, 'routesDeclaration.notice'),
    (declaration) => {
        // Если в конфиге компании нет правил, то выводим просто на основе airportGroups.
        if (!declaration) {
            return Object.keys(airportGroups).map((key) => ({
                ids: [+key],
                ...airportGroups[key],
            }));
        }

        // Если в конфиге компании правила есть, то выводим на основе правил.
        // Цвет берем по первым элеметам.
        // Сортировка по порядку в конфиге
        return declaration.reduce((res, item) => {
            const firstItem = item.ids && airportGroups[item.ids[0]];

            if (firstItem) {
                res.push({
                    ...item,
                    ids: item.ids.filter((id) => Object.keys(airportGroups).indexOf(`${id}`) !== -1),
                    ...(firstItem && {
                        colorName: firstItem.colorName,
                    }),
                });
            }
            return res;
        }, []);
    }
);

const checkNearest = (points, noticePosition, allowedDistance, airportCoord) => {
    const { lat: noticeLat, long: noticeLong, radius } = noticePosition;
    const { airportLat, airportLong } = airportCoord;

    // ищем расстояние между аэропортом и НОТИСом
    const airportDistance = getPointsDistance(airportLat, airportLong, noticeLat, noticeLong);
    // проверяем попадает ли НОТИС в зону обнаружения
    const isNearestPoint = airportDistance <= allowedDistance + radius;
    // если попадает, то показываем НОТИС
    if (isNearestPoint) {
        return true;
    }

    // ищем хотя бы одно совпадение для отображения НОТАМ
    const nearestPointAtNotice =
        points &&
        points
            .sort(sortByTwoField('routeNumber', 'pointNumber')) //
            .findIndex((point, i) => {
                let isNearestPoint = true;
                const prevPoint = points[i - 1];
                if (i > 0 && prevPoint.routeNumber === point.routeNumber) {
                    // координаты предыдущей точки
                    const ax = prevPoint.lat;
                    const ay = prevPoint.long;
                    // координаты текущей точки
                    const bx = point.lat;
                    const by = point.long;
                    // расстояние между предыдущей и текущей точками
                    const ba = getPointsDistance(bx, by, ax, ay);
                    // шаг проверки между точками
                    const step = allowedDistance;

                    // количество проверок между предыдущей и текущей точками
                    let stepCount = !step ? step : Math.floor(ba / step);
                    while (stepCount) {
                        const dx = bx - ax;
                        const dy = by - ay;
                        // координаты промежуточной точки между известными точками маршрута с учетом шага проверки
                        const stepLat = ax + dx * ((stepCount * step) / ba);
                        const stepLong = ay + dy * ((stepCount * step) / ba);

                        // расстояние между промежуточной точкой и НОТАМ
                        const stepPointDistance = getPointsDistance(stepLat, stepLong, noticeLat, noticeLong);
                        // отображать, если расстояние между промежуточной точкой и НОТАМ меньше или равно сумме
                        // минимальной дальности обнаружения точки и радиуса НОТАМ
                        isNearestPoint = stepPointDistance <= allowedDistance + radius;

                        if (isNearestPoint) {
                            return true;
                        }

                        stepCount--;
                    }
                }
                const { lat: pointLat, long: pointLong } = point;
                // расстояние между точкой маршрута и НОТАМ
                const pointDistance = getPointsDistance(pointLat, pointLong, noticeLat, noticeLong);
                // отображать, если расстояние между точкой маршрута и НОТАМ меньше или равно сумме
                // минимальной дальности обнаружения точки и радиуса НОТАМ
                isNearestPoint = pointDistance <= allowedDistance + radius;

                return isNearestPoint;
            });

    return nearestPointAtNotice !== -1;
};

const checkNoticeFilter = (notice, filter, isNearest) => {
    const {
        number,
        text,
        airport,
        isRecentFiltered = false,
        isFavoritesFiltered = false,
        isNearestFiltered = false,
        scope = -1,
    } = filter || {};
    const { series, fir, num, content, year, isNew = false, isFavorite = false, end } = notice;

    if (isNearestFiltered && !isNearest) {
        return true;
    }

    if ((airport && airport !== fir) || (text && content.toLowerCase().indexOf(text.toLowerCase()) === -1)) {
        return true;
    }

    if ((scope !== -1 && (scope === 0 && notice.scope !== scope || scope > 0 && (notice.scope & scope) !== scope)) || (isRecentFiltered && !isNew) || (isFavoritesFiltered && !isFavorite)) {
        return true;
    }
    if (number) {
        const [, matchedSeries, matchedNumber, , matchedYear] = number.match(/([A-Z])?(\d{0,4})(\/(\d{2}))?/);
        const result =
            (matchedSeries && matchedSeries !== series) ||
            (matchedNumber && +matchedNumber !== num) ||
            (matchedYear && +`20${matchedYear}` !== year);

        return result;
    }
    if ((isRecentFiltered && !isNew) || (isFavoritesFiltered && !isFavorite)) {
        return true;
    }
    if (end && moment.utc(end) < moment.utc()) {
        return true;
    }

    return false;
};

const getAirportNotices = (airport, notices, airportNotices = {}, filter, points, allowedDistance) => {
    return airport.data.reduce(
        (noticeResult, noticeId) => {
            const notice = notices[noticeId];
            const isNearest =
                Boolean(notice) &&
                checkNearest(points, { lat: notice.lat, long: notice.long, radius: notice.r }, allowedDistance, {
                    airportLat: airport.latitude,
                    airportLong: airport.longitude,
                });
            const isHidden = !notice || checkNoticeFilter(notice, filter, isNearest);

            if (notice && !airportNotices[noticeId] && !isHidden) {
                noticeResult.newNotices[noticeId] = notice;
                if (notice.isFavorite) {
                    noticeResult.favorites++;
                }

                if (notice.isNew) {
                    noticeResult.recent++;
                }

                if (isNearest) {
                    noticeResult.nearest++;
                }
            }

            return noticeResult;
        },
        { newNotices: {}, favorites: 0, recent: 0, nearest: 0 }
    );
};

const getNoticeAirportByTask = createSelector(
    getFlightTaskLegIds,
    getUserLegs,
    (state) => get(state, 'notices'),
    getNoticeGroup,
    getAllowedDistance,
    getCurrentSendedLeg,
    (legIds = [], userLegs, { routesAirports, airports, notices, filter }, groups, allowedDistance, { points }) => {
        const airportIDs = [];
        const data = groups.reduce(
            (res, { name, colorName, orderNumber, ids = [] }, index) => {
                if (!res.routesAirports[index]) {
                    res.routesAirports.push({
                        name,
                        colorName,
                        orderNumber,
                        airportList: {},
                    });
                }

                legIds.forEach((legId) => {
                    if (userLegs[legId] === CrewTypes.PCT_CABINSTAFF) {
                        // ONLY FOR PILOT
                        const legsAirports = routesAirports[legId] || {};

                        ids.forEach((type) => {
                            Object.keys(legsAirports).forEach((airportId) => {
                                const airport = {
                                    ...airports[airportId],
                                    latitude: legsAirports[airportId].latitude,
                                    longitude: legsAirports[airportId].longitude,
                                };
                                const isAirportInGroup = legsAirports[airportId] && legsAirports[airportId].type === type && airport;
                                if (isAirportInGroup && airport.data && airport.data.length > 0) {
                                    const airportData = res.routesAirports[index].airportList[airportId];
                                    const {
                                        newNotices: airportNotices,
                                        favorites,
                                        recent,
                                        nearest,
                                    } = getAirportNotices(
                                        airport,
                                        notices,
                                        airportData && airportData.airportNotices,
                                        filter,
                                        points,
                                        allowedDistance
                                    );

                                    if (
                                        Object.keys(airportNotices).length === 0 ||
                                        airportIDs.indexOf(airportId) !== -1 ||
                                        Object.keys(airportNotices).length === 0
                                    ) {
                                        // если не содержит НОТАМ, то не показываем попросту
                                        return;
                                    }

                                    if (!airportData) {
                                        res.routesAirports[index].airportList[airportId] = {
                                            ...airports[airportId],
                                            legs: { [legId]: true },
                                            airportNotices,
                                        };
                                        airportIDs.push(airportId);
                                    } else {
                                        res.routesAirports[index].airportList[airportId] = {
                                            ...res.routesAirports[index].airportList[airportId],
                                            ...airportNotices,
                                        };
                                        res.routesAirports[index].airportList[airportId].legs = {
                                            ...res.routesAirports[index].airportList[airportId].legs,
                                            [legId]: true,
                                        };
                                    }
                                    res.noticeIds = res.noticeIds.concat(Object.keys(airportNotices).map((i) => +i));
                                    res.favorites += favorites;
                                    res.recent += recent;
                                    res.nearest += nearest;
                                }
                            });
                        }, []);
                    }

                    return res;
                }, {});

                return res;
            },
            { routesAirports: [], favorites: 0, recent: 0, nearest: 0, noticeIds: [] }
        );

        return data;
    }
);

const useNoticeAirportByTask = () => useSelector((state) => getNoticeAirportByTask(state));

const useNoticeFilter = () => useSelector((state) => get(state, 'notices.filter', {}));

export {
    getNoticeAirportByTask, //
    useNoticeAirportByTask,
    useNoticeFilter,
};
