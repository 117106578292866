import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    comment: {
        width: '100%',
        padding: theme.spacing(1),
        background: theme.palette.type === 'light' ? '#ffe9e9' : '#292d44',

        '& .MuiInput-root': {
            height: 'auto',
        },

        '& .MuiInputLabel-formControl': {
            top: theme.spacing(1),
            left: theme.spacing(1),
        },

        ['@media (max-width: 990px)']: {
            margin: `auto auto ${theme.spacing(1)}px`,
        },
    },
    commentLabel: {
        width: '125%',
        top: theme.spacing(1),
        left: theme.spacing(1),
    },
    field: {
        flex: '1 1 22%',
        margin: '16px 6px 8px',
        minWidth: 145,

        ['@media (max-width: 990px)']: {
            margin: `auto auto ${theme.spacing(1)}px`,
            flex: '1 1 50%',
        },
    },
    inner: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: 4,
    },
    input: {
        height: 'auto',
    },
    hidden: {
        visibility: 'hidden',

        ['@media (max-width: 990px)']: {
            display: 'none',
        },
    },
    requiredStyle: {
        color: theme.palette.warningColor,
    },
    panel: {
        flex: '1 1 100%',
    },
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        paddingBottom: theme.spacing(1),
    },
}));

export { useStyles };
