import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
    return {
        panel: {
            flex: '1 1 45%',
            ['@media (max-width: 900px)']: {
                flexBasis: '100%',
            },
        },
    };
});

export { useStyles };
