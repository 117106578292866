import React from 'react';
import { useDispatch } from 'react-redux';

import { Panel } from '../Panel/Panel.jsx';
import { useStyles } from '../TakeOffPage/useStyles';
import { onChangeTime } from '../../actions/landing';
import { LandingTime } from './LandingTime/LandingTime';
import { StopEngineTime } from './StopEngineTime/StopEngineTime';
import { LandingPalette } from './LandingPalette/LandingPalette';
import { CrewPanel } from './CrewPanel/CrewPanel';

const LandingPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleChangeTime = React.useCallback((name, value) => {
        dispatch(onChangeTime(name, value));
    }, []);

    return (
        <div className={classes.container}>
            <div className={classes.panelWrapper}>
                <LandingTime onChangeTime={handleChangeTime} className={classes.panel} />
                <CrewPanel />
                <StopEngineTime onChangeTime={handleChangeTime} className={classes.panel} />
            </div>
            <Panel title="Landing information" className={classes.form}>
                <LandingPalette className={classes.form} />
            </Panel>
        </div>
    );
};

const LandingPageMemoized = React.memo(LandingPage);

export { LandingPageMemoized as LandingPage };
