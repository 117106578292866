import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    actions: {
        padding: theme.spacing(1, 2),

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1, 0.5),
        },
    },
    comment: {
        margin: theme.spacing(0.5, 0),
        flexBasis: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        minWidth: 300,
    },
    date: {
        margin: theme.spacing(0.5, 0),
        flexBasis: '66%',
    },
    input: {
        height: '1.4em',
    },
    formControl: {
        margin: theme.spacing(0.5, 0),
        flexBasis: '18%',
    },
    paper: {
        maxWidth: 680,
    },
    picker: {
        margin: theme.spacing(0.5, 0),
        flexBasis: '46%',
    },
    radio: {
        flex: '0 0 30%',
        marginRight: 0,
    },
    radioGroup: {
        flexBasis: '100%',
    },
    textarea: {
        '& .MuiInput-root': {
            height: 'auto',
        },
    },
    title: {
        padding: theme.spacing(2, 2, 1),

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2, 1, 1),
        },
    },
}));

export { useStyles };
