import React from 'react';

import { DialogActions } from '../../../components/material-ui/Dialog';
import { DialogControls } from '../../../components/DialogControls/DialogControls';

const SettingActions = (props) => {
    const { onSave, onClose } = props;

    const actionList = React.useMemo(
        () => [
            {
                label: 'CANCEL',
                primary: false,
                onClick: onClose,
            },
            {
                label: 'OK',
                primary: true,
                onClick: onSave,
            },
        ],
        [onSave]
    );

    return (
        <DialogActions>
            <DialogControls actions={actionList} />
        </DialogActions>
    );
};

const SettingActionsMemoized = React.memo(SettingActions);

export { SettingActionsMemoized as SettingActions };
