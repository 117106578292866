import { makeStyles } from '@material-ui/styles';
import { darkFullWhite } from '../../../service/getMuiThemeStyle';

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    closeBtn: {
        marginLeft: theme.spacing(1),
    },
    message: {
        padding: theme.spacing(2, 1),
        fontSize: '20px',
        fontWeight: 500,
    },
    paper: {
        height: 'calc(100vh -64px)',
        backgroundColor: theme.paper.backgroundColor,
        color: theme.palette.textColor,
    },
    title: {
        fontSize: '22px',
        color: darkFullWhite,
        marginRight: 'auto',
    },
    wrapper: {
        backgroundColor: theme.paper.backgroundColor,
        fontFamily: theme.fontFamily,
        color: theme.palette.textColor,
    },
}));

export { useStyles };
