import _ from 'lodash';
import { findNextPoint, getPointsDistance } from '../service/NavPointsUtils';
import { SelectionType } from '../modals/Setting/GpsSetting/GpsRouteSelectionType';
const TOGGLE_GPS = 'TOGGLE_GPS';
const CHANGE_GPS_POSITION = 'CHANGE_GPS_POSITION';
const GPS_ERROR = 'GPS_ERROR';
const SET_OFFSET = 'SET_OFFSET';

const MIN_ALLOWABLE_ACCURACY = 200;
const PERMISSIBLE_ERROR = 0.3;
export const MIN_ALLOWABLE_DISTANCE_TO_ACCEPT = 3;

function findCurrentPoint(
    points,
    gps,
    lastPosition,
    allowedDistance = MIN_ALLOWABLE_DISTANCE_TO_ACCEPT,
    selectionType = SelectionType.CURRENT
) {
    if (!gps.latitude || !gps.longitude || gps.accuracy > MIN_ALLOWABLE_ACCURACY) {
        return;
    }
    const offset = gps.offset || 0;
    const latitude = +gps.latitude;
    const longitude = +gps.longitude;
    // Если есть offset то ищем по гипотенузе
    const allowedDistanceWithOffset = Math.sqrt(Math.pow(offset, 2) + Math.pow(allowedDistance, 2));

    const point = points.reduce((res, point) => {
        if (point.pointNumber <= lastPosition) {
            return res;
        }
        const distance = getPointsDistance(latitude, longitude, point.lat, point.long);
        // if(distance < MIN_ALLOWABLE_DISTANCE_TO_ACCEPT) {
        //     console.log(distance, getCoordinates(latitude, 'lat'), getCoordinates(longitude, 'long'), getCoordinates(point.lat, 'lat'), getCoordinates(point.long, 'long'));
        // }

        if (distance < allowedDistanceWithOffset + PERMISSIBLE_ERROR) {
            res = point;
        }

        return res;
    }, {});

    if (selectionType === SelectionType.CURRENT) {
        return point;
    }

    return findNextPoint(points, point) || point;
}

function toggleGps() {
    return (dispatch, getState, { plugins }) => {
        const isActive = _.get(getState(), 'gps.isGpsOn', false);
        if (isActive) {
            plugins.geolocation.stop();
        } else {
            const onSuccessCallback = (position) => dispatch(changeGpsPosition(position));
            const onErrorCallback = () => dispatch(gpsError());
            plugins.geolocation.start(onSuccessCallback, onErrorCallback);
        }

        return dispatch({ type: TOGGLE_GPS, value: !isActive });
    };
}

function setOffset(offset) {
    return { type: SET_OFFSET, offset };
}

function changeGpsPosition(position) {
    return (dispatch, getState) => {
        const gpsPosition = { ...position };
        delete gpsPosition.timestamp;

        const {
            screen: { currentLeg },
            sendedData,
            routesDeclaration,
            gps: { offset },
        } = getState();
        const points = _.get(sendedData[currentLeg], 'points', []);
        const lastPosition = _.get(sendedData[currentLeg], 'currentPosition');
        const allowedDistance = _.get(routesDeclaration, 'allowedDistance');
        const selectionType = _.get(routesDeclaration, 'selectionType');
        const currentPosition = (findCurrentPoint(points, { ...position, offset }, lastPosition, allowedDistance, selectionType) || {})
            .pointNumber;
        return dispatch({
            type: CHANGE_GPS_POSITION,
            position: gpsPosition,
            currentPosition,
            legId: currentLeg,
            successTime: position.timestamp,
        });
    };
}

function gpsError() {
    return { type: GPS_ERROR };
}

function restoreGps() {
    return (dispatch, getState, { plugins }) => {
        const isActive = _.get(getState(), 'gps.isGpsOn', false);
        if (isActive) {
            const onSuccessCallback = (position) => dispatch(changeGpsPosition(position));
            const onErrorCallback = () => dispatch(gpsError());
            plugins.geolocation.start(onSuccessCallback, onErrorCallback);
        }
    };
}

export { TOGGLE_GPS, CHANGE_GPS_POSITION, GPS_ERROR, SET_OFFSET, toggleGps, changeGpsPosition, restoreGps, gpsError, setOffset };
