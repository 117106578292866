import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

import { IconButton } from '../material-ui/IconButton';
import Lamp from '../../icons/image/Lamp';
import { toggleTheme } from '../../actions/screen';

const ThemeToggler = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const darkTheme = useSelector((state) => get(state, 'screen.darkTheme', false));
    const handleClick = React.useCallback(() => dispatch(toggleTheme()), []);

    return (
        <IconButton onClick={handleClick}>
            <Lamp className={classes.icon} isDark={!darkTheme} />
        </IconButton>
    );
};

const useStyles = makeStyles((theme) => ({
    icon: {
        fill: theme.icon.staticWhite,
    },
}));

const ThemeTogglerMemoized = React.memo(ThemeToggler);

export { ThemeTogglerMemoized as ThemeToggler };
