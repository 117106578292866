import { makeStyles } from '@material-ui/styles';

import { getMuiThemeStyle } from '../../service/getMuiThemeStyle';

const DRAWER_WIDTH = 350;

const useStyles = makeStyles((theme) => {
    const styles = getMuiThemeStyle(theme.palette.type);
    const { borderColor } = styles.palette;

    return {
        drawerClass: {
            width: DRAWER_WIDTH,
            flexShrink: 0,
            zIndex: '999 !important',
            whiteSpace: 'nowrap',
        },
        drawerOpenClass: {
            width: DRAWER_WIDTH,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerCloseClass: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            // display: 'none',
            // [theme.breakpoints.down('sm')]: {
            //     display: 'none',
            //     width: DRAWER_WIDTH,
            // },
            // [theme.breakpoints.up('sm')]: {
            //     display: 'block',
            //     width: theme.spacing(7) + 1,
            // },
        },
        drawerPaper: {
            overflowX: 'hidden',
        },
        icon: {
            top: '0px',
        },
        innerDivStyle: {
            marginLeft: '0',
            borderLeftWidth: '0px',
            borderLeftStyle: 'solid',
            borderLeftColor: 'transparent',
            userSelect: 'none',
        },
        flightTasks: {
            '& span': {
                fontSize: '18px',
                fontWeight: 'bold',
            },
        },
        listItemText: {
            '& span': {
                fontSize: '18px',
            },
        },
        listItemWithBorder: {
            borderTop: `1px solid ${borderColor}`,
            fontSize: '18px',
            lineHeight: '18px',
            userSelect: 'none',
        },
        listItemWithBorderBottom: {
            borderBottom: `3px solid ${borderColor}`,
            fontWeight: 'bold',
            fontSize: '18px',
            lineHeight: '18px',
            userSelect: 'none',
        },
        toolbar: theme.mixins.toolbar,
        simple: {
            fontSize: '18px',
            lineHeight: '18px',
            userSelect: 'none',
        },
        listIcon: {
            minWidth: '40px',
        },
    };
});

export { useStyles };
