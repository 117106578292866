import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
    },
    controls: {
        padding: theme.spacing(0, 2, 2),
    },
    title: {
        padding: theme.spacing(2, 2, 0),
    },
}));
