import { VALIDATION_EMPTY_FIELD } from '../hooks/useForm/validate';

export const getTimeFromInt = (value) => {
    value = Math.round(value / 60) * 60;
    let mm = (value / 60) % 60;
    let hh = ((value / 60 - mm) / 60) % 100;

    return `${hh < 10 ? '0' + hh : hh}:${mm < 10 ? '0' + mm : mm}`;
};

export const getIntFromTime = (value) => {
    let [hh = '00', mm = '00'] = value.split(':');

    return parseInt(hh) * 3600 + parseInt(mm) * 60;
};

export const checkValid = (val) => {
    const regexp = /^\d{0,2}?\:?\d{0,2}$/; //eslint-disable-line
    const [hoursStr, minutesStr] = val.split(':');
    let valArr = [];

    if (!regexp.test(val)) {
        return false;
    }

    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour < 100;
    const isValidMinutes = (minutes) => (Number.isInteger(minutes) && hours >= 0 && hours < 100) || Number.isNaN(minutes);
    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false;
    }

    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false;
    }

    if (valArr.indexOf(':')) {
        valArr = val.split(':');
    } else {
        valArr.push(val);
    }

    if (valArr[0] && valArr[0].length && (parseInt(valArr[0]) < 0 || parseInt(valArr[0]) > 99)) {
        return false;
    }

    if (valArr[1] && valArr[1].length && (parseInt(valArr[1]) < 0 || parseInt(valArr[1]) > 59)) {
        return false;
    }

    return true;
};

export const getErrorText = (errorText, defaultValue, required) =>
    errorText || ((defaultValue === undefined || defaultValue === null) && required && VALIDATION_EMPTY_FIELD);
