import React from 'react';

import { Checkbox } from '../../../components/material-ui/Checkbox';
import { FormControlLabel } from '../../../components/material-ui/FormControlLabel';
import { Typography } from '../../../components/material-ui/Typography';

const EnrouteSettingRow = (props) => {
    const { num, data, checked, onRowSelection, onCellSelection, styles } = props;
    const handleRowSelection = React.useCallback(() => {
        onRowSelection(num);
    }, [num]);
    const handleCellSelection = React.useCallback(() => {
        onCellSelection(num);
    }, [num]);

    return (
        <tr onClick={handleRowSelection} className="navroute-settings__row">
            <td style={styles.cellCheckBox}>
                <FormControlLabel
                    control={<Checkbox checked={checked} onChange={handleCellSelection} color="primary" style={styles.checkbox} />}
                />
            </td>
            <td style={styles.cellSm}>
                <Typography variant="body1">{num + 1}</Typography>
            </td>
            <td style={styles.cell}>
                <Typography variant="body1">{(data[0] && data[0].title) || '-'}</Typography>
            </td>
            <td style={styles.cell}>
                <Typography variant="body1">{(data[1] && data[1].title) || '-'}</Typography>
            </td>
            <td style={styles.cell}>
                <Typography variant="body1">{(data[2] && data[2].title) || '-'}</Typography>
            </td>
        </tr>
    );
};

const EnrouteSettingRowMemoized = React.memo(EnrouteSettingRow);

export { EnrouteSettingRowMemoized as EnrouteSettingRow };
