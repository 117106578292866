import React from 'react';
import classnames from 'classnames';

import { CrewTypes } from '../../../service/constants';
import { TYPES, useShowModal } from '../../../actions/modal';
import { useStyles } from './useStyles';

const SummaryCrewLegInfo = (props) => {
    const { leg, declaration, fields, number, getDataFormat } = props;
    const showModal = useShowModal();
    const classes = useStyles();
    const fieldList = React.useMemo(() => fields.filter(({ id }) => declaration[id]), [fields, declaration]);

    const handleShowModal = React.useCallback(() => {
        showModal(TYPES.SUMMARY_CREW_LEG, {
            ...leg,
            legId: +leg.legId,
            title: `${leg.flightNum} ${leg.apTo || ''}-${leg.apLa || ''}`,
        });
    }, [leg]);

    if (!leg) {
        return;
    }

    return (
        <>
            <tr onClick={handleShowModal} {...(number % 2 !== 1 && { className: classes.highlightRow })}>
                <td className={classes.cell}>{leg.flightNum}</td>
                {fieldList.map(
                    ({ id, format }, index) =>
                        ((leg.crewType !== CrewTypes.PCT_PAXSTAFF || (leg.crewType === CrewTypes.PCT_PAXSTAFF && index < 2)) && (
                            <td key={index} className={classes.cell}>
                                {getDataFormat(id, format, leg)}
                            </td>
                        )) ||
                        (leg.crewType === CrewTypes.PCT_PAXSTAFF && index === 3 && (
                            <td key={index} colSpan={Math.max(fields.length - 3, 3)} className={classes.cell}>
                                Dead head crew
                            </td>
                        ))
                )}
            </tr>
            {(leg.comment || '') !== '' && (
                <tr className={classnames(classes.row, number % 2 !== 1 && classes.highlightRow)} onClick={handleShowModal}>
                    <td colSpan={fields.length} className={classes.comment}>
                        {leg.comment || ''}
                    </td>
                </tr>
            )}
        </>
    );
};

export { SummaryCrewLegInfo };
