import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    comment: {
        margin: `0 ${theme.spacing(1)}px`,

        '& .MuiInput-root': {
            height: 'auto',
        },
    },
    empty: {
        border: 'none',
    },
    field: {
        flex: '0 1 24%',
        ['@media (max-width:440px)']: {
            flexBasis: '50%',
        },
    },
    requiredStyle: {
        color: theme.palette.warningColor,
    },
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: '0 0 67%',
        justifyContent: 'space-around',
        paddingBottom: theme.spacing(1),

        ['@media (max-width:990px)']: {
            flexGrow: 1,
        },
    },
}));

export { useStyles };
