import React from 'react';
import { get } from 'lodash';
import { useTheme } from '@material-ui/core';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { ListItem, ListItemIcon, ListItemText } from '../../material-ui/List';
import { List } from '../../material-ui/List';
import { Attachment } from '../../../icons';
import { AirportDocuments } from '../AirportDocuments/AirportDocuments';
import { Limitations } from '../Limitations/Limitations';
import { useStyles, getStyles } from './useStyles';
import { getCurrentLeg, getCurrentLegInfo } from '../../../selectors/screen';
import { getTelexInfo } from '../../../selectors/telegrams';
import { useDefects } from '../../../selectors/defects';
import { useDocuments } from '../../../selectors/documents';
import { showConfirmDialog } from '../../../actions/messageDialog';
import { getTelegram, showAttachment as showAttachmentTelegram } from '../../../actions/telegrams';
import { getTelex, showAttachment as showAttachmentTelex } from '../../../actions/telex';
import { filterReportsByFlight } from '../../../actions/reports';
import { redirectPage } from '../../../actions/screen';
import { showModal, TYPES } from '../../../actions/modal';
import { TelexList } from '../TelexList/TelexList';
import { TelegramItem } from '../TelegramItem/TelegramItem';

const fields = [
    { primaryText: 'Briefing', code: 'BRF' },
    { primaryText: 'NOTAMs', code: 'NTB' },
    { primaryText: 'Meteo', code: 'TAF' },
];

function AttachmentContent({ onClose }) {
    const dispatch = useDispatch();
    const defects = useDefects();
    const documents = useDocuments();
    const {
        currentLegInfo = '',
        telegrams,
        isSupportTelex,
        id,
        plnName,
        ofps,
        isNoticeAllowed,
        isNoticePreviewAllowed,
        currentOfpId,
    } = useSelector((state) => {
        const {
            screen: { currentLeg },
            sendedData,
            routesDeclaration,
        } = state;
        const isNoticeAllowed = get(routesDeclaration, 'options.allowNotices', false);
        const isNoticePreviewAllowed = get(routesDeclaration, 'options.allowNoticesPreview', false);
        const leg = getCurrentLeg(state) || {};
        const ofpId = get(sendedData[currentLeg], 'ofpId');
        const currentLegInfo = getCurrentLegInfo(state);
        const { telegramList, isSupportTelex } = getTelexInfo(state);

        return {
            currentLegInfo,
            isSupportTelex,
            telegrams: telegramList,
            plnName: get(leg, 'plnName', ''),
            ofps: get(leg, 'ofps'),
            id: get(leg, 'id'),
            currentOfpId: ofpId,
            isNoticeAllowed,
            isNoticePreviewAllowed,
        };
    }, shallowEqual);
    const theme = useTheme();
    const classes = useStyles();
    // TODO добавлено с 2.8.0, убрать старые телеграммы после перехода на нее всех
    const showAttachment = isSupportTelex ? showAttachmentTelex : showAttachmentTelegram;
    const getCurrentTelegram = isSupportTelex ? getTelex : getTelegram;

    const styles = React.useMemo(() => {
        return getStyles(theme);
    }, [theme]);

    const ofp = React.useMemo(() => {
        const ofpId = currentOfpId;
        let ofp = null;

        if (!ofps) {
            return ofp;
        }

        Object.keys(ofps).forEach((key) => {
            if (ofps[key].id === +ofpId) {
                ofp = ofps[key];

                return;
            }
        });

        return ofp;
    }, [currentOfpId, ofps]);

    const handleShowConfirmDialog = React.useCallback(
        ({ title, message, btnCancelVisibility = true }) =>
            dispatch(
                showConfirmDialog({
                    title,
                    message,
                    btnCancelVisibility,
                })
            ),
        []
    );

    const handleClickOfp = React.useCallback(() => {
        if (ofp) {
            dispatch(getCurrentTelegram({ id: ofp.telegramId, name: `OFP ${ofp.name}` }));
            onClose();
        } else {
            let msg = {
                title: 'Warning message',
                message: "There is no CFP telegram for this aircraft or hasn't been selected yet",
                btnCancelVisibility: false,
            };
            handleShowConfirmDialog(msg);
        }
    }, [ofp, onClose]);

    const handleClickSafetyReport = React.useCallback(() => {
        dispatch(filterReportsByFlight(id));
        dispatch(redirectPage('reports'));
        onClose();
    }, [id, onClose]);

    const handleClickNotices = React.useCallback(() => {
        dispatch(
            showModal(TYPES.NOTICE, {
                title: 'NOTAM',
                showPreview: !isNoticeAllowed && isNoticePreviewAllowed,
            })
        );
        onClose();
    }, [isNoticeAllowed, isNoticePreviewAllowed, onClose]);

    const handleClickTelegram = React.useCallback(
        (code) => {
            let telegram;

            Object.keys(telegrams).forEach((key) => {
                if (telegrams[key] && key === code) {
                    const prevMailTime = get(telegram, 'mailTime');
                    const mailTime = get(telegrams[key], 'mailTime');
                    if (!telegram || (prevMailTime && mailTime && new Date(prevMailTime) < new Date(mailTime))) {
                        telegram = telegrams[key];
                    }

                    return;
                }
            });

            if (telegram) {
                dispatch(getCurrentTelegram({ id: telegram.id, name: telegram.name }));
                onClose();
            } else {
                const msg = {
                    title: 'Warning message',
                    message: `There is no ${name} telegram for this aircraft`,
                    btnCancelVisibility: false,
                };
                handleShowConfirmDialog(msg);
            }
        },
        [telegrams, id, onClose]
    );

    const getStyle = React.useCallback(
        (isDisabled, defaultStyle) => {
            const { disabledColor } = theme.palette;
            defaultStyle = defaultStyle || styles.simple;

            return isDisabled ? { ...defaultStyle, color: disabledColor } : defaultStyle;
        },
        [styles]
    );

    const handleShowAttachment = React.useCallback((id) => dispatch(showAttachment(id)), []);

    return (
        <>
            <div className={classes.toolbar} />
            <List className="menu-list" component="div">
                <ListItem className={classes.listItemWithBorderBottom}>
                    <ListItemIcon style={styles.listIcon}>
                        <Attachment width="20" height="20" />
                    </ListItemIcon>
                    <ListItemText primary={`Attachments ${currentLegInfo}`} primaryTypographyProps={{ className: classes.bold }} />
                </ListItem>
                {isSupportTelex ? (
                    <TelexList
                        getStyle={getStyle}
                        telegrams={telegrams}
                        onOfpClick={handleClickOfp}
                        onClickTelegram={handleClickTelegram}
                        onAttachmentClick={handleShowAttachment}
                    />
                ) : (
                    <>
                        {fields.map(({ primaryText, code }) => (
                            <TelegramItem
                                key={code}
                                primaryText={primaryText}
                                code={code}
                                telegrams={telegrams}
                                getStyle={getStyle}
                                onClick={handleClickTelegram}
                                onAttachmentClick={handleShowAttachment}
                            />
                        ))}
                        <TelegramItem
                            primaryText="OFP"
                            onClick={handleClickOfp}
                            disabled={!ofp}
                            disableAttachments={!ofp}
                            style={getStyle(!ofp)}
                            code="CFP"
                            telegrams={telegrams}
                            onAttachmentClick={handleShowAttachment}
                        />
                    </>
                )}
                {isNoticeAllowed || isNoticePreviewAllowed ? (
                    <ListItem className={classes.listItemWithBorder} onClick={handleClickNotices} button={true}>
                        <ListItemText primary="NOTAMs" />
                    </ListItem>
                ) : (
                    ''
                )}
                <ListItem className={classes.listItemWithBorder} onClick={handleClickSafetyReport} button={true}>
                    <ListItemText primary="Reports" />
                </ListItem>
                <Limitations
                    defects={defects}
                    style={styles.listItemWithBorder}
                    primary={
                        <>
                            AC limitations <span className={classes.bold}>{(plnName && plnName.toUpperCase()) || ''}</span>
                        </>
                    }
                />
                <AirportDocuments docs={documents} style={styles.listItemWithBorder} />
            </List>
        </>
    );
}

const AttachmentContentMemoized = React.memo(AttachmentContent);

export { AttachmentContentMemoized as AttachmentContent };
