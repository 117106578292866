import { uniqBy } from 'lodash';

import {
    DELETE_REPORT_DATA,
    FILTER_REPORTS_BY_LEG,
    SAVE_REPORT_DATA,
    UPDATE_REPORTS_AFTER_SAVE,
    GET_REPORTS,
    CANCEL_DELETE_REPORT_DATA,
} from '../actions/reports';
import { LOGIN, GET_USER_DATA, UPDATE_USER_PLAN_FLIGHT } from '../actions/login';
import { LOGOUT } from '../actions/screen';

const initialState = {
    reports: [],
    reportsOptions: {
        groups: [],
        types: [],
    },
    planFlights: [],
    showFilter: false,
    syncTime: null,
};

function mergePlanFlights(initPlanFlights = [], newPlanFlights = []) {
    const additionPlanFlight = [];

    // Добавляем в список рейсов, по которым можно добавить доклады, рейсы из подгруженных докладов.
    // Признак isDefault не ставим - он для неизменных рейсов.
    newPlanFlights.forEach((pf) => {
        const itemIndex = initPlanFlights.findIndex((item) => item.id === pf.id);
        if (itemIndex === -1) {
            additionPlanFlight.push(pf);
        } else if (initPlanFlights[itemIndex].taskNumber !== pf.taskNumber) {
            initPlanFlights[itemIndex].taskNumber = pf.taskNumber;
        }
    });

    return initPlanFlights.concat(uniqBy(additionPlanFlight, 'id'));
}

export default (state = initialState, action) => {
    let newState;
    let reports;

    switch (action.type) {
        case LOGIN:
        case LOGOUT:
            return { ...initialState };
        case GET_USER_DATA: {
            newState = { ...state };
            const { planFlights = [], reportsOptions } = action.user.userData;
            const initPlanFlights = (state.planFlights || []).filter((pf) => !pf.isDefault);
            const newPlanFlights = planFlights.map((pf) => ({ ...pf, isDefault: true }));

            newState.reportsOptions = reportsOptions;
            newState.planFlights = mergePlanFlights(initPlanFlights, newPlanFlights);
            newState.syncTime = new Date().getTime();
            return newState;
        }
        case GET_REPORTS: {
            newState = { ...state };
            const { reports = [], planFlights = [] } = action;
            const editedReports = state.reports.filter((report) => (report.isEdited && !report.isNew) || report.isRemoved);
            const notSavedReports = state.reports.filter((report) => report.isNew);
            const commonReports = [].concat(reports, notSavedReports);
            const initPlanFlights = (newState.planFlights || []).filter((pf) => pf.isDefault);
            editedReports.forEach((report) => {
                const repIndex = commonReports.findIndex((item) => item.id === report.id);
                if (repIndex !== -1) {
                    commonReports[repIndex] = { ...commonReports[repIndex], ...report };
                } else {
                    commonReports.push({ ...report });
                }
            });

            newState.reports = commonReports;
            newState.planFlights = mergePlanFlights(initPlanFlights, planFlights);
            return newState;
        }
        case UPDATE_USER_PLAN_FLIGHT: {
            newState = { ...state };
            newState.planFlights = action.planFlights;

            return newState;
        }
        case SAVE_REPORT_DATA: {
            newState = { ...state };
            reports = [].concat(newState.reports);

            const { report } = action;
            const reportIndex = reports.findIndex((r) => r.id === report.id);

            if (reportIndex !== -1) {
                reports[reportIndex] = Object.assign(reports[reportIndex], report);
            } else {
                reports.push(report);
            }
            newState.reports = reports;

            return newState;
        }
        case DELETE_REPORT_DATA: {
            newState = { ...state };
            reports = [].concat(newState.reports);

            const index = reports.findIndex((r) => r.id === action.id);

            if (action.id.toString().indexOf('uniq') == -1) {
                reports[index] = Object.assign({}, reports[index], { isRemoved: true });
            } else {
                reports.splice(index, 1);
            }
            newState.reports = reports;

            return newState;
        }
        case CANCEL_DELETE_REPORT_DATA: {
            newState = { ...state };
            reports = [].concat(newState.reports);

            const index = reports.findIndex((r) => r.id === action.id);

            if (index !== -1) {
                reports[index] = { ...reports[index], isRemoved: false };
                newState.reports = reports;
            }

            return newState;
        }
        case FILTER_REPORTS_BY_LEG:
            newState = { ...state };
            newState.showFilter = !!action.legId;
            return newState;

        case UPDATE_REPORTS_AFTER_SAVE:
            newState = { ...state };

            reports = newState.reports.reduce((res, report) => {
                if (!report.isRemoved) {
                    let newReport = { ...report };
                    const reportInfo = action.reports[newReport.id];

                    if (reportInfo) {
                        newReport.isEdited = false;
                        newReport.isNew = false;
                        newReport.id = reportInfo.id;
                        newReport.number = reportInfo.number ? reportInfo.number : newReport.number;

                        newReport.images = newReport.images.reduce((images, img) => {
                            if (!img.isRemoved) {
                                const newImg = Object.assign({}, img, { needSave: false });

                                if (reportInfo && reportInfo.imagesIds[newImg.id]) {
                                    newImg.id = '' + reportInfo.imagesIds[newImg.id];
                                }
                                delete newImg.contentType;
                                delete newImg.data;

                                images.push(newImg);
                            }

                            return images;
                        }, []);
                    }

                    res.push(newReport);
                } else if (action.deleteError) {
                    // если была ошибка удаления, то не трогаем доклады с признаком удаленных
                    res.push({ ...report });
                }

                return res;
            }, []);

            newState.reports = reports;

            return newState;

        default:
            return state;
    }
};
