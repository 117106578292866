import { makeStyles } from '@material-ui/core/styles';

import { darkFullWhite } from '../../service/getMuiThemeStyle';

const useStyles = makeStyles((theme) => ({
    button: {
        height: 48,
    },
    buttonLeft: {
        height: 48,
        marginRight: theme.spacing(1),
    },
    label: {
        minHeight: 56,
        color: darkFullWhite,
    },
    text: {
        padding: theme.spacing(0, 2),
    },
}));

export { useStyles };
