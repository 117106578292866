import React from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core';

import { ListItem, ListItemText } from '../../../material-ui/List';
import { downloadDocument } from '../../../../actions/documents';
import { downloadDefect } from '../../../../actions/defects';
import { downloadLibraryItem } from '../../../../actions/library';

const NotificationItem = (props) => {
    const { id, primary, secondary, selectedIndex } = props;
    const dispatch = useDispatch();
    const theme = useTheme();

    const handleDownloadItem = React.useCallback(() => {
        if (selectedIndex === 0) {
            return dispatch(downloadDocument(id, primary));
        }

        if (selectedIndex === 1) {
            return dispatch(downloadDefect(id, primary));
        }

        dispatch(downloadLibraryItem(id, primary));
    }, [selectedIndex, id, primary]);

    return (
        <ListItem key={id} onClick={handleDownloadItem} button={true}>
            <ListItemText
                primary={primary}
                secondary={secondary}
                secondaryTypographyProps={{
                    style: { color: theme.palette.grey[theme.palette.type === 'light' ? '700' : '300'] },
                }}
            />
        </ListItem>
    );
};

const NotificationItemMemoized = React.memo(NotificationItem);

export { NotificationItemMemoized as NotificationItem };
