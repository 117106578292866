import React, { useContext, useEffect } from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useModalWrapperProps } from '../../selectors/modals';
import { closeConfirmDialog } from '../../actions/messageDialog';
import { useHideModal } from '../../actions/modal';
import { setWizardStep } from '../../actions/wizard';
import { NotificationTypes, pushNotification } from '../../actions/notifications';
import { redirectPage } from '../../actions/screen';
import { SidebarContext } from '../PageLayout/ContextProvider';
import { isAndroid } from '../../service/utils';

const useBackButton = (disableOverflow) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { modalType } = useModalWrapperProps();
    const { visibility } = useSelector((state) => get(state, 'messageDialog', {}));
    const activeStep = useSelector((state) => get(state, 'wizard.wizardStep', 0));
    const hideModal = useHideModal();
    const { handleCloseAllSidebar } = useContext(SidebarContext);

    let lastBackButtonTime;

    const handleBackButton = React.useCallback(
        (event) => {
            if (isAndroid()) {
                if (visibility === true) {
                    dispatch(closeConfirmDialog(false));
                } else if (modalType) {
                    hideModal();
                } else if (disableOverflow === true) {
                    handleCloseAllSidebar();
                } else if (history.location.pathname !== '/flight-task') {
                    if (history.location.pathname === '/flight-info' && activeStep > 0) {
                        dispatch(setWizardStep(activeStep - 1));
                    } else if (history.length > 1) {
                        history.goBack();
                    } else {
                        dispatch(redirectPage('flight-task'));
                    }
                } else {
                    if (event.timeStamp - lastBackButtonTime < 2000) {
                        window.plugins.appMinimize.minimize();
                    } else {
                        dispatch(
                            pushNotification({
                                variant: NotificationTypes.INFO,
                                message: 'Please click BACK again to exit.',
                                details: '',
                            })
                        );
                        lastBackButtonTime = event.timeStamp;
                    }
                }
                event.preventDefault();
            }
        },
        [modalType, visibility, activeStep, disableOverflow]
    );

    useEffect(() => {
        document.addEventListener('backbutton', handleBackButton, false);

        return () => {
            document.removeEventListener('backbutton', handleBackButton, false);
        };
    }, [handleBackButton]);
};

export { useBackButton };
