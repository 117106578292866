import { DefaultMessage, DocumentPrefix, DocumentTypes, FileStorePath } from './constants';
import { get } from 'lodash';

export const getFileName = (fileName) => (fileName && fileName.replace(/\.[^/.]+$/, '')) || '';

export const checkDocumentFormat = (name) => {
    const fileName = getFileName(name);
    const isDocumentItem = Number.isInteger(+fileName);
    const isDefectItem = fileName.indexOf(DocumentPrefix.DEFECT) === 0;
    const isLibraryItem = fileName.indexOf(DocumentPrefix.LIBRARY) === 0;
    const needChange = fileName === name && (isDocumentItem || isDefectItem || isLibraryItem);
    let type = DocumentTypes.OTHER;
    if (isDefectItem) {
        type = DocumentTypes.DEFECT;
    } else if (isLibraryItem) {
        type = DocumentTypes.LIBRARY;
    } else if (isDocumentItem) {
        type = DocumentTypes.DOCUMENT;
    }

    return {
        fileName,
        type,
        needChange,
    };
};

export const getFileExtensions = (contentType) => {
    switch (contentType) {
        case 'application/pdf':
            return '.pdf';
    }

    return '';
};

export const checkExistValue = (value) => {
    return value !== null && value !== undefined; // && !isNaN(value);
};

export const isAndroid = () => {
    // eslint-disable-next-line no-undef
    return typeof device !== 'undefined' && device.platform === 'Android';
};

export const getFileStorePath = () => {
    return isAndroid() ? FileStorePath.ANDROIDDOWNLOAD : FileStorePath.LOCAL;
};

export const getErrorMessage = (error, messageType) => {
    if (!error.message && (!messageType || !DefaultMessage[messageType])) {
        return `An unhandled error has occurred please contact support. ${messageType && `${messageType}.`} ${error && `Error: ${error}`}`;
    }
    return error.message || `${DefaultMessage[messageType]}. ${error && `Details: ${error}`}`;
};

export const checkVersion = (appVersion, initialState) => {
    const stateVersion = get(initialState, 'version.application', '');

    return appVersion === stateVersion;
};
