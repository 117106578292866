import file from './file';
import pdf from './pdf';
// import session from './session';
import geolocation from './geolocation';

export default {
    file,
    pdf,
    // session,
    geolocation,
};
