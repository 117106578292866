import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    const {
        table: { secondaryColor },
        palette: { primary3Color, borderColor },
    } = theme;

    return {
        itemText: {
            padding: '5px 0',
            textAlign: 'left',
            whiteSpace: 'pre-line',
        },
        flightColumn: {
            width: '240px',
            padding: '5px 0',
            textAlign: 'center',
        },
        header: {
            width: '100%',
            margin: theme.spacing(2, 0, 0),
            borderSpacing: 0,

            color: primary3Color,
            borderColor: borderColor,
            textAlign: 'center',
        },
        headerCell: {
            padding: '10px 0',
            borderBottom: '1px solid',
            fontWeight: 'normal',
        },
        secondaryRow: {
            background: secondaryColor,
        },
        table: {
            width: '100%',
            borderSpacing: 0,
            borderTop: `1px solid ${borderColor}`,
            borderBottom: `1px solid ${borderColor}`,
        },
    };
});
