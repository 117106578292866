import moment from 'moment';
import { pad } from 'lodash';

export function convertDateToUTC(date) {
    return (
        date &&
        new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds()
        )
    );
}

export function convertUTCToDate(value) {
    const utcDate = value && new Date(value);

    return (
        value &&
        new Date(
            Date.UTC(
                utcDate.getFullYear(),
                utcDate.getMonth(),
                utcDate.getDate(),
                utcDate.getHours(),
                utcDate.getMinutes(),
                utcDate.getSeconds()
            )
        )
    );
}

export function getTimeHHmm(time) {
    return (time && moment.utc(time).format('HH:mm')) || '';
}

export function getTimeDiff(dateStart, dateEnd) {
    return dateEnd && dateStart && (new Date(dateEnd) - new Date(dateStart)) / 1000;
}

export function parseTimeHHmm(time) {
    return (time && moment(time, 'HH:mm').toDate()) || '';
}

export function getStringTimeHHmm(time) {
    return (time && moment(time).format('HH:mm')) || '';
}

export function checkInputTimeSupport() {
    try {
        const input = document.createElement('input');
        input.type = 'time';

        if (input.type === 'time') {
            return true;
        }
    } catch (e) {
        return false;
    }

    return false;
}

export function removeSeconds(time) {
    if (time) {
        time.seconds(0);
        time.milliseconds(0);
    }

    return time;
}

export const getDate = (dt) => {
    const date = new Date(dt);
    if (date && date.toString() !== 'Invalid Date') {
        return date;
    }
};

export const convertTime = (time) => {
    return moment
        .utc(time || undefined)
        .second(0)
        .millisecond(0);
};

export const sec2min = (timeInSec) => {
    const minutes = Math.floor(timeInSec / 60);
    const seconds = Math.floor(timeInSec - minutes * 60);

    return minutes ? `${minutes}:${pad(seconds, 2)}` : `${pad(seconds, 2)}`;
};
