import * as React from 'react';
import { useSelector } from 'react-redux';
import { isNil } from 'lodash';
import { ButtonGroup } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';

import { Weight, Weights } from '../../reducers/systemOfMeasurement';
import { useAddWeightUnitsToHistory } from '../../actions/systemOfMeasurement';
import { getCurrentSendedLeg } from '../../selectors/sended-data';

const weightButtons = [
    { value: Weight.KG, label: Weights[Weight.KG] },
    { value: Weight.LBS, label: Weights[Weight.LBS] },
];

const SystemMeasure = React.memo(({ legID, weightUnits, onWeightUnitsChange, size = 'small' }) => {
    const { convertedTo } = useSelector(getCurrentSendedLeg);
    const [firstRender, setFirstRender] = React.useState(true);
    const addWeightUnitsToHistory = useAddWeightUnitsToHistory();
    const handeChangeWeightUnits = React.useCallback(
        (evt) => {
            if (evt.currentTarget.tagName === 'BUTTON') {
                const value = +evt.currentTarget.id.split('_')[1];
                onWeightUnitsChange(value);
            }
        },
        [addWeightUnitsToHistory, onWeightUnitsChange]
    );

    const getClassName = React.useCallback((value) => (value === weightUnits ? 'primary' : 'inherit'), [weightUnits]);

    React.useEffect(() => {
        if (!firstRender) {
            addWeightUnitsToHistory({
                weightUnitsState: {
                    event: 'Toggle weight units',
                    appUnits: weightUnits,
                    legBeforeToggleIs: convertedTo,
                },
                legID,
            });
        }
        setFirstRender(false);
    }, [weightUnits]);

    if (isNil(weightUnits)) {
        return null;
    }

    return (
        <ButtonGroup>
            {weightButtons.map(({ value, label }) => (
                <Button
                    key={label}
                    size={size}
                    id={`${label}_${value}`}
                    variant={'contained'}
                    color={getClassName(value)}
                    onClick={handeChangeWeightUnits}
                >
                    {label}
                </Button>
            ))}
        </ButtonGroup>
    );
});

SystemMeasure.displayName = 'SystemMeasure';

export { SystemMeasure };
