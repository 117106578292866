import React from 'react';
import classnames from 'classnames';

import { Drawer } from '../../../components/material-ui/Drawer';
import { List } from '../../../components/material-ui/List';
import { NoticeSidebarGroupItem } from './NoticeSidebarGroupItem/NoticeSidebarGroupItem';
import { useStyles } from './useStyles';

const SIDEBAR_WIDTH = 340;

const NoticeSidebar = ({ open, routesAirports = [], className }) => {
    const classes = useStyles();

    return (
        <Drawer
            open={open}
            className={className}
            variant="permanent"
            style={{
                position: 'fixed',
                zIndex: '1501',
                WebkitOverflowScrolling: 'touch',
            }}
            classes={{ paper: classnames(classes.drawerPaper, open && classes.drawerOpen) }}
        >
            <List className={classes.list}>
                {routesAirports.map(({ airportList = {}, name, colorName }, index) => (
                    <NoticeSidebarGroupItem
                        key={`${name}_${index}`}
                        name={name}
                        type={index}
                        colorName={colorName}
                        currentData={routesAirports}
                        airports={airportList}
                    />
                ))}
            </List>
        </Drawer>
    );
};

NoticeSidebar.defaultProps = {
    docked: true,
    onToggle: () => {},
};

const NoticeSidebarMemoized = React.memo(NoticeSidebar);

export { SIDEBAR_WIDTH, NoticeSidebarMemoized as NoticeSidebar };
