import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    attachments: {
        flexBasis: '40%',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: theme.spacing(4, 0, 0),

        '&:first-child': {
            padding: theme.spacing(10, 0, 0),
        },
    },
    images: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        flexGrow: 1,
        flexBasis: '100%',
    },
    inner: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: '0 14px',

        ['@media only screen and (max-width: 480px)']: {
            padding: theme.spacing(0, 0.5),
        },
    },
    field: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        color: theme.palette.text.primary,
    },
    fieldHalf: {
        flexBasis: '50%',
    },
    preview: {
        margin: theme.spacing(1),
        maxWidth: 200,
    },
    textarea: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        color: theme.palette.text.primary,

        '& .MuiInput-root': {
            height: 'auto',
        },
    },
    title: {
        marginRight: 'auto',
    },
}));

export { useStyles };
