import React from 'react';

import { Typography } from '../../../components/material-ui/Typography';
import { FlightTaskCrewItem } from './FlightTaskCrewItem/FlightTaskCrewItem';
import { useStyles } from './useStyles';

function FlightTaskCrewTable({ crew = [], getMessage, getTextField }) {
    const classes = useStyles();

    return (
        <>
            <Typography variant="h6" className={classes.title}>{getMessage`Crew content`}</Typography>
            <table className={classes.table}>
                <thead>
                    <tr>
                        <th className={classes.header}>
                            <Typography>{getMessage`Table number`.toUpperCase()}</Typography>
                        </th>
                        <th className={classes.header}>
                            <Typography>{getMessage`Role`.toUpperCase()}</Typography>
                        </th>
                        <th className={classes.header}>
                            <Typography>{getMessage`Full name`.toUpperCase()}</Typography>
                        </th>
                        <th className={classes.header}>
                            <Typography>{getMessage`Comment`.toUpperCase()}</Typography>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {crew && crew.length > 0 ? (
                        crew.map((item, index) => {
                            return <FlightTaskCrewItem key={index} item={item} getTextField={getTextField} getMessage={getMessage} />;
                        })
                    ) : (
                        <tr>
                            <td colSpan={3}>
                                <Typography>There is not crew information</Typography>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    );
}

const FlightTaskCrewTableMemoized = React.memo(FlightTaskCrewTable);

export { FlightTaskCrewTableMemoized as FlightTaskCrewTable };
