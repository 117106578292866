import * as React from 'react';

import { isImageHeic } from '../../service/ImageUtils';

export const useDropzoneFiles = (imageList = []) => {
    const [images, changeImages] = React.useState([]);
    const [removed, changeRemoved] = React.useState([]);

    const data = React.useMemo(
        () =>
            [].concat(
                images.map((img, pos) => Object.assign(img, { isNew: true, pos })),
                imageList.map((img, pos) => Object.assign({}, img, { pos })).filter((img) => removed.indexOf(img.pos) === -1)
            ),
        [images, removed, imageList]
    );

    const handleDrop = React.useCallback((images) => {
        changeImages((prevState) => [...prevState, ...images]);
    }, []);

    const handleDeleteImage = React.useCallback((pos, isNew) => {
        if (isNew) {
            changeImages((prevState) => {
                const newState = [...prevState];
                newState.splice(pos, 1);

                return newState;
            });
        } else {
            changeRemoved((prevState) => [...prevState, pos]);
        }
    }, []);

    const handleSave = React.useCallback(() => {
        return Promise.all(
            images.map((img) => {
                const contentType = isImageHeic(img.type, img.name) ? 'image/heic' : img.type;
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();

                    reader.onload = () => {
                        const arrayBuffer = reader.result;

                        const buffer = Buffer.alloc(arrayBuffer.byteLength);
                        const view = new Uint8Array(arrayBuffer);

                        for (var i = 0; i < buffer.length; ++i) {
                            buffer[i] = view[i];
                        }

                        resolve({
                            url: img.preview,
                            data: buffer,
                            contentType,
                        });
                    };
                    reader.onerror = () => reject('file reading has failed');
                    reader.readAsArrayBuffer(img);
                });
            })
        ).then((newImages) => ({ removed, newImages }));
    }, [removed, images]);

    return {
        images: data,
        handleDrop,
        handleDeleteImage,
        handleSave,
    };
};
