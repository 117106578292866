import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
    cell: {
        height: 48,
        padding: theme.spacing(0, 1),
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    cellEmpty: {
        textAlign: 'center',
    },
    headCell: {
        height: 48,
        padding: theme.spacing(0, 1),
        borderBottom: `1px solid ${theme.palette.divider}`,
        fontSize: 12,
        fontWeight: 'bold',
    },
    sortedIcon: {
        width: 12,
        height: 12,
        margin: '0 3px',
        color: theme.icon.color,
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        borderSpacing: 0,
    },
    passengerInfo: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        padding: '5px',
    },
    passengerInfoItem: {
        flex: '1 1 50%',
        textAlign: 'left',
        margin: '5px 0',
        fontSize: '14px',
    },
}));
