import { makeStyles } from '@material-ui/styles';

import { darkFullWhite } from '../../service/getMuiThemeStyle';

export const useStyles = makeStyles((theme) => ({
    companyInfo: {
        width: '95%',
        margin: '-50px auto 0px',
        padding: theme.spacing(2, 0),
        textAlign: 'center',
        borderRadius: 4,
    },
    copyright: {
        display: 'inline-block',
        marginRight: 80,
        color: darkFullWhite,

        ['@media only screen and  (max-width: 480px)']: {
            marginRight: 0,
        },
    },
    form: {
        padding: 20,
        borderRadius: 4,

        ['@media (max-width: 480px)']: {
            marginTop: 80,
        },
    },
    footer: {
        marginBottom: 20,

        ['@media only screen and  (max-width: 480px)']: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
    },
    inner: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,

        ['@media only screen and  (max-width: 480px)']: {
            padding: 15,
        },
    },
    link: {
        color: darkFullWhite,
        textDecoration: 'none',

        '&:hover': {
            textDecoration: 'underline',
        },
        '&:focus': {
            textDecoration: 'underline',
        },
    },
    title: {
        display: 'inline-block',
        margin: '0 0 12px 0',

        color: darkFullWhite,
        fontSize: 32,
        fontWeight: 600,
        verticalAlign: 'middle',
        letterSpacing: 2,
    },
    version: {
        display: 'inline-block',
        textAlign: 'center',
        color: darkFullWhite,
    },
}));
