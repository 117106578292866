import React from 'react';
import classnames from 'classnames';
import { shallowEqual, useSelector } from 'react-redux';
import { get } from 'lodash';

import { Panel } from '../../Panel/Panel';
import { CustomField } from '../../CustomField/CustomField';
import { TimeField } from '../../TimeField/TimeField';
import MaterialTextField from '../../MaterialTextField/MaterialTextField';
import { MAX_DISTANCE } from '../../../service/NavPointsUtils';
import { checkRequired, getLabel } from '../tools';
import { useStyles } from './useStyles';
import { getFirstLeg } from '../../../selectors/screen';
import { useFieldsDeclaration } from '../../../selectors/declaration';

const FieldTypes = {
    FUEL: 0,
    TIME: 1,
};

const fields = [
    { id: 'distance', label: 'DIST/T', validator: { type: 'int', min: 0, max: MAX_DISTANCE }, type: FieldTypes.FUEL },
    { id: 'landings', label: 'Landings', validator: { type: 'int', min: 0, max: 255 }, type: FieldTypes.FUEL },
    {
        id: 'landingsOnDevices',
        label: 'Landing on device',
        validator: { type: 'int', min: 0, max: 255 },
        type: FieldTypes.FUEL,
    },
    { id: 'timeOnDevices', label: 'Instrumental flight' },
    { id: 'timeFlightNight', label: 'Flight night' },
    { id: 'timeBiologicalNight', label: 'Biological night' },
    { id: 'timeWaitingZone', label: 'Hold time' },
    { id: 'empty1', label: '', type: FieldTypes.FUEL, disable: true, hidden: true },
    // { id: 'timeWorkRest', label: 'Rest time', linkedOnLegNumber: true },
    // { id: 'timeWorkParking', label: 'Parking time', linkedOnLegNumbemptyer: true },
];

function AdditionalInfoPanel({ onEditField, onEditCommentField }) {
    const declaration = useFieldsDeclaration();
    const { bufferComment, isFirstLeg, distanceEstimate, ...rest } = useSelector((state) => {
        const currentLeg = get(state, 'screen.currentLeg');
        const currentData = get(state, `sendedData[${currentLeg}]`, {});
        const landing = get(currentData, 'landing', {});
        const firstLeg = getFirstLeg(state) || {};
        const isFirstLeg = firstLeg.id === currentLeg;

        return {
            landings: landing.landings,
            landingsOnDevices: landing.landingsOnDevices,
            timeOnDevices: landing.timeOnDevices,
            timeFlightNight: landing.timeFlightNight,
            timeBiologicalNight: landing.timeBiologicalNight,
            timeWaitingZone: landing.timeWaitingZone,
            // timeWorkRest: landing.timeWorkRest,
            // timeWorkParking: landing.timeWorkParking,
            distance: landing.distance,
            distanceEstimate: currentData.distanceEstimate,
            bufferComment: landing.bufferComment,
            isFirstLeg,
        };
    }, shallowEqual);
    const classes = useStyles();
    const handleChangeComment = React.useCallback((evt) => onEditCommentField('bufferComment', evt.currentTarget.value), []);

    return (
        <Panel title="Additional flight info" className={classes.wrapper}>
            <div className={classes.inner}>
                {fields &&
                    fields.map(({ id, label, type, validator, linkedOnLegNumber, hidden }) => {
                        const defaultValue = rest[id];
                        const fieldLabel = getLabel(declaration, id) || label;
                        const fieldLabelCustom =
                            id === 'distance' && distanceEstimate && distanceEstimate !== defaultValue
                                ? `${fieldLabel} (EST=${distanceEstimate})`
                                : fieldLabel;

                        return type === FieldTypes.FUEL ? (
                            <CustomField
                                key={`${defaultValue}:${id}`}
                                name={id}
                                label={fieldLabelCustom}
                                onEditField={onEditField}
                                defaultValue={defaultValue}
                                validator={validator}
                                required={checkRequired(declaration, id)}
                                className={classnames(classes.field, hidden && classes.hidden)}
                                type="number"
                            />
                        ) : (
                            <TimeField
                                key={`${defaultValue}:${id}`}
                                name={id}
                                label={fieldLabelCustom}
                                disabled={linkedOnLegNumber && isFirstLeg}
                                onEditField={onEditField}
                                defaultValue={defaultValue}
                                required={checkRequired(declaration, id)}
                                className={classnames(classes.field, hidden && classes.hidden)}
                            />
                        );
                    })}
                <MaterialTextField
                    label="Duty comment (will be shown on Summary info page)"
                    placeholder="Duty comment"
                    name="bufferComment"
                    onBlur={handleChangeComment}
                    key="bufferComment"
                    fullWidth={true}
                    defaultValue={bufferComment}
                    InputLabelProps={{ className: classes.commentLabel }}
                    multiline={true}
                    minRows={4}
                    maxRows={4}
                    required={checkRequired(declaration, 'bufferComment')}
                    className={classnames(classes.comment, 'multiline')}
                />
            </div>
        </Panel>
    );
}

const AdditionalInfoPanelMemoized = React.memo(AdditionalInfoPanel);

export { AdditionalInfoPanelMemoized as AdditionalInfoPanel };
