import moment from 'moment';
import { createSelector } from 'reselect';
import { get } from 'lodash';

import { getCurrentLeg } from './screen';

const getTelegrams = createSelector(
    (state) => get(state, 'telegrams', {}),
    (state) => get(state, 'telex', null),
    (telegrams, telex) => {
        return {
            telegrams: telex ? telex : telegrams,
            isSupportTelex: Boolean(telex),
        };
    }
);

const getCfpSignature = createSelector(
    (state) => get(state, 'screen.currentLeg', null),
    (state) => get(state, 'sendedData', {}),
    (currentLegID, sendedData) => get(sendedData[currentLegID], 'signature')
);

const getCfpID = createSelector(
    (state) => get(state, 'screen.currentLeg', null),
    (state) => get(state, 'sendedData', {}),
    (currentLegID, sendedData) => get(sendedData[currentLegID], 'ofpId')
);

const getImages = createSelector(
    (state) => get(state, 'images'),
    (images) => images
);

const getTelexInfo = createSelector(
    getCurrentLeg,
    getTelegrams,
    getCfpSignature,
    getCfpID,
    getImages,
    (leg, { telegrams, isSupportTelex }, currentCfpSignature, currentCfpId, images) => {
        const telegramIds = get(leg, 'telegramIds', []);
        let telegramList = telegramIds.reduce((res, key) => {
            const { id, name, attachments, telexCode } = telegrams[key] || {};
            let { pfacnID, cfpNumber, mailTime } = telegrams[key] || {};
            const prevMailTime = get(res[name], 'mailTime');
            const isCfp = name === 'CFP';

            if (isCfp) {
                const ofps = Object.values(leg.ofps);
                let findedOfp = { date: moment('01-01-1900') };
                for (let i = 0; i < ofps.length; i++) {
                    const ofp = ofps[i];
                    const isMatchedTelegram = ofp.telegramId === id;
                    const isSameOrAfter = moment(ofp.date).isSameOrAfter(findedOfp.date);

                    if (isMatchedTelegram && isSameOrAfter) {
                        findedOfp = { ...ofp };

                        const isCurrentSigned = ofp.id === +currentCfpId;
                        if (isCurrentSigned) {
                            break;
                        }
                    }
                }

                if (findedOfp.id) {
                    pfacnID = findedOfp.id;
                    cfpNumber = findedOfp.name;
                    mailTime = findedOfp.date;
                }
            }

            const signatureName = isCfp ? currentCfpSignature : `sign_telegram_${id}`;
            const signatureExist = Boolean(images[signatureName]);
            const isCurrentTelegramSigned = isCfp ? +pfacnID === +currentCfpId && signatureExist : signatureExist;
            const isPrevCfpSigned = Boolean(res[name] && res[name].signExist);
            const isLatestMailTime = !res[name] || (prevMailTime && mailTime && new Date(prevMailTime) < new Date(mailTime));

            if (isCfp ? isCurrentTelegramSigned || (isLatestMailTime && !isPrevCfpSigned) : isLatestMailTime) {
                res[name] = {
                    id,
                    pfacnID,
                    cfpNumber,
                    name,
                    mailTime,
                    attachments,
                    code: telexCode,
                };
                res[name].signExist = isCurrentTelegramSigned;
            }

            return res;
        }, {});

        return { telegramList, isSupportTelex, images };
    }
);

export { getTelexInfo };
