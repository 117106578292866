import React from 'react';

import { useStyles } from './useStyles';
import { Typography } from '../../../components/material-ui/Typography';

function FlightTaskLegsTable({ flights, getMessage, getDateField }) {
    const classes = useStyles();

    return (
        <>
            <Typography variant="h6" className={classes.title}>{getMessage`The planned route of flight`}</Typography>
            <table className={classes.table}>
                <thead>
                    <tr>
                        <th className={classes.header} rowSpan={2}>
                            <Typography>{getMessage`#`}</Typography>
                        </th>
                        <th className={classes.header} rowSpan={2}>
                            <Typography>{getMessage`Flight`.toUpperCase()}</Typography>
                        </th>
                        <th className={classes.header}>
                            <Typography>{getMessage`Route`.toUpperCase()}</Typography>
                        </th>
                        <th className={classes.header} rowSpan={2}>
                            <Typography>STD</Typography>
                        </th>
                        <th className={classes.header} rowSpan={2}>
                            <Typography>ETD</Typography>
                        </th>
                        <th className={classes.header} rowSpan={2}>
                            <Typography>STA</Typography>
                        </th>
                        <th className={classes.header} rowSpan={2}>
                            <Typography>ETA</Typography>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {flights &&
                        flights.map((item, index) => {
                            const isCanceled = (item && item.cancel) || false;

                            return (
                                <tr key={item.id}>
                                    <td className={classes.col}>
                                        <Typography>{index + 1}</Typography>
                                    </td>
                                    <td className={classes.col3}>
                                        <Typography>
                                            {item.num}
                                            {isCanceled ? ' (canceled)' : ''}
                                        </Typography>
                                    </td>
                                    <td className={classes.col4}>
                                        <Typography>{`${item.airportTakeOff || ''} - ${item.airportLanding || ''}`}</Typography>
                                    </td>
                                    <td className={classes.col4}>
                                        <Typography>
                                            {getDateField('std', item).date}
                                            <span className={classes.time}>{getDateField('std', item).time}</span>
                                        </Typography>
                                    </td>
                                    <td className={classes.col4}>
                                        <Typography>
                                            {getDateField('etd', item).date}
                                            <span className={classes.time}>{getDateField('etd', item).time}</span>
                                        </Typography>
                                    </td>
                                    <td className={classes.col4}>
                                        <Typography>
                                            {getDateField('sta', item).date}
                                            <span className={classes.time}>{getDateField('sta', item).time}</span>
                                        </Typography>
                                    </td>
                                    <td className={classes.col4}>
                                        <Typography>
                                            {getDateField('eta', item).date}
                                            <span className={classes.time}>{getDateField('eta', item).time}</span>
                                        </Typography>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </>
    );
}

const FlightTaskLegsTableMemoized = React.memo(FlightTaskLegsTable);

export { FlightTaskLegsTableMemoized as FlightTaskLegsTable };
