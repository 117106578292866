import { LOGOUT } from '../actions/screen';
import { SET_LINEAR_LOADING_STATE } from '../actions/progressIndicator';

const initialState = {
    min: 0,
    max: 0,
    value: 0,
    visibility: false,
};

export const screenInitialState = initialState;

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_LINEAR_LOADING_STATE:
            return {
                ...state,
                ...action.linearLoading,
            };

        case LOGOUT:
            return initialState;

        default:
            return state;
    }
};
