import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import classnames from 'classnames';

import { useHideModal } from '../../actions/modal';
import MaterialTextField from '../../components/MaterialTextField/MaterialTextField';
import { Panel } from '../../components/Panel/Panel';
import { ReportDropzoneImage } from '../Report/ReportDropzoneImage/ReportDropzoneImage';
import Pdf from '../../icons/image/Pdf';
import { showPdf } from '../../actions/documents';
import { useStyles } from './useStyles';
import { DialogAdaptive, DialogContent, DialogTitle } from '../../components/material-ui/Dialog';
import { AppBar } from '../../components/material-ui/AppBar';
import { Toolbar } from '../../components/material-ui/Toolbar';
import { Typography } from '../../components/material-ui/Typography';
import { Button } from '../../components/material-ui/Button';
import { preloadAllReportImageUrl } from '../../actions/reports';

const ReportDetail = React.forwardRef(({ report }, ref) => {
    const {
        generalInfo,
        addInfo,
        value,
        closedDate,
        images,
        flightName,
        flightDate,
        number,
        createdDate,
        statusDate,
        statusComment,
        statusType,
        safetyTypeName,
        safetyGroupTypeName,
        receiptDate,
        dayCount,
        responsible,
        reason,
        actions,
        airportName,
    } = report;
    const flight = `${flightName} at ${flightDate && moment.utc(flightDate).format('DD.MM.YYYY HH:mm')}`;
    const safetyType = `${safetyTypeName} (${safetyGroupTypeName})`;
    const hideModal = useHideModal();
    const dispatch = useDispatch();
    const classes = useStyles();
    React.useEffect(() => {
        dispatch(preloadAllReportImageUrl());
    }, []);

    const handleShowPdf = React.useCallback((id) => {
        dispatch(showPdf(id, null, ''));
    }, []);
    const InputProps = React.useMemo(
        () => ({
            classes: {
                input: classes.field,
            },
        }),
        []
    );

    return (
        <DialogAdaptive open={true} fullScreen={true} scroll="paper">
            <DialogTitle>
                <AppBar>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title} color="inherit">
                            Report details
                        </Typography>
                        <Button color="inherit" onClick={hideModal}>
                            OK
                        </Button>
                    </Toolbar>
                </AppBar>
            </DialogTitle>
            <DialogContent className={classes.content} ref={ref}>
                <Panel title="Report info" className={classes.info}>
                    <div className={classes.inner}>
                        <MaterialTextField
                            label="Flight"
                            defaultValue={flight}
                            className={classes.field}
                            InputProps={InputProps}
                            fullWidth={true}
                            disabled={true}
                        />
                        <MaterialTextField
                            label="Created at"
                            defaultValue={createdDate && moment.utc(createdDate).format('DD.MM.YYYY HH:mm')}
                            className={classnames(classes.field, classes.fieldHalf)}
                            InputProps={InputProps}
                            fullWidth={true}
                            disabled={true}
                        />
                        <MaterialTextField
                            label="Report №"
                            defaultValue={number}
                            className={classnames(classes.field, classes.fieldHalf)}
                            InputProps={InputProps}
                            fullWidth={true}
                            disabled={true}
                        />
                        <MaterialTextField
                            label="Report type(group)"
                            defaultValue={safetyType}
                            className={classes.field}
                            InputProps={InputProps}
                            fullWidth={true}
                            multiline={true}
                            maxRows={2}
                            disabled={true}
                        />
                        <MaterialTextField
                            label="Airport"
                            defaultValue={airportName}
                            fullWidth={true}
                            multiline={true}
                            className={classnames(classes.field, classes.fieldHalf)}
                            InputProps={InputProps}
                            disabled={true}
                        />
                        <MaterialTextField
                            label="Value"
                            defaultValue={value}
                            className={classnames(classes.field, classes.fieldHalf)}
                            InputProps={InputProps}
                            fullWidth={true}
                            disabled={true}
                        />
                        <MaterialTextField
                            label="Basic info"
                            defaultValue={generalInfo}
                            className={classes.textarea}
                            InputProps={InputProps}
                            fullWidth={true}
                            multiline={true}
                            disabled={true}
                        />
                        <MaterialTextField
                            label="Additional info"
                            defaultValue={addInfo}
                            className={classes.textarea}
                            InputProps={InputProps}
                            fullWidth={true}
                            multiline={true}
                            disabled={true}
                        />
                    </div>
                </Panel>
                <Panel title="Feedback info" className={classes.info}>
                    <div className={classes.inner}>
                        <MaterialTextField
                            label="Date received"
                            defaultValue={receiptDate && moment.utc(receiptDate).format('DD.MM.YYYY HH:mm')}
                            className={classnames(classes.field, classes.fieldHalf)}
                            InputProps={InputProps}
                            fullWidth={true}
                            disabled={true}
                        />
                        <MaterialTextField
                            label="Days to process"
                            defaultValue={dayCount}
                            className={classnames(classes.field, classes.fieldHalf)}
                            InputProps={InputProps}
                            fullWidth={true}
                            disabled={true}
                        />
                        <MaterialTextField
                            label="Date closed"
                            defaultValue={closedDate && moment.utc(closedDate).format('DD.MM.YYYY HH:mm')}
                            className={classes.field}
                            InputProps={InputProps}
                            fullWidth={true}
                            disabled={true}
                        />
                        <MaterialTextField
                            label="Responsible person or department"
                            defaultValue={responsible}
                            className={classes.field}
                            InputProps={InputProps}
                            fullWidth={true}
                            multiline={true}
                            disabled={true}
                        />
                        <MaterialTextField
                            label="Reason"
                            defaultValue={reason}
                            className={classes.field}
                            InputProps={InputProps}
                            fullWidth={true}
                            multiline={true}
                            disabled={true}
                        />
                        <MaterialTextField
                            label="Actions"
                            defaultValue={actions}
                            className={classes.field}
                            InputProps={InputProps}
                            fullWidth={true}
                            multiline={true}
                            disabled={true}
                        />
                    </div>
                </Panel>
                <Panel title="Report status" className={classes.info}>
                    <div className={classes.inner}>
                        <MaterialTextField
                            label="Status date"
                            defaultValue={statusDate && moment.utc(statusDate).format('DD.MM.YYYY HH:mm')}
                            className={classnames(classes.field, classes.fieldHalf)}
                            InputProps={InputProps}
                            fullWidth={true}
                            disabled={true}
                        />
                        <MaterialTextField
                            label="Status"
                            defaultValue={statusType}
                            className={classnames(classes.field, classes.fieldHalf)}
                            InputProps={InputProps}
                            fullWidth={true}
                            disabled={true}
                        />
                        <MaterialTextField
                            label="Comment"
                            defaultValue={statusComment}
                            className={classes.field}
                            InputProps={InputProps}
                            fullWidth={true}
                            multiline={true}
                            disabled={true}
                        />
                    </div>
                </Panel>

                <Panel title="Attachments" className={classnames(classes.info, classes.attachments)}>
                    <div className={classnames(classes.inner, classes.images)}>
                        {images.length === 0 ? (
                            <Typography>There are not any attachments</Typography>
                        ) : (
                            images
                                .filter((img) => !img.isRemoved)
                                .map((img, index) => {
                                    return img.url ? (
                                        <ReportDropzoneImage
                                            key={index}
                                            className={classes.preview}
                                            height={150}
                                            src={img.url}
                                            quickShow={true}
                                        />
                                    ) : (
                                        <Pdf
                                            key={index}
                                            width={80}
                                            height={80}
                                            className={classes.preview}
                                            onClick={() => handleShowPdf(`si_${img.id}`)}
                                        />
                                    );
                                })
                        )}
                    </div>
                </Panel>
            </DialogContent>
        </DialogAdaptive>
    );
});

export { ReportDetail };
