import moment from 'moment';

import { showConfirmDialog } from './messageDialog';

const SAVE_REPORT_DATA = 'SAVE_REPORT_DATA';
const DELETE_REPORT_DATA = 'DELETE_REPORT_DATA';
const UPDATE_REPORTS_AFTER_SAVE = 'UPDATE_REPORTS_AFTER_SAVE';
const FILTER_REPORTS_BY_LEG = 'FILTER_REPORTS_BY_LEG';
const GET_REPORTS = 'GET_REPORTS';
const CANCEL_DELETE_REPORT_DATA = 'CANCEL_DELETE_REPORT_DATA';
import { createImage, getImageUrl } from './images';
import _ from 'lodash';
import { setLoadingState } from './progressIndicator';
import { MessageDialogTypes } from '../components/MessageDialog/MessageDialog';
import { UNAUTHORIZED_HTTP_CODE } from '../service/Error';
import { showModal, TYPES } from './modal';

const getId = () => `uniq${Math.round(Math.random() * 10e6)}`;

function saveReportData(data) {
    return (dispatch, getState) => {
        const { currentUser } = getState().screen;
        const { removed = [], newImages = [] } = data.imagesChanges;

        newImages.forEach((img) => {
            img.needSave = true;
            img.id = getId();
        });

        data.images = data.images.reduce((res, item, pos) => {
            delete item.url;
            if (removed.indexOf(pos) !== -1) {
                if (!item.needSave) {
                    item.isRemoved = true;
                    res.push(item);
                }
            } else {
                res.push(Object.assign(item));
            }

            return res;
        }, []);
        data.images = data.images.concat(newImages);
        const currentDate = new Date();
        const filePrefix = `${moment(currentDate).format('YYMMDD_HHmm')}` + (data.taskNumber ? `_${data.taskNumber}` : '');

        return Promise.all(
            newImages.map((img) => {
                const { id, data, contentType } = img;
                const fileName = `${filePrefix}_${id}`;
                return dispatch(
                    createImage(
                        `si_${id}`,
                        data,
                        contentType,
                        { needCompression: true, rewrite: true, saveLibrary: { saveLibrary: true, fileName } }));
            })
        ).then(() => {
            data.images.forEach((img) => {
                delete img.data;
                delete img.contentType;
                delete img.url;
            });
            data.imagesChanges = undefined;
            data.isEdited = true;

            if (!data.id) {
                data.id = getId();
                data.isNew = true;
                data.userId = currentUser.id;
            }

            return dispatch({
                type: SAVE_REPORT_DATA,
                report: data,
            });
        });
    };
}

function deleteReportData(id) {
    return {
        type: DELETE_REPORT_DATA,
        id,
    };
}

function cancelDeleteReportData(id) {
    return {
        type: CANCEL_DELETE_REPORT_DATA,
        id,
    };
}

function updateReportsAfterSave(reports = {}, deleteError = false) {
    return (dispatch, getState, { apiManager }) => {
        reports &&
            Object.keys(reports).forEach((reportKey) => {
                const report = reports[reportKey] || {};
                Object.keys(report.imagesIds || {}).forEach((key) => {
                    apiManager.renameFile(`si_${key}`, `si_${report.imagesIds[key]}`);
                });
            });

        return dispatch({
            type: UPDATE_REPORTS_AFTER_SAVE,
            reports,
            deleteError,
        });
    };
}

function filterReportsByFlight(legId) {
    return {
        type: FILTER_REPORTS_BY_LEG,
        legId,
    };
}

function preloadReportImageUrl(id) {
    return (dispatch, getState) => {
        const reports = _.get(getState(), 'reports.reports', []);
        const report = reports.find((item) => item.id == id);

        if (report && report.images) {
            report.images.forEach((img) => dispatch(getImageUrl(`si_${img.id}`)));
        }
    };
}

function preloadAllReportImageUrl() {
    return (dispatch, getState) => {
        const reports = _.get(getState(), 'reports.reports', []);
        reports.forEach((report) => {
            if (report.images) {
                report.images.forEach((img) => dispatch(getImageUrl(`si_${img.id}`)));
            }
        });
    };
}

function getUserReports(dateFrom, dateTo) {
    return (dispatch, getState, { apiManager }) => {
        const state = getState();
        const userId = _.get(state, 'login.user.id');
        dispatch(setLoadingState(true, 'Downloading reports...'));

        return apiManager
            .getUserReports(userId, dateFrom, dateTo)
            .then((data) => dispatch(prepareUserReports(data)))
            .then(() => dispatch(setLoadingState(false)))
            .catch((err) => {
                dispatch(setLoadingState(false));
                console.log(err);
                if (err.errorCode === UNAUTHORIZED_HTTP_CODE) {
                    dispatch(showModal(TYPES.RELOGIN, {}));
                    return;
                }
                dispatch(
                    showConfirmDialog({
                        title: 'Error message',
                        message: 'Failed to get data from the server. Please try again later!',
                        btnCancelVisibility: false,
                        options: {
                            type: MessageDialogTypes.ERROR,
                        },
                    })
                );
            });
    };
}
function prepareUserReports(reports) {
    const data = reports.slice();
    const planFlights = [];

    return (dispatch) => {
        return Promise.all(
            reports.map((report) => {
                const apTo = report.airportTakeOff || {};
                const apLa = report.airportLanding || {};
                planFlights.push({
                    id: report.planFlightId,
                    flightName: report.flightName,
                    flightDate: report.flightDate,
                    route: `${(apTo && apTo.code) || ''}-${(apLa && apLa.code) || ''}`,
                    airports: [
                        { id: apTo.id, name: apTo.name },
                        { id: apLa.id, name: apLa.name },
                    ],
                });

                delete report.airportTakeOff;
                delete report.airportLanding;

                return dispatch(prepareUserReportImages(report));
            })
        ).then(() => {
            return dispatch(loadUserReports(data, planFlights));
        });
    };
}

function prepareUserReportImages(report) {
    const PREFIX = 'si_';
    return (dispatch) => {
        return Promise.all(
            report.images.map((img) => {
                const { id, data, contentType } = img;
                if (typeof img === 'object' && (!data || !contentType)) {
                    return;
                }
                return dispatch(createImage(`${PREFIX}${id}`, data, contentType, { createImmediately: true }));
            })
        ).then((imgIds) => {
            report.images = imgIds.map((item) => ({ id: item.substring(PREFIX.length) }));
        });
    };
}

function loadUserReports(reports, planFlights) {
    return {
        type: GET_REPORTS,
        reports,
        planFlights,
    };
}

export {
    SAVE_REPORT_DATA,
    DELETE_REPORT_DATA,
    UPDATE_REPORTS_AFTER_SAVE,
    FILTER_REPORTS_BY_LEG,
    GET_REPORTS,
    CANCEL_DELETE_REPORT_DATA,
    saveReportData,
    deleteReportData,
    updateReportsAfterSave,
    filterReportsByFlight,
    preloadReportImageUrl,
    preloadAllReportImageUrl,
    loadUserReports,
    cancelDeleteReportData,
    getUserReports,
};
