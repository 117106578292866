import _ from 'lodash';
import { setLeg } from './legs';
const SET_CURRENT_FLIGHT = 'SET_CURRENT_FLIGHT';

function setCurrentFlight(flightId) {
    return (dispatch, getState) => {
        const state = getState();
        const currentTaskId = _.get(state, 'screen.currentTask');
        const legs = _.get(state, `tasks.list[${currentTaskId}].flights[${flightId}].legs`);
        const defaultLeg = (legs && Object.keys(legs).length > 0 && _.values(legs).sort((a, b) => (a.etd > b.etd ? 1 : 0))[0]) || {};
        const ofpId = _.get(state, 'sendedData[defaultLeg.id].ofpId', null);

        return dispatch(setLeg(flightId, defaultLeg.id, ofpId));
    };
}

function setDefaultFlight() {
    return (dispatch, getState) => {
        const state = getState();
        const currentTaskId = _.get(state, 'screen.currentTask');
        const flights = _.get(state, `tasks.list[${currentTaskId}].flights`);
        const defaultFlight = flights && Object.keys(flights).length > 0 && _.values(flights).sort((a, b) => (a.date > b.date ? 1 : 0))[0];
        const defaultFlightId = _.get(defaultFlight, 'id');

        return dispatch(setCurrentFlight(defaultFlightId));
    };
}

export { SET_CURRENT_FLIGHT, setCurrentFlight, setDefaultFlight };
