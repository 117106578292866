import React from 'react';
import moment from 'moment';

import { ListItem, ListItemIcon, ListItemText } from '../../../material-ui/List';
import Complete from '../../../../icons/image/Complete';
import { useStyles } from '../useStyles';
import { NestedListItem } from '../../../NestedListItem/NestedListItem';
import { getAttachments } from '../../../../actions/telegrams';
import Document from '../../../../icons/image/Document';

const TelexItem = ({ telegram: { name, mailTime, signExist, attachments }, getStyle, onAttachmentClick, disableAttachments, onClick }) => {
    const classes = useStyles();
    const items = getAttachments(attachments);

    const handleClick = React.useCallback(() => {
        onClick(name);
    }, [name]);

    return (
        <NestedListItem
            showSign={signExist}
            onClick={handleClick}
            style={getStyle(!mailTime)}
            primary={name}
            secondary={mailTime && `${moment.utc(mailTime).format('DD.MM.YYYY HH:mm')}`}
            disabled={!mailTime}
            icon={<Complete className={classes.icon} width={24} height={24} />}
            inset={false}
            showIcon={true}
        >
            {items.map(({ id, disabled, name, extension, changeTime }) => (
                <ListItem
                    key={id}
                    disabled={disabled || disableAttachments}
                    style={getStyle(disabled)}
                    onClick={() => onAttachmentClick(id)}
                    button={true}
                    className={classes.nested}
                >
                    <ListItemIcon>
                        <Document width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText
                        primary={`${name}${extension}`}
                        secondary={changeTime && `${moment.utc(changeTime).format('DD.MM.YYYY HH:mm')}`}
                    />
                </ListItem>
            ))}
        </NestedListItem>
    );
};

TelexItem.defaultProps = {
    telegram: {},
};

export { TelexItem };
