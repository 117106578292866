import React, { useMemo, useCallback, forwardRef, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { useHideModal } from '../../actions/modal';
import { showConfirmDialog } from '../../actions/messageDialog';
import { DateTimePicker } from '../../components/DateTimePicker/DateTimePicker';
import { TimeField } from '../../components/TimeField/TimeField';
import MaterialTextField from '../../components/MaterialTextField/MaterialTextField';
import { DialogControls } from '../../components/DialogControls/DialogControls';
import { getUsersEdgeLeg } from '../../selectors/screen';
import { Dialog, DialogContent, DialogTitle, DialogActions } from '../../components/material-ui/Dialog';
import { useStyles } from './useStyles';
import { Typography } from '../../components/material-ui/Typography';
import { useMobileWidth } from '../../components/hooks/useMobileWidth';
import { getDate } from '../../service/DateTimeUtils';
import { useSummaryCrewModal } from './useSummaryCrewModal';

export const SUMMARY_CREW_LEG = 'SUMMARY_CREW_LEG';

export const SummaryCrewLegModal = memo(
    forwardRef((props, ref) => {
        const { title, text, legId, persId } = props;
        const usersEdgeLeg = useSelector(getUsersEdgeLeg);
        const disableStart = get(usersEdgeLeg[persId], 'start.id') !== legId;
        const disableEnd = get(usersEdgeLeg[persId], 'end.id') !== legId;
        const crew = useSelector((state) => get(state, 'crew', {}));
        const hideModal = useHideModal();
        const dispatch = useDispatch();
        const classes = useStyles();
        const { isMobilePortrait } = useMobileWidth();
        const { data, handleChangeComment, handleChangeField, isValidWorkBegin, handleSave } = useSummaryCrewModal(props, disableStart);

        const handleDisplayWarning = useCallback(() => {
            dispatch(
                showConfirmDialog({
                    title: 'Edit crew duty time error',
                    message: 'START LEG cannot be earlier than the end of the previous working time.',
                    btnCancelVisibility: false,
                })
            );
        }, []);

        const actions = useMemo(
            () => [
                { label: 'Cancel', onClick: hideModal },
                { label: 'OK', color: 'primary', onClick: isValidWorkBegin ? handleSave : handleDisplayWarning },
            ],
            [isValidWorkBegin, hideModal, handleSave, handleDisplayWarning]
        );
        const crewInfo = (crew[persId] && crew[persId].fullNameEng) || '';

        return (
            <Dialog open={true} ref={ref} maxWidth="md" fullScreen={isMobilePortrait}>
                <DialogTitle className={classes.title}>{`Edit crew duty time on Flight ${title}`}</DialogTitle>
                <DialogContent className={classes.content}>
                    <Typography className={classes.crew}>{crewInfo}</Typography>
                    <Typography>{text}</Typography>
                    <div className={classes.dates}>
                        <DateTimePicker
                            name="workBegin"
                            canBeEmpty={false}
                            value={getDate(data.workBegin)}
                            labelTextDate="START LEG"
                            onChange={handleChangeField}
                            disabled={disableStart}
                        />
                        <DateTimePicker
                            name="workEnd"
                            canBeEmpty={false}
                            value={getDate(data.workEnd)}
                            labelTextDate="END LEG"
                            onChange={handleChangeField}
                            disabled={disableEnd}
                        />
                    </div>
                    <div className={classes.dates}>
                        <TimeField
                            key="biologicalNight"
                            name="biologicalNight"
                            onEditField={handleChangeField}
                            defaultValue={data.biologicalNight}
                            className={classes.biologicalNight}
                            label="BIO NIGHT"
                        />
                        <TimeField
                            key="independent"
                            name="independent"
                            onEditField={handleChangeField}
                            defaultValue={data.independent}
                            className={classes.independent}
                            label="INDEPENDENT FLIGHT"
                        />
                    </div>
                    <MaterialTextField
                        InputProps={{
                            classes: { root: classes.commentInput },
                        }}
                        placeholder="Additional info"
                        name="comment"
                        onChange={handleChangeComment}
                        key="comment"
                        label="Additional info"
                        fullWidth={true}
                        multiline={true}
                        value={data.comment}
                        className={classes.comment}
                        minRows={1}
                        maxRows={5}
                    />
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <DialogControls actions={actions} />
                </DialogActions>
            </Dialog>
        );
    })
);
