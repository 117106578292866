import { makeStyles } from '@material-ui/core/styles';
import { darkFullWhite } from '../../../service/getMuiThemeStyle';

const styles = {
    default: {},
    success: { backgroundColor: '#43a047' },
    error: { backgroundColor: '#d32f2f' },
    warning: { backgroundColor: '#ffa000' },
    info: { backgroundColor: '#2979ff' },
};

const useStyles = (props) =>
    makeStyles((theme) => {
        return {
            card: {
                width: '100%',
                pointerEvents: 'auto',

                ['@media (min-width:370px)']: {
                    maxWidth: 400,
                    minWidth: 344,
                },
            },
            actionRoot: {
                position: 'relative',
                padding: '8px 8px 8px 16px',
                color: 'white',
                ...styles[props.variant],
            },
            icons: {
                flex: '0 0 80px',
                marginLeft: 'auto',
                textAlign: 'right',
            },
            expand: {
                padding: '8px 8px',
                transform: 'rotate(0deg)',
                transition: theme.transitions.create('transform', {
                    duration: theme.transitions.duration.shortest,
                }),
            },
            expandOpen: {
                transform: 'rotate(180deg)',
            },
            collapse: {
                padding: 16,
                whiteSpace: 'pre-wrap',
            },
            checkIcon: {
                fontSize: 20,
                color: '#b3b3b3',
                paddingRight: 4,
            },
            iconWrapper: {
                position: 'absolute',
                left: '16px',
                top: '16px',
            },
            icon: {
                fill: darkFullWhite,
            },
            title: {
                paddingLeft: '30px',
                flexGrow: 1,
                color: darkFullWhite,

                fontSize: 18,
            },
            text: {
                overflowX: 'auto',
            },
        };
    });

export { useStyles };
