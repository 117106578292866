import React from 'react';

import { Panel } from '../../Panel/Panel';
import { TextFieldStatic } from './TextFieldStatic/TextFieldStatic';
import { fields, getValue } from './tools';

function FlightCard(props) {
    const { flight = {} } = props;

    return (
        <Panel title="Main flight info" className="flight-info__panel  flight-info__panel--main">
            <div className="flight-info__inner">
                {fields.map((item, index) => {
                    const { id, label, full } = item;
                    const onClick = id === 'status' ? () => alert(flight['statusName']) : () => {};
                    const value = getValue(props, id);
                    let className = 'text-field  flight-info__text-field';
                    if (full) {
                        className += '  flight-info__text-field--full';
                    }

                    return value ? <TextFieldStatic key={index} label={label} value={value} onClick={onClick} className={className} /> : '';
                })}
            </div>
        </Panel>
    );
}

export { FlightCard };
