import React from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { useHideModal } from '../../actions/modal';
import { getRestoreFiles } from '../../actions/login';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../components/material-ui/Dialog';
import { useMobileWidth } from '../../components/hooks/useMobileWidth';
import { List, ListItem, ListItemText } from '../../components/material-ui/List';
import { RestoreFile } from './RestoreFile';
import { DialogControls } from '../../components/DialogControls/DialogControls';
import { useStyles } from './useStyles';

export const RESTORE = 'RESTORE';

const RestoreModal = React.forwardRef((props, ref) => {
    const classes = useStyles();
    const restoreFiles = useSelector((state) => get(state, 'restoreFiles', []));
    const dispatch = useDispatch();
    const { isMobilePortrait } = useMobileWidth();
    const hideModal = useHideModal();
    const [selectedIndex, changeSelectedIndex] = React.useState(null);

    React.useEffect(() => {
        // TODO показывать ошибку при загрузке списка
        dispatch(getRestoreFiles());
    }, []);

    const isFilesExists = restoreFiles && restoreFiles.length > 0;

    const handleRestore = React.useCallback((fileName) => {
        if (fileName) {
            // dispatch(restoreFromFile(fileName));
        }
        hideModal();
    }, []);

    const actions = React.useMemo(
        () => [
            { label: 'CANCEL', onClick: hideModal },
            { label: 'OK', color: 'primary', type: 'submit', disabled: true, onClick: handleRestore },
            //disabled: !isFilesExists || this.state.selectedIndex === null,
        ],
        []
    );

    return (
        <Dialog ref={ref} open={true} fullScreen={isMobilePortrait} classes={{ paper: classes.paper }}>
            <DialogTitle className={classes.title}>Select backup files for restore application</DialogTitle>
            <DialogContent>
                <List>
                    {(isFilesExists &&
                        restoreFiles.map((file, index) => (
                            <RestoreFile
                                key={`${file}_index`}
                                {...{ file, index }}
                                selected={selectedIndex === index}
                                onSelect={changeSelectedIndex}
                                onRestore={handleRestore}
                            />
                        ))) || (
                        <ListItem key={0}>
                            <ListItemText primary="No records" />
                        </ListItem>
                    )}
                </List>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <DialogControls actions={actions} />
            </DialogActions>
        </Dialog>
    );
});

export { RestoreModal };
