import React from 'react';
import { get } from 'lodash';
import { useTheme } from '@material-ui/core/styles';

import { getPointFontSize } from '../../../../service/NavPointsUtils';
import { AlternateRouteHeadRow } from '../AlternateRouteHeadRow/AlternateRouteHeadRow';
import { Typography } from '../../../../components/material-ui/Typography';

const AlternateRouteHead = (props) => {
    const { mobileEnrouteMode, columnsDeclaration } = props;
    const theme = useTheme();
    const columns = React.useMemo(
        () =>
            columnsDeclaration.filter(
                (item) => (!item.hidden && ((item.mobile && mobileEnrouteMode) || !mobileEnrouteMode)) || !item.items
            ),
        [columnsDeclaration]
    );
    const fontSize = getPointFontSize(mobileEnrouteMode, columns);

    return (
        <tbody className="enr__table-inner">
            <AlternateRouteHeadRow {...{ columns, mobileEnrouteMode }} index={0} />
            <tr className="enr__table-row">
                {columns.map(({ items }, pos) => {
                    const col = items && items[1];
                    const key = get(col, 'id', pos);
                    const title = get(col, 'title', '');

                    return (
                        <th
                            className={`enr__table-cell enr__table-cell_key_${key}`}
                            key={key}
                            style={{
                                width: 'inherit',
                                color: theme.palette.primary3Color,
                                fontSize,
                            }}
                        >
                            <Typography style={{ color: 'inherit' }}>{title}</Typography>
                        </th>
                    );
                })}
            </tr>
            <AlternateRouteHeadRow {...{ columns, mobileEnrouteMode }} index={2} />
        </tbody>
    );
};

export { AlternateRouteHead };
