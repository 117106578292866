import { UPDATE_TELEX } from '../actions/tasks';
import { LOGOUT } from '../actions/screen';
import { SIGN_TELEX } from '../actions/telex';
import { CLEAR_SENDED_DATA_LIST } from '../actions/sended-data';

const initialState = null;

export default (state = initialState, action) => {
    let newState = state ? { ...state } : null;

    switch (action.type) {
        case CLEAR_SENDED_DATA_LIST: {
            if (!newState) {
                return newState;
            }
            const { removedTelegrams = [] } = action;

            removedTelegrams.forEach((id) => {
                delete newState[id];
            });

            return newState;
        }

        case LOGOUT: {
            return initialState;
        }

        case SIGN_TELEX: {
            if (action.signatureName) {
                newState = newState ? newState : {};
                newState[action.id] = { ...newState[action.id], signatureName: action.signatureName };
            }

            return newState;
        }

        case UPDATE_TELEX: {
            if (action.data.length) {
                newState = newState ? newState : {};
                action.data.forEach(({ id, pfacnID, cfpNumber, mailTime, name, changeTime, attachments, signature }) => {
                    newState[id] = {
                        id,
                        mailTime,
                        name,
                        changeTime,
                        attachments,
                        pfacnID,
                        cfpNumber,
                        signature,
                    };
                });
            }

            return newState;
        }

        default: {
            return state;
        }
    }
};
