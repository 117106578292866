import React, { useEffect, useContext } from 'react';
import { isUndefined, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { CssBaseline } from '@material-ui/core';

import { AppBar } from '../AppBar/AppBar';
import { NotificationSidebar } from '../NotificationSidebar/NotificationSidebar';
import { MessageDialog } from '../MessageDialog/MessageDialog';
import { ProgressIndicatorOverlay } from '../Progress/ProgressIndicatorOverlay/ProgressIndicatorOverlay';
import { ProgressIndicatorLinear } from '../Progress/ProgressIndicatorLinear/ProgressIndicatorLinear';
import { AttachmentSidebar } from '../AttachmentSidebar/AttachmentSidebar';
import { FilterSidebar } from '../FilterSidebar/FilterSidebar';
import { getCurrentFlight } from '../../selectors/screen';
import { setCurrentTask } from '../../actions/tasks';
import { filterReportsByFlight } from '../../actions/reports';
import { setCurrentFlight } from '../../actions/flights';
import { redirectPage } from '../../actions/screen';
import { FlightToolbar } from '../FlightToolbar/FlightToolbar';
import { Sidebar } from '../Sidebar/Sidebar';
import { Paper } from '../material-ui/Paper';
import { setHtmlOverflow } from '../hooks/useScrollFix';
import { useBackButton } from '../hooks/useBackButton';
import { FlightWizardStepper } from '../FlightWizardPage/FlightWizardStepper/FlightWizardStepper';
import { setInitialOfp } from '../../actions/systemOfMeasurement';
import { SidebarContext } from './ContextProvider';

import './PageLayout.scss';

const routesWithoutFlightTask = ['reports', 'library'];
const routesWithoutToolbar = ['summary-task'];

const getContentStyle = (toolbarVisibility, showStepper) => {
    const TOOLBAR_HEIGHT = 46;
    const STEPPER_HEIGHT = 56;
    let padding = 64;
    if (toolbarVisibility) {
        padding += TOOLBAR_HEIGHT;
    }

    if (showStepper) {
        padding += STEPPER_HEIGHT;
    }

    return {
        paddingTop: `${padding}px`,
    };
};

export const PageLayout = React.memo((props) => {
    const currentFlight = useSelector(getCurrentFlight);
    const pathname = useSelector((state) => get(state, 'router.location.pathname'));
    const needHideToolbar = routesWithoutToolbar.indexOf(pathname.substring(1)) + 1;
    const needHideStepper = pathname.substring(1) !== 'flight-info';
    const showStepper = pathname === '/flight-info';
    const toolbarVisibility = !isUndefined(currentFlight) && !needHideToolbar;
    const {
        sidebarVisibility: {
            notificationSidebarVisibility,
            navigationPanelVisibility,
            attachmentSidebarVisibility,
            filterSidebarVisibility,
        },
        handleCloseSidebar,
    } = useContext(SidebarContext);

    const dispatch = useDispatch();
    const disableOverflow =
        notificationSidebarVisibility || navigationPanelVisibility || attachmentSidebarVisibility || filterSidebarVisibility || false;
    useEffect(() => {
        setHtmlOverflow(disableOverflow);
    }, [disableOverflow]);

    useBackButton(disableOverflow);

    const handleClickFlight = React.useCallback((flightId, path) => {
        dispatch(setCurrentFlight(flightId));
        dispatch(redirectPage(path));
        handleCloseSidebar();
    }, []);

    const handleToggleSendedDataDialog = React.useCallback(() => {
        props.onToggleSendedDataDialog();
        handleCloseSidebar();
    }, []);

    const handleRedirectPage = (path) => {
        if (path === 'flight-task') {
            dispatch(setCurrentTask());
            dispatch(setInitialOfp());
        }
        if (path === 'reports') {
            dispatch(filterReportsByFlight());
        }
        if (routesWithoutFlightTask.indexOf(path) !== -1) {
            dispatch(setCurrentFlight());
        }
        dispatch(redirectPage(path));
    };

    const handleClickFlightTask = React.useCallback(() => {
        handleRedirectPage('flight-task');
        handleCloseSidebar();
    }, []);

    const handleClickSafetyReports = React.useCallback(() => {
        handleRedirectPage('reports');
        handleCloseSidebar();
    }, []);

    const handleClickLibrary = React.useCallback(() => {
        handleRedirectPage('library');
        handleCloseSidebar();
    }, []);

    const handleClickSummaryTask = React.useCallback(() => {
        handleRedirectPage('summary-task');
        handleCloseSidebar();
    }, []);

    return (
        // <div className={classnames('page-layout', classes.paper)}>
        //     <div className={classes.inner}>
        <>
            <CssBaseline />
            <ProgressIndicatorOverlay />
            <AppBar />
            <Sidebar
                onClickFlight={handleClickFlight}
                onSendData={handleToggleSendedDataDialog}
                onClickFlightTask={handleClickFlightTask}
                onClickSafetyReports={handleClickSafetyReports}
                onClickSummaryTask={handleClickSummaryTask}
                onClickLibrary={handleClickLibrary}
                onCloseSidebar={handleCloseSidebar}
            />
            <NotificationSidebar
                open={notificationSidebarVisibility}
                onClickLibrary={handleClickLibrary}
                onCloseSidebar={handleCloseSidebar}
            />
            <AttachmentSidebar />
            {toolbarVisibility ? <FlightToolbar /> : ''}
            {!needHideStepper && <FlightWizardStepper />}
            <Paper className="page-layout__content" style={getContentStyle(toolbarVisibility, showStepper)}>
                <ProgressIndicatorLinear />
                {props.children}
            </Paper>
            <MessageDialog />
            <FilterSidebar />
        </>
    );
});
