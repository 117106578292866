import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
        color: theme.palette.text.primary,
    },
    icon: {
        fill: '#00b600',
    },
    listIcon: { minWidth: 40 },
}));

export { useStyles };
