import React from 'react';
import { useSelector } from 'react-redux';

import { useStyles } from './useStyles';
import { getFlightTaskComments } from '../../../selectors/sended-data';

const FlightTaskComment = () => {
    const classes = useStyles();
    const comments = useSelector(getFlightTaskComments);

    return comments && comments.length > 0 ? (
        <table className={classes.table}>
            <thead>
                <tr className={classes.header}>
                    <th className={classes.headerCell}>Leg</th>
                    <th className={classes.headerCell}>Comment</th>
                </tr>
            </thead>
            {comments.map(({ flight, comment }, index) => (
                <tbody key={index} {...(index % 2 !== 1 && { className: classes.secondaryRow })}>
                    <tr>
                        <td className={classes.flightColumn}>{flight}</td>
                        <td className={classes.itemText}>{comment}</td>
                    </tr>
                </tbody>
            ))}
        </table>
    ) : (
        ''
    );
};

const FlightTaskCommentMemoized = React.memo(FlightTaskComment);

export { FlightTaskCommentMemoized as FlightTaskComment };
