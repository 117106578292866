import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, defaultTo } from 'lodash';
import moment from 'moment';

import { DialogControls } from '../../../components/DialogControls/DialogControls';
import { useHideModal } from '../../../actions/modal';
import { onCheckFuelInfo, saveSpecialPointData } from '../../../actions/navigation';
import { DateTimePicker } from '../../../components/DateTimePicker/DateTimePicker';
import { getCurrentOfp } from '../../../selectors/screen';
import { NavType } from '../../../components/NavigationRoutes/constants';
import { convertFieldsValidator, getDeclarationFieldName, getFieldLabel, getFields, getFieldType, PointFields } from '../tools';
import { usePrevPointPos } from '../hooks';
import { useStyles } from './useStyles';
import { useMobileWidth } from '../../../components/hooks/useMobileWidth';
import { useForm } from '../../../components/hooks/useForm/useForm';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../../components/material-ui/Dialog';
import { FormControl } from '../../../components/material-ui/FormControl';
import { TextField } from '../../../components/material-ui/TextField';
import { Typography } from '../../../components/material-ui/Typography';
import { Radio, RadioGroup } from '../../../components/material-ui/Radio';
import { FormControlLabel } from '../../../components/material-ui/FormControlLabel';
import { MAX_LATITUDE_INTEGER, MAX_LONGITUDE_INTEGER } from '../../../components/hooks/useForm/validate';
import { getFloatCoordinates, getKgValue, getNewPointEstimatedData } from '../../../service/NavPointsUtils';
import { GpsPanel } from './GpsPanel/GpsPanel';
import { useColumnsDeclaration } from '../../../selectors/routesDeclaration';
import { getInitialData } from './tools';
import { useSingleConvert } from '../../../components/hooks/useMeasureConverter';

const fieldList = [
    PointFields.FL,
    PointFields.GS,
    PointFields.FL_DEFAULT,
    PointFields.GS_DEFAULT,
    PointFields.EST,
    PointFields.ETO,
    PointFields.LAT,
    PointFields.LONG,
    PointFields.USED,
    PointFields.ROUTE,
    PointFields.MTK,
    PointFields.ACT,
    PointFields.ATO,
    PointFields.DIST,
    PointFields.DTGO,
    PointFields.COMMENT,
];
const convertedFields = [PointFields.EST, PointFields.USED, PointFields.ACT];

const SpecialPoint = React.forwardRef((props, ref) => {
    const [gpsVisibility, changeGpsVisibility] = React.useState(false);
    const { fuelFlowAvg } = useSelector(getCurrentOfp) || {};
    const columnsDeclaration = useColumnsDeclaration();
    const points = useSelector((state) => get(state, `sendedData[${get(state, 'screen.currentLeg')}].points`, []));
    const { isNewPoint = false, pos, point, title } = props;
    const prevPointPos = usePrevPointPos(points, pos);
    const classes = useStyles();
    const { isMobilePortrait } = useMobileWidth();
    const hideModal = useHideModal();
    const dispatch = useDispatch();
    const handleSuccess = React.useCallback(
        (data) => {
            dispatch(saveSpecialPointData(pos, data, isNewPoint));
            dispatch(onCheckFuelInfo());
            hideModal();
        },
        [pos, isNewPoint]
    );
    const handleGpsToggle = React.useCallback(() => {
        changeGpsVisibility((prevState) => !prevState);
    }, []);

    const fields = React.useMemo(() => getFields(fieldList), []);
    const convert = useSingleConvert();
    React.useMemo(() => convertFieldsValidator(convertedFields, fields, convert), []);

    const { inputs, handleInputChange, handleSubmit, status, setInputs, changeStatus, handleChangeField, validateForm } = useForm({
        onSuccess: handleSuccess,
        initialData: getInitialData(point, fieldList, isNewPoint),
        fields,
    });

    const handleCoordinatesChange = React.useCallback((event) => {
        const { name, label = '', value } = event.target;

        setInputs((inputList) => {
            if ((name === PointFields.LAT && value.length > 7) || (name === PointFields.LONG && value.length > 8)) {
                return inputList;
            }

            const newValue =
                value +
                (((name === PointFields.LAT && value.length === 5 && inputList[name].length < MAX_LATITUDE_INTEGER) ||
                    (name === PointFields.LONG && value.length === 6 && inputList[name].length < MAX_LONGITUDE_INTEGER)) &&
                value.indexOf(',') === -1
                    ? ','
                    : '');

            const error = handleChangeField(name, newValue);
            changeStatus((prevState) => ({ ...prevState, [name]: error }));

            return {
                ...inputList,
                [name]: newValue,
                [`${name}Label`]: label,
            };
        });
    }, []);

    const handleChangeDateTime = React.useCallback((name, value) => {
        handleInputChange({ target: { name, value } });
    }, []);

    const handleBlur = React.useCallback(() => {
        setInputs((inputList) => {
            const { eto, est, used, dist, et, tt } = getNewPointEstimatedData(
                points,
                { ...inputList, lat: getFloatCoordinates(inputList.lat), long: getFloatCoordinates(inputList.long) },
                prevPointPos,
                fuelFlowAvg
            );
            const result = {
                ...inputList,
                eto: eto ? moment.utc(eto) : null,
                est: getKgValue(est),
                used: getKgValue(used),
                dist,
                et,
                tt,
            };
            changeStatus(validateForm(result));

            return result;
        });
    }, [isNewPoint, prevPointPos, fields]);

    const handleSetLocation = React.useCallback(
        ({ lat, long }) => {
            setInputs((inputList) => {
                const { eto, est, used, dist, et, tt } = getNewPointEstimatedData(
                    points,
                    { ...inputList, lat: getFloatCoordinates(lat), long: getFloatCoordinates(long) },
                    prevPointPos,
                    fuelFlowAvg
                );
                const result = {
                    ...inputList,
                    lat,
                    long,
                    eto: eto ? moment.utc(eto) : null,
                    est: getKgValue(est),
                    used: getKgValue(used),
                    dist,
                    et,
                    tt,
                };
                changeStatus(validateForm(result));

                return result;
            });
            handleGpsToggle();
        },
        [isNewPoint, prevPointPos, fields]
    );

    const disableSubmit = Boolean(
        fieldList.find((key) => Object.values(status || {}).some((item) => item) || (fields[key].required && !inputs[key]))
    );

    React.useEffect(() => {
        const fieldErrors = fieldList.reduce((res, key) => {
            const error = handleChangeField(key, inputs[key]);
            if (error) {
                res[key] = error;
            }

            return res;
        }, {});
        changeStatus((prevState) => ({ ...prevState, ...fieldErrors }));
    }, []);

    const actions = React.useMemo(
        () => [
            { label: 'SET LOCATION', onClick: handleGpsToggle },
            { label: 'CANCEL', onClick: hideModal },
            { label: 'OK', color: 'primary', type: 'submit', disabled: disableSubmit },
        ],
        [disableSubmit]
    );

    return (
        <Dialog ref={ref} open={true} fullScreen={isMobilePortrait} classes={{ paper: classes.paper }}>
            <DialogTitle className={classes.title}>{isNewPoint ? 'Add special point' : title}</DialogTitle>
            <form className={classes.fields} onSubmit={handleSubmit}>
                <DialogContent className={classes.content}>
                    <RadioGroup
                        name="type"
                        value={+get(inputs, 'type')}
                        onChange={handleInputChange}
                        className={classes.radioGroup}
                        row={true}
                    >
                        <FormControlLabel
                            key="tp"
                            value={NavType.FLIGHT}
                            control={<Radio color="primary" />}
                            label="TP"
                            className={classes.radio}
                        />
                        <FormControlLabel
                            key="toc"
                            value={NavType.TOC}
                            control={<Radio color="primary" />}
                            label="TOC"
                            className={classes.radio}
                        />
                        <FormControlLabel
                            key="tod"
                            value={NavType.TOD}
                            control={<Radio color="primary" />}
                            label="TOD"
                            className={classes.radio}
                        />
                    </RadioGroup>
                    <FormControl className={classes.formControl}>
                        <TextField
                            name={PointFields.FL}
                            label={getDeclarationFieldName(PointFields.FL, columnsDeclaration)}
                            placeholder={getDeclarationFieldName(PointFields.FL, columnsDeclaration)}
                            onChange={handleInputChange}
                            type={getFieldType(PointFields.FL)}
                            helperText={status[PointFields.FL]}
                            error={Boolean(status[PointFields.FL])}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            value={get(inputs, PointFields.FL, '')}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            name={PointFields.GS}
                            label={getDeclarationFieldName(PointFields.GS, columnsDeclaration)}
                            placeholder={getDeclarationFieldName(PointFields.GS, columnsDeclaration)}
                            onChange={handleInputChange}
                            type={getFieldType(PointFields.GS)}
                            onBlur={handleBlur}
                            helperText={status[PointFields.GS]}
                            error={Boolean(status[PointFields.GS])}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            value={get(inputs, PointFields.GS, '')}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            name={PointFields.EST}
                            label={getDeclarationFieldName(PointFields.EST, columnsDeclaration)}
                            placeholder={getDeclarationFieldName(PointFields.EST, columnsDeclaration)}
                            onChange={handleInputChange}
                            type={getFieldType(PointFields.EST)}
                            helperText={status[PointFields.EST]}
                            error={Boolean(status[PointFields.EST])}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            value={get(inputs, PointFields.EST, '')}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <DateTimePicker
                        className={classes.picker}
                        name={PointFields.ETO}
                        canBeEmpty={true}
                        value={get(inputs, PointFields.ETO)}
                        labelTextTime={getDeclarationFieldName(PointFields.ETO, columnsDeclaration)}
                        onChange={handleChangeDateTime}
                        error={Boolean(status[PointFields.ETO])}
                    />
                    <FormControl className={classes.formControl}>
                        <TextField
                            name={PointFields.LAT}
                            label={getDeclarationFieldName(PointFields.LAT, columnsDeclaration)}
                            placeholder={getDeclarationFieldName(PointFields.LAT, columnsDeclaration)}
                            onChange={handleCoordinatesChange}
                            onBlur={handleBlur}
                            helperText={status[PointFields.LAT]}
                            error={Boolean(status[PointFields.LAT])}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            value={get(inputs, PointFields.LAT, '')}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            name={PointFields.LONG}
                            label={getDeclarationFieldName(PointFields.LONG, columnsDeclaration)}
                            placeholder={getDeclarationFieldName(PointFields.LONG, columnsDeclaration)}
                            onChange={handleCoordinatesChange}
                            onBlur={handleBlur}
                            helperText={status[PointFields.LONG]}
                            error={Boolean(status[PointFields.LONG])}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            value={get(inputs, PointFields.LONG, '')}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            name={PointFields.USED}
                            label={getDeclarationFieldName(PointFields.USED, columnsDeclaration)}
                            placeholder={getDeclarationFieldName(PointFields.USED, columnsDeclaration)}
                            onChange={handleInputChange}
                            type={getFieldType(PointFields.USED)}
                            helperText={status[PointFields.USED]}
                            error={Boolean(status[PointFields.USED])}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            value={get(inputs, PointFields.USED, '')}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <FormControl className={classes.picker}>
                        <TextField
                            label=" "
                            disabled={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            name={PointFields.ROUTE}
                            label={getDeclarationFieldName(PointFields.ROUTE, columnsDeclaration)}
                            placeholder={getDeclarationFieldName(PointFields.ROUTE, columnsDeclaration)}
                            onChange={handleInputChange}
                            helperText={status[PointFields.ROUTE]}
                            error={Boolean(status[PointFields.ROUTE])}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            value={get(inputs, PointFields.ROUTE, '')}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            name={PointFields.MTK}
                            label={getDeclarationFieldName(PointFields.MTK, columnsDeclaration)}
                            placeholder={getDeclarationFieldName(PointFields.MTK, columnsDeclaration)}
                            onChange={handleInputChange}
                            type={getFieldType(PointFields.MTK)}
                            helperText={status[PointFields.MTK]}
                            error={Boolean(status[PointFields.MTK])}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            value={get(inputs, PointFields.MTK, '')}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            name={PointFields.ACT}
                            label={getDeclarationFieldName(PointFields.ACT, columnsDeclaration)}
                            placeholder={getDeclarationFieldName(PointFields.ACT, columnsDeclaration)}
                            onChange={handleInputChange}
                            type={getFieldType(PointFields.ACT)}
                            helperText={status[PointFields.ACT]}
                            error={Boolean(status[PointFields.ACT])}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            value={get(inputs, PointFields.ACT, '')}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <DateTimePicker
                        className={classes.picker}
                        name={PointFields.ATO}
                        canBeEmpty={true}
                        value={get(inputs, PointFields.ATO)}
                        labelTextTime={getDeclarationFieldName(PointFields.ATO, columnsDeclaration)}
                        onChange={handleChangeDateTime}
                        error={Boolean(status[PointFields.ATO])}
                    />
                    <FormControl className={classes.formControl}>
                        <TextField
                            name={PointFields.DIST}
                            label={getDeclarationFieldName(PointFields.DIST, columnsDeclaration)}
                            placeholder={getDeclarationFieldName(PointFields.DIST, columnsDeclaration)}
                            onChange={handleInputChange}
                            type={getFieldType(PointFields.DIST)}
                            helperText={status[PointFields.DIST]}
                            error={Boolean(status[PointFields.DIST])}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            value={defaultTo(get(inputs, PointFields.DIST, ''), '')}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            name={PointFields.DTGO}
                            label={getDeclarationFieldName(PointFields.DTGO, columnsDeclaration)}
                            placeholder={getDeclarationFieldName(PointFields.DTGO, columnsDeclaration)}
                            onChange={handleInputChange}
                            type={getFieldType(PointFields.DTGO)}
                            helperText={status[PointFields.DTGO]}
                            error={Boolean(status[PointFields.DTGO])}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            value={get(inputs, PointFields.DTGO, '')}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            label=" "
                            disabled={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <FormControl className={classes.picker}>
                        <TextField
                            label=" "
                            disabled={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <FormControl className={classes.comment}>
                        <TextField
                            name={PointFields.COMMENT}
                            label={getFieldLabel(PointFields.COMMENT)}
                            onChange={handleInputChange}
                            helperText={status[PointFields.COMMENT]}
                            error={Boolean(status[PointFields.COMMENT])}
                            fullWidth={true}
                            multiline={true}
                            value={get(inputs, PointFields.COMMENT, '')}
                            minRows={1}
                            maxRows={5}
                            className={classes.textarea}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <Typography color="error">{status.message}</Typography>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <DialogControls actions={actions} />
                </DialogActions>
            </form>
            <GpsPanel onOk={handleSetLocation} open={gpsVisibility} onClose={handleGpsToggle} />
        </Dialog>
    );
});

export { SpecialPoint };

// floatingLabelText={this._getDeclarationFieldName('dtgo')}
