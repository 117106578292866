import { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { getPersonnelFlightInfo } from './tools';
import { calculateBufferCrewCustom, changeCrewInfo } from '../../actions/buffer-crew';
import { getPreviousPersonnelFlightsTask } from '../../selectors/screen';
import { useHideModal } from '../../actions/modal';

export const useSummaryCrewModal = (props, disableStart) => {
    const { workBegin, comment, workEnd, workAfter, workBefore, work, independent, legId, persId, biologicalNight } = props;
    const previousPersonnelFlightsTask = useSelector(getPreviousPersonnelFlightsTask);
    const hideModal = useHideModal();
    const dispatch = useDispatch();
    const [data, changeData] = useState({
        independent,
        comment,
        biologicalNight,
        workBegin: workBegin ? moment.utc(workBegin) : null,
        workEnd: workEnd ? moment.utc(workEnd) : null,
    });
    const handleChangeField = useCallback((name, value) => {
        changeData((prevState) => ({ ...prevState, [name]: value }));
    }, []);

    const handleChangeComment = useCallback((event) => {
        changeData((prevState) => ({ ...prevState, comment: event.currentTarget.value }));
    }, []);

    const isValidWorkBegin = useMemo(() => {
        const previousWorkEnd = getPersonnelFlightInfo(previousPersonnelFlightsTask, persId, !disableStart);

        return !previousWorkEnd || !data.workBegin || moment.utc(previousWorkEnd) <= moment.utc(data.workBegin);
    }, [previousPersonnelFlightsTask, persId, disableStart, data.workBegin]);

    const handleSave = useCallback(() => {
        changeData((prevState) => {
            const customData = calculateBufferCrewCustom(prevState, {
                workBegin,
                workAfter,
                workBefore,
                work,
            });

            dispatch(
                changeCrewInfo(persId, legId, {
                    ...prevState,
                    ...customData,
                })
            );
            hideModal();

            return prevState;
        });
    }, []);

    return {
        data,
        handleChangeComment,
        handleChangeField,
        handleSave,
        isValidWorkBegin,
    };
};
