import React from 'react';

import { useStyles } from '../useStyles';

function LoadInfoRow({ item = {} }) {
    const classes = useStyles();

    return (
        <tr>
            <td className={classes.columnMain}>
                <div>
                    <div>{item.airportTakeOff.code}</div>
                    <div>{item.airportLanding.code}</div>
                </div>
            </td>
            <td className={classes.column}>{item.business}</td>
            <td className={classes.column}>{item.adt}</td>
            <td className={classes.column}>{item.chd}</td>
            <td className={classes.column}>{item.inf}</td>
            <td className={classes.column}>{item.baggagePay}</td>
            <td className={classes.column}>{item.mail}</td>
            <td className={classes.column}>{item.cargo}</td>
        </tr>
    );
}

export { LoadInfoRow };
