import React from 'react';

import BaseIcon from '../BaseIcon';

const Passenger = (props) => (
    <BaseIcon {...props} viewBox="0 0 100 100">
        <path
            d="M34.634,29.099c4.759-0.016,8.607-3.866,8.611-8.61c-0.004-4.751-3.852-8.6-8.611-8.612
            c-4.738,0.013-8.586,3.861-8.61,8.612C26.048,25.233,29.896,29.083,34.634,29.099L34.634,29.099z"
        />
        <path d="M34.634,20.489" />
        <path
            d="M28.104,39.53c-1.54-9.885,12.73-12.891,15.012-2.756l3.905,19.383h14.504
            c4.236,0.013,6.435,3.401,6.445,6.533v26.339c-0.011,6.416-9.121,6.383-9.119-0.084c-0.002-6.026,0-20.531,0-20.531H40.318
            c-4.313,0.006-7.021-2.956-7.716-6.362L28.104,39.53L28.104,39.53z"
        />
        <path
            d="M52.066,70.149H39.853c-4.393,0-8.034-2.621-9.118-7.546L26.79,43.347c-0.975-4.691-7.557-3.165-6.702,1.397
            l4.117,20.148c0.653,2.887,2.01,5.377,3.797,7.355l-0.013-0.007v19.168v2.409h2.891h14.545h2.892v-2.409V77.28h3.793  C56.851,77.27,56.868,70.149,52.066,70.149z M45.151,91.409H31.164l1.823-15.461c1.854,0.861,3.849,1.329,5.848,1.332h4.711  L45.151,91.409z"
        />
        <path
            d="M69.452,6.183h-7.451c-5.81,0-10.537,4.727-10.537,10.538v15.108c0,5.811,4.728,10.539,10.537,10.539h7.451
            c5.81,0,10.537-4.728,10.537-10.539V16.721C79.989,10.91,75.262,6.183,69.452,6.183z M62.001,8.996h7.451  c3.454,0,6.397,2.298,7.377,5.441h-6.862v-0.04c0-0.629-0.515-1.144-1.144-1.144h-6.194c-0.628,0-1.143,0.515-1.143,1.144v0.04  h-6.863C55.603,11.294,58.547,8.996,62.001,8.996z M73.448,17.028v14.492c0,2.374-1.931,4.304-4.304,4.304h-6.836  c-2.373,0-4.305-1.931-4.305-4.304V17.028c0-0.414,0.071-0.808,0.185-1.185h15.076C73.378,16.22,73.448,16.615,73.448,17.028z   M77.176,31.829c0,4.249-3.476,7.725-7.724,7.725h-7.451c-4.249,0-7.724-3.477-7.724-7.725V16.721c0-0.297,0.02-0.588,0.053-0.877  h1.929c-0.077,0.385-0.13,0.778-0.13,1.185v14.492c0,3.399,2.781,6.18,6.18,6.18h6.836c3.398,0,6.18-2.78,6.18-6.18V17.028  c0-0.407-0.053-0.799-0.13-1.185h1.929c0.033,0.289,0.053,0.58,0.053,0.877V31.829z"
        />
    </BaseIcon>
);

export default Passenger;
