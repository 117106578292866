import React from 'react';

import { ListItem, ListItemIcon, ListItemText } from '../../material-ui/List';

function SidebarItem(props) {
    const { onClick, className, primary, children, textClassName, iconClassName } = props;

    return (
        <ListItem onClick={onClick} className={className} button={true}>
            <ListItemIcon className={iconClassName}>{children}</ListItemIcon>
            <ListItemText primary={primary} className={textClassName} />
        </ListItem>
    );
}

const SidebarItemMemoized = React.memo(SidebarItem);

export { SidebarItemMemoized as SidebarItem };
