import heic2any from 'heic2any';

function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    let byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    let buffer = Buffer.alloc(byteString.length);

    for (var i = 0; i < buffer.length; ++i) {
        buffer[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    let blob = new Blob([new Uint8Array(buffer)], { type: mimeString });

    return blob;
}

function createObjectURL(blob) {
    return blob ? window.URL.createObjectURL(blob) : null;
}

function dataBufferToBlob(data, contentType) {
    if (!data && !contentType) return;

    return new Blob([new Uint8Array(data)], { type: contentType });
}

function uint8ArrayToBlob(data, contentType) {
    if (!data && !contentType) return;

    return new Blob([data], { type: contentType });
}

function dataBufferToObjectURL(data, contentType) {
    if (!data && !contentType) return;

    return window.URL.createObjectURL(new Blob([new Uint8Array(data.data)], { type: contentType }));
}

function blobToObjectURL(blob) {
    return blob && window.URL.createObjectURL(blob);
}

function dataURItoBuffer(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    let byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    let buffer = Buffer.alloc(byteString.length);

    for (var i = 0; i < buffer.length; ++i) {
        buffer[i] = byteString.charCodeAt(i);
    }

    return {
        data: buffer,
        contentType: mimeString,
    };
}

function getImage(dataUrl) {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = dataUrl;
        image.onload = () => {
            resolve(image);
        };
        image.onerror = (el, err) => {
            reject(err.error);
        };
    });
}

const compressedMimeTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];

function downscaleImage(
    dataUrl,
    imageType, // e.g. 'image/jpeg'
    quality = 0.75, // e.g. 0.8 = 80% quality
    maxResolution = 1680
) {
    if (compressedMimeTypes.indexOf(imageType) === -1) {
        return Promise.resolve({});
    }

    return getImage(dataUrl).then((image) => {
        let width = image.naturalWidth;
        let height = image.naturalHeight;
        const scale = Math.min(1, maxResolution / Math.max(width, height));
        width *= scale;
        height *= scale;

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, width, height);
        const blob = dataURItoBlob(canvas.toDataURL(imageType, quality));
        const url = blobToObjectURL(blob);

        return {
            url,
            blob,
        };
    });
}

function utf8ArrayToStr(array) {
    let out, i, len, c;
    let char2, char3;

    out = '';
    len = array.length;
    i = 0;
    while (i < len) {
        c = array[i++];
        switch (c >> 4) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
                // 0xxxxxxx
                out += String.fromCharCode(c);
                break;
            case 12:
            case 13:
                // 110x xxxx   10xx xxxx
                char2 = array[i++];
                out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
                break;
            case 14:
                // 1110 xxxx  10xx xxxx  10xx xxxx
                char2 = array[i++];
                char3 = array[i++];
                out += String.fromCharCode(((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0));
                break;
        }
    }
    return out;
}

const imageFormatTypes = {
    HEIC: 'heic',
    HEIF: 'heif',
};

function isImageHeic(contentType, fileName) {
    const x = contentType ? contentType.split('image/').pop() : fileName && fileName.split('.').pop().toLowerCase();
    return x === imageFormatTypes.HEIC || x === imageFormatTypes.HEIF;
}

const jpegMimeType = 'image/jpeg';

const convertImageHeic = (blob) =>
    new Promise((resolve) => {
        heic2any({
            blob,
            toType: jpegMimeType,
            quality: 0.5,
        }).then((convertedFile) => {
            resolve({ blob: convertedFile, contentType: convertedFile.type });
        });
    });

export {
    dataURItoBlob,
    blobToObjectURL,
    dataURItoBuffer,
    createObjectURL,
    dataBufferToBlob,
    dataBufferToObjectURL,
    uint8ArrayToBlob,
    downscaleImage,
    utf8ArrayToStr,
    compressedMimeTypes,
    isImageHeic,
    convertImageHeic,
};
