import _ from 'lodash';

const SET_CURRENT_LEG = 'SET_CURRENT_LEG';

function setLeg(flightId, legId, ofpId) {
    return { type: SET_CURRENT_LEG, flightId, legId, ofpId };
}

function findFlight(state, legId) {
    const tasks = state.tasks.list;
    let flight;

    for (let taskId in tasks) {
        flight = _.find(tasks[taskId].flights, (flight) => (flight.legs ? Object.keys(flight.legs).some((leg) => leg == legId) : false));

        if (flight) {
            return flight;
        }
    }
}

function setCurrentLeg(legId) {
    return (dispatch, getState) => {
        const state = getState();
        const flight = findFlight(state, legId);

        return flight && dispatch(setLeg(flight.id, legId));
    };
}

export { SET_CURRENT_LEG, setCurrentLeg, findFlight, setLeg };
