import React from 'react';
import { get } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Panel } from '../../Panel/Panel.jsx';
import { PaletteForm } from '../../PaletteForm/PaletteForm';
import { TAKEOFF_FIELDS } from '../constants';
import { getCurrentSendedLeg } from '../../../selectors/sended-data';
import { editInformationField } from '../../../actions/take-off';
import { useConvert } from '../../hooks/useMeasureConverter';

const TakeOffPalette = ({ className }) => {
    const dispatch = useDispatch();
    const validationWeightFields = [
        { id: 'tow', type: 'number', validator: { type: 'int', min: 0, max: useConvert(560000) } },
        { id: 'mtow', type: 'number', validator: { type: 'int', min: 0, max: useConvert(560000) } },
    ];

    const { declaration, ...informationForm } = useSelector((state) => {
        const currentLeg = getCurrentSendedLeg(state);
        const takeOff = get(currentLeg, 'takeOff', {});
        const takeOffDeclaration = get(state, 'routesDeclaration.takeOffDeclaration', []);

        // pilotFlight
        const result = {
            ...get(takeOff, 'informationForm', {}),
            declaration: takeOffDeclaration,
        };

        delete result.pilotFlight;

        return result;
    }, shallowEqual);

    const handleChangeField = React.useCallback((name, value) => {
        dispatch(editInformationField(name, value ? value : null));
    }, []);

    return (
        <Panel title="Take Off information" className={className}>
            <PaletteForm
                onEditInformationField={handleChangeField}
                informationForm={informationForm}
                declaration={declaration}
                fields={[...TAKEOFF_FIELDS, ...validationWeightFields]}
            />
        </Panel>
    );
};

const TakeOffMemoized = React.memo(TakeOffPalette);

export { TakeOffMemoized as TakeOffPalette };
