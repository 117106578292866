import React, { memo, useCallback, useMemo } from 'react';

import { useStyles } from './useStyles';

import './LoginForm.scss';
import { useLogin } from '../hooks/useLogin';
import { ErrorContainer } from './ErrorContainer/ErrorContainer';
import { AuthConfirmationTypes, useTwoFactor } from '../hooks/useTwoFactor';
import { LoginStep } from '../hooks/useStep';
import { TwoFactor } from './TwoFactor/TwoFactor';
import { Auth } from './Auth/Auth';
import { SendButton } from './SendButton/SendButton';
import { login as loginAction, SHOW_ERROR } from '../../../actions/login';
import { useDispatch } from 'react-redux';
import { usePushNotification } from '../../../actions/notifications';
import { getErrorMessage } from '../../../service/utils';

const getCurrentStep = (
    step,
    {
        onLoginChange,
        onCodeChange,
        login,
        password,
        confirmationCode,
        dark,
        refreshBlocked,
        sendingInfo,
        changeRefreshBlocked,
        onRequestCode,
        ref,
        isWhiteColor,
    }
) => {
    switch (step) {
        case LoginStep.CHECK_CONFIRMATION:
            return (
                <TwoFactor
                    ref={ref}
                    sendingInfo={sendingInfo}
                    value={confirmationCode}
                    dark={dark}
                    onChange={onCodeChange}
                    changeRefreshBlocked={changeRefreshBlocked}
                    onRequestCode={onRequestCode}
                    refreshBlocked={refreshBlocked}
                    isWhiteColor={isWhiteColor}
                />
            );
        default:
            return <Auth login={login} password={password} dark={dark} onChange={onLoginChange} />;
    }
};

export const LoginForm = memo(({ onLogin, step, showStep, error, dark = true, isWhiteColor }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const pushNotification = usePushNotification();
    const { login, password, onLoginChange } = useLogin();
    const {
        confirmationCode,
        onCodeChange,
        refreshBlocked,
        sendingInfo,
        confirmationCodeRef,
        changeRefreshBlocked,
        setCode,
        changeSendingInfo,
    } = useTwoFactor();

    const handleAuthErrors = useCallback(
        async (info) => {
            const error = info.text ? await JSON.parse(info.text) : null;
            if (error) {
                if (error.info && error.info.type === AuthConfirmationTypes.EMAIL) {
                    showStep(LoginStep.CHECK_CONFIRMATION);
                    if (error.info && error.info.sendingInfo) {
                        changeSendingInfo(error.info.sendingInfo);
                    }
                    changeRefreshBlocked(true);
                    if (confirmationCodeRef && confirmationCodeRef.current) {
                        confirmationCodeRef.current.focus();
                    }

                    return;
                }
                if (error.info && error.info.type) {
                    return dispatch({
                        type: SHOW_ERROR,
                        error: getErrorMessage('', error.info.type),
                    });
                }
                if (error.code) {
                    return dispatch({
                        type: SHOW_ERROR,
                        error: getErrorMessage(error.message || '', error.code),
                    });
                }

                return dispatch({
                    type: SHOW_ERROR,
                    error: getErrorMessage(error),
                });
            }

            return dispatch({ type: SHOW_ERROR, error: 'There is no connection to the server' });
        },
        [confirmationCodeRef, confirmationCodeRef.current, changeRefreshBlocked, changeSendingInfo, pushNotification, showStep]
    );

    const handleRequestCode = useCallback(() => {
        dispatch(loginAction({ login, password, onError: handleAuthErrors }));
        setCode('');
        dispatch({
            type: SHOW_ERROR,
            error: '',
        });
        changeRefreshBlocked(true);
    }, [login, password, handleAuthErrors]);

    const handleLoginClick = useCallback(
        () => onLogin(login, password, confirmationCode, handleAuthErrors),
        [onLogin, login, password, confirmationCode, handleAuthErrors]
    );
    const disabled = useMemo(() => step === LoginStep.CHECK_CONFIRMATION && !confirmationCode, [step, confirmationCode]);

    return (
        <form className={classes.form} noValidate={true} autoComplete="off">
            {error && <ErrorContainer error={error} />}
            {getCurrentStep(step, {
                onLoginChange,
                onCodeChange,
                login,
                password,
                confirmationCode,
                dark,
                refreshBlocked,
                sendingInfo,
                changeRefreshBlocked,
                isWhiteColor,
                onRequestCode: handleRequestCode,
                ref: confirmationCodeRef,
            })}
            <SendButton step={step} onClick={handleLoginClick} disabled={disabled} />
        </form>
    );
});
