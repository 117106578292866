import file from './file';

function show(fileName) {
    return file.read(`${fileName}.pdf`).then((blob) => {
        window.open(URL.createObjectURL(blob));
    });
}

export default {
    show,
};
