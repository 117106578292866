import { FieldTypes, ValidatorTypes } from '../../components/hooks/useForm/validate';
import { getCoordinates, getFloatCoordinates, getKgValue, setKgValue } from '../../service/NavPointsUtils';

export const LONGITUDE_LIMIT = 180;
export const LATITUDE_LIMIT = 90;

export const PointFields = {
    ACT: 'act',
    ATO: 'ato',
    CAPTAIN_ALTIMETER: 'captainAltimeter',
    COMMENT: 'comment',
    DIST: 'dist',
    DTGO: 'dtgo',
    DATE_CREATE: 'dateCreate',
    END_HOLD: 'endHoldTime',
    ETO: 'eto',
    EST: 'est',
    FIRST_OFFICER_ALTIMETER: 'firstOfficerAltimeter',
    FL: 'fl',
    GS: 'gs',
    FL_DEFAULT: 'fl_default',
    GS_DEFAULT: 'gs_default',
    LAT: 'lat',
    LONG: 'long',
    MTK: 'mtk',
    ROUTE: 'route',
    STANDBY_ALTIMETER: 'standbyAltimeter',
    START_HOLD: 'startHoldTime',
    USED: 'used',
};

export const PointConfig = {
    [PointFields.ACT]: {
        title: 'ACT',
        type: FieldTypes.NUMBER,
        required: false,
        validator: { type: ValidatorTypes.INT, min: 0, max: 296000 },
        preload: getKgValue,
        upload: setKgValue,
    },
    [PointFields.ATO]: { title: 'ATO', type: FieldTypes.MOMENT, required: false },
    [PointFields.CAPTAIN_ALTIMETER]: {
        title: 'Captain altimeter',
        type: FieldTypes.NUMBER,
        required: true,
        validator: { type: ValidatorTypes.INT, min: 0, max: 60000 },
    },
    [PointFields.COMMENT]: { title: 'Comment', type: FieldTypes.STRING, required: false },
    [PointFields.DATE_CREATE]: { title: 'Date', type: FieldTypes.MOMENT, required: true },
    [PointFields.DIST]: {
        title: 'DIST',
        type: FieldTypes.NUMBER,
        validator: { type: ValidatorTypes.INT, min: 0, max: 1000 },
    },
    [PointFields.DTGO]: {
        title: 'DTGO',
        type: FieldTypes.NUMBER,
        required: false,
        validator: { type: ValidatorTypes.INT, min: 0, max: 40000 },
    },
    [PointFields.END_HOLD]: { title: 'End hold', type: FieldTypes.MOMENT, required: false },
    [PointFields.EST]: {
        title: 'EST',
        type: FieldTypes.NUMBER,
        required: true,
        validator: { type: ValidatorTypes.INT, min: 0, max: 296000 },
        preload: getKgValue,
        upload: setKgValue,
    },
    [PointFields.ETO]: { title: 'ETO', type: FieldTypes.MOMENT, required: true },
    [PointFields.FIRST_OFFICER_ALTIMETER]: {
        title: 'FO altimeter',
        type: FieldTypes.NUMBER,
        required: true,
        validator: { type: ValidatorTypes.INT, min: 0, max: 60000 },
    },
    [PointFields.FL]: {
        title: 'FL',
        type: FieldTypes.NUMBER,
        required: false,
        validator: { type: ValidatorTypes.SMALLINT, min: 0, max: 600 },
    },
    [PointFields.FL_DEFAULT]: {
        title: 'FL_DEFAULT',
        type: FieldTypes.NUMBER,
        required: false,
        validator: { type: ValidatorTypes.SMALLINT, min: 0, max: 600 },
    },
    [PointFields.GS]: {
        title: 'GS',
        type: FieldTypes.NUMBER,
        required: false,
        validator: { type: ValidatorTypes.SMALLINT, min: 0 },
    },
    [PointFields.GS_DEFAULT]: {
        title: 'GS_DEFAULT',
        type: FieldTypes.NUMBER,
        required: false,
        validator: { type: ValidatorTypes.SMALLINT, min: 0 },
    },
    [PointFields.LAT]: {
        title: 'lat',
        type: FieldTypes.STRING,
        required: true,
        validator: { type: ValidatorTypes.LATITUDE, precision: [12, 8], min: -LATITUDE_LIMIT, max: LATITUDE_LIMIT },
        preload: (val) => getCoordinates(val, 'lat'),
        upload: getFloatCoordinates,
    },
    [PointFields.LONG]: {
        title: 'long',
        type: FieldTypes.STRING,
        required: true,
        validator: { type: ValidatorTypes.LONGITUDE, precision: [12, 8], min: -LONGITUDE_LIMIT, max: LONGITUDE_LIMIT },
        preload: (val) => getCoordinates(val, 'long'),
        upload: getFloatCoordinates,
    },
    [PointFields.MTK]: {
        title: 'MTK',
        type: FieldTypes.NUMBER,
        required: false,
        validator: { type: ValidatorTypes.SMALLINT },
    },
    [PointFields.ROUTE]: {
        title: 'route',
        type: FieldTypes.STRING,
        required: false,
        validator: { type: ValidatorTypes.TEXT, length: 80 },
    },
    [PointFields.STANDBY_ALTIMETER]: {
        title: 'Standby altimeter',
        type: FieldTypes.NUMBER,
        required: false,
        validator: { type: ValidatorTypes.INT, min: 0, max: 60000 },
    },
    [PointFields.START_HOLD]: { title: 'Start hold', type: FieldTypes.MOMENT, required: false },
    [PointFields.USED]: {
        title: 'USED',
        type: FieldTypes.NUMBER,
        required: true,
        validator: { type: ValidatorTypes.INT, min: 0, max: 296000 },
        preload: getKgValue,
        upload: setKgValue,
    },
};

export const getFieldType = (name) => (PointConfig[name] && PointConfig[name].type) || 'text';

export const getFieldLabel = (name) => (PointConfig[name] && PointConfig[name].title) || '';

export const getFields = (arr) =>
    arr.reduce((res, fieldName) => {
        res[fieldName] = { ...PointConfig[fieldName] };

        return res;
    }, {});

export const getFieldValue = (event, value) => {
    const fieldName = event.target.name;
    const fieldType = event.currentTarget.type || 'text';
    let fieldValue = value || event.currentTarget.value;
    switch (fieldType) {
        case 'number':
            fieldValue = (fieldValue !== null && fieldValue !== undefined && !isNaN(+fieldValue) && +fieldValue) || null;
    }

    return {
        fieldName,
        fieldValue,
    };
};

export const getDeclarationFieldName = (id, columnsDeclaration) => {
    return columnsDeclaration[id] || id.toUpperCase();
};

// Копируем список полей из точки
export const clonePoint = (fields, point) => {
    return fields.reduce((res, key) => {
        res[key] = point[key];

        return res;
    }, {});
};

export const convertFieldsValidator = (convertedFields, fields, convert) => {
    convertedFields.forEach((fieldName) => {
        if (fields[fieldName]) {
            fields[fieldName].validator = {
                ...fields[fieldName].validator,
                max: convert(fields[fieldName].validator.max),
            };
        }
    });
};
