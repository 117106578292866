import { LOGOUT } from '../actions/screen';
import { GET_VERSION } from '../actions/tasks';
import version from '../service/version';

const initialState = { application: version.toString() };

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_VERSION:
            return {
                ...state,
                ...action.version,
            };

        case LOGOUT:
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
