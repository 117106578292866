import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    const {
        textField: { textColor, textFontSize },
    } = theme;

    return {
        actions: {
            padding: theme.spacing(2),

            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(1),
            },
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(0, 2),

            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(0, 1),
            },
        },
        errorText: {
            color: '#f00',
            fontSize: textFontSize,
            marginBottom: '12px',
        },
        field: {
            width: '100%',
        },
        fields: {
            display: 'flex',
            margin: theme.spacing(1, 0),

            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
            },
        },
        text: {
            color: textColor,
            fontSize: textFontSize,
            marginBottom: '12px',
        },
        title: {
            padding: theme.spacing(2),

            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(1),
            },
        },
    };
});

export { useStyles };
