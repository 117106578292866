import React from 'react';
import moment from 'moment';
import classnames from 'classnames';

import { Document, Leg, Pilot, Route, Passenger } from '../../../icons';
import { ListItem, ListItemText, ListItemIcon } from '../../material-ui/List';
import { CrewTypes } from '../../../service/constants';
import { useStyles } from './useStyles';
import { NestedListItem } from '../../NestedListItem/NestedListItem';

export const Status = {
    NONE: 0,
    EDIT: 1,
    SEND: 2,
};

const getLegColor = (classes, status) => {
    switch (status) {
        case Status.SEND:
            return classes.statusSend;
        case Status.EDIT:
            return classes.statusEdit;
    }

    return '';
};

const SidebarLegItem = ({ leg, onClickSidebarItem, status, crewType }) => {
    const classes = useStyles();
    let { takeOffDate, airportTakeOff, airportLanding, plnName = '' } = leg;

    const handleAirportDocumentClick = React.useCallback(() => {
        onClickSidebarItem(leg.id, 'documents');
    }, [onClickSidebarItem, leg.id]);

    const handleFlightInfoClick = React.useCallback(() => {
        onClickSidebarItem(leg.id, 'flight-info');
    }, [onClickSidebarItem, leg.id]);

    const handlePassengerListClick = React.useCallback(() => {
        onClickSidebarItem(leg.id, 'passengers');
    }, [onClickSidebarItem, leg.id]);

    const handleCrewClick = React.useCallback(() => {
        onClickSidebarItem(leg.id, 'crew');
    }, [onClickSidebarItem, leg.id]);

    return (
        <NestedListItem
            key={2}
            showSign={true}
            inset={false}
            primary={
                <span className={classes.boldText}>
                    {`${airportTakeOff.code}-${airportLanding.code} ${(plnName && ` / ${plnName}`) || ''}`}
                </span>
            }
            secondary={
                <span className={classes.secondaryText}>
                    {`${moment.utc(takeOffDate).format('DD.MM.YYYY ')}`}
                    <span className={classes.boldText}>
                        {`${moment.utc(takeOffDate).format('HH:mm')}`}
                        {leg.eta && ` - ${moment.utc(leg.eta).format('HH:mm')}`}
                    </span>
                </span>
            }
            icon={<Leg width="24" height="24" className={classnames(classes.legIconStyle, getLegColor(classes, status))} />}
            button={true}
            showIcon={true}
        >
            {crewType === CrewTypes.PCT_CABINSTAFF && (
                <>
                    <ListItem key={1} onClick={handleFlightInfoClick} className={classes.nestedItemStyle} button={true}>
                        <ListItemIcon className={classes.listIcon}>
                            <Route width="24" height="24" />
                        </ListItemIcon>
                        <ListItemText primary="Flight info" className={classes.listItemText} />
                    </ListItem>
                </>
            )}
            {crewType === CrewTypes.PCT_SALONSTAFF && (
                <>
                    <ListItem key={1} onClick={handlePassengerListClick} className={classes.nestedItemStyle} button={true}>
                        <ListItemIcon className={classes.listIcon}>
                            <Passenger width="24" height="24" />
                        </ListItemIcon>
                        <ListItemText primary="Passenger list" className={classes.listItemText} />
                    </ListItem>
                    <ListItem key={3} onClick={handleAirportDocumentClick} className={classes.nestedItemStyle} button={true}>
                        <ListItemIcon className={classes.listIcon}>
                            <Document width="24" height="24" />
                        </ListItemIcon>
                        <ListItemText primary="Documents" className={classes.listItemText} />
                    </ListItem>
                </>
            )}
            <ListItem key={2} onClick={handleCrewClick} className={classes.nestedItemStyle} button={true}>
                <ListItemIcon className={classes.listIcon}>
                    <Pilot width="24" height="24" />
                </ListItemIcon>
                <ListItemText primary="Crew" className={classes.listItemText} />
            </ListItem>
        </NestedListItem>
    );
};

const SidebarLegItemMemoized = React.memo(SidebarLegItem);

export { SidebarLegItemMemoized as SidebarLegItem };
