export const NotamAction = {
    0: 'N', // новый
    1: 'R', // замена
    2: 'C', // отмена
};

export const Traffic = {
    IFR: { code: 'IFR', nameEng: 'Instrumental Flight' },
    VFR: { code: 'VFR', nameEng: 'Visual Flight' },
};

export const Scope = {
    0: { code: '-', nameEng: 'Empty scope' },
    1: { code: 'A', nameEng: 'Aerodrome' },
    2: { code: 'E', nameEng: 'En-route information' },
    4: { code: 'W', nameEng: 'Navigational warnings' },
};

export const Purpose = {
    N: { code: 'N', nameEng: 'NOTAM selected for immediate attention' },
    B: { code: 'B', nameEng: 'NOTAM selected for pre-flight info brief' },
    O: { code: 'O', nameEng: 'NOTAM concerning flight operations' },
    M: { code: 'M', nameEng: 'Miscellaneous NOTAM; not subject for a briefing' },
};
