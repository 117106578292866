import _ from 'lodash';
import { showConfirmDialog } from './messageDialog';
import { MessageDialogTypes } from '../components/MessageDialog/MessageDialog';
import { setLoadingState } from './progressIndicator';

const UPDATE_NOTICES = 'UPDATE_NOTICES';
const TOGGLE_FAVORITE_NOTICE = 'TOGGLE_FAVORITE_NOTICE';
const TOGGLE_RECENT_NOTICE = 'TOGGLE_RECENT_NOTICE';
const MARK_AS_READED = 'MARK_AS_READED';
const UPDATE_AIRPORT = 'UPDATE_AIRPORT';
const SET_NOTICE_FILTER = 'SET_NOTICE_FILTER';

function updateRouteAirports(taskId, data) {
    return (dispatch, getState, { apiManager }) => {
        // eslint-disable-line no-unused-vars
        const airportsInState = _.get(getState(), 'notices.airports', {});
        const allowNotices = _.get(getState(), 'routesDeclaration.options.allowNotices', false);
        if (!allowNotices) {
            return;
        }

        const { airports, routesAirports } = Object.keys(data).reduce(
            (res, id) => {
                if (!res.routesAirports[id]) {
                    res.routesAirports[id] = {};
                }
                Object.keys(data[id]).forEach((apId) => {
                    const airport = data[id][apId] || {};
                    const { type, isEmpty, latitude, longitude } = airport;
                    const syncTime = airportsInState[apId] && airportsInState[apId].syncTime;
                    if (!res.airports[apId]) {
                        res.airports[apId] = {
                            syncTime,
                            ...airport,
                        };
                    }

                    if (!res.routesAirports[id][apId]) {
                        res.routesAirports[id][apId] = {
                            id: +apId,
                            type,
                            isEmpty,
                            latitude,
                            longitude,
                        };
                    }

                    return res;
                }, {});

                return res;
            },
            { routesAirports: {}, airports: [] }
        );

        return Promise.all([
            Promise.resolve(routesAirports),
            Promise.resolve(airports),
            ...Object.keys(airports)
                .filter((id) => airports[id] && airports[id].code)
                .map((id) => {
                    const { code, syncTime } = airports[id];

                    return apiManager.getNotice(code, syncTime).then((record) => ({
                        airportId: +id,
                        ...record,
                    }));
                }),
        ]).then(([routesAirports, airportList, ...noticeData]) => {
            const { airports, notices } = noticeData.reduce(
                (res, item) => {
                    const { airportId, data } = item;
                    const { name, nameEng, code, codeIata, region, regionEng, syncTime } = airportList[airportId] || {};

                    res.airports[airportId] = {
                        id: +airportId,
                        name,
                        nameEng,
                        region,
                        regionEng,
                        code,
                        codeIata,
                        syncTime,
                        data: [],
                    };

                    data.forEach((item) => {
                        res.airports[airportId].data.push(item.id);
                        if (!res.notices[item.id]) {
                            res.notices[item.id] = { ...item };
                        }
                    });

                    return res;
                },
                { airports: {}, notices: {} }
            );

            dispatch({
                type: UPDATE_NOTICES,
                taskId,
                routesAirports,
                airports,
                notices,
            });
        });
    };
}

function clearNotices(state, removedLegs) {
    const { routesAirports = {}, airports = {} } = _.get(state, 'notices', {});
    const { removed, other } = Object.keys(routesAirports).reduce(
        (res, key) => {
            const legId = +key;
            const isRemovedLegs = removedLegs.indexOf(legId) !== -1;
            const arrayName = isRemovedLegs ? 'removed' : 'other';
            // Ищем аэропорты удаленных участков и аэропорты оставшихся. То, что есть в первых и нет во вторых - удаляем.
            res[arrayName] = Object.keys(routesAirports[legId]).reduce((res, airportId) => {
                if (res.indexOf(+airportId) === -1) {
                    res.push(+airportId);
                }

                return res;
            }, res[arrayName]);

            return res;
        },
        { removed: [], other: [] }
    );

    const removedAirports = _.without(removed, ...other);

    const removedNotices = removedAirports.reduce((res, airportId) => {
        res = res.concat((airports[airportId] && airports[airportId].data) || []);

        return res;
    }, []);

    // CLEAR_NOTICES
    return {
        removedLegs,
        removedAirports,
        removedNotices,
    };
}

function toggleFavoriteNotice(id) {
    return {
        type: TOGGLE_FAVORITE_NOTICE,
        id,
    };
}

function toggleRecentNotice(id) {
    return {
        type: TOGGLE_RECENT_NOTICE,
        id,
    };
}

function markAsReaded(noticeIds) {
    return {
        type: MARK_AS_READED,
        noticeIds,
    };
}

function setNoticeFilter(data) {
    return {
        type: SET_NOTICE_FILTER,
        data,
    };
}

function toggleViewNotice(name) {
    return (dispatch, getState) => {
        // eslint-disable-line no-unused-vars
        const filter = _.get(getState(), 'notices.filter', {});
        return dispatch({
            type: SET_NOTICE_FILTER,
            data: {
                ...filter,
                [name]: !filter[name],
            },
        });
    };
}

function onAddAirport(legId, code) {
    return (dispatch, getState, { apiManager }) => {
        // eslint-disable-line no-unused-vars
        // const airports = _.get(getState(), 'notices.airports', {});
        // TODO Если есть аэропорт, то не запрашиваем по нему данные, а сразу проверяем НОТАМ.

        return apiManager
            .getAirportInfo(code)
            .then((airport) => {
                if (!airport || !airport.id) {
                    setTimeout(() => {
                        dispatch(
                            showConfirmDialog({
                                title: 'Error message',
                                message: `Airport with ICAO code ${code.toUpperCase()} was not find.`,
                                btnCancelVisibility: false,
                                options: {
                                    type: MessageDialogTypes.ERROR,
                                },
                            })
                        );
                        dispatch(setLoadingState(false));
                    }, 1000);

                    return;
                }

                return Promise.all([
                    Promise.resolve(airport),
                    apiManager.getNotice(code), //, syncTime)
                ]).then(([airport, notices]) => {
                    airport.data = [];
                    notices.data.forEach((item) => {
                        airport.data.push(item.id);
                    });

                    return dispatch({
                        type: UPDATE_AIRPORT,
                        legId: legId,
                        airport: airport,
                        notices: notices.data,
                    });
                });
            })
            .catch((err) => {
                console.log(err);
                setTimeout(() => {
                    dispatch(
                        showConfirmDialog({
                            title: 'Error message',
                            message: 'Failed to get NOTAMs from the server. Please try again later!',
                            btnCancelVisibility: false,
                            options: {
                                type: MessageDialogTypes.ERROR,
                            },
                        })
                    );
                    dispatch(setLoadingState(false));
                }, 1000);
            });
    };
}

export {
    UPDATE_NOTICES,
    TOGGLE_FAVORITE_NOTICE,
    TOGGLE_RECENT_NOTICE,
    MARK_AS_READED,
    UPDATE_AIRPORT,
    SET_NOTICE_FILTER,
    updateRouteAirports,
    clearNotices,
    toggleFavoriteNotice,
    toggleRecentNotice,
    markAsReaded,
    onAddAirport,
    setNoticeFilter,
    toggleViewNotice,
};
