import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => {
    const groupBackgroundColor = 'rgba(66, 66, 66, 0.7)';

    return {
        checkListItem: {
            position: 'relative',
            display: 'flex',
            padding: 18,

            fontSize: 18,
            wordWrap: 'break-word',
            borderTop: `2px solid ${groupBackgroundColor}`,
            borderBottom: `2px solid ${groupBackgroundColor}`,
            borderLeft: `4px solid ${groupBackgroundColor}`,
            borderRight: `4px solid ${groupBackgroundColor}`,
        },
        check: {
            margin: '-14px',
            paddingLeft: '18px',
            paddingRight: '18px',
            alignSelf: 'center',
        },
        checkIcon: {
            color: '#00b600',
        },
        controlValue: {
            textAlign: 'right',
            fontSize: 18,
        },
        controlValueChecked: {
            textAlign: 'right',
            color: '#00b600',
            fontSize: 18,
        },
        itemCell: {
            width: '50%',
        },
        name: {
            fontSize: 18,
        },
        nameChecked: {
            color: '#00b600',
            fontSize: 18,
        },
    };
});
