import React from 'react';

import { FuelPanel } from './FuelPanel/FuelPanel';
import { LoadPanel } from './LoadPanel/LoadPanel';
import { PassengerPanel } from './PassengerPanel/PassengerPanel';
import { AdditionalInfoPanel } from './AdditionalInfoPanel/AdditionalInfoPanel';
import { TotalPanel } from './TotalPanel/TotalPanel';
import { useStyles } from './useStyles';
import { useFieldsDeclaration } from '../../selectors/declaration';

const LoadingPage = () => {
    const classes = useStyles();
    const declaration = useFieldsDeclaration();

    return (
        <div className={classes.info}>
            <div className={classes.inner}>
                <div className={classes.fuel}>
                    <FuelPanel declaration={declaration} />
                    <AdditionalInfoPanel declaration={declaration} />
                </div>
                <TotalPanel declaration={declaration} />
            </div>
            <div className={classes.inner}>
                <PassengerPanel />
                <LoadPanel />
            </div>
        </div>
    );
};

export { LoadingPage };
