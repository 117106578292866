import _ from 'lodash';
import { LOGOUT } from '../actions/screen';
import {
    MARK_AS_READED,
    SET_NOTICE_FILTER,
    TOGGLE_FAVORITE_NOTICE,
    TOGGLE_RECENT_NOTICE,
    UPDATE_AIRPORT,
    UPDATE_NOTICES,
} from '../actions/notices';
import { CLEAR_SENDED_DATA_LIST } from '../actions/sended-data';
const initialState = {
    routesAirports: {},
    airports: {},
    notices: {},
    filter: {
        scope: -1,
        isRecentFiltered: false,
        isFavoritesFiltered: false,
        isNearestFilterd: false,
    },
};

export default (state = initialState, action) => {
    let newState = { ...state };

    switch (action.type) {
        case UPDATE_NOTICES:
            newState.routesAirports = { ...newState.routesAirports };
            newState.airports = { ...newState.airports };
            newState.notices = { ...newState.notices };

            Object.keys(action.routesAirports).forEach((id) => {
                newState.routesAirports[id] = { ...action.routesAirports[id] };
            });

            Object.keys(action.airports).forEach((id) => {
                const oldNoticeList = (newState.airports[id] && newState.airports[id].data) || [];
                newState.airports[id] = {
                    ...action.airports[id],
                    data: _.uniq([...oldNoticeList, ...action.airports[id].data]),
                };
            });

            Object.keys(action.notices).forEach((id) => {
                const { isNew, isFavorite } = newState.notices[id] || { isNew: true, isFavorite: false };
                newState.notices[id] = { ...action.notices[id], isNew, isFavorite };
            });

            return newState;
        case CLEAR_SENDED_DATA_LIST: {
            newState.routesAirports = { ...newState.routesAirports };
            newState.airports = { ...newState.airports };
            newState.notices = { ...newState.notices };

            action.notice.removedLegs.forEach((legId) => {
                delete newState.routesAirports[legId];
            });

            action.notice.removedAirports.forEach((airportId) => {
                delete newState.airports[airportId];
            });

            action.notice.removedNotices.forEach((noticeId) => {
                delete newState.notices[noticeId];
            });

            return newState;
        }
        case TOGGLE_FAVORITE_NOTICE: {
            newState.notices[action.id] = {
                ...newState.notices[action.id],
                isFavorite: !newState.notices[action.id].isFavorite,
            };

            return newState;
        }
        case TOGGLE_RECENT_NOTICE: {
            newState.notices[action.id] = {
                ...newState.notices[action.id],
                isNew: !newState.notices[action.id].isNew,
            };

            return newState;
        }
        case MARK_AS_READED: {
            // newState.notices = [...newState.notices];
            action.noticeIds.forEach((id) => {
                if (newState.notices[id] && newState.notices[id].isNew) {
                    const notice = { ...newState.notices[id] };
                    delete notice.isNew;
                    newState.notices[id] = notice;
                }
            });

            return newState;
        }
        case SET_NOTICE_FILTER: {
            newState.filter = { ...action.data };

            return newState;
        }
        case UPDATE_AIRPORT: {
            const { id, type, isEmpty, ...other } = action.airport;
            if (!newState.routesAirports[action.legId]) {
                newState.routesAirports[action.legId] = {};
            }

            newState.routesAirports[action.legId][id] = {
                id,
                type,
                isEmpty,
            };
            newState.airports[id] = {
                id,
                ...other,
            };

            action.notices.forEach((notice) => {
                newState.notices[notice.id] = {
                    ...notice,
                };
            });

            return newState;
        }
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};
