import React from 'react';

import { useInputs } from '../useInputs';
import { validate, checkField, getInitialData } from './validate';
import { usePushError } from '../../../actions/notifications';

const preloadData = (initialData, fields) => {
    Object.keys(fields).reduce((res, key) => {
        if (fields[key].preload) {
            initialData[key] = fields[key].preload(initialData[key]);
        }
    });

    return initialData;
};

const uploadData = (initialData, fields) => {
    Object.keys(fields).reduce((res, key) => {
        if (fields[key].upload) {
            initialData[key] = fields[key].upload(initialData[key]);
        }
    });

    return initialData;
};

const useForm = (props) => {
    const { onSuccess, params, initialData, reloadToggle, prepareData, fields, onCheckStatus } = props;
    const pushError = usePushError();
    const handleChangeField = React.useCallback((name, value) => checkField(fields, name, value), [fields]);

    const { inputs, handleInputChange, setInputs, status, changeStatus } = useInputs({
        initialData: preloadData(initialData, fields),
        reloadToggle,
        checkField: handleChangeField,
        onCheckStatus,
    });

    const validateForm = React.useCallback((data) => validate(data, handleChangeField), [handleChangeField]);

    const submit = React.useCallback(
        (list, event, options) => {
            const data = {
                ...getInitialData(fields),
                ...params,
                ...(prepareData ? prepareData(list, options) : list),
            };
            const errors = validateForm(data);

            const errorList = Object.values(errors || {});

            if (errorList.length > 0) {
                if (event) {
                    event.preventDefault();
                }

                changeStatus((prevState) => ({ ...prevState, ...errors }));

                return pushError({ header: 'Validation error', error: errorList.join('\n') });
            }

            return onSuccess(uploadData(data, fields));
        },
        [fields, params, validateForm, onSuccess]
    );

    const handleSubmit = (event, options) => {
        if (event) {
            event.preventDefault();
        }
        submit(inputs, event, options);
    };
    return { handleSubmit, handleInputChange, handleChangeField, inputs, setInputs, status, changeStatus, validateForm };
};

export { useForm };
