import 'regenerator-runtime/runtime';
import React from 'react';
import { render } from 'react-dom';
import { createMemoryHistory, createBrowserHistory } from 'history';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import _ from 'lodash';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { composeWithDevTools } from 'redux-devtools-extension';
import moment from 'moment';

import init from '../plugins/init';
import { MomentUTCUtils } from './service/momentUtils';
import { createRootReducer } from './reducers/index';
import ApiManager from './service/ApiManger';
import BackendRequestsFacadeViaFetch from './service/BackendRequestsFacadeViaFetch';
import { SET_SETTINGS, setSettings } from './actions/login';
import { getBackupInterval, StateManager } from './service/StateManager';
import { ErrorLogger } from './service/ErrorLogger';
import ThemeProvider from './components/ThemeProvider';
import { TOGGLE_SNACK_NOTIFICATION } from './actions/screen';
import { SET_DATA_BACKUP_STATUS } from './actions/storeStage';
import { CHANGE_GPS_POSITION, GPS_ERROR, restoreGps, TOGGLE_GPS } from './actions/gps';
import { HIDE_MODAL, SHOW_MODAL } from './actions/modal';
import BackupSingleton from './service/BackupSingleton';
import { TOGGLE_CONFIRM } from './actions/messageDialog';
import { UPDATE_IMAGE_URL } from './actions/images';
import { SET_CURRENT_USER } from './actions/tasks';
import { RootContainer } from './containers/RootContainer';
import { getBackendUrl, getSsl, getUrl } from '../plugins/tools';
import version from './service/version';
import errorLogsSender from './service/ErrorLogsSender';
import { setOnlineStatus } from './actions/online';

import './app.scss';
import { checkVersion } from './service/utils';

window._ = _;

const logger = (stateManager, errorLogger) => (store) => (next) => (action) => {
    // eslint-disable-line
    // console.log('dispatching', action);
    let result = next(action);
    if (BackupSingleton.check()) {
        const userId = _.get(store.getState(), 'login.user.id');
        // console.log('next state', store.getState());
        if (
            action.type !== TOGGLE_SNACK_NOTIFICATION &&
            action.type !== SET_DATA_BACKUP_STATUS &&
            action.type !== TOGGLE_GPS &&
            action.type !== CHANGE_GPS_POSITION &&
            action.type !== GPS_ERROR &&
            action.type !== HIDE_MODAL &&
            action.type !== SHOW_MODAL &&
            action.type !== SET_SETTINGS &&
            action.type !== TOGGLE_CONFIRM &&
            action.type !== UPDATE_IMAGE_URL &&
            action.type !== SET_CURRENT_USER &&
            userId
        ) {
            stateManager.startTimer(errorLogger);
        }
    }
    return result;
};

const initStore = (stateManager, apiManager, plugins, initialState, errorLogger) => {
    const history = process.env.NODE_ENV === 'local' ? createBrowserHistory() : createMemoryHistory('/');

    const store = createStore(
        createRootReducer(history),
        initialState,
        composeWithDevTools({})(
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions
                thunk.withExtraArgument({
                    apiManager,
                    history,
                    plugins,
                    getUrl,
                    errorLogger,
                }),
                logger(stateManager, errorLogger)
            )
        )
    );

    errorLogsSender.init(store, plugins.file);
    stateManager.init(store);
    const backupInterval = stateManager.getBackupInterval();
    store.dispatch(setSettings(getBackendUrl(), getSsl(), backupInterval));
    store.dispatch(restoreGps());
    store.subscribe(() => {
        const { login } = store.getState();
        let backendUrl = getBackendUrl();

        if (backendUrl !== login.backendUrl || getSsl() !== login.ssl) {
            localStorage.setItem('backendUrl', login.backendUrl);
            localStorage.setItem('ssl', login.ssl);
            apiManager.setNewBackendFacade(new BackendRequestsFacadeViaFetch(getUrl()));
        }
        if (login.backupInterval !== backupInterval) {
            localStorage.setItem('backupInterval', getBackupInterval(login.backupInterval));
            stateManager.updateBackupInterval();
        }
        // В WkWebView есть проблемы с инициализацией cookie при первом запуске. Решаем проблему плагином при изменении url
        // if (window.wkWebView) {
        //     console.log('!!!!!!!URL!!!!!!!', getUrl().replace('http://', ''));
        //     window.wkWebView.injectCookie(getUrl().replace('http://', ''));
        // }
        window.addEventListener('online', () => {
            store.dispatch(setOnlineStatus(true));
        });
        window.addEventListener('offline', () => {
            store.dispatch(setOnlineStatus(false));
        });
    });
    if (initialState.router && initialState.router.location) {
        history.replace(initialState.router.location);
    }

    render(
        <Provider store={store}>
            <ThemeProvider>
                <MuiPickersUtilsProvider utils={MomentUTCUtils} moment={moment}>
                    <RootContainer history={history} />
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </Provider>,
        document.getElementById('main')
    );
};

const ITERATION_COUNT = 1;
init().then((plugins) => {
    const errorLogger = new ErrorLogger(plugins.file);
    const backendFacade = new BackendRequestsFacadeViaFetch(getUrl());
    const apiManager = new ApiManager(backendFacade, plugins);
    errorLogger.sendErrorsToMonitoringApi(apiManager);

    const stateManager = new StateManager({
        getItem: plugins.file.readFileAsString,
        setItem: (name, data) => {
            return plugins.file.write(name, new Blob([data], { type: 'text/plain' }));
        },
        getStateFiles: plugins.file.getFilesList,
    });

    const loadState = (iteration) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                try {
                    stateManager
                        .getStateFromStorage(errorLogger, iteration)
                        .then((initialState) => {
                            const appVersion = version.toString();
                            const isActualVersion = checkVersion(appVersion, initialState);
                            const actualState = isActualVersion ? initialState : { version: { application: appVersion } };
                            resolve(initStore(stateManager, apiManager, plugins, actualState, errorLogger));
                            BackupSingleton.on();
                        })
                        .catch((err) => {
                            errorLogger.addErrorManual(err);
                            if (iteration === ITERATION_COUNT) {
                                console.log('Both state files are not loading with error!');
                                resolve(initStore(stateManager, apiManager, plugins, {}, errorLogger));
                            } else {
                                console.log(err);
                                reject();
                            }
                        });
                } catch (err) {
                    errorLogger.addErrorManual(err);
                    errorLogsSender.addLog(err);
                    if (iteration === ITERATION_COUNT) {
                        console.log('Cannot load state from file. Initial new one');
                        resolve(initStore(stateManager, apiManager, plugins, {}, errorLogger));
                    } else {
                        reject();
                    }
                }
            }, 500 * iteration);
        });
    };

    let res = null;
    for (let i = 1; i <= ITERATION_COUNT; i++) {
        if (!res) {
            res = loadState(i);
        } else {
            res = res.catch(() => loadState(i));
        }
    }
});
