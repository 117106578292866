import React from 'react';

import { validateState, getValue, VALIDATION_EMPTY_FIELD } from '../../hooks/useForm/validate';
import MaterialTextField from '../../MaterialTextField/MaterialTextField';
import { useStyles } from './useStyles';

const PassengerInfoField = (props) => {
    const { passengerInfo, recordId, name, defaultValue, editPassengerInfo, onChangeValue, required, validator } = props;
    const classes = useStyles();
    const [errorText, changeError] = React.useState(required && !defaultValue ? 'Field is required' : '');
    const [field, changeField] = React.useState({
        name: '',
        title: '',
        type: 'number',
        validator: { type: 'smallint', min: 0 },
    });

    React.useEffect(() => {
        changeField((prevState) => ({
            ...prevState,
            name,
            title: name,
            validator: { ...(validator || field.validator), isRequired: required },
        }));
    }, [validator, required]);

    const handleInputFocus = React.useCallback(() => {
        if (errorText) {
            changeError('');
        }
    }, []);

    const handleInfoChange = React.useCallback(
        (evt) => {
            const { validator, isRequired = false } = field;
            const fieldValue = evt.currentTarget.value === '' ? undefined : getValue(validator, evt.currentTarget.value);
            const validateRes = validateState(validator, fieldValue, isRequired);

            if (validateRes !== null && validateRes.errorText !== VALIDATION_EMPTY_FIELD) {
                return changeError(validateRes.errorText);
            }

            if (editPassengerInfo) {
                editPassengerInfo(recordId, passengerInfo.cls, passengerInfo.type, evt.target.name, fieldValue);
            }
            if (onChangeValue) {
                onChangeValue(fieldValue);
            }
        },
        [passengerInfo, editPassengerInfo]
    );

    return (
        <MaterialTextField
            label=""
            placeholder="0"
            name={name}
            fullWidth={true}
            defaultValue={defaultValue}
            onBlur={handleInfoChange}
            onFocus={handleInputFocus}
            className={classes.field}
            helperText={errorText}
            error={Boolean(errorText)}
            inputProps={{ className: classes.input }}
            type="number"
        />
    );
};

const PassengerInfoFieldMemoized = React.memo(PassengerInfoField);

export { PassengerInfoFieldMemoized as PassengerInfoField };
