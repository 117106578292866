import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return {
        actions: {
            padding: theme.spacing(2),

            [theme.breakpoints.down('md')]: {
                marginTop: 'auto',
            },

            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(1),
            },
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(0, 2),

            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(0, 1),
            },
        },
        field: {
            width: '100%',
        },
        fields: {
            display: 'flex',
            flexDirection: 'column',

            [theme.breakpoints.down('md')]: {
                flexGrow: 1,
            },
        },
        paper: {
            width: 'calc(100% - 64px)',
            maxWidth: 720,

            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
        title: {
            padding: theme.spacing(2),

            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(1),
            },
        },
    };
});

export { useStyles };
