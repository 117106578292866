export class ErrorLogger {
    constructor(filePlugin) {
        this._filePlugin = filePlugin;
        this._subscribeToUnhandledErrors();
        window.errorLogger = this;
    }

    _subscribeToUnhandledErrors() {
        window.onerror = (err, file = 'app.js', row, count, { stack }) => {
            const time = Date.now().toString();
            this._filePlugin
                .readFileAsString('device')
                .then((content) => {
                    const { userId, version } = JSON.parse(content || {});

                    this._filePlugin.write(
                        `error-${time}`,
                        new Blob([JSON.stringify({ err, file, row, count, stack, time, userId, version })], {
                            type: 'application/json',
                        })
                    );
                })
                .catch(() => {
                    console.log('Device data is undefined');
                    this._filePlugin.write(
                        `error-${time}`,
                        new Blob([JSON.stringify({ err, file, row, count, stack, time })], { type: 'application/json' })
                    );
                });
        };
    }

    addErrorManual(error) {
        const { message: err, file = 'app.js', row, count, stack } = error;
        const time = Date.now().toString();

        this._filePlugin
            .readFileAsString('device')
            .then((content) => {
                const { userId, version } = JSON.parse(content || {});

                this._filePlugin.write(
                    `error-${time}`,
                    new Blob([JSON.stringify({ err, file, row, count, stack, time, userId, version })], { type: 'application/json' })
                );
            })
            .catch(() => {
                console.log('Device data is undefined');
                this._filePlugin.write(
                    `error-${time}`,
                    new Blob([JSON.stringify({ err, file, row, count, stack, time })], { type: 'application/json' })
                );
            });
    }

    sendErrorsToMonitoringApi(apiManager) {
        this._filePlugin.getFilesList().then((files) => {
            return Promise.all(
                files
                    .filter((file) => file.name.indexOf('error') === 0)
                    .map((file) => {
                        const fileName = file.name;
                        this._filePlugin
                            .readFileAsString(fileName)
                            .then((content) => {
                                return apiManager.sendLog(fileName, content);
                            })
                            .catch((error) => console.log(error));
                    })
            );
        });
    }
}
