import React from 'react';

import BaseIcon from '../BaseIcon';

const PilotFlight = (props) => (
    <BaseIcon {...props} viewBox="0 0 512.001 512.001">
        <path
            d="M492.064,192.353l-52.314-47.3c-104.736-93.105-262.734-93.105-367.5,0l-52.312,47.3
            c-13.872,12.319-21.079,30-19.79,48.501c1.274,18.516,10.869,35.024,26.309,45.322l16.64,11.695
            c137.466-53.787,289.497-53.13,425.691,0.077l16.757-11.772c15.439-10.298,25.034-26.807,26.309-45.322
            C513.143,222.353,505.936,204.672,492.064,192.353z M270.999,207.581v19.195h-30v-19.195L161.254,181l9.492-28.447l70.254,23.417
            v-39.194h30v39.194l70.254-23.417L350.746,181L270.999,207.581z"
        />
        <path d="M60.999,323.094v28.228c126.024-44.498,263.976-44.498,390,0v-28.228C325.882,276.131,187.225,275.666,60.999,323.094z" />
        <path
            d="M66.692,381.048c21.974,36.63,105.996,55.728,189.307,55.728s167.333-19.098,189.307-55.728
            C323.183,336.773,188.816,336.773,66.692,381.048z"
        />
    </BaseIcon>
);

export default PilotFlight;
