import React, { memo, useMemo, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { Avatar } from '../../material-ui/Avatar';
import { IconButton } from '../../material-ui/IconButton';
import { Menu, MenuItem } from '../../material-ui/Menu';
import { ListItemIcon } from '../../material-ui/List';
import Person from '../../../icons/image/Person';
import Settings from '../../../icons/image/Settings';
import { AppBarVersion } from '../AppBarVersion';
import Logout from '../../../icons/image/Logout';
import { Typography } from '../../material-ui/Typography';
import { useStyles } from './useStyles';
import { useAnchorEl } from '../../hooks/useAnchorEl';
import { TYPES, useShowModal } from '../../../actions/modal';
import { getCurrentTaskSelector } from '../../../selectors/screen';
import { getImageUrl } from '../../../actions/images';
import { setCurrentUser } from '../../../actions/tasks';

export const AppBarMenu = memo(({ onLogout }) => {
    const dispatch = useDispatch();
    const [anchorEl, handleOpenMenu, handleClose] = useAnchorEl();
    const showModal = useShowModal();
    const classes = useStyles();
    const { crewId, userId, userName, userPreview } = useSelector((state) => {
        const { users: userState } = state;
        const currentTask = getCurrentTaskSelector(state);

        const user = get(state, 'screen.currentUser');
        const users = userState.list || {};
        const crewId = user && user.id && get(users, `[${user.id}].crewId`);

        return {
            crewId,
            users,
            userId: user ? user.id : '',
            userName: user ? user.name : '',
            userPreview: user ? user.preview : '',
            taskUsers: get(currentTask, 'users', []),
        };
    }, shallowEqual);

    const user = useMemo(
        () => ({
            crewId,
            id: userId,
            preview: userPreview,
            name: userName,
        }),
        [crewId, userName, userId, userPreview]
    );

    useEffect(() => {
        if (!user.preview && user.crewId) {
            dispatch(getImageUrl(`img_${user.crewId}`)).then((url) => {
                dispatch(
                    setCurrentUser({
                        id: user.id,
                        preview: url,
                        name: user.name,
                    })
                );
            });
        }
    }, [user]);

    const handleSettingsClick = React.useCallback(() => {
        showModal(TYPES.SETTING, {});
        handleClose();
    }, []);

    return (
        <>
            <IconButton className={classes.icon} onClick={handleOpenMenu}>
                <Avatar className="app-bar-menu__avatar-icon" src={user.preview} icon={<Person width="30" height="30" />} />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <MenuItem className={classes.listItem}>
                    <ListItemIcon style={{ boxSizing: 'border-box' }}>
                        <Person width="24" height="24" />
                    </ListItemIcon>
                    <Typography className={classes.text}>{user.name}</Typography>
                </MenuItem>
                <MenuItem className={classes.listItem} onClick={handleSettingsClick}>
                    <ListItemIcon>
                        <Settings width="24" height="24" />
                    </ListItemIcon>
                    <Typography className={classes.text}>Settings</Typography>
                </MenuItem>
                <AppBarVersion isMenu={true} onLogout={onLogout} />
                <MenuItem className={classes.listItem} onClick={onLogout}>
                    <ListItemIcon>
                        <Logout width="24" height="24" />
                    </ListItemIcon>
                    <Typography className={classes.text}>Sign out</Typography>
                </MenuItem>
            </Menu>
        </>
    );
});
