import React from 'react';
import classnames from 'classnames';

import { validateState, VALIDATION_EMPTY_FIELD } from '../hooks/useForm/validate';
import { TextField } from '../material-ui/TextField';
import { useStyles } from './useStyles';
import { checkExistValue } from '../../service/utils';

const CustomField = (props) => {
    const {
        name,
        validator,
        onEditField,
        onChangeValue,
        label,
        defaultValue,
        value,
        className,
        helperText,
        required,
        type = 'text',
        autoComplete = 'off',
        ...restProps
    } = props;
    const classes = useStyles();
    const [currentValue, changeValue] = React.useState(defaultValue !== undefined && defaultValue !== null ? defaultValue : value);
    const [errorText, changeError] = React.useState((validateState(validator, defaultValue, required) || {}).errorText);

    let customHelperText = errorText || helperText || '';

    // Не показываем текст ошибки, когда поле не заполнено. Только подсвечиваем
    if (customHelperText === VALIDATION_EMPTY_FIELD) {
        customHelperText = '';
    }

    React.useEffect(() => {
        if (checkExistValue(defaultValue)) {
            changeValue(defaultValue);
            changeError((validateState(validator, defaultValue, required) || {}).errorText);
        }
    }, [required, defaultValue, helperText, validator]);

    const InputLabelProps = React.useMemo(
        () => ({
            shrink: true,
            ...props.InputLabelProps,
        }),
        []
    );

    const handleEdit = React.useCallback(
        (evt) => {
            const fieldValue = evt.currentTarget.value === '' ? undefined : evt.currentTarget.value;
            const validateRes = validateState(validator, fieldValue, required);

            if (validateRes === null || validateRes.errorText === VALIDATION_EMPTY_FIELD) {
                onEditField(evt.target.name, fieldValue);

                if (onChangeValue) {
                    onChangeValue(fieldValue);
                }
            } else {
                changeError(validateRes.errorText);
            }
        },
        [required, onEditField, validator]
    );

    const handleChange = React.useCallback((event) => {
        changeValue(event.target.value);
    }, []);

    const handleInputFocus = React.useCallback(() => {
        if (errorText) {
            changeError('');
        }
    }, [errorText]);

    return (
        <TextField
            {...restProps}
            {...{ name, label, className, InputLabelProps, type, autoComplete }}
            value={currentValue}
            FormHelperTextProps={{
                className: classes.helperText,
            }}
            placeholder={label}
            onBlur={handleEdit}
            onChange={handleChange}
            onFocus={handleInputFocus}
            helperText={customHelperText}
            error={Boolean(errorText)}
            fullWidth={true}
            className={classnames(classes.field, className)}
            key={name}
            margin="normal"
        />
    );
};

const CustomFieldMemoized = React.memo(CustomField);

export { CustomFieldMemoized as CustomField };
