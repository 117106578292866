import { UPDATE_DOCUMENTS } from '../actions/tasks';
import { LOGOUT } from '../actions/screen';
import { UPDATE_DOCUMENTS_STATUS } from '../actions/documents';

const initialState = {};

export default (state = initialState, action) => {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case UPDATE_DOCUMENTS:
            action.data.forEach((item) => {
                newState[item.id] = Object.assign({}, item);
            });

            return newState;
        case UPDATE_DOCUMENTS_STATUS:
            action.data.downloaded.forEach((item) => {
                newState[item] = Object.assign({}, newState[item], { isDownloaded: true });
            });

            return newState;
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};
