import React from 'react';

import BaseIcon from '../BaseIcon';

const DownloadAll = (props) => (
    <BaseIcon {...props} viewBox="0 0 433.5 433.5">
        <path d="M395.25,153h-102V0h-153v153h-102l178.5,178.5L395.25,153z M38.25,382.5v51h357v-51H38.25z" />
    </BaseIcon>
);

export default DownloadAll;
