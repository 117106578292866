import React from 'react';
import { Add, Remove } from '@material-ui/icons';

import { CustomSlider } from '../../../components/material-ui/Slider';
import { Grid } from '../../../components/material-ui/Grid';
import { IconButton } from '../../../components/material-ui/IconButton';
import { Typography } from '../../../components/material-ui/Typography';
import { MAX_GPS_DISTANCE, MIN_GPS_DISTANCE } from '../constants';

const styles = {
    distance: {
        fontWeight: 'bold',
        fontSize: '1.3em',
    },
    sliderContainer: { paddingBottom: '5px', alignItems: 'center' },
};

const GpsSlider = (props) => {
    const { onChangeSlider, allowedDistance, onSliderUp, onSliderDown } = props;

    return (
        <>
            <Typography>
                The minimal point detection distance:
                <span style={styles.distance}> {allowedDistance}</span>
            </Typography>
            <Grid container={true} spacing={2} style={styles.sliderContainer}>
                <Grid item={true}>
                    <IconButton color="inherit" style={{ marginLeft: 'auto' }} onClick={onSliderDown}>
                        <Remove />
                    </IconButton>
                </Grid>
                <Grid item={true} xs={true}>
                    <CustomSlider
                        value={allowedDistance}
                        onChange={onChangeSlider}
                        valueLabelDisplay="auto"
                        min={MIN_GPS_DISTANCE}
                        max={MAX_GPS_DISTANCE}
                        step={1}
                    />
                </Grid>
                <Grid item={true}>
                    <IconButton color="inherit" style={{ marginLeft: 'auto' }} onClick={onSliderUp}>
                        <Add />
                    </IconButton>
                </Grid>
            </Grid>
        </>
    );
};

const GpsSliderMemoized = React.memo(GpsSlider);

export { GpsSliderMemoized as GpsSlider };
