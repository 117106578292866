import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { ActionTimeControl } from '../../ActionTimeControl/ActionTimeControl';
import { MoreVertIconContrast } from '../../../icons/MoreVertIconContrast';
import { getCurrentSendedLanding, getCurrentSendedTakeOff, getPreviewWorkTime } from '../../../selectors/sended-data';
import { Engine } from '../../../icons';
import { checkTimeIsCorrect, getTimeFormat } from '../../../service/NavPointsUtils';

const StartEngineTime = ({ className, onChangeTime }) => {
    const { timeWorkBefore, value, timeWorkParking } = useSelector((state) => {
        const takeOff = getCurrentSendedTakeOff(state);
        const landing = getCurrentSendedLanding(state);
        const previewWorkTime = getPreviewWorkTime(state);

        return {
            value: takeOff.startEngineTime,
            timeWorkBefore: previewWorkTime.timeWorkBefore,
            timeWorkParking: getTimeFormat(landing.timeWorkParking),
        };
    }, shallowEqual);

    const startEngineSummary = React.useMemo(() => {
        const res = [];

        if (timeWorkParking) {
            res.push({
                title: 'Parking time ',
                value: timeWorkParking,
                isCorrect: checkTimeIsCorrect(timeWorkParking),
            });
        } else {
            res.push({
                title: 'Pre-flight time ',
                value: timeWorkBefore,
                isCorrect: checkTimeIsCorrect(timeWorkBefore),
            });
        }

        return res;
    }, [timeWorkBefore, timeWorkParking]);

    return (
        <ActionTimeControl
            title="Start engine time"
            buttonName="Start engine"
            state="ENTER_COMPLETED"
            iconButton={<Engine width="30" height="30" />}
            iconMenuImg={<MoreVertIconContrast isContrast={true} />}
            text="Start up on"
            name="startEngineTime"
            value={value}
            summary={startEngineSummary}
            onChange={onChangeTime}
            className={className}
        />
    );
};

const StartEngineTimeMemoized = React.memo(StartEngineTime);

export { StartEngineTimeMemoized as StartEngineTime };
