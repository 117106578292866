import { checkUserDataCache } from './login';
import { SelectionType } from '../modals/Setting/GpsSetting/GpsRouteSelectionType';
import { TimePickerType } from '../components/CustomTimePicker/CustomTimePicker';
import errorLogsSender from '../service/ErrorLogsSender';
import { getErrorMessage } from '../service/utils';
import { MessageType } from '../service/constants';

const GET_ROUTE_DECLARATION = 'GET_ROUTE_DECLARATION';
const SET_CUSTOM_ROUTE_SETTINGS = 'SET_CUSTOM_ROUTE_SETTINGS';

function getRoutesDeclaration() {
    return (dispatch, getState, { apiManager }) => {
        const state = getState();
        if (checkUserDataCache(state)) {
            return Promise.resolve();
        }

        return apiManager
            .downloadRoutesDeclaration()
            .then((routesDeclaration) => dispatch({ type: GET_ROUTE_DECLARATION, routesDeclaration }))
            .catch((err) => {
                console.error(err);
                const newError = new Error(getErrorMessage(err, MessageType.GET_ROUTES_DECLARATIONS), { cause: err });
                errorLogsSender.addLog(newError);
                throw newError;
            });
    };
}

function saveCustomRouteSettings({
    customRouteSettings,
    allowedDistance,
    selectionType = SelectionType.CURRENT,
    timePickerType = TimePickerType.NATIVE,
}) {
    return (dispatch) => {
        return dispatch({ type: SET_CUSTOM_ROUTE_SETTINGS, customRouteSettings, allowedDistance, selectionType, timePickerType });
    };
}

export { GET_ROUTE_DECLARATION, SET_CUSTOM_ROUTE_SETTINGS, getRoutesDeclaration, saveCustomRouteSettings };
