import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { Stepper } from '../../material-ui/Stepper';
import { useStyles } from './useStyles';
import { FlightWizardStep } from './FlightWizardStep/FlightWizardStep';
import { wizardSteps } from '../tools';
import { getCurrentSendedLeg } from '../../../selectors/sended-data';
import { MobileWizardStepper } from './MobileWizardStepper/MobileWizardStepper';

function FlightWizardStepper({ activeStep }) {
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width: 660px)');
    const isNextStepDisabled = useSelector((state) => {
        const leg = getCurrentSendedLeg(state);
        const takeOff = get(leg, 'takeOff', {});
        const ofpId = get(leg, 'ofpId');
        const isNextStepDisabled = !ofpId || !takeOff.startWorkTime;

        return isNextStepDisabled;
    });

    return (
        <div className={classes.stepperWrapper}>
            <Stepper activeStep={activeStep} className={classes.stepper} nonLinear={true}>
                {!isMobile ? (
                    wizardSteps.map(({ number, name, checkDisable }) => (
                        <FlightWizardStep key={number} index={number} name={name} disabled={checkDisable && isNextStepDisabled} />
                    ))
                ) : (
                    <MobileWizardStepper isNextStepDisabled={isNextStepDisabled} />
                )}
            </Stepper>
        </div>
    );
}

const FlightWizardStepperMemoized = React.memo(FlightWizardStepper);

export { FlightWizardStepperMemoized as FlightWizardStepper };
