import React from 'react';

const initialTabsHeight = 50;

export const useGetTabsHeight = (tabsRef) => {
    const [tabsHeight, changeTabsHeight] = React.useState(initialTabsHeight);

    const handleWindowResize = React.useCallback(() => changeTabsHeight(tabsRef.current.offsetHeight), []);

    React.useEffect(() => changeTabsHeight(tabsRef.current.offsetHeight), [tabsRef]);

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return tabsHeight;
};
