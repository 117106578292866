import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => {
    const { primary3Color } = theme.palette;
    const { textFontSize, labelFontSize, textColor } = theme.textField;

    return {
        label: {
            color: primary3Color,
            fontSize: labelFontSize,
        },
        text: {
            color: textColor,
            fontSize: textFontSize,
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            margin: '14px 0',
            paddingBottom: 5,

            boxSizing: 'border-box',
            lineHeight: '22px',
        },
    };
});
