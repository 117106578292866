import { utf8ArrayToStr } from '../../app/service/ImageUtils';

function write(fileName, blob) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.readAsBinaryString(blob);

        reader.onloadend = () => {
            try {
                // if (fileName === 'state') {
                localStorage.setItem(fileName, reader.result);
                console.log(`File ${fileName} has been written`);
                // }
                resolve({});
            } catch (err) {
                console.log(err);
                reject(err);
            }
        };
    });
}

const writeLibraryFile = () => Promise.resolve();

function read(fileName, type = 'application/pdf') {
    return new Promise((resolve) => {
        try {
            let data = localStorage.getItem(fileName);
            let array = new Uint8Array(data.length);

            for (let i = 0; i < data.length; i++) {
                array[i] = data.charCodeAt(i);
            }

            resolve(new Blob([array], { type }));
        } catch (err) {
            resolve(new Blob([], { type }));
            console.log(err);
        }
    });
}

function getFileEntry() {
    return new Promise((resolve) => {
        // const error = (new Error('ПЗДЦ'));
        // reject({...error, message: 'ПЗДЦ!'});
        resolve({});
    });
}

function readFileAsString(fileName) {
    return getFileEntry(fileName + '', true).then(() => {
        return new Promise((resolve, reject) => {
            try {
                let byteCharacters = localStorage.getItem(fileName),
                    byteNumbers = new Array(byteCharacters.length);

                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }

                const blob = new Blob([new Uint8Array(byteNumbers)], { type: 'text/plain' });
                const reader = new FileReader();

                reader.onloadend = () => {
                    const array = new Uint8Array(reader.result);
                    resolve(utf8ArrayToStr(array));
                };

                reader.onerror = (err) => {
                    const msg = `Read file ${fileName} error`;
                    // todo разобраться, нужно ли хоть где-то это... Если нет - убрать.
                    // if (fileName.indexOf('state') === -1) {
                    //     remove(fileName);
                    // }
                    console.log(msg, err);
                    reject(new Error(msg));
                };

                reader.readAsArrayBuffer(blob);
            } catch (err) {
                console.log(`readFileAsString. Open file entry '${fileName}' error. Details: ${err.message}`);
                reject({
                    ...err,
                    stack: err.stack,
                    message: `readFileAsString. Open file entry '${fileName}' error. Details: ${err.message}`,
                });
            }
        });
    });
}

function rename(prevName, newName) {
    return new Promise((resolve) => {
        const data = localStorage.getItem(prevName);
        localStorage.removeItem(prevName);
        localStorage.setItem(newName, data);
        resolve();
    });
}

function readAsArray(fileName) {
    return new Promise((resolve, reject) => {
        try {
            let data = localStorage.getItem(fileName);
            let array = new Array(data.length);

            for (let i = 0; i < data.length; i++) {
                array[i] = data.charCodeAt(i);
            }

            resolve(array);
        } catch (err) {
            console.log(`readAsArray. Open file entry '${fileName}' error. Details: ${err.message}`);
            reject({
                ...err,
                stack: err.stack,
                message: `readAsArray. Open file entry '${fileName}' error. Details: ${err.message}`,
            });
        }
    });
}

const testFileList = [
    // {
    //     name: 'library_2187',
    //     modificationTime: null,
    // },
    // {
    //     name: 'library_2057',
    //     modificationTime: null,
    // },
    // {
    //     name: 'telegram_1447539',
    //     modificationTime: null,
    // },
];

function getFilesList() {
    const arr = testFileList.slice();
    if (arr.length === 0) {
        Object.keys(localStorage).forEach((key) => arr.push({ name: key, modificationTime: null }));
    }

    const state = arr.find((item) => item.name === 'state');
    if (state) {
        state.modificationTime = new Date(2018, 9, 1);
    }
    const state1 = arr.find((item) => item.name === 'state1');
    if (state1) {
        state1.modificationTime = new Date(2018, 9, 2);
    }

    return Promise.resolve(arr);
}

function getDirectoryList() {
    return getFilesList();
}

function remove(fileName) {
    return new Promise((resolve) => {
        localStorage.removeItem(fileName);
        resolve(fileName);
    });
}

function removeDirectory(dirName) {
    return Promise.resolve(dirName);
}

export default {
    read,
    write,
    writeLibraryFile,
    remove,
    removeDirectory,
    rename,
    getFilesList,
    getDirectoryList,
    readFileAsString,
    readAsArray,
};
