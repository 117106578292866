import React from 'react';
import classnames from 'classnames';

import { getTimeFormat } from '../../../service/NavPointsUtils';
import { CrewTypes } from '../../../service/constants';
import { sortSummaryPageCrew } from '../../../service/sort';
import { useStyles } from './useStyles';

function SummaryCrewTable(props) {
    const { bufferCrew } = props;
    const classes = useStyles();

    return (
        <table className={classes.table}>
            <thead>
                <tr className={classes.header}>
                    <th className={classes.headerCell}>ID</th>
                    <th className={classes.headerCell}>Crew</th>
                    <th className={classes.headerCell}>Chair</th>
                    <th className={classes.headerCell}>Duty</th>
                    <th className={classes.headerCell}>Flight</th>
                    <th className={classes.headerCell}>Block</th>
                </tr>
            </thead>
            <tbody>
                {bufferCrew && bufferCrew.length > 0 ? (
                    bufferCrew.sort(sortSummaryPageCrew).map((crew, index) => {
                        const { tableNumber = '', fullNameEng = '', total = {} } = crew || {};
                        const { work, previousWork, block, flight, crewType, chairs = [] } = total;
                        const className = classnames(classes.info, crewType === CrewTypes.PCT_CABINSTAFF ? classes.cabin : classes.salon);

                        return (
                            <tr key={index}>
                                <td className={className}>{tableNumber}</td>
                                <td className={className}>{fullNameEng}</td>
                                <td className={className}>
                                    {Object.keys(chairs)
                                        .sort()
                                        .map((key, i, arr) => `${chairs[key]}${i === arr.length - 1 ? '' : ', '}`)}
                                </td>
                                <td className={className}>
                                    {getTimeFormat(work + (previousWork || 0))}
                                    {previousWork ? <span className={classes.previousWork}>({getTimeFormat(work, true)})</span> : ''}
                                </td>
                                <td className={className}>{getTimeFormat(flight)}</td>
                                <td className={className}>{getTimeFormat(block)}</td>
                            </tr>
                        );
                    })
                ) : (
                    <tr className={classes.row}>
                        <td>There are not crew information</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}

export { SummaryCrewTable };
