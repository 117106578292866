import * as React from 'react';
import moment from 'moment';

import { InputAdornment } from '../../material-ui/InputAdornment';
import { IconButton } from '../../material-ui/IconButton';
import { TextField } from '../../material-ui/TextField';
import Remove from '../../../icons/image/Remove';
import { stopPropagation } from '../../../service/eventHandlers';
import { validateDate } from './tools';

const NativeTimePicker = (props) => {
    const { value, onChange, label, showClear = true, onClear, ...restProps } = props;
    const [selectedDate, changeSelectedDate] = React.useState(value);
    const ref = React.useRef();

    React.useEffect(() => {
        changeSelectedDate(value);
    }, [value]);

    const handleDateChange = React.useCallback((evt) => {
        changeSelectedDate(evt.target.value);
    }, []);

    const handleClear = React.useCallback((event) => {
        stopPropagation(event);
        if (onClear) {
            onClear();
        } else if (onChange) {
            onChange(event, null);
        }

        changeSelectedDate('');
    }, []);

    const handleBlur = React.useCallback((event) => {
        if (event.target) {
            const isDateValid = validateDate(event.target.value, 'HH:mm');
            if (ref && ref.current && !isDateValid) {
                return ref.current.focus();
            }
            onChange(event, moment.utc(event.target.value, 'HH:mm', true));
        }
    }, []);

    return (
        <TextField
            {...restProps}
            id="time"
            type={label}
            inputRef={ref}
            onChange={handleDateChange}
            value={selectedDate}
            label={label}
            InputLabelProps={{
                shrink: true,
            }}
            inputProps={{
                onBlur: handleBlur,
                type: 'time',
            }}
            InputProps={
                showClear
                    ? {
                          endAdornment: (
                              <InputAdornment position="end">
                                  <IconButton onClick={handleClear}>
                                      <Remove width="16" height="16" />
                                  </IconButton>
                              </InputAdornment>
                          ),
                      }
                    : {}
            }
        />
    );
};

const NativeTimePickerMemoized = React.memo(NativeTimePicker);

export { NativeTimePickerMemoized as NativeTimePicker };
