import React from 'react';

import BaseIcon from '../BaseIcon';

const Pilot = (props) => (
    <BaseIcon {...props} viewBox="0 0 124.64 124.64">
        <path
            d="M38.912,16.332l-0.457,1.518c-4.296,0.746-5.938,1.77-5.938,3.477c0,1.514,1.298,2.482,4.562,3.209
            c-0.485,0.793-0.748,1.619-0.748,2.471c0,1.691,1.021,3.289,2.822,4.701c-0.563,1.951-0.005,4.719,0.457,6.4
            c0.326,1.813,0.902,3.096,1.777,4.002c0.068,0.078,0.104,0.146,0.117,0.236c1.799,11.902,11.607,22.742,20.578,22.742
            c10.603,0,20.036-11.975,21.869-22.713c0.016-0.09,0.081-0.201,0.035-0.152c0.908-0.893,1.532-2.188,1.811-3.75
            c0.608-2.281,1.114-5.129,0.537-6.955c4.278-3.445,2.611-6.229,2.192-6.811c3.89-0.742,5.384-1.752,5.384-3.381
            c0-1.799-1.811-2.836-6.628-3.596l-0.428-1.398c6.241-1.732,10.11-4.125,10.11-6.783C96.964,4.274,81.707,0,62.88,0
            C44.062,0,28.8,4.273,28.8,9.549C28.8,12.207,32.67,14.599,38.912,16.332z M70.107,15.029c0.189,0.043,0.376,0.145,0.542,0.293
            l0.032,0.035c0.148,0.164,0.25,0.352,0.293,0.541c0.129,0.541-0.301,1.357-0.795,1.883l-2.323,2.457l1.751,4.504
            c0.1,0.271,0.049,0.611-0.121,0.83l-0.693,0.895c-0.256,0.324-0.76,0.287-0.967-0.061l-2.281-3.852l-2.328,2.184
            c-0.004,0.002-0.008,0.006-0.012,0.01l0.141,1.076l-1.145,1.48l-0.336-0.412c-0.027-0.033-0.054-0.068-0.074-0.109l-0.492-0.918
            c-0.146,0.051-0.402,0.125-0.624,0.125c-0.202,0-0.364-0.061-0.481-0.178c-0.22-0.223-0.235-0.592-0.053-1.105l-1.004-0.547
            l-0.451-0.332l1.494-1.166l1.078,0.137c0.004-0.004,0.008-0.008,0.012-0.012l0.029-0.039l2.149-2.285l-3.85-2.283
            c-0.179-0.107-0.286-0.279-0.296-0.473c-0.012-0.191,0.077-0.373,0.24-0.498l0.887-0.691c0.216-0.168,0.567-0.219,0.827-0.121
            l4.509,1.75l2.455-2.32C68.822,15.259,69.613,14.914,70.107,15.029z M58.961,25.359l0.17,0.078v0.002
            c0.11,0.307-0.039,0.477-0.346,0.357c-2.104-0.816-3.781-2.492-4.598-4.6c-0.119-0.305,0.049-0.455,0.357-0.344
            c0.918,0.328,2,0.582,3.161,0.756c0.323,0.051,0.618,0.346,0.667,0.67c0.037,0.244,0.08,0.484,0.124,0.721l-1.388,1.063
            L58.961,25.359z M59.687,20.939c-0.328-0.025-0.618-0.314-0.643-0.641c-0.031-0.406-0.049-0.826-0.061-1.256
            c0.092,0.092,0.196,0.172,0.311,0.24l2.721,1.615l-0.105,0.111c-0.068,0.002-0.137,0.004-0.207,0.004
            C61.005,21.013,60.331,20.988,59.687,20.939z M60.002,21.847l-0.709,0.543c-0.002-0.006-0.002-0.01-0.002-0.016
            c-0.055-0.322,0.174-0.561,0.501-0.539C59.861,21.841,59.932,21.843,60.002,21.847z M65.257,16.371
            c-0.022-0.326,0.217-0.555,0.539-0.502c0.375,0.061,0.732,0.133,1.073,0.207l-1.11,1.051l-0.474-0.186
            C65.278,16.752,65.27,16.56,65.257,16.371z M64.619,10.765c1.807,0.703,3.297,2.039,4.199,3.734
            c-0.421,0.211-0.8,0.492-1.085,0.762l-0.089,0.084c-0.611-0.156-1.262-0.289-1.945-0.393c-0.322-0.049-0.617-0.346-0.667-0.668
            c-0.176-1.162-0.429-2.244-0.757-3.16C64.164,10.816,64.313,10.646,64.619,10.765z M64.36,16.265
            c0.008,0.105,0.012,0.215,0.019,0.324l-2.518-0.979c-0.068-0.025-0.139-0.045-0.211-0.063c0.018,0,0.035,0,0.053,0
            c0.699,0,1.372,0.027,2.016,0.074C64.044,15.648,64.337,15.939,64.36,16.265z M60.203,10.847c0.13-0.297,0.521-0.574,0.848-0.6
            c0.215-0.018,0.434-0.027,0.652-0.027c0.22,0,0.438,0.01,0.652,0.027c0.326,0.025,0.717,0.303,0.848,0.6
            c0.369,0.85,0.691,1.99,0.913,3.34c0.054,0.322-0.176,0.563-0.503,0.539c-0.633-0.045-1.274-0.066-1.91-0.066
            c-0.636,0-1.277,0.021-1.91,0.066c-0.326,0.023-0.556-0.217-0.502-0.539C59.511,12.838,59.834,11.697,60.203,10.847z
             M59.687,15.623c0.32-0.022,0.647-0.041,0.982-0.055c-0.238,0.064-0.463,0.172-0.652,0.32l-0.879,0.686
            c-0.041,0.031-0.08,0.064-0.117,0.1c0.008-0.137,0.013-0.275,0.023-0.408C59.068,15.939,59.359,15.648,59.687,15.623z
             M54.187,15.365c0.816-2.104,2.493-3.781,4.598-4.6c0.307-0.119,0.456,0.051,0.346,0.359c-0.329,0.916-0.582,1.998-0.758,3.16
            c-0.049,0.322-0.344,0.619-0.667,0.668c-1.161,0.176-2.243,0.428-3.161,0.758C54.236,15.82,54.068,15.672,54.187,15.365z
             M53.668,17.627c0.026-0.324,0.303-0.715,0.603-0.846c0.849-0.369,1.989-0.691,3.337-0.912c0.324-0.053,0.563,0.176,0.54,0.502
            c-0.043,0.635-0.066,1.275-0.066,1.91c0,0.637,0.023,1.277,0.066,1.91c0.022,0.326-0.216,0.557-0.54,0.502
            c-1.348-0.221-2.488-0.543-3.337-0.912c-0.3-0.131-0.576-0.521-0.603-0.848c-0.018-0.215-0.025-0.432-0.025-0.652
            C53.642,18.062,53.65,17.843,53.668,17.627z M62.633,37.443c7.882,0,14.947-1.377,19.769-3.557
            c0.165-0.055,0.753-0.355,0.753-0.355c0.097-0.045,0.179-0.098,0.271-0.145c0.006,0.797-0.121,2.15-0.709,4.33l-0.033,0.148
            c-0.156,0.936-0.475,1.66-0.985,2.166c-0.44,0.492-0.748,1.127-0.868,1.801C79.244,51.12,70.71,61.917,62.084,61.917
            c-7.274,0-15.916-9.932-17.444-20.051c-0.11-0.713-0.414-1.348-0.938-1.922c-0.459-0.479-0.771-1.26-0.981-2.463l-0.034-0.15
            c-0.479-1.723-0.62-2.998-0.614-3.822C46.888,35.906,54.306,37.443,62.633,37.443z"
        />
        <path
            d="M101.729,75.925c-0.354-0.453-2.33-2.752-6.282-4.258c-3.473-1.076-12.057-3.921-16.757-7.322
            c-1.358-0.979-2.95-2.879-2.95-2.879l-0.466,0.441c-0.228,0.214-0.461,0.402-0.69,0.607c-0.906,2.842-3.726,11.07-8.398,19.729
            l-0.342-6.678l-2.643-1.889l2.643-1.887l-1.604-5.537H60.4l-1.604,5.537l2.642,1.887l-2.642,1.889l-0.341,6.678
            c-4.673-8.657-7.493-16.887-8.398-19.729c-0.229-0.205-0.464-0.393-0.691-0.607L48.9,61.466c0,0-1.591,1.899-2.951,2.879
            c-4.699,3.401-13.285,6.246-16.755,7.322c-3.953,1.506-5.927,3.805-6.282,4.258c-7.271,10.795-8.096,35.013-8.102,35.252
            c0.064,3.224,1.023,4.324,1.352,4.459c16.026,7.132,36.191,8.968,46.01,9.002v0.002c0.049,0,0.101,0,0.148,0s0.1,0,0.148,0v-0.002
            c9.818-0.034,29.983-1.87,46.01-9.002c0.328-0.135,1.285-1.235,1.352-4.459C109.823,110.938,109,86.72,101.729,75.925z"
        />
    </BaseIcon>
);

export default Pilot;
