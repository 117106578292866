import { utf8ArrayToStr } from '../../app/service/ImageUtils';
import { FileStorePath } from '../../app/service/constants';
import { isAndroid } from '../../app/service/utils';

const BLOCK_SIZE = 1 * 1024 * 2048; // write blocks of 1MB at a time

function getError(evt) {
    return new Error(`Error ${(evt && evt.target && evt.target.error && evt.target.error.code) || evt.code || '0'}`);
}

// Example of resolveLocalFileSystemURL
/* eslint-disable */
function getLocalFileEntry(fileName) {
    // eslint-disable-line no-unused-vars
    return new Promise((resolve, reject) => {
        resolveLocalFileSystemURL(
            // eslint-disable-line no-undef
            getDocumentsPath() + fileName,
            (fileEntry) => {
                resolve(fileEntry);
            },
            (evt) => {
                const err = getError(evt);
                // console.log(`Open file entry "${fileName}" error`, err);
                reject({
                    ...err,
                    stack: err.stack,
                    message: `getFileEntry. Request file system error "${fileName}". Details: ${err.message}`,
                });
            }
        );
    });
}

// requestFileSystem
function getFileSystem() {
    return new Promise((resolve, reject) => {
        /* eslint-disable */
        requestFileSystem(
            // eslint-disable-line no-undef
            LocalFileSystem.PERSISTENT, // eslint-disable-line no-undef
            0,
            (fs) => resolve(fs),
            (evt) => {
                const err = getError(evt);
                // console.log(`Open file entry "${fileName}" error`, err);
                reject({
                    ...err,
                    stack: err.stack,
                    message: `getFileSystem. Request file system error. Details: ${err.message}`,
                });
            }
        );
    });
}

function getDirectory(folder = '/') {
    return getFileSystem().then((fs) => {
        return new Promise((resolve, reject) => {
            fs.root.getDirectory(
                folder,
                { create: true },
                (dirEntry) => resolve(dirEntry),
                (err) => {
                    reject({
                        ...err,
                        stack: err.stack,
                        message: `getDirectory. Get directory "${folder}" error. Details: ${err.message}`,
                    });
                }
            );
        });
    });
}

function getFile(dirEntry, fileName, isNew) {
    return new Promise((resolve, reject) => {
        dirEntry.getFile(fileName, { create: isNew, exclusive: false }, resolve, (evt) => {
            const err = getError(evt);
            // console.log(`Open file entry "${fileName}" error`, err);
            reject({
                ...err,
                stack: err.stack,
                message: `getFile. Get file "${fileName}" error. Details: ${err.message}`,
            });
        });
    });
}

// requestFileSystem
function getFileEntry(fileName, isNew, path, folder) {
    return getDirectoryByPath(path, folder).then((dirEntry) => {
        return getFile(dirEntry, fileName, isNew);
    });
}

function readEntries(dirEntry) {
    return new Promise((resolve, reject) => {
        dirEntry.createReader().readEntries(
            (entries) => resolve(entries),
            (evt) => {
                const err = getError(evt);
                // console.log(`Open file entry "${fileName}" error`, err);
                reject({
                    ...err,
                    stack: err.stack,
                    message: `readEntries. Read entries from directory "${dirEntry.name}" error. Details: ${err.message}`,
                });
            }
        );
    });
}

function moveFileEntry(fileEntry, dirEntry, newName) {
    return new Promise((resolve, reject) => {
        fileEntry.moveTo(
            dirEntry,
            newName,
            function () {
                console.log(`CONS: Renamed ${fileEntry.name} --> ${dirEntry.name}/${newName}`);
                resolve();
            },
            (evt) => {
                const err = getError(evt);
                // console.log(`Open file entry "${fileName}" error`, err);
                reject({
                    ...err,
                    stack: err.stack,
                    message: `moveFileEntry. Move file "${fileEntry.name}" to dir "${dirEntry}/${newName}" error. Details: ${err.message}`,
                });
            }
        );
    });
}

function rename(currentName, newName, path, folder) {
    return Promise.all([getFileEntry(currentName, false, path, folder), getDirectoryByPath(path, folder)]).then(
        ([fileEntry, dirEntry]) => {
            // const parentEntry = new fs.root.DirectoryEntry(currentName, currentDir + currentName);

            return moveFileEntry(fileEntry, dirEntry, newName);
        }
    );
}

function getFileMetadata(file) {
    return new Promise((resolve, reject) => {
        file.getMetadata(
            (metadata) =>
                resolve({
                    name: file.name,
                    modificationTime: metadata.modificationTime,
                }),
            (evt) => {
                const err = getError(evt);
                // console.log(`Open file entry "${fileName}" error`, err);
                reject({
                    ...err,
                    stack: err.stack,
                    message: `getFileMetadata. Get metadata "${file.name}" error. Details: ${err.message}`,
                });
            }
        );
    });
}

function getDirectoryByPath(path, folder) {
    if (path && path === FileStorePath.ANDROIDDOWNLOAD) {
        return getAndroidDirectory('Aviabit', folder);
    } else {
        return getDirectory(folder);
    }
}

function getFilesList(path, folder) {
    const directoryPromise = getDirectoryByPath(path, folder);

    return directoryPromise
        .then((dirEntry) => readEntries(dirEntry))
        .then((entries) => {
            return Promise.all(entries.filter((entry) => entry.isFile).map((file) => getFileMetadata(file)));
        });
}

function getDirectoryList(path, folder) {
    const directoryPromise = getDirectoryByPath(path, folder);

    return directoryPromise
        .then((dirEntry) => readEntries(dirEntry))
        .then((entries) => entries);
}

// const write = function(fileName, blob) {
//
//     return getFileEntry(fileName + '').then(fileEntry => {
//
//         return new Promise((resolve, reject) => {
//             fileEntry.createWriter(fileWriter => {
//                 //append to the end
//                 fileWriter.seek(fileWriter.length);
//                 fileWriter.onerror = (err) => reject({...err, message: `Write file ${fileName} error`});
//
//                 const finishedWriting = function() {
//                     resolve();
//                 };
//                 let offset = 0;
//                 let writeNext = function(finishCallback) {
//                     let blockSize = Math.min(BLOCK_SIZE, blob.size - offset);
//                     let block = blob.slice(offset, offset + blockSize);
//                     fileWriter.onwriteend = function() {
//                         if (offset < blob.size) {
//                             offset += blockSize;
//                             writeNext(finishCallback);
//                         } else {
//                             finishCallback();
//                         }
//                     };
//                     fileWriter.write(block);
//                 };
//                 writeNext(finishedWriting);
//             }, function(err) {
//                 console.log(`Write file ${fileName} error`);
//                 reject(err);
//             });
//         });
//     });
// };

function write(fileName, blob, folder) {
    const chunkSize = BLOCK_SIZE;
    const chunkCount = Math.floor(blob.size / chunkSize);
    let res = Promise.resolve();
    const tempFileName = `temp_${fileName}`;

    for (let i = 0; i <= chunkCount; i++) {
        //console.log(`CONS1: i=${i}, i * chunkSize = ${i * chunkSize}, (i + 1) * chunkSize) = ${(i + 1) * chunkSize}`);
        if (i === 0) {
            console.log(`CONS: ${tempFileName} added; chunkCount=${chunkCount}; size = ${blob.size}`);
        }
        res = res
            .then(() =>
                writeChunk(tempFileName, blob.slice(i * chunkSize, (i + 1) * chunkSize), folder, {
                    isFirst: i === 0,
                    isLast: i === chunkCount,
                })
            )
            .catch((err) => {
                console.log(`Write chunk file error. Write file "${tempFileName}" error. Details: ${err.message}`);
                return {
                    ...err,
                    stack: err.stack,
                    message: `Write chunk file error. Write file "${tempFileName}" error. Details: ${err.message}`,
                };
            });
    }

    return res;
}

function writeChunk(tempFileName = '', chunk, folder, { isFirst, isLast }) {
    return getFileEntry(tempFileName, isFirst, FileStorePath.LOCAL, folder).then((fileEntry) => {
        console.log(`Write file ${tempFileName} into ${fileEntry.toURL()}`);

        return new Promise((resolve, reject) => {
            fileEntry.createWriter((fileWriter) => {
                if (!isFirst) {
                    fileWriter.seek(fileWriter.length);
                }
                fileWriter.onwriteend = () => {
                    if (isLast) {
                        const fileName = tempFileName.replace('temp_', '');

                        return remove(fileName, folder)
                            .then(() => {
                                return rename(tempFileName, fileName, FileStorePath.LOCAL, folder);
                            })
                            .then(() => resolve())
                            .catch((err) => {
                                reject(err);
                            });
                    }

                    resolve();
                };
                fileWriter.onerror = (evt) => reject(getError(evt));
                fileWriter.write(chunk);
            });
        });
    });
}

function writeFile(dir, filename, blob) {
    dir.getFile(filename, { create: true }, function (file) {
        if (!file) {
            console.error(new Error(`No file: ${filename}`));
            return;
        }
        file.createWriter(function (fileWriter) {
            fileWriter.seek(fileWriter.length);
            fileWriter.write(blob);
        }, (error) => {
            console.error('Filesystem error');
            console.error(error);
        });
    });
}

function getLibraryDirectory(folder) {
    return new Promise((resolve, reject) => {
        function getDirectorySuccess(dir) {
            return resolve(dir);
        }

        function getDirectoryError(error) {
            return reject({
                ...error,
                stack: error.stack,
                message: `getLibraryDirectory. Request directory. Details: ${error.message}`,
            });
        }

        function getDirectory(dir) {
            dir.getDirectory(folder, { create: true, exclusive: false }, getDirectorySuccess, getDirectoryError);
        }

        window.resolveLocalFileSystemURL(
            `${cordova.file.documentsDirectory}`,
            folder ? getDirectory : getDirectorySuccess,
            getDirectoryError
        );
    });
}

function writeLibraryFile(filename, blob, folder) {
    if (isAndroid()) {
        let directoryName = 'Images';
        directoryName = folder ? `${directoryName}/${folder}` : directoryName;
        return getAndroidDirectory('Aviabit', directoryName).then((dir) => {
            writeFile(dir, filename, blob);
        });
    }
    return getLibraryDirectory(folder).then((dir) => {
        writeFile(dir, filename, blob);
    });
}

function read(fileName) {
    return getFileEntry(fileName + '', true).then((fileEntry) => {
        return new Promise((resolve, reject) => {
            fileEntry.file(
                (file) => {
                    let reader = new FileReader();

                    reader.onloadend = () => resolve(new Blob([new Uint8Array(reader.result)]));
                    reader.onerror = (evt) => {
                        const err = getError(evt);
                        // console.log(msg, err);
                        reject({
                            ...err,
                            stack: err.stack,
                            message: `read. Read file "${fileName}" error. Details: ${err.message}`,
                        });
                    };

                    reader.readAsArrayBuffer(file);
                },
                (err) => {
                    // console.log(`Read file entry "${fileName}" error`, err);
                    reject({
                        ...err,
                        stack: err.stack,
                        message: `read. Read file entry "${fileName}" error. Details: ${err.message}`,
                    });
                }
            );
        });
    });
}

function getDocumentsPath() {
    /* LIBRARY */
    const path = cordova.file.dataDirectory.replace('NoCloud', 'files'); // eslint-disable-line no-undef
    // console.log(`Get path ${path}`);
    return path;

    /* DOCUMENTS */
    // return cordova.file.documentsDirectory; // eslint-disable-line no-undef
}

function remove(fileName, folder) {
    const folderPrefix = folder ? `${folder}/` : '';
    const filePath = `${folderPrefix}${fileName}`;

    // В андроиде библиотека загружается в загрузки
    if (isAndroid() && typeof fileName === 'string' && fileName.split('.')[1] === 'pdf') {
        return getAndroidDirectory('Aviabit', folder).then(dir => {
            return new Promise((resolve, reject) => {
                dir.getFile(fileName, { create: false }, function (fileEntry) {
                    fileEntry.remove(
                        () => resolve(fileName),
                        (err) => {
                            //console.error(err);
                            reject({
                                ...err,
                                stack: err.stack,
                                message: `remove. Remove file "${fileName}" error. Details: ${err.message}`,
                            });
                        }
                    );
                });
            });
        });
    }

    return getFileEntry(filePath, true).then((fileEntry) => {
        return new Promise((resolve, reject) => {
            console.log(`CONS: ${fileName} removed`);
            fileEntry.remove(
                () => resolve(fileName),
                (err) => {
                    // console.log(`Read file entry "${fileName}" error`, err);
                    reject({
                        ...err,
                        stack: err.stack,
                        message: `remove. Remove file "${fileName}" error. Details: ${err.message}`,
                    });
                }
            );
        });
    });
}

function removeDirectory(dirName) {
    // В андроиде библиотека загружается в загрузки
    if (isAndroid()) {
        return getAndroidDirectory('Aviabit', dirName).then(dir => {
            return new Promise((resolve, reject) => {
                dir.removeRecursively(
                    () => resolve(dirName),
                    (err) => {
                        //console.error(err);
                        reject({
                            ...err,
                            stack: err.stack,
                            message: `remove. Remove directory "${dirName}" error. Details: ${err.message}`,
                        });
                    }
                );
            });
        });
    }

    return getDirectory(dirName).then((dirEntry) => {
        return new Promise((resolve, reject) => {
            console.log(`CONS: ${dirName} removed`);
            dirEntry.removeRecursively(
                () => resolve(dirName),
                (err) => {
                    // console.log(`Read directory entry "${dirName}" error`, err);
                    reject({
                        ...err,
                        stack: err.stack,
                        message: `remove. Remove directory "${dirName}" error. Details: ${err.message}`,
                    });
                }
            );
        });
    });
}

// function getFileEntry(fileName, isNew) {
//     return new Promise((resolve, reject) => {
//         requestFileSystem(// eslint-disable-line no-undef
//             LocalFileSystem.PERSISTENT, // eslint-disable-line no-undef
//             0,
//             fs => {
//                 // eslint-disable-line no-undef
//                 fs.root.getFile(fileName, { create: isNew, exclusive: false }, resolve, reject);
//             },
//             reject
//         );
//     });
// }

// // requestFileSystem
// function getFileEntry(fileName, isNew) {
//     return new Promise((resolve, reject) => {
//         resolveLocalFileSystemURL(// eslint-disable-line no-undef
//             getDocumentsPath() + fileName,
//             (fs) => {
//                 // console.log(fs.name);
//                 // console.log(fs.root);
//                 // eslint-disable-line no-undef
//                 fs.root.getFile(fileName, { create: isNew, exclusive: false }, resolve,
//                     (evt) => {
//                         const err = getError(evt);
//                         // console.log(`Open file entry "${fileName}" error`, err);
//                         reject({
//                             ...err,
//                             stack: err.stack,
//                             message: `getFileEntry. Get file entry "${fileName}" error. Details: ${err.message}`
//                         });
//                     });
//             },
//             (evt) => {
//                 const err = getError(evt);
//                 // console.log(`Open file entry "${fileName}" error`, err);
//                 reject({
//                     ...err,
//                     stack: err.stack,
//                     message: `getFileEntry. Request file system error "${fileName}". Details: ${err.message}`
//                 });
//             }
//         );
//     });
// }

// function readFileAsString(fileName) {
//     return getFileEntry(fileName + '', true).then(fileEntry => {
//         return new Promise((resolve, reject) => {
//             fileEntry.file(file => {
//                 let reader = new FileReader();
//
//                 reader.onloadend = () => {
//                     resolve(reader.result);
//                 };
//
//                 reader.onerror = (evt) => {
//                     const err = getError(evt);
//                     if(fileName.indexOf('state') === -1) {
//                         remove(fileName);
//                     }
//                     reject({
//                         ...err,
//                         stack: err.stack,
//                         message: `readFileAsString. Read file "${fileName}" error. Details: ${err.message}`
//                     });
//                 };
//
//                 reader.readAsText(file);
//             }, (err) =>{
//                 // console.log(`Open file entry "${fileName}" error`, err);
//                 reject({
//                     ...err,
//                     stack: err.stack,
//                     message: `readFileAsString. Open file entry "${fileName}" error. Details: ${err.message}`
//                 });
//             });
//         });
//     });
// }

function readFileAsString(fileName) {
    return getFileEntry(fileName + '', true).then((fileEntry) => {
        return new Promise((resolve, reject) => {
            fileEntry.file(
                (file) => {
                    let reader = new FileReader();

                    reader.onloadend = () => {
                        const array = new Uint8Array(reader.result);
                        resolve(utf8ArrayToStr(array));
                        //var string = (new TextDecoder('utf-16')).decode(array);
                        //resolve(string);
                    };

                    reader.onerror = (evt) => {
                        const err = getError(evt);
                        // console.log('error', e);
                        // remove(fileName);
                        reject({
                            ...err,
                            stack: err.stack,
                            message: `readAsArray.Read file "${fileName}" error. Details: ${err.message}`,
                        });
                    };

                    reader.readAsArrayBuffer(file);
                },
                (err) => {
                    // console.log(Open file entry "${fileName}" error, err);
                    reject({
                        ...err,
                        stack: err.stack,
                        message: `readAsArray.Open file entry "${fileName}" error.Details: ${err.message}`,
                    });
                }
            );
        });
    });
}

function readAsArray(fileName) {
    return getFileEntry(fileName + '', true).then((fileEntry) => {
        return new Promise((resolve, reject) => {
            fileEntry.file(
                (file) => {
                    let reader = new FileReader();

                    reader.onloadend = () => {
                        const arrayBuffer = reader.result;
                        const array = new Uint8Array(arrayBuffer);
                        const fileByteArray = Array.from(array);

                        resolve(fileByteArray);
                    };

                    reader.onerror = (evt) => {
                        const err = getError(evt);
                        // console.log('error', e);
                        // remove(fileName);
                        reject({
                            ...err,
                            stack: err.stack,
                            message: `readAsArray. Read file "${fileName}" error. Details: ${err.message}`,
                        });
                    };

                    reader.readAsArrayBuffer(file);
                },
                (err) => {
                    // console.log(`Open file entry "${fileName}" error`, err);
                    reject({
                        ...err,
                        stack: err.stack,
                        message: `readAsArray. Open file entry "${fileName}" error. Details: ${err.message}`,
                    });
                }
            );
        });
    });
}

// Android functions
function getAndroidDirectory(directory = 'Aviabit', folder) {
    const directoryName = folder ? `${directory}/${folder.replace('\\', '/')}` : directory;
    const folders = directoryName.split('/');
    let index = 0;
    return new Promise((resolve, reject) => {
        function getDirectorySuccess(dir) {
            return resolve(dir);
        }

        function getDirectoryError(error) {
            return reject({
                ...error,
                stack: error.stack,
                message: `getAndroidDirectory. Request directory. Details: ${error.message}`,
            });
        }

        function getDirectory(dir) {
            dir.getDirectory(
                folders[index++],
                { create: true, exclusive: false },
                folders[index] ? getDirectory : getDirectorySuccess,
                getDirectoryError
            );
        }

        window.resolveLocalFileSystemURL(`${cordova.file.externalRootDirectory}Download/`, getDirectory, getDirectoryError);
    });
}

function writeAndroidDownloadFile(filename, blob, folder) {
    return getAndroidDirectory('Aviabit', folder).then((dir) => {
        writeFile(dir, filename, blob);
    });
}


export default {
    read,
    write,
    writeLibraryFile,
    remove,
    removeDirectory,
    rename,
    writeAndroidDownloadFile,
    getDocumentsPath,
    getFilesList,
    getDirectoryList,
    readFileAsString,
    getFileEntry,
    readAsArray,
};
