export const CrewTypes = {
    PCT_CABINSTAFF: 0,
    PCT_SALONSTAFF: 1,
    PCT_TECHNICSTAFF: 2,
    PCT_UNDEFINEDSTAFF: 3,
    PCT_PAXSTAFF: 4,
};

export const KeyCode = {
    // CANCEL: 3,
    // HELP: 6,
    // BACK_SPACE: 8,
    // TAB: 9,
    // CLEAR: 12,
    // RETURN: 13,
    // ENTER: 14,
    // SHIFT: 16,
    // CONTROL: 17,
    // ALT: 18,
    // PAUSE: 19,
    // CAPS_LOCK: 20,
    ESCAPE: 27,
    // SPACE: 32,
    // PAGE_UP: 33,
    // PAGE_DOWN: 34,
    // END: 35,
    // HOME: 36,
    // LEFT: 37,
    // UP: 38,
    // RIGHT: 39,
    // DOWN: 40,
    // PRINTSCREEN: 44,
    // INSERT: 45,
    // DELETE: 46,
    // 0: 48,
    // 1: 49,
    // 2: 50,
    // 3: 51,
    // 4: 52,
    // 5: 53,
    // 6: 54,
    // 7: 55,
    // 8: 56,
    // 9: 57,
    // SEMICOLON: 59,
    // EQUALS: 61,
    // A: 65,
    // B: 66,
    // C: 67,
    // D: 68,
    // E: 69,
    // F: 70,
    // G: 71,
    // H: 72,
    // I: 73,
    // J: 74,
    // K: 75,
    // L: 76,
    // M: 77,
    // N: 78,
    // O: 79,
    // P: 80,
    // Q: 81,
    // R: 82,
    // S: 83,
    // T: 84,
    // U: 85,
    // V: 86,
    // W: 87,
    // X: 88,
    // Y: 89,
    // Z: 90,
    // CONTEXT_MENU: 93,
    // NUMPAD0: 96,
    // NUMPAD1: 97,
    // NUMPAD2: 98,
    // NUMPAD3: 99,
    // NUMPAD4: 100,
    // NUMPAD5: 101,
    // NUMPAD6: 102,
    // NUMPAD7: 103,
    // NUMPAD8: 104,
    // NUMPAD9: 105,
    // MULTIPLY: 106,
    // ADD: 107,
    // SEPARATOR: 108,
    // SUBTRACT: 109,
    // DECIMAL: 110,
    // DIVIDE: 111,
    // F1: 112,
    // F2: 113,
    // F3: 114,
    // F4: 115,
    // F5: 116,
    // F6: 117,
    // F7: 118,
    // F8: 119,
    // F9: 120,
    // F10: 121,
    // F11: 122,
    // F12: 123,
    // F13: 124,
    // F14: 125,
    // F15: 126,
    // F16: 127,
    // F17: 128,
    // F18: 129,
    // F19: 130,
    // F20: 131,
    // F21: 132,
    // F22: 133,
    // F23: 134,
    // F24: 135,
    // NUM_LOCK: 144,
    // SCROLL_LOCK: 145,
    // COMMA: 188,
    // PERIOD: 190,
    // SLASH: 191,
};

export const ALTIMETER_WARNING_LIMIT = 200; // min altitude difference for warning

export const TelegramPrefix = {
    TELEGRAM: 'telegram_',
    SIGN: 'sign_telegram_',
    ATTACHMENT: 'telexAttachments_',
};

// коэффициенты перевода веса
export const KG_IN_LB = 0.45359;

export const LANDSCAPE_WIDTH = 1024;

export const DocumentPrefix = {
    DEFECT: 'defect_',
    LIBRARY: 'library_',
    DOCUMENT: '',
};

export const DocumentTypes = {
    DEFECT: 0,
    DOCUMENT: 1,
    LIBRARY: 2,
    OTHER: 10,
};

export const FileStorePath = {
    LOCAL: 0,
    ANDROIDDOWNLOAD: 1,
};

export const Method = {
    POST: 'POST',
    GET: 'GET',
    DELETE: 'DELETE',
};

export const MessageType = {
    AUTH_ERROR_CONFIRMATION_CODE_INCORRECT: 'AUTH_ERROR_CONFIRMATION_CODE_INCORRECT',
    AUTH_ERROR_CONFIRMATION_CODE_NOT_FOUND: 'AUTH_ERROR_CONFIRMATION_CODE_NOT_FOUND',
    AUTH_ERROR_EMAIL_CONFIRMATION_SEND: 'AUTH_ERROR_EMAIL_CONFIRMATION_SEND',
    CREATE_TELEX_ATTACHMENT: 'CREATE_TELEX_ATTACHMENT',
    GET_AIRPORT_INFO_API: 'GET_AIRPORT_INFO_API',
    GET_DATA: 'GET DATA',
    GET_DEFECT_DOC_API: 'GET_DEFECT_DOC_API',
    GET_DIRECTORY_LIST_API: 'GET_DIRECTORY_LIST',
    GET_DOC_API: 'GET_DOC_API',
    GET_FILE: 'GET_FILE',
    GET_FILE_API: 'GET_FILE_API',
    GET_LIBRARY_API: 'GET_LIBRARY_API',
    GET_LIBRARY_DATA: 'GET_LIBRARY_DATA',
    GET_LIBRARY_DOC_API: 'GET_LIBRARY_DOC',
    GET_NAV_ROUTE_DECLARAION_API: 'GET_NAV_ROUTE_DECLARAION',
    GET_NOTICE_DATA_API: 'GET_NOTICE_DATA_API',
    GET_ROUTES_DECLARATIONS: 'GET_ROUTES_DECLARATIONS',
    GET_TASK_DATA_API: 'GET_TASK_DATA_API',
    GET_TASK_LIST_API: 'GET_TASK_LIST_API',
    GET_USER_DATA_API: 'GET_USER_DATA_API',
    GET_USER_PLAN_FLIGHT_API: 'GET_USER_PLAN_FLIGHT_API',
    GET_USER_REPORTS_API: 'GET_USER_REPORTS_API',
    LOAD_LIBRARY: 'LOAD_LIBRARY',
    REMOVE_DIR_API: 'REMOVE_DIR_API',
    REMOVE_DOC_API: 'REMOVE_DOC_API',
    REMOVE_REPORT_API: 'REMOVE_REPORT_API',
    REMOVE_TASK: 'REMOVE_TASK',
    RENAME_FILE_API: 'RENAME_FILE_API',
    SEND_DATA: 'SEND_DATA',
    SEND_DATA_API: 'SEND_DATA_API',
    SEND_LOG_API: 'SEND_LOG_API',
    SEND_OTHER_DATA_API: 'SEND_OTHER_DATA_API',
    SEND_REPORTS: 'SEND_REPORTS',
    SEND_REPORT_API: 'SEND_REPORT_API',
    SEND_STATE_API: 'SEND_STATE_API',
    TASK_SELECT: 'TASK_SELECT',
    GET_CHECKLIST_DATA_API: 'GET_CHECKLIST_DATA_API',
    SMTP_VALIDATION_ERROR: 'SMTP_VALIDATION_ERROR',
};

export const DefaultMessage = {
    [MessageType.AUTH_ERROR_CONFIRMATION_CODE_INCORRECT]: 'The confirmation code was entered incorrectly. Try again or request a new code',
    [MessageType.AUTH_ERROR_CONFIRMATION_CODE_NOT_FOUND]: 'The confirmation code was not found or expired. Request a new one',
    [MessageType.AUTH_ERROR_EMAIL_CONFIRMATION_SEND]: 'Can\'t send mail. Check "infoMessageFrom" in configuration file',
    [MessageType.CREATE_TELEX_ATTACHMENT]: 'An error occurred while creating the telegram attachment',
    [MessageType.GET_AIRPORT_INFO_API]: 'Failed to load airport info',
    [MessageType.GET_DATA]: 'On get data from the server was error',
    [MessageType.GET_DEFECT_DOC_API]: 'Failed to download defect document',
    [MessageType.GET_DIRECTORY_LIST_API]: 'Failed to get directory list',
    [MessageType.GET_DOC_API]: 'Failed to download document',
    [MessageType.GET_DOC_API]: 'Failed to download document',
    [MessageType.GET_FILE]: 'An error occurred while getting the file',
    [MessageType.GET_LIBRARY_API]: 'Failed to load the library data',
    [MessageType.GET_LIBRARY_DATA]: 'Failed library data',
    [MessageType.GET_LIBRARY_DOC_API]: 'Failed to download library document',
    [MessageType.GET_NAV_ROUTE_DECLARAION_API]: 'Failed to download navigation route declaration',
    [MessageType.GET_NOTICE_DATA_API]: 'Failed to load the notice data',
    [MessageType.GET_ROUTES_DECLARATIONS]: 'Failed routes declarations',
    [MessageType.GET_TASK_DATA_API]: 'Failed to load the task data',
    [MessageType.GET_TASK_LIST_API]: "Failed to load the user's task list",
    [MessageType.GET_USER_DATA_API]: "Failed to load the user's info",
    [MessageType.GET_USER_PLAN_FLIGHT_API]: "Failed to load the user's plan flights",
    [MessageType.GET_USER_REPORTS_API]: "Failed to load the user's reports",
    [MessageType.LOAD_LIBRARY]: 'Failed to load library',
    [MessageType.REMOVE_DIR_API]: 'Failed to remove directory',
    [MessageType.REMOVE_DOC_API]: 'Failed to remove document',
    [MessageType.REMOVE_REPORT_API]: 'Failed to remove report, API error',
    [MessageType.REMOVE_TASK]: 'An error occurred while removing the task',
    [MessageType.RENAME_FILE_API]: 'Failed to rename document',
    [MessageType.SEND_DATA]: 'An error occurred while sending the data',
    [MessageType.SEND_DATA_API]: 'Failed to send data, API error',
    [MessageType.SEND_LOG_API]: 'Failed to send log',
    [MessageType.SEND_OTHER_DATA_API]: 'Failed to send other data, API error',
    [MessageType.SEND_REPORTS]: 'An error occurred while sending the reports',
    [MessageType.SEND_REPORT_API]: 'Failed to send report, API error',
    [MessageType.SEND_STATE_API]: 'Failed to send state',
    [MessageType.TASK_SELECT]: 'On task select was error',
    [MessageType.SMTP_VALIDATION_ERROR]: 'SMTP error. Check SMTP configuration',
};

export const toolbarMinHeight = 64;
