import React from 'react';

import { SvgIcon } from '../components/material-ui/SvgIcon';

const BaseIcon = ({ defaultStyle, className, style, color, height, width, ...props }) => {
    const viewBox = props.viewBox || '0 0 100 100';
    const initialStyle = {
        display: 'inline',
        verticalAlign: 'middle',
        enableBackground: `new ${viewBox}`,
        ...(defaultStyle || {}),
    };

    return (
        <SvgIcon
            x="0px"
            y="0px"
            {...props}
            style={{
                ...initialStyle,
                ...style,
                ...(color && { fill: color }),
                ...(height && { height }),
                ...(width && { width }),
            }}
            {...(className && { className })}
        >
            {props.children}
        </SvgIcon>
    );
};

export default BaseIcon;
