import React from 'react';

import { FormControl } from '../../../material-ui/FormControl';
import { InputLabel } from '../../../material-ui/InputLabel';
import { FormControlLabel } from '../../../material-ui/FormControlLabel';
import { RadioGroup } from '../../../material-ui/Radio/RadioGroup';
import { Radio } from '../../../material-ui/Radio/Radio';
import { useStyles } from './useStyles';
import { RADIO_GROUP_INITIAL } from '../../constants';

export const SidebarRadio = React.memo(({ filterValue, setFilterInputs, settings }) => {
    const classes = useStyles();
    const handleRadioGroup = React.useCallback(({ target }) => {
        setFilterInputs((prevState) => ({ ...prevState, [target.name]: +target.value }));
    }, []);

    return (
        <FormControl component="fieldset" className={classes.fieldset}>
            <InputLabel component="legend" className={classes.legend}>
                {settings.label}
            </InputLabel>
            <RadioGroup name={settings.name} value={filterValue || RADIO_GROUP_INITIAL} onChange={handleRadioGroup}>
                {settings.values.map((button) => (
                    <FormControlLabel
                        key={`radio${button.value}`}
                        className={classes.radioLabel}
                        control={<Radio color="primary" classes={{ root: classes.radio }} />}
                        label={button.label}
                        value={button.value}
                        checked={button.value === filterValue}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
});
