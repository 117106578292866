import React from 'react';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { List, ListItem, ListItemText } from '../../../../components/material-ui/List';
import { NoticeSidebarItem } from './NoticeSidebarItem/NoticeSidebarItem';
import { Collapse } from '../../../../components/material-ui/Collapse';
import { useStyles } from './useStyles';
import { Typography } from '../../../../components/material-ui/Typography';

const NoticeSidebarGroupItem = (props) => {
    const classes = useStyles(props.colorName)();
    const { name, airports = {}, type } = props;
    const airportIds = Object.keys(airports);
    const [open, toggleOpen] = React.useState(true);

    const handleClick = React.useCallback(() => {
        toggleOpen((prevState) => !prevState);
    }, []);

    return (
        <>
            {airportIds && airportIds.length > 0 && (
                <ListItem onClick={handleClick} className={classes.listItem} button={true}>
                    <ListItemText
                        inset={false}
                        primary={
                            <Typography variant="body1" className={classes.text}>
                                {name}
                            </Typography>
                        }
                    />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
            )}
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding={true}>
                    {airportIds &&
                        airportIds.map((key) => {
                            const airport = airports[key];

                            return (
                                <NoticeSidebarItem
                                    key={`${type}_${airport.id}`}
                                    number={airport.id}
                                    className={classes.nestedItem}
                                    airport={airport}
                                />
                            );
                        })}
                </List>
            </Collapse>
        </>
    );
};

const NoticeSidebarGroupItemMemoized = React.memo(NoticeSidebarGroupItem);

export { NoticeSidebarGroupItemMemoized as NoticeSidebarGroupItem };
