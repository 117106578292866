import React from 'react';
import classnames from 'classnames';

import { useStyles } from './useStyles';
import { Typography } from '../../../material-ui/Typography';

import './TextFieldStatic.scss';

const TextFieldStatic = (props) => {
    const classes = useStyles();
    const className = props.className || classes.container;

    return (
        <div className={className} style={{ margin: 0 }}>
            <Typography variant="body1" className={classnames('text-field__label', classes.label)}>
                {props.label}
            </Typography>
            <Typography variant="body1" className={classnames('text-field__value', classes.value)}>
                {props.value}
            </Typography>
        </div>
    );
};

export { TextFieldStatic };
