import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
    field: {
        flex: '0 1 19%',
        ['@media (max-width:440px)']: {
            flex: '1 1 50%',
        },
    },
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        paddingBottom: 2,
    },
}));
