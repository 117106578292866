import React from 'react';

import BaseIcon from '../BaseIcon';

const Library = (props) => (
    <BaseIcon {...props} viewBox="0 0 419.686 419.686">
        <path
            d="M415.436,279.342c-5.878-11.272-16.921-18.655-29.39-19.881V216.2l10.021-67.63c1.089-7.356-1.078-14.822-5.935-20.452
            c-4.552-5.276-11.043-8.449-17.962-8.828V65.966c0-25.979-21.135-47.116-47.114-47.116h-76.504
            c-25.459,0-38.974,10.751-48.843,18.601c-7.934,6.309-12.722,10.118-22.994,10.118H66.157c-23.151,0-41.985,18.356-41.985,40.918
            v30.803c-6.917,0.379-13.41,3.552-17.962,8.828c-4.857,5.63-7.023,13.096-5.934,20.452l30.686,207.117
            c1.857,12.537,12.62,21.824,25.295,21.824h243.668l5.135,7.361c6.977,9.996,18.416,15.963,30.604,15.963
            c12.188,0,23.63-5.968,30.609-15.967l46.683-66.919C420.94,306.501,421.888,291.706,415.436,279.342z M54.96,119.252V88.487
            c0-6.341,5.692-10.129,11.197-10.129h110.558c21.024,0,32.742-9.321,42.158-16.812c8.692-6.913,14.972-11.908,29.679-11.908h76.504
            c9.003,0,16.326,7.325,16.326,16.328v53.286H54.96z M393.811,304.605l-46.682,66.919c-2.618,3.752-6.904,5.987-11.48,5.987
            c-4.571,0-8.858-2.235-11.477-5.987l-46.682-66.919c-2.983-4.276-3.341-9.856-0.929-14.479c2.412-4.622,7.193-7.522,12.407-7.522
            h19.626v-57.977c0-7.73,6.267-13.994,13.995-13.994h26.123c7.728,0,13.996,6.264,13.996,13.994v57.979h19.624
            c5.215,0,9.996,2.899,12.409,7.522C397.152,294.749,396.793,300.329,393.811,304.605z"
        />
    </BaseIcon>
);

export default Library;
