import React from 'react';
import { useDispatch } from 'react-redux';

import { useStyles } from './useStyles';
import { Menu, MenuItem } from '../../material-ui/Menu';
import {
    cancelMultiedit,
    multieditFrom,
    multieditTo,
    removeEntry,
    revertDirection,
    showSpecialPointModal,
} from '../../../actions/navigation';
import { TYPES, useShowModal } from '../../../actions/modal';
import { ListItemIcon, ListItemText } from '../../material-ui/List';
import Comment from '../../../icons/image/Comment';
import Hold from '../../../icons/image/Hold';
import CheckAltitude from '../../../icons/image/CheckAltitude';
import Add from '../../../icons/image/Add';
import TwoArrowBifurcation from '../../../icons/image/TwoArrowBifurcation';
import Rubbish from '../../../icons/image/Rubbish';
import Edit from '../../../icons/image/Edit';
import { NavType } from '../constants';
import Direct from '../../../icons/image/Direct';
import RightRefresh from '../../../icons/image/RightRefresh';
import Download from '../../../icons/image/Download';
import Cancel from '../../../icons/image/Cancel';
import Divert from '../../../icons/image/Divert';
import { stopPropagation } from '../../service/eventHandlers';

const getModalType = (type, sign) => {
    return sign || type === NavType.TOC || type === NavType.TOD ? TYPES.SPECIAL_POINT : TYPES.POINT;
};

const validatePointCoordinates = ({ lat, long }) => {
    return !!(lat && long);
};

export const PointMenuTypes = {
    DEFAULT: 0,
    POINT: 1,
    DIRECTED: 2,
};

const RouteItemMenu = (props) => {
    const { offset, point, pos, type = PointMenuTypes.DEFAULT, onClose, anchorEl, ...rest } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const showModal = useShowModal();
    const handleRemoveEntry = React.useCallback(
        (event) => {
            stopPropagation(event);
            dispatch(removeEntry(pos));
            onClose();
        },
        [pos]
    );

    const handleCloseMenu = React.useCallback((event) => {
        stopPropagation(event);
        onClose();
    }, []);

    const handleShowSpecialPoint = React.useCallback(
        (event) => {
            stopPropagation(event);
            dispatch(
                showSpecialPointModal({
                    title: 'Add special point',
                    pos,
                    point,
                    isUserAdd: true,
                    isNewPoint: true,
                })
            );
            onClose();
        },
        [point, pos]
    );

    const handleShowCheckAltitude = React.useCallback(
        (event) => {
            stopPropagation(event);
            showModal(TYPES.CHECK_ALTITUDE, {
                title: 'Add check altitude',
                pos,
                point,
                isNewPoint: true,
            });
            onClose();
        },
        [point, pos]
    );

    const handleShowHold = React.useCallback(
        (event) => {
            stopPropagation(event);
            showModal(TYPES.HOLD, {
                title: 'Add hold',
                pos,
                point,
                isNewPoint: true,
            });
            onClose();
        },
        [pos, point]
    );

    const handleShowOffset = React.useCallback(
        (event) => {
            stopPropagation(event);
            showModal(TYPES.OFFSET, {
                title: 'SET OFFSET',
                pos,
                point,
            });
            onClose();
        },
        [pos, point]
    );

    const handleShowComment = React.useCallback(
        (event) => {
            stopPropagation(event);
            showModal(TYPES.COMMENT, {
                pos,
                point,
                isNewPoint: true,
            });
            onClose();
        },
        [pos, point]
    );

    const handleEditClick = React.useCallback(
        (event) => {
            stopPropagation(event);
            const { route, rnt } = point || {};
            const title = `Edit point ${route || rnt || ''} ${rnt && route && rnt !== route ? `(${rnt})` : ''} (full mode)`;

            showModal(getModalType(point.type, point.isUserAdd), {
                title,
                pos,
                point,
                isUserAdd: point.isUserAdd,
            });
            onClose();
        },
        [pos, point]
    );

    const handleDirect = React.useCallback(
        (event) => {
            stopPropagation(event);
            showModal(TYPES.DIRECT, {
                title: 'direct',
                pointPosFrom: pos,
            });
            onClose();
        },
        [pos]
    );

    const handleChangeDirection = React.useCallback(
        (event) => {
            stopPropagation(event);
            showModal(TYPES.DIRECT, {
                title: 'direct',
                pointPosFrom: pos,
                editMode: point.isRemoveFromDirecting,
            });
            onClose();
        },
        [pos, point]
    );

    const handleRevertDirection = React.useCallback(
        (event) => {
            stopPropagation(event);
            dispatch(revertDirection(pos));
            onClose();
        },
        [pos, point]
    );

    const handleToMultiedit = React.useCallback(
        (event) => {
            stopPropagation(event);
            dispatch(multieditTo(pos));
            showModal(TYPES.MULTIEDIT, {
                pos,
                point,
                title: 'Multiedit point',
                multieditTo: pos,
            });
            onClose();
        },
        [pos, point]
    );

    const handleMultiedit = React.useCallback(
        (event) => {
            stopPropagation(event);
            dispatch(multieditFrom(pos));
            onClose();
        },
        [pos]
    );

    const handleCancelMultiedit = React.useCallback((event) => {
        stopPropagation(event);
        dispatch(cancelMultiedit());
        onClose();
    }, []);

    const handleShowAlternateRoutes = React.useCallback(
        (event) => {
            stopPropagation(event);
            showModal(TYPES.ALTERNATE_ROUTE, {
                title: 'Alternate route',
                pos,
            });
            onClose();
        },
        [pos]
    );

    return (
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
            {type !== PointMenuTypes.DEFAULT && (
                <MenuItem onClick={handleEditClick}>
                    <ListItemIcon>
                        <Edit fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Edit" />
                </MenuItem>
            )}
            <MenuItem onClick={handleShowComment}>
                <ListItemIcon>
                    <Comment fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Add comment" />
            </MenuItem>
            {type !== PointMenuTypes.DIRECTED && (
                <MenuItem onClick={handleShowHold}>
                    <ListItemIcon>
                        <Hold fontSize="small" className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary="Add HOLD" />
                </MenuItem>
            )}
            <MenuItem onClick={handleShowCheckAltitude}>
                <ListItemIcon>
                    <CheckAltitude fontSize="small" className={classes.icon} />
                </ListItemIcon>
                <ListItemText primary="Check altitude" />
            </MenuItem>
            {type !== PointMenuTypes.DIRECTED && (
                <MenuItem onClick={handleShowSpecialPoint}>
                    <ListItemIcon>
                        <Add fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Add waypoint" />
                </MenuItem>
            )}
            <MenuItem onClick={handleShowOffset}>
                <ListItemIcon>
                    <TwoArrowBifurcation fontSize="small" />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <>
                            <span>OFFSET </span>
                            {offset && (
                                <span style={{ fontWeight: 'bold' }}>
                                    {offset > 0 ? 'R' : 'L'}
                                    {Math.abs(offset)}
                                </span>
                            )}
                        </>
                    }
                />
            </MenuItem>
            {type !== PointMenuTypes.DIRECTED && (
                <MenuItem onClick={handleRemoveEntry}>
                    <ListItemIcon>
                        <Rubbish fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Delete" />
                </MenuItem>
            )}
            {type === PointMenuTypes.POINT && (
                <MenuItem onClick={handleDirect} disabled={!validatePointCoordinates(point)}>
                    <ListItemIcon>
                        <Direct fontSize="small" className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary="Direct" />
                </MenuItem>
            )}
            {type === PointMenuTypes.POINT && point.isDirectionFrom && (
                <MenuItem onClick={handleRevertDirection}>
                    <ListItemIcon>
                        <RightRefresh fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Cancel Direction" />
                </MenuItem>
            )}
            {type === PointMenuTypes.POINT && rest.multieditFrom < pos && (
                <MenuItem onClick={handleToMultiedit}>
                    <ListItemIcon>
                        <Download fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Multiedit To" />
                </MenuItem>
            )}
            {type === PointMenuTypes.POINT && rest.multieditFrom === undefined && (
                <MenuItem onClick={handleMultiedit}>
                    <ListItemIcon>
                        <Edit fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Multiedit From" />
                </MenuItem>
            )}
            {type === PointMenuTypes.POINT && rest.multieditFrom !== undefined && (
                <MenuItem onClick={handleCancelMultiedit}>
                    <ListItemIcon>
                        <Cancel fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Cancel multiedit" />
                </MenuItem>
            )}
            {type === PointMenuTypes.POINT && (
                <MenuItem onClick={handleShowAlternateRoutes}>
                    <ListItemIcon>
                        <Divert fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Divert" />
                </MenuItem>
            )}
            {type === PointMenuTypes.DIRECTED && (
                <MenuItem onClick={handleChangeDirection}>
                    <ListItemIcon>
                        <Direct fontSize="small" className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary="Change direction" />
                </MenuItem>
            )}
        </Menu>
    );
};

const RouteItemMenuMemoized = React.memo(RouteItemMenu);

export { RouteItemMenuMemoized as RouteItemMenu };
