import React from 'react';

import { IconButton } from '../../material-ui/IconButton';
import { Settings } from '../../../icons';
import { useStyles } from './useStyles';
import { TextField } from '../../material-ui/TextField';
import { Switch } from '../../material-ui/Switch';
import { FormControlLabel } from '../../material-ui/FormControlLabel';
import { Button } from '../../material-ui/Button';
import { Popover } from '../../material-ui/Popover';
import { useAnchorEl } from '../../hooks/useAnchorEl';
import { darkFullWhite } from '../../../service/getMuiThemeStyle';

import '../LoginPage.scss';

const AppSettings = (props) => {
    const { onChangeSettings, onRestore } = props;
    const classes = useStyles();
    const [anchorEl, handleOpenMenu, handleClose] = useAnchorEl();

    const [settings, setSettings] = React.useState({
        backendUrl: props.backendUrl,
        backupInterval: props.backupInterval,
        ssl: props.ssl,
    });

    React.useEffect(() => {
        setSettings({
            backendUrl: props.backendUrl,
            backupInterval: props.backupInterval,
            ssl: props.ssl,
        });
    }, [props.backendUrl, props.backupInterval, props.ssl, anchorEl]);

    const { backendUrl, backupInterval, ssl } = settings;

    const handleSaveSettings = React.useCallback(() => {
        onChangeSettings(backendUrl, ssl, backupInterval);
        handleClose();
    }, [backendUrl, ssl, backupInterval]);

    const handleRestoreApp = React.useCallback(() => {
        onRestore({
            title: 'Restore',
        });
        handleClose();
    }, []);

    const handleChangeUrl = React.useCallback((evt) => {
        const value = evt.target.value;
        setSettings((prevState) => ({ ...prevState, backendUrl: value }));
    }, []);

    const handleChangeInterval = React.useCallback((evt) => {
        const value = evt.target.value;
        setSettings((prevState) => ({ ...prevState, backupInterval: value }));
    }, []);

    const handleToggleSsl = React.useCallback(() => {
        setSettings((prevState) => ({ ...prevState, ssl: !prevState.ssl }));
    }, []);

    return (
        <React.Fragment>
            <IconButton tooltip="Settings" onClick={handleOpenMenu} classes={{ root: classes.icon }}>
                <Settings width="25" height="25" color={darkFullWhite} />
            </IconButton>
            <Popover
                id="setting-popover"
                open={!!anchorEl}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={handleClose}
            >
                <form className={classes.inner} noValidate={true} autoComplete="off">
                    <FormControlLabel
                        className={classes.switch}
                        control={<Switch checked={ssl} onChange={handleToggleSsl} color="primary" />}
                        label="SSL"
                        labelPlacement="start"
                    />
                    <TextField required={true} label="Server name" onChange={handleChangeUrl} value={backendUrl} fullWidth={true} />
                    <TextField
                        required={true}
                        label="Backup data timeout (sec)"
                        onChange={handleChangeInterval}
                        value={backupInterval}
                        fullWidth={true}
                    />
                    <div className={classes.controls}>
                        <Button variant="contained" onClick={handleRestoreApp}>
                            RESTORE
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleSaveSettings}>
                            OK
                        </Button>
                    </div>
                </form>
            </Popover>
        </React.Fragment>
    );
};

const AppSettingsMemoized = React.memo(AppSettings);

export { AppSettingsMemoized as AppSettings };
