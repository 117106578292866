import React from 'react';
import { useDispatch } from 'react-redux';

import { useStyles } from './useStyles';
import { TakeOffTime } from './TakeOffTime/TakeOffTime';
import { StartEngineTime } from './StartEngineTime/StartEngineTime';
import { onChangeTime } from '../../actions/take-off';
import { CrewPanel } from './CrewPanel/CrewPanel';
import { TakeOffPalette } from './TakeOffPalette/TakeOffPalette';

const TakeOffPage = () => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleChangeTime = React.useCallback((name, value) => {
        dispatch(onChangeTime(name, value));
    }, []);

    return (
        <div className={classes.container}>
            <div className={classes.panelWrapper}>
                <StartEngineTime onChangeTime={handleChangeTime} className={classes.panel} />
                <CrewPanel />
                <TakeOffTime onChangeTime={handleChangeTime} className={classes.panel} />
            </div>
            <TakeOffPalette className={classes.form} />
        </div>
    );
};

const TakeOffPageMemoized = React.memo(TakeOffPage);
export { TakeOffPageMemoized as TakeOffPage };
