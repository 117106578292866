export const swipeDirections = {
    LEFT: 1,
    RIGHT: 2,
    UP: 4,
    DOWN: 8,
};
export const swipeConfig = {
    delta: 20,
    preventScrollOnSwipe: false,
    trackTouch: true,
    trackMouse: false,
    rotationAngle: 0,
    swipeDuration: Infinity,
    touchEventOptions: { passive: false },
};
