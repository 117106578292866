import * as React from 'react';
import { useSelector } from 'react-redux';

import { usePushNotification, NotificationTypes } from '../../../actions/notifications';
import { getTimesWarnings } from '../../../selectors/sended-data';
import { useStyles } from '../useStyles';
import { useCurrentLeg } from '../../../selectors/screen';
import { getAllTimeErrorsAsString } from '../../../service/NavPointsUtils';
import { Button } from '../../material-ui/Button';

const IncorrectButton = ({ name }) => {
    const classes = useStyles();
    const pushNotification = usePushNotification();
    const currentLegID = useCurrentLeg();
    const timeWarnings = useSelector((state) => getTimesWarnings(state))[currentLegID];
    const fields = timeWarnings[name];
    const allTimesWarning = React.useMemo(getAllTimeErrorsAsString(timeWarnings), [timeWarnings]);

    const handleClick = React.useCallback(() => {
        const notification = {
            variant: NotificationTypes.ERROR,
            message: 'The chronological sequence of operations is broken',
            details: 'Please check that all time fields are entered correctly',
        };

        if (fields.length) {
            notification.details = `Some of the entered times are incorrect. Please check «${fields[0]}» and «${fields[1]}» fields`;
        }

        if (!fields.length && allTimesWarning) {
            notification.details = `Some of the entered times are incorrect. Please check ${allTimesWarning} fields`;
        }

        pushNotification(notification);
    }, [allTimesWarning, fields]);

    return (
        <span className={classes.incorrectButton}>
            <span className={classes.textIncorrect}>Some of the entered times are incorrect</span>
            <Button variant="contained" color="primary" className={classes.button} onClick={handleClick}>
                details
            </Button>
        </span>
    );
};

export { IncorrectButton };
