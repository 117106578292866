import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { ActionTimeControl } from '../../ActionTimeControl/ActionTimeControl.jsx';
import { Clock } from '../../../icons';
import { MoreVertIconContrast } from '../../../icons/MoreVertIconContrast';
import { useStyles } from '../useStyles';
import { TYPES, useShowModal } from '../../../actions/modal';
import { getLastLeg } from '../../../selectors/screen';
import { onChangeTime } from '../../../actions/landing';
import { getPreviewWorkTime, getSubtotalWorkTime } from '../../../selectors/sended-data';
import { checkTimeIsCorrect } from '../../../service/NavPointsUtils';

const EndWorkTime = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isLastLeg, value, workTime, timeWorkAfter, legWorkTime } = useSelector((state) => {
        const currentLeg = get(state, 'screen.currentLeg');
        const currentData = get(state, `sendedData[${currentLeg}]`, {});
        const landing = get(currentData, 'landing', {});
        const lastLeg = getLastLeg(state) || {};
        const isLastLeg = lastLeg.id === currentLeg;
        const previewWorkTime = getPreviewWorkTime(state);
        const subtotalWorkTime = getSubtotalWorkTime(state);

        return {
            value: landing.endWorkTime,
            isLastLeg,
            legWorkTime: previewWorkTime.workTime,
            timeWorkAfter: previewWorkTime.timeWorkAfter,
            workTime: subtotalWorkTime.workTime,
        };
    }, shallowEqual);
    const showModal = useShowModal();

    const handleChangeTime = React.useCallback((name, value) => {
        dispatch(onChangeTime(name, value));
    }, []);
    const title = isLastLeg ? 'End duty time' : 'End leg time';
    const text = isLastLeg ? 'Duty time ended on' : 'Leg time ended on';

    const handleShowModal = React.useCallback((data) => {
        showModal(TYPES.DATE_TIME_PICKER, data);
    }, []);

    const summaryInfo = React.useMemo(() => {
        const res = [];

        if (timeWorkAfter) {
            res.push({
                title: 'Post-flight time ',
                value: timeWorkAfter,
                isCorrect: checkTimeIsCorrect(timeWorkAfter),
            });
        }

        res.push({
            title: 'Duty time on leg ',
            value: legWorkTime,
            isCorrect: checkTimeIsCorrect(legWorkTime),
        });

        res.push({
            title: isLastLeg ? 'Duty time ' : 'Subtotal duty time ',
            value: workTime,
            isCorrect: checkTimeIsCorrect(workTime),
        });

        return res;
    }, [workTime, timeWorkAfter, isLastLeg, legWorkTime]);

    return (
        <div className={classes.actionTime}>
            <ActionTimeControl
                title={title}
                buttonName={title}
                state="ENTER_COMPLETED"
                iconButton={<Clock width="22" height="22" />}
                iconMenuImg={<MoreVertIconContrast isContrast={true} />}
                text={text}
                name="endWorkTime"
                value={value}
                summary={summaryInfo}
                onChange={handleChangeTime}
                showModal={handleShowModal}
            />
        </div>
    );
};

const EndWorkTimeMemoized = React.memo(EndWorkTime);

export { EndWorkTimeMemoized as EndWorkTime };
