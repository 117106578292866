import { makeStyles } from '@material-ui/core';

export const useSwitchStyles = makeStyles((theme) => ({
    input: {
        left: 0,
    },
    root: {
        position: 'absolute',
        right: 0,
        display: 'inline-flex',
        width: 50,
        height: 18,
        padding: 0,
        alignItems: 'center',

        fontSize: 12,
        opacity: 0.85,

        '&:hover': {
            opacity: 0.75,
        },
    },
    switchBase: {
        padding: 2,
        color: theme.palette.background.paper,

        '&$checked': {
            color: theme.palette.background.paper,
            transform: 'translateX(32px)',
        },
        '&$checked + $track': {
            opacity: 1,
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.background.paper,
            '&:before': {
                opacity: 1,
            },
            '&:after': {
                opacity: 0,
            },
        },
        '&$checked + $input': {
            left: '-180%',
        },
    },
    thumb: {
        width: 14,
        height: 14,

        backgroundColor: theme.palette.background.paper,
        boxShadow: 'none',
    },
    track: {
        position: 'relative',

        backgroundColor: theme.palette.error.main,
        borderRadius: 7,
        opacity: 0.9,

        '&:before, &:after': {
            position: 'absolute',
            top: '50%',
            display: 'inline-block',
            width: '50%',

            textAlign: 'center',
            fontSize: 12,
            color: theme.palette.background.paper,
            transform: 'translateY(-50%)',
        },
        '&:before': {
            content: (props) => `"${props.before}"`,
            left: 2,
            opacity: 0,
        },
        '&:after': {
            content: (props) => `"${props.after}"`,
            right: 5,
        },
    },
}));
