import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => {
    const {
        flatButton: { warningColor },
        messageDialog: { errorColor, successColor },
    } = theme;

    return {
        content: {
            whiteSpace: 'pre-line',
        },
        title: {
            backgroundColor: 'transparent',
        },
        titleError: {
            backgroundColor: errorColor,
        },
        titleSuccess: {
            backgroundColor: successColor,
        },
        warningButton: {
            color: warningColor,
        },
        wrapper: {
            zIndex: '1400 !important',
        },
    };
});
