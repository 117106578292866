import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    chip: {
        margin: 4,
    },
    captainCommentWrapper: {
        padding: theme.spacing(0, 0, 1),
        borderBottom: `2px solid ${theme.palette.borderColor}`,
        marginBottom: '8px',
    },
    captainCommentTitle: {
        fontWeight: 500,
    },
    captainText: {
        whiteSpace: 'pre-wrap',
    },
    content: {
        margin: theme.spacing(7, 1, 1),
        padding: 0,
        overflow: 'auto',
        overflowScrolling: 'touch',
        webkitOverflowScrolling: 'touch',
    },
    highlighted: {
        color: theme.icon.contrastColor,
    },
    inner: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: '70px 6px 0',
    },
    input: {
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(2),

        [theme.breakpoints.down('xs')]: {
            width: '100%',
            paddingTop: 0,
            marginRight: theme.spacing(1.5),
            marginLeft: theme.spacing(1),
            marginBottom: theme.spacing(1),
            order: 10,
        },
    },
    invisible: {
        opacity: 0,
    },
    message: {
        margin: 0,
        whiteSpace: 'pre',
        fontFamily: 'Monospace',
        userSelect: 'text',
    },
    margin: {
        marginRight: theme.spacing(1),

        [theme.breakpoints.down(400)]: {
            marginRight: 0,
        },
    },
    search: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2),
        width: '550px',

        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            width: '97%',
            marginTop: theme.spacing(0.5),
            padding: theme.spacing(0.5),
        },
    },
    status: {
        marginRight: theme.spacing(1),
        color: theme.palette.disabledColor,
    },
    tags: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(1),
    },
    title: {
        marginRight: 'auto',
        fontSize: 18,
        fontWeight: 600,

        [theme.breakpoints.down(400)]: {
            display: 'none',
        },
    },
    toolbar: {
        [theme.breakpoints.down(400)]: {
            paddingLeft: 0,
        },
    },
    filter: ({ isDarkTheme }) => ({
        backgroundColor: isDarkTheme ? theme.palette.grey[700] : 'white',
        verticalAlign: 'bottom',
    }),
}));

export { useStyles };
