import React from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Step, StepButton } from '../../../material-ui/Stepper';
import { useStyles } from './useStyles';
import { setWizardStep } from '../../../../actions/wizard';
import { get } from 'lodash';

function FlightWizardStep({ index, name, icon, disabled }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const handleSetStep = React.useCallback(() => {
        dispatch(setWizardStep(index));
    }, [index]);

    const active = useSelector((state) => get(state, 'wizard.wizardStep', 0) === index);

    return (
        <Step {...{ disabled, active }} className={classes.step}>
            <StepButton
                key={index}
                onClick={handleSetStep}
                className={classnames(classes.stepButton, active && classes.active, name && name.length > 6 && classes.stepButtonLong)}
                icon={icon || index + 1}
            >
                {name}
            </StepButton>
        </Step>
    );
}

const FlightWizardStepMemoized = React.memo(FlightWizardStep);

export { FlightWizardStepMemoized as FlightWizardStep };
