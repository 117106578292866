import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    underlineStyle: {
        borderColor: 'rgba(0, 0, 0, 0.2)',
    },
    wrapper: {
        flex: '0 1 300px',

        ['@media only screen and (max-width: 480px)']: {
            flex: '1 1 200px !important',
            order: 3,
        },

        '&:hover svg': {
            fill: 'rgba(0, 0, 0, 1)',
        },
        '& .Mui-focused svg': {
            fill: 'rgba(0, 0, 0, 1)',
        },
    },
    style: {
        fontSize: '18px',
    },
    icon: {
        top: '0px',
        fill: 'rgba(0, 0, 0, 0.3)',
    },
}));
