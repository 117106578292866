import { get } from 'lodash';

import {
    EDIT_TAKE_OFF_INFORMATION_FIELD,
    ON_CHANGE_TAKE_OFF_TIME,
    EDIT_TAKE_OFF_FIELD,
    EDIT_PASSENGER_INFO,
    EDIT_LOAD_INFO,
    TOGGLE_TOTAL_EDITABLE,
} from '../actions/take-off';
import { EDIT_LANDING_INFORMATION_FIELD, ON_CHANGE_LANDING_TIME, EDIT_LANDING_FIELD } from '../actions/landing';
import {
    SAVE_POINT_DATA,
    SAVE_SPECIAL_POINT_DATA,
    SAVE_COMMENT_DATA,
    SAVE_CHECK_ALTITUDE_DATA,
    SAVE_HOLD_DATA,
    REMOVE_ENTRY,
    DIRECT,
    REVERT_DIRECTION,
    SET_POINTS,
    SELECT_ALTERNATE_ROUTES,
    MULTIEDIT_POINTS,
} from '../actions/navigation';
import { RESIGN_OFP, SET_OFP } from '../actions/startInfo';
import { UPDATE_LEG_POINT_IDS, UPDATE_SENDED_DATA, REMOVE_DELETED_POINTS, CONVERT_WEIGHT } from '../actions/sended-data';
import { LOGOUT } from '../actions/screen';
import {
    recalculatePointsAfterDirection,
    recalculateTotalFuel,
    recalculatePointsAfterTakeOff,
    findPrevPointPos,
    findNextPointPos,
    calculateAlternateRoute,
    isActiveEntry,
    getTimeFormat,
    updateLastPoint,
    direct,
    revertDirection,
    calculateDistance,
} from '../service/NavPointsUtils';
import { CLEAR_SENDED_DATA, CLEAR_SENDED_DATA_LIST, EDIT_OFP_FIELD } from '../actions/sended-data';
import { SIGN_TELEGRAM } from '../actions/telegrams';
import { SIGN_TELEX } from '../actions/telex';
import { getTimeDiff } from '../service/DateTimeUtils';
import { CHANGE_CREW_INFO, GENERATE_CREW_INFO } from '../actions/buffer-crew';
import { CHANGE_GPS_POSITION } from '../actions/gps';
import { NavType } from '../components/NavigationRoutes/constants';
import { convertFields, weightConverter } from '../service/measureConverter';
import { Weight } from './systemOfMeasurement';

const BaggagePayInternalCode = 5;

const initialState = {};

function getPayLoad(leg) {
    let res = 0;

    if (leg.takeOff && leg.takeOff.passengerInfo) {
        res += Object.keys(leg.takeOff.passengerInfo).reduce((res, key) => {
            const { weight = 0, number = 0, luggage = 0, baggage = 0, handLuggage = 0 } = leg.takeOff.passengerInfo[key] || {};
            res += weight * number + luggage + baggage + handLuggage;

            return res;
        }, 0);
    }

    if (leg.takeOff && leg.takeOff.loadInfo) {
        res += Object.keys(leg.takeOff.loadInfo).reduce((res, key) => {
            const {
                weight = 0,
                type: { internal = 0 },
            } = leg.takeOff.loadInfo[key] || {};
            if (internal !== BaggagePayInternalCode) {
                res += weight;
            }

            return res;
        }, 0);
    }

    return Math.round(res);
}

function getMaxLoading(leg) {
    const {
        takeOffWeightMax = 0,
        landingWeightMax = 0,
        tripFuel = 0,
        dryOperatingWeight = 0,
        zeroFuelWeightMax = 0,
        takeOff: { totalOnBoard = 0, expenseLandTakeoff = 0, expenseBeforeTakeoff = 0 },
    } = leg;
    const tof = totalOnBoard - expenseLandTakeoff - expenseBeforeTakeoff;
    const values = [];
    if (takeOffWeightMax) {
        values.push(takeOffWeightMax);
    }

    if (landingWeightMax && tripFuel) {
        values.push(landingWeightMax + tripFuel);
    }

    if (zeroFuelWeightMax && tof) {
        values.push(zeroFuelWeightMax + tof);
    }

    const result = Math.min(...values) - dryOperatingWeight - tof;

    return result > 0 ? result : 0;
}

function calculateFlightTime(leg) {
    const takeOffTime = leg.takeOff.takeOffTime && new Date(leg.takeOff.takeOffTime);
    const landingTime = leg.landing.landingTime && new Date(leg.landing.landingTime);

    return takeOffTime && landingTime && landingTime > takeOffTime && Math.round((landingTime - takeOffTime) / 1000);
}

function updateTotalLoadWeight(leg, changedFieldName) {
    const {
        takeOff: { expenseLandTakeoff = 0, expenseBeforeTakeoff = 0, totalOnBoard = 0 },
        dryOperatingWeight = 0,
        extraCrewWeight = 0,
        takeOffWeightReal = 0,
        tripFuel = 0,
        zeroFuelWeightReal = 0,
        landingWeightReal = 0,
    } = leg;
    const fuelWithoutExpense = totalOnBoard - expenseLandTakeoff - expenseBeforeTakeoff;

    if (leg.isTotalEditable) {
        const payLoadReal = leg.payLoadReal || 0;
        // если стоит галка ручного ввода, то пересчет локальный делаем.
        if (changedFieldName === 'payLoadReal' || changedFieldName === 'extraCrewWeight' || changedFieldName === 'dryOperatingWeight') {
            leg.zeroFuelWeightReal = dryOperatingWeight + extraCrewWeight + payLoadReal;
            leg.takeOffWeightReal = leg.zeroFuelWeightReal + fuelWithoutExpense;
            leg.landingWeightReal = leg.takeOffWeightReal - tripFuel;
        }

        if (changedFieldName === 'tripFuel') {
            leg.landingWeightReal = takeOffWeightReal - tripFuel;
        }

        if (changedFieldName === 'zeroFuelWeightReal') {
            leg.takeOffWeightReal = zeroFuelWeightReal + fuelWithoutExpense;
            leg.landingWeightReal = leg.takeOffWeightReal - tripFuel;
        }

        if (changedFieldName === 'takeOffWeightReal') {
            leg.landingWeightReal = takeOffWeightReal - tripFuel;
        }

        if (changedFieldName === 'landingWeightReal') {
            leg.tripFuel = takeOffWeightReal - landingWeightReal;
        }

        if (
            changedFieldName === 'dryOperatingWeight' ||
            changedFieldName === 'zeroFuelWeightMax' ||
            changedFieldName === 'takeOffWeightMax' ||
            changedFieldName === 'landingWeightMax'
        ) {
            leg.maxLoading = getMaxLoading(leg);
        }
    } else {
        const payLoadReal = getPayLoad(leg);

        leg.takeOffWeightReal = dryOperatingWeight + extraCrewWeight + payLoadReal + fuelWithoutExpense;
        leg.payLoadReal = payLoadReal;
        leg.extraCrewWeight = extraCrewWeight;
        leg.zeroFuelWeightReal = dryOperatingWeight + extraCrewWeight + payLoadReal;
        leg.maxLoading = getMaxLoading(leg);
        leg.landingWeightReal = leg.takeOffWeightReal - tripFuel;
    }

    if (leg.landingWeightReal < 0) {
        leg.landingWeightReal = 0;
    }

    if (leg.takeOff) {
        leg.takeOff.informationForm = Object.assign({}, leg.takeOff.informationForm, { tow: leg.takeOffWeightReal });
    }
}

function removeEntry(points, pos) {
    if (!points[pos].pointId) {
        points.splice(pos, 1);
    } else {
        points[pos] = Object.assign({}, points[pos], { isDeleted: true });
    }
}

export function removeLinkedEntries(points, pointNumber) {
    const newPoints = [];
    points.forEach((item) => {
        if (item.pointNumber === pointNumber && isActiveEntry(item) && (item.type == NavType.COMMENT || item.type == NavType.ALTITUDE)) {
            if (item.pointId) {
                newPoints.push(Object.assign({}, item, { isDeleted: true }));
            }
            //else entry should delete
        } else {
            newPoints.push(item);
        }
    }, 0);

    return newPoints;
}

export function initialLeg(state, { legId, type }) {
    let leg;

    if (legId !== undefined) {
        leg = Object.assign(
            {
                takeOff: {
                    totalOnBoard: 0,
                },
                landing: {},
                points: [],
                isTotalEditable: false,
                telegramWithSignature: [],
                weightUnits: Weight.KG,
            },
            state[legId]
        );
    }

    if (
        legId !== undefined &&
        type !== SET_OFP &&
        type !== SIGN_TELEGRAM &&
        type !== SIGN_TELEX &&
        type !== SET_POINTS &&
        type !== CONVERT_WEIGHT
    ) {
        leg.hasChanges = true;
    }

    return leg;
}

export default (state = initialState, action) => {
    const newState = Object.assign({}, state);
    let leg = initialLeg(newState, action);

    switch (action.type) {
        case SET_OFP:
            leg.ofpId = action.ofpId;
            leg.signature = action.signature;
            leg.hasOfpSignatureChanges = true;
            leg.userId = action.userId;
            leg.fuelFlowAvg = action.currentOfp.fuelFlowAvg;
            leg.minFuelMAPt = action.currentOfp.minFuelMAPt;
            leg.holdALTN = action.currentOfp.holdALTN;
            leg.distanceEstimate = action.currentOfp.distanceEstimate;
            leg.tripFuel = action.currentOfp.tripFuel;
            leg.taxiFuel = action.currentOfp.taxiFuel;

            leg.dryOperatingWeight = action.currentOfp.dryOperatingWeight || 0;
            leg.payLoadEstimate = action.currentOfp.payLoadEstimate;
            leg.payLoadReal = action.currentOfp.payLoadReal;
            leg.zeroFuelWeightEstimate = action.currentOfp.zeroFuelWeightEstimate;
            leg.zeroFuelWeightReal = action.currentOfp.zeroFuelWeightReal;
            leg.zeroFuelWeightMax = action.currentOfp.zeroFuelWeightMax;
            leg.takeOffWeightEstimate = action.currentOfp.takeOffWeightEstimate;
            leg.takeOffWeightReal = action.currentOfp.takeOffWeightReal;
            leg.takeOffWeightMax = action.currentOfp.takeOffWeightMax;
            leg.extraCrewWeight = action.extraCrewWeight || 0;
            leg.landingWeightEstimate = action.currentOfp.landingWeightEstimate;
            leg.landingWeightReal = action.currentOfp.landingWeightReal;
            leg.landingWeightMax = action.currentOfp.landingWeightMax;
            leg.signComment = action.signComment;
            leg.isTotalEditable = get(action, 'options.manualEdit', false);
            leg.weightUnits = action.currentOfp.weightUnits;

            if (!leg.dryOperatingWeight && leg.zeroFuelWeightEstimate && leg.payLoadEstimate) {
                leg.dryOperatingWeight = leg.zeroFuelWeightEstimate - leg.payLoadEstimate;
            }

            if (!leg.payLoadEstimate && leg.zeroFuelWeightEstimate && leg.dryOperatingWeight) {
                leg.payLoadEstimate = leg.zeroFuelWeightEstimate - leg.dryOperatingWeight;
            }

            if (!leg.zeroFuelWeightEstimate && leg.payLoadEstimate && leg.dryOperatingWeight) {
                leg.zeroFuelWeightEstimate = leg.payLoadEstimate + leg.dryOperatingWeight;
            }
            if (action.currentOfp.onBoardFuel) {
                leg.onBoardFuel = action.currentOfp.onBoardFuel;
            }
            if (action.currentOfp.taxiFuel && action.options.preloadTaxi) {
                leg.takeOff.expenseBeforeTakeoff = action.currentOfp.taxiFuel;
            }
            if (action.currentOfp.componovka) {
                leg.takeOff.componovka = action.currentOfp.componovka;
            }
            leg.landing = {
                distance: action.currentOfp.distanceEstimate,
                ...leg.landing,
                landings: 1,
                landingsOnDevices: 1,
            };
            newState[action.legId] = leg;

            return newState;
        case RESIGN_OFP:
            leg.ofpId = action.ofpId;
            leg.signature = action.signature;
            leg.hasOfpSignatureChanges = true;
            leg.userId = action.userId;
            leg.signComment = action.signComment;
            leg.weightUnits = action.weightUnits;
            newState[action.legId] = leg;

            return newState;
        case TOGGLE_TOTAL_EDITABLE:
            leg.isTotalEditable = !newState[action.legId].isTotalEditable;
            newState[action.legId] = leg;
            if (!leg.isTotalEditable) {
                updateTotalLoadWeight(leg);
            }

            return newState;
        case EDIT_OFP_FIELD:
            leg[action.name] = action.value;
            leg.userId = action.userId;
            updateTotalLoadWeight(leg, action.name);
            newState[action.legId] = leg;

            return newState;
        case EDIT_TAKE_OFF_INFORMATION_FIELD:
            leg.takeOff.informationForm = Object.assign({}, leg.takeOff.informationForm, {
                [action.name]: action.value,
            });
            leg.userId = action.userId;
            newState[action.legId] = leg;

            return newState;
        case ON_CHANGE_TAKE_OFF_TIME: {
            const prevLeg = action.prevLegId && initialLeg(newState, { legId: action.prevLegId, type: action.type });
            leg.takeOff = Object.assign({}, leg.takeOff, { [action.name]: action.value });
            leg.userId = action.userId;

            if (action.name === 'takeOffTime') {
                leg.points = recalculatePointsAfterTakeOff(leg.points, action.value);
                leg.landing.timeOnDevices = calculateFlightTime(leg);
            }

            // Если окончание лега и начало следующего отличаются, то появляется рабочее время.
            if (prevLeg) {
                const timeWorkAfter = getTimeFormat(getTimeDiff(prevLeg.landing.stopEngineTime, prevLeg.landing.endWorkTime));
                const timeWorkRest = getTimeDiff(prevLeg.landing.endWorkTime, leg.takeOff.startWorkTime);
                if (!timeWorkAfter && !timeWorkRest && leg.takeOff.startEngineTime) {
                    leg.landing.timeWorkParking = getTimeDiff(prevLeg.landing.stopEngineTime, leg.takeOff.startEngineTime);
                } else if ((timeWorkAfter || timeWorkRest) && leg.takeOff.startEngineTime) {
                    leg.landing.timeWorkParking = 0;
                }
            }

            newState[action.legId] = leg;

            return newState;
        }
        case EDIT_LANDING_INFORMATION_FIELD:
            leg.landing.informationForm = Object.assign({}, leg.landing.informationForm, {
                [action.name]: action.value,
            });
            leg.userId = action.userId;
            newState[action.legId] = leg;

            return newState;

        case ON_CHANGE_LANDING_TIME: {
            const nextLeg = action.nextLegId && initialLeg(newState, { legId: action.nextLegId, type: action.type });
            const needEndWorkTimeUpdate =
                nextLeg &&
                leg.landing.endWorkTime &&
                leg.landing.stopEngineTime &&
                new Date(leg.landing.endWorkTime).toString() === new Date(leg.landing.stopEngineTime).toString();
            const needStartWorkTimeUpdate =
                nextLeg &&
                nextLeg.takeOff.startWorkTime &&
                leg.landing.endWorkTime &&
                new Date(nextLeg.takeOff.startWorkTime).toString() === new Date(leg.landing.endWorkTime).toString();

            leg.landing = Object.assign({}, leg.landing, { [action.name]: action.value });
            leg.userId = action.userId;
            if (action.name === 'landingTime') {
                leg.landing.timeOnDevices = calculateFlightTime(leg);
                leg.points = updateLastPoint(leg.points, { ato: action.value });
            }

            // Выполняем для всех, кроме крайнего лега
            if (nextLeg && action.name === 'stopEngineTime' && (!leg.landing.endWorkTime || needEndWorkTimeUpdate)) {
                leg.landing.endWorkTime = action.value;
            }

            if (nextLeg && (action.name === 'stopEngineTime' || action.name === 'endWorkTime')) {
                const timeWorkAfter = getTimeFormat(getTimeDiff(leg.landing.stopEngineTime, leg.landing.endWorkTime));

                if (!nextLeg.takeOff.startWorkTime || needStartWorkTimeUpdate) {
                    nextLeg.takeOff.startWorkTime = leg.landing.endWorkTime;
                    newState[action.nextLegId] = nextLeg;
                }

                // Если окончание лега и начало следующего отличаются, то появляется рабочее время.
                const timeWorkRest = getTimeDiff(leg.landing.endWorkTime, nextLeg.takeOff.startWorkTime);
                if (!timeWorkAfter && !timeWorkRest && nextLeg.takeOff.startEngineTime) {
                    nextLeg.landing.timeWorkParking = getTimeDiff(leg.landing.stopEngineTime, nextLeg.takeOff.startEngineTime);
                } else if ((timeWorkAfter || timeWorkRest) && nextLeg.takeOff.startEngineTime) {
                    nextLeg.landing.timeWorkParking = 0;
                }
            }

            newState[action.legId] = leg;

            return newState;
        }
        case EDIT_LANDING_FIELD:
            leg.landing = Object.assign({}, leg.landing, { [action.name]: action.value });
            leg.userId = action.userId;
            if (action.name === 'expenseLandLanding' || action.name === 'expenseAfterLanding' || action.name === 'remainLanding') {
                const { expenseLandLanding = 0, expenseAfterLanding = 0, remainLanding = 0 } = leg.landing;
                leg.points = updateLastPoint(leg.points, { act: expenseLandLanding + expenseAfterLanding + remainLanding });

                // Обновление информации по ALW
                if (leg.takeOff && leg.takeOff.informationForm && leg.takeOff.informationForm.tow) {
                    if (!leg.landing.informationForm) {
                        leg.landing.informationForm = {};
                    }

                    const { totalOnBoard = 0, expenseLandTakeoff = 0, expenseBeforeTakeoff = 0 } = leg.takeOff || {};
                    const tof = +totalOnBoard - +expenseLandTakeoff - +expenseBeforeTakeoff;
                    leg.landing.informationForm.lw =
                        +leg.takeOff.informationForm.tow - tof + remainLanding + expenseLandLanding + expenseAfterLanding;
                }
            }

            newState[action.legId] = leg;

            return newState;

        case EDIT_TAKE_OFF_FIELD: {
            const fieldForCalculation = ['remainTakeoff', 'refuelingTakeoff', 'expenseLandTakeoff', 'expenseBeforeTakeoff', 'taxiFuel'];
            leg.takeOff = Object.assign({}, leg.takeOff, { [action.name]: action.value });
            leg.userId = action.userId;
            if (fieldForCalculation.indexOf(action.name) !== -1) {
                const remainTakeoff = leg.takeOff['remainTakeoff'] || 0;
                const refuelingTakeoff = leg.takeOff['refuelingTakeoff'] || 0;
                const expenseLandTakeoff = leg.takeOff['expenseLandTakeoff'] || 0;
                const expenseBeforeTakeoff = leg.takeOff['expenseBeforeTakeoff'] || 0;

                leg.takeOff.totalOnBoard = remainTakeoff + refuelingTakeoff;
                const fuel = leg.takeOff.totalOnBoard - expenseLandTakeoff - expenseBeforeTakeoff;
                if (fuel > 0) {
                    leg.points = recalculateTotalFuel(leg.points, fuel / 1000);
                }
                updateTotalLoadWeight(leg);
            }
            newState[action.legId] = leg;

            return newState;
        }
        case EDIT_PASSENGER_INFO: {
            leg.takeOff.passengerInfo = Object.assign({}, leg.takeOff.passengerInfo);
            let passengerItemKey =
                Object.keys(leg.takeOff.passengerInfo).find((key) => {
                    const item = leg.takeOff.passengerInfo[key];

                    return item.type.id === action.passengerType.id && item.cls.id === action.passengerClass.id;
                }) || Object.keys(leg.takeOff.passengerInfo).length;

            leg.takeOff.passengerInfo[passengerItemKey] = Object.assign({}, leg.takeOff.passengerInfo[passengerItemKey], {
                cls: action.passengerClass,
                type: action.passengerType,
            });
            leg.takeOff.passengerInfo[passengerItemKey].userId = action.userId;
            leg.takeOff.passengerInfo[passengerItemKey][action.name] = action.value;
            leg.takeOff.passengerInfo[passengerItemKey]['weight'] = action.weight;

            updateTotalLoadWeight(leg);
            newState[action.legId] = leg;

            return newState;
        }
        case EDIT_LOAD_INFO: {
            leg.takeOff.loadInfo = leg.takeOff.loadInfo ? leg.takeOff.loadInfo.slice() : [];

            let itemKey = leg.takeOff.loadInfo.findIndex((item) => {
                return item.type.id === action.loadType.id;
            });
            itemKey = itemKey === -1 ? leg.takeOff.loadInfo.length : itemKey;
            leg.takeOff.loadInfo[itemKey] = Object.assign({}, leg.takeOff.loadInfo[itemKey], { type: action.loadType });
            leg.takeOff.loadInfo[itemKey].userId = action.userId;
            leg.takeOff.loadInfo[itemKey][action.name] = action.value;

            updateTotalLoadWeight(leg);
            newState[action.legId] = leg;

            return newState;
        }
        case SAVE_POINT_DATA:
        case SAVE_SPECIAL_POINT_DATA:
        case SAVE_COMMENT_DATA:
        case SAVE_CHECK_ALTITUDE_DATA:
        case SAVE_HOLD_DATA:
            return action.newState;
        case REMOVE_ENTRY: {
            const { type, pointNumber } = leg.points[action.pointPos];
            leg.points = [].concat(leg.points);

            leg.points = recalculatePointsAfterDirection(
                leg.points,
                findPrevPointPos(leg.points, action.pointPos),
                findNextPointPos(leg.points, action.pointPos),
                action.fuelFlowAvg
            );

            removeEntry(leg.points, action.pointPos);

            if (type !== NavType.COMMENT && type !== NavType.ALTITUDE) {
                leg.points = removeLinkedEntries(leg.points, pointNumber);

                Object.keys(leg.points).forEach((key) => {
                    const point = leg.points[key];
                    if (key > action.pointPos) {
                        point.pointNumber -= 1;
                        leg.userId = action.userId;
                    }
                });
            }
            leg.landing.distance = calculateDistance(leg.points);
            newState[action.legId] = leg;

            return newState;
        }
        case REMOVE_DELETED_POINTS:
            Object.keys(action.legsData).forEach((legId) => {
                const leg = Object.assign({}, newState[legId]);

                leg.points = leg.points.filter((point) => !point.isDeleted);
                leg.landing.distance = calculateDistance(leg.points);
                newState[legId] = leg;
            });

            return newState;

        case DIRECT: {
            // Если было ранее спрямление - отменяем его и затем делаем новое.
            const points = revertDirection(leg.points, action);

            leg.points = direct(points, action.pointPosFrom, action.pointPosTo, action.fuelFlowAvg, action.userId);
            leg.landing.distance = calculateDistance(leg.points);

            newState[action.legId] = leg;
            return newState;
        }
        case REVERT_DIRECTION: {
            const points = revertDirection(leg.points, action);
            if (points) {
                leg.points = points;
                leg.landing.distance = calculateDistance(leg.points);
                newState[action.legId] = leg;
                return newState;
            }

            return state;
        }
        case SET_POINTS:
            leg.points = recalculateTotalFuel(
                action.points,
                (leg.convertedTo === Weight.LBS
                    ? weightConverter({
                          value: leg.takeOff.totalOnBoard,
                          currentDimension: Weight.KG,
                      })
                    : leg.takeOff.totalOnBoard) / 1000
            );
            leg.points.forEach((point) => {
                point.userId = action.userId;
            });
            newState[action.legId] = leg;

            return newState;

        case UPDATE_LEG_POINT_IDS:
            Object.keys(action.legsData).forEach((legId) => {
                const ids = action.legsData[legId].pointsIds;
                const points = newState[legId].points.map((point, pos) => Object.assign({}, point, { pointId: ids[pos] }));

                newState[legId] = Object.assign(newState[legId], { points });
            });

            return newState;

        case UPDATE_SENDED_DATA:
            return Object.assign({}, newState, action.sendedData);

        case MULTIEDIT_POINTS: {
            const { points } = newState[action.legId];

            for (let i = action.multieditFrom; i <= action.multieditTo; i++) {
                if (isActiveEntry(points[i])) {
                    points[i] = {
                        ...points[i],
                        ...action.data,
                        userId: action.userId,
                    };
                }
            }

            newState[action.legId] = Object.assign(newState[action.legId], { points });

            return newState;
        }
        case SELECT_ALTERNATE_ROUTES:
            leg.points = calculateAlternateRoute(leg, action);
            newState[action.legId] = leg;

            return newState;
        case SIGN_TELEGRAM: {
            const telegramWithSignature = [...leg.telegramWithSignature];
            if (telegramWithSignature.indexOf(action.id) === -1) {
                telegramWithSignature.push(action.id);
            }
            newState[action.legId] = {
                ...leg,
                telegramWithSignature,
                hasTelegramSignChanges: true,
            };

            return newState;
        }
        case SIGN_TELEX: {
            const telegramWithSignature = [...leg.telegramWithSignature];
            if (telegramWithSignature.indexOf(action.id) === -1) {
                telegramWithSignature.push(action.id);
            }
            newState[action.legId] = {
                ...leg,
                telegramWithSignature,
                hasTelegramSignChanges: true,
            };

            return newState;
        }
        case CLEAR_SENDED_DATA:
            delete newState[action.id];

            return newState;
        case CLEAR_SENDED_DATA_LIST:
            if (action.ids) {
                action.ids.forEach((id) => {
                    delete newState[id];
                });
            }

            return newState;
        case GENERATE_CREW_INFO:
            if (action.updateForSendedData) {
                action.updateForSendedData.forEach(({ id, bufferCrew, hasChanges }) => {
                    if (hasChanges && newState[id]) {
                        newState[id] = {
                            ...newState[id],
                            bufferCrew,
                            hasChanges: true,
                            userId: action.userId,
                        };
                    }
                });
            }

            return newState;
        case CHANGE_CREW_INFO:
            newState[action.legId].bufferCrew = {
                ...(newState[action.legId].bufferCrew || {}),
            };
            newState[action.legId].bufferCrew[action.persId] = {
                ...(newState[action.legId].bufferCrew[action.persId] || {}),
                ...(action.data || {}),
                isBlock: true,
            };

            newState[action.legId].hasChanges = true;

            return newState;
        case CHANGE_GPS_POSITION:
            if (!action.currentPosition) {
                return state;
            }
            leg.currentPosition = action.currentPosition;

            newState[action.legId] = leg;
            return newState;
        case CONVERT_WEIGHT:
            leg = convertFields({
                value: leg,
                fieldsForConvert: action.fieldsForConvert,
                weightDimension: action.convertTo,
                options: {
                    precision: {
                        fields: ['est', 'est_default', 'act', 'used', 'used_default'],
                        toFixed: 2,
                    },
                    ...(action.options || {}),
                },
            });
            leg.convertedTo = action.convertTo;

            newState[action.legId] = leg;

            return newState;
        case LOGOUT:
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
