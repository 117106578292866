import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => {
    const { borderColor } = theme.palette;

    return {
        bold: {
            fontWeight: 600,
        },
        listItemWithBorderBottom: {
            borderBottom: `3px solid ${borderColor}`,
            fontWeight: 'bold',
            fontSize: '18px',
            lineHeight: '18px',
        },
        listItemWithBorder: {
            borderTop: `1px solid ${borderColor}`,
            fontSize: '18px',
            lineHeight: '18px',
        },
        toolbar: theme.mixins.toolbar,
    };
});

const getStyles = (theme) => {
    const { borderColor } = theme.palette;

    return {
        menuIcon: {
            target: {
                horizontal: 'right',
                vertical: 'top',
            },
            anchor: {
                horizontal: 'right',
                vertical: 'bottom',
            },
        },
        listItemWithBorder: {
            borderTop: `1px solid ${borderColor}`,
            fontSize: '18px',
            lineHeight: '18px',
        },
        listItemWithBorderBottom: {
            borderBottom: `3px solid ${borderColor}`,
            fontWeight: 'bold',
            fontSize: '18px',
            lineHeight: '18px',
        },
        simple: {
            fontSize: '18px',
            lineHeight: '18px',
        },
        primaryTextStyle: {
            fontWeight: 400,
        },
        listIcon: {
            minWidth: '40px',
        },
    };
};

export { useStyles, getStyles };
