import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    controls: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 5,
    },
    icon: {
        fill: theme.icon.staticWhite,
        position: 'absolute',
        top: '20px',
        right: '20px',
    },
    inner: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: 250,
        height: 210,
        padding: theme.spacing(2),
    },
    switch: {
        display: 'flex',
        justifyContent: 'space-between',
        marginLeft: '0px',
    },
}));

export { useStyles };
