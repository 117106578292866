import * as React from 'react';
import { TimePicker } from '@material-ui/pickers';
import moment from 'moment';

import { InputAdornment } from '../../material-ui/InputAdornment';
import { IconButton } from '../../material-ui/IconButton';
import Remove from '../../../icons/image/Remove';
import { stopPropagation } from '../../../service/eventHandlers';

const CustomTimePicker = (props) => {
    const { value, onChange, label, showClear = true, onClear, ...restProps } = props;
    const [selectedDate, changeSelectedDate] = React.useState(value ? moment.utc(value) : null);
    const event = React.useMemo(() => ({ target: { name: restProps.name } }), []);
    React.useEffect(() => {
        changeSelectedDate(value);
    }, [value]);
    const handleDateChange = React.useCallback((time) => {
        const timeUtc = moment.utc(time.format('YYYY-MM-DD HH:mm:ss'));
        if (onChange) {
            onChange(event, timeUtc);
        }
        changeSelectedDate(timeUtc);
    }, []);

    const handleClear = React.useCallback((evt) => {
        stopPropagation(evt);
        if (onClear) {
            onClear();
        } else if (onChange) {
            onChange(event, null);
        }
        changeSelectedDate(null);
    }, []);

    return (
        <TimePicker
            {...restProps}
            ampm={false}
            label={label}
            emptyLabel="HH:mm"
            value={selectedDate}
            onChange={handleDateChange}
            InputProps={
                showClear
                    ? {
                          style: restProps.error ? { color: 'red' } : {},
                          endAdornment: (
                              <InputAdornment position="end">
                                  <IconButton onClick={handleClear}>
                                      <Remove width="16" height="16" />
                                  </IconButton>
                              </InputAdornment>
                          ),
                      }
                    : {}
            }
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
};

const TimePickerMemoized = React.memo(CustomTimePicker);

export { TimePickerMemoized as TimePicker };
