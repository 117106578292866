import {
    blobToObjectURL,
    dataBufferToBlob,
    downscaleImage,
    uint8ArrayToBlob,
    compressedMimeTypes,
    isImageHeic,
    convertImageHeic,
} from '../service/ImageUtils';

const UPDATE_IMAGE = 'UPDATE_IMAGE';
const UPDATE_IMAGE_URL = 'UPDATE_IMAGE_URL';
const REMOVE_SIGN = 'REMOVE_SIGN';

function imageConverter(data, contentType) {
    if (data instanceof Uint8Array) {
        return uint8ArrayToBlob(data, contentType);
    } else {
        return dataBufferToBlob(data.data, contentType);
    }
}

function createImage(id, data, contentType, options = {}) {
    const { createImmediately = false, rewrite = false, needCompression = false, maxResolution, saveLibrary = {} } = options;
    return (dispatch, getState, { plugins }) => {
        const state = getState();
        const images = state.images;
        if (images[id] && !rewrite) {
            return id;
        }
        let blob = imageConverter(data, contentType);
        const convertHeicPromise = isImageHeic(contentType) ? convertImageHeic(blob) : Promise.resolve({ blob, contentType });

        return convertHeicPromise.then(({ blob, contentType }) => {
            const compressionAllowed = needCompression && compressedMimeTypes.indexOf(contentType) !== -1;
            const url = compressionAllowed || createImmediately ? blobToObjectURL(blob) : null;
            const compressionPromise = compressionAllowed ? downscaleImage(url, contentType, null, maxResolution) : Promise.resolve();

            return compressionPromise.then((compressedImage) => {
                const saveBlob = (compressedImage && compressedImage.blob) || blob;
                const saveLibraryAllowed = saveLibrary && saveLibrary.saveLibrary && compressedMimeTypes.indexOf(contentType) !== -1;
                const saveLibraryPromise = saveLibraryAllowed
                    ? plugins.file.writeLibraryFile(`${saveLibrary.fileName}.${contentType.split('image/').pop()}`, saveBlob)
                    : Promise.resolve();

                return Promise.all([plugins.file.write(id, saveBlob), saveLibraryPromise]).then(() => {
                    dispatch(updateImage(id, contentType, (compressedImage && compressedImage.url) || url));

                    return id;
                });
            });
        });
    };
}

function removeImage(imageName) {
    return (dispatch, getState, { plugins }) => {
        try {
            plugins.file.remove(imageName);
            console.log(`File ${imageName} has been removed`);
            dispatch({
                type: REMOVE_SIGN,
                signatureName: imageName,
            });
        } catch (err) {
            console.log(`Error when removing ${imageName} file. \n${err}`);
        }
    };
}

function updateImage(id, contentType, url) {
    return {
        type: UPDATE_IMAGE,
        id,
        contentType,
        url,
    };
}

function updateImagePreview(id, url) {
    return {
        type: UPDATE_IMAGE_URL,
        id,
        url,
    };
}

function getImageUrl(id) {
    return (dispatch, getState, { plugins }) => {
        const state = getState();
        const image = state.images[id];

        if (!image) {
            // throw new Error('Unknown image id');
            console.log(`Unknown image id ${id}`);
            return Promise.resolve();
        }

        return new Promise((res) => {
            if (!image.url) {
                const { id, contentType } = image;
                if (compressedMimeTypes.indexOf(contentType) === -1) {
                    res();
                    return;
                }

                plugins.file.read(id, contentType).then((blob) => {
                    const url = blobToObjectURL(blob);

                    dispatch(updateImagePreview(id, url));

                    res(url);
                });
            } else {
                res(image.url);
            }
        });
    };
}

function getImagesForLeg(crew) {
    return (dispatch) => {
        return Promise.all(
            crew
                ? Object.keys(crew).map((key) => {
                      const crewItem = crew[key];

                      return crewItem.photo ? dispatch(getImageUrl(`img_${crew[key].crewId}`)) : '';
                  })
                : []
        );
    };
}

export { createImage, removeImage, getImageUrl, getImagesForLeg, UPDATE_IMAGE, UPDATE_IMAGE_URL, REMOVE_SIGN };
