import React from 'react';

import BaseIcon from '../BaseIcon';

const Info = (props) => (
    <BaseIcon {...props} viewBox="0 0 258.427 258.427">
        <path
            d="M170.039,202.929h-13.076v-86.353c0-15.301-12.448-27.749-27.749-27.749h-23.37c-15.301,0-27.749,12.448-27.749,27.749
            c0,13.812,10.143,25.298,23.37,27.404v58.948H88.388c-15.301,0-27.749,12.448-27.749,27.749s12.448,27.749,27.749,27.749h81.65
            c15.301,0,27.749-12.448,27.749-27.749S185.339,202.929,170.039,202.929z M170.039,240.427h-81.65
            c-5.376,0-9.749-4.373-9.749-9.749s4.373-9.749,9.749-9.749h22.076c4.971,0,9-4.029,9-9v-76.604c0-4.971-4.029-9-9-9h-4.621
            c-5.376,0-9.749-4.373-9.749-9.749s4.373-9.749,9.749-9.749h23.37c5.376,0,9.749,4.373,9.749,9.749v95.353c0,4.971,4.029,9,9,9
            h22.076c5.376,0,9.749,4.373,9.749,9.749S175.415,240.427,170.039,240.427z"
        />
        <path
            d="M129.213,72.627c20.023,0,36.313-16.29,36.313-36.313S149.237,0,129.213,0S92.9,16.29,92.9,36.313
            S109.19,72.627,129.213,72.627z M129.213,18c10.098,0,18.313,8.216,18.313,18.313s-8.216,18.313-18.313,18.313
            S110.9,46.411,110.9,36.313S119.116,18,129.213,18z"
        />
    </BaseIcon>
);

export default Info;
