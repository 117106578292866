import React from 'react';
import classnames from 'classnames';

import { Panel } from '../../Panel/Panel';
import { useStyles } from '../useStyles';
import { PreliminaryInfoRow } from '../PreliminaryInfoRow/PreliminaryInfoRow';

function FlightCardPreliminaryInfo({ legs }) {
    const classes = useStyles();

    return (
        <Panel title="Preliminary info" className="flight-info__panel  flight-info__panel--preliminary">
            <div className="flight-info__inner">
                <table className="flight-info__table">
                    <thead>
                        <tr>
                            <th className={classnames(classes.columnMain, classes.tableHeaderCell)}>Route</th>
                            <th className={classnames(classes.column, classes.tableHeaderCell)}>Version</th>
                            <th className={classnames(classes.column, classes.tableHeaderCell)}>MTOW</th>
                            <th className={classnames(classes.column, classes.tableHeaderCell)}>Uplift plan</th>
                            <th className={classnames(classes.column, classes.tableHeaderCell)}>Uplift fact</th>
                            <th className={classnames(classes.column, classes.tableHeaderCell)}>Passengers</th>
                            <th className={classnames(classes.column, classes.tableHeaderCell)}>Load</th>
                        </tr>
                    </thead>
                    <tbody>
                        {legs.map((data) => (
                            <PreliminaryInfoRow key={data.key} item={data.item} />
                        ))}
                    </tbody>
                </table>
            </div>
        </Panel>
    );
}

export { FlightCardPreliminaryInfo };
