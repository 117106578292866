import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
    input: {
        textAlign: 'center',
    },
    field: {
        flex: '1 1 25%',
        alignSelf: 'baseline',
        boxSizing: 'border-box',
        textAlign: 'center',
        lineHeight: '22px',
        color: theme.palette.primary.main,
    },
}));
