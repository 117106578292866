import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { Switch } from '../../../components/material-ui/Switch';
import { Typography } from '../../../components/material-ui/Typography';
import { FormControlLabel } from '../../../components/material-ui/FormControlLabel';
import { useStyles } from './useStyles';
import { useToggleSwitcher } from '../../../actions/flightPage';

const FlightTaskSwitch = ({ defaultValue, name, label }) => {
    const classes = useStyles();
    const checked = useSelector((state) => get(state, `flightPage.${name}`, defaultValue));
    const toggleSwitcher = useToggleSwitcher();

    const handleToggleSwitcher = React.useCallback((evt) => {
        toggleSwitcher({ [evt.target.name]: evt.target.checked });
    }, []);

    return (
        <FormControlLabel
            control={
                <Switch
                    onChange={handleToggleSwitcher}
                    classes={{ checked: classes.checked, track: classes.track }}
                    color="default"
                    {...{ checked }}
                />
            }
            label={<Typography className={classes.switch}>{label}</Typography>}
            name={name}
            labelPlacement="start"
            className={classes.switch}
        />
    );
};

export { FlightTaskSwitch };
