import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
    return {
        active: {
            background: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
        },
        step: {
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'center',
            padding: theme.spacing(0, 1),
        },
        stepButton: {
            flexGrow: 1,
            maxWidth: 80,
            margin: 0,
            padding: theme.spacing(0, 2),

            '& .MuiTypography-body2': {
                fontSize: 16,
            },
        },
        stepButtonLong: {
            maxWidth: 120,
        },
    };
});

export { useStyles };
