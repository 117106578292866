import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import { ListItem, ListItemSecondaryAction, ListItemText } from '../../material-ui/List';
import { showPdf } from '../../../actions/documents';
import { useStyles } from './useStyles';

const DocumentItem = (props) => {
    const {
        id,
        primaryTextStyle,
        primaryText = '',
        secondaryText = '',
        download,
        downloadFormat = 'DD.MM.YYYY',
        downloadPreifix = '',
        className,
    } = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const handleOpen = React.useCallback(() => {
        dispatch(showPdf(id));
    }, [id]);

    return (
        <ListItem id={id} className={classnames(classes.document, className)} onClick={handleOpen} button={true}>
            <ListItemText
                className={classes.text}
                primary={primaryTextStyle ? <span style={primaryTextStyle}>{primaryText}</span> : primaryText}
                secondary={secondaryText}
            />
            <ListItemSecondaryAction className={classes.downloadStatus}>
                {download && `${downloadPreifix}${moment.utc(download).format(downloadFormat)}`}
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export { DocumentItem };
