import moment from 'moment';

export const filterByEntering = (filter, row) => {
    return filter.value && row[filter.id] && row[filter.id].indexOf(filter.value) !== -1;
};

export const filterSimple = (filter, row) => {
    if (filter.value === 'all') {
        return true;
    }

    return filter.value === row[filter.id];
};

const capitalizeText = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const getFullName = (data) => {
    const surname = capitalizeText(data.surname || '');
    const name = capitalizeText(data.name || '');
    const patronymic = capitalizeText(data.patronymic || '');

    return `${surname} ${name} ${patronymic}`;
};

export const getGender = ({ value }) => {
    return value === null || value === undefined ? '' : value ? 'F' : 'M';
};

export const getGenderType = (value) => {
    if (value === undefined && value === null) {
        return '';
    }

    return +value === 0 ? '(male)' : '(female)';
};

export const filterGender = (filter, row) => {
    if (filter.value === 'all') {
        return true;
    }

    return filter.value === getGender({ value: row[filter.id] });
};

export const getDocument = (data) => {
    const { doc, docExpired } = data;
    return docExpired ? `${doc ? doc : '-'} (${moment(docExpired).format('DD.MM.YYYY')})` : doc ? doc : '-';
};

export const getBaggage = (data) => {
    if (!data) {
        return '';
    }
    const { baggagePieces, baggageWeight } = data;

    return `${baggagePieces || 0} place, ${baggageWeight || 0} kg`;
};

export const getSSR = ({ value }) => (value ? value.map(({ code }) => code).join(', ') : '');

export const getFullSSR = (data) => (data ? data.map(({ code, desc }) => code + (desc ? ` (${desc})` : '')).join(', ') : '');
