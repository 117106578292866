import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    const {
        palette: { primary3Color, borderColor },
        table: { secondaryColor },
    } = theme;

    return {
        text: {
            flexBasis: '100%',
            padding: 10,
        },
        footRow: {
            padding: '10px 0',
            fontWeight: 600,

            '& > td': {
                padding: '10px 0',
                borderTop: `1px solid ${theme.palette.divider}`,
            },
        },
        header: {
            padding: '10px 0',
            color: primary3Color,
            borderColor: borderColor,
        },
        headerCell: {
            padding: '10px 0',
            borderBottom: '1px solid',
            fontWeight: 'normal',
        },
        secondaryRow: {
            background: secondaryColor,
        },
        table: {
            width: '100%',
            textAlign: 'center',
            borderSpacing: '0',
        },
    };
});
