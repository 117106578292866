import React from 'react';
import Signature_pad from 'signature_pad';

import { getMuiThemeStyle } from '../../service/getMuiThemeStyle';

export const useHookWithRefCallback = (callback, isDarkTheme) => {
    const [node, setRef] = React.useState(null);
    const theme = getMuiThemeStyle(isDarkTheme ? 'dark' : 'light');

    React.useLayoutEffect(() => {
        if (node) {
            const data = new Signature_pad(node, {
                backgroundColor: theme.signaturePad.backgroundColor,
                penColor: theme.signaturePad.color,
            });
            callback(data);
        }
    }, [node]);

    return [setRef];
};
