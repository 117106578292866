import React from 'react';

import { sortWithParseInt } from '../../../service/sort';
import { filterByEntering, filterGender, filterSimple, getFullName, getGender, getSSR } from '../tools';
import { IconButton } from '../../material-ui/IconButton';
import { NestedListIconContrast } from '../../NestedListIconContrast/NestedListIconContrast';
import { FilterSelectField } from '../../FilterSelectField';

const simpleElementFilter = (name) => {
    return (props) => {
        const data = props[name];

        return <FilterSelectField {...props} key={`simple-${name}`} options={data.map((item) => ({ value: item, label: item }))} />;
    };
};

const GenderElementFilter = (props) => {
    const options = [
        {
            value: 'F',
            label: 'F',
        },
        {
            value: 'M',
            label: 'M',
        },
    ];

    return <FilterSelectField {...props} key="gender" options={options} />;
};

const airportElementFilter = (name) => {
    return (props) => {
        const data = props[name];
        const list = Object.keys(data).map((key) => data[key]) || [];

        return (
            <FilterSelectField {...props} key={`airport-${name}`} options={list.map((item) => ({ value: item.code, label: item.code }))} />
        );
    };
};

const genderSortType = (itemA, itemB) => {
    const valueA = itemA.values.gender;
    const valueB = itemB.values.gender;

    return valueA === valueB ? 0 : valueA ? 1 : -1;
};

const ssrSortType = (itemA, itemB) => {
    const valueA = itemA.values.ssr;
    const valueB = itemB.values.ssr;

    return !valueA === !valueB ? 0 : valueA ? -1 : 1;
};

const DoubleHeader = ({ firstHeader, secondHeader }) => (
    <>
        <div>{firstHeader}</div>
        <div>{secondHeader}</div>
    </>
);

const DoubleCell = ({ firstCell, secondCell, noWrap }) => (
    <>
        <div style={{ minHeight: '20px', whiteSpace: noWrap ? 'nowrap' : null }}>{firstCell}</div>
        {secondCell ? <div style={{ marginTop: '5px', whiteSpace: noWrap ? 'nowrap' : null }}>{secondCell}</div> : ''}
    </>
);

const getFullNameHeader = () => <DoubleHeader firstHeader="Name" secondHeader="SSR" />;

const getTypeHeader = () => <DoubleHeader firstHeader="Type" secondHeader="Takeoff" />;

const getClassHeader = () => <DoubleHeader firstHeader="Class" secondHeader="Landing" />;

const getFullNameCell = (data) => {
    const fullName = getFullName(data);
    const ssr = getSSR({ value: data.ssrsList });

    return <DoubleCell firstCell={fullName} secondCell={ssr} />;
};

const getTypeCell = ({ type, gender, apToCode }) => {
    const genderLabel = getGender({ value: gender });

    return <DoubleCell firstCell={`${type}${genderLabel ? ` (${genderLabel})` : ''}`} secondCell={apToCode} noWrap="true" />;
};

const getClassCell = (data) => <DoubleCell firstCell={data.class} secondCell={data.apLaCode} />;

export function useColumns(isMobile) {
    // const data = passengerList && Object.keys(passengerList).map((key) => passengerList[key]) || [];

    return React.useMemo(
        () =>
            isMobile
                ? [
                      {
                          Header: 'Check-in',
                          accessor: 'checkInSequence',
                          width: 40,
                          style: {
                              paddingTop: '5px',
                              verticalAlign: 'top',
                          },
                      },
                      {
                          id: 'seat',
                          Header: 'Seat',
                          accessor: 'seats',
                          width: 40,
                          style: {
                              paddingTop: '5px',
                              verticalAlign: 'top',
                          },
                      },
                      {
                          id: 'name',
                          Header: getFullNameHeader,
                          accessor: getFullNameCell,
                          style: {
                              paddingTop: '5px',
                              verticalAlign: 'top',
                              textAlign: 'left',
                          },
                      },
                      {
                          id: 'type',
                          Header: getTypeHeader,
                          accessor: getTypeCell,
                          width: 45,
                          style: {
                              paddingTop: '5px',
                              verticalAlign: 'top',
                          },
                      },
                      {
                          id: 'class',
                          Header: getClassHeader,
                          accessor: getClassCell,
                          width: 45,
                          style: {
                              paddingTop: '5px',
                              verticalAlign: 'top',
                          },
                      },
                      {
                          id: 'expander',
                          Header: '',
                          expander: true,
                          width: 30,
                          headerClassName: 'passenger-table__expander',
                          style: {
                              cursor: 'pointer',
                              fontSize: 25,
                              padding: '0',
                              textAlign: 'center',
                              userSelect: 'none',
                          },
                          Cell: ({ row }) => (
                              <IconButton style={{ marginLeft: '-8px' }}>
                                  <NestedListIconContrast open={row.isExpanded} />
                              </IconButton>
                          ),
                      },
                  ]
                : [
                      {
                          Header: 'Check-in',
                          accessor: 'checkInSequence',
                          width: 40,
                      },
                      {
                          Header: 'Seat',
                          accessor: 'seats',
                          width: 40,
                          sortMethod: sortWithParseInt,
                      },
                      {
                          id: 'name',
                          Header: 'Name',
                          accessor: getFullName,
                          filterMethod: filterByEntering,
                          style: { textAlign: 'left' },
                      },
                      {
                          id: 'type',
                          Header: 'Type',
                          accessor: 'type',
                          width: 30,
                          filterMethod: filterSimple,
                          Filter: simpleElementFilter('types'),
                      },
                      {
                          id: 'class',
                          Header: 'Class',
                          accessor: 'class',
                          width: 35,
                          filterMethod: filterSimple,
                          Filter: simpleElementFilter('classes'),
                      },
                      {
                          id: 'gender',
                          Header: 'Gender',
                          accessor: 'gender',
                          width: 35,
                          Cell: getGender,
                          filterMethod: filterGender,
                          Filter: GenderElementFilter,
                          sortType: genderSortType,
                      },
                      {
                          id: 'ssr',
                          Header: 'SSR',
                          accessor: 'ssrsList',
                          width: 40,
                          Cell: getSSR,
                          sortType: ssrSortType,
                      },
                      {
                          id: 'takeOff',
                          Header: 'Take-Off',
                          accessor: 'apToCode',
                          width: 45,
                          filterMethod: filterSimple,
                          Filter: airportElementFilter('takeOff'),
                      },
                      {
                          id: 'landing',
                          Header: 'Landing',
                          accessor: 'apLaCode',
                          width: 45,
                          filterMethod: filterSimple,
                          Filter: airportElementFilter('landing'),
                      },
                      {
                          id: 'expander',
                          Header: '',
                          expander: true,
                          width: 30,
                          headerClassName: 'passenger-table__expander',
                          style: {
                              cursor: 'pointer',
                              fontSize: 25,
                              padding: '0',
                              textAlign: 'center',
                              userSelect: 'none',
                          },
                          Cell: ({ row }) => (
                              <IconButton style={{ marginLeft: '-8px' }}>
                                  <NestedListIconContrast open={row.isExpanded} />
                              </IconButton>
                          ),
                      },
                  ],
        [isMobile]
    );
}
