import React from 'react';
import moment from 'moment';

import { Panel } from '../Panel/Panel.jsx';
import { TYPES, useShowModal } from '../../actions/modal';
import { useStyles } from './useStyles';
import { Typography } from '../material-ui/Typography';
import { Button } from '../material-ui/Button';
import { IncorrectButton } from './IncorrectButton/IncorrectButton';

import './ActionTimeControl.scss';

const ActionTimeControl = (props) => {
    const {
        name,
        value,
        buttonName = '',
        disabled = false,
        text = '',
        title = '',
        dialogText = '',
        className = '',
        iconMenuImg,
        iconButton,
        onChange,
        defaultValue,
        summary,
    } = props;
    const classes = useStyles();
    const showModal = useShowModal();

    const handleClearDialog = React.useCallback(() => {
        onChange(name, null);
    }, []);

    const handleOpenDialog = React.useCallback(
        () =>
            showModal(TYPES.DATE_TIME_PICKER, {
                title: `${title} dialog`,
                text: dialogText,
                name,
                value: value || defaultValue,
            }),
        [title, dialogText, name, value, defaultValue]
    );

    const iconMenuItems = React.useMemo(
        () =>
            value && [
                {
                    name: 'Edit time',
                    callback: handleOpenDialog,
                },
                {
                    name: 'Clear',
                    callback: handleClearDialog,
                },
            ],
        [handleOpenDialog]
    );

    return (
        <Panel
            title={title}
            className={className || classes.default}
            contentClassName={classes.content}
            iconMenuItems={iconMenuItems}
            iconMenuImg={iconMenuImg}
            leftIcon={iconButton}
        >
            {!value && (
                <div className={classes.buttonContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={disabled}
                        onClick={handleOpenDialog}
                        fullWidth={true}
                        className={classes.button}
                        size="large"
                    >
                        {buttonName}
                    </Button>
                </div>
            )}
            {value && (
                <>
                    <Typography className={classes.subHeader}>
                        {text}
                        <span className={classes.text}>{` ${moment.utc(value).format('DD.MM.YYYY')} `}</span>
                        at
                        <span className={classes.text}>{` ${moment.utc(value).format('HH:mm')}`}</span>
                    </Typography>
                    {summary &&
                        summary.some((summaryItem) => summaryItem.isCorrect === false) ? (
                            <IncorrectButton name={name} />
                        ) : (
                            summary.map((summaryItem, index) => {
                                return summaryItem && summaryItem.value ? (
                                    <Typography className={classes.subHeader} key={index}>
                                        <span className={classes.comment}>
                                            {summaryItem.title}
                                            <span className={classes.text}>
                                                {summaryItem.value}
                                            </span>
                                        </span>
                                    </Typography>
                                ) : (
                                    ''
                                );
                            })
                        )}
                </>
            )}
        </Panel>
    );
};

const ActionTimeControlMemoized = React.memo(ActionTimeControl);

export { ActionTimeControlMemoized as ActionTimeControl };
