import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    actions: {
        padding: theme.spacing(1, 3),
    },
    field: {
        flex: '0 1 19%',
        ['@media (max-width:440px)']: {
            flexBasis: '48%',
        },
    },
    info: {
        margin: '8px 0',
    },
    title: {
        paddingBottom: 0,
    },
}));

export { useStyles };
