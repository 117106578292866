export const NavType = {
    OTHER: 0,
    DEPART: 1,
    CLIMB: 2,
    FLIGHT: 3,
    DESCENT: 4,
    ARRIVE: 5,
    HOLD: 7, // Hold start
    // HOLD: 8, // Hold end
    TOC: 9,
    TOD: 10,
    COMMENT: 11,
    ALTITUDE: 12,
    ETP: 13, // Пока не используется.
};
