import React from 'react';
import { get } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';

import { Panel } from '../../Panel/Panel';
import { LoadInfoField } from '../LoadInfoField/LoadInfoField';
import MaterialTextField from '../../MaterialTextField/MaterialTextField';
import { sortLoadFields } from '../../../service/sort';
import { Typography } from '../../material-ui/Typography';
import { useStyles } from './useStyles';
import { getCurrentSendedTakeOff } from '../../../selectors/sended-data';

const LoadPanel = () => {
    const loadingTemplates = useSelector((state) => get(state, 'login.user.data.loadingTemplates'), shallowEqual);
    const loadInfo = useSelector((state) => {
        const takeOff = getCurrentSendedTakeOff(state);

        return takeOff.loadInfo || {};
    }, shallowEqual);
    const classes = useStyles();
    const data = React.useMemo(
        () =>
            loadingTemplates &&
            Object.keys(loadingTemplates).sort((key1, key2) => sortLoadFields(loadingTemplates[key1].type, loadingTemplates[key2].type)),
        [loadingTemplates]
    );

    return (
        <Panel title="Load info" className={classes.wrapper} key={2}>
            <div className={classes.row}>
                <Typography className={classes.headerCell}>Type</Typography>
                <Typography className={classes.headerCell}>Weight</Typography>
            </div>
            {data.map((key) => {
                const loadInfoItem = loadingTemplates[key];
                const loadInfoItemData =
                    loadInfo[
                        Object.keys(loadInfo).filter((key) => {
                            const res = loadInfo[key];

                            return res.type.id === loadInfoItem.type.id;
                        })[0]
                    ] || {};

                return (
                    <div className={classes.row} key={key}>
                        <MaterialTextField
                            label=""
                            name="cls"
                            disabled={true}
                            fullWidth={true}
                            key={`${loadInfoItem.type.name}:loadInfoItem.type.name`}
                            defaultValue={loadInfoItem.type.name}
                            inputProps={{ className: classes.input }}
                            className={classes.field}
                        />
                        <LoadInfoField
                            key={`${key}:loadInfoItemData.weight`}
                            name={'weight'}
                            loadInfo={loadInfoItem}
                            defaultValue={loadInfoItemData.weight}
                            recordId={key}
                        />
                    </div>
                );
            })}
        </Panel>
    );
};

const LoadPanelMemoized = React.memo(LoadPanel);

export { LoadPanelMemoized as LoadPanel };
