import { round } from 'lodash';
import moment from 'moment';

import { KG_IN_LB } from './constants';
import { Weight } from '../reducers/systemOfMeasurement';

const ObjectType = {
    NUMBER: '[object Number]',
    ARRAY: '[object Array]',
    OBJECT: '[object Object]',
    STRING: '[object String]',
};

export const LoadType = {
    UPLOAD: 'UPLOAD',
    DOWNLOAD: 'DOWNLOAD',
};

const fromKgToLbs = (kg, precision = 0) => {
    return round(kg / KG_IN_LB, precision);
};

const fromLbBToKg = (lb, precision = 0) => {
    return round(lb * KG_IN_LB, precision);
};

export const weightConverter = ({ value, currentDimension, precision }) => {
    switch (currentDimension) {
        case Weight.KG:
            return fromLbBToKg(value, precision);
        case Weight.LBS:
            return fromKgToLbs(value, precision);
        default:
            return value;
    }
};

export const convertFields = ({ value, fieldName, fieldsForConvert, weightDimension, options = {}, loadType }) => {
    const { precision = {}, exceptions = [] } = options;

    if (exceptions.length && exceptions.includes(fieldName)) {
        return value;
    }

    const { fields, toFixed } = precision;
    let updatedFields = value;

    switch (Object.prototype.toString.call(value)) {
        case ObjectType.NUMBER:
        case ObjectType.STRING:
            if (fieldsForConvert.includes(fieldName)) {
                updatedFields = weightConverter({
                    value: +updatedFields,
                    currentDimension: weightDimension,
                    precision: fields && fields.includes(fieldName) ? toFixed : 0,
                });
            }
            break;
        case ObjectType.ARRAY:
            updatedFields = updatedFields.reduce((accum, item) => {
                accum.push(convertFields({ value: item, fieldsForConvert, weightDimension, options, loadType }));

                return accum;
            }, []);
            break;
        case ObjectType.OBJECT:
            // исключаем объект moment
            if (moment.isMoment(updatedFields)) {
                break;
            }
            updatedFields = Object.keys(updatedFields).reduce((accum, item) => {
                accum[item] = convertFields({
                    value: updatedFields[item],
                    fieldName: item,
                    fieldsForConvert,
                    weightDimension,
                    options,
                    loadType,
                });

                return accum;
            }, {});
            break;

        default:
            return updatedFields;
    }

    return updatedFields;
};

export const convertToAppUntis = (value, appWeightUnits) => {
    if (appWeightUnits === Weight.KG) {
        return value;
    }

    return weightConverter({ value, currentDimension: appWeightUnits });
};
