import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    table: {
        width: '100%',
        marginTop: 12,
        borderSpacing: 0,
        textAlign: 'left',
    },
    title: {
        textAlign: 'center',
        margin: '32px auto 0',
        fontWeight: 600,
    },
    header: {
        borderBottom: `1px solid ${theme.palette.divider}`,

        '& p': {
            color: theme.palette.info.main,
        },
    },
}));
