import React from 'react';

import BaseIcon from '../BaseIcon';

export const CheckList = (props) => (
    <BaseIcon {...props} viewBox="0 0 40 70">
        <path
            d="M45 9h-5.1686v-2.9314c0-.5965-.4835-1.08-1.08-1.08h-7.814C30 3 27.2347.0792 23.9176.0792c-3.3172 0-6.9176 2.9208-6.9176
            4.9208H10c-.5965 0-2-0-2 1v2.92H3.0624c-2.0624.08-3.0624 1.08-3.0624 3.08v53c0 2 2 3 4 3h40c2.0176 0 4-1 4-4V12C48 10 47 9 45 9z
            M10.3913 7.7424h7.6753c.5712 0 1.0434-.4446 1.0781-1.0146-.1447-2.7278 2.249-4.4885 4.773-4.4885 2.524 0 4.6204 1.9716 4.773
            4.4885.0347.57.5069 1.0146 1.0781 1.0146h7.6753v1.8514 2.1601 2.5716H10.3913v-2.5716-2.1601V7.7424z
            M45 65H3V12h5v3.6516c0 .5965.4835 1.08 1.08 1.08h29.2126c.5965 0 1.7074.2684 1.7074-.7316V12h5V62z"
        />
        <path
            d="M15.7924 23.5132 11.3488 27.9569l-1.987-1.9871c-.4219-.4216-1.1057-.4216-1.5275 0-.4217.4219-.4217 1.1057 0 1.5275l2.7507
            2.7508c.2109.2108.4873.3163.7638.3163.2765 0 .5529-.1055.7638-.3163l5.2073-5.2073c.4217-.4219.4217-1.1057 0-1.5276C16.8981 23.0916
            16.2143 23.0916 15.7924 23.5132z"
        />
        <path
            d="M21.3392 25.8006c-.5965 0-1.08.4835-1.08 1.08 0 .5965.4835 1.08 1.08 1.08h17.8979c.5965 0 1.08-.4835 1.08-1.08
            0-.5965-.4835-1.08-1.08-1.08H21.3392z"
        />
        <path
            d="M15.7924 35.7474l-4.4436 4.4437-1.987-1.9871c-.4219-.4216-1.1057-.4216-1.5275 0-.4217.4219-.4217 1.1057 0 1.5276l2.7507
            2.7507c.2109.2108.4873.3162.7638.3162.2765 0 .5529-.1054.7638-.3162l5.2073-5.2073c.4217-.4219.4217-1.1056 0-1.5276C16.8981
            35.3258 16.2143 35.3258 15.7924 35.7474z"
        />
        <path
            d="M39.2371 38.0348H21.3392c-.5965 0-1.08.4835-1.08 1.0801s.4835 1.0801 1.08 1.0801h17.8979c.5965 0 1.08-.4835 1.08-1.0801S39.8336
            38.0348 39.2371 38.0348z"
        />
        <path
            d="M15.7924 47.9817l-4.4436 4.4437-1.987-1.9872c-.4219-.4216-1.1057-.4216-1.5275 0-.4217.4219-.4217 1.1057 0 1.5276l2.7507
            2.7507c.2109.2108.4873.3162.7638.3162.2765 0 .5529-.1054.7638-.3162l5.2073-5.2073c.4217-.4219.4217-1.1057 0-1.5276C16.8981 47.5601
            16.2143 47.5601 15.7924 47.9817z"
        />
        <path d="M39.2371 50.2691H21.3392c-.5965 0-1.08.4835-1.08 1.0801s.4835 1.08 1.08 1.08h17.8979c.5965 0 1.08-.4835 1.08-1.08S39.8336 50.2691 39.2371 50.2691z" />
    </BaseIcon>
);
