import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
    return {
        avatar: {
            width: 100,
            height: 100,

            ['@media (max-width: 500px)']: {
                width: 50,
                height: 50,
            },
        },
        avatarWrapper: {
            display: 'flex',
            marginRight: theme.spacing(2),
            flexDirection: 'column',
            justifyContent: 'flex-start',
            overflow: 'hidden',

            ['@media (max-width: 500px)']: {
                marginRight: theme.spacing(1),
            },
        },
        item: {
            display: 'block',
        },
        primary: {
            fontWeight: 500,
            fontSize: 20,

            ['@media (max-width: 500px)']: {
                fontSize: 18,
            },
        },
        listItem: {
            ['@media (max-width: 500px)']: {
                padding: theme.spacing(0, 1),
            },
        },
        secondaryText: {
            display: 'block',
            height: 'auto',
            minHeight: '60px',
            WebkitLineClamp: 3,
            opacity: 0.9,
        },
    };
});

export { useStyles };
