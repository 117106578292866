import React from 'react';
import moment from 'moment';

import { Info } from '../../../icons';
import { SidebarLegItem } from '../SidebarLegItem/SidebarLegItem';
import { ListItem, ListItemIcon, ListItemText } from '../../material-ui/List';
import { useStyles } from './useStyles';
import { sortObjByField } from '../../../service/sort';

function SidebarFlightItem({ flight, onClickSidebarItem, getLegStatus, onClickFlight, userLegs }) {
    const classes = useStyles();
    const isCancelled = (flight && flight.cancel) || false;
    const handleFlightClick = React.useCallback(() => {
        const { id } = flight;
        onClickFlight(id, 'flight');
    }, [flight]);

    return (
        <>
            <ListItem key={1} onClick={handleFlightClick} className={classes.listItemWithBorder} button={true}>
                <ListItemIcon className={classes.listIcon}>
                    <Info width="24" height="24" className={isCancelled ? classes.iconCancelled : classes.icon} />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <div className={isCancelled ? classes.cancelledText : classes.text}>
                            Flight
                            <span className="sidebar-menu__item  sidebar-menu__item--bold">
                                {flight.num}
                                {isCancelled ? ' (cancelled)' : ''}
                            </span>
                        </div>
                    }
                />
            </ListItem>
            {Object.values(flight.legs || {})
                .sort(sortObjByField('takeOffDate', moment))
                .map((leg) => (
                    <SidebarLegItem
                        key={leg.id}
                        leg={leg}
                        status={getLegStatus(leg.id)}
                        crewType={userLegs[leg.id]}
                        onClickSidebarItem={onClickSidebarItem}
                    />
                ))}
        </>
    );
}

const SidebarFlightItemMemoized = React.memo(SidebarFlightItem);

export { SidebarFlightItemMemoized as SidebarFlightItem };
