import React from 'react';
import { useSelector } from 'react-redux';

import { useStyles } from '../useStyles';
import { List } from '../../material-ui/List';
import { documentsSort, sortObjByField } from '../../../service/sort';
import { NotificationNavigation } from '../NotificationNavigation/NotificationNavigation';
import { NotificationItems } from '../NotificationItems/NotificationItems';
import { getLibraryNotifications } from '../../../selectors/library';
import { getDocumentsNotifications } from '../../../selectors/documents';
import { getDefectsNotifications } from '../../../selectors/defects';

const NotificationContent = ({ shortView }) => {
    const [selectedIndex, changeSelected] = React.useState(0);
    const classes = useStyles();
    const { documentsWithWarning, defectsWithWarning, libraryWithWarning } = useSelector((state) => {
        const libraryWithWarning = getLibraryNotifications(state) || [];
        const documentsWithWarning = getDocumentsNotifications(state) || [];
        const defectsWithWarning = getDefectsNotifications(state) || [];

        return {
            libraryWithWarning,
            documentsWithWarning,
            defectsWithWarning,
        };
    });

    const items = React.useMemo(() => {
        if (selectedIndex === 0) {
            return documentsWithWarning.sort(documentsSort).map((item) => ({
                id: item.id,
                primary: `${item.airport} ${item.type}`,
                secondary: item.description,
            }));
        }

        if (selectedIndex === 1) {
            return defectsWithWarning.sort(sortObjByField('name')).map((item) => ({
                id: item.id,
                primary: item.name,
            }));
        }

        return libraryWithWarning.sort(sortObjByField('name')).map((item) => ({
            id: item.id,
            primary: item.name,
        }));
    }, [documentsWithWarning, defectsWithWarning, libraryWithWarning, selectedIndex]);

    return (
        <List className={classes.list}>
            <NotificationNavigation
                {...{
                    documentsWithWarning,
                    defectsWithWarning,
                    libraryWithWarning,
                    shortView,
                    selectedIndex,
                }}
                onChangeSelected={changeSelected}
            />
            <NotificationItems items={items} selectedIndex={selectedIndex} />
        </List>
    );
};

const NotificationContentMemoized = React.memo(NotificationContent);

export { NotificationContentMemoized as NotificationContent };
