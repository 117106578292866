import React from 'react';
import moment from 'moment';

import { IconButton } from '../../components/material-ui/IconButton';
import { MoreVertIconContrast } from '../../icons/MoreVertIconContrast';
import { ListItem, ListItemSecondaryAction, ListItemText } from '../../components/material-ui/List';
import { Menu, MenuItem } from '../../components/material-ui/Menu';
import { useAnchorEl } from '../../components/hooks/useAnchorEl';

const RestoreFile = ({ file, index, selected = false, onSelect, onRestore }) => {
    const [anchorEl, handleOpenMenu, handleClose] = useAnchorEl();
    const fileName = file.name;

    const handleRestore = React.useCallback(() => {
        onRestore(fileName);
    }, []);

    const handleSelect = React.useCallback(() => onSelect(index), []);
    const style = (selected && { backgroundColor: '#dadada' }) || {};

    return (
        <>
            <ListItem key={index} style={style} onClick={handleSelect}>
                <ListItemText
                    primary={file.name}
                    secondary={(file.modificationTime && moment.utc(file.modificationTime).format('DD.MM.YYYY HH:mm')) || ''}
                />
                <ListItemSecondaryAction>
                    <IconButton onClick={handleOpenMenu}>
                        <MoreVertIconContrast />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={handleRestore}>Send to server</MenuItem>
            </Menu>
        </>
    );
};

export { RestoreFile };
