import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { FlightCard } from './FlightCard/FlightCard';
import { FlightCardAirportInfo } from './FlightCardAirportInfo/FlightCardAirportInfo';
import { FlightCardPreliminaryInfo } from './FlightCardPreliminaryInfo/FlightCardPreliminaryInfo';
import { FlightCardLoadInfo } from './FlightCardLoadInfo/FlightCardLoadInfo';
import { useStyles } from './useStyles';
import { Typography } from '../material-ui/Typography';
import { sortFlightByAirportNumber } from '../../service/sort';
import { getCurrentFlight, getCurrentTaskSelector } from '../../selectors/screen';

import './FlightPage.scss';

const FlightPage = () => {
    const task = useSelector(getCurrentTaskSelector);
    const flight = useSelector(getCurrentFlight);
    const data = get(flight, 'legs');
    const classes = useStyles();

    if (!flight) return <Typography className={classes.text}>There is not data</Typography>;

    const legs = React.useMemo(
        () =>
            data &&
            Object.values(data)
                .sort(sortFlightByAirportNumber)
                .map((item, index) => ({
                    key: `${item.id}_${index}`,
                    item: item,
                })),
        [data]
    );

    return (
        <ErrorBoundary>
            <div className={classes.page}>
                <FlightCard {...{ task, flight }} />
                <FlightCardAirportInfo legs={legs} />
                <FlightCardPreliminaryInfo legs={legs} />
                <FlightCardLoadInfo legs={legs} />
            </div>
        </ErrorBoundary>
    );
};

const FlightPageMemoized = React.memo(FlightPage);

export { FlightPageMemoized as FlightPage };
