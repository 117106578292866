import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
    listItem: {
        borderTop: `1px solid ${theme.palette.border}`,
        fontSize: '18px',
        lineHeight: '18px',
        paddingLeft: '0px !important',
    },
    nested: {
        paddingLeft: theme.spacing(4),
        color: theme.palette.text.primary,
    },
}));
