import React from 'react';
import classnames from 'classnames';

import { Panel } from '../../Panel/Panel';
import { LoadInfoRow } from '../LoadInfoRow/LoadInfoRow';
import { useStyles } from '../useStyles';

function FlightCardLoadInfo({ legs }) {
    const classes = useStyles();

    return (
        <Panel title="Load info" className="flight-info__panel  flight-info__panel--preliminary">
            <div className="flight-info__inner">
                <table className="flight-info__table">
                    <thead>
                        <tr>
                            <th className={classnames(classes.columnMain, classes.tableHeaderCell)}>Route</th>
                            <th className={classnames(classes.column, classes.tableHeaderCell)}>B/cl</th>
                            <th className={classnames(classes.column, classes.tableHeaderCell)}>ADT</th>
                            <th className={classnames(classes.column, classes.tableHeaderCell)}>CHD</th>
                            <th className={classnames(classes.column, classes.tableHeaderCell)}>INF</th>
                            <th className={classnames(classes.column, classes.tableHeaderCell)}>Baggage</th>
                            <th className={classnames(classes.column, classes.tableHeaderCell)}>Mail</th>
                            <th className={classnames(classes.column, classes.tableHeaderCell)}>Cargo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {legs.map((data) => (
                            <LoadInfoRow key={data.key} item={data.item} />
                        ))}
                    </tbody>
                </table>
            </div>
        </Panel>
    );
}

export { FlightCardLoadInfo };
