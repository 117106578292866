import React from 'react';

import BaseIcon from '../BaseIcon';

const About = (props) => (
    <BaseIcon {...props} viewBox="0 0 90 90">
        <path
            d="M65.449,6.169C59.748,2.057,52.588,0,43.971,0c-6.559,0-12.09,1.449-16.588,4.34
                C20.25,8.871,16.457,16.562,16,27.412h16.531c0-3.158,0.922-6.203,2.766-9.137c1.846-2.932,4.975-4.396,9.389-4.396
                c4.488,0,7.58,1.19,9.271,3.568c1.693,2.381,2.539,5.018,2.539,7.91c0,2.513-1.262,4.816-2.781,6.91
                c-0.836,1.22-1.938,2.342-3.307,3.369c0,0-8.965,5.75-12.9,10.368c-2.283,2.681-2.488,6.692-2.689,12.449
                c-0.014,0.409,0.143,1.255,1.576,1.255c1.433,0,11.582,0,12.857,0s1.541-0.951,1.559-1.362c0.09-2.098,0.326-3.167,0.707-4.377
                c0.723-2.286,2.688-4.283,4.893-5.997l4.551-3.141c4.107-3.199,7.385-5.826,8.83-7.883C72.264,33.562,74,29.393,74,24.443
                C74,16.373,71.148,10.281,65.449,6.169z M43.705,69.617c-5.697-0.17-10.398,3.771-10.578,9.951
                c-0.178,6.178,4.293,10.258,9.99,10.426c5.949,0.177,10.523-3.637,10.701-9.814C53.996,74,49.654,69.793,43.705,69.617z"
        />
    </BaseIcon>
);

export default About;
