import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { initialFilterState } from '../reducers/filter';

export const useFilter = (filterName) =>
    useSelector((state) => filterName && get(state, `filter[${filterName}]`, initialFilterState[filterName]));

export const useIsFilterSet = (filterName) =>
    useSelector(() => {
        const filter = useFilter(filterName);

        const result =
            filter &&
            Object.keys(initialFilterState[filterName]).reduce((res, key) => {
                if (filter[key] && ((Array.isArray(filter[key]) && filter[key].length) || !Array.isArray(filter[key]))) {
                    res = true;
                }

                return res;
            }, false);

        return result;
    });
