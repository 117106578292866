import React from 'react';

import MaterialTextField from '../MaterialTextField/MaterialTextField';
import { checkValid, getErrorText, getIntFromTime, getTimeFromInt } from './tools';
import { VALIDATION_EMPTY_FIELD } from '../hooks/useForm/validate';

const TimeField = (props) => {
    const { label, name, className, disabled, hintText, fullWidth = true, required, defaultValue, onEditField } = props;
    const [errorText, setErrorText] = React.useState('');
    const [value, setValue] = React.useState(defaultValue === undefined || defaultValue === null ? '' : getTimeFromInt(defaultValue));
    let customHelperText = errorText && errorText !== VALIDATION_EMPTY_FIELD ? errorText : '';

    React.useEffect(() => {
        setValue(defaultValue === undefined || defaultValue === null ? '' : getTimeFromInt(defaultValue));
    }, [defaultValue]);

    React.useEffect(() => {
        setErrorText(getErrorText(props.errorText, defaultValue, required));
    }, [props.errorText]);

    const handleFocus = React.useCallback(() => setErrorText(''), []);
    const handleBlur = React.useCallback((evt) => {
        const fieldValue = evt.currentTarget.value;
        if (checkValid(fieldValue) && fieldValue.length === 5) {
            var value = getIntFromTime(fieldValue);
            onEditField(evt.target.name, value);
        } else if (fieldValue !== '') {
            setErrorText('Field is incorrect');
        } else {
            if (fieldValue === '' && required) {
                setErrorText(VALIDATION_EMPTY_FIELD);
            }

            onEditField(evt.target.name, undefined);
        }
    }, []);

    const handleChange = React.useCallback((evt) => {
        let value = evt.target.value;

        setValue((prevState) => {
            if (!(value === prevState || value.length > 5) && checkValid(value)) {
                if (value.length === 2 && prevState.length !== 3 && value.indexOf(':') === -1) {
                    value = value + ':';
                }

                if (value.length === 2 && prevState.length === 3) {
                    value = value.slice(0, 1);
                }

                return value;
            }

            return prevState;
        });
    }, []);

    return (
        <MaterialTextField
            autoComplete="off"
            placeholder={hintText || label}
            {...{ label, name, value, disabled, fullWidth, className }}
            helperText={customHelperText}
            error={Boolean(errorText)}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={handleChange}
            key={name}
        />
    );
};

const TimeFieldMemoized = React.memo(TimeField);

export { TimeFieldMemoized as TimeField };
