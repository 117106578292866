import { makeStyles } from '@material-ui/styles';

import { darkFullWhite } from '../../../service/getMuiThemeStyle';

const useStyles = makeStyles((theme) => ({
    checked: {
        '& ~ span': {
            backgroundColor: theme.palette.info.light,
        },
    },
    switch: {
        color: darkFullWhite,
    },
    track: {
        backgroundColor: theme.palette.grey[900],
    },
}));

export { useStyles };
