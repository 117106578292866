import React from 'react';
import moment from 'moment';

import { useStyles } from './useStyles';
import { Typography } from '../../../components/material-ui/Typography';

function FlightTaskHeader(props) {
    const { flights, plns, plnTypes, flightTask, taskDate, flightNames, chief, getMessage, operator } = props;
    const classes = useStyles();

    return (
        <>
            <Typography variant="h5" className={classes.title}>
                {operator}
            </Typography>
            <Typography variant="h6" className={classes.subtitle}>{getMessage`Flight task ${flightTask} from ${moment
                .utc(taskDate)
                .format('DD.MM.YYYY')}`}</Typography>
            <div className={classes.container}>
                <Typography className={classes.item}>
                    {getMessage`Data`} <span>{flights && flights[0] && moment.utc(flights[0].date).format('DD.MM.YYYY')}</span>
                </Typography>
                <Typography className={classes.item}>
                    {getMessage`Flight`} <span>{flightNames}</span>
                </Typography>
                <Typography className={classes.item}>
                    {getMessage`Pln type`} <span>{plnTypes}</span>
                </Typography>
                <Typography className={classes.item}>
                    {getMessage`Pln number`} <span>{plns}</span>
                </Typography>
                <Typography className={classes.item}>
                    {getMessage`Chief`} <span>{chief}</span>
                </Typography>
            </div>
        </>
    );
}

const FlightTaskHeaderMemoized = React.memo(FlightTaskHeader);

export { FlightTaskHeaderMemoized as FlightTaskHeader };
