import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as moment from 'moment';
import { get } from 'lodash';
import classnames from 'classnames';

import { DialogActions, DialogAdaptive, DialogContent, DialogTitle } from '../../components/material-ui/Dialog';
import { TextField } from '../../components/material-ui/TextField';
import { InputLabel } from '../../components/material-ui/InputLabel';
import { Input } from '../../components/material-ui/Input';
import { useStyles } from './useStyles';
import { FieldTypes } from '../../components/hooks/useForm/validate';
import { useHideModal } from '../../actions/modal';
import { setLoadingState } from '../../actions/progressIndicator';
import { DialogControls } from '../../components/DialogControls/DialogControls';
import { FormControl } from '../../components/material-ui/FormControl';
import { Select } from '../../components/material-ui/Select';
import { MenuItem } from '../../components/material-ui/Menu';
import { ListItemText } from '../../components/material-ui/List';
import { DateTimePicker } from '../../components/DateTimePicker/DateTimePicker';
import { getReportsOptions } from '../../selectors/reports';
import { useForm } from '../../components/hooks/useForm/useForm';
import { SelectIcon } from '../../components/ReportsPage/SelectIcon/SelectIcon';
import { saveReportData } from '../../actions/reports';
import { ReportDropzone } from './ReportDropzone/ReportDropzone';
import { useDropzoneFiles } from './tools';

const fields = {
    safetyTypeId: { title: 'Type', type: FieldTypes.NUMBER },
    planFlightId: { title: 'Flight', type: FieldTypes.NUMBER },
    airportId: { title: 'Airport', type: FieldTypes.NUMBER, required: false },
    createdDate: { title: 'Date', type: FieldTypes.MOMENT },
    value: { title: 'Value', type: FieldTypes.NUMBER, precision: [12, 3], required: false },
};

const Report = React.forwardRef((props, ref) => {
    const { report } = props;
    const hideModal = useHideModal();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { planFlights, groups, types } = useSelector(getReportsOptions);
    const isNew = !report.id;
    const { images, handleDrop, handleDeleteImage, handleSave } = useDropzoneFiles(report.images);

    const handleSuccess = React.useCallback(
        (data) => {
            const planFlight = planFlights.find((flight) => flight.id === data.planFlightId);
            const reportType = types.find((type) => type.typeId === data.safetyTypeId);
            const reportGroupType = groups.find((group) => group.typeId === data.groupTypeId || group.typeId === reportType.groupId);
            const airport = planFlight.airports.find((airport) => airport.id === data.airportId);
            const userDate = report.userDate ? report.userDate : new Date();

            dispatch(setLoadingState(true));
            handleSave()
                .then((imagesChanges) => {
                    return dispatch(
                        saveReportData({
                            userDate,
                            planFlightId: data.planFlightId,
                            flightName: planFlight.flightName,
                            flightDate: moment.utc(planFlight.flightDate),
                            taskNumber: planFlight.taskNumber,
                            safetyGroupTypeId: reportGroupType.typeId,
                            safetyGroupTypeName: reportGroupType.name,
                            safetyTypeId: reportType.typeId,
                            safetyTypeName: reportType.name,
                            ...(airport && {
                                airportId: data.airportId,
                                airportName: airport.name,
                            }),
                            createdDate: data.createdDate,
                            value: data.value,
                            generalInfo: data.generalInfo,
                            addInfo: data.addInfo,
                            imagesChanges: imagesChanges,
                            id: report.id,
                            images: report.images || [], // TODO изображения
                        })
                    );
                })
                .then(() => {
                    hideModal();
                })
                .catch((error) => {
                    // TODO push notifications
                    console.error('Cannot save image changes', error);
                })
                .finally(() => {
                    dispatch(setLoadingState(false));
                });
        },
        [report.id, images]
    );

    const { inputs, handleInputChange, handleSubmit, status } = useForm({
        onSuccess: handleSuccess,
        // prepareData: prepareFiles,
        initialData: {
            planFlightId: props.planFlightId,
            groupTypeId: report.safetyGroupTypeId,
            safetyTypeId: undefined,
            ...report,
            createdDate: moment.utc(report.createdDate),
            value: report.value ? Number(report.value).toFixed(3) : '',
        },
        fields,
    });
    const planFlightId = get(inputs, 'planFlightId', 0);
    const groupTypeId = get(inputs, 'groupTypeId', 0);
    const safetyTypeId = get(inputs, 'safetyTypeId', 0);
    const createdDate = get(inputs, 'createdDate');
    const airportId = get(inputs, 'airportId', 0);

    const airports = React.useMemo(() => {
        const planFlight = planFlights.find((item) => item.id == planFlightId) || { airports: [] };

        return [
            { value: 0, label: 'Not selected' },
            ...(planFlight &&
                planFlight.airports.map((item) => ({
                    value: item.id,
                    label: item.name,
                }))),
        ];
    }, [planFlightId, planFlights]);

    const reportTypes = React.useMemo(() => {
        return [
            { value: 0, label: 'Not selected' },
            ...(types &&
                types
                    .filter((item) => !groupTypeId || groupTypeId === item.groupId)
                    .map((item) => ({
                        value: item.typeId,
                        label: item.name,
                    }))),
        ];
    }, [groupTypeId, types]);

    const reportGroupTypes = React.useMemo(() => {
        return [
            { value: 0, label: 'All items' },
            ...(groups &&
                groups.map((item) => ({
                    value: item.typeId,
                    label: item.name,
                }))),
        ];
    }, [groups]);

    const actions = React.useMemo(
        () => [
            { label: 'Cancel', onClick: hideModal },
            {
                label: 'OK',
                color: 'primary',
                type: 'submit',
                disabled: !planFlightId || !safetyTypeId,
            },
        ],
        [planFlightId, safetyTypeId]
    );
    const handleFlightChange = React.useCallback((event) => {
        handleInputChange(event);
        handleSelectClear('airportId');
    }, []);

    const handleTypeChange = React.useCallback(
        (event) => {
            const typeId = event.target.value;
            handleInputChange(event);
            const type = types.find((type) => type.typeId === typeId);
            if (type) {
                handleInputChange({ target: { name: 'groupTypeId', value: type.groupId } });
            }
        },
        [groups, types]
    );

    const handleSelectClear = React.useCallback(
        (name) =>
            handleInputChange({
                target: {
                    name,
                    value: undefined,
                },
            }),
        []
    );

    const handleGroupChange = React.useCallback(
        (event) => {
            const groupTypeId = event.target.value;
            handleInputChange(event);
            const type = types.find((type) => type.typeId === safetyTypeId);
            if (type && type.groupId !== groupTypeId) {
                handleSelectClear('safetyTypeId');
            }
        },
        [groups, types, safetyTypeId]
    );

    const handleDateChange = React.useCallback(
        (name, value) =>
            handleInputChange({
                target: {
                    name,
                    value,
                },
            }),
        []
    );

    const handleAirportChange = React.useCallback((event) => {
        handleInputChange(event);
    }, []);

    const selectControls = React.useMemo(
        () => ({
            group: () => <SelectIcon onClear={handleSelectClear} name="groupTypeId" />,
            type: () => <SelectIcon onClear={handleSelectClear} name="safetyTypeId" />,
            airport: () => <SelectIcon onClear={handleSelectClear} name="airportId" />,
        }),
        []
    );

    return (
        <DialogAdaptive open={true} maxWidth="md" fullScreenBreakpoint="sm">
            <DialogTitle className={classes.title}>{isNew ? 'Add report dialog' : 'Edit report dialog'}</DialogTitle>
            <form ref={ref} onSubmit={handleSubmit}>
                <DialogContent className={classes.content}>
                    <FormControl className={classnames(classes.formControl, classes.flight)}>
                        <InputLabel htmlFor="planFlightId">Flight info</InputLabel>
                        <Select name="planFlightId" value={planFlightId} onChange={handleFlightChange} input={<Input id="planFlightId" />}>
                            <MenuItem key={0} value={0}>
                                <ListItemText primary="Not selected" {...(planFlightId === 0 && { classes: { primary: classes.error } })} />
                            </MenuItem>
                            {planFlights &&
                                planFlights.map((flight) => (
                                    <MenuItem key={flight.id} value={flight.id}>
                                        <ListItemText
                                            primary={`Flight № ${flight.flightName} (${moment
                                                .utc(flight.flightDate)
                                                .format('DD.MM.YYYY')}) ${flight.route}`}
                                        />
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl className={classnames(classes.formControl, classes.group)}>
                        <InputLabel htmlFor="groupTypeId">Group</InputLabel>
                        <Select
                            name="groupTypeId"
                            value={groupTypeId}
                            onChange={handleGroupChange}
                            input={<Input id="groupTypeId" />}
                            IconComponent={selectControls.group}
                        >
                            {reportGroupTypes.map((group) => (
                                <MenuItem key={group.value} value={group.value}>
                                    <ListItemText primary={group.label} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl className={classnames(classes.formControl, classes.type)}>
                        <InputLabel htmlFor="safetyTypeId">Type</InputLabel>
                        <Select
                            name="safetyTypeId"
                            value={safetyTypeId}
                            onChange={handleTypeChange}
                            input={<Input id="safetyTypeId" />}
                            IconComponent={selectControls.type}
                        >
                            {reportTypes.map((type) => (
                                <MenuItem key={type.value} value={type.value}>
                                    <ListItemText
                                        primary={type.label}
                                        {...(type.value === 0 && safetyTypeId === 0 && { classes: { primary: classes.error } })}
                                    />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl className={classnames(classes.formControl, classes.userDate)}>
                        <DateTimePicker
                            labelTextDate="Created date"
                            name="createdDate"
                            value={createdDate}
                            error={Boolean(status.createdDate)}
                            onChange={handleDateChange}
                            fullWidth={true}
                            inputProps={{ className: classes.input }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </FormControl>
                    <FormControl className={classnames(classes.formControl, classes.airport)}>
                        <InputLabel htmlFor="airportId">Airport</InputLabel>
                        <Select
                            name="airportId"
                            value={airportId}
                            onChange={handleAirportChange}
                            input={<Input id="airportId" />}
                            IconComponent={selectControls.airport}
                        >
                            {airports.map((airport) => (
                                <MenuItem key={airport.value} value={airport.value}>
                                    <ListItemText primary={airport.label} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl className={classnames(classes.formControl, classes.value)}>
                        <TextField
                            id="value"
                            name="value"
                            label="Special value"
                            value={get(inputs, 'value')}
                            onChange={handleInputChange}
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                max: 999999999,
                                min: 0,
                                list: 'defaultNumbers',
                                className: classnames(classes.input, classes.valueInput),
                            }}
                            placeholder={'xxxxxx.xxx'}
                            className={classes.field}
                            FormHelperTextProps={{
                                className: classes.helperText,
                            }}
                        />
                    </FormControl>
                    <FormControl className={classnames(classes.formControl, classes.generalInfo)}>
                        <TextField
                            name="generalInfo"
                            label="Basic info"
                            placeholder="Enter basic info"
                            onChange={handleInputChange}
                            fullWidth={true}
                            multiline={true}
                            value={get(inputs, 'generalInfo', '')}
                            minRows={1}
                            maxRows={23}
                            className={classes.textarea}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <FormControl className={classnames(classes.formControl, classes.addInfo)}>
                        <TextField
                            name="addInfo"
                            label="Additional info"
                            placeholder="Enter additional info"
                            onChange={handleInputChange}
                            fullWidth={true}
                            multiline={true}
                            value={get(inputs, 'addInfo', '')}
                            minRows={1}
                            maxRows={23}
                            className={classes.textarea}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <FormControl className={classnames(classes.formControl, classes.files)}>
                        <ReportDropzone images={images} onDrop={handleDrop} onDelete={handleDeleteImage} />
                    </FormControl>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <DialogControls actions={actions} />
                </DialogActions>
            </form>
        </DialogAdaptive>
    );
});

export { Report };
