import { FilterTypes } from '../../reducers/filter';

export const RADIO_GROUP_INITIAL = 1;

export const InputTypes = {
    SELECT_LIST: 0,
    SELECT_LIST_MULTI: 1,
    CHECKBOX: 2,
    RADIO_GROUP: 3,
};

export const FilterKinds = {
    PASSENGER_TYPE: 'PASSENGER_TYPE',
    SSR_TYPE: 'SSR_TYPE',
    SSR: 'SSR',
};

const filters = {
    [FilterKinds.PASSENGER_TYPE]: {
        name: FilterKinds.PASSENGER_TYPE,
        type: InputTypes.SELECT_LIST_MULTI,
    },
    [FilterKinds.SSR_TYPE]: {
        name: FilterKinds.SSR_TYPE,
        type: InputTypes.SELECT_LIST_MULTI,
    },
    [FilterKinds.SSR]: {
        name: FilterKinds.SSR,
        type: InputTypes.SELECT_LIST_MULTI,
    },
};

export const pageFiltersSet = {
    [FilterTypes.PASSENGERS]: [filters[FilterKinds.PASSENGER_TYPE], filters[FilterKinds.SSR_TYPE], filters[FilterKinds.SSR]],
};

export const filterSettings = {
    [FilterKinds.PASSENGER_TYPE]: {
        name: 'passengerType',
        label: 'Passenger type',
        emptyValueText: 'No code',
    },
    [FilterKinds.SSR_TYPE]: {
        name: 'ssrType',
        label: 'SSR type',
        emptyValueText: 'No SSR Type',
    },
    [FilterKinds.SSR]: {
        name: 'ssr',
        label: 'SSR',
        emptyValueText: 'No SSR',
    },
};
