import React from 'react';
import { useDispatch } from 'react-redux';

import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { getImagesForLeg } from '../../actions/images';
import { useCurrentLegCrew } from '../../selectors/screen';
import { EmployersList } from './EmployersList/EmployersList';
import { useStyles } from './useStyles';

const EmployersPage = () => {
    const dispatch = useDispatch();
    const crew = useCurrentLegCrew();
    const classes = useStyles();
    React.useEffect(() => {
        if (crew) {
            dispatch(getImagesForLeg(crew));
        }
    }, [crew]);

    const { cabinList, flightAttendantList, otherList } = React.useMemo(() => {
        const crewList = Object.values(crew || {});
        const cabinList = crewList.filter((emp) => emp.crewType === 0);
        const flightAttendantList = crewList.filter((emp) => emp.crewType === 1);
        const otherList = crewList.filter((emp) => emp.crewType >= 2);

        return {
            cabinList,
            flightAttendantList,
            otherList,
        };
    }, [crew]);

    return (
        <ErrorBoundary>
            <div className={classes.crewWrapper}>
                <EmployersList title="Flight crew" data={cabinList} />
                <EmployersList title="Flight attendants" data={flightAttendantList} />
                <EmployersList title="Other" data={otherList} />
            </div>
        </ErrorBoundary>
    );
};

const EmployersPageMemoized = React.memo(EmployersPage);

export { EmployersPageMemoized as EmployersPage };
