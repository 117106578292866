import React from 'react';

import BaseIcon from '../BaseIcon';

const Folder = ({ isDashed, ...props }) => (
    <BaseIcon
        {...props}
        viewBox="0 0 390 390"
        strokeWidth="2px"
        fillOpacity={isDashed ? '0.3' : '1'}
        strokeDasharray={isDashed ? '5%' : '0%'}
    >
        <path
            d="M383.889,126.058c-4.478-5.191-10.868-8.314-17.674-8.686V64.9c0-25.563-20.797-46.36-46.361-46.36h-75.278
            c-25.052,0-38.351,10.578-48.062,18.303c-7.807,6.208-12.518,9.956-22.626,9.956H65.099c-22.78,0-41.313,18.062-41.313,40.263
            v30.311c-6.806,0.372-13.196,3.495-17.674,8.686c-4.78,5.541-6.912,12.888-5.839,20.125l30.194,203.802
            c1.828,12.338,12.417,21.475,24.89,21.475h279.286c12.473,0,23.063-9.137,24.891-21.474l30.195-203.802
            C390.801,138.946,388.669,131.599,383.889,126.058z M65.099,77.094h108.788c20.687,0,32.219-9.172,41.484-16.542
            c8.552-6.802,14.732-11.716,29.204-11.716h75.278c8.858,0,16.066,7.206,16.066,16.065v52.434H54.081V87.062
            C54.081,80.823,59.683,77.094,65.099,77.094z"
        />
    </BaseIcon>
);

export default Folder;
