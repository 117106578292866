import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { DialogControls } from '../../../components/DialogControls/DialogControls';
import { useHideModal } from '../../../actions/modal';
import { setOffset } from '../../../actions/gps';
import { useStyles } from './useStyles';
import { useMobileWidth } from '../../../components/hooks/useMobileWidth';
import { useForm } from '../../../components/hooks/useForm/useForm';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../../components/material-ui/Dialog';
import { FormControl } from '../../../components/material-ui/FormControl';
import { TextField } from '../../../components/material-ui/TextField';
import { Typography } from '../../../components/material-ui/Typography';
import { FieldTypes } from '../../../components/hooks/useForm/validate';
import { Radio, RadioGroup } from '../../../components/material-ui/Radio';
import { FormControlLabel } from '../../../components/material-ui/FormControlLabel';

export const OffsetType = {
    LEFT: -1,
    RIGHT: 1,
};

const fields = {
    offset: {
        title: 'Offset',
        type: FieldTypes.NUMBER,
        required: true,
        validator: { type: 'smallint', min: 0, max: 20 },
    },
};

const Offset = React.forwardRef((props, ref) => {
    const offset = useSelector((state) => get(state, 'gps.offset', 0));
    const classes = useStyles();
    const { isMobilePortrait } = useMobileWidth();
    const hideModal = useHideModal();
    const dispatch = useDispatch();
    const handleSuccess = React.useCallback((data) => {
        dispatch(setOffset(data.offset * data.offsetType));
        hideModal();
    }, []);

    const { inputs, handleInputChange, handleSubmit, status } = useForm({
        onSuccess: handleSuccess,
        initialData: {
            offset: Math.abs(offset),
            offsetType: offset >= 0 ? OffsetType.RIGHT : OffsetType.LEFT,
        },
        fields,
    });
    const disableSubmit = Object.values(status || {}).some((item) => item) || inputs.offset === undefined || inputs.offset === '';
    const actions = React.useMemo(
        () => [
            { label: 'CANCEL', onClick: hideModal },
            { label: 'OK', color: 'primary', type: 'submit', disabled: disableSubmit },
        ],
        [disableSubmit]
    );

    return (
        <Dialog ref={ref} open={true} maxWidth="sm" fullScreen={isMobilePortrait}>
            <DialogTitle className={classes.title}>Set OFFSET</DialogTitle>
            <form className={classes.fields} onSubmit={handleSubmit}>
                <DialogContent className={classes.content}>
                    <RadioGroup
                        name="offsetType"
                        value={+get(inputs, 'offsetType', OffsetType.RIGHT)}
                        onChange={handleInputChange}
                        row={true}
                    >
                        <FormControlLabel className={classes.radio} value={OffsetType.LEFT} control={<Radio />} label="LEFT" />
                        <FormControlLabel className={classes.radio} value={OffsetType.RIGHT} control={<Radio />} label="RIGHT" />
                    </RadioGroup>
                    <FormControl className={classes.offset}>
                        <TextField
                            name="offset"
                            label="Offset"
                            onChange={handleInputChange}
                            helperText={status.offset}
                            error={Boolean(status.offset)}
                            fullWidth={true}
                            type="number"
                            value={get(inputs, 'offset', 0)}
                            className={classes.offset}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <Typography color="error">{status.message}</Typography>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <DialogControls actions={actions} />
                </DialogActions>
            </form>
        </Dialog>
    );
});

export { Offset };
