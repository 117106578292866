import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';

import { AppBar } from '../../../components/material-ui/AppBar';
import { IconButton } from '../../../components/material-ui/IconButton';
import { DialogControls } from '../../../components/DialogControls/DialogControls';
import { Toolbar } from '../../../components/material-ui/Toolbar';
import { Typography } from '../../../components/material-ui/Typography';
import { useStyles } from './useStyles';
import { useHideModal } from '../../../actions/modal';

const NoticeAppBar = (props) => {
    const { title, sidebarOpen, onLeftIconButtonClick, onMarkAll } = props;
    const classes = useStyles();
    const hideModal = useHideModal();

    const actionList = React.useMemo(
        () => [
            {
                label: 'Read all',
                variant: 'text',
                onClick: onMarkAll,
            },
            {
                label: 'Close',
                variant: 'text',
                onClick: hideModal,
            },
        ],
        []
    );

    return (
        <AppBar className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <IconButton onClick={onLeftIconButtonClick}>
                    {sidebarOpen ? (
                        <CloseIcon aria-label="Menu" className={classes.icon} />
                    ) : (
                        <MenuIcon aria-label="Menu" className={classes.icon} />
                    )}
                </IconButton>
                <Typography className={classes.title}>{title}</Typography>
                <DialogControls actions={actionList} appBar={true} />
            </Toolbar>
        </AppBar>
    );
};

NoticeAppBar.defaultProps = {
    sidebarOpen: false,
};

const NoticeAppBarMemoized = React.memo(NoticeAppBar);

export { NoticeAppBarMemoized as NoticeAppBar };
