import { UPDATE_IMAGE, UPDATE_IMAGE_URL, REMOVE_SIGN } from '../actions/images';
import { UPDATE_REPORTS_AFTER_SAVE } from '../actions/reports';
import { LOGOUT } from '../actions/screen';
import { CLEAR_SENDED_DATA_LIST } from '../actions/sended-data';
import { TelegramPrefix } from '../service/constants';
import { SignaturePrefix } from '../actions/tasks';

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_IMAGE: {
            const newImgState = {
                id: action.id,
                contentType: action.contentType,
            };

            if (action.url) {
                newImgState.url = action.url;
            }

            return {
                ...state,
                [action.id]: newImgState,
            };
        }
        case UPDATE_IMAGE_URL:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    url: action.url,
                },
            };
        case UPDATE_REPORTS_AFTER_SAVE: {
            const newState = Object.assign({}, state);

            Object.keys(action.reports).forEach((key) => {
                const report = action.reports[key];
                Object.keys(report.imagesIds).forEach((key) => {
                    const id = `${report.imagesIds[key]}`;
                    const fullPrevName = `si_${key}`;
                    const fullNewName = `si_${id}`;
                    newState[fullNewName] = { ...newState[fullPrevName], id: fullNewName };
                    delete newState[fullPrevName];
                });
            });

            return newState;
        }
        case REMOVE_SIGN: {
            const newState = { ...state };
            const { signatureName } = action;

            delete newState[signatureName];

            return newState;
        }
        case CLEAR_SENDED_DATA_LIST: {
            const newState = { ...state };

            const { removedTelegrams = [], removedOfps = [], removedTasks = [] } = action;
            removedTelegrams.forEach((id) => {
                delete newState[`${TelegramPrefix.SIGN}${id}`];
            });

            removedOfps.forEach((id) => {
                delete newState[`${SignaturePrefix.OFP}${id}`];
            });

            removedTasks.forEach((id) => {
                delete newState[`${SignaturePrefix.TASK}${id}`];
            });

            return newState;
        }
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};
