import React from 'react';

import { Radio, RadioGroup } from '../../../components/material-ui/Radio';
import { FormControlLabel } from '../../../components/material-ui/FormControlLabel';
import { Typography } from '../../../components/material-ui/Typography';

export const SelectionType = {
    CURRENT: 0,
    NEXT: 1,
};

const styles = {
    radioButton: {
        flex: '1 0 30%',
    },
};

const GpsRouteSelectionType = (props) => {
    const { selectionType, onChangeSelectionType } = props;

    return (
        <>
            <Typography>Highlight detected point:</Typography>
            <RadioGroup
                name="typeName"
                value={selectionType}
                className="navroute-settings__selection-type"
                onChange={onChangeSelectionType}
            >
                <FormControlLabel
                    value={SelectionType.CURRENT}
                    control={<Radio color="primary" />}
                    label="Current"
                    style={styles.radioButton}
                />
                <FormControlLabel value={SelectionType.NEXT} control={<Radio color="primary" />} label="Next" style={styles.radioButton} />
            </RadioGroup>
        </>
    );
};

const GpsRouteSelectionTypeMemoized = React.memo(GpsRouteSelectionType);

export { GpsRouteSelectionTypeMemoized as GpsRouteSelectionType };
