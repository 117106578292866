import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

function NestedListIconContrast(props) {
    const { open = false } = props;
    const theme = useTheme();
    const { color } = theme.icon;

    return open ? <KeyboardArrowUpIcon style={{ fill: color }} /> : <KeyboardArrowDownIcon style={{ fill: color }} />;
}

const NestedListIconContrastMemoized = React.memo(NestedListIconContrast);

export { NestedListIconContrastMemoized as NestedListIconContrast };
