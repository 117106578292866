import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    bold: {
        color: theme.textField.highLightTextColor,
        fontWeight: '600',
        paddingLeft: 0,
    },
    menuWrapper: {
        width: '48px',
        verticalAlign: 'middle',
    },
    pointCurrent: { backgroundColor: theme.point.current },
    pointDirected: { backgroundColor: theme.point.directed },
    pointDirect: { backgroundColor: theme.point.direct },
    pointEdit: { backgroundColor: theme.point.edit },
    pointWarning: { backgroundColor: theme.point.warning },
    simple: {
        paddingLeft: 0,
    },
    title: {
        fontSize: '16px',
        fontWeight: '600',
    },
    text: {
        whiteSpace: 'pre',
        fontSize: '16px',
    },
}));
