import { makeStyles } from '@material-ui/styles';

const useStyles = (colorName) =>
    makeStyles((theme) => {
        const {
            notice: {
                accentColor,
                airportBackgroundColor,
                textColor,
                ifrColor,
                vfrColor,
                scopeColor,
                assignmentColor,
                numberColor,
                airportGroup,
                favoriteColor,
                newColor = '#00b600',
            },

            icon: { color: iconColor },
        } = theme;

        return {
            begin: {
                fontSize: 18,
                marginRight: 20,
                ['@media only screen and (max-width: 500px)']: {
                    flexBasis: '100%',
                },
            },
            controls: {
                position: 'absolute',
                top: 6,
                right: 0,
            },
            details: {
                position: 'relative',
                display: 'flex',
                flexWrap: 'wrap',
                marginBottom: 6,
                paddingRight: 180,

                ['@media only screen and (max-width: 768px)']: {
                    paddingRight: 150,
                },

                ['@media only screen and (max-width: 500px)']: {
                    paddingRight: 0,
                },
            },
            end: {
                fontSize: 18,
                ['@media only screen and (max-width: 500px)']: {
                    flexBasis: '100%',
                },
            },
            ifr: {
                color: textColor,
                backgroundColor: ifrColor,
            },
            favoriteIcon: {
                color: favoriteColor,
            },
            fir: {
                fontSize: 18,
                fontWeight: 600,
            },
            linkIcon: {
                margin: theme.spacing(0, 1),
                color: iconColor,
            },
            newIcon: {
                color: newColor,
            },
            noBorder: {
                borderTop: 'none !important',
            },
            notice: {
                position: 'relative',
                padding: 10,

                borderTop: `2px solid ${airportBackgroundColor}`,
                borderLeft: `6px solid ${airportGroup[colorName] || 'inherit'}`,
            },
            number: {
                display: 'inline-block',
                padding: '0 6px',
                borderRadius: 6,

                fontSize: 18,
                color: textColor,
                backgroundColor: numberColor,
            },
            periodTitle: {
                color: accentColor,
            },
            purpose: {
                color: textColor,
                backgroundColor: assignmentColor,
            },
            scope: {
                color: textColor,
                backgroundColor: scopeColor,
            },
            subtitle: {
                fontSize: 18,
                flexBasis: '100%',
            },
            tag: {
                padding: '0 6px',
                borderRadius: 6,
                fontSize: 16,
            },
            tags: {
                position: 'absolute',
                top: 0,
                right: 0,
                display: 'flex',
                width: 180,
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'center',

                ['@media only screen and (max-width: 768px)']: {
                    width: 160,
                },

                ['@media only screen and (max-width: 500px)']: {
                    position: 'relative',
                    flexBasis: '100%',
                },
            },
            text: {
                fontSize: 18,
                wordWrap: 'break-word',
            },
            titleWrapper: {
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                paddingRight: 96,
            },
            vfr: {
                color: textColor,
                backgroundColor: vfrColor,
            },
        };
    });

export { useStyles };
