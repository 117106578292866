import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        addBtn: {
            position: 'fixed',
            right: '20px',
            bottom: '20px',
            zIndex: '1',
        },
        filterContent: {
            display: 'flex',
            flexDirection: 'column',
        },
        filterIconActive: {
            color: theme.palette.primary.main,
        },
        selectField: {
            width: 'auto',
            flex: '1 0 40%',
            paddingLeft: '0',
        },
        page: {
            display: 'block',
            width: '100%',
            margin: '0 auto',
        },
        wrapper: {
            paddingTop: 5,
        },
    };
});
