import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { getPassengerSeats } from '../../../selectors/passengers';
import { useStyles } from './useStyles';
import { Aisle } from './Aisle/Aisle';
import { Seat } from './Seat/Seat';
import { Typography } from '../../material-ui/Typography';

export const PassengerGridView = React.memo(({ isMobile }) => {
    const classes = useStyles();
    const passengerSeats = useSelector(getPassengerSeats);
    const isPlnCabinExist = passengerSeats && Object.keys(passengerSeats).length > 0;

    return (
        <div>
            {!isPlnCabinExist ? (
                <Typography>There is not cabin layout for aircraft. Use table view for passenger list.</Typography>
            ) : (
                <div className="passenger-grid">
                    {passengerSeats &&
                        Object.keys(passengerSeats).map((rowNumber) => {
                            const row = passengerSeats[rowNumber];
                            const isAisleExist = row && row.some((item) => item.type);

                            return (
                                <section key={rowNumber} className={classnames('passenger-grid__row', rowNumber % 2 && classes.secondRow)}>
                                    {row.map((seat) =>
                                        seat.type ? (
                                            <Aisle key={`${seat.row} ${seat.id} ${seat.name}`} seat={seat} />
                                        ) : (
                                            <Seat
                                                key={`${seat.row} ${seat.id} ${seat.name}`}
                                                seat={seat}
                                                isAisleExist={isAisleExist}
                                                isMobile={isMobile}
                                            />
                                        )
                                    )}
                                </section>
                            );
                        })}
                </div>
            )}
        </div>
    );
});
