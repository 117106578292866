import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
    return {
        library: {
            display: 'block',
            width: '100%',
            margin: '0 auto',
        },
    };
});

export { useStyles };
