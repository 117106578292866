import React from 'react';
import { useSelector } from 'react-redux';

import {
    getAppWeightUnits,
    getCfpWeightUnits,
    getFieldsForConvert,
    getIsOfpChanged,
    getNeedToConvert,
    getSendedDataConvertedTo,
} from '../../selectors/sended-data';
import { useConvertWeightValues } from '../../actions/sended-data';
import { Weight } from '../../reducers/systemOfMeasurement';
import { weightConverter } from '../../service/measureConverter';
import { getCurrentOfp } from '../../selectors/screen';
import { useAddWeightUnitsToHistory, useResetIsOfpChanged } from '../../actions/systemOfMeasurement';
import { get, isNil } from 'lodash';
import { StepNumber, WizardStep } from '../FlightWizardPage/constants';
import { getSystemOfMeasurementOption } from '../../selectors/config';

export const exceptionsOnChangeCFP = [
    'expenseBeforeTakeoff',
    'fuelFlowAvg',
    'minFuelMAPt',
    'tripFuel',
    'taxiFuel',
    'dryOperatingWeight',
    'payLoadEstimate',
    'payLoadReal',
    'zeroFuelWeightEstimate',
    'zeroFuelWeightReal',
    'zeroFuelWeightMax',
    'takeOffWeightEstimate',
    'takeOffWeightReal',
    'takeOffWeightMax',
    'extraCrewWeight',
    'landingWeightEstimate',
    'landingWeightReal',
    'landingWeightMax',
    'remainFuel',
    'onBoardFuel',
    'est',
    'est_default',
    'act',
    'used',
    'used_default',
];

const useControlOfpChange = ({
    isUsedCfpWeightUnits,
    convertWeightValues,
    convertedTo,
    legId,
    fieldsForConvert,
    wizardStep,
    prevWizardStep,
    appWeightUnits,
    cfpWeightUnits,
    isOfpChanged,
}) => {
    const resetIsOfpChanged = useResetIsOfpChanged();
    const addWeightUnitsToHistory = useAddWeightUnitsToHistory();

    React.useEffect(() => {
        if (isUsedCfpWeightUnits && isOfpChanged) {
            const isCfpInLBS = cfpWeightUnits === Weight.LBS;
            const isNavRouteStep = wizardStep === StepNumber[WizardStep.NAV_ROUTE];
            const isNavRoutePrevStep = prevWizardStep === StepNumber[WizardStep.NAV_ROUTE];
            const isSendedDataWasConverted = !isNil(convertedTo);
            const isSendedDataInLBS = convertedTo === Weight.LBS;
            const isSendedDataAndAppUnitsEqual = convertedTo === appWeightUnits;
            const isFirstRenderWizard = wizardStep === StepNumber[WizardStep.START] && prevWizardStep === null;

            if (isCfpInLBS) {
                if (isSendedDataWasConverted && isSendedDataAndAppUnitsEqual && isSendedDataInLBS && isOfpChanged) {
                    convertWeightValues({
                        legId,
                        fieldsForConvert: fieldsForConvert.filter((forConvertItem) => exceptionsOnChangeCFP.includes(forConvertItem)),
                        convertTo: appWeightUnits,
                        options: {},
                    });
                    addWeightUnitsToHistory({
                        weightUnitsState: {
                            event: 'CFP change. isSendedDataWasConverted && isSendedDataAndAppUnitsEqual && isSendedDataInLBS && isOfpChanged',
                            appUnits: appWeightUnits,
                            convertTo: appWeightUnits,
                        },
                        legID: legId,
                    });
                }
            } else {
                if (!isNavRouteStep && isSendedDataInLBS) {
                    convertWeightValues({
                        legId,
                        fieldsForConvert,
                        convertTo: Weight.KG,
                        options: {
                            exceptions: isNavRoutePrevStep || isFirstRenderWizard || !isSendedDataWasConverted ? [] : exceptionsOnChangeCFP,
                        },
                    });
                    addWeightUnitsToHistory({
                        weightUnitsState: {
                            event: 'CFP change. CFP not lbs. !isNavRouteStep && isSendedDataInLBS ',
                            appUnits: appWeightUnits,
                            convertTo: Weight.KG,
                        },
                        legID: legId,
                    });
                }
            }
        }
        resetIsOfpChanged();
    }, [isOfpChanged, legId]);
};

const useControlWizardStepChange = ({
    pathname,
    isUsedCfpWeightUnits,
    convertWeightValues,
    wizardStep,
    prevWizardStep,
    convertedTo,
    appWeightUnits,
    cfpWeightUnits,
    legId,
    fieldsForConvert,
}) => {
    const addWeightUnitsToHistory = useAddWeightUnitsToHistory();

    React.useEffect(() => {
        const isWizardPage = pathname === '/flight-info';
        if (isUsedCfpWeightUnits && isWizardPage) {
            const isCfpInLBS = cfpWeightUnits === Weight.LBS;
            const isNavRouteStep = wizardStep === StepNumber[WizardStep.NAV_ROUTE];
            const isStartStep = wizardStep === StepNumber[WizardStep.START];
            const isNavRoutePrevStep = prevWizardStep === StepNumber[WizardStep.NAV_ROUTE];
            const isAppWeightUnitsInLBS = appWeightUnits === Weight.LBS;
            const isSendedDataWasConverted = !isNil(convertedTo);
            const isSendedDataInKG = convertedTo === Weight.KG;
            const isSendedDataAndAppUnitsEqual = convertedTo === appWeightUnits;
            const isFirstRenderWizard = wizardStep === StepNumber[WizardStep.START] && prevWizardStep === null;

            if (isCfpInLBS) {
                if (isNavRouteStep) {
                    if (!isSendedDataWasConverted || isSendedDataInKG) {
                        convertWeightValues({
                            legId,
                            fieldsForConvert,
                            convertTo: Weight.LBS,
                            options: {},
                        });
                        addWeightUnitsToHistory({
                            weightUnitsState: {
                                event: 'Step change. Go to Nav route',
                                appUnits: Weight.LBS,
                                convertTo: Weight.LBS,
                            },
                            legID: legId,
                        });
                    }

                    return;
                }

                if (isStartStep && isSendedDataWasConverted && !isSendedDataInKG) {
                    convertWeightValues({
                        legId,
                        fieldsForConvert,
                        convertTo: Weight.KG,
                        options: {},
                    });

                    return;
                }
                if (isSendedDataWasConverted ? !isSendedDataAndAppUnitsEqual : isAppWeightUnitsInLBS) {
                    convertWeightValues({
                        legId,
                        fieldsForConvert,
                        convertTo: appWeightUnits,
                        options: {
                            exceptions:
                                isNavRoutePrevStep || isFirstRenderWizard || !isSendedDataWasConverted || !isSendedDataAndAppUnitsEqual
                                    ? []
                                    : exceptionsOnChangeCFP,
                        },
                    });
                    addWeightUnitsToHistory({
                        weightUnitsState: {
                            event: `Step change. Not Nav route. ${
                                isSendedDataAndAppUnitsEqual ? 'Not equal units' : 'Initial. Cfp in lbs'
                            }`,
                            appUnits: appWeightUnits,
                            convertTo: appWeightUnits,
                        },
                        legID: legId,
                    });
                }
            }
        }
    }, [wizardStep, pathname]);
};

const useControlAppWeightUnitsChange = ({
    isUsedCfpWeightUnits,
    convertWeightValues,
    appWeightUnits,
    convertedTo,
    wizardStep,
    cfpWeightUnits,
    legId,
    fieldsForConvert,
}) => {
    const addWeightUnitsToHistory = useAddWeightUnitsToHistory();

    React.useEffect(() => {
        if (isUsedCfpWeightUnits) {
            const isCfpInLBS = cfpWeightUnits === Weight.LBS;
            const isAppWeightUnitsInLBS = appWeightUnits === Weight.LBS;
            const isSendedDataWasConverted = !isNil(convertedTo);
            const isSendedDataAndAppUnitsNotEqual = convertedTo !== appWeightUnits;
            const isNavRouteStep = wizardStep === StepNumber[WizardStep.NAV_ROUTE];
            const isStarttStep = wizardStep === StepNumber[WizardStep.START];

            if (isCfpInLBS) {
                if (!isStarttStep && !isNavRouteStep && isSendedDataWasConverted && isSendedDataAndAppUnitsNotEqual) {
                    convertWeightValues({
                        legId,
                        fieldsForConvert,
                        convertTo: appWeightUnits,
                        options: {},
                    });
                    addWeightUnitsToHistory({
                        weightUnitsState: {
                            event: 'Weight units toggled. Not nav route step and sended data was converted and units not equal',
                            appUnits: appWeightUnits,
                            convertTo: appWeightUnits,
                        },
                        legID: legId,
                    });
                }
                if (!isNavRouteStep && !isSendedDataWasConverted && isAppWeightUnitsInLBS) {
                    convertWeightValues({
                        legId,
                        fieldsForConvert,
                        convertTo: appWeightUnits,
                        options: {},
                    });
                    addWeightUnitsToHistory({
                        weightUnitsState: {
                            event: 'Weight units toggled. Not nav route step and leg was not converted and appUnits in lbs',
                            appUnits: appWeightUnits,
                            convertTo: appWeightUnits,
                        },
                        legID: legId,
                    });
                }
            }
        }
    }, [appWeightUnits]);
};

const useMeasureConverter = () => {
    const pathname = useSelector((state) => get(state, 'router.location.pathname'));
    const convertWeightValues = useConvertWeightValues();
    const legId = useSelector((state) => get(state, 'screen.currentLeg', null));
    const convertedTo = useSelector(getSendedDataConvertedTo);
    const needToConvert = useSelector(getNeedToConvert);
    const fieldsForConvert = useSelector(getFieldsForConvert);
    const appWeightUnits = useSelector(getAppWeightUnits);
    const isOfpChanged = useSelector(getIsOfpChanged);
    const cfpWeightUnits = useSelector(getCfpWeightUnits);
    const isUsedCfpWeightUnits = useSelector(getSystemOfMeasurementOption);
    const wizardStep = useSelector((state) => get(state, 'wizard.wizardStep', 0));
    const prevWizardStep = useSelector((state) => get(state, 'wizard.prevWizardStep', null));

    const hooksProps = {
        legId,
        convertedTo,
        needToConvert,
        fieldsForConvert,
        appWeightUnits,
        cfpWeightUnits,
        convertWeightValues,
        wizardStep,
        prevWizardStep,
        isUsedCfpWeightUnits,
        pathname,
    };

    useControlWizardStepChange(hooksProps);
    useControlAppWeightUnitsChange(hooksProps);
    useControlOfpChange({
        isOfpChanged,
        ...hooksProps,
    });
};

const useConvert = (value) => {
    const { weightUnits: cfpWeightUnits } = useSelector(getCurrentOfp) || {};
    const appWeightUnits = useSelector(getAppWeightUnits);
    const convertedTo = useSelector(getSendedDataConvertedTo);

    if (cfpWeightUnits === Weight.KG) {
        return value;
    }

    if (convertedTo === appWeightUnits) {
        return value;
    }

    return weightConverter({
        value,
        currentDimension: appWeightUnits,
    });
};

const useSingleConvert = () => {
    const { weightUnits: cfpWeightUnits } = useSelector(getCurrentOfp) || {};
    const appWeightUnits = useSelector(getAppWeightUnits);

    return (value) => {
        if (cfpWeightUnits === Weight.KG || appWeightUnits === Weight.KG) {
            return value;
        }

        return weightConverter({
            value,
            currentDimension: appWeightUnits,
        });
    };
};

export { useMeasureConverter, useConvert, useSingleConvert };
