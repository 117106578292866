import React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useTheme } from '@material-ui/core';

/**
 * Dialogs can be nested. This example opens a Date Picker from within a Dialog.
 */
const MoreVertIconContrast = (props) => {
    const { isContrast = false, style = {} } = props;
    const theme = useTheme();
    const { contrastColor, color } = theme.icon;

    return (
        <MoreVertIcon
            style={{
                width: '30px',
                height: '30px',
                color: isContrast ? contrastColor : color,
                ...style,
            }}
        />
    );
};

const MoreVertIconContrastMemoized = React.memo(MoreVertIconContrast);

export { MoreVertIconContrastMemoized as MoreVertIconContrast };
