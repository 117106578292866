import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    filter: {
        width: '100%',
        padding: theme.spacing(1.5),
        flexDirection: 'column',
        flexWrap: 'nowrap',
    },
    noDataString: {
        margin: theme.spacing(7),
        textAlign: 'center',
    },
    margin: {
        position: 'relative',
        width: '100%',
        marginBottom: '140px',
    },
    select: {
        position: 'relative',
        width: '100%',
        margin: 0,
        marginBottom: theme.spacing(2),
    },
}));
