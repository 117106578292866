import * as React from 'react';
import { isNil } from 'lodash';

import { Typography } from '../material-ui/Typography';
import { Chip } from '../material-ui/Chip';
import { Weights } from '../../reducers/systemOfMeasurement';

const WeightUnits = React.memo(({ weightUnits }) => {
    if (isNil(weightUnits)) {
        return null;
    }

    return <Chip variant="outlined" label={<Typography>{Weights[weightUnits]}</Typography>} />;
});
WeightUnits.displayName = 'WeightUnits';

export { WeightUnits };
