import { makeStyles } from '@material-ui/styles';

const useStyles = (isActive) =>
    makeStyles(() => {
        const color = isActive ? '#4CAF50' : '#F48FB1';

        return {
            icon: {
                width: 25,
                height: 25,
                minWidth: 'auto',
                background: color,
                borderRadius: '50%',
                boxShadow: `0 0 8px ${color}, inset 0 0 3px rgba(0, 0, 0, 0.7)`,
                boxSizing: 'border-box',
            },
            iconButton: {
                width: 48,
                height: 48,
            },
        };
    });

export { useStyles };
