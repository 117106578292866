import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { ActionTimeControl } from '../../ActionTimeControl/ActionTimeControl';
import { MoreVertIconContrast } from '../../../icons/MoreVertIconContrast';
import { getCurrentSendedTakeOff } from '../../../selectors/sended-data';
import { Clock } from '../../../icons';
import { getTimeFormat } from '../../../service/NavPointsUtils';
import { getTimeDiff } from '../../../service/DateTimeUtils';
import { getFirstLeg, getPrevLeg, getUserLegs } from '../../../selectors/screen';
import { onChangeTime } from '../../../actions/take-off';

const StartWorkTime = () => {
    const dispatch = useDispatch();
    const { value, timeWorkRest, defaultValue, isFirstLeg, ofpId } = useSelector((state) => {
        const currentLegId = get(state, 'screen.currentLeg');
        const takeOff = getCurrentSendedTakeOff(state);
        const prevLegId = get(getPrevLeg(state), 'id');
        const userLegs = getUserLegs(state);
        const isPrevLegForUser = userLegs[prevLegId] !== undefined;
        const endWorkTime = isPrevLegForUser && get(state, `sendedData.[${prevLegId}].landing.endWorkTime`);
        const firstLeg = getFirstLeg(state) || {};
        const ofpId = get(state, `sendedData[${currentLegId}].ofpId`);

        return {
            value: takeOff.startWorkTime,
            timeWorkRest: getTimeFormat(getTimeDiff(endWorkTime, takeOff.startWorkTime)),
            defaultValue: endWorkTime,
            ofpId: +ofpId,
            isFirstLeg: firstLeg.id === currentLegId || (firstLeg.id === prevLegId && !isPrevLegForUser),
        };
    }, shallowEqual);

    const summary = React.useMemo(() => {
        const res = [];

        if (timeWorkRest) {
            res.push({
                title: 'Rest time ',
                value: timeWorkRest,
            });
        }

        return res;
    }, [timeWorkRest]);

    const handleChangeTime = React.useCallback((name, value) => {
        dispatch(onChangeTime(name, value));
    }, []);

    const title = isFirstLeg ? 'Start duty time' : 'Start leg time';
    const text = isFirstLeg ? 'Duty time started on' : 'Started leg on';

    return (
        <ActionTimeControl
            {...{ title, text, value, defaultValue, summary }}
            buttonName={title}
            state="ENTER_COMPLETED"
            disabled={!ofpId}
            iconButton={<Clock width="22" height="22" />}
            iconMenuImg={<MoreVertIconContrast isContrast={true} />}
            name="startWorkTime"
            onChange={handleChangeTime}
            dialogText={
                !isFirstLeg
                    ? `The current leg is not the first in the flight task.
                        By default, if there was no rest, then the beginning of working time on the leg should coincide with the end leg time on the previous one.
                        If you want to add a rest, enter end time of the rest as value.`
                    : ''
            }
        />
    );
};

const StartWorkTimeMemoized = React.memo(StartWorkTime);

export { StartWorkTimeMemoized as StartWorkTime };
