import React from 'react';

import { Panel } from '../../Panel/Panel';
import { CustomField } from '../../CustomField/CustomField';
import { useStyles } from './useStyles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getCurrentSendedLeg } from '../../../selectors/sended-data';
import { get } from 'lodash';
import { editOfpField } from '../../../actions/sended-data';
import * as landing from '../../../actions/landing';
import { editField } from '../../../actions/take-off';

const fields = [
    {
        id: 'domac',
        label: 'DOMAC',
        editHandler: 'handleEditTotalField',
        validator: { type: 'decimal', min: 0, max: 100, precision: [5, 2] },
    },
    {
        id: 'maczfw',
        label: 'MACZFW',
        editHandler: 'handleEditTotalField',
        validator: { type: 'decimal', min: 0, max: 100, precision: [5, 2] },
    },
    {
        id: 'amcTakeoff',
        label: 'MACTOW',
        editHandler: 'handleEditField',
        validator: { type: 'decimal', min: 0, max: 100, precision: [5, 2] },
    },
    {
        id: 'amcLanding',
        label: 'MACLAW',
        editHandler: 'handleEditLandingField',
        validator: { type: 'decimal', min: 0, max: 100, precision: [5, 2] },
    },
    {
        id: 'componovka',
        label: 'Version',
        editHandler: 'handleEditField',
        validator: { type: 'int', min: 0, max: 999 },
    },
];

function setRequiredStyle(declaration, fieldName) {
    const takeOff = declaration.takeOff || {};

    if (['maczfw', 'domac'].indexOf(fieldName) !== -1) {
        return declaration[fieldName] && declaration[fieldName].required;
    }

    return takeOff[fieldName] && takeOff[fieldName].required;
}

function AdditionalInfoPanel({ declaration }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const props = useSelector((state) => {
        const props = {};
        const leg = getCurrentSendedLeg(state);
        props.amcTakeoff = get(leg, 'takeOff.amcTakeoff');
        props.componovka = get(leg, 'takeOff.componovka');
        props.amcLanding = get(leg, 'landing.amcLanding');
        props.maczfw = get(leg, 'maczfw');
        props.domac = get(leg, 'domac');

        return props;
    }, shallowEqual);

    const callbacks = React.useMemo(
        () => ({
            handleEditTotalField: (name, value) => dispatch(editOfpField(name, value)),
            handleEditLandingField: (name, value) => dispatch(landing.editField(name, value)),
            handleEditField: (name, value) => dispatch(editField(name, value === null || value === undefined ? null : +value)),
        }),
        []
    );

    return (
        <Panel title="Additional info" style={{ margin: '0' }}>
            <div className={classes.wrapper}>
                {fields &&
                    fields.map(({ id, label, validator, editHandler }) => {
                        const defaultValue = props && props[id];

                        return (
                            <CustomField
                                key={`${defaultValue}:${id}`}
                                label={label}
                                name={id}
                                className={classes.field}
                                onEditField={callbacks[editHandler]}
                                required={setRequiredStyle(declaration, id)}
                                defaultValue={defaultValue}
                                validator={validator}
                                type="number"
                            />
                        );
                    })}
            </div>
        </Panel>
    );
}

const AdditionalInfoPanelMemoized = React.memo(AdditionalInfoPanel);

export { AdditionalInfoPanelMemoized as AdditionalInfoPanel };
