import { LOGIN, SHOW_ERROR, GET_USER_DATA, SET_SETTINGS, SET_RELOGIN_VISIBLE } from '../actions/login';

import { LOGOUT } from '../actions/screen';

const initialState = {
    user: {
        data: {},
    },
    config: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_SETTINGS:
            return Object.assign({}, state, {
                backendUrl: action.backendUrl,
                ssl: action.ssl,
                backupInterval: action.backupInterval,
            });

        case LOGIN:
            return Object.assign({}, state, { error: '', user: action.user, config: action.config, syncTime: null });
        case LOGOUT:
            return Object.assign({}, state, { user: {}, config: {}, syncTime: null });
        case SET_RELOGIN_VISIBLE:
            return { ...state, isReloginVisible: action.isReloginVisible, error: '' };
        case SHOW_ERROR:
            return Object.assign({}, state, { error: action.error });
        case GET_USER_DATA: {
            const { passengersTemplates, loadingTemplates } = action.user.userData;
            return Object.assign({}, state, {
                user: Object.assign(state.user, {
                    name: action.user.name,
                    avatar: action.user.avatar,
                    data: {
                        passengersTemplates,
                        loadingTemplates,
                    },
                }),
                syncTime: new Date().getTime(),
            });
        }

        default:
            return state;
    }
};
