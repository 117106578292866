import moment from 'moment';

import { SET_FILTERS, RESET_FILTERS, RESET_FILTERS_DATES } from '../actions/filter';
import { LOGOUT } from '../actions/screen';

const FilterTypes = {
    REPORT_FILTER: 'reportFilter',
    REPORT_LOADING: 'reportLoading',
    PASSENGERS: 'passengerList',
};

const initialState = {
    [FilterTypes.REPORT_FILTER]: {
        types: [],
        groups: [],
    },
    [FilterTypes.REPORT_LOADING]: {
        dateFrom: moment.utc().subtract(3, 'days').startOf('day'),
        dateTo: moment.utc().add(1, 'days').endOf('day'),
    },
    [FilterTypes.PASSENGERS]: {
        passengerType: null,
        ssrType: null,
        ssr: null,
    },
};

const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_FILTERS:
            return { ...initialState };
        case RESET_FILTERS_DATES:
            return {
                ...state,
                [action.filterType]: {
                    ...state[action.filterType],
                    dateFrom: initialState[action.filterType].dateFrom,
                    dateTo: initialState[action.filterType].dateTo,
                    lastChangeTime: moment.utc(),
                },
            };
        case SET_FILTERS:
            return {
                ...state,
                [action.filterType]: { ...state[action.filterType], ...action.values, lastChangeTime: moment.utc() },
            };
        case LOGOUT:
            return { ...initialState };
        default:
            return state;
    }
};

export { filterReducer, initialState as initialFilterState, FilterTypes };
