import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { toolbarMinHeight } from '../../../service/constants';

export const useStyles = makeStyles(() =>
    createStyles({
        filter: {
            display: 'flex',
            alignItems: 'baseline',
            flexWrap: 'wrap',
        },
        filterButton: {
            position: 'fixed',
            top: `${toolbarMinHeight}px`,
            margin: '12px',
            marginLeft: '0 !important',
        },
        filterClearButton: {
            position: 'fixed',
            top: `${toolbarMinHeight}px`,
            margin: '12px',
            marginLeft: '110px !important',
        },
    })
);
