import { useRef, useState, useCallback } from 'react';

export const AuthConfirmationTypes = {
    NO: 0,
    PHONE: 1,
    EMAIL: 2,
    ALL: 3,
};

export const useTwoFactor = () => {
    const [confirmationCode, setCode] = useState('');
    const [refreshBlocked, setRefreshBlocked] = useState(false);
    const [sendingInfo, setSendingInfo] = useState('');
    const confirmationCodeRef = useRef(null);

    const handleCodeChange = useCallback((evt) => setCode(evt.target.value), [setCode]);
    const handleChangeRefreshBlocked = useCallback((value) => setRefreshBlocked(value), [setRefreshBlocked]);
    const handleChangeSendingInfo = useCallback((info) => setSendingInfo(info), [setSendingInfo]);

    return {
        confirmationCode,
        refreshBlocked,
        confirmationCodeRef,
        sendingInfo,
        setCode,
        onCodeChange: handleCodeChange,
        changeRefreshBlocked: handleChangeRefreshBlocked,
        changeSendingInfo: handleChangeSendingInfo,
    };
};
