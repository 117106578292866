import moment from 'moment';

export const fields = [
    { id: 'flight', label: 'Flight' },
    { id: 'pln', label: 'AC reg.' },
    { id: 'task', label: 'Flight task' },
    { id: 'operatorEng', label: 'АС operator' },
    { id: 'flightCommunicationType', label: 'Communication' },
    { id: 'flightMeanType', label: 'Flight mean type' },
    { id: 'flightType', label: 'Flight type' },
    { id: 'passengersMax', label: 'Seat сonfiguration' },
    { id: 'statusCode', label: 'Status' },
    { id: 'markCodeCompany', label: 'Comment', full: true },
    { id: 'markFlightNumber', label: 'Flight comment', full: true },
];

export const getValue = (props, id) => {
    const { flight = {}, task = {} } = props || {};
    const { num, date, route } = flight;
    const { flightTask, taskDate } = task;

    switch (id) {
        case 'flight':
            return `${num} / ${moment(date).format('DD.MM.YY')}`;
        case 'task':
            return `${flightTask} ${moment(taskDate).format('DD.MM.YY')} (${route})`;
    }

    return flight[id];
};
