import React from 'react';

import { Typography } from '../../../../components/material-ui/Typography';
import { useStyles } from './useStyles';

function FlightTaskCrewItem({ item, getTextField, getMessage }) {
    const pplsCode = getTextField('pplsCode', item);
    const ppls = getTextField('ppls', item);
    const pplsString = `${getMessage`Ppls`} ${pplsCode ? `${pplsCode} ` : ''}${ppls ? `${ppls} ` : ''}`;
    const classes = useStyles();

    const rows = [
        <tr key={0}>
            <td className={classes.col}>
                <Typography>{item.tableNumber}</Typography>
            </td>
            <td className={classes.col2}>
                <Typography>{getTextField('chair', item)}</Typography>
            </td>
            <td className={classes.col3}>
                <Typography>{getTextField('fullName', item)}</Typography>
            </td>
            <td className={classes.col4}>
                <Typography>{item.minimum}</Typography>
            </td>
        </tr>,
    ];

    if (ppls || pplsCode) {
        rows.push(
            <tr key={1} className={classes.comment}>
                <td colSpan={3} className={classes.comment}>
                    <Typography>{pplsString}</Typography>
                </td>
            </tr>
        );
    }

    if (item.comment) {
        rows.push(
            <tr key={2} className={classes.comment}>
                <td colSpan={3} className={classes.comment}>
                    <Typography>
                        {getMessage`Comment`}: {item.comment}
                    </Typography>
                </td>
            </tr>
        );
    }

    return <>{rows}</>;
}

export { FlightTaskCrewItem };
