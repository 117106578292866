import React from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core';

import { BottomNavigation } from '../../material-ui/BottomNavigation';
import { BottomNavigationAction } from '../../material-ui/BottomNavigationAction';
import { Document, DownloadAll, Library, Limitation } from '../../../icons';
import { useStyles } from './useStyles';
import { downloadAllDocuments } from '../../../actions/documents';
import { downloadAllDefects } from '../../../actions/defects';
import { downloadAllLibraryItems } from '../../../actions/library';

const showTitle = (name, count) => (
    <span>
        {name} <span style={{ color: '#ff0000' }}>{count > 0 ? count : ''}</span>
    </span>
);

const NotificationNavigation = (props) => {
    const classes = useStyles();
    const {
        documentsWithWarning = [],
        defectsWithWarning = [],
        libraryWithWarning = [],
        shortView,
        selectedIndex,
        onChangeSelected,
    } = props;
    const dispatch = useDispatch();
    const {
        palette: { primary },
    } = useTheme();
    const buttonStyle = React.useMemo(
        () => ({
            ...(shortView && { flexBasis: '50%' }),
        }),
        [shortView]
    );

    const handleDownloadData = React.useCallback(() => {
        if (selectedIndex === 0) {
            return dispatch(downloadAllDocuments(documentsWithWarning.map((item) => item.id)));
        }

        if (selectedIndex === 1) {
            return dispatch(downloadAllDefects(defectsWithWarning.map((item) => item.id)));
        }

        if (selectedIndex === 2) {
            return dispatch(downloadAllLibraryItems(libraryWithWarning.map((item) => item.id)));
        }
    }, [selectedIndex, documentsWithWarning, defectsWithWarning, libraryWithWarning]);

    const handleChange = React.useCallback(
        (event, value) => {
            if (value === 3) {
                handleDownloadData();
            } else {
                onChangeSelected(value);
            }
        },
        [handleDownloadData]
    );

    const isButtonDisabled = React.useMemo(() => {
        if (selectedIndex === 0) {
            return documentsWithWarning.length === 0;
        }

        if (selectedIndex === 1) {
            return defectsWithWarning.length === 0;
        }

        if (selectedIndex === 2) {
            return libraryWithWarning.length === 0;
        }
    }, [selectedIndex, documentsWithWarning, defectsWithWarning, libraryWithWarning]);

    return (
        <BottomNavigation
            value={selectedIndex}
            className={classes.wrapper}
            style={shortView ? { height: 'auto' } : {}}
            showLabels={true}
            onChange={handleChange}
        >
            <BottomNavigationAction
                label={showTitle('Documents', documentsWithWarning.length)}
                style={buttonStyle}
                icon={<Document width="24" height="24" color={selectedIndex === 0 && primary.main} />}
            />
            <BottomNavigationAction
                label={showTitle('Limitations', defectsWithWarning.length)}
                style={buttonStyle}
                icon={<Limitation width="24" height="24" color={selectedIndex === 1 && primary.main} />}
            />
            <BottomNavigationAction
                label={showTitle('Library', libraryWithWarning.length)}
                style={buttonStyle}
                icon={<Library width="24" height="24" color={selectedIndex === 2 && primary.main} />}
            />
            <BottomNavigationAction
                style={{
                    flexGrow: 0,
                    ...buttonStyle,
                    width: '80px',
                }}
                disabled={isButtonDisabled}
                tooltip="Download all"
                icon={<DownloadAll width="24" height="24" />}
            />
        </BottomNavigation>
    );
};

const NotificationNavigationMemoized = React.memo(NotificationNavigation);

export { NotificationNavigationMemoized as NotificationNavigation };
