import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import { IconButton } from '../../../components/material-ui/IconButton';
import { MoreVertIconContrast } from '../../../icons/MoreVertIconContrast';
import { clearSendedData } from '../../../actions/sended-data';
import { List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction } from '../../../components/material-ui/List';
import { Checkbox } from '../../../components/material-ui/Checkbox';
import { Menu, MenuItem } from '../../../components/material-ui/Menu';
import { useHideModal } from '../../../actions/modal';
import { Collapse } from '../../../components/material-ui/Collapse';
import { useStyles } from './useStyles';
import { useAnchorEl } from '../../../components/hooks/useAnchorEl';
import { getAllTimeErrorsAsString } from '../../../service/NavPointsUtils';

const CHRONOLOGICAL_TIME_ERROR = 'The chronological sequence of operations is broken. Check fields: ';

const SendedDataItem = (props) => {
    const { id, hasChanges, warnings, timeWarnings, isCheckListNotCompleted, route, flightNum, pln, etd, checked, onCheck } = props;
    const hideModal = useHideModal();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [open, changeOpen] = React.useState(true);
    const [anchorEl, handleOpenMenu, handleClose] = useAnchorEl();
    const isWarningExist = warnings.length > 0;
    const allTimesWarning = React.useMemo(getAllTimeErrorsAsString(timeWarnings), [timeWarnings]);
    const timesWarningMessage = allTimesWarning ? `${CHRONOLOGICAL_TIME_ERROR}${allTimesWarning}` : '';
    const isTimeWarningExist = Boolean(timesWarningMessage);

    const handleClearSendedData = React.useCallback(() => {
        dispatch(clearSendedData(id));
        hideModal();
    }, []);

    const handleCheck = React.useCallback(() => onCheck(id, !checked), [id, checked]);

    const handleToggleOpen = React.useCallback(() => {
        if (isWarningExist || isTimeWarningExist || isCheckListNotCompleted) {
            changeOpen((prevState) => !prevState);
        } else if (hasChanges) {
            handleCheck();
        }
    }, [isWarningExist, isTimeWarningExist, isCheckListNotCompleted, handleCheck, hasChanges]);

    return (
        <>
            <ListItem key={id} onClick={handleToggleOpen} button={true} className={classes.item}>
                <ListItemIcon className={classes.icon}>
                    <Checkbox
                        edge="start"
                        checked={checked}
                        color="primary"
                        tabIndex={-1}
                        onChange={handleCheck}
                        disableRipple={true}
                        disabled={!hasChanges || isWarningExist || isTimeWarningExist}
                    />
                </ListItemIcon>
                <ListItemText
                    className={classes.text}
                    primary={
                        <span
                            className={classnames((isWarningExist || isTimeWarningExist || isCheckListNotCompleted) && classes.warningText)}
                        >{`${flightNum} ${route}`}</span>
                    }
                    classes={{ primary: classes.primaryText }}
                    secondary={<span>{`${pln}  ${etd && moment(etd).format('MM-DD-YYYY HH:mm')}`}</span>}
                />
                <ListItemSecondaryAction className={classes.action}>
                    <IconButton onClick={handleOpenMenu}>
                        <MoreVertIconContrast />
                    </IconButton>
                </ListItemSecondaryAction>
                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem onClick={handleClearSendedData}>Reset data for leg</MenuItem>
                </Menu>
            </ListItem>
            {isWarningExist && (
                <Collapse in={open} timeout="auto" unmountOnExit={true}>
                    <List component="div" disablePadding={true}>
                        <ListItem key={0}>
                            <ListItemText
                                primary="Please enter required fields: "
                                secondary={warnings.join(', ')}
                                classes={{ secondary: classes.secondaryText }}
                            />
                        </ListItem>
                    </List>
                </Collapse>
            )}
            {isTimeWarningExist && (
                <Collapse in={open} timeout="auto" unmountOnExit={true}>
                    <ListItem key={1}>
                        <ListItemText primary={timesWarningMessage} classes={{ secondary: classes.secondaryText }} />
                    </ListItem>
                </Collapse>
            )}
            {isCheckListNotCompleted && (
                <Collapse in={open} timeout="auto" unmountOnExit={true}>
                    <ListItem key={1}>
                        <ListItemText primary="Please complete check list" classes={{ secondary: classes.secondaryText }} />
                    </ListItem>
                </Collapse>
            )}
        </>
    );
};

const SendedDataItemMemoized = React.memo(SendedDataItem);

export { SendedDataItemMemoized as SendedDataItem };
