import React from 'react';

const Aisle = ({ seat }) => {
    return (
        <article key={`aisle_${seat.id}`} className="passenger-grid__aisle">
            <span>{seat.row}</span>
        </article>
    );
};

export { Aisle };
