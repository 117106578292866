import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DialogControls } from '../../../../components/DialogControls/DialogControls';
import { toggleGps } from '../../../../actions/gps';
import { getCoordinates } from '../../../../service/NavPointsUtils';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../../../components/material-ui/Dialog';
import { useMobileWidth } from '../../../../components/hooks/useMobileWidth';
import { useStyles } from './useStyles';
import { getFieldLabel, PointFields } from '../../tools';
import { TextField } from '../../../../components/material-ui/TextField';
import { FormControl } from '../../../../components/material-ui/FormControl';
import { FormControlLabel } from '../../../../components/material-ui/FormControlLabel';
import { Switch } from '../../../../components/material-ui/Switch';

const getValue = (id, position) => {
    // TODO переделать state.gps и хранить там lat и long вместо полных наименований.
    let value = +(position || {})[id === PointFields.LAT ? 'latitude' : 'longitude'];
    if (!value || isNaN(value)) {
        return;
    }

    return getCoordinates(value, id);
};

const GpsPanel = React.forwardRef(({ onClose, onOk, open }, ref) => {
    const dispatch = useDispatch();
    const { isMobilePortrait } = useMobileWidth();
    const classes = useStyles();
    const { isGpsOn, position } = useSelector((state) => state.gps);
    const lat = getValue(PointFields.LAT, position);
    const long = getValue(PointFields.LONG, position);

    const handleToggleGps = React.useCallback(() => {
        dispatch(toggleGps());
    }, []);

    const handleOk = () =>
        onOk({
            lat,
            long,
        });

    return (
        <Dialog ref={ref} open={open} fullScreen={isMobilePortrait} classes={{ paper: classes.paper }}>
            <DialogTitle className={classes.title}>Current location</DialogTitle>
            <DialogContent className={classes.content}>
                <FormControlLabel
                    className={classes.toggler}
                    control={<Switch onChange={handleToggleGps} color="primary" {...{ checked: isGpsOn }} />}
                    label="Enable location"
                    labelPlacement="start"
                />
                <FormControl className={classes.formControl}>
                    <TextField
                        name={PointFields.LAT}
                        label={getFieldLabel(PointFields.LAT)}
                        placeholder={PointFields.LAT.toUpperCase()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth={true}
                        value={lat || '-'}
                        inputProps={{ className: classes.input }}
                        disabled={true}
                    />
                </FormControl>
                <FormControl className={classes.formControl}>
                    <TextField
                        name={PointFields.LONG}
                        label={getFieldLabel(PointFields.LONG)}
                        placeholder={PointFields.LONG.toUpperCase()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth={true}
                        value={long || '-'}
                        inputProps={{ className: classes.input }}
                        disabled={true}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <DialogControls
                    actions={[
                        { label: 'CANCEL', onClick: onClose },
                        { label: 'OK', color: 'primary', type: 'submit', onClick: handleOk, disabled: !lat || !long },
                    ]}
                />
            </DialogActions>
        </Dialog>
    );
});

export { GpsPanel };
