import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';

import { downloadCheckList, toggleCheckedCheckListItem } from '../../../actions/checkList';
import { NotificationTypes, pushNotification } from '../../../actions/notifications';
import { getCurrentCheckLists } from '../../../selectors/checkList';
import { getCrewTypeSelector, useCurrentLeg } from '../../../selectors/screen';

export const useCheckListModalContent = () => {
    const crewType = useSelector(getCrewTypeSelector);
    const checkInfoShowed = useSelector((state) => get(state, 'checkLists.checkInfoShowed', false));
    const checkLists = useSelector(getCurrentCheckLists) || {};
    const currentLeg = useCurrentLeg();

    const [checkListId, changeCheckListId] = React.useState(Object.keys(checkLists).length > 0 ? Object.keys(checkLists)[0] : null);

    const dispatch = useDispatch();

    const handleToggleCheckItem = React.useCallback((itemId) => dispatch(toggleCheckedCheckListItem(checkListId, itemId)), [checkListId]);

    const handleDownloadCheckList = React.useCallback(() => {
        dispatch(downloadCheckList(currentLeg, crewType));
    }, [currentLeg, crewType]);

    useEffect(() => {
        if (checkInfoShowed) {
            return;
        }

        dispatch(
            pushNotification({
                variant: NotificationTypes.INFO,
                message: 'Swipe the item to change the status.',
                details: '',
            })
        );
    }, [checkInfoShowed]);

    const handleCheckListChange = React.useCallback((event, newValue) => changeCheckListId(newValue), []);

    return { checkListId, checkLists, handleDownloadCheckList, handleToggleCheckItem, handleCheckListChange };
};
