import React from 'react';
import { useSelector } from 'react-redux';

import { Panel } from '../Panel/Panel';
import { useStyles } from './useStyles';
import { useMobileWidth } from '../hooks/useMobileWidth';
import { getPassengerList } from '../../selectors/passengers';
import { PassengerTableView } from './PassengerTableView/PassengerTableView';
import { PassengerGridView } from './PassengerGridView/PassengerGridView';

import './PassengerPage.scss';

export const PassengerPage = React.memo(() => {
    const [tableView, toggleTableView] = React.useState(true);
    const classes = useStyles();
    const passengerList = useSelector(getPassengerList);
    const handleChangeView = () => toggleTableView((prevState) => !prevState);
    const { isMobilePortrait } = useMobileWidth();

    return (
        <div className={classes.wrapper}>
            <Panel
                title="Passenger list"
                className={classes.panel}
                toggle={{
                    label: 'Table view',
                    defaultToggled: tableView,
                    onToggle: handleChangeView,
                }}
            >
                {tableView ? (
                    <PassengerTableView passengerList={passengerList} isMobile={isMobilePortrait} />
                ) : (
                    <PassengerGridView isMobile={isMobilePortrait} />
                )}
            </Panel>
        </div>
    );
});
