import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { Panel } from '../../Panel/Panel.jsx';
import { getLabelValue } from './tools';
import { useStyles } from './useStyles';
import { Disable, InputStyle, TotalPanelField } from './TotalPanelField/TotalPanelField';
import { checkRequired, getDefaultValue } from '../tools';
import { editOfpField } from '../../../actions/sended-data';
import { toggleTotalEditable } from '../../../actions/take-off';
import { getCurrentSendedLeg } from '../../../selectors/sended-data';
import { useConvert } from '../../hooks/useMeasureConverter';

const fields = [
    {
        name: 'dryOperatingWeight',
        label: 'DOW',
        type: 'number',
        validator: { type: 'int', min: 0, max: 5000000 },
        disable: Disable.RELATIVE,
        style: InputStyle.SIMPLE,
    },
    { name: 'payLoadEstimate', label: 'EPYLD', type: 'number', disable: Disable.TRUE, style: InputStyle.NONE },
    {
        name: 'maxLoading',
        label: 'APYLD',
        type: 'number',
        validator: { type: 'int', min: 0, max: 5000000 },
        disable: Disable.RELATIVE,
        style: InputStyle.SIMPLE,
    },
    {
        name: 'payLoadReal',
        label: 'TPYLD',
        type: 'number',
        validator: { type: 'int', min: 0, max: 5000000 },
        disable: Disable.RELATIVE,
        style: InputStyle.SPECIAL,
    },
    {
        name: 'underload',
        label: 'UNDERLOAD',
        disable: Disable.TRUE,
        style: InputStyle.SPECIAL,
    },
    {
        name: 'extraCrewWeight',
        label: 'Extra crew',
        type: 'number',
        validator: { type: 'int', min: 0, max: 5000000 },
        disable: Disable.RELATIVE,
        style: InputStyle.SIMPLE,
    },
    { name: 'zeroFuelWeightEstimate', label: 'EZFW', type: 'number', disable: Disable.TRUE, style: InputStyle.NONE },
    {
        name: 'zeroFuelWeightMax',
        label: 'MZFW',
        type: 'number',
        validator: { type: 'int', min: 0, max: 5000000 },
        disable: Disable.RELATIVE,
        style: InputStyle.SIMPLE,
    },
    {
        name: 'zeroFuelWeightReal',
        label: 'ZFW',
        type: 'number',
        validator: { type: 'int', min: 0, max: 5000000 },
        disable: Disable.RELATIVE,
        style: InputStyle.SPECIAL,
    },
    { name: 'empty2', label: '', disable: Disable.TRUE, style: InputStyle.INVISIBLE },
    {
        name: 'takeOffFuel',
        label: 'TOF',
        type: 'number',
        validator: { type: 'int', min: 0, max: 5000000 },
        disable: Disable.TRUE,
        style: InputStyle.NONE,
    },
    { name: 'takeOffWeightEstimate', label: 'ETOW', type: 'number', disable: Disable.TRUE, style: InputStyle.NONE },
    {
        name: 'takeOffWeightMax',
        label: 'MTOW',
        type: 'number',
        validator: { type: 'int', min: 0, max: 5000000 },
        disable: Disable.RELATIVE,
        style: InputStyle.SIMPLE,
    },
    {
        name: 'takeOffWeightReal',
        label: 'TOW',
        type: 'number',
        validator: { type: 'int', min: 0, max: 5000000 },
        disable: Disable.RELATIVE,
        style: InputStyle.SPECIAL,
    },
    {
        name: 'allowedTakeOffWeight',
        label: 'ATOW',
        disable: Disable.TRUE,
        style: InputStyle.SPECIAL,
    },
    {
        name: 'tripFuel',
        label: 'Trip fuel',
        type: 'number',
        validator: { type: 'int', min: 0, max: 5000000 },
        disable: Disable.FALSE,
        style: InputStyle.SIMPLE,
    },
    { name: 'landingWeightEstimate', label: 'ELW', type: 'number', disable: Disable.TRUE, style: InputStyle.NONE },
    {
        name: 'landingWeightMax',
        label: 'MLW',
        type: 'number',
        validator: { type: 'int', min: 0, max: 5000000 },
        disable: Disable.RELATIVE,
        style: InputStyle.SIMPLE,
    },
    {
        name: 'landingWeightReal',
        label: 'LW',
        type: 'number',
        validator: { type: 'int', min: 0, max: 5000000 },
        disable: Disable.RELATIVE,
        style: InputStyle.SPECIAL,
    },
    { name: 'empty3', label: '', disable: Disable.TRUE, style: InputStyle.INVISIBLE },
];

const TotalPanel = ({ declaration }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const data = useSelector((state) => {
        const currentLeg = getCurrentSendedLeg(state);
        const takeOff = get(currentLeg, 'takeOff', {});

        return {
            remainTakeoff: takeOff.remainTakeoff,
            refuelingTakeoff: takeOff.refuelingTakeoff,
            totalOnBoard: takeOff.totalOnBoard,
            onBoardFuel: currentLeg.onBoardFuel,
            expenseLandTakeoff: takeOff.expenseLandTakeoff,
            expenseBeforeTakeoff: takeOff.expenseBeforeTakeoff,
            amcTakeoff: takeOff.amcTakeoff,
            dryOperatingWeight: currentLeg.dryOperatingWeight,
            payLoadEstimate: currentLeg.payLoadEstimate,
            payLoadReal: currentLeg.payLoadReal,
            zeroFuelWeightEstimate: currentLeg.zeroFuelWeightEstimate,
            zeroFuelWeightReal: currentLeg.zeroFuelWeightReal,
            zeroFuelWeightMax: currentLeg.zeroFuelWeightMax,
            takeOffWeightEstimate: currentLeg.takeOffWeightEstimate,
            takeOffWeightReal: currentLeg.takeOffWeightReal,
            takeOffWeightMax: currentLeg.takeOffWeightMax,
            extraCrewWeight: currentLeg.extraCrewWeight,
            landingWeightEstimate: currentLeg.landingWeightEstimate,
            landingWeightReal: currentLeg.landingWeightReal,
            landingWeightMax: currentLeg.landingWeightMax,
            isTotalEditable: currentLeg.isTotalEditable,
            tripFuel: currentLeg.tripFuel,

            takeoffWeight: currentLeg.takeoffWeight,
            payLoad: currentLeg.payLoad,
            zeroFuelWeight: currentLeg.zeroFuelWeight,
            maxLoading: currentLeg.maxLoading,
        };
    }, shallowEqual);

    const {
        isTotalEditable,
        takeOffWeightMax = 0,
        landingWeightMax = 0,
        zeroFuelWeightMax = 0,
        payLoadReal = 0,
        maxLoading = 0,
        zeroFuelWeightReal,
        takeOffWeightReal,
        landingWeightReal,
    } = data;
    const convertedValidatorFields = {
        dryOperatingWeight: { type: 'int', min: 0, max: useConvert(5000000) },
        maxLoading: { type: 'int', min: 0, max: useConvert(5000000) },
        payLoadReal: { type: 'int', min: 0, max: useConvert(5000000) },
        extraCrewWeight: { type: 'int', min: 0, max: useConvert(5000000) },
        zeroFuelWeightMax: { type: 'int', min: 0, max: useConvert(5000000) },
        zeroFuelWeightReal: { type: 'int', min: 0, max: useConvert(5000000) },
        takeOffFuel: { type: 'int', min: 0, max: useConvert(5000000) },
        takeOffWeightMax: { type: 'int', min: 0, max: useConvert(5000000) },
        takeOffWeightReal: { type: 'int', min: 0, max: useConvert(5000000) },
        tripFuel: { type: 'int', min: 0, max: useConvert(5000000) },
        landingWeightMax: { type: 'int', min: 0, max: useConvert(5000000) },
        landingWeightReal: { type: 'int', min: 0, max: useConvert(5000000) },
    };
    const updateFields = React.useCallback(
        (validatorFields) => {
            return validatorFields.map((field) => {
                if (convertedValidatorFields[field.name]) {
                    field.validator = convertedValidatorFields[field.name];
                }

                return field;
            });
        },
        [convertedValidatorFields]
    );
    const updatedValidatorFields = updateFields(fields);

    const handleEditTotalField = React.useCallback((name, value) => {
        dispatch(editOfpField(name, value));
    }, []);
    const handleToggleTotalEditable = React.useCallback(() => {
        dispatch(toggleTotalEditable());
    }, []);

    const getValues = React.useCallback(
        (name) => {
            if (name === 'payLoadReal') {
                return [payLoadReal, maxLoading];
            }

            if (name === 'zeroFuelWeightReal') {
                return [zeroFuelWeightReal, zeroFuelWeightMax];
            }

            if (name === 'takeOffWeightReal') {
                return [takeOffWeightReal, takeOffWeightMax];
            }

            if (name === 'landingWeightReal') {
                return [landingWeightReal, landingWeightMax];
            }

            return [];
        },
        [zeroFuelWeightReal, takeOffWeightReal, landingWeightReal]
    );

    return (
        <Panel
            title="Total info"
            className={classes.panel}
            toggle={{
                label: 'Manual edit',
                defaultToggled: isTotalEditable,
                onToggle: handleToggleTotalEditable,
                isControlled: true,
            }}
        >
            <div className={classes.wrapper}>
                {updatedValidatorFields &&
                    updatedValidatorFields.map(({ name, label, type, disable, style, validator }) => {
                        const [value1, value2] = getValues(name);
                        const value = getDefaultValue(name, data);

                        return (
                            <TotalPanelField
                                key={name}
                                {...{ name, label, disable, type, style, value1, value2, isTotalEditable }}
                                validator={validator}
                                defaultValue={value}
                                label={getLabelValue(name, label, data)}
                                onEdit={handleEditTotalField}
                                required={checkRequired(declaration, classes, name)}
                            />
                        );
                    })}
            </div>
        </Panel>
    );
};

const TotalPanelMemoized = React.memo(TotalPanel);

export { TotalPanelMemoized as TotalPanel };
