import React from 'react';
import classnames from 'classnames';

import { getUnderlineType, UnderlineType } from '../tools';
import { useStyles } from './useStyles';
import { validateState, VALIDATION_EMPTY_FIELD } from '../../../hooks/useForm/validate';
import { TextField } from '../../../material-ui/TextField';
import { checkExistValue } from '../../../../service/utils';

export const Disable = {
    FALSE: 0,
    TRUE: 1,
    RELATIVE: 2,
};

export const InputStyle = {
    NONE: 0,
    SIMPLE: 1,
    SPECIAL: 2,
    INVISIBLE: 3,
};

const TotalPanelField = (props) => {
    const classes = useStyles();
    const {
        name,
        onEdit,
        style,
        value1,
        value2,
        isTotalEditable,
        disable,
        required = true,
        validator,
        defaultValue,
        helperText,
        ...restProps
    } = props;
    const [errorText, changeError] = React.useState((validateState(validator, defaultValue, required) || {}).errorText);
    const [value, changeValue] = React.useState(defaultValue);

    const isDisable = disable === Disable.TRUE || (disable === Disable.RELATIVE && !isTotalEditable);
    const className = classnames(classes.field, style === InputStyle.INVISIBLE ? classes.hidden : '');
    let customHelperText = helperText === VALIDATION_EMPTY_FIELD ? '' : helperText;

    const InputLabelProps = React.useMemo(
        () => ({
            shrink: true,
            ...props.InputLabelProps,
        }),
        []
    );

    React.useEffect(() => {
        if (checkExistValue(defaultValue)) {
            changeValue(defaultValue);
            changeError((validateState(validator, defaultValue, required) || {}).errorText);
        }

        if (helperText) {
            changeError(helperText);
        }
    }, [defaultValue, helperText]);

    const handleEdit = React.useCallback(
        (evt) => {
            const fieldName = evt.target.name;
            const fieldValue = evt.currentTarget.value === '' ? undefined : +evt.currentTarget.value;
            const validateRes = validateState({ ...validator, isRequired: required }, fieldValue);

            if (validateRes === null) {
                onEdit(fieldName, fieldValue);
            } else {
                changeError(validateRes.errorText);
            }
        },
        [validator, required]
    );

    const handleChange = React.useCallback((event) => {
        changeValue(event.target.value);
    }, []);

    const placeholder = React.useMemo(() => {
        if (name === 'underload') {
            return '0';
        }

        return props.label;
    }, [name, props.label]);

    const fieldStyle = React.useMemo(() => {
        if (style === InputStyle.SIMPLE) {
            return isTotalEditable ? classes.editedField : classes.disabledTextFieldStyle;
        }

        const wellDone = isDisable ? classes.wellDone : classes.wellDoneEdited;
        const badWork = isDisable ? classes.badWork : classes.badWorkEdited;
        let inputClassName = classnames(defaultClassName, badWork);
        const defaultClassName = isTotalEditable ? classes.editedField : classes.disabledTextFieldStyle;
        const underlineType = getUnderlineType(value1, value2);

        if (!value1 || !value2 || value1 - value2 === 0) {
            inputClassName = defaultClassName;
        } else if (underlineType === UnderlineType.WELL_DONE) {
            inputClassName = classnames(defaultClassName, wellDone);
        }

        return style === InputStyle.SPECIAL &&
            ['payLoadReal', 'zeroFuelWeightReal', 'takeOffWeightReal', 'landingWeightReal'].indexOf(name) !== -1
            ? inputClassName
            : '';
    }, [style, name, isDisable, value1, value2, isTotalEditable]);

    const handleInputFocus = React.useCallback((e) => {
        changeError('');

        if (props.onFocus) {
            props.onFocus(e);
        }
    }, []);

    return (
        <TextField
            key={name}
            {...restProps}
            {...{ className, placeholder, name, InputLabelProps }}
            value={value}
            disabled={isDisable}
            helperText={customHelperText}
            error={Boolean(errorText)}
            margin="normal"
            onBlur={handleEdit}
            onChange={handleChange}
            onFocus={handleInputFocus}
            inputProps={{ className: fieldStyle }}
            FormHelperTextProps={{
                className: classes.helperText,
            }}
        />
    );
};

const TotalPanelFieldMemoized = React.memo(TotalPanelField);

export { TotalPanelFieldMemoized as TotalPanelField };
