import { LOGOUT } from '../actions/screen';

const initialState = {};

// TODO remove as unused
export default (state = initialState, action) => {
    switch (action.type) {
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};
