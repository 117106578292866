import React from 'react';
import classnames from 'classnames';
import { isUndefined } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { List } from '../material-ui/List';
import { ListItem, ListItemIcon, ListItemText } from '../material-ui/List';
import { FlightTask, Report, Send, Download, Library, Summary, Lens } from '../../icons';
import { useStyles } from './useStyles';
import { showModal, TYPES } from '../../actions/modal';
import { getCurrentTaskNumberSelector, getFlights } from '../../selectors/screen';
import { getActualData, preloadSignatureUrl } from '../../actions/tasks';
import { SidebarFlights } from './SidebarFlights/SidebarFlights';
import { SidebarItem } from './SidebarItem/SidebarItem';

import './Sidebar.scss';

function SidebarContent(props) {
    const {
        onClickSummaryTask,
        onClickFlightTask,
        onClickSafetyReports,
        onClickLibrary,
        onClickFlight,
        onCloseSidebar,
        onToggleNavigationPanel,
    } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const flights = useSelector(getFlights);
    const currentTask = useSelector(getCurrentTaskNumberSelector);
    const canDownloadTaskData = !isUndefined(currentTask);

    const handleShowSendDataModal = React.useCallback(() => {
        dispatch(showModal(TYPES.SEND_DATA, { title: 'Send data' }));
    }, []);

    const handleGetActualData = React.useCallback(() => {
        dispatch(getActualData());
        onCloseSidebar();
    }, []);

    const handleClose = React.useCallback(() => {
        onToggleNavigationPanel(false);
    }, []);

    const handleShowFlightTask = React.useCallback(() => {
        dispatch(preloadSignatureUrl(currentTask));
        dispatch(showModal(TYPES.FLIGHT_TASK, { title: 'Task title', id: currentTask }));
        handleClose();
    }, [currentTask]);

    return (
        <>
            <div className={classes.toolbar} />
            <List className="sidebar-menu">
                <SidebarItem
                    key={1}
                    onClick={onClickFlightTask}
                    iconClassName={classes.listIcon}
                    primary={'Flight Tasks'}
                    className={classnames('sidebar-menu__item', classes.listItemWithBorderBottom)}
                >
                    <FlightTask width="24" height="24" className={classes.icon} />
                </SidebarItem>
                {canDownloadTaskData && (
                    <SidebarItem
                        key={2}
                        onClick={handleShowFlightTask}
                        iconClassName={classes.listIcon}
                        primary={'Show task title'}
                        className={classnames('sidebar-menu__item', classes.simple)}
                    >
                        <Lens width="24" height="24" className={classes.icon} />
                    </SidebarItem>
                )}
                <SidebarFlights onCloseSidebar={onCloseSidebar} onClickFlight={onClickFlight} />
                {canDownloadTaskData && (
                    <ListItem
                        key={3}
                        onClick={handleGetActualData}
                        className={classnames('sidebar-menu__item', classes.listItemWithBorder)}
                        button={true}
                    >
                        <ListItemIcon className={classes.listIcon}>
                            <Download width="24" height="24" className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText primary="Get data" className={classes.listItemText} />
                    </ListItem>
                )}
                {flights && (
                    <ListItem
                        key={4}
                        onClick={onClickSummaryTask}
                        className={classnames('sidebar-menu__item', classes.simple)}
                        button={true}
                    >
                        <ListItemIcon className={classes.listIcon}>
                            <Summary width="24" height="24" className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText primary="Summary info" className={classes.listItemText} />
                    </ListItem>
                )}
                {canDownloadTaskData && (
                    <ListItem
                        key={5}
                        onClick={handleShowSendDataModal}
                        className={classnames('sidebar-menu__item', classes.simple)}
                        button={true}
                    >
                        <ListItemIcon className={classes.listIcon}>
                            <Send width="24" height="24" className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText primary="Send data" className={classes.listItemText} />
                    </ListItem>
                )}
                <ListItem
                    key={6}
                    onClick={onClickSafetyReports}
                    button={true}
                    className={classnames('sidebar-menu__item', classes.listItemWithBorder)}
                >
                    <ListItemIcon className={classes.listIcon}>
                        <Report width="24" height="24" className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary="Reports" className={classes.listItemText} />
                </ListItem>
                <ListItem
                    key={7}
                    onClick={onClickLibrary}
                    className={classnames('sidebar-menu__item', classes.innerDivStyle)}
                    button={true}
                >
                    <ListItemIcon className={classes.listIcon}>
                        <Library width="24" height="24" className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary="Library" className={classes.listItemText} />
                </ListItem>
            </List>
        </>
    );
}

const SidebarContentMemoized = React.memo(SidebarContent);

export { SidebarContentMemoized as SidebarContent };
