import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Add, Remove, CheckCircle, Search, KeyboardArrowDown, KeyboardArrowUp, Close } from '@material-ui/icons';
import classnames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import { debounce } from 'lodash';

import { DialogControls } from '../../components/DialogControls/DialogControls';
import { useHideModal } from '../../actions/modal';
import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import { getAttachments as getAttachmentsTelegram, showAttachment as showAttachmentTelegram, signTelegram } from '../../actions/telegrams';
import { getAttachments as getAttachmentsTelex, showAttachment as showAttachmentTelex, signTelex } from '../../actions/telex';
import { SignaturePopup } from '../../components/SignaturePopup/SignaturePopup';
import Document from '../../icons/image/Document';
import { resignOfp } from '../../actions/startInfo';
import { useStyles } from './useStyles';
import { Typography } from '../../components/material-ui/Typography';
import { Box } from '../../components/material-ui/Box';
import { Dialog, DialogContent, DialogTitle } from '../../components/material-ui/Dialog';
import { AppBar } from '../../components/material-ui/AppBar';
import { IconButton } from '../../components/material-ui/IconButton';
import { TransitionUp } from '../../components/material-ui/Slide';
import { Toolbar } from '../../components/material-ui/Toolbar';
import { Popover } from '../../components/material-ui/Popover';
import { Popper } from '../../components/material-ui/Popper';
import { Paper } from '../../components/material-ui/Paper';
import { TextField } from '../../components/material-ui/TextField';
import { Chip } from '../../components/material-ui/Chip';
import { useAnchorEl } from '../../components/hooks/useAnchorEl';
import { decreaseTelegramFontSize, increaseTelegramFontSize } from '../../actions/screen';
import { getFontSize, useDarkTheme } from '../../selectors/screen';

const TelegramViewer = React.forwardRef((props, ref) => {
    const fontSize = useSelector(getFontSize);
    const {
        isPreviewEditable,
        message,
        title,
        attachments,
        signComment,
        isCurrentUserCaptain,
        ofpId,
        signaturePreview,
        signatureName,
        isSupportTelex,
    } = props;
    const isDarkTheme = useDarkTheme();
    const classes = useStyles({ isDarkTheme });
    const hideModal = useHideModal();
    const [anchorEl, handleOpenMenu, handleClose] = useAnchorEl();
    const [anchorElSearch, searchOpen, searchClose] = useAnchorEl();
    const dispatch = useDispatch();
    const [preview, setPreview] = React.useState(signaturePreview);
    const [signatureOpen, setSignatureOpen] = React.useState(false);

    const [isSearchOpen, setIsSearchOpen] = React.useState(false);
    const [caseIgnore, setCaseIgnore] = React.useState(true);
    const [currentMark, setCurrentMark] = React.useState(0);
    const [searchValue, changeSearchValue] = React.useState('');
    const searchString = searchValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regExpFlags = caseIgnore ? 'gi' : 'g';
    let matchesNumber = 0;
    const highlightedMessage =
        searchValue.length > 1
            ? message.replace(new RegExp(searchString, regExpFlags), (str) => {
                  matchesNumber++;
                  const style = ` style="scroll-margin-top: 75px;${matchesNumber === currentMark ? ' background-color: orange;' : ''}"`;

                  return `<mark id="search-mark-${matchesNumber}"${style}>${str}</mark>`;
              })
            : message;
    const parsedMessage = isSearchOpen ? ReactHtmlParser(highlightedMessage.replace(/(?:\r\n|\r|\n)/g, '<br />')) : message;

    const sign = isSupportTelex ? signTelex : signTelegram;
    const getAttachments = isSupportTelex ? getAttachmentsTelex : getAttachmentsTelegram;
    const showAttachment = isSupportTelex ? showAttachmentTelex : showAttachmentTelegram;

    const handleSign = React.useCallback(
        (signature) => {
            dispatch(sign(signature, signatureName)).then((url) => setPreview(url));
        },
        [signatureName]
    );

    const items = getAttachments(attachments);
    const handleSelectOfp = React.useCallback(
        (signature, signComment) => {
            dispatch(resignOfp(ofpId, signature, signComment));
            hideModal();
        },
        [ofpId]
    );

    const handleSignatureShow = React.useCallback(() => {
        setSignatureOpen(true);
    }, []);

    const handleSignatureHide = React.useCallback(() => {
        setSignatureOpen(false);
    }, []);

    const handleUpSize = React.useCallback(() => {
        dispatch(increaseTelegramFontSize());
    }, []);
    const handleDownSize = React.useCallback(() => {
        dispatch(decreaseTelegramFontSize());
    }, []);
    const handleOpenSearch = React.useCallback((evt) => {
        setIsSearchOpen(true);
        searchOpen(evt);
    }, []);
    const handleCloseSearch = React.useCallback((evt) => {
        setIsSearchOpen(false);
        searchClose(evt);
    }, []);
    const handleCaseIgnoreChange = React.useCallback(() => {
        setCaseIgnore((prevState) => !prevState);
    }, []);
    const handleIncCurrentMark = React.useCallback(() => {
        setCurrentMark((prevState) => (prevState < matchesNumber ? ++prevState : 1));
    }, [matchesNumber]);
    const handleDecCurrentMark = React.useCallback(() => {
        setCurrentMark((prevState) => (prevState > 1 ? --prevState : matchesNumber));
    }, [matchesNumber]);

    const handleSearchChange = (evt) => {
        changeSearchValue(evt.target.value);
    };
    const debouncedSearch = React.useMemo(() => {
        return debounce(handleSearchChange, 300);
    }, []);

    React.useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    });

    React.useEffect(() => {
        const anchor = document.getElementById(`search-mark-${currentMark}`);
        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth' });
        }
    }, [currentMark, searchValue, caseIgnore, isSearchOpen]);

    React.useEffect(() => {
        setCurrentMark(matchesNumber === 0 ? 0 : 1);
    }, [matchesNumber]);

    const actionList = [];

    if (isPreviewEditable) {
        actionList.push({
            label: 'Sign',
            primary: false,
            onClick: handleSignatureShow,
        });
    }

    actionList.push({
        label: 'Close',
        primary: false,
        onClick: hideModal,
    });

    return (
        <Dialog fullScreen={true} open={true} onClose={hideModal} scroll="paper" TransitionComponent={TransitionUp}>
            <DialogTitle>
                <AppBar>
                    <Toolbar className={classes.toolbar}>
                        <Typography variant="h6" className={classes.title} color="inherit">
                            {title}
                        </Typography>
                        <IconButton color="inherit" onClick={handleDownSize}>
                            <Remove />
                        </IconButton>
                        <IconButton color="inherit" onClick={handleUpSize}>
                            <Add />
                        </IconButton>
                        <IconButton color="inherit" onClick={handleOpenSearch} {...(!preview && { className: classes.margin })}>
                            <Search />
                        </IconButton>
                        {Boolean(preview) && (
                            <IconButton color="inherit" onClick={handleOpenMenu} className={classes.margin}>
                                <CheckCircle />
                            </IconButton>
                        )}
                        <Popover
                            id="preview"
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            {...{ anchorEl }}
                        >
                            <img src={preview} width="320px" height="132px" title="Pilot signature" className={classes.filter} />
                        </Popover>
                        <Popper
                            id="search"
                            anchorEl={anchorElSearch}
                            open={Boolean(anchorElSearch)}
                            disablePortal={true}
                            placement="bottom-end"
                        >
                            <Paper className={classes.search} role="search">
                                <TextField
                                    className={classes.input}
                                    onChange={debouncedSearch}
                                    defaultValue={searchValue}
                                    autoFocus
                                    fullWidth={true}
                                />
                                <Typography
                                    variant="h6"
                                    className={classnames(classes.status, searchValue.length < 2 && classes.invisible)}
                                    color="inherit"
                                >
                                    {`${currentMark}/${matchesNumber}`}
                                </Typography>
                                <IconButton variant="contained" onClick={handleIncCurrentMark}>
                                    <KeyboardArrowDown />
                                </IconButton>
                                <IconButton variant="contained" onClick={handleDecCurrentMark}>
                                    <KeyboardArrowUp />
                                </IconButton>
                                <IconButton
                                    className={classnames(!caseIgnore && classes.highlighted)}
                                    variant="contained"
                                    onClick={handleCaseIgnoreChange}
                                >
                                    Aa
                                </IconButton>
                                <IconButton variant="contained" onClick={handleCloseSearch}>
                                    <Close />
                                </IconButton>
                            </Paper>
                        </Popper>
                        <DialogControls actions={actionList} />
                    </Toolbar>
                </AppBar>
            </DialogTitle>
            <DialogContent className={classes.content} ref={ref}>
                <ErrorBoundary>
                    {items.length > 0 && (
                        <div className={classes.tags}>
                            {items.map(({ id, name, disabled, extension }) => {
                                const handleAttachmentClick = () => dispatch(showAttachment(id));

                                return (
                                    <Chip
                                        key={id}
                                        variant="outlined"
                                        icon={<Document width={20} height={20} />}
                                        label={`${name}${extension}`}
                                        disabled={disabled}
                                        onClick={handleAttachmentClick}
                                    />
                                );
                            })}
                        </div>
                    )}
                    {signComment && (
                        <div className={classes.captainCommentWrapper}>
                            <Typography className={classes.captainCommentTitle}>Captain comment</Typography>
                            <Typography className={classes.captainText}>{signComment}</Typography>
                        </div>
                    )}
                    <Box fontFamily="Monospace" fontSize={fontSize} className={classes.message}>
                        {parsedMessage}
                    </Box>
                    {ofpId ? (
                        <SignaturePopup
                            visibility={signatureOpen}
                            text={'Signature popup text'}
                            title={'OFP select confirm'}
                            onSign={handleSelectOfp}
                            onClose={handleSignatureHide}
                            signComment={signComment}
                            showComment={isCurrentUserCaptain}
                            ofpId={ofpId}
                        />
                    ) : (
                        <SignaturePopup
                            visibility={signatureOpen}
                            text={'Signature telegram text'}
                            title={`Telegram (${title}) signature`}
                            onSign={handleSign}
                            onClose={handleSignatureHide}
                        />
                    )}
                </ErrorBoundary>
            </DialogContent>
        </Dialog>
    );
});

export { TelegramViewer };
