import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { Dialog, DialogContent } from '../../components/material-ui/Dialog';
import { getColumnList } from '../../selectors/declaration';
import { useHideModal } from '../../actions/modal';
import { saveCustomRouteSettings } from '../../actions/declaration';
import { MIN_ALLOWABLE_DISTANCE_TO_ACCEPT } from '../../actions/gps';
import { GpsSetting } from './GpsSetting/GpsSetting';
import { SelectionType } from './GpsSetting/GpsRouteSelectionType';
import { EnrouteSetting } from './EnrouteSetting/EnrouteSetting';
import { DateTimeSetting } from './DateTimeSetting/DateTimeSetting';
import { TimePickerType } from '../../components/CustomTimePicker/CustomTimePicker';
import { useStyles } from './useStyles';
import { MAX_GPS_DISTANCE, MIN_GPS_DISTANCE } from './constants';
import { SettingTabs } from './SettingTabs/SettingTabs';
import { SettingActions } from './SettingActions/SettingActions';

import './Setting.scss';

const getSelected = (selected, columns) => {
    return Object.keys(selected).length > 0
        ? selected
        : columns.reduce((res, column, index) => {
              if (column && column.mobile) {
                  res[index] = true;
              }

              return res;
          }, {});
};

const Setting = React.forwardRef((props, ref) => {
    const hideModal = useHideModal();
    const dispatch = useDispatch();
    const routesDeclaration = useSelector((state) => get(state, 'routesDeclaration', {}));
    const columns = useSelector(getColumnList);
    const allowedDistance = routesDeclaration.allowedDistance || MIN_ALLOWABLE_DISTANCE_TO_ACCEPT;
    const selectionType = routesDeclaration.selectionType || SelectionType.CURRENT;
    const classes = useStyles();
    const [tabIndex, changeTabIndex] = React.useState(0);

    const [gps, changeGpsState] = React.useState({
        allowedDistance,
        selectionType,
    });
    const [timePickerType, changeTimePickerType] = React.useState(routesDeclaration.timePickerType || TimePickerType.NATIVE);
    const [selected, changeSelected] = React.useState(getSelected(routesDeclaration.routesDeclaration || {}, columns));
    const selectedAll = React.useMemo(() => columns.length === Object.keys(selected).length, [columns, selected]);

    const handleSelect = React.useCallback((event, value) => {
        changeTabIndex(value);
    }, []);

    const handleClose = React.useCallback(() => {
        hideModal();
    }, []);

    const handleSaveCustomRouteSettings = React.useCallback((data) => {
        dispatch(saveCustomRouteSettings(data));
    }, []);

    const handleSave = React.useCallback(() => {
        handleSaveCustomRouteSettings({
            customRouteSettings: selected,
            allowedDistance: gps.allowedDistance,
            selectionType: gps.selectionType,
            timePickerType,
        });
        hideModal();
    }, [selected, gps.allowedDistance, gps.selectionType, timePickerType]);

    const handleChangeSlider = React.useCallback((event, value) => {
        changeGpsState((prevState) => ({ ...prevState, allowedDistance: value }));
    }, []);

    const handleChangeSelectionType = React.useCallback((event, value) => {
        changeGpsState((prevState) => ({ ...prevState, selectionType: +value }));
    }, []);

    const handleSliderUp = React.useCallback(() => {
        changeGpsState((prevState) => {
            const value = prevState.allowedDistance + 1 > MAX_GPS_DISTANCE ? MAX_GPS_DISTANCE : prevState.allowedDistance + 1;

            return { ...prevState, allowedDistance: value };
        });
    }, []);
    const handleSliderDown = React.useCallback(() => {
        changeGpsState((prevState) => {
            const value = prevState.allowedDistance - 1 < MIN_GPS_DISTANCE ? MIN_GPS_DISTANCE : prevState.allowedDistance - 1;

            return { ...prevState, allowedDistance: value };
        });
    }, []);

    const handleChangeTimePickerType = React.useCallback((timePickerType) => {
        changeTimePickerType(timePickerType);
    }, []);

    const handleCellSelection = React.useCallback(
        (index) => {
            return (event) =>
                changeSelected((oldState) => {
                    const selected = {
                        ...oldState,
                    };

                    if (event.target.checked) {
                        selected[index] = true;
                    } else {
                        delete selected[index];
                    }

                    return selected;
                });
        },
        [selected]
    );

    const handleRowSelection = React.useCallback(
        (index) =>
            changeSelected((oldState) => {
                const selected = {
                    ...oldState,
                };

                if (selected[index]) {
                    delete selected[index];
                } else {
                    selected[index] = true;
                }

                return selected;
            }),
        [selected]
    );

    const handleToggleAll = React.useCallback(() => {
        let selected = {};
        if (!selectedAll) {
            selected = columns.reduce((res, column, index) => {
                res[index] = true;

                return res;
            }, {});
        }

        changeSelected(selected);
    }, [selectedAll]);

    return (
        <Dialog open={true} onClose={handleClose} className="datetime-popup" fullScreen={true}>
            <DialogContent style={{ padding: 0 }} ref={ref}>
                <SettingTabs onChange={handleSelect} tabIndex={tabIndex} />
                <div className={classes.contentWrapper}>
                    {tabIndex === 0 && <DateTimeSetting onChange={handleChangeTimePickerType} timePickerType={timePickerType} />}
                    {tabIndex === 1 && (
                        <GpsSetting
                            allowedDistance={gps.allowedDistance}
                            selectionType={gps.selectionType}
                            onChangeSlider={handleChangeSlider}
                            onSliderUp={handleSliderUp}
                            onSliderDown={handleSliderDown}
                            onChangeSelectionType={handleChangeSelectionType}
                        />
                    )}
                    {tabIndex === 2 && (
                        <EnrouteSetting
                            columns={columns}
                            selected={selected}
                            selectedAll={selectedAll}
                            onCellSelection={handleCellSelection}
                            onRowSelection={handleRowSelection}
                            onToggleAll={handleToggleAll}
                        />
                    )}
                </div>
            </DialogContent>
            <SettingActions onClose={handleClose} onSave={handleSave} />
        </Dialog>
    );
});

export { Setting };
