import React from 'react';
import { get } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { PaletteForm } from '../../PaletteForm/PaletteForm';
import { LANDING_FIELDS } from '../constants';
import { getCurrentSendedLeg } from '../../../selectors/sended-data';
import { editInformationField } from '../../../actions/landing';
import { useSingleConvert } from '../../hooks/useMeasureConverter';

const LandingPalette = () => {
    const dispatch = useDispatch();
    const convert = useSingleConvert();

    const validationWeightFields = [
        { id: 'lw', title: 'lw', type: 'number', validator: { type: 'int', min: 0, max: convert(560000) } },
        { id: 'mlw', title: 'lw', type: 'number', validator: { type: 'int', min: 0, max: convert(560000) } },
    ];
    const { declaration, landingWeightMax, ...informationForm } = useSelector((state) => {
        const currentLeg = getCurrentSendedLeg(state);
        const landing = get(currentLeg, 'landing', {});
        const landingWeightMax = get(currentLeg, 'landingWeightMax', {});
        const landingDeclaration = get(state, 'routesDeclaration.landingDeclaration', []);

        // pilotFlight
        const result = {
            ...get(landing, 'informationForm', {}),
            declaration: landingDeclaration,
            landingWeightMax,
        };

        delete result.pilotFlight;

        return result;
    }, shallowEqual);

    const handleChangeField = React.useCallback((name, value) => {
        dispatch(editInformationField(name, value ? value : null));
    }, []);

    const fields = React.useMemo(() => {
        const res = [...LANDING_FIELDS, ...validationWeightFields];

        const index = res.findIndex((item) => item.id === 'lw');
        if (index !== -1) {
            res[index] = { ...res[index], maxLimit: landingWeightMax };
        }

        return res;
    }, [landingWeightMax]);

    return (
        <PaletteForm
            onEditInformationField={handleChangeField}
            informationForm={informationForm}
            declaration={declaration}
            fields={fields}
        />
    );
};

const LandingPaletteMemoized = React.memo(LandingPalette);

export { LandingPaletteMemoized as LandingPalette };
