import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
    canvas: {
        width: '100%',
        height: 420,
    },
    darkFilter: ({ isDarkTheme }) => ({
        ['-webkit-filter']: isDarkTheme ? `grayscale(${theme.palette.grey[700]})` : '',
        filter: isDarkTheme ? `grayscale(${theme.palette.grey[700]})` : '',
    }),
    eraseBtn: {
        position: 'absolute',
        right: theme.spacing(1),
        top: 0,
    },
    wrapper: {
        position: 'relative',
        display: 'flex',
        border: '2px solid rgb(100, 100, 100)',
    },
}));
