import React from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import moment from 'moment';

import { DialogControls } from '../../../components/DialogControls/DialogControls';
import { useHideModal } from '../../../actions/modal';
import { onCheckFuelInfo, savePointData } from '../../../actions/navigation';
import { DateTimePicker } from '../../../components/DateTimePicker/DateTimePicker';
import { convertFieldsValidator, getDeclarationFieldName, getFieldLabel, getFields, getFieldType, PointFields } from '../tools';
import { useStyles } from './useStyles';
import { useMobileWidth } from '../../../components/hooks/useMobileWidth';
import { useForm } from '../../../components/hooks/useForm/useForm';
import { NavType } from '../../../components/NavigationRoutes/constants';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../../components/material-ui/Dialog';
import { FormControl } from '../../../components/material-ui/FormControl';
import { TextField } from '../../../components/material-ui/TextField';
import { Typography } from '../../../components/material-ui/Typography';
import { useColumnsDeclaration } from '../../../selectors/routesDeclaration';
import { useSingleConvert } from '../../../components/hooks/useMeasureConverter';

const fieldList = [
    PointFields.FL,
    PointFields.GS,
    PointFields.EST,
    PointFields.USED,
    PointFields.ACT,
    PointFields.ETO,
    PointFields.ATO,
    PointFields.COMMENT,
];
const convertedFields = [PointFields.EST, PointFields.USED, PointFields.ACT];

const Point = React.forwardRef((props, ref) => {
    const { pos, point, title } = props;
    const classes = useStyles();
    const columnsDeclaration = useColumnsDeclaration();
    const { isMobilePortrait } = useMobileWidth();
    const hideModal = useHideModal();
    const dispatch = useDispatch();
    const handleSuccess = React.useCallback(
        (data) => {
            dispatch(savePointData(pos, data));
            dispatch(onCheckFuelInfo());
            hideModal();
        },
        [pos]
    );

    const fields = React.useMemo(() => getFields(fieldList), []);
    const convert = useSingleConvert();
    React.useMemo(() => convertFieldsValidator(convertedFields, fields, convert), []);

    const { inputs, handleInputChange, handleSubmit, status } = useForm({
        onSuccess: handleSuccess,
        initialData: {
            ...point,
            type: NavType.FLIGHT,
            dateCreate: moment.utc(point.dateCreate),
            eto: point.eto ? moment.utc(point.eto) : null,
            ato: point.ato ? moment.utc(point.ato) : moment.utc(),
        },
        fields,
    });

    const handleChangeField = React.useCallback((name, value) => {
        handleInputChange({ target: { name, value } });
    }, []);

    const disableSubmit = Boolean(
        fieldList.find((key) => Object.values(status || {}).some((item) => item) || (fields[key].required && !inputs[key]))
    );
    const actions = React.useMemo(
        () => [
            { label: 'CANCEL', onClick: hideModal },
            { label: 'OK', color: 'primary', type: 'submit', disabled: disableSubmit },
        ],
        [disableSubmit]
    );

    return (
        <Dialog ref={ref} open={true} fullScreen={isMobilePortrait} classes={{ paper: classes.paper }}>
            <DialogTitle className={classes.title}>{title}</DialogTitle>
            <form className={classes.fields} onSubmit={handleSubmit}>
                <DialogContent className={classes.content}>
                    <FormControl className={classes.formControl}>
                        <TextField
                            name={PointFields.FL}
                            label={getDeclarationFieldName(PointFields.FL, columnsDeclaration)}
                            placeholder={getDeclarationFieldName(PointFields.FL, columnsDeclaration)}
                            onChange={handleInputChange}
                            type={getFieldType(PointFields.FL)}
                            helperText={status[PointFields.FL] || get(inputs, PointFields.FL_DEFAULT, '')}
                            error={Boolean(status[PointFields.FL])}
                            FormHelperTextProps={{ classes: { root: classes.fl } }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            value={get(inputs, PointFields.FL, '')}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            name={PointFields.EST}
                            label={getDeclarationFieldName(PointFields.EST, columnsDeclaration)}
                            placeholder={getDeclarationFieldName(PointFields.EST, columnsDeclaration)}
                            onChange={handleInputChange}
                            type={getFieldType(PointFields.EST)}
                            helperText={status[PointFields.EST]}
                            error={Boolean(status[PointFields.EST])}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            value={get(inputs, PointFields.EST, '')}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <DateTimePicker
                        className={classes.picker}
                        name={PointFields.ETO}
                        canBeEmpty={true}
                        value={get(inputs, PointFields.ETO)}
                        labelTextTime={getDeclarationFieldName(PointFields.ETO, columnsDeclaration)}
                        onChange={handleChangeField}
                    />
                    <FormControl className={classes.formControl}>
                        <TextField
                            name={PointFields.GS}
                            label={getDeclarationFieldName(PointFields.GS, columnsDeclaration)}
                            placeholder={getDeclarationFieldName(PointFields.GS, columnsDeclaration)}
                            onChange={handleInputChange}
                            type={getFieldType(PointFields.GS)}
                            helperText={status[PointFields.GS]}
                            error={Boolean(status[PointFields.GS])}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            value={get(inputs, PointFields.GS, '')}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            name={PointFields.USED}
                            label={getDeclarationFieldName(PointFields.USED, columnsDeclaration)}
                            placeholder={getDeclarationFieldName(PointFields.USED, columnsDeclaration)}
                            onChange={handleInputChange}
                            type={getFieldType(PointFields.USED)}
                            helperText={status[PointFields.USED]}
                            error={Boolean(status[PointFields.USED])}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            value={get(inputs, PointFields.USED, '')}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            label=" "
                            disabled={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            label=" "
                            disabled={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            label=" "
                            disabled={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            name={PointFields.ACT}
                            label={getDeclarationFieldName(PointFields.ACT, columnsDeclaration)}
                            placeholder={getDeclarationFieldName(PointFields.ACT, columnsDeclaration)}
                            onChange={handleInputChange}
                            type={getFieldType(PointFields.ACT)}
                            helperText={status[PointFields.ACT]}
                            error={Boolean(status[PointFields.ACT])}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            value={get(inputs, PointFields.ACT, '')}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <DateTimePicker
                        className={classes.picker}
                        name={PointFields.ATO}
                        canBeEmpty={true}
                        value={get(inputs, PointFields.ATO)}
                        labelTextTime={getDeclarationFieldName(PointFields.ATO, columnsDeclaration)}
                        onChange={handleChangeField}
                    />
                    <FormControl className={classes.comment}>
                        <TextField
                            name={PointFields.COMMENT}
                            label={getFieldLabel(PointFields.COMMENT)}
                            onChange={handleInputChange}
                            helperText={status[PointFields.COMMENT]}
                            error={Boolean(status[PointFields.COMMENT])}
                            fullWidth={true}
                            multiline={true}
                            value={get(inputs, PointFields.COMMENT, '')}
                            minRows={1}
                            maxRows={5}
                            className={classes.textarea}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <Typography color="error">{status.message}</Typography>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <DialogControls actions={actions} />
                </DialogActions>
            </form>
        </Dialog>
    );
});

export { Point };
