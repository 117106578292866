import React from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import { useNoticeAirportByTask, useNoticeFilter } from '../../../selectors/notices/notices';
import { NoticeList } from '../NoticeList/NoticeList';
import { markAsReaded, onAddAirport, setNoticeFilter, toggleFavoriteNotice, toggleRecentNotice } from '../../../actions/notices';
import { NoticeAppBar } from '../NoticeAppBar/NoticeAppBar';
import { NoticeFooterPanel } from '../NoticeFooterPanel/NoticeFooterPanell';
import { ChooseAirportModal } from '../ChooseAirportModal/ChooseAirportModal';
import { FilterNoticeModal } from '../FilterNoticeModal/FilterNoticeModal';
import { NoticeSidebar, SIDEBAR_WIDTH } from '../NoticeSidebar/NoticeSidebar';
import { ErrorBoundary } from '../../../components/ErrorBoundary/ErrorBoundary';
import { useStyles } from '../useStyles';
import { useCurrentLeg } from '../../../selectors/screen';
import { useModalProps } from '../../../selectors/modals';
import { useMediaQuery } from '@material-ui/core';

export const NOTICE = 'NOTICE';

const NoticeModalContent = (props) => {
    const { styles = {} } = props;
    const [isMarkAll, setIsMarkAll] = React.useState(false);
    const { routesAirports, recent, favorites, nearest, noticeIds } = useNoticeAirportByTask();
    const filter = useNoticeFilter();
    const currentLeg = useCurrentLeg();
    const { title } = useModalProps();
    const shortView = useMediaQuery('(max-width: 600px)');
    const { isFavoritesFiltered = false, isRecentFiltered = false, isNearestFiltered = false } = filter;
    const isFilterSet = React.useMemo(
        () => Boolean(filter.text || filter.number || filter.scope !== -1),
        [filter.text, filter.number, filter.scope]
    );

    const dispatch = useDispatch();
    const classes = useStyles();
    const [sidebarOpen, toggleSidebarOpen] = React.useState(true);
    const [isNestedModalOpen, toggleNestedOpen] = React.useState(false);
    const [isFilterOpen, toggleFilterOpen] = React.useState(false);

    const handleToggleFavorite = React.useCallback((id) => dispatch(toggleFavoriteNotice(id)), []);
    const handleToggleRecent = React.useCallback((id) => dispatch(toggleRecentNotice(id)), []);

    const handleToggleSidebar = React.useCallback(() => {
        toggleSidebarOpen((prevState) => !prevState);
    }, []);

    const handleToggleFilterModal = React.useCallback(() => {
        toggleFilterOpen((prevState) => !prevState);
    }, []);

    const handleToggleModal = React.useCallback(() => {
        toggleNestedOpen((prevState) => !prevState);
    }, []);

    const handleAddAirport = React.useCallback(
        (code) => {
            dispatch(onAddAirport(currentLeg, code));
            handleToggleModal();
        },
        [currentLeg]
    );

    const handleSetFilter = React.useCallback((data) => {
        dispatch(setNoticeFilter(data));
        toggleFilterOpen(false);
    }, []);

    const handleMarkAll = React.useCallback(() => {
        setIsMarkAll(true);
    }, [noticeIds]);

    React.useEffect(() => {
        if (isMarkAll) {
            dispatch(markAsReaded(noticeIds));
            setIsMarkAll(false);
        }
    }, [isMarkAll, noticeIds]);

    const contentStyle = React.useMemo(() => {
        return {
            ...(sidebarOpen && {
                paddingLeft: `${SIDEBAR_WIDTH}px`,
            }),
        };
    }, [sidebarOpen]);

    return (
        <ErrorBoundary>
            <NoticeAppBar
                title={title}
                showMarkButton={recent > 0}
                sidebarOpen={sidebarOpen}
                onMarkAll={handleMarkAll}
                onLeftIconButtonClick={handleToggleSidebar}
            />
            <NoticeSidebar
                onToggle={handleToggleSidebar}
                className="notice-sidebar"
                open={sidebarOpen}
                routesAirports={routesAirports}
                isFilterSet={isFilterSet}
            />
            <div className={classnames('notice', classes.notice)}>
                <div style={contentStyle}>
                    <div className={classes.inner}>
                        <ErrorBoundary>
                            <NoticeList
                                routesAirports={routesAirports}
                                toggleFavoriteNotice={handleToggleFavorite}
                                toggleRecentNotice={handleToggleRecent}
                                backgroundColor={styles.airportBackgroundColor}
                            />
                        </ErrorBoundary>
                    </div>
                </div>
            </div>
            <NoticeFooterPanel
                shortView={shortView}
                iconColor={styles.iconColor}
                favorites={favorites}
                nearest={nearest}
                recent={recent}
                isFavoritesFiltered={isFavoritesFiltered}
                isRecentFiltered={isRecentFiltered}
                isNearestFiltered={isNearestFiltered}
                isFilterSet={isFilterSet}
                onAddAirport={handleToggleModal}
                onFilter={handleToggleFilterModal}
            />
            <ChooseAirportModal open={isNestedModalOpen} onOk={handleAddAirport} hideModal={handleToggleModal} />
            <FilterNoticeModal open={isFilterOpen} onOk={handleSetFilter} hideModal={handleToggleFilterModal} data={filter} />
        </ErrorBoundary>
    );
};

const NoticeModalContentMemoized = React.memo(NoticeModalContent);

export { NoticeModalContentMemoized as NoticeModalContent };
