import { showConfirmDialog } from './messageDialog';
import { setLoadingState } from './progressIndicator';
import { MessageDialogTypes } from '../components/MessageDialog/MessageDialog';

const UPDATE_DEFECTS_STATUS = 'UPDATE_DEFECTS_STATUS';

function downloadDefects(ids) {
    return (dispatch, getState, { apiManager }) => {
        return ids.reduce((promise, id) => {
            return promise.then((res) => {
                return apiManager
                    .downloadDefectFile(id)
                    .then(() => {
                        res.downloaded.push(id);

                        return res;
                    })
                    .catch((err) => {
                        console.log(err);
                        res.errors.push(id);

                        return res;
                    });
            });
        }, Promise.resolve({ downloaded: [], errors: [] }));
    };
}

function downloadDefect(id, name) {
    return (dispatch, getState, { apiManager }) => {
        dispatch(setLoadingState(true, `Downloading AC limitation "${name}"...`));

        return apiManager
            .downloadDefectFile(id)
            .then(() => {
                dispatch({ type: UPDATE_DEFECTS_STATUS, data: { downloaded: [id] } });
                dispatch(setLoadingState(false));
            })
            .catch(() => {
                let msg = {
                    title: 'Error message',
                    message: 'The document have been uploaded correctly. Please try again later!',
                    btnCancelVisibility: false,
                    options: {
                        type: MessageDialogTypes.ERROR,
                    },
                };
                // // Cannot re-open Dialog immediately, need timeout fro it
                // setTimeout(() =>{
                dispatch(showConfirmDialog(msg));
                dispatch(setLoadingState(false));
            });
    };
}

function downloadAllDefects(ids) {
    return (dispatch) => {
        dispatch(setLoadingState(true, 'Downloading documents...'));
        return Promise.all([dispatch(downloadDefects(ids))])
            .then(([data]) => {
                dispatch({ type: UPDATE_DEFECTS_STATUS, data: data });
                if (data.errors.length || data.errors.length) {
                    let msg = {
                        title: 'Error message',
                        message: 'Not all the documents have been uploaded correctly. Please try again later!',
                        btnCancelVisibility: false,
                        options: {
                            type: MessageDialogTypes.ERROR,
                        },
                    };
                    // // Cannot re-open Dialog immediately, need timeout fro it
                    // setTimeout(() =>{
                    dispatch(showConfirmDialog(msg));
                    // }, 1000)
                }
                dispatch(setLoadingState(false));
            })
            .catch(() => {
                dispatch(setLoadingState(false));
                // throw new Error('Failed to load documents from the server. Please try again later!');
            });
    };
}

export { UPDATE_DEFECTS_STATUS, downloadAllDefects, downloadDefect };
