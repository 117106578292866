import * as React from 'react';
import classnames from 'classnames';
import { useSnackbar } from 'notistack';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import { default as CloseIcon } from '@material-ui/icons/Close';
import { default as ExpandMoreIcon } from '@material-ui/icons/ExpandMore';

import { useStyles } from './useStyles';
import { SnackIcon } from '../SnackIcon/SnackIcon';

const SnackMessage = React.forwardRef(({ id, variant = 'default', message, details }, ref) => {
    const classes = useStyles({ variant })();
    const { closeSnackbar } = useSnackbar();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleDismiss = () => {
        closeSnackbar(id);
    };

    return (
        <Card className={classes.card} ref={ref}>
            <CardActions classes={{ root: classes.actionRoot }}>
                <Typography variant="subtitle2" className={classes.title}>
                    <div className={classes.iconWrapper}>
                        <SnackIcon variant={variant} className={classes.icon} />
                    </div>
                    {message}
                </Typography>
                <div className={classes.icons}>
                    {details && (
                        <IconButton
                            aria-label="Show more"
                            className={classnames(classes.expand, {
                                [classes.expandOpen]: expanded,
                            })}
                            onClick={handleExpandClick}
                            color="inherit"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    )}

                    <IconButton className={classes.expand} onClick={handleDismiss} color="inherit">
                        <CloseIcon />
                    </IconButton>
                </div>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit={true}>
                <Paper className={classes.collapse}>
                    <Typography gutterBottom={true} className={classes.text}>
                        {details}
                    </Typography>
                </Paper>
            </Collapse>
        </Card>
    );
});

export { SnackMessage };
