import * as React from 'react';
import CloseIcon from '@material-ui/icons/Close';

import { IconButton } from '../../material-ui/IconButton';
import { NestedListIconContrast } from '../../NestedListIconContrast/NestedListIconContrast';
import { useStyles } from './useStyles';
import { stopPropagation } from '../../service/eventHandlers';

const SelectIcon = ({ name, onClear }) => {
    const classes = useStyles();
    const handleClear = React.useCallback((event) => {
        stopPropagation(event);
        onClear(name);
    }, []);

    return (
        <>
            <IconButton className={classes.close} onClick={handleClear}>
                <CloseIcon />
            </IconButton>
            <div className={classes.divider} />
            <IconButton className={classes.arrow}>
                <NestedListIconContrast open={false} />
            </IconButton>
        </>
    );
};

const SelectIconMemoized = React.memo(SelectIcon);

export { SelectIconMemoized as SelectIcon };
