import moment from 'moment';
import { get, cloneDeep, xor, isNil } from 'lodash';
import { useDispatch } from 'react-redux';

import { setLoadingState } from './progressIndicator';
import { showConfirmDialog } from './messageDialog';
import { redirectPage } from './screen';
import { updateReportsAfterSave } from './reports';
import { createImage } from './images';
import { updateCheckListAfterSaving } from './checkList';
import { UNAUTHORIZED_HTTP_CODE } from '../service/Error';
import { getFlightTaskLegsList } from '../selectors/screen';
import { clearTaskSignatureStatus, SignaturePrefix } from './tasks';
import { MessageDialogTypes } from '../components/MessageDialog/MessageDialog';
import { getErrorMessage, getFileExtensions } from '../service/utils';
import { clearNotices } from './notices';
import { showModal, TYPES } from './modal';
import { MessageType, TelegramPrefix } from '../service/constants';
import { convertFields, LoadType } from '../service/measureConverter';
import { Weight } from '../reducers/systemOfMeasurement';
import { OtherError } from '../service/errors/OtherError';
import errorLogsSender from '../service/ErrorLogsSender';
import { addWeightUnitsToHistory } from './systemOfMeasurement';

const UPDATE_LEG_POINT_IDS = 'UPDATE_LEG_POINT_IDS';
const UPDATE_SENDED_DATA = 'UPDATE_SENDED_DATA';
const REMOVE_DELETED_POINTS = 'REMOVE_DELETED_POINTS';
const EDIT_OFP_FIELD = 'EDIT_OFP_FIELD';
const CLEAR_SENDED_DATA = 'CLEAR_SENDED_DATA';
const CLEAR_SENDED_DATA_LIST = 'CLEAR_SENDED_DATA_LIST';
const CONVERT_WEIGHT = 'CONVERT_WEIGHT';

const Message = {
    CREW_INFO: 'Crew info',
    FUEL_INFO: 'Fuel info',
    LOAD_INFO: 'Load info',
    PASSSENGER_INFO: 'Passanger info',
};

function updateLegPointsIds(legsData) {
    return {
        type: UPDATE_LEG_POINT_IDS,
        legsData,
    };
}

function removeDeletedPoints(legsData) {
    return {
        type: REMOVE_DELETED_POINTS,
        legsData,
    };
}

function prepareReportsToSend(plugins, reports = [], images = [], errorLogger) {
    const PREFIX = 'si_';

    return reports
        .filter((report) => report.isEdited || report.isRemoved)
        .map((item) => {
            if (item.isRemoved) {
                return Promise.resolve({ id: item.id, isRemoved: true });
            }

            const report = cloneDeep(item);
            report.newImages = report.images.filter((img) => img.needSave) || [];
            report.removedImages = report.images.filter((img) => img.isRemoved) || [];
            delete report.images;

            return Promise.all(getFiles(plugins, report.newImages || [], '', null, 'si_', errorLogger)).then((files) => {
                files.forEach((file) => {
                    let img = report.newImages.find((item) => item.id === file.id);
                    img.data = file.data;
                    img.contentType = images[`${PREFIX}${file.id}`].contentType;
                });

                return report;
            });
        });
}

function checkDataForRemove(tasks, ids) {
    let removedLegs = [].concat(ids);
    let activeTelegrams = [];
    let removedOfps = [];
    let removedTasks = [];

    tasks &&
        Object.keys(tasks).forEach((taskId) => {
            const task = tasks[taskId] || {};

            task.flights &&
                Object.keys(task.flights).map((key) => {
                    const flight = task.flights[key] || {};

                    flight.legs &&
                        Object.keys(flight.legs).forEach((legId) => {
                            const index = ids.indexOf(+legId);
                            if (index !== -1) {
                                // Все участки для удаления
                                removedOfps = [...removedOfps, ...Object.keys(get(flight, `legs[${legId}].ofps`, {})).map((id) => +id)];
                                if (removedTasks.indexOf(+taskId) === -1) {
                                    removedTasks.push(+taskId);
                                }
                            } else if (index === -1) {
                                // Все телеграммы из заданий, которые не удалены и не являются кандидатами на удаление, считаем актуальными.
                                activeTelegrams = [].concat(activeTelegrams, get(flight, `legs[${legId}].telegramIds`, []));
                            }
                        });
                });
        });

    return {
        removedLegs,
        activeTelegrams,
        removedOfps,
        removedTasks,
    };
}

function removeFiles(plugins, telegramIds = [], attachmentList = {}, removedOfpIds = [], removedTaskIds = []) {
    const telegrams = [].concat(telegramIds);
    const attachments = { ...attachmentList };
    const removedOfps = [].concat(removedOfpIds);
    const removedTasks = [].concat(removedTaskIds);

    return new Promise((resolve, reject) => {
        Promise.all([
            ...telegrams.map((id) => plugins.file.remove(`${TelegramPrefix.TELEGRAM}${id}`)),
            ...telegrams.map((id) => plugins.file.remove(`${TelegramPrefix.SIGN}${id}`)),
            ...Object.keys(attachments).map((id) =>
                plugins.file.remove(`${TelegramPrefix.ATTACHMENT}${id}${getFileExtensions(attachments[id])}`, 'telexAttachments')
            ),
            ...removedOfps.map((id) => plugins.file.remove(`${SignaturePrefix.OFP}${id}`)),
            ...removedTasks.map((id) => plugins.file.remove(`${SignaturePrefix.TASK}${id}`)),
        ])
            .then(resolve)
            .catch(reject);
    });
}

function clearSendedDataList(ids) {
    return (dispatch, getState, { plugins }) => {
        const state = getState();
        const tasks = get(state.tasks, 'list', {});
        const telex = get(state, 'telex', null);
        const telegrams = telex ? telex : get(state, 'telegrams', {});
        const { removedLegs, activeTelegrams, removedOfps, removedTasks } = checkDataForRemove(tasks, ids);
        const removedTelegrams = xor(
            Object.keys(telegrams).map((id) => +id),
            activeTelegrams
        );
        let removedAttachments = {};
        removedTelegrams.forEach((id) => {
            const telegramAttachments = get(telegrams[id], 'attachments', {});
            Object.keys(telegramAttachments).forEach((id) => {
                const attachment = telegramAttachments[id] || {};
                removedAttachments[id] = attachment.contentType;
            });
        });

        dispatch({
            type: CLEAR_SENDED_DATA_LIST,
            ids: removedLegs,
            removedTelegrams,
            removedOfps,
            removedTasks,
            notice: {
                ...clearNotices(state, ids),
            },
        });

        return removeFiles(plugins, removedTelegrams, removedAttachments, removedOfps, removedTasks);
    };
}

function clearSendedData(id) {
    return (dispatch) => {
        dispatch(
            showConfirmDialog({
                title: 'Confirmation dialog',
                message: 'Do you want to reset ALL changes for this leg?',
                options: {
                    okWarning: true,
                },
            })
        ).then((hasConflictOk) => {
            if (hasConflictOk) {
                dispatch(redirectPage('flight'));
                dispatch({ type: CLEAR_SENDED_DATA, id });
            }
        });
    };
}

function prepareCheckList(legId, checkList, checkListStatus) {
    const checkLists =
        checkList &&
        Object.keys(checkList).reduce((res, key) => {
            if (
                checkList[key] &&
                checkListStatus[key] &&
                +checkList[key].legId === +legId &&
                checkList[key].hasChanges &&
                checkList[key].groups
            ) {
                const { id, name, createDate, templateID, groups, comment } = checkList[key];

                res.push({
                    id,
                    name,
                    createDate,
                    templateID,
                    comment,
                    items: Object.values(groups).reduce(
                        (resItems, group = {}) =>
                            group.items &&
                            group.items.reduce((acc, item) => {
                                acc[item.templateItemId] = {
                                    id: item.id,
                                    templateItemId: item.templateItemId,
                                    checked: !!checkListStatus[key][item.templateItemId],
                                };

                                return acc;
                            }, resItems),
                        {}
                    ),
                });
            }

            return res;
        }, []);

    return checkLists && checkLists.length > 0 ? checkLists : null;
}

function prepareSendedData(state, ids, ignoreChoice = false, dispatch) {
    const legsOfTask = getFlightTaskLegsList(state);
    const sendedData = get(state, 'sendedData');
    const weightUnitsHistory = get(state, 'systemOfMeasurement.history', {});
    const appUnits = get(state, 'systemOfMeasurement.dimension');
    const { checkList, checkListStatus } = get(state, 'checkLists', {});
    const fieldsForConvert = get(state, 'systemOfMeasurement.fields', []);
    const res = {};

    sendedData &&
        Object.keys(sendedData).forEach((key) => {
            const dataConvertedTo =
                sendedData[key].weightUnits === Weight.LBS && !isNil(sendedData[key].convertedTo) ? sendedData[key].convertedTo : Weight.KG;

            let data = sendedData[key];
            data.weightUnitsHistory = weightUnitsHistory[key] ? [...weightUnitsHistory[key]] : [];
            const weightUnitsState = {
                event: 'Send',
                appUnits,
                legConvertedToUnits: sendedData[key].convertedTo || 'unset',
                onSendUnitsChangeTo: -1,
            };
            if (dataConvertedTo === Weight.LBS) {
                data = convertFields({
                    value: data,
                    fieldsForConvert,
                    weightDimension: Weight.KG,
                    options: {
                        precision: {
                            fields: ['est', 'est_default', 'act', 'used', 'used_default'],
                            toFixed: 2,
                        },
                    },
                    loadType: LoadType.UPLOAD,
                });
                weightUnitsState.onSendUnitsChangeTo = Weight.KG;
            }
            data.weightUnitsHistory.push(weightUnitsState);
            dispatch(addWeightUnitsToHistory({ weightUnitsState, legID: key }));

            if (!!legsOfTask[key] && data) {
                // только данные по текущему ПЗ отправляем
                if (
                    // только если есть изменения
                    (data.hasChanges || data.hasTelegramSignChanges || data.hasOfpSignatureChanges) &&
                    data.ofpId && // только там, где выбран расчет
                    (ignoreChoice || ids.indexOf(key) !== -1) // пользователь выбрал данный лег для отправки
                ) {
                    res[key] = cloneDeep(data);
                }

                const newCheckLists = prepareCheckList(key, checkList, checkListStatus);

                if (newCheckLists) {
                    res[key].checkLists = newCheckLists;
                }
            }
        });
    return res;
}

function getReports(reportData) {
    return reportData.reduce(
        (res, report = {}) => {
            if (res.empty) {
                res.empty = false;
            }
            if (report.error) {
                res.error = true;
                if (report.reportIds) {
                    res.deleteError = true;
                }

                return res;
            }
            const reportData = get(report, 'output.reportData', {});

            const { id, srcId, number, imagesIds } = reportData;
            if (srcId) {
                res.data[srcId] = {
                    id,
                    number,
                    imagesIds,
                };
            }

            return res;
        },
        { data: {}, error: false, empty: true }
    );
}

function getRemovedReportIds(reports = []) {
    return reports.length > 0 && reports.map((report) => report.id).join(',');
}

function sendData(ids = []) {
    const idsList = ids.slice();

    return (dispatch, getState, { apiManager, plugins, errorLogger }) => {
        const state = getState();
        const { currentTask } = state.screen;
        const sendDataWithChanges = prepareSendedData(state, idsList, false, dispatch);
        const userId = get(state, 'login.user.id');
        const msg = {
            title: 'Sending error',
            message: 'Sending data error. Please try again later',
            btnCancelVisibility: false,
            options: {
                type: MessageDialogTypes.ERROR,
            },
        };

        if (!userId) {
            dispatch(redirectPage('login'));

            return;
        }

        dispatch(setLoadingState(true, 'Uploading data...'));

        return Promise.all([
            prepareSignatures(plugins, state.images, errorLogger),
            ...prepareReportsToSend(plugins, get(state, 'reports.reports', []), state.images, errorLogger),
        ])
            .then(([{ taskSignatureList, ofpSignatureList, telegramSignatureList }, ...reports]) => {
                const removedReports = [];
                const updatedReports = [];

                reports.forEach((report) => {
                    if (report.isRemoved) {
                        removedReports.push(report);
                    } else {
                        updatedReports.push(report);
                    }
                });

                setOfpSignatureData(sendDataWithChanges, filterOfpSignatureList(ofpSignatureList, sendDataWithChanges));
                const reportPromises = [...updatedReports.map((report) => apiManager.sendReport(userId, report))];
                if (removedReports.length > 0) {
                    reportPromises.push(apiManager.removeReports(userId, getRemovedReportIds(removedReports)));
                }

                return Promise.all([
                    apiManager.sendData(
                        userId,
                        sendDataWithChanges,
                        filterTaskSignatureList(taskSignatureList, state),
                        filterTelegramSignatureList(telegramSignatureList, sendDataWithChanges)
                    ),
                    ...reportPromises,
                ]).then(([data, ...reportData]) => {
                    const reports = getReports(reportData);

                    if (!data.error) {
                        dispatch(updateLegPointsIds(data.output.legsData));
                        dispatch(removeDeletedPoints(data.output.legsData));
                        dispatch(updateSendedDataAfterSaving(idsList));
                        dispatch(clearTaskSignatureStatus(currentTask));
                        dispatch(updateCheckListAfterSaving(data.output.legsData));
                    }

                    if (!reports.empty) {
                        dispatch(updateReportsAfterSave(reports.data, reports.deleteError));
                    }

                    dispatch(setLoadingState(false));

                    if (!data.error && !reports.error) {
                        const legData = Object.values(data.output.legsData)[0];
                        const isBufferLoadsUpdated = get(legData, 'isBufferLoadsUpdated', true);
                        const isBufferPassengerUpdated = get(legData, 'isBufferPassengerUpdated', true);
                        const isBufferFuelUpdated = get(legData, 'isBufferFuelUpdated', true);
                        const isBufferCrewUpdated = get(legData, 'isBufferCrewUpdated', true);

                        if (!isBufferLoadsUpdated || !isBufferPassengerUpdated || !isBufferFuelUpdated || !isBufferCrewUpdated) {
                            const updateStutses = [
                                [isBufferLoadsUpdated, Message.LOAD_INFO],
                                [isBufferPassengerUpdated, Message.PASSSENGER_INFO],
                                [isBufferFuelUpdated, Message.FUEL_INFO],
                                [isBufferCrewUpdated, Message.CREW_INFO],
                            ];
                            const messages = updateStutses.reduce((allMessages, [isUpdated, message]) => {
                                if (!isUpdated) {
                                    allMessages.push(message);
                                }
                                return allMessages;
                            }, []);

                            dispatch(
                                showConfirmDialog({
                                    title: 'Sending success',
                                    message: `All data was sent successfully, but part of the data was not updated on server: \n ${messages.join(
                                        ', '
                                    )}`,
                                    btnCancelVisibility: false,
                                    options: {
                                        type: MessageDialogTypes.SUCCESS,
                                    },
                                })
                            );
                        } else {
                            dispatch(
                                showConfirmDialog({
                                    title: 'Sending success',
                                    message: 'All data was sent successfully to the server',
                                    btnCancelVisibility: false,
                                    options: {
                                        type: MessageDialogTypes.SUCCESS,
                                    },
                                })
                            );
                        }
                    } else if (!data.error && reports.error) {
                        const error = new OtherError({
                            message: 'An error occurred while sending data. Data error is false and reports error is true',
                        });
                        errorLogsSender.addLog(error);
                        dispatch(
                            showConfirmDialog({
                                title: 'Sending error',
                                message:
                                    'The flight task data were sent successfully, but the reports have not been sent yet. Please try to send them again later.',
                                btnCancelVisibility: false,
                                options: {
                                    type: MessageDialogTypes.ERROR,
                                },
                            })
                        );
                    } else if (data.error && reports.empty) {
                        const error = new OtherError({
                            message: 'An error occurred while sending data. Data error is true and reports are empty',
                        });
                        errorLogsSender.addLog(error);
                        dispatch(
                            showConfirmDialog({
                                title: 'Sending error',
                                message: 'The flight task data have not been sent yet. Please try to send them again later.',
                                btnCancelVisibility: false,
                                options: {
                                    type: MessageDialogTypes.ERROR,
                                },
                            })
                        );
                    } else if (data.error && !reports.error && !reports.empty && !reportData.some((item) => !item || item.error)) {
                        dispatch(
                            showConfirmDialog({
                                title: 'Sending error',
                                message:
                                    'Reports were sent successfully, but the flight task data have not been sent yet. Please try to send them again later.',
                                btnCancelVisibility: false,
                                options: {
                                    type: MessageDialogTypes.ERROR,
                                },
                            })
                        );
                    } else {
                        dispatch(showConfirmDialog(msg));
                    }
                });
            })
            .catch((err) => {
                dispatch(setLoadingState(false));

                if (err.errorCode === UNAUTHORIZED_HTTP_CODE) {
                    dispatch(showModal(TYPES.RELOGIN, {}));
                } else {
                    const errorWithCustomMessage = new Error(getErrorMessage(err, MessageType.SEND_DATA));
                    const error = new OtherError(errorWithCustomMessage);
                    errorLogsSender.addLog(error);
                    dispatch(showConfirmDialog(msg));
                }
            })
            .finally(() => {
                errorLogsSender.sendLogs();
            });
    };
}

function sendOtherData(ids = []) {
    const idsList = ids.slice();

    return (dispatch, getState, { apiManager, plugins, errorLogger }) => {
        const state = getState();
        const { currentTask } = state.screen;
        const sendDataWithChanges = prepareSendedData(state, idsList, true, dispatch);
        const userId = get(state, 'login.user.id');
        const msg = {
            title: 'Sending error',
            message: 'Sending data error. Please try again later',
            btnCancelVisibility: false,
            options: {
                type: MessageDialogTypes.ERROR,
            },
        };

        if (!userId) {
            dispatch(redirectPage('login'));

            return;
        }

        dispatch(setLoadingState(true, 'Sending signs and reports...'));

        return Promise.all([
            prepareSignatures(plugins, state.images, errorLogger),
            ...prepareReportsToSend(plugins, get(state, 'reports.reports', []), state.images, errorLogger),
        ])
            .then(([{ taskSignatureList, ofpSignatureList, telegramSignatureList }, ...reports]) => {
                const removedReports = [];
                const updatedReports = [];

                reports.forEach((report) => {
                    if (report.isRemoved) {
                        removedReports.push(report);
                    } else {
                        updatedReports.push(report);
                    }
                });

                const reportPromises = [...updatedReports.map((report) => apiManager.sendReport(userId, report))];
                if (removedReports.length > 0) {
                    reportPromises.push(apiManager.removeReports(userId, getRemovedReportIds(removedReports)));
                }

                return Promise.all([
                    apiManager.sendOtherData(
                        userId,
                        filterTaskSignatureList(taskSignatureList, state),
                        filterTelegramSignatureList(telegramSignatureList, sendDataWithChanges),
                        filterOfpSignatureList(ofpSignatureList, sendDataWithChanges),
                        getRequiredRefueling(sendDataWithChanges)
                    ),
                    ...reportPromises,
                ]).then(([data, ...reportData]) => {
                    const reports = getReports(reportData);

                    if (!data.error) {
                        dispatch(updateSendedDataAfterSaving(idsList, true));
                        dispatch(clearTaskSignatureStatus(currentTask));
                    }
                    if (!reports.empty) {
                        dispatch(updateReportsAfterSave(reports.data, reports.deleteError));
                    }

                    dispatch(setLoadingState(false));
                    if (!data.error && !reports.error) {
                        dispatch(
                            showConfirmDialog({
                                title: 'Sending success',
                                message: 'All data was sent successfully to the server',
                                btnCancelVisibility: false,
                                options: {
                                    type: MessageDialogTypes.SUCCESS,
                                },
                            })
                        );
                    } else if (!data.error && reports.error) {
                        dispatch(
                            showConfirmDialog({
                                title: 'Sending error',
                                message:
                                    'The flight task data were sent successfully, but the reports have not been sent yet. Please try to send them again later.',
                                btnCancelVisibility: false,
                                options: {
                                    type: MessageDialogTypes.ERROR,
                                },
                            })
                        );
                    } else if (data.error && !reports.error && !reportData.some((item) => !item || item.error)) {
                        dispatch(
                            showConfirmDialog({
                                title: 'Sending error',
                                message:
                                    'Reports were sent successfully, but the flight task data have not been sent yet. Please try to send them again later.',
                                btnCancelVisibility: false,
                                options: {
                                    type: MessageDialogTypes.ERROR,
                                },
                            })
                        );
                    } else {
                        dispatch(showConfirmDialog(msg));
                    }
                });
            })
            .catch((err) => {
                dispatch(setLoadingState(false));

                if (err.errorCode === UNAUTHORIZED_HTTP_CODE) {
                    dispatch(showModal(TYPES.RELOGIN, {}));
                } else {
                    const errorWithCustomMessage = new Error(getErrorMessage(err, MessageType.SEND_REPORTS));
                    const error = new OtherError(errorWithCustomMessage);
                    errorLogsSender.addLog(error);
                    dispatch(showConfirmDialog(msg));
                }
            });
    };
}

function updateSendedDataFromBackend(sendedData = {}) {
    return (dispatch, getState) => {
        const state = getState();
        const isTotalEditable = get(state, 'routesDeclaration.options.manualEdit', false);

        Object.keys(sendedData).forEach((id) => {
            const prevOfpSignature = get(state.sendedData[id], 'signature');
            sendedData[id] = prepareDataFromBackend(dispatch, sendedData[id], prevOfpSignature, isTotalEditable);
        });

        return dispatch({ type: UPDATE_SENDED_DATA, sendedData });
    };
}

function updateSendedDataAfterSaving(ids = [], sendSign = false) {
    return (dispatch, getState) => {
        const state = getState();
        const { sendedData } = state;
        const legIds = Object.keys(getFlightTaskLegsList(state));

        Object.keys(sendedData).forEach((id) => {
            if (ids.indexOf(id) !== -1) {
                sendedData[id].hasChanges = false;
                sendedData[id].hasTelegramSignChanges = false;
                sendedData[id].hasOfpSignatureChanges = false;
            } else if (sendSign && legIds.indexOf(id) !== -1) {
                sendedData[id].hasTelegramSignChanges = false;
                sendedData[id].hasOfpSignatureChanges = false;
            }
        });

        return dispatch({ type: UPDATE_SENDED_DATA, sendedData });
    };
}

function prepareOfpSignature(dispatch, fileName, signature = {}) {
    const { data, contentType } = signature;
    if (!data || !contentType || !fileName) {
        return;
    }

    return dispatch(createImage(fileName, data, contentType));
}

function convertWeightValues(update) {
    return { type: CONVERT_WEIGHT, ...update };
}

function useConvertWeightValues() {
    const dispatch = useDispatch();

    return (update) => dispatch(convertWeightValues(update));
}

function prepareDataFromBackend(dispatch, data, prevOfpSignature, isTotalEditable = false) {
    const res = Object.assign({}, data);

    if (data.signature && data.signature.data) {
        const fileName = `sign-ofp_${data.ofpId}`;
        res.signature = fileName;
        prepareOfpSignature(dispatch, fileName, data.signature);
    } else if (prevOfpSignature) {
        res.signature = prevOfpSignature;
    }

    res.takeOff.startWorkTime = res.takeOff.startWorkTime ? moment.utc(res.takeOff.startWorkTime) : undefined;
    res.takeOff.startEngineTime = res.takeOff.startEngineTime ? moment.utc(res.takeOff.startEngineTime) : undefined;
    res.takeOff.takeOffTime = res.takeOff.takeOffTime ? moment.utc(res.takeOff.takeOffTime) : undefined;
    res.landing.endWorkTime = res.landing.endWorkTime ? moment.utc(res.landing.endWorkTime) : undefined;
    res.landing.stopEngineTime = res.landing.stopEngineTime ? moment.utc(res.landing.stopEngineTime) : undefined;
    res.landing.landingTime = res.landing.landingTime ? moment.utc(res.landing.landingTime) : undefined;
    res.isTotalEditable = res.isTotalEditable || isTotalEditable;

    return res;
}

function getFiles(plugins, list, string_template = '', type, prefix = '', errorLogger) {
    return list.map((img) => {
        const id = img.id.substring(string_template.length);

        return new Promise((res, rej) => {
            plugins.file
                .readAsArray(`${prefix}${img.id}`)
                .then((data) =>
                    res({
                        id,
                        type,
                        data,
                    })
                )
                .catch((err) => {
                    if (errorLogger) {
                        errorLogger.addErrorManual(err);
                    }
                    const errorWithCustomMessage = new Error(getErrorMessage(err, MessageType.GET_FILE));
                    errorLogsSender.addLog(new OtherError(errorWithCustomMessage));
                    rej(err);
                });
        });
    });
}

function getImageList(images, prefix) {
    return Object.keys(images)
        .map((key) => images[key])
        .filter((img) => {
            const pos = img && img.id && img.id.indexOf(prefix);

            return pos !== undefined && pos !== -1 && Number.isFinite(+img.id.substr(prefix.length));
        });
}

function prepareSignatures(plugins, images, errorLogger) {
    const imageList = getImageList(images, SignaturePrefix.TASK);
    const imageOfpList = getImageList(images, SignaturePrefix.OFP);
    const imageTelegramList = getImageList(images, TelegramPrefix.SIGN);
    const taskSignatureList = {};
    const ofpSignatureList = {};
    const telegramSignatureList = {};

    return Promise.all([
        ...getFiles(plugins, imageList, SignaturePrefix.TASK, 'task', '', errorLogger),
        ...getFiles(plugins, imageOfpList, SignaturePrefix.OFP, 'ofp', '', errorLogger),
        ...getFiles(plugins, imageTelegramList, TelegramPrefix.SIGN, 'telegram', '', errorLogger),
    ]).then((data) => {
        data.forEach((item) => {
            let array;
            switch (item.type) {
                case 'task':
                    array = taskSignatureList;
                    break;
                case 'ofp':
                    array = ofpSignatureList;
                    break;
                default:
                    array = telegramSignatureList;
            }

            array[item.id] = {
                contentType: 'image/jpeg',
                data: item.data,
            };
        });

        return {
            taskSignatureList,
            ofpSignatureList,
            telegramSignatureList,
        };
    });
}

function setOfpSignatureData(sendedData, signatureList) {
    Object.keys(sendedData).forEach((key) => {
        const leg = sendedData[key];
        const signature = signatureList[leg.ofpId];
        if (signature) {
            leg.signature = signature;
        } else {
            delete leg.signature;
        }
    });
}

function editOfpField(name, value) {
    return (dispatch, getState) => {
        const { currentUser, currentLeg } = getState().screen;
        return dispatch({
            type: EDIT_OFP_FIELD,
            name,
            value,
            legId: currentLeg,
            userId: currentUser.id,
        });
    };
}

function filterTelegramSignatureList(telegramSignatureList, sendData) {
    const res = {};
    let sendList = [];
    Object.keys(sendData).forEach((key) => {
        const list = sendData[key].telegramWithSignature || [];
        list.forEach((id) => sendData[key].hasTelegramSignChanges && sendList.indexOf(id) === -1 && sendList.push(id));
    });

    sendList.forEach((id) => {
        const telegram = telegramSignatureList[id];
        if (telegram) {
            res[id] = { ...telegram };
        }
    });

    return res;
}

function filterOfpSignatureList(ofpSignatureList, sendData) {
    const res = {};
    let sendList = [];
    Object.keys(sendData).forEach((key) => {
        const { ofpId, hasOfpSignatureChanges, signComment } = sendData[key];
        if (hasOfpSignatureChanges && ofpId && sendList.indexOf(ofpId) === -1) {
            sendList.push({ ofpId, signComment });
        }
    });

    sendList.forEach(({ ofpId, signComment }) => {
        const ofp = ofpSignatureList[ofpId];
        if (ofp) {
            res[ofpId] = {
                ...ofp,
                signComment,
            };
        }
    });

    return res;
}

function filterTaskSignatureList(taskSignatureList, state) {
    const { currentTask } = state.screen;
    const hasSignatureChange = get(state.tasks, `list[${currentTask}].hasSignatureChange`);

    return (
        (currentTask &&
            taskSignatureList[currentTask] &&
            hasSignatureChange && {
                [currentTask]: { ...(taskSignatureList[currentTask] || {}) },
            }) ||
        {}
    );
}

function getRequiredRefueling(sendedData) {
    return Object.keys(sendedData).reduce((accum, key) => {
        if (sendedData[key] && sendedData[key].takeOff && sendedData[key].takeOff.decisionFuel) {
            accum[key] = {
                ofpId: sendedData[key].ofpId,
                takeOff: {
                    decisionFuel: sendedData[key].takeOff.decisionFuel,
                    refuelingTakeoff: sendedData[key].takeOff.refuelingTakeoff,
                },
            };
        }

        return accum;
    }, {});
}

export {
    UPDATE_LEG_POINT_IDS,
    UPDATE_SENDED_DATA,
    REMOVE_DELETED_POINTS,
    EDIT_OFP_FIELD,
    CLEAR_SENDED_DATA,
    CLEAR_SENDED_DATA_LIST,
    CONVERT_WEIGHT,
    sendData,
    updateSendedDataFromBackend,
    editOfpField,
    clearSendedData,
    clearSendedDataList,
    sendOtherData,
    convertWeightValues,
    useConvertWeightValues,
};
