import React from 'react';

import { Person } from '../../../icons';
import { TYPES, useShowModal } from '../../../actions/modal';
import { useStyles } from './useStyles';
import { ListItem, ListItemText } from '../../material-ui/List';
import { ListItemAvatar } from '../../material-ui/List/ListItemAvatar';
import { Avatar } from '../../material-ui/Avatar';

const EmployeeItem = (props) => {
    const { fullName, staffTree, chair, phone, email, url, crewId } = props;
    const showModal = useShowModal();
    const classes = useStyles();

    const handleShowModal = React.useCallback(
        () =>
            showModal(TYPES.EMPLOYEE, {
                fullName,
                staffTree,
                chair,
                phone,
                email,
                url,
                id: crewId,
                title: 'employee',
            }),
        [crewId, url]
    );

    return (
        <ListItem onClick={handleShowModal} className={classes.listItem} button={true}>
            <ListItemAvatar className={classes.avatarWrapper}>
                <Avatar src={url} className={classes.avatar}>
                    <Person width={60} height={60} />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                classes={{
                    primary: classes.primary,
                    secondary: classes.secondaryText,
                }}
                primary={fullName}
                secondary={
                    <span>
                        <span className={classes.item}>{`${staffTree} ${chair ? '(' + chair + ')' : ''}`}</span>
                        <span className={classes.item}>{phone}</span>
                        <span className={classes.item}>{email}</span>
                    </span>
                }
            />
        </ListItem>
    );
};

const EmployeeItemMemoized = React.memo(EmployeeItem);

export { EmployeeItemMemoized as EmployeeItem };
