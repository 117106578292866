import { get } from 'lodash';

import { REMOVE_ALL_FILES } from './library';
import { showConfirmDialog } from './messageDialog';
import { setLoadingState } from './progressIndicator';
import BackupSingleton from '../service/BackupSingleton';
import version from '../service/version';
import { FileStorePath } from '../service/constants';
import { isAndroid } from '../service/utils';

export const LOGOUT = 'LOGOUT';
export const REDIRECT_PAGE = 'REDIRECT_PAGE';
export const SET_FONT_SIZE = 'SET_FONT_SIZE';
export const TOGGLE_SNACK_NOTIFICATION = 'TOGGLE_SNACK_NOTIFICATION';
export const TOGGLE_THEME = 'TOGGLE_THEME';
export const TOGGLE_ENROUTE_MODE = 'TOGGLE_ENROUTE_MODE';
export const DEFAULT_TELEX_FONT_SIZE = 18;
const FontSizeLimit = {
    MIN: 10,
    MAX: 30,
};

function toggleSnackNotification(isVisible, message) {
    return { type: TOGGLE_SNACK_NOTIFICATION, isVisible, message };
}

function logout() {
    return (dispatch, getState, { apiManager, history, plugins }) => {
        BackupSingleton.off();
        const userId = get(getState(), 'login.user.id');
        dispatch(setLoadingState(true, 'Logout...'));
        apiManager
            .logout(userId, version.toObject())
            .then((res) => {
                tryLogout(res.error, dispatch, history, plugins);
            })
            .catch((error) => {
                tryLogout(error, dispatch, history, plugins);
            });
    };
}

function tryLogout(error, dispatch, history, plugins) {
    setTimeout(() => {
        const msg = {
            title: 'Logout error',
            message: 'Logout error. Please try again later',
            btnCancelVisibility: false,
        };

        dispatch(setLoadingState(false));
        if (error && error.errorCode && error.errorCode !== 401) {
            BackupSingleton.on();
            return dispatch(showConfirmDialog(msg));
        } else {
            history.push('/login');
            plugins.geolocation.stop();

            return dispatch({ type: LOGOUT });
        }
    }, 1000);
}

function removeDocs() {
    return (dispatch, getState, { apiManager }) => {
        dispatch(setLoadingState(true, 'Removing documents...'));

        const operations = [apiManager.getDirectoryList()];
        operations.push(isAndroid() ? apiManager.getDirectoryList(FileStorePath.ANDROIDDOWNLOAD) : Promise.resolve());

        return Promise.all(operations).then(([localList = [], androidDownloadList = []]) => {
            return Promise.all(
                [...localList, ...androidDownloadList].map((item) => {
                    if (item.isFile) {
                        apiManager.removeFile(item.name);
                    } else {
                        apiManager.removeDirectory(item.name);
                    }
                })
            )
                .then(() => {
                    dispatch(setLoadingState(false));
                    dispatch({ type: REMOVE_ALL_FILES });

                    setTimeout(
                        () =>
                            dispatch(
                                showConfirmDialog({
                                    title: 'Success',
                                    message: 'All files are successfully deleted',
                                    btnCancelVisibility: false,
                                })
                            ),
                        1000
                    );
                })
                .catch((err) => {
                    dispatch(setLoadingState(false));
                    console.log(err);

                    setTimeout(
                        () =>
                            dispatch(
                                showConfirmDialog({
                                    title: 'Delete error',
                                    message: 'An unexpected error occurred. Failed to remove the files',
                                    btnCancelVisibility: false,
                                })
                            ),
                        1000
                    );
                });
        });
    };
}

function redirectPage(path) {
    return (dispatch, getState, { history }) => {
        dispatch({ type: REDIRECT_PAGE, path });

        return history.push(`/${path}`);
    };
}

function toggleTheme() {
    return (dispatch, getState) => {
        const { screen } = getState();

        return dispatch({ type: TOGGLE_THEME, darkTheme: !screen.darkTheme });
    };
}

function toggleMobileEnrouteMode() {
    return { type: TOGGLE_ENROUTE_MODE };
}

function increaseTelegramFontSize() {
    return (dispatch, getState) => {
        const fontSize = get(getState(), 'screen.fontSize', DEFAULT_TELEX_FONT_SIZE);

        return dispatch({ type: SET_FONT_SIZE, payload: fontSize + 1 > FontSizeLimit.MAX ? FontSizeLimit.MAX : fontSize + 1 });
    };
}

function decreaseTelegramFontSize() {
    return (dispatch, getState) => {
        const fontSize = get(getState(), 'screen.fontSize', DEFAULT_TELEX_FONT_SIZE);

        return dispatch({
            type: SET_FONT_SIZE,
            payload: fontSize - 1 < FontSizeLimit.MIN ? FontSizeLimit.MIN : fontSize - 1,
        });
    };
}

export {
    decreaseTelegramFontSize,
    increaseTelegramFontSize,
    logout,
    redirectPage,
    removeDocs,
    toggleMobileEnrouteMode,
    toggleSnackNotification,
    toggleTheme,
};
