import React from 'react';

import HeicIcon from '../../../icons/image/Heic';
import { useAnchorEl } from '../../../components/hooks/useAnchorEl';
import { Menu, MenuItem } from '../../../components/material-ui/Menu';

export const ReportDropzoneHeic = React.memo(({ width, height, className, onDelete, pos, isNew }) => {
    const [anchorEl, handleOpenMenu, handleClose] = useAnchorEl();

    const handleDeleteImage = React.useCallback(() => {
        onDelete(pos, isNew);
        handleClose();
    }, [pos, isNew]);

    return (
        <>
            <HeicIcon className={className} width={width} height={height} onClick={handleOpenMenu} />
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {onDelete && <MenuItem onClick={handleDeleteImage}>Delete</MenuItem>}
            </Menu>
        </>
    );
});
