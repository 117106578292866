import React, { useContext } from 'react';
import classnames from 'classnames';

import { Drawer } from '../material-ui/Drawer';
import { FilterContent } from './FilterContent/FilterContent';
import { SidebarContext } from '../PageLayout/ContextProvider';
import { useStyles } from './useStyles';

export const FilterSidebar = React.memo(() => {
    const {
        sidebarVisibility: { filterSidebarVisibility },
        handleToggleFilterSidebar,
    } = useContext(SidebarContext);

    const classes = useStyles();

    const handleRequestClose = React.useCallback(() => handleToggleFilterSidebar(false), []);

    return (
        <Drawer
            open={filterSidebarVisibility}
            anchor="right"
            onClose={handleRequestClose}
            classes={{ root: classes.drawerRoot, paper: classes.drawerPaper }}
            className={classnames({
                [classes.drawerOpenClass]: filterSidebarVisibility,
                [classes.drawerCloseClass]: !filterSidebarVisibility,
            })}
        >
            <FilterContent handleFiltersClose={handleRequestClose} />
        </Drawer>
    );
});
