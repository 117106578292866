const MAX_POSITION_ERRORS_BEFORE_RESET = 5;
const FEET_TO_METER_KOEF = 0.3048;
const MIN_QUALITY = 300;
const PositionError = {
    PERMISSION_DENIED: 1,
    POSITION_UNAVAILABLE: 2,
    TIMEOUT: 3,
};

const OPTIONS = {
    maximumAge: 3000,
    timeout: 5000,
    enableHighAccuracy: true,
};

class Geolocation {
    constructor() {
        this.watchpositionErrorCount = 0;
    }

    start = (onSuccess, onError) => {
        if (this._watchId) {
            this.stop();
        }
        this.onSuccess = onSuccess;
        this.onError = onError;

        this._watchId = navigator.geolocation.watchPosition(this._onSuccess, this._onError, OPTIONS);
    };

    stop = () => {
        this.onSuccess = null;
        if (this._watchId) {
            navigator.geolocation.clearWatch(this._watchId);
        }
    };

    _onSuccess = (position) => {
        this.watchpositionErrorCount = 0;
        // console.log('Latitude: ' + position.coords.latitude + '\n' +
        //     'Longitude: ' + position.coords.longitude + '\n' +
        //     'Altitude: ' + position.coords.altitude + '\n' +
        //     'Accuracy: ' + position.coords.accuracy + '\n' +
        //     'Altitude Accuracy: ' + position.coords.altitudeAccuracy + '\n' +
        //     'Heading: ' + position.coords.heading + '\n' +
        //     'Speed: ' + position.coords.speed + '\n' +
        //     'Timestamp: ' + position.timestamp + '\n');

        if (this.onSuccess) {
            this.onSuccess({
                accuracy: position.coords.accuracy,
                altitudeAccuracy: position.coords.altitudeAccuracy,
                heading: position.coords.heading,
                speed: position.coords.speed,
                longitude: position.coords.longitude,
                latitude: position.coords.latitude,
                timestamp: position.coords.accuracy < MIN_QUALITY && position.timestamp,
                altitude: position.coords.altitude && position.coords.altitude / FEET_TO_METER_KOEF,
            });
        }
    };

    _onError = (err) => {
        if (err.code == PositionError.TIMEOUT && this.watchpositionErrorCount >= MAX_POSITION_ERRORS_BEFORE_RESET) {
            this.start(this.onSuccess);
            this.watchpositionErrorCount = 0;
        }

        if (this.onError) {
            this.onError();
        }
    };
}

const geolocation = new Geolocation();

export default geolocation;
