function en(strings, ...values) {
    let pattern = '';
    for (let i = 0; i < values.length; i++) {
        pattern += strings[i] + '{' + i + '}';
    }
    pattern += strings[strings.length - 1];
    // Теперь pattern = "Hello, {0}!"

    let translated = translationsOject['en'][pattern];

    return translated.replace(/\{(\d)\}/g, (s, num) => values[num]);
}

function ru(strings, ...values) {
    let pattern = '';
    for (let i = 0; i < values.length; i++) {
        pattern += strings[i] + '{' + i + '}';
    }
    pattern += strings[strings.length - 1];
    // Теперь pattern = "Hello, {0}!"

    let translated = translationsOject['ru'][pattern];

    return translated.replace(/\{(\d)\}/g, (s, num) => values[num]);
}

function message(lang) {
    if (lang == 'ru') return ru;

    return en;
}

const translationsOject = {
    en: {
        '#': '#',
        Chief: 'Pilot in command',
        Comment: 'Comment',
        'Crew content': 'Crew',
        Data: 'Data',
        Departure: 'Departure',
        Flight: 'Flight',
        'Flight task {0} from {1}': 'Flight task {0} from {1}',
        'Full name': 'Full name',
        Mimimum: 'Minimum',
        Landing: 'Landing',
        'Pln number': 'AC number',
        'Pln type': 'AC type',
        Ppls: 'Training program: ',
        Route: 'Route',
        'Sign place': 'sign place',
        'Table number': '#',
        'The planned route of flight': 'The planned route of flight',
        Role: 'Role',
    },
    ru: {
        '#': '№',
        Chief: 'Командир ВС',
        Comment: 'Примечание',
        'Crew content': 'Состав экипажа',
        Data: 'Дата',
        Departure: 'Отправление',
        Flight: 'Рейс',
        'Flight task {0} from {1}': 'Задание на полет {0} от {1}',
        'Full name': 'ФИО',
        Mimimum: 'Минимум',
        Landing: 'Посадка',
        'Pln number': 'Бортовой номер',
        'Pln type': 'Тип ВС',
        Ppls: 'ППЛС: ',
        Route: 'Маршрут',
        'Sign place': 'место для подписи',
        'Table number': '№',
        'The planned route of flight': 'Планируемый маршрут полета',
        Role: 'Обязанности',
    },
};

export default message;
