import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    fieldset: {
        width: '100%',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    legend: {
        paddingRight: theme.spacing(1),
        fontSize: 12,
    },
    radio: {
        margin: -theme.spacing(0.5),
    },
    radioLabel: {
        marginLeft: 0,
        marginRight: 0,
    },
}));
