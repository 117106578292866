import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
    drawerClass: {
        flexShrink: 0,
        zIndex: '999 !important',
        whiteSpace: 'nowrap',
        minWidth: '350px',
    },
    drawerOpenClass: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerCloseClass: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        display: 'none',
    },
    drawerPaper: {
        overflowX: 'hidden',
        width: 480,
        whiteSpace: 'break-spaces',

        ['@media (max-width: 720px)']: {
            width: 320,
        },
    },
}));
