import React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import { Panel } from '../Panel/Panel';
import { documentsSort } from '../../service/sort';
import { DocumentItem } from './DocumentItem/DocumentItem';
import { List, ListItem } from '../material-ui/List';
import { useStyles } from './useStyles';
import { getCurrentLeg } from '../../selectors/screen';

const DocumentsPage = () => {
    const docs = useSelector((state) => {
        const leg = getCurrentLeg(state);

        return (leg.documentIds || []).map((id) => get(state, 'documents', {})[id]);
    });

    const classes = useStyles();

    return (
        <Panel title="Documents" className={classes.content}>
            <List>
                {docs && docs.length > 0 ? (
                    docs.sort(documentsSort).map((item) => {
                        return (
                            <DocumentItem
                                key={item.id}
                                id={item.id}
                                primaryText={`${item.airport} ${item.type}`}
                                isNested={false}
                                secondaryText={
                                    <span className={classes.secondaryText}>
                                        {`${moment.utc(item.date).format('DD.MM.YYYY')}   ${item.description}`}
                                    </span>
                                }
                                download={get(item, 'syncTime')}
                                downloadPreifix="Downloaded to device at "
                            />
                        );
                    })
                ) : (
                    <ListItem primaryText="There are not any documents" disabled={true} />
                )}
            </List>
        </Panel>
    );
};

const DocumentsPageMemoized = React.memo(DocumentsPage);
export { DocumentsPageMemoized as DocumentsPage };
