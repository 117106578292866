import React from 'react';
import { useTable, useExpanded, useSortBy } from 'react-table';

import { useStyles } from './useStyles';
import { getBaggage, getDocument, getFullSSR } from '../tools';
import { Typography } from '../../material-ui/Typography';
import { TableBody, TableCell, TableHead, TableRow } from '../../material-ui/Table';
import { useColumns } from './useColumns';
import { DownArrow, UpArrow } from '../../../icons';
import { useIsFilterSet } from '../../../selectors/filter';
import { FilterTypes } from '../../../reducers/filter';

export const PassengerTableView = React.memo(({ passengerList, isMobile }) => {
    const classes = useStyles();
    const columns = useColumns(isMobile);
    const dataLength = (data && data.length) || 0;
    const pageSize = [20, 25, 50, 100, 200].filter((item) => item < dataLength);
    pageSize.push(dataLength);
    const isFilterSet = useIsFilterSet(FilterTypes.PASSENGERS);
    const data = React.useMemo(
        () => (passengerList && Object.values(passengerList).filter((item) => item.isFiltering)) || [],
        [passengerList]
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, visibleColumns } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: [
                    {
                        id: 'checkInSequence',
                        desc: false,
                    },
                ],
            },
        },
        useSortBy,
        useExpanded
    );

    // Create a function that will render our row sub components
    const renderRowSubComponent = React.useCallback(
        ({ row }) => (
            <div className={classes.passengerInfo}>
                <Typography className={classes.passengerInfoItem}>{`Document: ${getDocument(row.original || '-')}`}</Typography>
                <Typography className={classes.passengerInfoItem}>{`PNR: ${row.original.pnr || '-'}`}</Typography>
                <Typography className={classes.passengerInfoItem}>{`Ticket number: ${row.original.ticket || '-'}`}</Typography>
                <Typography className={classes.passengerInfoItem}>{`Baggage: ${getBaggage(row.original || '-')}`}</Typography>
                {row.original.ssrsList && (
                    <Typography className={classes.passengerInfoItem}>{`SSR: ${getFullSSR(row.original.ssrsList)}`}</Typography>
                )}
            </div>
        ),
        []
    );

    return (
        <table {...getTableProps()} className={classes.table}>
            <TableHead>
                {headerGroups.map((headerGroup, rowNumber) => (
                    <TableRow key={rowNumber} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, columnNumber) => {
                            const headerStyle = {
                                style: {
                                    textAlign: 'center',
                                    width: column.width,
                                    maxWidth: column.width,
                                    padding: 0,
                                },
                            };

                            return (
                                <TableCell
                                    key={columnNumber}
                                    {...column.getHeaderProps(isMobile ? headerStyle : column.getSortByToggleProps(headerStyle))}
                                    className={classes.headCell}
                                >
                                    {column.render('Header')}
                                    {!isMobile && column.isSorted ? (
                                        column.isSortedDesc ? (
                                            <UpArrow className={classes.sortedIcon} />
                                        ) : (
                                            <DownArrow className={classes.sortedIcon} />
                                        )
                                    ) : (
                                        ''
                                    )}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
                {rows.length ? (
                    rows.map((row) => {
                        prepareRow(row);

                        return (
                            <React.Fragment key={row.id}>
                                <TableRow {...row.getRowProps()} {...row.getToggleRowExpandedProps()}>
                                    {row.cells.map((column, columnNumber) => (
                                        <TableCell
                                            className={classes.cell}
                                            key={columnNumber}
                                            {...column.getCellProps({
                                                style: {
                                                    textAlign: 'center',
                                                    ...column.column.style,
                                                    width: column.column.width,
                                                    maxWidth: column.column.width,
                                                    paddingLeft: '5px',
                                                },
                                            })}
                                        >
                                            {column.render('Cell')}
                                        </TableCell>
                                    ))}
                                </TableRow>
                                {row.isExpanded && (
                                    <TableRow>
                                        <TableCell colSpan={visibleColumns.length}>{renderRowSubComponent({ row })}</TableCell>
                                    </TableRow>
                                )}
                            </React.Fragment>
                        );
                    })
                ) : (
                    <TableRow>
                        <TableCell colSpan={visibleColumns.length} className={classes.cellEmpty}>
                            There are no passengers {isFilterSet ? 'for the specified filtering conditions' : ''}
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </table>
    );
});
