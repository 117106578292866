import React from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';

import { useHideModal } from '../../actions/modal';
import { getRestoreFiles } from '../../actions/login';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../components/material-ui/Dialog';
import { useMobileWidth } from '../../components/hooks/useMobileWidth';
import { DialogControls } from '../../components/DialogControls/DialogControls';
import { useStyles } from './useStyles';
import { getBaggage, getDocument, getFullName, getGenderType, getFullSSR } from '../../components/PassengerPage/tools';
import { Typography } from '../../components/material-ui/Typography';

export const PASSENGER = 'PASSENGER';

export const Passenger = React.forwardRef((props, ref) => {
    const { data } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isMobilePortrait } = useMobileWidth();
    const hideModal = useHideModal();

    React.useEffect(() => {
        // TODO показывать ошибку при загрузке списка
        dispatch(getRestoreFiles());
    }, []);

    const actions = React.useMemo(() => [{ label: 'OK', color: 'primary', onClick: hideModal }], []);
    const isSeatEmpty = !get(data, 'passenger');
    const passenger = get(data, 'passenger', {});
    const { apToName, apLaName, pnr, seats, ticket, gender, ssrsList } = passenger;
    const fullName = getFullName(passenger);
    const baggage = getBaggage(passenger);
    const document = getDocument(passenger);
    const ssr = ssrsList && getFullSSR(ssrsList);
    const route = apToName && apLaName && `${apToName} - ${apLaName}`;

    return (
        <Dialog ref={ref} open={true} fullScreen={isMobilePortrait}>
            <DialogTitle>
                {isSeatEmpty && 'Place is empty'}
                {!isSeatEmpty && fullName && `${fullName.toUpperCase()} ${getGenderType(gender)}`}
            </DialogTitle>
            <DialogContent className={classes.content}>
                {seats && (
                    <Typography className={classes.item}>
                        Seat:
                        <span className={classes.value}>{seats}</span>
                    </Typography>
                )}
                {passenger.class && (
                    <Typography className={classes.item}>
                        Class:
                        <span className={classes.value}>{passenger.class}</span>
                    </Typography>
                )}
                {passenger.type && (
                    <Typography className={classes.item}>
                        Type:
                        <span className={classes.value}>{passenger.type}</span>
                    </Typography>
                )}
                {passenger.checkInSequence && (
                    <Typography className={classes.item}>
                        Sequence number:
                        <span className={classes.value}>{passenger.checkInSequence}</span>
                    </Typography>
                )}
                {ticket && (
                    <Typography className={classes.item}>
                        Ticket:
                        <span className={classes.value}>{ticket}</span>
                    </Typography>
                )}
                {pnr && (
                    <Typography className={classes.item}>
                        PNR:
                        <span className={classes.value}>{pnr}</span>
                    </Typography>
                )}
                {document && (
                    <Typography className={classes.item}>
                        Document:
                        <span className={classes.value}>{document}</span>
                    </Typography>
                )}
                {route && (
                    <Typography className={classes.item}>
                        Route:
                        <span className={classes.value}>{route}</span>
                    </Typography>
                )}
                {baggage && (
                    <Typography className={classes.item}>
                        Baggage:
                        <span className={classes.value}>{baggage}</span>
                    </Typography>
                )}
                {ssr && (
                    <Typography className={classes.item}>
                        SSR:
                        <span className={classes.value}>{ssr}</span>
                    </Typography>
                )}
            </DialogContent>
            <DialogActions className={classes.actions}>
                <DialogControls actions={actions} />
            </DialogActions>
        </Dialog>
    );
});
