import { UPDATE_USERS } from '../actions/users';
import { LOGOUT } from '../actions/screen';

const initialState = { list: {} };

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_USERS:
            return {
                ...state,
                syncTime: action.syncTime,
                list: Object.assign({}, state.list, action.users),
            };

        case LOGOUT:
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
