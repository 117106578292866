export const findDeclarationFields = (fields, declarations) => {
    const res = {};
    declarations.forEach((item) => {
        (item.items || []).forEach((cell) => {
            const isRequiredToFind = fields.some((item) => cell && cell.id === item);
            if (isRequiredToFind) {
                res[cell.id] = cell.compareWith;
            }
        });
    });

    return res;
};

export const getPointStyle = ({ columnsDeclaration, point, pos, directFromPos, multieditFrom, multieditTo, classes, current }) => {
    const { act: actCompare } = findDeclarationFields(['act'], columnsDeclaration);
    const { act, isRemoveFromDirecting } = point || {};
    const est = point[actCompare] || point['est'];

    if (isRemoveFromDirecting) {
        return classes.pointDirected;
    }

    if (pos === directFromPos) {
        return classes.pointDirect;
    }

    if ((multieditTo !== undefined && pos >= multieditFrom && pos <= multieditTo) || pos === multieditFrom) {
        return classes.pointEdit;
    }

    if (current) {
        return classes.pointCurrent;
    }

    // подсвечиваем только те точки, у которых товливо отличается более чем на 100 кг
    if (act && Math.abs(act - est) > 0.1) {
        return classes.pointWarning;
    }

    return '';
};
