import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import { Typography } from '../material-ui/Typography';
import { getCurrentTaskNumber, getCurrentTaskDate } from '../../selectors/screen';

const routesWithoutFlightTask = ['reports', 'library'];

const AppBarTitle = () => {
    const route = useSelector((state) => get(state, 'router.location.pathname'));
    const taskNumber = useSelector(getCurrentTaskNumber);
    const taskDate = useSelector(getCurrentTaskDate);
    const isAppBarWithoutTaskInfo = routesWithoutFlightTask.indexOf(route.substring(1)) + 1;
    const showText = isAppBarWithoutTaskInfo && true;
    const text = (isAppBarWithoutTaskInfo && route[1] && route[2] && `${route[1].toUpperCase()}${route.slice(2)}`) || '';

    return (
        <Typography variant="h6" style={{ flexGrow: 1 }} color="inherit">
            {showText ? (
                text
            ) : taskNumber ? (
                <>
                    <span className="app-bar__title-start">{'Flight task '}</span>
                    <span>{taskNumber}</span>
                    <span className="app-bar__title-date">{` (${taskDate})`}</span>
                </>
            ) : (
                'Flight tasks'
            )}
        </Typography>
    );
};

const AppBarTitleMemoized = React.memo(AppBarTitle);

export { AppBarTitleMemoized as AppBarTitle };
