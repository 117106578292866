import BackendRequestsFacade from './BackendRequestsFacade.js';
import { NetworkError } from './errors/NetworkError';
import { ApiError } from './errors/ApiError';
import { OtherError } from './errors/OtherError';
import { Method } from './constants';
import errorLogsSender from './ErrorLogsSender';

const CHECK_AUTH_FAILED = 'CHECK_AUTH_FAILED';

export const handleCatchError = (err, path, method, data) => {
    let error = err;
    if (error.isFetchError) {
        // Either a Network Error or an API Error
        if (error.response) {
            // Must be an API Error since the server sent a response
            console.error(error, error.data);
        } else {
            // Must be a Network Error since the server did not send a response
            console.error(error);
        }
    } else if (!error.isFetchError) {
        // Must be a JS Syntax Error since our custom property was not attached.
        error = new OtherError({
            data,
            path,
            method,
            message: err.message,
            code: err.status,
            stack: err.stack,
            info: err.info,
        });
        console.error(error);
    }

    errorLogsSender.addLog({
        type: error.type,
        status: error.errorCode,
        stack: error.stack,
        message: error.message,
        text: error.text,
        path: error.path,
        data: error.data,
        method: error.method,
    });

    throw error;
};

const getText = async (res) => await res.clone().text();
export const handleResponse = async (res, path, method, data) => {
    if (!res.ok) {
        const text = await getText(res);
        try {
            const parsedText = await JSON.parse(text);
            if (parsedText && res.code === CHECK_AUTH_FAILED) {
                return res;
            }
        } catch (e) {
            console.error(e);
        }
        throw new ApiError({
            data,
            res,
            method,
            info: res.info,
            text: text,
            message: res.statusText,
            code: res.status,
            stack: res.stack,
            path: res.url || path,
        });
    }

    return res;
};

export const handleReject = async (err, path, method, data) => {
    err.isFetchError = true;
    throw new NetworkError({ message: err.message, code: err.errorCode, stack: err.stack, data, path, method });
};

class BackendRequestsFacadeViaFetch extends BackendRequestsFacade {
    /**
     * Get запрос
     */
    get(path) {
        return fetch(this._getUrl(path), { credentials: 'include' })
            .then(
                (res) => handleResponse(res, path, Method.GET),
                (err) => handleReject(err, path, Method.GET)
            )
            .then((res) => res.json())
            .catch((err) => handleCatchError(err, path, Method.GET));
    }

    /**
     * Get запрос
     */
    getFile(path) {
        return fetch(this._getUrl(path), { credentials: 'include' })
            .then(
                (res) => handleResponse(res, path, Method.GET),
                (err) => handleReject(err, path, Method.GET)
            )
            .then((res) => res.arrayBuffer())
            .then((data) => new Blob([data]))
            .catch((err) => handleCatchError(err, path, Method.GET));
    }

    /**
     * Post запрос
     */
    post(path, data) {
        return fetch(this._getUrl(path), {
            body: JSON.stringify(data),
            method: Method.POST,
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(
                (res) => handleResponse(res, path, Method.POST, data),
                (err) => handleReject(err, path, Method.POST, data)
            )
            .then((res) => res.json())
            .catch((err) => handleCatchError(err, path, Method.POST, data));
    }

    /**
     * Delete запрос
     */
    delete(path, data) {
        return fetch(this._getUrl(path), {
            body: JSON.stringify(data),
            method: Method.DELETE,
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(
                (res) => handleResponse(res, path, Method.DELETE, data),
                (err) => handleReject(err, path, Method.DELETE, data)
            )
            .then((res) => res.json())
            .catch((err) => handleCatchError(err, path, Method.DELETE, data));
    }
}

export default BackendRequestsFacadeViaFetch;
