import { showConfirmDialog } from './messageDialog';
import {
    calcHoldTime,
    calculateDistance,
    findEndPoint,
    findNextPoint,
    findPrevPointPos,
    recalculatePointsAfterAddHold,
    recalculatePointsAfterUpdate,
    recalculatePointsAfterUpdateHold,
} from '../service/NavPointsUtils';
import { get } from 'lodash';
import { initialLeg } from '../reducers/sended-data';
import { showModal, TYPES } from './modal';
import { SelectionType } from '../modals/Setting/GpsSetting/GpsRouteSelectionType';
import { NavType } from '../components/NavigationRoutes/constants';
import { PointConfig, PointFields } from '../modals/Point/tools';
import { FieldTypes } from '../components/hooks/useForm/validate';
import { getCurrentSendedLeg } from '../selectors/sended-data';

const SAVE_POINT_DATA = 'SAVE_POINT_DATA';
const SAVE_SPECIAL_POINT_DATA = 'SAVE_SPECIAL_POINT_DATA';
const SAVE_COMMENT_DATA = 'SAVE_COMMENT_DATA';
const SAVE_CHECK_ALTITUDE_DATA = 'SAVE_CHECK_ALTITUDE_DATA';
const SAVE_HOLD_DATA = 'SAVE_HOLD_DATA';
const DIRECT = 'DIRECT';
const CANCEL_DIRECTION = 'CANCEL_DIRECTION';
const REMOVE_ENTRY = 'REMOVE_ENTRY';
const REVERT_DIRECTION = 'REVERT_DIRECTION';
const SET_FUEL_WARNING = 'SET_FUEL_WARNING';
const SET_POINTS = 'SET_POINTS';
const SELECT_ALTERNATE_ROUTES = 'SELECT_ALTERNATE_ROUTES';
const CHECK_FUEL_INFO = 'CHECK_FUEL_INFO';
const MULTIEDIT_FROM = 'MULTIEDIT_FROM';
const MULTIEDIT_TO = 'MULTIEDIT_TO';
const MULTIEDIT_POINTS = 'MULTIEDIT_POINTS';
const CANCEL_MULTIEDIT = 'CANCEL_MULTIEDIT';

const FuelWarningType = {
    MAY_DAY: 1,
    MIN_FUEL: 2,
};

function getPointUpdates(state, pointData) {
    const { currentLeg } = state.screen;
    const data = get(state, `sendedData[${currentLeg}]`);
    const endPoint = findEndPoint(data.points);
    const isLastPoint = endPoint.pointNumber <= pointData.pointNumber;
    const point = isLastPoint ? pointData : endPoint;

    const remainFuel = Math.round((point.act ? +point.act : +point.est) * 1000);
    const takeOff = get(state.sendedData[currentLeg], 'takeOff', {});
    const tow = get(takeOff, 'informationForm.tow', 0);
    const { totalOnBoard = 0, expenseLandTakeoff = 0, expenseBeforeTakeoff = 0 } = takeOff;
    const tof = +totalOnBoard - +expenseLandTakeoff - +expenseBeforeTakeoff;
    const lw = Math.round((+tow - tof + remainFuel) * 100) / 100;
    // Для всех точек обновляем lw, а для последней - обновляем топливо
    if (!isLastPoint) {
        return {
            lw,
        };
    }

    return {
        lw,
        remainFuel,
    };
}

function addPoint(pointList, { data, pointPos, fuelFlowAvg, newFlightLevel }) {
    localStorage.setItem('beforeTest', JSON.stringify(pointList));
    let points = [].concat(pointList);
    const newPointPos = pointPos + 1;
    points.splice(newPointPos, 0, data);
    if (data.type !== NavType.COMMENT && data.type !== NavType.ALTITUDE) {
        Object.keys(points).forEach((key) => {
            const point = points[key];
            if (key > pointPos && !point.routeNumber) {
                point.pointNumber += 1;
            }
        });

        if (data.type !== NavType.HOLD) {
            points = recalculatePointsAfterUpdate(points, newPointPos, fuelFlowAvg, newFlightLevel);
        } else {
            points = recalculatePointsAfterAddHold(points, newPointPos);
        }
    }

    return points;
}

function updatePoint(pointList, { data, pointPos, fuelFlowAvg, newFlightLevel }) {
    // localStorage.setItem('beforeTest', JSON.stringify(pointList));
    let points = [].concat(pointList);
    points[pointPos] = { ...points[pointPos], ...data };
    if (data.type === NavType.HOLD) {
        points = recalculatePointsAfterUpdateHold(points, pointPos, data);
        points[pointPos] = { ...points[pointPos], ...data };
    } else {
        points[pointPos] = { ...points[pointPos], ...data };
        if (data.type !== NavType.COMMENT && data.type !== NavType.ALTITUDE) {
            points = recalculatePointsAfterUpdate(points, pointPos, fuelFlowAvg, newFlightLevel);
        }
    }
    // localStorage.setItem('afterTest', JSON.stringify(points));
    return points;
}

function getFuelFlowAvgFromState(state) {
    const { currentTask, currentLeg, currentFlight } = state.screen;
    const currentOfp = get(state, `sendedData[${currentLeg}].ofpId`);

    return get(state.tasks, `list[${currentTask}].flights[${currentFlight}].legs[${currentLeg}].ofps[${currentOfp}].fuelFlowAvg`);
}

function setCurrentPosition(selectionType, leg, data) {
    let currentPosition = get(data, 'pointNumber');
    if (selectionType === SelectionType.NEXT) {
        const nextPoint = findNextPoint(leg.points, data) || {};
        currentPosition = nextPoint.pointNumber || leg.currentPosition;
    }

    return currentPosition;
}

function setPostPointUpdate(leg, state, data) {
    const { lw, remainFuel } = getPointUpdates(state, data);

    if (!leg.landing.informationForm) {
        leg.landing.informationForm = {};
    }
    if (lw) {
        leg.landing.informationForm.lw = lw;
    }

    leg.landing.distance = calculateDistance(leg.points);

    if (remainFuel) {
        const { expenseAfterLanding = 0, expenseLandLanding = 0 } = leg.landing;
        leg.landing.remainLanding = remainFuel - expenseAfterLanding - expenseLandLanding;
    }

    // Calculate total HOLD time
    if (data.type === NavType.HOLD) {
        leg.landing.timeWaitingZone = calcHoldTime(leg.points.filter((item) => item.type === NavType.HOLD));
    }

    return leg;
}

function saveNavRouteData(state, action, isNewPoint) {
    const {
        currentLeg,
        currentUser: { id: userId },
    } = state.screen;
    const selectionType = get(state, 'routesDeclaration.selectionType');
    const fuelFlowAvg = getFuelFlowAvgFromState(state);
    const newState = { ...state.sendedData };
    let leg = initialLeg(newState, { legId: currentLeg, type: action.type });

    Object.values(PointFields || {}).forEach((field) => {
        const dataField = action.data[field];
        if (dataField && PointConfig[field] && PointConfig[field].type === FieldTypes.NUMBER) {
            action.data[field] = +dataField;
        }
    });

    // если у точки должно быть FlightLevel и он изменился в текущей точке, то меняем и в следующей
    const previousFlightLevel = get(leg, `points[${action.pointPos}].fl`);
    const newFlightLevel = action.data.fl_default && action.data.fl && action.data.fl !== previousFlightLevel && action.data.fl;

    if (isNewPoint) {
        leg.points = addPoint(leg.points, {
            data: action.data,
            pointPos: action.pointPos,
            fuelFlowAvg,
            newFlightLevel,
            userId,
        });
    } else {
        leg.points = updatePoint(leg.points, {
            data: action.data,
            pointPos: action.pointPos,
            fuelFlowAvg,
            newFlightLevel,
            userId,
        });

        leg.currentPosition = setCurrentPosition(selectionType, leg, action.data);
    }

    newState[currentLeg] = setPostPointUpdate(leg, state, action.data);

    return newState;
}

function savePointData(pointPos, data) {
    return (dispatch, getState) => {
        const state = getState();
        const newState = saveNavRouteData(state, {
            type: SAVE_POINT_DATA,
            pointPos,
            data,
        });

        return dispatch({
            type: SAVE_POINT_DATA,
            newState,
        });
    };
}

function saveSpecialPointData(pointPos, data, isNewPoint) {
    return (dispatch, getState) => {
        const state = getState();
        const newState = saveNavRouteData(
            state,
            {
                pointPos,
                data,
            },
            isNewPoint
        );

        return dispatch({
            type: SAVE_SPECIAL_POINT_DATA,
            newState,
        });
    };
}

function saveCommentData(pointPos, data, isNewPoint) {
    return (dispatch, getState) => {
        const state = getState();
        const newState = saveNavRouteData(
            state,
            {
                pointPos,
                data,
            },
            isNewPoint
        );

        return dispatch({
            type: SAVE_COMMENT_DATA,
            newState,
        });
    };
}

function saveCheckAltitudeData(pointPos, data, isNewPoint) {
    return (dispatch, getState) => {
        const state = getState();
        const newState = saveNavRouteData(
            state,
            {
                pointPos,
                data,
            },
            isNewPoint
        );

        return dispatch({
            type: SAVE_CHECK_ALTITUDE_DATA,
            newState,
        });
    };
}

function saveHoldData(pointPos, data, isNewPoint) {
    return (dispatch, getState) => {
        const state = getState();
        const newState = saveNavRouteData(
            state,
            {
                pointPos,
                data,
            },
            isNewPoint
        );

        return dispatch({
            type: SAVE_HOLD_DATA,
            newState,
        });
    };
}

function multieditFrom(pointPos) {
    return { type: MULTIEDIT_FROM, pointPos };
}

function multieditTo(pointPos) {
    return { type: MULTIEDIT_TO, pointPos };
}

function multieditPoints(data) {
    return (dispatch, getState) => {
        const {
            navigation: { multieditFrom, multieditTo },
            screen: { currentLeg, currentUser },
        } = getState();

        return dispatch({
            type: MULTIEDIT_POINTS,
            multieditFrom,
            multieditTo,
            data,
            legId: currentLeg,
            userId: currentUser.id,
        });
    };
}

function cancelMultiedit() {
    return { type: CANCEL_MULTIEDIT };
}

function directTo(pointPosFrom, pointPosTo, editMode) {
    return (dispatch, getState) => {
        const state = getState();
        const legId = state.screen.currentLeg;
        const { currentTask, currentLeg, currentFlight, currentUser } = state.screen;
        const currentOfp = get(state, `sendedData[${currentLeg}].ofpId`);
        const { fuelFlowAvg } = get(
            state.tasks,
            `list[${currentTask}].flights[${currentFlight}].legs[${currentLeg}].ofps[${currentOfp}]`,
            {}
        );

        dispatch({
            type: DIRECT,
            pointPosFrom,
            pointPosTo,
            editMode,
            legId,
            fuelFlowAvg,
            userId: currentUser.id,
        });
    };
}

function revertDirection(pointPosFrom) {
    return (dispatch, getState) => {
        const state = getState();
        const legId = state.screen.currentLeg;
        const userId = state.screen.currentUser.id;

        let msg = {
            title: 'Confirmation dialog',
            message:
                'Do you really want to perform cancel operation "Direct to" from the current point? All removed points will be restored.',
        };

        dispatch(showConfirmDialog(msg)).then((isOk) => {
            if (isOk) {
                dispatch({
                    type: REVERT_DIRECTION,
                    pointPosFrom,
                    legId,
                    userId,
                });
                dispatch(onCheckFuelInfo());
            }
        });
    };
}

function cancelDirection() {
    return { type: CANCEL_DIRECTION };
}

function removeEntry(pointPos) {
    return (dispatch, getState) => {
        let msg = {
            title: 'Confirmation dialog',
            message: 'Are you sure you want to remove the point?',
        };
        const state = getState();
        const { currentTask, currentLeg, currentFlight, currentUser } = state.screen;
        const currentOfp = get(state, `sendedData[${currentLeg}].ofpId`);
        const { fuelFlowAvg } = get(
            state.tasks,
            `list[${currentTask}].flights[${currentFlight}].legs[${currentLeg}].ofps[${currentOfp}]`,
            {}
        );

        return dispatch(showConfirmDialog(msg)).then((isOk) => {
            if (isOk) {
                dispatch({
                    type: REMOVE_ENTRY,
                    pointPos,
                    fuelFlowAvg,
                    legId: getState().screen.currentLeg,
                    userId: currentUser.id,
                });
                dispatch(onCheckFuelInfo());
            }
        });
    };
}

function setPoints(legId, points, userId) {
    return { type: SET_POINTS, legId, points, userId };
}

function selectAlternateRoutes(route, pos) {
    return (dispatch, getState) => {
        const state = getState();
        const { currentTask, currentLeg, currentFlight, currentUser } = state.screen;
        const currentOfp = get(state, `sendedData[${currentLeg}].ofpId`);
        const { fuelFlowAvg } = get(
            state.tasks,
            `list[${currentTask}].flights[${currentFlight}].legs[${currentLeg}].ofps[${currentOfp}]`,
            {}
        );

        let msg = {
            title: 'Confirmation dialog',
            message: `Are you sure you want to select an alternate route #${route}?`,
        };

        dispatch(showConfirmDialog(msg)).then((isOk) => {
            if (isOk) {
                dispatch({
                    type: SELECT_ALTERNATE_ROUTES,
                    route,
                    pos,
                    legId: currentLeg,
                    userId: currentUser.id,
                    fuelFlowAvg,
                });
            }
        });
    };
}

function setFuelWarning(warningType) {
    return (dispatch, getState) => {
        const state = getState();
        const leg = getCurrentSendedLeg(state);

        if (leg.warningType === warningType) {
            return;
        }

        dispatch({
            type: SET_FUEL_WARNING,
            legId: state.screen.currentLeg,
            btnCancelVisibility: false,
            warningType,
        });

        if (warningType === 1) {
            dispatch(
                showConfirmDialog({
                    title: 'MAYDAY, MAYDAY, MAYDAY, FUEL!!!',
                    message: 'You should ensure that the aircraft can land as soon as possible',
                    btnCancelVisibility: false,
                })
            );
        }
        if (warningType === 2) {
            dispatch(
                showConfirmDialog({
                    title: 'Minimum Fuel',
                    message: 'Please ensure the protection of final fuel reserve and safe flight completion',
                    btnCancelVisibility: false,
                })
            );
        }
    };
}

function onCheckFuelInfo() {
    return (dispatch, getState) => {
        const state = getState();
        const { currentTask, currentLeg, currentFlight } = state.screen;
        const data = get(state, `sendedData[${currentLeg}]`);
        const { minFuelMAPt = 0, holdALTN = 0 } = get(
            state.tasks,
            `list[${currentTask}].flights[${currentFlight}].legs[${currentLeg}].ofps[${data.ofpId}]`,
            {}
        );

        const endPoint = findEndPoint(data.points);
        const remainFuel = Math.round((endPoint.act ? +endPoint.act : +endPoint.est) * 1000);

        setTimeout(() => {
            if (remainFuel <= holdALTN) {
                dispatch(setFuelWarning(FuelWarningType.MAY_DAY));
            } else if (remainFuel <= minFuelMAPt) {
                dispatch(setFuelWarning(FuelWarningType.MIN_FUEL));
            } else {
                dispatch(setFuelWarning());
            }
        }, 1000);
    };
}

function showSpecialPointModal(props) {
    return (dispatch, getState) => {
        const modalProps = { ...props };
        const state = getState();
        const { point, isNewPoint, pos } = props;
        let newPoint;
        if (isNewPoint && (point.type === NavType.HOLD || point.type === NavType.COMMENT || point.type === NavType.ALTITUDE)) {
            const {
                screen: { currentLeg },
                sendedData,
            } = state;
            const points = get(sendedData[currentLeg], 'points', []);
            newPoint = points[findPrevPointPos(points, pos)];
            modalProps.point = newPoint;
        }

        return dispatch(showModal(TYPES.SPECIAL_POINT, modalProps));
    };
}

export {
    SAVE_POINT_DATA,
    SAVE_SPECIAL_POINT_DATA,
    SAVE_COMMENT_DATA,
    SAVE_CHECK_ALTITUDE_DATA,
    SAVE_HOLD_DATA,
    SET_FUEL_WARNING,
    DIRECT,
    CANCEL_DIRECTION,
    CANCEL_MULTIEDIT,
    REMOVE_ENTRY,
    REVERT_DIRECTION,
    SET_POINTS,
    SELECT_ALTERNATE_ROUTES,
    CHECK_FUEL_INFO,
    MULTIEDIT_FROM,
    MULTIEDIT_TO,
    MULTIEDIT_POINTS,
    savePointData,
    saveSpecialPointData,
    saveCommentData,
    saveCheckAltitudeData,
    saveHoldData,
    directTo,
    multieditFrom,
    multieditTo,
    multieditPoints,
    cancelMultiedit,
    cancelDirection,
    removeEntry,
    revertDirection,
    setPoints,
    selectAlternateRoutes,
    onCheckFuelInfo,
    showSpecialPointModal,
    addPoint,
    updatePoint,
};
