import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
    panel: {
        '& .panel__toolbar': {
            padding: '0 16px !important',
        },
    },
    wrapper: {
        position: 'relative',
        width: '100%',
        paddingTop: 0,
    },
}));
