import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    const {
        table: { secondaryColor },
    } = theme;

    return {
        cell: {
            paddingTop: 8,
            paddingBottom: 8,
        },
        comment: {
            padding: '5px 10px 10px 30px',
            textAlign: 'left',
            whiteSpace: 'pre-line',
        },
        row: {
            cursor: 'pointer',
            backgroundColor: theme.palette.background.paper,
        },
        highlightRow: {
            background: secondaryColor,
        },
    };
});
