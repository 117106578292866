import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentPoints } from '../../selectors/sended-data';
import { useWidth } from '../hooks/useWidth';
import { get } from 'lodash';

const useCalcHeaderWidhts = (onChangeHeaderWidth) => {
    const width = useWidth();
    const points = useSelector(getCurrentPoints);
    const mobileEnrouteMode = useSelector((state) => get(state, 'screen.mobileEnrouteMode', false));
    const ref = React.useRef(null);

    React.useEffect(() => {
        if (ref && ref.current && onChangeHeaderWidth) {
            const items = Array.from(ref.current.querySelectorAll('.enr__td')).reduce((accum, item) => {
                accum[item.getAttribute('data-column-name')] = item.offsetWidth;

                return accum;
            }, {});

            onChangeHeaderWidth(items);
        }
    }, [width, points, mobileEnrouteMode]);

    return { ref };
};

export { useCalcHeaderWidhts };
