import { makeStyles } from '@material-ui/core/styles';

const useStyles = (needUpdate, hasUpdates) =>
    makeStyles((theme) => {
        const { requiredColor, changedColor } = theme.library;

        return {
            listStyle: {
                fontSize: '18px',
                backgroundColor: needUpdate ? requiredColor : hasUpdates ? changedColor : 'transparent',
            },
            text: {
                paddingRight: 50,
            },
            menu: {
                right: '8px',
            },
        };
    });

export { useStyles };
