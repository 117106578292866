import { createSelector } from 'reselect';
import { get } from 'lodash';

const getConfig = createSelector(
    (state) => get(state, 'login.config', {}),
    (config) => config
);

const getSystemOfMeasurementOption = createSelector(getConfig, (config) => {
    return get(config, 'enabledSystemOfMeasurement', false) && get(config, 'enabledSystemOfMeasurement', false);
});

export { getConfig, getSystemOfMeasurementOption };
