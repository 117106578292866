import * as React from 'react';
import { Route, Redirect } from 'react-router';

const PrivateRoute = ({ component: Component, auth: isAuthenticated = true, ...rest }) => {
    const renderFunction = (props) =>
        isAuthenticated ? (
            <Component {...props} />
        ) : (
            <Redirect
                to={{
                    pathname: '/login',
                    state: { from: props.location },
                }}
            />
        );

    return <Route {...rest} render={renderFunction} />;
};

export { PrivateRoute };
