const OFP_MODEL = {
    id: 'ID',
    name: 'OFP name',
    telegramId: 'ID of CFP',
    date: 'OFP created date',
    fuelFlowAvg: 'AVG fuel flow',
    minFuelMAPt: 'Min fuel MAPt (minFuelMAPt)',
    holdALTN: 'Hold alternate',
    distanceEstimate: 'Estimate distance',
    tripFuel: 'Trip fuel',
    taxiFuel: 'Taxi fuel',
    onBoardFuel: 'On board fuel',
    extraFuel: 'Extra fuel',
    tankeringFuel: 'Tankering fuel',
    tripDistance: 'Trip distance',

    dryOperatingWeight: 'Dry operating weight (DOW)',
    payLoadEstimate: 'Estimate pay load (PYLD)',
    payLoadReal: 'Real pay load (PYLD)',
    zeroFuelWeightEstimate: 'Estimate zero fuel weight (EZFW)',
    zeroFuelWeightReal: 'Real zero fuel weight (ZFW)',
    zeroFuelWeightMax: 'Max zero fuel weight (EZFW)',
    takeOffWeightEstimate: 'Estimate take off weight (ETOW)',
    takeOffWeightReal: 'Real take off weight (TOW)',
    takeOffWeightMax: 'Max take off weight (MTOW)',
    extraCrewWeight: 'Extra crew weight',
    landingWeightEstimate: 'Estimate landing weight (ELW)',
    landingWeightReal: 'Real landing weight (LW)',
    landingWeightMax: 'Max landing weight (MLW)',
    weightUnits: 'Units of weight: KG or LBS',
    maxLoading: 'Max loading',
    componovka: 'Componovka',
    maczfw: 'Mean Aerodynamic Chord Zero Fuel',
    domac: 'Mean Aerodynamic Chord for DRY',

    plnID: 'Aircraft name id',

    comment: 'comment',
    navPoints: 'Navigation points',
};

const PASSENGER_MODEL = {
    id: 'ID',
    apToID: 'Airport TakeOff ID',
    apToName: 'Airport TakeOff name',
    apToCode: 'Airport TakeOff code',
    apLaID: 'Airport Laneing ID',
    apLaName: 'Airport Laneing name',
    apLaCode: 'Airport Laneing code',
    name: 'Name',
    patronymic: 'Patronymic',
    surname: 'Surname',
    doc: 'Document number',
    docExpired: 'Date of document expired',
    gender: 'Gender',
    type: 'Passenger Type',
    class: 'Fare Class Type',
    seats: 'Seats',
    pnr: 'PNR',
    ticket: 'Ticket number',
    checkInSequence: 'CheckIn sequence',
    baggagePieces: 'Baggage pieces',
    baggageWeight: 'Baggage weight',
    ssrsList: 'SSRS List',
};

// const CREW_MODEL = {
//     id: 'ID',
//     crewId: 'Personnel ID',
//     tableNumber: 'Table number',
//     fullName: 'Full personnel name',
//     fullNameEng: 'Full personnel name eng',
//     staffTree: 'Staff tree',
//     staffTreeEng: 'Staff tree eng',
//     phone: 'Phone',
//     email: 'Email',
//     chair: 'Chair',
//     chairEng: 'Chair eng',
//     type: 'Crew type',
//     orderNumber: 'Crew order number',
//     chairRole: 'Chair role',
//     chairRoleEng: 'Chair role eng',
//     ppls: 'PPLS',
//     pplsEng: 'PPLS eng',
//     pplsCode: 'PPLS code',
//     pplsCodeEng: 'PPLS code eng',
//     before: 'Standard time before flight',
//     after: 'Standard time after flight',
//     percent: 'Percent of work time',
//     comment: 'Additional information',
//     photo: {
//         name: 'Photo content',
//         type: 'image'
//     }
// };

const LEGS_MODEL = {
    id: 'ID',
    defectId: 'ID of defect document',
    takeOffDate: 'Take Off date',
    airportTakeOff: 'Airport Take Off',
    airportLanding: 'Airport Landing',
    prePlaneComponovkaInfo: 'Operation plane componovka info',
    prePlaneInfo: 'Maximum take off weight',
    preFuelInfo: 'Operation fuel info',
    preFuelInfoReal: 'Fact fuel info',
    prePassengerInfo: 'Operation passenger info',
    preLoadInfo: 'Operation load info',
    prePassengerFirstLAInfo: 'Operation passenger info to the first point',
    preLoadFirstLAInfo: 'Operation load info to the first point',
    business: 'Business passenger count',
    adt: 'Adult count',
    chd: 'Children count',
    inf: 'Infant count',
    baggagePay: 'Baggage pay',
    mail: 'Mail',
    cargo: 'Cargo',

    sta_local: 'STA local',
    std_local: 'STD local',
    eta_local: 'ETA local',
    etd_local: 'ETD local',
    sta: 'STA',
    std: 'STD',
    eta: 'ETA',
    etd: 'ETD',
    alt_local: 'ALT local',
    atot_local: 'ATOT local',
    alt: 'ALT',
    atot: 'ATOT',
    ata: 'ATA',
    atd: 'ATD',
    ata_local: 'ATA local',
    atd_local: 'ATD local',
    stand: 'Stand name',
    delayLandingCode: 'Landing delay code',
    delayTakeOffCode: 'TakeOff delay code',
    delayLanding: 'Landing delay time',
    delayArrive: 'Arrive delay time',
    delayTakeOff: 'TakeOff delay time',
    delayDepart: 'Depart delay time',
    airportNumber: 'Airport order number',
    plnName: 'Pln number',
    plnID: 'Aircraft name id',
    plnChanged: 'Sign about aircraft number change',

    documents: {
        name: 'Flight documents',
        type: 'documents',
    },
    defects: {
        name: 'Defect document',
        type: 'defects',
    },
    ofps: {
        name: 'Flight leg ofps',
        type: 'model-list',
        model: OFP_MODEL,
    },
    telegrams: {
        name: 'Flight leg telegrams',
        type: 'telegrams',
    },
    telex: {
        name: 'Flight leg telex',
        type: 'telex',
    },
    routeAirports: {
        name: 'Airports on route',
        type: 'routeAirports',
    },
    crew: {
        name: 'Flight crew',
        type: 'crew',
    },
    sendedData: {
        name: 'Sended data',
        type: 'sended-data',
    },
    passengerList: {
        name: 'Passenger list',
        type: 'model-list',
        model: PASSENGER_MODEL,
    },
    plnCabin: {
        name: 'Pln cabin layout',
        type: 'plnCabin',
    },
    checkList: {
        name: 'Check list',
        type: 'checkList',
    },
};

const FLIGHT_MODEL = {
    id: 'ID',
    num: 'Flight number',
    date: 'Flight date',
    route: 'Route',
    pln: 'Aircraft info',
    plnID: 'Aircraft name id',
    flightMeanType: 'Flight mean type',
    flightType: 'Flight type',
    operator: 'Operator',
    operatorEng: 'OperatorEng',
    flightCommunicationType: 'Flight communication type',
    passengersMax: 'Passengers max',
    statusName: 'Status name',
    statusCode: 'Status code',
    markCodeCompany: 'Mark code company',
    markFlightNumber: 'Mark flight number',
    cancel: 'Cancel flight',
    legs: {
        name: 'Flights legs',
        type: 'model-list',
        model: LEGS_MODEL,
    },
};

const TASK_MODEL = {
    id: 'ID',
    flightTask: 'Flight task identifier',
    taskDate: 'Flight task date',
    userId: 'Last change by this User',
    flights: {
        name: 'Flights',
        type: 'model-list',
        model: FLIGHT_MODEL,
    },
    personnelFlights: 'Personnel flights',
    signature: {
        name: 'Flight task signature',
        type: 'signature',
    },
    users: 'Users',
    crewType: 'Crew type',
};

export { TASK_MODEL, FLIGHT_MODEL, LEGS_MODEL, OFP_MODEL };
