import { makeStyles } from '@material-ui/styles';

import { grey750 } from '../ThemeProvider';

export const useStyles = makeStyles((theme) => ({
    content: {
        flex: '1 0 auto',
    },
    toolbar: {
        height: 'auto',
        minHeight: '48px',
        background: theme.palette.type === 'light' ? theme.palette.grey['300'] : grey750,
    },
    toolbarTitle: {
        fontSize: '18px',
        paddingRight: 0,
        verticalAlign: 'middle',
        color: theme.palette.type === 'light' ? theme.palette.grey['700'] : theme.palette.accent3Color,
    },
}));
