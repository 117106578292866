export const resizeSignature = (signature) => {
    if (!signature) {
        return;
    }
    const { canvas } = signature;
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);

    signature.clear();
};
