import React from 'react';

import { Button } from '../material-ui/Button';
import { useStyles } from './useStyles';

const DialogControls = ({ actions = [], itemStyle = {}, appBar = false }) => {
    const classes = useStyles();

    return (
        <>
            {actions.length > 0 &&
                actions.map(({ label, primary, color, variant = 'contained', disabled = false, ...props }, index) => (
                    <Button
                        key={index}
                        variant={variant}
                        {...(appBar && {
                            classes: {
                                text: classes.text,
                                label: classes.label,
                            },
                        })}
                        {...props}
                        // TODO убрать primary после перехода на material v1
                        color={color || primary ? 'primary' : 'default'}
                        className={index !== actions.length - 1 ? classes.buttonLeft : classes.button}
                        style={itemStyle}
                        disabled={disabled}
                    >
                        {label}
                    </Button>
                ))}
        </>
    );
};

const DialogControlsMemoized = React.memo(DialogControls);

export { DialogControlsMemoized as DialogControls };
