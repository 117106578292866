import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
    drawerRoot: {
        width: '350px',
        flexShrink: 0,

        zIndex: '999 !important',
    },
    drawerPaper: {
        display: 'flex',
        width: '350px',
        flexDirection: 'column',
        alignItems: 'start',
    },
    drawerOpenClass: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerCloseClass: {
        display: 'none',

        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
}));
