import _ from 'lodash';

import { getLibrary } from './library';
import { setLoadingState } from './progressIndicator';
import { getTasksList, setCurrentUser } from './tasks';
import { createImage, getImageUrl } from './images';
import version from '../service/version';
import { getBackupInterval } from '../service/StateManager';
import { getRoutesDeclaration } from './declaration';
import BackupSingleton from '../service/BackupSingleton';
import { showConfirmDialog } from './messageDialog';
import { hideModal } from './modal';
import { addFields, setDimension } from './systemOfMeasurement';
import { Weight } from '../reducers/systemOfMeasurement';
import errorLogsSender from '../service/ErrorLogsSender';

const LOGIN = 'LOGIN';
const SHOW_ERROR = 'SHOW_ERROR';
const GET_USER_DATA = 'GET_USER_DATA';
const SET_SETTINGS = 'SET_SETTINGS';
const SET_RELOGIN_VISIBLE = 'SET_RELOGIN_VISIBLE';
const UPDATE_USER_PLAN_FLIGHT = 'UPDATE_USER_PLAN_FLIGHT';
const GET_RESTORE_FILES = 'GET_RESTORE_FILES';

const errorMessages = ['Incorrect username or password', 'No access to the application. Check your account.'];
const ErrorCode = {
    USER_OR_PASSWORD_EMPTY: 0,
    NO_PARTITIONS: 1,
    USER_OR_PASSWORD_INCORRECT: 2,
    USER_IS_BLOCKED: 3,
    EMPLOYEE_IS_BLOCKED: 4,
};

const getErrorMessage = (code) => {
    switch (code) {
        case ErrorCode.USER_OR_PASSWORD_EMPTY:
        case ErrorCode.USER_OR_PASSWORD_INCORRECT:
            return errorMessages[0];
        case ErrorCode.NO_PARTITIONS:
        case ErrorCode.USER_IS_BLOCKED:
        case ErrorCode.EMPLOYEE_IS_BLOCKED:
            return errorMessages[1];
    }
};

function relogin({ username, password, confirmationCode, onError, onSuccess }) {
    return login({ login: username, password, confirmationCode, onError, onSuccess, renew: true });
}

function login({ login, password, confirmationCode, onError, onSuccess, renew = false }) {
    return (dispatch, getState, { apiManager, history, plugins }) => {
        if (!login || !password) {
            return dispatch({ type: SHOW_ERROR, error: 'Login and Password are required' });
        }
        dispatch(setLoadingState(true, 'Login...'));

        apiManager
            .login(login, password, confirmationCode, version.toObject())
            .then((res) => {
                if (res.error) {
                    console.log(res.error);
                    dispatch(setLoadingState(false));
                    return dispatch({
                        type: SHOW_ERROR,
                        error: getErrorMessage(res.error.message.code) || 'Error occurred. Please try again later',
                    });
                } else {
                    BackupSingleton.on();
                    const { id } = res.user || {};
                    dispatch({ type: LOGIN, user: res.user, config: res.config });
                    const enabledSystemOfMeasurement = _.get(res, 'config.enabledSystemOfMeasurement', false);
                    if (!enabledSystemOfMeasurement && setDimension) {
                        dispatch(setDimension({ dimension: Weight.KG }));
                    }
                    saveDeviceInfo(id, plugins);

                    Promise.all([dispatch(getUserData(id)), dispatch(getRoutesDeclaration()), dispatch(getLibrary(false, id))])
                        .then(() => {
                            dispatch(setLoadingState(false));
                            if (!renew) {
                                dispatch(getTasksList());
                                history.push('/flight-task');
                            } else {
                                dispatch(hideModal());
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            dispatch(setLoadingState(false));
                            return dispatch({
                                type: SHOW_ERROR,
                                error: err.message
                                    ? `Login success, but ${err.message.toLowerCase()}`
                                    : 'Login success, but error occurred',
                            });
                        });
                }
            })
            .then(() => {
                if (onSuccess) {
                    onSuccess();
                }
            })
            .then(() => {
                if (errorLogsSender) {
                    errorLogsSender.sendLogs();
                }
            })
            .catch((err) => {
                dispatch(setLoadingState(false));
                if (onError) {
                    onError(err);
                } else {
                    console.log(err);
                    dispatch({ type: SHOW_ERROR, error: err.message || 'There is no connection to the server' });
                }
            });
    };
}

function saveDeviceInfo(id, plugins) {
    return plugins.file.write(
        'device',
        new Blob(
            [
                JSON.stringify({
                    userId: id,
                    version: `${version}`,
                }),
            ],
            { type: 'application/json' }
        )
    );
}

function checkUserDataCache(state) {
    const CACHE_TIME = 60 * 60000;
    const syncTime = _.get(state, 'login.syncTime');
    // console.log(CACHE_TIME, new Date(), new Date(syncTime), syncTime, new Date() - new Date(syncTime));

    return syncTime && new Date() - new Date(syncTime) < CACHE_TIME;
}

function getUserData(defaultUserId) {
    return (dispatch, getState, { apiManager }) => {
        const state = getState();
        const userId = _.get(state, 'login.user.id', defaultUserId);

        if (checkUserDataCache(state)) {
            return Promise.resolve();
        }

        return apiManager
            .getUserData(userId)
            .then((data) => dispatch(prepareUserData(data)))
            .then((data) => {
                if (!data.avatar) {
                    return Promise.resolve(data);
                }
                delete data.avatar;
                return dispatch(getImageUrl(`img_${data.crewId}`)).then((url) => ({ ...data, url }));
            })
            .then((user) => {
                const { url: preview, name } = user;
                dispatch({ type: GET_USER_DATA, user: user });
                dispatch(setCurrentUser({ id: userId, preview, name }));

                return user;
            })
            .then((user) =>
                dispatch(
                    addFields({
                        fields: user.userData.loadingTemplates.reduce((accum, template) => {
                            if (template.type.name) {
                                accum.push(template.type.name);
                            }

                            return accum;
                        }, []),
                    })
                )
            )
            .catch((err) => {
                throw new Error('Failed user data', { cause: err });
            });
    };
}

function prepareUserData(data) {
    const user = Object.assign({}, data);
    const { avatar, crewId } = user;

    return (dispatch) => {
        if (!avatar) {
            return Promise.resolve(user);
        }
        const { data, contentType } = avatar;

        return Promise.all([dispatch(createImage(`img_${crewId}`, data, contentType))]).then(() => Promise.resolve(user));
    };
}

function getUserPlanFlight() {
    return (dispatch, getState, { apiManager }) => {
        const userId = _.get(getState(), 'login.user.id');

        apiManager.getUserPlanFlight(userId).then((planFlights) => {
            dispatch({ type: UPDATE_USER_PLAN_FLIGHT, planFlights });
        });
    };
}

function setSettings(backendUrl, ssl, backupInterval) {
    backendUrl = backendUrl.replace(/^https?:\/\//i, '');
    backupInterval = getBackupInterval(backupInterval);

    if (backendUrl[backendUrl.length - 1] !== '/') {
        backendUrl += '/';
    }

    return { type: SET_SETTINGS, backendUrl, ssl, backupInterval };
}

function getRestoreFiles() {
    return (dispatch, getState, { plugins }) => {
        plugins.file
            .getFilesList()
            .then((files) => {
                const fileList = files
                    .filter((file) => file.name.indexOf('state') !== -1)
                    .sort((a, b) => (new Date(a.modificationTime) < new Date(b.modificationTime) ? 1 : -1));
                return fileList;
            })
            .then((fileList) => {
                dispatch({ type: GET_RESTORE_FILES, fileList });
            });
    };
}

function sendRestoreFile(fileName) {
    return (dispatch, getState, { apiManager, plugins }) => {
        const userId = _.get(getState(), 'login.user.id');

        plugins.file
            .readFileAsString(fileName)
            .then((data) => {
                return apiManager.sendState(userId, fileName, data);
            })
            .then(({ error, message }) => {
                if (error) {
                    dispatch(
                        showConfirmDialog({
                            title: 'Error',
                            message: `File not sent. Details: ${message}`,
                            btnCancelVisibility: false,
                        })
                    );
                } else {
                    dispatch(
                        showConfirmDialog({
                            title: 'Success',
                            message: 'File sent successfully',
                            btnCancelVisibility: false,
                        })
                    );
                }
            })
            .catch(() => {
                dispatch(
                    showConfirmDialog({
                        title: 'Error',
                        message: `State file ${fileName} not sent`,
                        btnCancelVisibility: false,
                    })
                );
            });
    };
}

export {
    LOGIN,
    SHOW_ERROR,
    GET_USER_DATA,
    SET_SETTINGS,
    SET_RELOGIN_VISIBLE,
    UPDATE_USER_PLAN_FLIGHT,
    GET_RESTORE_FILES,
    login,
    relogin,
    setSettings,
    getUserPlanFlight,
    getUserData,
    checkUserDataCache,
    getRestoreFiles,
    sendRestoreFile,
};
