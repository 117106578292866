import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    const {
        palette: { primary3Color, borderColor },
        crew: { cabin, salon },
    } = theme;

    return {
        cabin: {
            backgroundColor: cabin,
        },
        info: {
            padding: '5px 0',
            border: '1px solid rgb(245, 245, 245)',
        },
        header: {
            width: '100%',
            margin: theme.spacing(2, 0, 0),
            borderSpacing: 0,

            color: primary3Color,
            borderColor: borderColor,
            textAlign: 'center',
        },
        headerCell: {
            padding: '10px 0',
            borderBottom: '1px solid',
            fontWeight: 'normal',
        },
        row: {
            cursor: 'pointer',

            '& > td': {
                paddingTop: 10,
                paddingBottom: 5,
            },
        },
        salon: {
            backgroundColor: salon,
        },
        table: {
            width: '100%',
            textAlign: 'center',
            borderSpacing: 0,
        },
        previousWork: {
            opacity: 0.7,
        },
    };
});
