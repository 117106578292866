import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    label: {
        color: theme.palette.type === 'light' ? theme.palette.grey['700'] : theme.palette.accent3Color,
    },
    wrapper: {
        marginRight: theme.spacing(2),
    },
}));
