const UPDATE_USERS = 'UPDATE_USERS';

function updateUsers(users, syncTime) {
    return {
        type: UPDATE_USERS,
        users,
        syncTime,
    };
}

export { updateUsers, UPDATE_USERS };
