import { createSelector } from 'reselect';
import { get } from 'lodash';

import { getCurrentLeg } from './screen';

const checkListCompleted = (checkListStatus) => {
    if (checkListStatus) {
        for (let i = 0; i < checkListStatus.length; i++) {
            if (!checkListStatus[i]) {
                return false;
            }
        }
    }

    return true;
};

export const getCurrentCheckLists = createSelector(
    (state) => get(state, 'checkLists'),
    getCurrentLeg,
    ({ checkList = {}, checkListStatus = {} }, leg) => {
        const legCheckLists =
            leg &&
            Object.keys(checkList).reduce(
                (res, key) => {
                    if (checkList[key].legId === leg.id) {
                        res.isFind = true;

                        res.legCheckLists[key] = {
                            ...checkList[key],
                            checkedItems: checkListStatus[key],
                            completed: checkList[key].createDate ? checkListCompleted(Object.values(checkListStatus[key])) : true,
                        };
                    }

                    return res;
                },
                { isFind: false, legCheckLists: {} }
            );

        return legCheckLists && legCheckLists.isFind ? legCheckLists.legCheckLists : null;
    }
);

export const getCheckListWarnings = createSelector(
    (state) => get(state, 'sendedData'),
    (state) => get(state, 'checkLists', {}),
    (data, { checkList = {}, checkListStatus = {} }) =>
        data &&
        Object.keys(data).reduce((res, legId) => {
            const legCheckListStatus = Object.keys(checkList).reduce((resStatus, key) => {
                if (+checkList[key].legId === +legId) {
                    resStatus.push(...Object.values(checkListStatus[key]));
                }

                return resStatus;
            }, []);

            if (!checkListCompleted(legCheckListStatus)) {
                res[legId] = {};
            }

            return res;
        }, {})
);
