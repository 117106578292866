import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        minHeight: 74,

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: 5,
        padding: theme.spacing(0, 9, 0, 0),

        border: `1px solid ${theme.palette.divider}`,
    },
    dropzone: {
        position: 'absolute',
        top: '50%',
        right: 10,
        marginTop: -28,
    },
    info: {
        padding: 0,
        margin: 0,
        color: theme.palette.info[theme.palette.type === 'light' ? 'dark' : 'light'],
        fontSize: '14px',
    },
    inner: {
        display: 'flex',
        flexWrap: 'nowrap',
        height: 150,
        overflowY: 'hidden',
        overflowX: 'scroll',
        alignItems: 'center',
    },
    paragraph: {
        padding: theme.spacing(0, 1),
    },
    preview: {
        margin: theme.spacing(1),
    },
}));

export { useStyles };
