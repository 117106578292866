import { CustomError } from './CustomError';

class ApiError extends CustomError {
    constructor({ message, code, stack, data, path, res, text, method, info }) {
        super({ message, code, stack, data, path, method, info });
        this.type = 'API Error';
        this.isFetchError = true;
        this.response = res;
        this.text = text;
        this.info = info;
    }
}

export { ApiError };
