import { createSelector } from 'reselect';
import { get, isUndefined } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { CrewTypes } from '../service/constants';
import { DEFAULT_TELEX_FONT_SIZE } from '../actions/screen';
import { sortObjByField } from '../service/sort';

export const useDarkTheme = () => useSelector((state) => state.screen.darkTheme);

export const getFontSize = createSelector(
    (state) => get(state, 'screen.fontSize', DEFAULT_TELEX_FONT_SIZE),
    (fontSize) => fontSize
);

export const getCurrentTaskNumberSelector = createSelector(
    (state) => get(state, 'screen.currentTask'),
    (currentTaskId) => currentTaskId
);

export const getCurrentTaskSelector = createSelector(
    (state) => get(state, 'screen.currentTask'),
    (state) => get(state, 'tasks.list'),
    (currentTaskId, tasks) => {
        return tasks && currentTaskId ? tasks[currentTaskId] : undefined;
    }
);

export const getCrewTypeSelector = createSelector(getCurrentTaskSelector, (currentTask) => currentTask?.crewType || 0);

export const getCurrentTaskNumber = createSelector(getCurrentTaskSelector, (currentTask) => {
    return (currentTask && currentTask.flightTask) || undefined;
});

export const getCurrentTaskDate = createSelector(getCurrentTaskSelector, (currentTask) => {
    const { flights } = currentTask || {};
    const sortedFlights =
        flights &&
        Object.values(flights)
            .map((flight) => flight.date && moment.utc(flight.date))
            .sort((a, b) => (a > b ? 1 : -1));

    return (
        (sortedFlights &&
            (sortedFlights[0] || currentTask.taskDate) &&
            moment(sortedFlights[0] || currentTask.taskDate).format('DD.MM.YY')) ||
        undefined
    );
});

export const getFlights = createSelector(getCurrentTaskSelector, (currentTask) => {
    return currentTask ? currentTask.flights : undefined;
});

export const getFlightTasks = createSelector(
    (state) => get(state, 'screen.currentUser.id'),
    (state) => get(state, 'tasks.list'),
    (userId, tasks) => {
        const flightTasks = Object.keys(tasks)
            .map((id) => {
                const { flightTask, flights, syncTime, users, removeDate } = tasks[id];
                const sortedFlights =
                    flights &&
                    Object.keys(flights)
                        .map((keyValue) => {
                            let flight = flights[keyValue];

                            return {
                                id: flight.id,
                                date: flight.date && moment.utc(flight.date),
                                num: flight.num,
                                route: flight.route,
                                cancel: flight.cancel,
                            };
                        })
                        .sort((a, b) => (a.date > b.date ? 1 : -1));

                return {
                    id,
                    users,
                    flightTask,
                    taskDate: (sortedFlights[0] || {}).date || tasks[id].taskDate,
                    syncTime,
                    flights: sortedFlights,
                    removeDate,
                };
            })
            // отображаем лишь ПЗ для пользователя и то,что не было удалено
            .filter((task) => task.users.indexOf(userId) !== -1 && !task.removeDate);

        return flightTasks.sort((a, b) => {
            if (!a.flights[0] || !b.flights[0]) {
                return 0;
            }
            let date1 = a.flights[0].date;
            let date2 = b.flights[0].date;
            return date1 > date2 ? 1 : -1;
        });
    }
);

export const getSortedFlights = createSelector(getFlights, (flights) => {
    return flights && Object.values(flights || {}).sort(sortObjByField('date'));
});

export const getCurrentFlight = createSelector(
    (state) => state.screen.currentFlight,
    getFlights,
    (currentFlightId, flights) => {
        return flights && currentFlightId ? flights[currentFlightId] : undefined;
    }
);

export const getCurrentLeg = createSelector(
    (state) => state.screen.currentLeg,
    getCurrentFlight,
    (currentLegId, currentFlight) => {
        return currentFlight && currentFlight.legs && currentLegId ? currentFlight.legs[currentLegId] : undefined;
    }
);

export const getCurrentLegCrew = createSelector(
    getCurrentLeg,
    (state) => state.images,
    (state) => state.crew,
    (leg, images, crew) => {
        return (
            leg &&
            Object.keys(leg.crew).map((item) => {
                const { id } = leg.crew[item] || {};
                const crewItem = crew[id];
                const { url } = (crewItem && images[crewItem.photo]) || {};

                return { ...crewItem, ...leg.crew[item], url: url };
            })
        );
    }
);

export const getCurrentLegCabinCrew = createSelector(
    getCurrentLeg,
    (state) => state.crew,
    (leg, crew) => {
        const res = [];

        if (leg) {
            Object.keys(leg.crew).forEach((item) => {
                const { id, crewType } = leg.crew[item] || {};
                const crewItem = crew[id];

                if (crewType === CrewTypes.PCT_CABINSTAFF) {
                    res.push({ ...crewItem });
                }
            });
        }

        return res;
    }
);

export const getFlightTaskLegsList = createSelector(getFlights, (flights) => {
    return (
        flights &&
        Object.keys(flights).reduce((res, flightId) => {
            const flight = flights[flightId];
            const legs = flight.legs || {};
            Object.keys(legs).forEach((legId) => {
                res[legId] = legs[legId];
            });

            return res;
        }, {})
    );
});

export const getAllFlightTaskLeg = createSelector(getFlightTaskLegsList, (legs) => {
    return (
        legs &&
        Object.keys(legs)
            .map((key) => legs[key])
            .sort((a, b) => (moment.utc(a.etd) > moment.utc(b.etd) ? 1 : -1))
    );
});

export const getFlightTaskLegIds = createSelector(getAllFlightTaskLeg, (legs) => {
    return legs && legs.length > 0 && legs.map((item) => item.id);
});

export const getPreviosTaskLegs = createSelector(getCurrentLeg, getAllFlightTaskLeg, (currentLeg, legs) => {
    return legs.filter((leg) => {
        return leg.etd && currentLeg.etd && new Date(leg.etd) <= new Date(currentLeg.etd);
    });
});

export const getFirstLeg = createSelector(getAllFlightTaskLeg, (legs) => {
    return legs && legs[0];
});

export const getLastLeg = createSelector(getAllFlightTaskLeg, (legs) => {
    return legs && legs.length > 0 && legs[legs.length - 1];
});

export const getPrevLeg = createSelector(getCurrentLeg, getAllFlightTaskLeg, (currentLeg, legs) => {
    let currentIndex = 0;
    if (!legs) {
        return;
    }

    legs.forEach((item, index) => {
        if (item.id === currentLeg.id) {
            currentIndex = index;
        }
    });

    return legs && legs.length > 0 && currentIndex > 0 && legs[currentIndex - 1];
});

export const getNextLeg = createSelector(getCurrentLeg, getAllFlightTaskLeg, (currentLeg, legs) => {
    let currentIndex = 0;
    if (!legs) {
        return;
    }

    legs.forEach((item, index) => {
        if (item.id === currentLeg.id) {
            currentIndex = index;
        }
    });

    return legs && legs.length > 0 && legs[currentIndex + 1];
});

export const getCurrentOfp = createSelector(
    [(state) => state.screen.currentLeg, (state) => state.sendedData, getCurrentLeg],
    (currentLegId, sendedData, currentLeg) => {
        const ofpId = get(sendedData, `[${currentLegId}].ofpId`, null);

        return get(currentLeg, `ofps[${ofpId}]`, null);
    }
);

export const getUserLegs = createSelector(
    getCurrentTaskSelector,
    (state) => get(state, 'screen.currentUser.id'),
    (state) => get(state, 'users.list'),
    (currentTask, userId, users) => {
        const crewId = get(users, `[${userId}].crewId`);
        let legAccess = {};

        currentTask &&
            currentTask.flights &&
            Object.keys(currentTask.flights).map((key) => {
                const flight = currentTask.flights[key] || {};

                flight.legs &&
                    Object.keys(flight.legs).map((key) => {
                        const leg = flight.legs[key] || {};

                        leg.crew &&
                            Object.keys(leg.crew).forEach((key) => {
                                const { id, crewType } = leg.crew[key] || {};
                                if (id && id === crewId) {
                                    legAccess[leg.id] = crewType;
                                }
                            });
                    });
            });

        return legAccess;
    }
);

export const getPersonnelId = createSelector(
    (state) => state.screen,
    (state) => state.users,
    (screen, users) => {
        const currentUserId = get(screen, 'currentUser.id');

        return get(users, `list[${currentUserId}].crewId`);
    }
);

export const getUsersEdgeLeg = createSelector(getCurrentTaskSelector, getPersonnelId, (currentTask, persId) => {
    let result = {};

    currentTask &&
        currentTask.flights &&
        Object.keys(currentTask.flights).map((key) => {
            const flight = currentTask.flights[key] || {};

            flight.legs &&
                Object.keys(flight.legs).map((key) => {
                    const leg = flight.legs[key] || {};

                    if (!(leg.crew && Object.keys(leg.crew).find((key) => leg.crew[key] && leg.crew[key].id === persId))) {
                        return;
                    }

                    leg.crew &&
                        Object.keys(leg.crew).forEach((key) => {
                            const { id } = leg.crew[key] || {};

                            if (id && !result[id]) {
                                result[id] = {};
                            }

                            if (id && (!result[id].start || result[id].start.etd > leg.etd)) {
                                result[id].start = {
                                    id: leg.id,
                                    etd: leg.etd,
                                };
                            }

                            if (id && (!result[id].end || result[id].end.etd < leg.etd)) {
                                result[id].end = {
                                    id: leg.id,
                                    etd: leg.etd,
                                };
                            }
                        });
                });
        });

    return result;
});

export const getCurrentFlightInfo = createSelector(getCurrentFlight, (currentFlight) => {
    return !isUndefined(currentFlight)
        ? {
              flightNumber: currentFlight.num,
              date: moment(currentFlight.date).format('DD.MM.YY'),
              route: currentFlight.route,
          }
        : {};
});

export const getCurrentLegInfo = createSelector(getCurrentFlight, getCurrentLeg, (currentFlight, currentLeg = {}) => {
    const airportTakeOff = get(currentLeg, 'airportTakeOff.code', '');
    const airportLanding = get(currentLeg, 'airportLanding.code', '');

    return !isUndefined(currentFlight) && !isUndefined(currentLeg) ? `${currentFlight.num} ${airportTakeOff}-${airportLanding}` : '';
});

export const checkCaptain = createSelector(getCurrentLeg, getPersonnelId, (leg, personnelId) => {
    const id = Object.keys(leg.crew).find((key) => {
        return get(leg, `crew[${key}].id`) === personnelId;
    });

    const { crewType, orderNumber } = get(leg, `crew[${id}]`, {});

    return crewType === 0 && orderNumber === 1;
});

export const useCurrentLeg = () => useSelector((state) => get(state, 'screen.currentLeg'));

export const useCurrentLegCrew = () => useSelector((state) => getCurrentLegCrew(state) || {});

export const getPersonnelFlightsTask = createSelector(getCurrentTaskSelector, (currentTask) => {
    return currentTask && currentTask.personnelFlights
        ? currentTask.personnelFlights.sort((a, b) => (a.dateTakeoffReal > b.dateTakeoffReal ? 1 : -1))
        : [];
});

export const getPreviousPersonnelFlightsTask = createSelector(getCurrentTaskSelector, (currentTask) => {
    return currentTask && currentTask.personnelFlights
        ? currentTask.personnelFlights
              .filter((flight) => flight.isTaskBefore)
              .sort((a, b) => (a.dateTakeoffReal > b.dateTakeoffReal ? 1 : -1))
        : [];
});

export const getPreviousPersonnelWork = createSelector(getPreviousPersonnelFlightsTask, (prevPersonnelFlights) => {
    return (
        prevPersonnelFlights &&
        prevPersonnelFlights.reduce((res, flight) => {
            if (!res[flight.personnelID]) {
                res[flight.personnelID] = { tasks: [], timeWork: 0 };
            }

            let length = res[flight.personnelID].tasks.length;
            if (
                length > 0 &&
                res[flight.personnelID].tasks[length - 1].taskNumber === flight.taskNumber &&
                res[flight.personnelID].tasks[length - 1].dateWorkEnd &&
                flight.dateWorkBegin &&
                Math.abs(moment.utc(res[flight.personnelID].tasks[length - 1].dateWorkEnd).diff(flight.dateWorkBegin, 'minute')) <= 2
            ) {
                res[flight.personnelID].tasks[length - 1].dateWorkEnd = flight.dateWorkEnd;
                res[flight.personnelID].tasks[length - 1].timeWork += flight.timeWork;
            } else {
                res[flight.personnelID].tasks.push({
                    taskNumber: flight.taskNumber,
                    dateWorkBegin: flight.dateWorkBegin,
                    dateWorkEnd: flight.dateWorkEnd,
                    timeWork: flight.timeWork,
                });
            }

            res[flight.personnelID].timeWork += flight.timeWork;

            return res;
        }, [])
    );
});
