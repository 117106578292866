import React from 'react';

import { NoticeModalPreview } from './NoticeModalPreview/NoticeModalPreview';
import { NoticeModalContent } from './NoticeModalContent/NoticeModalContent';
import { Dialog, DialogContent } from '../../components/material-ui/Dialog';
import { useStyles } from './useStyles';

import './NoticeModal.scss';

export const NOTICE = 'NOTICE';

const NoticeModal = React.forwardRef((props, ref) => {
    const { showPreview = false, ...restProps } = props;
    const classes = useStyles();

    return (
        <Dialog fullScreen={true} open={true}>
            <DialogContent ref={ref} className={classes.container}>
                {showPreview ? <NoticeModalPreview {...restProps} /> : <NoticeModalContent {...restProps} />}
            </DialogContent>
        </Dialog>
    );
});

export { NoticeModal };
