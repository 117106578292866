import React from 'react';

import { GpsSlider } from './GpsSlider';
import { GpsRouteSelectionType } from './GpsRouteSelectionType';

const GpsSetting = (props) => {
    const { onChangeSlider, allowedDistance, selectionType, onChangeSelectionType, onSliderUp, onSliderDown } = props;

    return (
        <>
            <GpsSlider {...{ onChangeSlider, allowedDistance, onSliderUp, onSliderDown }} />
            <GpsRouteSelectionType {...{ selectionType, onChangeSelectionType }} />
        </>
    );
};

const GpsSettingMemoized = React.memo(GpsSetting);

export { GpsSettingMemoized as GpsSetting };
