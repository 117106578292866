import { get } from 'lodash';

import { checkDocumentFormat, getErrorMessage, getFileName, getFileStorePath } from '../service/utils';
import { showConfirmDialog } from './messageDialog';
import { linearProgress, setLoadingState } from './progressIndicator';
import { MessageDialogTypes } from '../components/MessageDialog/MessageDialog';
import { UNAUTHORIZED_HTTP_CODE } from '../service/Error';
import { showModal, TYPES } from './modal';
import { DocumentPrefix, DocumentTypes, MessageType } from '../service/constants';
import errorLogsSender from '../service/ErrorLogsSender';

const GET_LIBRARY = 'GET_LIBRARY';
const DOWNLOAD_LIBRARY_ITEMS = 'DOWNLOAD_LIBRARY_ITEMS';
const DOWNLOAD_LIBRARY_FOLDER = 'DOWNLOAD_LIBRARY_FOLDER';
const REMOVE_FILE = 'REMOVE_FILE';
const REMOVE_ALL_FILES = 'REMOVE_ALL_FILES';
const LibraryItemTypes = {
    FOLDER: 0,
    DOCUMENT: 1,
    LINK: 2,
};

let itemsDownloadedPromise;

function getLibrary(fullReset = false, defaultUserId) {
    return (dispatch, getState, { apiManager }) => {
        const state = getState();
        const userId = get(state, 'login.user.id', defaultUserId);
        const syncTime = !fullReset && get(state, 'library.syncTime');
        const libraryList = get(state, 'library.library', {});
        const fileStorePath = getFileStorePath();
        const preloadedIdsPromise = apiManager.getFileList(fileStorePath).then((files) => {
            return Promise.resolve(
                files.reduce((res, { name, modificationTime }) => {
                    const { fileName, type, needChange } = checkDocumentFormat(name);
                    if (needChange) {
                        apiManager.renameFile(name, `${name}.pdf`, fileStorePath);
                    }
                    if (type === DocumentTypes.LIBRARY) {
                        res[fileName.replace(DocumentPrefix.LIBRARY, '')] = {
                            modificationTime: modificationTime,
                            isDownloaded: true,
                        };
                    }

                    return res;
                }, {})
            );
        });

        return Promise.all([apiManager.getLibrary(userId, syncTime), preloadedIdsPromise])
            .then(([data, preloadedIds]) => {
                const { library, syncTime } = data || {};
                const downloaded = [];
                const changedFolders = [];
                const merged = library.reduce(
                    (res, item) => {
                        res[item.id] = { ...res[item.id], ...item, needUpdate: false, hasUpdates: false };
                        const { isDownloaded, modificationTime } = preloadedIds[item.id] || {};
                        const isFileHasUpdates = isDownloaded && new Date(item.date) > modificationTime;
                        // для обязательных файлов если есть обновления или не скачаны - требуем скачать.
                        if (item.req && (isFileHasUpdates || !isDownloaded)) {
                            res[item.id].needUpdate = true;
                        }
                        // для необязательных файлов, если они закачаны, уведомляем о наличии обновления.
                        if (!item.req && isFileHasUpdates) {
                            res[item.id].hasUpdates = true;
                        }
                        if (isDownloaded) {
                            downloaded.push(item.id);
                        }

                        if (!isDownloaded && res[item.id].isDownloaded) {
                            res[item.id].isDownloaded = false;
                        }

                        // Если документ не закачан, но изменен, то значит есть незакачанный документ в папке
                        // и папку нужно сделать серой
                        if (res[item.id].type !== LibraryItemTypes.FOLDER && !isDownloaded && changedFolders.indexOf(item.pid) === -1) {
                            changedFolders.push(item.pid);
                        }

                        return res;
                    },
                    { ...libraryList }
                );
                dispatch({ type: GET_LIBRARY, library: merged, syncTime: syncTime, downloaded, changedFolders });
            })
            .catch((err) => {
                const error = new Error(getErrorMessage(err, MessageType.GET_LIBRARY_DATA), { cause: err });
                console.log(err);
                errorLogsSender.addLog(error);
                throw error;
            });
    };
}

function downloadAllLibraryItems(ids) {
    return (dispatch, getState, { apiManager }) => {
        const state = getState();
        let res = { downloaded: [], errors: [] };
        const itemCount = ids.length;
        const userId = get(state, 'login.user.id');

        dispatch(setLoadingState(true, 'Downloading library items...'));

        return new Promise((resolve) => {
            ids.map((id) =>
                apiManager
                    .downloadLibraryItem(userId, id)
                    .then(() => {
                        res.downloaded.push(id);
                        if (res.downloaded.length + res.errors.length === itemCount) {
                            resolve(res);
                        }
                    })
                    .catch(() => {
                        res.errors.push(id);
                        if (res.downloaded.length + res.errors.length === itemCount) {
                            resolve(res);
                        }
                    })
            );
        }).then((res) => {
            dispatch({ type: DOWNLOAD_LIBRARY_ITEMS, downloaded: res.downloaded });
            if (res.errors.length > 0) {
                setTimeout(
                    () =>
                        dispatch(
                            showConfirmDialog({
                                title: 'Error message',
                                message: 'Not all the documents have been loaded correctly. Please try again later!',
                                btnCancelVisibility: false,
                                options: {
                                    type: MessageDialogTypes.ERROR,
                                },
                            })
                        ),
                    1000
                );
            }
            dispatch(setLoadingState(false));
        });
    };
}

function downloadLibraryItems(ids) {
    return (dispatch, getState, { apiManager }) => {
        const state = getState();
        const userId = get(state, 'login.user.id');

        if (itemsDownloadedPromise) {
            itemsDownloadedPromise = itemsDownloadedPromise.then(() => _downloadLibraryItems(ids, userId, apiManager, dispatch));
        } else {
            itemsDownloadedPromise = _downloadLibraryItems(ids, userId, apiManager, dispatch);
        }
        dispatch(linearProgress.increaseMax(ids.length));

        return itemsDownloadedPromise.then((res) => {
            dispatch({ type: DOWNLOAD_LIBRARY_ITEMS, downloaded: res.downloaded });
            if (res.errors.length > 0) {
                setTimeout(
                    () =>
                        dispatch(
                            showConfirmDialog({
                                title: 'Error message',
                                message: 'Not all the documents have been loaded correctly. Please try again later!',
                                btnCancelVisibility: false,
                                options: {
                                    type: MessageDialogTypes.ERROR,
                                },
                            })
                        ),
                    1000
                );
            }
        });
    };
}

function downloadLibraryItem(id, name) {
    return (dispatch, getState, { apiManager }) => {
        const userId = get(getState(), 'login.user.id');
        dispatch(setLoadingState(true, `Downloading document "${name}"...`));

        return apiManager
            .downloadLibraryItem(userId, id)
            .then(() => {
                dispatch({ type: DOWNLOAD_LIBRARY_ITEMS, downloaded: [id] });
                dispatch(setLoadingState(false));
            })
            .catch((err) => {
                dispatch(setLoadingState(false));
                console.log(err);
                if (err.errorCode === UNAUTHORIZED_HTTP_CODE) {
                    dispatch(showModal(TYPES.RELOGIN, {}));
                    return;
                }

                let msg = {
                    title: 'Warning message',
                    message: 'The document have been uploaded correctly. Please try again later!',
                    btnCancelVisibility: false,
                    options: {
                        type: MessageDialogTypes.ERROR,
                    },
                };
                // // Cannot re-open Dialog immediately, need timeout fro it
                // setTimeout(() =>{
                dispatch(showConfirmDialog(msg));
            });
    };
}

function downloadRequiredLibraryItems() {
    return (dispatch, getState) => {
        const state = getState();
        const libraryList = get(state, 'library.library', {});
        const ids =
            libraryList &&
            Object.keys(libraryList).reduce((res, key) => {
                const item = libraryList[key];

                if (item.type !== LibraryItemTypes.FOLDER && (item.hasChanges || item.needUpdate)) {
                    res.push(item.id);
                }

                return res;
            }, []);

        return ids && ids.length > 0 && dispatch(downloadLibraryItems(ids));
    };
}

function _downloadLibraryItems(ids, userId, apiManager, dispatch) {
    let res = { downloaded: [], errors: [] };
    const itemCount = ids.length;

    return new Promise((resolve) => {
        ids.map((id) =>
            apiManager
                .downloadLibraryItem(userId, id)
                .then(() => {
                    dispatch(linearProgress.increaseProgress());
                    res.downloaded.push(id);
                    if (res.downloaded.length + res.errors.length === itemCount) {
                        resolve(res);
                    }
                })
                .catch(() => {
                    dispatch(linearProgress.increaseProgress());
                    res.errors.push(id);
                    if (res.downloaded.length + res.errors.length === itemCount) {
                        resolve(res);
                    }
                })
        );
    });
}

function getChilds(library, id, idList, all = false) {
    let newIdList = (idList || []).slice(0);

    newIdList = Object.keys(library)
        .filter((key) => library[key].pid == id)
        .reduce((res, key) => {
            const item = library[key];
            if (item.type == LibraryItemTypes.FOLDER) {
                res = getChilds(library, item.id, res, all);
            } else if (res.indexOf(item.id) == -1 && (all || !item.isDownloaded || item.needUpdate || item.hasUpdates)) {
                res.push(item.id);
            }

            return res;
        }, newIdList || []);

    return newIdList;
}

function downloadLibraryFolder(id) {
    return (dispatch, getState) => {
        const state = getState();

        let ids = getChilds(state.library.library, id);
        dispatch(downloadLibraryItems(ids));
    };
}

function showPdf(id) {
    return (dispatch, getState, { plugins }) => {
        plugins.pdf.show(id).catch((err) => {
            console.log(err);
            setTimeout(() => {
                dispatch(
                    showConfirmDialog({
                        title: 'Error message',
                        message: "Can't open a file. Maybe it is not loaded.",
                        btnCancelVisibility: false,
                        options: {
                            type: MessageDialogTypes.ERROR,
                        },
                    })
                );
            }, 1000);
        });
    };
}

function removeFile(id) {
    return (dispatch, getState, { plugins }) => {
        plugins.file
            .remove(`${DocumentPrefix.LIBRARY}${id}.pdf`)
            .then((fileName) => {
                dispatch({ type: REMOVE_FILE, id: getFileName(fileName).replace(DocumentPrefix.LIBRARY, '') });
            })
            .catch((err) => {
                console.log(err);
                setTimeout(
                    () =>
                        dispatch(
                            showConfirmDialog({
                                title: 'Error message',
                                message: 'System error. Can not remove the file.',
                                btnCancelVisibility: false,
                                options: {
                                    type: MessageDialogTypes.ERROR,
                                },
                            })
                        ),
                    1000
                );
            });
    };
}

function removeFolder(id) {
    return (dispatch, getState) => {
        let ids = getChilds(getState().library.library, id, null, true);
        ids.forEach((item) => {
            dispatch(removeFile(item));
        });
    };
}

export {
    GET_LIBRARY,
    DOWNLOAD_LIBRARY_ITEMS,
    DOWNLOAD_LIBRARY_FOLDER,
    REMOVE_FILE,
    REMOVE_ALL_FILES,
    getLibrary,
    downloadLibraryItems,
    downloadLibraryFolder,
    downloadRequiredLibraryItems,
    showPdf,
    removeFile,
    removeFolder,
    downloadLibraryItem,
    downloadAllLibraryItems,
};
