import { createSelector } from 'reselect';
import { get } from 'lodash';

const getLibraryNotifications = createSelector(
    (state) => get(state, 'library.library'),
    (library) => {
        const libraryList = [];
        Object.keys(library || {}).forEach((key) => {
            const document = library[key];
            if (document.needUpdate) {
                libraryList.push(document);
            }
        });

        return libraryList;
    }
);

export { getLibraryNotifications };
