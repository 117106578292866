export const TAKEOFF_FIELDS = [
    { id: 'cgPct', type: 'number', validator: { type: 'decimal', precision: [7, 2] } },
    { id: 'trim', type: 'number', validator: { type: 'decimal', precision: [7, 2] } },
    { id: 'flaps', type: 'number', validator: { type: 'decimal', precision: [7, 2] } },
    { id: 'ass', type: 'text', validator: { type: 'text', length: 6 } },
    { id: 'rat', type: 'text', validator: { type: 'text', length: 6 } },
    { id: 'n1', type: 'number', validator: { type: 'int' } },
    { id: 'redAlt', type: 'number', validator: { type: 'int' } },
    { id: 'ta', type: 'number', validator: { type: 'int' } },
    { id: 'v1', type: 'number', validator: { type: 'int' } },
    { id: 'vr', type: 'number', validator: { type: 'int' } },
    { id: 'v2', type: 'number', validator: { type: 'int' } },
    { id: 'accAlt', type: 'number', validator: { type: 'int' } },
    { id: 'mfra', type: 'number', validator: { type: 'int' } },
    { id: 'h', type: 'number', validator: { type: 'int' } },
    { id: 'rw', type: 'text', validator: { type: 'text', length: 6 } },
    { id: 'v_ref', type: 'number', validator: { type: 'int' } },
    { id: 'to', type: 'number', validator: { type: 'decimal', precision: [7, 2] } },
    { id: 'clb', type: 'number', validator: { type: 'decimal', precision: [7, 2] } },
    { id: 'crz', type: 'number', validator: { type: 'decimal', precision: [7, 2] } },
    { id: 'con', type: 'number', validator: { type: 'decimal', precision: [7, 2] } },
    { id: 'wd', type: 'number', validator: { type: 'int', min: 0, max: 360 } },
    { id: 'ws', type: 'text', validator: { type: 'text', length: 10 } },
    { id: 'vis', type: 'text', validator: { type: 'text', length: 20 } },
    { id: 'ceiling', type: 'text', validator: { type: 'text', length: 10 } },
    { id: 'temp', type: 'number', validator: { type: 'int', min: -100, max: 100 } },
    { id: 'dewPoint', type: 'number', validator: { type: 'int', min: -100, max: 100 } },
    { id: 'qnh', type: 'number', validator: { type: 'decimal', precision: [9, 2], min: 0, max: 1100 } },
    { id: 'qfe', type: 'number', validator: { type: 'int', min: 0, max: 10000 } },
    { id: 'v0', type: 'number', validator: { type: 'int', min: 0, max: 1000000 } },
    { id: 'rwFric', type: 'text', validator: { type: 'text', length: 6 } },
    { id: 'rwhdg', type: 'number', validator: { type: 'int', min: 0, max: 360 } },
    { id: 'sid', type: 'text', validator: { type: 'text', length: 10 } },
    { id: 'sidTrans', type: 'text', validator: { type: 'text', length: 10 } },
    { id: 'initAlt', type: 'text', validator: { type: 'text', length: 10 } },
    { id: 'squawk', type: 'number', validator: { type: 'int', min: 0, max: 10000 } },
    { id: 'freq', type: 'number', validator: { type: 'decimal', precision: [8, 3] } },
    { id: 'fl', type: 'number', validator: { type: 'int', min: 0, max: 600 } },
    { id: 'capAlt', type: 'number', validator: { type: 'int', min: 0, max: 60000 } },
    { id: 'stbyAlt', type: 'number', validator: { type: 'int', min: 0, max: 60000 } },
    { id: 'foAlt', type: 'number', validator: { type: 'int', min: 0, max: 60000 } },
    { id: 'apElev', type: 'number', validator: { type: 'int', min: 0, max: 60000 } },
    { id: 'derate', type: 'number', validator: { type: 'int', min: 0, max: 100 } },
    { id: 'assumed', type: 'number', validator: { type: 'int', min: -200, max: 200 } },
    { id: 'origLndDist', type: 'number', validator: { type: 'int', min: 0, max: 100000 } },
    { id: 'qnhAlt', type: 'number', validator: { type: 'decimal', precision: [7, 2] } },
];
