import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { round } from 'lodash';

import { RouteName } from '../RouteName/RouteName';
import { NavType } from '../../../constants';
import { getCoordinates, getTimeFormat } from '../../../../../service/NavPointsUtils';

const UnderlineType = {
    NONE: 0,
    WELL_DONE: 1,
    BAD_WORK: 2,
};

const formatWithZero = (value, length) => {
    if (!value) {
        return '';
    }
    let res = '' + value;
    for (let i = res.length + 1; i <= length; i++) {
        res = 0 + res;
    }

    return res;
};

const getMacFormat = (point, fieldName) => {
    const { type, [fieldName]: field } = point || {};
    let result = field || '';
    switch (+type) {
        case NavType.CLIMB:
        case NavType.TOC:
            result = 'CLB';
            break;
        case NavType.DESCENT:
        case NavType.TOD:
            result = 'DESC';
            break;
    }

    return result;
};

const getRnt = (point) => {
    let { type, rnt = '', isUserAdd } = point || {};

    let typeName;
    switch (+type) {
        case NavType.TOC:
            typeName = 'TOC';
            break;
        case NavType.TOD:
            typeName = 'TOD';
            break;
        default:
            typeName = isUserAdd ? 'TP' : '';
    }

    return isUserAdd ? `${typeName}${rnt ? ` (${rnt})` : ''}` : `${rnt || typeName}`;
};

const needUnderlineTime = (time1, time2) => {
    const minDelta = 1000 * 60;
    const maxDelta = 1000 * 3600 * 24;
    const diff = (moment.utc(time1) - moment.utc(time2).seconds(0).milliseconds(0)) % maxDelta;
    if (Math.abs(diff) < minDelta || !time1 || !time2) {
        return UnderlineType.NONE;
    } else if (diff < 0) {
        return UnderlineType.WELL_DONE;
    } else {
        return UnderlineType.BAD_WORK;
    }
};

const needUnderlineFuel = (fuel1, fuel2) => {
    const minDelta = 0.1;
    const diff = Math.round((fuel1 - fuel2) * 1000) / 1000;
    if (Math.abs(diff) < minDelta || !fuel1 || !fuel2) {
        return UnderlineType.NONE;
    } else if (diff > 0) {
        return UnderlineType.WELL_DONE;
    } else {
        return UnderlineType.BAD_WORK;
    }
};

const getUnderlineClassName = (time1, time2, compareFunction, classes) => {
    let cellClassName = classes.basicField;
    const underlineType = compareFunction(time1, time2);
    if (underlineType === UnderlineType.WELL_DONE) {
        cellClassName = classnames(cellClassName, classes.wellDone);
    } else if (underlineType === UnderlineType.BAD_WORK) {
        cellClassName = classnames(cellClassName, classes.badWork);
    }

    return cellClassName;
};

const getRoundedValue = (value, precision = 2, toFixed = 2) => {
    return round(+value, precision).toFixed(toFixed);
};

export const getCellData = (decl, point, classes) => {
    const key = decl && decl.id;
    const field = point[key];
    const fieldForCompare = decl && decl.compareWith && point[decl.compareWith];
    const optionBold = decl && decl.bold;
    let cellContent, sign, difference, className, cellAddition;
    // const eto = point['eto'];
    // const est = point['est'];

    switch (key) {
        case 'long':
        case 'lat':
            cellContent = getCoordinates(field, key);
            break;
        case 'mac':
            cellContent = getMacFormat(point, key);
            break;
        case 'fl':
            sign = Math.abs(field - fieldForCompare) > 0 ? '+' : '-';
            cellContent = field && field - fieldForCompare !== 0 ? field : '';
            break;
        case 'tt':
        case 'tt_default':
            cellContent = getTimeFormat(field * 60, true, false);
            break;
        case 'est':
        case 'used':
            cellContent = field ? getRoundedValue(field) : '';
            className = classes.basicField;
            break;
        case 'est_default':
            cellContent = field && getRoundedValue(field);
            if (!cellContent) {
                cellContent = point['est'] ? getRoundedValue(point['est']) : '';
                className = classes.calculateField;
            } else {
                className = classes.basicField;
            }
            break;
        case 'used_default':
            cellContent = field && getRoundedValue(field);
            if (!cellContent) {
                cellContent = point['used'] ? getRoundedValue(point['used']) : '';
                className = classes.calculateField;
            } else {
                className = classes.basicField;
            }
            break;
        case 'act':
            difference = fieldForCompare ? field - fieldForCompare : 0;
            sign = difference > 0 ? '+' : '-';
            cellContent = field ? `${getRoundedValue(field)} ` : '';
            cellAddition = field && +Math.abs(+getRoundedValue(difference)) ? `${sign}${Math.abs(+getRoundedValue(difference, 2, 1))}` : '';
            className = getUnderlineClassName(field, fieldForCompare, needUnderlineFuel, classes);
            break;
        case 'ato': {
            const startDate = moment.utc(field);
            const endDate = moment.utc(fieldForCompare);
            difference = fieldForCompare ? (startDate.diff(endDate) > 0 ? startDate.diff(endDate) : endDate.diff(startDate)) : 0;
            sign = startDate.diff(endDate) > 0 ? '+' : '-';
            cellContent = field ? `${startDate.format('HH:mm')} ` : '';
            cellAddition = field && difference ? `${sign}${getTimeFormat(difference / 1000, false, false)}` : '';

            className = getUnderlineClassName(field, endDate, needUnderlineTime, classes);
            break;
        }
        case 'eto':
            cellContent = field ? moment.utc(field).format('HH:mm') : '';
            className = classes.basicField;
            break;
        case 'eto_default':
            cellContent = field && moment.utc(field).format('HH:mm');
            if (!cellContent) {
                cellContent = point['eto'] ? moment.utc(point['eto']).format('HH:mm') : '';
                className = classes.calculateField;
            } else {
                className = classes.basicField;
            }
            break;
        case 'mtk':
        case 'ttk':
            cellContent = formatWithZero(field, 3);
            break;
        case 'sr':
            cellContent = formatWithZero(field, 2);
            break;
        case 'route':
            cellContent = <RouteName value={field} directed={point.isRemoveFromDirecting} />;
            break;
        case 'rnt':
            cellContent = getRnt(point);
            break;
        default:
            cellContent = field || '';
    }

    className = classnames(className, optionBold && classes.bold);

    return {
        className,
        key,
        content: cellContent,
        addition: cellAddition,
    };
};
