import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { getPersonnelId } from './screen';

export const useFlightPageSwitchers = () => useSelector((state) => get(state, 'flightPage', { isRu: true, isLocalTime: false }));

export const getFlightPageProps = createSelector(
    (state) => get(state, 'modal', {}),
    (state) => get(state, 'tasks', {}),
    (state) => get(state, 'images', {}),
    (state) => get(state, 'crew', {}),
    getPersonnelId,
    (modal, tasks, images, crew, persId) => {
        const previewTask = get(modal, 'modalProps.id', 0);
        const { flightTask, taskDate, flights, id, crewType = 0 } = get(tasks, `list[${previewTask}]`, {});
        const signaturePreview = id && get(images, `sign_${id}.url`);
        let crewList = [];
        let plnList = [];
        let flightNames = [];

        const flightInfo =
            flights &&
            Object.keys(flights)
                .reduce((res, key) => {
                    const flight = flights[key];
                    const legsInfo = get(flight, 'legs');

                    legsInfo &&
                        Object.keys(legsInfo).map((legKey) => {
                            const leg = legsInfo[legKey] || {};

                            if (!(leg.crew && Object.keys(leg.crew).find((key) => leg.crew[key] && leg.crew[key].id === persId))) {
                                return;
                            }

                            res.push({
                                id: leg.id,
                                operator: flight.operator,
                                operatorEng: flight.operatorEng,
                                num: flight.num,
                                date: flight.date,
                                cancel: flight.cancel,
                                pln: flight.pln,
                                airportNumber: leg.airportNumber,
                                airportTakeOff: leg.airportTakeOff.codeIata,
                                airportLanding: leg.airportLanding.codeIata,
                                eta: leg.eta,
                                etd: leg.etd,
                                eta_local: leg.eta_local,
                                etd_local: leg.etd_local,
                                sta: leg.sta,
                                std: leg.std,
                                sta_local: leg.sta_local,
                                std_local: leg.std_local,
                            });

                            leg &&
                                Object.keys(leg.crew).forEach((key) => {
                                    const { id, crewType } = leg.crew[key] || {};
                                    const crewMember = crew[id];
                                    if (crewMember && id) {
                                        if (!crewList[crewType]) {
                                            crewList[crewType] = {};
                                        }

                                        if (!crewList[crewType][id]) {
                                            crewList[crewType][id] = {
                                                ...crewMember,
                                                ...leg.crew[key],
                                                flight: {
                                                    airportNumber: leg.airportNumber,
                                                    num: flight.num,
                                                    date: flight.date,
                                                },
                                            };
                                        }
                                    }
                                });
                        });

                    return res;
                }, [])
                .sort((a, b) => {
                    if (a.eta > b.eta) {
                        return 1;
                    } else {
                        return -1;
                    }
                });

        flightInfo &&
            flightInfo.forEach((flight) => {
                if (plnList.indexOf(flight.pln) === -1) {
                    plnList.push(flight.pln);
                }

                if (flightNames.indexOf(flight.num) === -1) {
                    flightNames.push(flight.num);
                }
            });

        let crewData = [];
        crewList.forEach((crew) => {
            crewData = crewData.concat(
                ...Object.keys(crew)
                    .map((key) => crew[key])
                    .sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1))
            );
        });

        return {
            id,
            flightTask,
            taskDate,
            crewType,
            plnTypes: plnList.map((item) => item.substring(0, item.indexOf('#'))).join(' / '),
            plns: plnList.map((item) => item.substring(item.indexOf('#') + 1, item.length)).join(' / '),
            flightNames: flightNames.join(' / '),
            flights: flightInfo,
            signaturePreview,
            crew: crewData,
            ...(modal.props || {}),
        };
    }
);
