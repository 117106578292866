import React from 'react';

import { TextField } from '../material-ui/TextField';

function MaterialTextField({ ...props }) {
    const InputLabelProps = React.useMemo(
        () => ({
            shrink: true,
            ...props.InputLabelProps,
        }),
        []
    );

    return <TextField {...props} InputLabelProps={InputLabelProps} />;
}

export default MaterialTextField;
