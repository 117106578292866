import { CustomError } from './CustomError';

class NetworkError extends CustomError {
    constructor({ message, code, stack, data, path, text, method }) {
        super({ message, code, stack, data, path, text, method });
        this.type = 'Network Error';
        this.isFetchError = true;
    }
}

export { NetworkError };
