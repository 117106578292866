import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { WizardContent } from './WizardContent/WizardContent';

const FlightWizardPage = () => {
    const currentLeg = useSelector((state) => get(state, 'screen.currentLeg'));

    return currentLeg ? <WizardContent /> : <></>;
};

const FlightWizardPageMemoized = React.memo(FlightWizardPage);

export { FlightWizardPageMemoized as FlightWizardPage };
