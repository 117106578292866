import React from 'react';

import PdfIcon from '../../../icons/image/Pdf';
import { useAnchorEl } from '../../../components/hooks/useAnchorEl';
import { Menu, MenuItem } from '../../../components/material-ui/Menu';

const ReportDropzonePdf = (props) => {
    const { width, height, className, onDelete, pos, isNew } = props;
    const [anchorEl, handleOpenMenu, handleClose] = useAnchorEl();

    const handleDeleteImage = React.useCallback(() => {
        onDelete(pos, isNew);
        handleClose();
    }, [pos, isNew]);

    return (
        <>
            <PdfIcon className={className} width={width} height={height} onClick={handleOpenMenu} />
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {onDelete && <MenuItem onClick={handleDeleteImage}>Delete</MenuItem>}
            </Menu>
        </>
    );
};

const ReportDropzonePdfMemoized = React.memo(ReportDropzonePdf);

export { ReportDropzonePdfMemoized as ReportDropzonePdf };
