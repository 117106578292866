import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    item: {
        flex: '1 1 50%',
        margin: '4px 0',
        borderBottom: `1px solid ${theme.palette.divider}`,

        '& span': {
            fontWeight: 600,
        },
    },
    subtitle: {
        textAlign: 'center',
        marginBottom: theme.spacing(1),
        fontWeight: 600,
    },
    title: {
        textAlign: 'center',
        fontWeight: 600,
    },
}));

export { useStyles };
