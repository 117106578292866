import React from 'react';

import { CheckListModalContent } from './CheckListModalContent/CheckListModalContent';
import { Dialog, DialogContent } from '../../components/material-ui/Dialog';
import { useStyles } from './useStyles';

export const CheckListModal = React.forwardRef((props, ref) => {
    const classes = useStyles();

    return (
        <Dialog fullScreen={true} open={true}>
            <DialogContent ref={ref} className={classes.container}>
                <CheckListModalContent {...props} />
            </DialogContent>
        </Dialog>
    );
});
