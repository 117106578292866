import React from 'react';

import BaseIcon from '../BaseIcon';

const FlightTask = (props) => (
    <BaseIcon {...props} viewBox="0 0 512 512">
        <path d="M352,30V0H160v30H54v482h404V30H352z M190,30h132v60H190V30z M428,482H84V60h76v60h192V60h76V482z" />
        <rect x="177.4" y="168.4" width="217.2" height="30" />
        <rect x="117.4" y="168.4" width="30" height="30" />
        <rect x="177.4" y="246.8" width="217.2" height="30" />
        <rect x="117.4" y="246.8" width="30" height="30" />
        <rect x="177.4" y="325.2" width="217.2" height="30" />
        <rect x="117.4" y="325.2" width="30" height="30" />
        <rect x="177.4" y="403.6" width="217.2" height="30" />
        <rect x="117.4" y="403.6" width="30" height="30" />
    </BaseIcon>
);

export default FlightTask;
