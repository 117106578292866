import React from 'react';

import BaseIcon from '../BaseIcon';

const Landing = (props) => (
    <BaseIcon {...props} viewBox="0 0 361.228 361.228">
        <path
            d="M12.348,132.041c-0.012-0.001-0.023-0.003-0.036-0.005c-4.478-0.737-8.776,2.086-9.873,6.494
            c-1.102,4.419,1.384,8.941,5.706,10.379l121.85,40.542l-40.533,16.141c-2.944,1.173-4.746,4.165-4.404,7.314
            c0.34,3.151,2.741,5.688,5.87,6.203l26.57,4.373c0.763,0.125,1.541,0.125,2.304-0.002l100.975-16.795l97.254,15.842
            c37.176,5.542,42.321-7.729,43.012-11.931c0.537-3.256,0.166-8.438-4.641-14.626c-4.875-6.279-13.269-12.348-25.652-18.553
            c-4.135-2.072-6.104-3.632-8.188-5.284c-7.334-5.807-12.791-9.106-47.809-14.871L83.206,125.736L50.492,57.958
            c-1.234-2.556-3.634-4.351-6.436-4.812l-12.517-2.061c-2.577-0.424-5.208,0.329-7.168,2.053
            c-1.962,1.724-3.048,4.236-2.958,6.845l2.525,74.013L12.348,132.041z"
        />
        <path
            d="M342.707,277.051H16.604C7.434,277.051,0,284.484,0,293.654s7.434,16.604,16.604,16.604h326.103
            c9.17,0,16.605-7.436,16.605-16.604S351.877,277.051,342.707,277.051z"
        />
    </BaseIcon>
);

export default Landing;
