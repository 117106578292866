import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import moment from 'moment';

import { DialogControls } from '../../../components/DialogControls/DialogControls';
import { useHideModal } from '../../../actions/modal';
import { saveCheckAltitudeData } from '../../../actions/navigation';
import { NavType } from '../../../components/NavigationRoutes/constants';
import { useStyles } from './useStyles';
import { useMobileWidth } from '../../../components/hooks/useMobileWidth';
import { useForm } from '../../../components/hooks/useForm/useForm';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../../components/material-ui/Dialog';
import { FormControl } from '../../../components/material-ui/FormControl';
import { TextField } from '../../../components/material-ui/TextField';
import { CustomTimePicker } from '../../../components/CustomTimePicker/CustomTimePicker';
import { clonePoint, getFields, getFieldType, PointFields } from '../tools';
import { ALTIMETER_WARNING_LIMIT } from '../../../service/constants';
import { Typography } from '../../../components/material-ui/Typography';
import { findPrevCheckAltitude } from '../../../service/NavPointsUtils';
import { getCurrentPoints } from '../../../selectors/sended-data';

const fieldList = [
    PointFields.FL,
    PointFields.CAPTAIN_ALTIMETER,
    PointFields.FIRST_OFFICER_ALTIMETER,
    PointFields.STANDBY_ALTIMETER,
    PointFields.DATE_CREATE,
    PointFields.COMMENT,
];

const CheckAltitude = React.forwardRef((props, ref) => {
    const { isNewPoint = false, pos, point } = props;
    const classes = useStyles();
    const { isMobilePortrait } = useMobileWidth();
    const hideModal = useHideModal();
    const dispatch = useDispatch();
    const points = useSelector((state) => getCurrentPoints(state), shallowEqual);
    const prevCheckAltitude = findPrevCheckAltitude(points, pos);
    const handleSuccess = React.useCallback(
        (data) => {
            dispatch(saveCheckAltitudeData(pos, data, isNewPoint));
            hideModal();
        },
        [pos, isNewPoint]
    );

    const handleCheckStatus = React.useCallback((data, changeStatus) => {
        const { captainAltimeter, firstOfficerAltimeter, fl } = data;
        const altimetersDifferenceWarning =
            captainAltimeter &&
            firstOfficerAltimeter &&
            Math.abs(captainAltimeter - firstOfficerAltimeter) >= ALTIMETER_WARNING_LIMIT &&
            'The difference between the altimeters is more than 200 feets. It is recommended to go to the Fl < 290 and continue the route.\n';
        const incorrectFlightLevelWarning =
            fl &&
            captainAltimeter &&
            Math.abs(fl * 100 - captainAltimeter) >= ALTIMETER_WARNING_LIMIT &&
            'Flight level is not consistent with the values of the altimeters';

        const message = `${altimetersDifferenceWarning || ''}${incorrectFlightLevelWarning || ''}`;

        changeStatus((prevState) => ({ ...prevState, message }));
    }, []);

    const fields = React.useMemo(() => getFields(fieldList), []);

    const { inputs, handleInputChange, handleSubmit, status } = useForm({
        onSuccess: handleSuccess,
        onCheckStatus: handleCheckStatus,
        initialData: {
            ...clonePoint(fieldList, point),
            ...(isNewPoint && {
                ...clonePoint(
                    fieldList.filter((item) => item !== PointFields.FL),
                    points[prevCheckAltitude] || {}
                ),
                isUserAdd: 1,
                pointNumber: point.pointNumber,
                comment: '',
            }),
            type: NavType.ALTITUDE,
            dateCreate: point.dateCreate || isNewPoint ? moment.utc(point.dateCreate) : null,
        },
        fields,
    });

    const dateCreate = get(inputs, 'dateCreate', '');
    const disableSubmit = Boolean(
        fieldList.find((key) => Object.values(status || {}).some((item) => item) || (fields[key].required && !inputs[key]))
    );
    const actions = React.useMemo(
        () => [
            { label: 'CANCEL', onClick: hideModal },
            { label: 'OK', color: 'primary', type: 'submit', disabled: disableSubmit },
        ],
        [disableSubmit]
    );

    return (
        <Dialog ref={ref} open={true} maxWidth="sm" fullScreen={isMobilePortrait}>
            <DialogTitle className={classes.title}>{isNewPoint ? 'Add check altitude' : 'Edit check altitude'}</DialogTitle>
            <form className={classes.fields} onSubmit={handleSubmit}>
                <DialogContent className={classes.content}>
                    <FormControl className={classes.formControl}>
                        <TextField
                            name="fl"
                            label="FL"
                            placeholder="FL"
                            onChange={handleInputChange}
                            type={getFieldType(PointFields.FL)}
                            helperText={status.fl}
                            error={Boolean(status.fl)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            value={get(inputs, 'fl', '')}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <FormControl className={classes.date}>
                        <CustomTimePicker
                            name="dateCreate"
                            label="Time"
                            placeholder="Time"
                            fullWidth={true}
                            helperText={status.dateCreate}
                            error={Boolean(status.dateCreate)}
                            onChange={handleInputChange}
                            style={{ width: '100%' }}
                            value={dateCreate ? moment.utc(dateCreate) : null}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            name="captainAltimeter"
                            label="Captain altimeter"
                            placeholder="Captain altimeter"
                            onChange={handleInputChange}
                            type={getFieldType(PointFields.CAPTAIN_ALTIMETER)}
                            helperText={status.captainAltimeter}
                            error={Boolean(status.captainAltimeter)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            value={get(inputs, 'captainAltimeter', '')}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            name="standbyAltimeter"
                            label="Standby"
                            placeholder="Standby"
                            type={getFieldType(PointFields.STANDBY_ALTIMETER)}
                            helperText={status.standbyAltimeter}
                            error={Boolean(status.standbyAltimeter)}
                            onChange={handleInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            value={get(inputs, 'standbyAltimeter', '')}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            name="firstOfficerAltimeter"
                            label="First Officer"
                            placeholder="First Officer"
                            onChange={handleInputChange}
                            type={getFieldType(PointFields.FIRST_OFFICER_ALTIMETER)}
                            helperText={status.firstOfficerAltimeter}
                            error={Boolean(status.firstOfficerAltimeter)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            value={get(inputs, 'firstOfficerAltimeter', '')}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <FormControl className={classes.comment}>
                        <TextField
                            name="comment"
                            label="Comment"
                            onChange={handleInputChange}
                            type={getFieldType(PointFields.COMMENT)}
                            helperText={status.comment}
                            error={Boolean(status.comment)}
                            fullWidth={true}
                            multiline={true}
                            value={get(inputs, 'comment', '')}
                            minRows={1}
                            maxRows={5}
                            className={classes.textarea}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <Typography color="error">{status.message}</Typography>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <DialogControls actions={actions} />
                </DialogActions>
            </form>
        </Dialog>
    );
});

export { CheckAltitude };
