import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

import { useStyles } from './useStyles';
import { Chip } from '../material-ui/Chip';
import { Typography } from '../material-ui/Typography';
import { getCurrentDutyInfo } from '../../selectors/sended-data';

const DutyTimeCounter = () => {
    const { dutyTime, startWorkTime } = useSelector(getCurrentDutyInfo);
    const [currentDutyTime, changeDutyTime] = React.useState();
    const shortView = useMediaQuery('(max-width: 700px)');
    const classes = useStyles();

    const handleTick = React.useCallback(() => {
        const currentTime = moment.utc();
        if (startWorkTime && startWorkTime.isBefore(currentTime)) {
            const time = dutyTime + currentTime.diff(startWorkTime, 'minute');
            changeDutyTime(moment.utc('20100101').add(time, 'minute').format('HH:mm'));
        } else if (dutyTime) {
            const time = moment.utc('20100101').add(dutyTime, 'minute');
            changeDutyTime(time.format('HH:mm'));
        } else {
            changeDutyTime(null);
        }
    }, [dutyTime, startWorkTime]);

    React.useEffect(() => {
        const timer = setInterval(handleTick, 1000);

        return () => clearInterval(timer);
    }, [handleTick]);

    return currentDutyTime ? (
        <Chip
            variant="outlined"
            label={
                <Typography>
                    {shortView ? '' : 'Current duty time '}
                    <span className={classes.dutyTime}>{currentDutyTime}</span>
                </Typography>
            }
        />
    ) : (
        <></>
    );
};

const DutyTimeCounterMemoized = React.memo(DutyTimeCounter);

export { DutyTimeCounterMemoized as DutyTimeCounter };
