import React from 'react';

const FilterSelectField = (props) => {
    const { filter, options, onChange } = props;
    const handleChange = React.useCallback(
        (event) => {
            event.preventDefault();
            onChange(event.target.value);
        },
        [onChange]
    );

    return (
        <select value={filter ? filter.value : 'all'} style={{ width: '100%' }} onChange={handleChange}>
            <option value="all">All</option>
            {options &&
                options.map((item) => (
                    <option key={item.value} value={item.value}>
                        {item.label}
                    </option>
                ))}
        </select>
    );
};

const FilterSelectFieldMemoized = React.memo(FilterSelectField);

export { FilterSelectFieldMemoized as FilterSelectField };
