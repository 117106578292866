import React from 'react';

import BaseIcon from '../BaseIcon';

const TwoArrowBifurcation = (props) => (
    <BaseIcon {...props} viewBox="0 0 923.109 923.109">
        <path
            d="M103.742,236.682c13.329,0,26.667-5.046,36.897-15.155l41.952-41.452c3.311,26.822,10.082,61.602,23.729,99.967
            c17.558,49.354,42.955,95.17,75.487,136.175c40.371,50.883,91.724,94.251,152.631,128.9
            c48.25,27.447,88.791,61.309,120.494,100.644c25.278,31.363,45.167,66.424,59.113,104.208
            c24.195,65.56,22.195,117.487,22.173,118.004l0.015-0.287l0.043,0.002v2.921c0,28.995,23.505,52.5,52.5,52.5s52.5-23.505,52.5-52.5
            V179.64l40.748,41.239c20.379,20.625,53.619,20.825,74.244,0.445c20.626-20.379,20.825-53.62,0.445-74.245L726.801,15.6
            c-20.378-20.624-53.618-20.825-74.244-0.445L519.285,146.837c-20.625,20.379-20.825,53.62-0.445,74.245
            c10.271,10.394,23.804,15.6,37.347,15.6c13.329,0,26.667-5.046,36.897-15.155l43.191-42.677v400.526
            c-39.951-49.427-90.362-91.647-149.919-125.526c-48.25-27.448-88.791-61.309-120.494-100.644
            c-25.277-31.364-45.167-66.424-59.111-104.208c-9.68-26.224-15.164-50.261-18.264-69.705l41.092,41.586
            c20.38,20.625,53.619,20.825,74.243,0.445c20.625-20.379,20.825-53.62,0.445-74.245L274.356,15.6
            c-20.378-20.624-53.618-20.825-74.244-0.445L66.84,146.837c-20.625,20.379-20.825,53.62-0.445,74.245
            C76.665,231.476,90.199,236.682,103.742,236.682z"
        />
    </BaseIcon>
);

export default TwoArrowBifurcation;
