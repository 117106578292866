import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    const {
        palette: { textColor, primary3Color, borderColor },
        crew: { cabin, salon },
        table: { secondaryColor },
    } = theme;

    return {
        header: {
            color: primary3Color,
            borderColor: borderColor,
        },
        salonToolbar: {
            background: salon,
        },
        cabinToolbar: {
            background: cabin,
        },
        headerCell: {
            padding: '10px 0',
            borderBottom: '1px solid',
            fontWeight: 'normal',
        },
        panel: {
            flexBasis: '100%',
            margin: '6px 0 12px',
            paddingBottom: 5,
        },
        secondaryRow: {
            color: textColor,
            background: secondaryColor,
        },
        table: {
            width: '100%',
            textAlign: 'center',
            borderSpacing: 0,
        },
        tableFoot: {
            padding: '10px 0',
            fontWeight: 600,
            '& > td': {
                padding: '10px 0 5px',
                borderTop: '1px solid',
            },
        },
        toolbar: {
            whiteSpace: 'pre',
        },
        flight: {
            minWidth: '120px',
        },
        pf: {
            minWidth: '35px',
        },
    };
});
