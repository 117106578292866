import React from 'react';

export const useSidebarSelect = (items, setFilterInputs) => {
    const itemsValue = items && items.map((item) => item.value);

    const handleChangeSelect = React.useCallback(
        ({ target }) =>
            setFilterInputs((prevState) => ({
                ...prevState,
                [target.name]: +target.value,
            })),
        []
    );

    const handleChangeMultiSelect = React.useCallback(({ target: { name, value } }) => {
        const values = typeof value === 'string' ? value.split(',') : value;

        setFilterInputs((prevState) => {
            const isSelectAll = (value) => Number(value) === -1;
            const isPositiveInteger = (value) => Number.isInteger(Number(value)) && Number(value) >= 0;
            const isValidNumber = (value) => !Number.isNaN(Number(value));

            const shouldSetAll = values.some(isSelectAll);
            const filterValues = values.filter((value) => !isValidNumber(value) || isPositiveInteger(value));

            return {
                ...prevState,
                [name]: shouldSetAll
                    ? (prevState[name] && prevState[name].length) === (itemsValue && itemsValue.length)
                        ? []
                        : itemsValue
                    : filterValues,
            };
        });
    }, []);

    return { handleChangeSelect, handleChangeMultiSelect };
};
