import { createSelector } from 'reselect';
import { find, get } from 'lodash';
import moment from 'moment';

import { reportSort, sortObjByField } from '../service/sort';
import { FilterTypes } from '../reducers/filter';

const getReportsOptions = createSelector(
    (state) => get(state, 'reports.reportsOptions'),
    (state) => get(state, 'reports.planFlights'),
    ({ groups = [], types = [] }, planFlights = []) => {
        return {
            groups: groups.slice().sort(sortObjByField('name')),
            types: types.slice().sort(sortObjByField('name')),
            planFlights: planFlights.slice().sort(sortObjByField('flightDate'), moment),
        };
    }
);

const getReports = createSelector(
    (state) => get(state, 'screen', {}),
    (state) => get(state, 'images', []),
    (state) => get(state, `filter[${FilterTypes.REPORT_FILTER}]`, {}),
    (state) => get(state, 'reports', {}),
    ({ currentLeg, currentUser }, images, { groups = [], types = [] }, { reports = [], showFilter = false }) => ({
        reportGroupTypes: groups,
        reportTypes: types,
        showFilter,
        reports: reports
            .reduce((res, report) => {
                if (report.userId === currentUser.id || report.flightTask) {
                    if (
                        (groups.length === 0 || find(groups, (i) => +i.typeId === +report.safetyGroupTypeId)) &&
                        (types.length === 0 || find(types, (i) => +i.typeId === +report.safetyTypeId)) &&
                        (!showFilter || +report.planFlightId === +currentLeg)
                    ) {
                        res.push({
                            ...report,
                            images: report.images.map((img) => ({
                                ...img,
                                url: get(images[`si_${img.id}`], 'url'),
                            })),
                        });
                    }
                }

                return res;
            }, [])
            .sort(reportSort),
    })
);

export { getReports, getReportsOptions };
