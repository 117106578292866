import { find, get, isString, merge } from 'lodash';
import { dataBufferToObjectURL } from './ImageUtils';

function mergeModel(model, obj, data, telegrams, telexes) {
    return Object.keys(model).reduce(
        (res, name) => {
            const fieldData = data[name];

            if (fieldData !== undefined) {
                let modelField = model[name];

                if (isString(modelField)) {
                    modelField = { name: modelField };
                }

                switch (modelField.type) {
                    case 'image': {
                        let preview;
                        const { contentType } = fieldData;

                        if (fieldData.data && contentType && contentType.indexOf('image') !== -1) {
                            const blob = new Blob([new Uint8Array(fieldData.data.data)], { type: contentType });
                            preview = window.URL.createObjectURL(blob);
                        }

                        obj[name] = {
                            data: fieldData.data,
                            contentType,
                            preview,
                        };

                        break;
                    }
                    case 'sended-data': {
                        const ofpSignatureData = get(fieldData, 'signature.data');
                        const ofpSignatureContentType = get(fieldData, 'signature.contentType');
                        const ofpNewFieldData = Object.assign({}, fieldData);

                        if (ofpSignatureData && ofpSignatureContentType) {
                            ofpNewFieldData.signature.preview = dataBufferToObjectURL(ofpSignatureData, ofpSignatureContentType);
                        }

                        res.sendedData.ids[data.id] = ofpNewFieldData;

                        break;
                    }
                    case 'signature': {
                        let signatureData = get(fieldData, 'data');
                        let signatureContentType = get(fieldData, 'contentType');
                        let newFieldData = Object.assign({}, fieldData);

                        if (signatureData && signatureContentType) {
                            newFieldData.preview = dataBufferToObjectURL(signatureData, signatureContentType);
                        }

                        obj[name] = {
                            data: newFieldData.data,
                            contentType: newFieldData.contentType,
                            preview: newFieldData.preview,
                        };

                        break;
                    }
                    case 'documents':
                        if (!obj[name]) {
                            obj[name] = [];
                        }

                        fieldData.forEach((doc) => {
                            const docs = (obj[name] = [].concat(obj[name]));

                            let docIndex = docs.findIndex((objDoc) => objDoc.id == doc.id);

                            if (docIndex !== -1) {
                                docs[docIndex] = Object.assign({}, doc);
                            } else {
                                docs.push(doc);
                            }

                            res.newDocuments.push(doc);
                        });
                        break;

                    case 'defects':
                        if (!obj[name]) {
                            obj[name] = [];
                        }

                        fieldData.forEach((defect) => {
                            const defects = (obj[name] = [].concat(obj[name]));

                            let defectIndex = defects.findIndex((objDefect) => objDefect.id == defect.id);

                            if (defectIndex !== -1) {
                                defects[defectIndex] = Object.assign({}, defect);
                            } else {
                                defects.push(defect);
                            }

                            res.newDefects.push(defect);
                        });
                        break;
                    case 'telegrams':
                        if (!obj[name]) {
                            obj[name] = [];
                        }

                        fieldData.forEach((telegram) => {
                            const objTelegrams = (obj[name] = [].concat(obj[name]));

                            let telegramIndex = objTelegrams.findIndex((objTelegram) => objTelegram.id === telegram.id);
                            const newTelegram = { ...((telegrams && telegrams[telegram.id]) || {}), ...telegram };
                            if (telegramIndex !== -1) {
                                objTelegrams[telegramIndex] = newTelegram;
                            } else {
                                objTelegrams.push(newTelegram);
                            }
                            res.newTelegrams.push(newTelegram);
                        });
                        break;
                    case 'telex':
                        if (!obj[name]) {
                            obj[name] = [];
                        }
                        fieldData.forEach((telex) => {
                            const objTelexes = (obj[name] = [].concat(obj[name]));
                            const newTelex = { ...((telexes && telexes[telex.id]) || {}), ...telex };

                            let telexIndex = objTelexes.findIndex((objTelex) => objTelex.id === telex.id);

                            if (telexIndex !== -1) {
                                objTelexes[telexIndex] = newTelex;
                            } else {
                                objTelexes.push(newTelex);
                            }
                            res.newTelexes.push(newTelex);
                        });
                        break;
                    case 'routeAirports':
                        if (!obj[name]) {
                            obj[name] = [];
                        }
                        if (!res.routeAirports[data.id]) {
                            res.routeAirports[data.id] = {};
                        }

                        fieldData.forEach((airport) => {
                            res.routeAirports[data.id][airport.id] = { ...airport };
                        });

                        delete obj[name];
                        break;
                    case 'crew':
                        obj[name] = {};

                        fieldData.forEach((newItem) => {
                            obj[name][+newItem.id] = { ...newItem };
                        });
                        break;
                    case 'checkList': {
                        Object.keys(fieldData).forEach((key) => {
                            res.checkList[`${data.id}_${key}`] = { legId: data.id, ...fieldData[key] };
                        });
                        break;
                    }
                    case 'model-list': {
                        let modelChanges = (res.childrenChanges[name] = { newItems: [] });

                        if (!obj[name]) {
                            obj[name] = {};
                        }

                        fieldData.forEach((newItem) => {
                            let currentItem = find(obj[name], (f) => f.id == newItem.id);
                            const isNewItem = !currentItem;

                            if (isNewItem) {
                                currentItem = obj[name][newItem.id] = {};
                                modelChanges.newItems[newItem.id] = newItem;
                            }

                            let updates = mergeModel(modelField.model, currentItem, newItem, telegrams, telexes);

                            res.newDocuments = res.newDocuments.concat(updates.newDocuments);
                            res.newDefects = res.newDefects.concat(updates.newDefects);
                            res.newTelegrams = res.newTelegrams.concat(updates.newTelegrams);
                            res.newTelexes = res.newTelexes.concat(updates.newTelexes);

                            res.sendedData = merge(res.sendedData || {}, updates.sendedData);
                            res.routeAirports = merge(res.routeAirports || {}, updates.routeAirports);
                            res.checkList = merge(res.checkList || {}, updates.checkList);

                            if (!isNewItem) {
                                modelChanges.changes = updates.changes;
                                modelChanges.childrenChanges = updates.childrenChanges;
                                obj[name][currentItem.id] = Object.assign({}, currentItem);
                            }
                        });
                        break;
                    }
                    default:
                        obj[name] = fieldData;
                }

                res.changes.push(modelField);
            }

            return res;
        },
        {
            changes: [],
            newDocuments: [],
            childrenChanges: {},
            sendedData: { ids: {} },
            newDefects: [],
            newTelegrams: [],
            newTelexes: [],
            routeAirports: {},
            checkList: {},
        }
    );
}

export default mergeModel;
