import React from 'react';
import { useSwipeable, LEFT, RIGHT, UP, DOWN } from 'react-swipeable';
import { swipeDirections, swipeConfig } from './constant';

const initialState = { dragX: 0, dragY: 0, swiping: false };

export const Swipeable = React.memo(({ draggable, maxDragShift, swipeDirection, onSwiped, onSwiping, className, ...props }) => {
    const [state, setState] = React.useState(initialState);

    const handleSwipeStart = React.useCallback(
        (SwipeEventData) => {
            const direction = swipeDirections[SwipeEventData.dir.toUpperCase()];

            if (draggable && swipeDirection && direction & swipeDirection) {
                setState((prevState) => ({
                    ...prevState,
                    swiping: true,
                }));
            }
        },
        [draggable, swipeDirection]
    );

    const handleSwiping = React.useCallback(
        (SwipeEventData) => {
            const direction = swipeDirections[SwipeEventData.dir.toUpperCase()];

            if (draggable && state.swiping && direction & swipeDirection) {
                if (
                    (SwipeEventData.dir === LEFT || SwipeEventData.dir === RIGHT) &&
                    (!maxDragShift || (maxDragShift && SwipeEventData.absX <= maxDragShift))
                ) {
                    setState((prevState) => ({
                        ...prevState,
                        dragX: SwipeEventData.deltaX,
                    }));
                } else if (
                    (SwipeEventData.dir === UP || SwipeEventData.dir === DOWN) &&
                    (!maxDragShift || SwipeEventData.absY <= maxDragShift)
                ) {
                    setState((prevState) => ({
                        ...prevState,
                        dragY: SwipeEventData.deltaY,
                    }));
                }
            }

            if (onSwiping) {
                onSwiping(SwipeEventData);
            }
        },
        [draggable, state.swiping, maxDragShift, onSwiping]
    );

    const handleTouchEnd = React.useCallback(() => {
        setState(initialState);
    }, []);

    const handlers = useSwipeable({
        onSwiped: !swipeDirection ? onSwiped : null,
        onSwipedLeft: swipeDirection & swipeDirections.LEFT ? onSwiped : null,
        onSwipedRight: swipeDirection & swipeDirections.RIGHT ? onSwiped : null,
        onSwipedUp: swipeDirection & swipeDirections.UP ? onSwiped : null,
        onSwipedDown: swipeDirection & swipeDirections.DOWN ? onSwiped : null,
        onSwipeStart: handleSwipeStart,
        onSwiping: handleSwiping,
        onTouchEndOrOnMouseUp: handleTouchEnd,
        ...swipeConfig,
    });

    return (
        <div className={className} style={{ transform: `translateX(${state.dragX}px) translateY(${state.dragY}px)` }} {...handlers}>
            {props.children}
        </div>
    );
});
