import React from 'react';

import { List } from '../../material-ui/List';
import { ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction } from '../../material-ui/List';
import { Collapse } from '../../material-ui/Collapse';
import { useStyles } from './useStyles';

const FolderItem = (props) => {
    const { style, onClick, primary, disabled, secondary, children, inset, icon, rightIcon, open } = props;
    const classes = useStyles();

    return (
        <>
            <ListItem onClick={onClick} disabled={disabled} style={style} button={true}>
                {icon && <ListItemIcon style={{ minWidth: '40px' }}>{icon}</ListItemIcon>}
                <ListItemText inset={inset} primary={primary} secondary={secondary} className={classes.text} />
                {rightIcon && <ListItemSecondaryAction className={classes.menu}>{rightIcon}</ListItemSecondaryAction>}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {/* TODO add to List disablePadding={true} */}
                <List component="div">{children}</List>
            </Collapse>
        </>
    );
};

FolderItem.defaultProps = {
    style: {},
    inset: true,
};

const FolderItemMemoized = React.memo(FolderItem);

export { FolderItemMemoized as FolderItem };
