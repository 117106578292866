const checkFilterDependencies = (item, dependencies, filters) => {
    const isEmptyOrIncorrect = (dependency) => !filters[dependency] || !item[dependency] || filters[dependency].toString() === '0';

    return (
        !dependencies?.length ||
        dependencies.reduce(
            (acc, cur) =>
                acc &&
                (isEmptyOrIncorrect(cur) ||
                    (typeof filters[cur] === 'number' && filters[cur] === item[cur]) ||
                    (typeof filters[cur] === 'object' && filters[cur].includes(item[cur]))),
            true
        )
    );
};

const prepareSelectListItem = ({ text, value, ...rest }, emptyValueText) => ({
    ...rest,
    label: text ? text : emptyValueText,
    value: value.toString(),
});

export const getSelectListItems = ({ data, settings, filterInputs }) =>
    data.reduce((acc, cur) => {
        if (checkFilterDependencies(cur, settings.dependencies, filterInputs)) {
            acc.push(prepareSelectListItem(cur, settings.emptyValueText));
        }

        return acc;
    }, []);
