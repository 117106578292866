import React, { memo, useCallback, forwardRef } from 'react';
import { TextField } from '../../../material-ui/TextField';
import { useStyles } from './useStyles';
import { darkFullWhite } from '../../../../service/getMuiThemeStyle';
import { Tooltip } from '../../../material-ui/Tooltip';

import { IconButton } from '../../../material-ui/IconButton';
import { Refresh } from '../../../../icons';
import { Typography } from '../../../material-ui/Typography';
import { Timer } from '../../../Timer/Timer';

const REFRESH_TIMEOUT = 60;

export const TwoFactor = memo(
    forwardRef(({ onChange, value, dark, refreshBlocked, changeRefreshBlocked, onRequestCode, sendingInfo, isWhiteColor = true }, ref) => {
        const classes = useStyles({ isWhiteColor });

        const handleTimeEnd = useCallback(() => {
            changeRefreshBlocked(false);
        }, [changeRefreshBlocked]);

        return (
            <>
                <TextField
                    ref={ref}
                    required={true}
                    type="confirmationCode"
                    name="confirmationCode"
                    label="Confirmation code"
                    onChange={onChange}
                    value={value}
                    fullWidth={true}
                    className={classes.field}
                    InputProps={{
                        style: {
                            color: isWhiteColor ? darkFullWhite : 'default',
                        },
                        endAdornment: refreshBlocked ? (
                            <Tooltip title={'Refresh code'}>
                                <Timer seconds={REFRESH_TIMEOUT} onTimeEnd={handleTimeEnd} />
                            </Tooltip>
                        ) : (
                            <IconButton className={classes.icon} onClick={onRequestCode}>
                                <Refresh fontSize="small" />
                            </IconButton>
                        ),
                    }}
                    {...(dark && {
                        InputLabelProps: {
                            style: {
                                color: darkFullWhite,
                            },
                        },
                    })}
                />
                {!!sendingInfo && (
                    <Typography className={classes.sendingInfo} variant={'caption'}>
                        We will send the confirmation code to: <span>{sendingInfo}</span>
                    </Typography>
                )}
            </>
        );
    })
);
