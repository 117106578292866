import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        flexGrow: 0,
        flexShrink: 1,
        flexBasis: '8%',
        marginTop: theme.spacing(1),

        ['@media only screen and (max-width: 480px)']: {
            marginTop: 10,
            marginBottom: 10,
        },

        ['@media (max-width: 480px)']: {
            flexBasis: '48% !important',
        },
    },
    inputBad: {
        color: 'red',
    },
    inputDone: {
        color: 'green',
    },
    subtitle: {
        marginTop: 6,
        marginBottom: 0,
        fontSize: '18px',
    },
}));
