import React from 'react';
import classnames from 'classnames';

import { convertTime, removeSeconds } from '../../service/DateTimeUtils';
import { CustomTimePicker } from '../CustomTimePicker/CustomTimePicker';
import { KeyboardDatePicker } from '../material-ui/pickers/KeyboardDatePicker';
import { useStyles } from './useStyles';

const DateTimePicker = (props) => {
    const {
        placeholder,
        name,
        className = '',
        disabled = false,
        canBeEmpty,
        onChange,
        labelTextDate = '',
        labelTextTime = '',
        error,
    } = props;

    const classes = useStyles(labelTextDate, labelTextTime)();
    const value = props.value ? convertTime(props.value) : null;

    const handleChange = React.useCallback(
        (event, val) => {
            onChange(name, removeSeconds(val));
        },
        [name]
    );

    const handleClear = React.useCallback(() => {
        onChange(name, null);
    }, [name]);

    // Осталось сделать следующее:
    // 1. Опционально прятать иконку у календаря и делать, чтобы срабатывало нажатие на любой элемент
    // 2. Если оба поля пустых, то при нажатии на время - должна текущая дата проставляться.
    // 3. Скачет поле времени при вводе с клавиатуры во время показа ошибки
    // 4. Проверить как это все работает вообще в диалогах.

    return (
        <div className={classnames(classes.container, className)}>
            <KeyboardDatePicker
                key={`dp_${name}`}
                className={classes.datePicker}
                onChange={handleChange}
                value={value}
                label={labelTextDate}
                disabled={disabled}
                error={error}
            />
            <CustomTimePicker
                key={`tp_${name}`}
                name="native_controlled"
                value={value}
                className={classes.timePicker}
                onChange={handleChange}
                label={labelTextTime}
                placeholder={placeholder}
                showClear={canBeEmpty}
                disabled={disabled}
                onClear={handleClear}
                fullWidth={true}
                error={error}
            />
        </div>
    );
};

const DateTimePickerMemoized = React.memo(DateTimePicker);

export { DateTimePickerMemoized as DateTimePicker };
