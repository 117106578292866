import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
    },
    form: {
        flex: '1 0 auto',
    },
    panelWrapper: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',

        flex: '0 0 auto',
    },
    panel: {
        flexGrow: 1,
        flexBasis: '100%',
        maxWidth: '100%',
        order: 1,

        ['@media only screen and (max-width:480px)']: {
            maxWidth: 'none',
            flexGrow: 1,
        },

        ['@media (min-width:768px)']: {
            flexBasis: '30%',
        },
    },
    specialPanel: {
        order: 2,
        flexBasis: '100%',
        maxWidth: 'none',

        ['@media (min-width:960px)']: {
            flexBasis: '30%',
            maxWidth: 300,
            order: 1,
        },
    },
}));
