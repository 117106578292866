import React from 'react';

import { DialogDateTimePicker } from './DialogDateTimePicker/DialogDateTimePicker';
import { SummaryCrewLegModal } from './SummaryCrewLegModal/SummaryCrewLegModal';
import { Report } from './Report/Report';
import { ReportDetail } from './ReportDetail/ReportDetail';
import { ReportsLoading } from './ReportsLoading/ReportsLoading';
import { Setting } from './Setting/Setting';
import { EmployeeModal } from './EmployeeModal/EmployeeModal';
import { SendedDataModal } from './SendedDataModal/SendedDataModal';
import { RestoreModal } from './RestoreModal/RestoreModal';
import FlightTaskModal from './FlightTaskModal/FlightTaskModal';
import { TelegramViewer } from './TelegramViewer/TelegramViewer';
import { NoticeModal } from './NoticeModal/NoticeModal';
import { ReportFilter } from './ReportFilter/ReportFilter';
import { Modal } from '../components/material-ui/Modal';
import { ShortPoint, Point, SpecialPoint, Comment, CheckAltitude, Hold, MultieditPoint, AlternateRoute, Direct, Offset } from './Point';
import { TYPES } from '../actions/modal';
import { useModalWrapperProps } from '../selectors/modals';
import { ChangeUser } from './ChangeUser/ChangeUser';
import { Relogin } from './Relogin/Relogin';
import { Passenger } from './Passenger/Passenger';
import { Signature } from './Signature/Signature';
import { CheckListModal } from './CheckListModal/CheckListModal';

const getModalComponent = () => ({
    [TYPES.ALTERNATE_ROUTE]: AlternateRoute,
    [TYPES.CHANGE_USER]: ChangeUser,
    [TYPES.CHECK_ALTITUDE]: CheckAltitude,
    [TYPES.COMMENT]: Comment,
    [TYPES.DATE_TIME_PICKER]: DialogDateTimePicker,
    [TYPES.DIRECT]: Direct,
    [TYPES.EMPLOYEE]: EmployeeModal,
    [TYPES.FLIGHT_TASK]: FlightTaskModal,
    [TYPES.HOLD]: Hold,
    [TYPES.MULTIEDIT]: MultieditPoint,
    [TYPES.NOTICE]: NoticeModal,
    [TYPES.OFFSET]: Offset,
    [TYPES.PASSENGER]: Passenger,
    [TYPES.POINT]: Point,
    [TYPES.RELOGIN]: Relogin,
    [TYPES.REPORT_DETAIL]: ReportDetail,
    [TYPES.REPORT]: Report,
    [TYPES.REPORT_FILTER]: ReportFilter,
    [TYPES.REPORTS_LOADING]: ReportsLoading,
    [TYPES.RESTORE]: RestoreModal,
    [TYPES.SEND_DATA]: SendedDataModal,
    [TYPES.SETTING]: Setting,
    [TYPES.SIGNATURE]: Signature,
    [TYPES.SHORT_POINT]: ShortPoint,
    [TYPES.SPECIAL_POINT]: SpecialPoint,
    [TYPES.SUMMARY_CREW_LEG]: SummaryCrewLegModal,
    [TYPES.TELEGRAM_VIEWER]: TelegramViewer,
    [TYPES.CHECK_LIST]: CheckListModal,
});

const CustomModal = ({ hideModal }) => {
    const { modalProps, modalType } = useModalWrapperProps();
    if (!modalType) {
        return null;
    }
    const ModalComponent = getModalComponent(modalProps.type)[modalType];

    return (
        ModalComponent && (
            <Modal onClose={hideModal} open={!!modalType}>
                <ModalComponent {...modalProps} />
            </Modal>
        )
    );
};

export { CustomModal as Modal };
