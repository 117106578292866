import React from 'react';

import { AppBar } from '../../../components/material-ui/AppBar';
import { Tabs, Tab } from '../../../components/material-ui/Tabs';
import Target from '../../../icons/image/Target';
import Clock from '../../../icons/image/Clock';
import Route from '../../../icons/image/Route';

const SettingTabs = (props) => {
    const { onChange, tabIndex } = props;

    return (
        <AppBar position="static" color="default">
            <Tabs
                value={tabIndex}
                onChange={onChange}
                variant="scrollable"
                scrollButtons="off"
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable force tabs example"
            >
                <Tab label="Date/Time" icon={<Clock width={24} height={24} style={{ marginBottom: '4px' }} />} />
                <Tab label="GPS" icon={<Target width={24} height={24} style={{ marginBottom: '4px' }} />} />
                <Tab label="Enroute" icon={<Route width={24} height={24} style={{ marginBottom: '4px' }} />} />
            </Tabs>
        </AppBar>
    );
};

const SettingTabsMemoized = React.memo(SettingTabs);

export { SettingTabsMemoized as SettingTabs };
