import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { ActionTimeControl } from '../../ActionTimeControl/ActionTimeControl';
import { MoreVertIconContrast } from '../../../icons/MoreVertIconContrast';
import { getCurrentSendedLanding, getPreviewWorkTime } from '../../../selectors/sended-data';
import { Engine } from '../../../icons';
import { checkTimeIsCorrect } from '../../../service/NavPointsUtils';

const StopEngineTime = ({ className, onShowModal, onChangeTime }) => {
    const { blockTime, value, timeEngineWorkAfter } = useSelector((state) => {
        const landing = getCurrentSendedLanding(state);
        const previewWorkTime = getPreviewWorkTime(state);

        return {
            value: landing.stopEngineTime,
            blockTime: previewWorkTime.blockTime,
            timeEngineWorkAfter: previewWorkTime.timeEngineWorkAfter,
        };
    }, shallowEqual);

    const stopEngineSummary = React.useMemo(() => {
        return [
            {
                title: 'Engine run after landing ',
                value: timeEngineWorkAfter,
                isCorrect: checkTimeIsCorrect(timeEngineWorkAfter),
            },
            {
                title: 'Block time ',
                value: blockTime,
                isCorrect: checkTimeIsCorrect(blockTime),
            },
        ];
    }, [timeEngineWorkAfter, blockTime]);
    return (
        <ActionTimeControl
            title="Stop engine time"
            buttonName="Stop engine"
            iconButton={<Engine width="30" height="30" />}
            iconMenuImg={<MoreVertIconContrast isContrast={true} />}
            text="Shutdown on"
            name="stopEngineTime"
            value={value}
            summary={stopEngineSummary}
            onChange={onChangeTime}
            showModal={onShowModal}
            className={className}
        />
    );
};

const StopEngineTimeMemoized = React.memo(StopEngineTime);

export { StopEngineTimeMemoized as StopEngineTime };
