import { useState, useCallback } from 'react';

export const useLogin = () => {
    const [{ login, password }, setLogin] = useState({
        login: '',
        password: '',
    });

    const handleValueChange = useCallback(
        ({ target: { name, value } }) => {
            setLogin((prevState) => ({ ...prevState, [name]: value }));
        },
        [setLogin]
    );

    return { login, password, onLoginChange: handleValueChange };
};
