import { useDispatch } from 'react-redux';

import { setHtmlOverflow } from '../components/hooks/useScrollFix';

const SHOW_MODAL = 'SHOW_MODAL';
const HIDE_MODAL = 'HIDE_MODAL';

const TYPES = {
    ALTERNATE_ROUTE: 'ALTERNATE_ROUTE',
    CHANGE_USER: 'CHANGE_USER',
    CHECK_ALTITUDE: 'CHECK_ALTITUDE',
    COMMENT: 'COMMENT',
    DATE_TIME_PICKER: 'DATE_TIME_PICKER',
    DIRECT: 'DIRECT',
    EMPLOYEE: 'EMPLOYEE',
    FLIGHT_TASK: 'FLIGHT_TASK',
    HOLD: 'HOLD',
    MULTIEDIT: 'MULTIEDIT',
    NOTICE: 'NOTICE',
    OFFSET: 'OFFSET',
    PASSENGER: 'PASSENGER',
    POINT: 'POINT',
    RELOGIN: 'RELOGIN',
    REPORT_DETAIL: 'REPORT_DETAIL',
    REPORT_FILTER: 'REPORT_FILTER',
    REPORT: 'REPORT',
    REPORTS_LOADING: 'REPORTS_LOADING',
    RESTORE: 'RESTORE',
    SEND_DATA: 'SEND_DATA',
    SETTING: 'SETTING',
    SIGNATURE: 'SIGNATURE',
    SHORT_POINT: 'SHORT_POINT',
    SPECIAL_POINT: 'SPECIAL_POINT',
    SUMMARY_CREW_LEG: 'SUMMARY_CREW_LEG',
    TELEGRAM_VIEWER: 'TELEGRAM_VIEWER',
    CHECK_LIST: 'CHECK_LIST',
};

const showModal = (modalType, modalProps) => {
    setHtmlOverflow(true);

    return {
        type: SHOW_MODAL,
        payload: {
            modalType,
            modalProps,
        },
    };
};

const useShowModal = () => {
    const dispatch = useDispatch();

    return (modalType, modalProps) => dispatch(showModal(modalType, modalProps));
};

const hideModal = () => {
    setHtmlOverflow(false);

    return { type: HIDE_MODAL };
};

const useHideModal = () => {
    const dispatch = useDispatch();

    return () => dispatch(hideModal());
};

export {
    SHOW_MODAL, //
    HIDE_MODAL,
    TYPES,
    showModal,
    hideModal,
    useHideModal,
    useShowModal,
};
