import { createSelector } from 'reselect';
import { get } from 'lodash';
import { getCurrentLeg } from './screen';
import { useSelector } from 'react-redux';

export const getDocumentsNotifications = createSelector(
    (state) => get(state, 'documents'),
    (documents) => {
        const documentList = [];
        Object.keys(documents || {}).forEach((key) => {
            const document = documents[key];
            if (!document.isDownloaded) {
                documentList.push(document);
            }
        });

        return documentList;
    }
);

const getDocuments = createSelector(
    getCurrentLeg,
    (state) => get(state, 'documents', {}),
    (leg = {}, documents) => {
        return get(leg, 'documentIds', []).map((documentId) => documents[documentId]);
    }
);

export const useDocuments = () => useSelector(getDocuments);
