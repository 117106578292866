import { makeStyles } from '@material-ui/styles';

import { darkFullWhite } from '../../../service/getMuiThemeStyle';

const useStyles = makeStyles(() => ({
    airport: {
        display: 'block',
        padding: '8px 0 6px',

        color: darkFullWhite,
        fontSize: 20,
        textAlign: 'center',
        
        backgroundColor: '#425456',
    },
    sticky: {
        transform: 'translate3d(0, 0, 0)',
        position: 'sticky',
        top: 0,
        zIndex: 99,
    },
}));

export { useStyles };
