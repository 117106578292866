import moment from 'moment';

export function getDownloadDateInfo(action, downloadDate) {
    const date = moment.utc(downloadDate);
    const currentDate = moment.utc();

    let diffMinutes = Math.round((currentDate - date) / (1000 * 60));
    let diffHours = Math.round(diffMinutes / 60);
    let diffDays = Math.round(diffHours / 24);

    if (diffDays > 1) {
        return `${action} ${diffDays} days ago`;
    } else if (diffDays === 1) {
        return `${action} a day ago`;
    } else if (diffHours > 1) {
        return `${action} ${diffHours} hours ago`;
    } else if (diffHours === 1) {
        return `${action} an hour ago`;
    } else if (diffMinutes > 1) {
        return `${action} ${diffMinutes} minutes ago`;
    } else if (diffMinutes === 1) {
        return `${action} a minute ago`;
    } else {
        return `Just ${action.toLowerCase()}`;
    }
}
