import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => {
    const { labelWarning, labelGreen, calculation } = theme.point;

    return {
        badWork: {
            color: labelWarning,
        },
        basicField: {
            fontWeight: 'bold',
            fontSize: '1.1em',
        },
        bold: {
            fontWeight: 600,
        },
        calculateField: {
            color: calculation,
        },
        cellInner: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        coloredData: {
            color: 'inherit',
        },
        sup: {
            lineHeight: '0.9em',
            fontStyle: 'italic',

            color: 'inherit',
        },
        wellDone: {
            color: labelGreen,
        },
    };
});
