import React from 'react';

import { getTimeFormat } from '../../../service/NavPointsUtils';
import { getTimeHHmm } from '../../../service/DateTimeUtils';
import { DownArrow, UpArrow } from '../../../icons';

const SummaryFieldFormat = {
    DEFAULT: 0,
    EMPTY: 1,
    TIME: 2,
    TIME_HHMM: 3,
    CUSTOM: 4,
};

export const summaryFields = [
    { id: 'work', title: 'Duty', format: SummaryFieldFormat.TIME },
    {
        id: 'workBegin',
        title: 'Start',
        format: SummaryFieldFormat.TIME_HHMM,
        totalFormat: SummaryFieldFormat.EMPTY,
    },
    { id: 'workBefore', title: 'Before', format: SummaryFieldFormat.TIME },
    {
        id: 'timeTakeoff',
        title: 'Take Off',
        format: SummaryFieldFormat.TIME_HHMM,
        totalFormat: SummaryFieldFormat.EMPTY,
    },
    { id: 'flight', title: 'Flight', format: SummaryFieldFormat.CUSTOM },
    { id: 'workAfter', title: 'After', format: SummaryFieldFormat.TIME },
    {
        id: 'workEnd',
        title: 'End',
        format: SummaryFieldFormat.TIME_HHMM,
        totalFormat: SummaryFieldFormat.EMPTY,
    },
    { id: 'block', title: 'Block', format: SummaryFieldFormat.TIME },
    { id: 'night', title: 'Night', format: SummaryFieldFormat.TIME },
    { id: 'biologicalNight', title: 'Bio', format: SummaryFieldFormat.TIME },
    { id: 'independent', title: 'Indep', format: SummaryFieldFormat.TIME },
    { id: 'parking', title: 'Parking', format: SummaryFieldFormat.TIME },
    { id: 'delay', title: 'Delay', format: SummaryFieldFormat.TIME },
    { id: 'rest', title: 'Rest', format: SummaryFieldFormat.TIME },
    { id: 'dist', title: 'Distance', format: SummaryFieldFormat.DEFAULT },
    { id: 'landings', title: 'landings', format: SummaryFieldFormat.CUSTOM },
    { id: 'pf', title: 'PF', format: SummaryFieldFormat.CUSTOM },
];

export const getDataFormat = (id, format, data) => {
    switch (format) {
        case SummaryFieldFormat.EMPTY:
            return '';
        case SummaryFieldFormat.TIME:
            return getTimeFormat(data[id]);
        case SummaryFieldFormat.TIME_HHMM:
            return getTimeHHmm(data[id]);
        case SummaryFieldFormat.CUSTOM: {
            const flightInfo = `${getTimeFormat(data.flight)}${
                data.flight !== data.timeOnDevices && data.timeOnDevices ? ` (${getTimeFormat(data.timeOnDevices)})` : ''
            }`;
            const landingsInfo = `${data.landings}${
                data.landings !== data.landingsOnDevices && data.landingsOnDevices ? ` (${data.landingsOnDevices})` : ''
            }`;
            switch (id) {
                case 'flight':
                    return flightInfo;
                case 'landings':
                    return landingsInfo;
                case 'pf':
                    return (
                        <div>
                            {data.takeoff && <UpArrow width={15} height={20} />}
                            {data.landing && <DownArrow width={15} height={20} />}
                        </div>
                    );
            }
        }
    }
    return data[id] || '';
};
