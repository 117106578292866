import { NOTIFICATION_ADD, NOTIFICATION_REMOVE } from '../actions/notifications';
import { LOGOUT } from '../actions/screen';

const notificationsReducer = (state = [], action) => {
    switch (action.type) {
        case NOTIFICATION_ADD:
            return [...state, action.notification];
        case NOTIFICATION_REMOVE:
            return [...state.filter((notification) => notification.key !== action.key)];
        case LOGOUT:
            return [];
        default:
            return state;
    }
};

export { notificationsReducer };
