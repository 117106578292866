import {
    ADD_FIELDS,
    SET_DIMENSION,
    SET_NEED_TO_CONVERT,
    RESET_IS_OFP_CHANGED,
    ADD_TO_HISTORY,
    CLEAR_HISTORY_FOR_LEG,
} from '../actions/systemOfMeasurement';
import { SET_POINTS } from '../actions/navigation';

const MeasureType = {
    WEIGHT: 'WEIGHT',
};

export const Weight = {
    EMPTY: 0,
    KG: 1,
    LBS: 2,
    T: 3,
};

const Weights = ['', 'KG', 'LBS', 'T'];

export const fields = [
    'act',
    'alw',
    'allowedTakeOffWeight',
    'baggage',
    'ballast',
    'block',
    'Cabin bag',
    'cargo',
    'dryOperatingWeight',
    'est',
    'est_default',
    'expenseAfterLanding',
    'expenseBeforeTakeoff',
    'expenseLandLanding',
    'expenseLandTakeoff',
    'ffa_default',
    'fuelFlowAvg',
    'landingWeightEstimate',
    'landingWeightMax',
    'landingWeightReal',
    'lw',
    'mail',
    'maxLoading',
    'minFuelMAPt',
    'onBoardFuel',
    'payLoadEstimate',
    'payLoadReal',
    'refuelingLanding',
    'refuelingTakeoff',
    'decisionFuel',
    'remainLanding',
    'remainTakeoff',
    'takeOffFuel',
    'takeOffWeightEstimate',
    'takeOffWeightMax',
    'takeOffWeightReal',
    'taxiFuel',
    'totalOnBoard',
    'tripFuel',
    'tow',
    'underload',
    'used',
    'used_default',
    'weight',
    'weightValidator',
    'zeroFuelWeightEstimate',
    'zeroFuelWeightMax',
    'zeroFuelWeightReal',
];

const initialState = {
    fields,
    dimension: Weight.KG,
    needToConvert: false,
    isOfpChanged: false,
    isUsedCfpWeightUnits: false,
    history: {},
};

const systemOfMeasurement = (state = { ...initialState }, action) => {
    let updatedFields;
    const history = { ...(state.history || {}) };

    switch (action.type) {
        case ADD_FIELDS:
            updatedFields = new Set([...state.fields, ...action.update.fields]);

            return {
                ...state,
                fields: Array.from(updatedFields),
            };
        case SET_DIMENSION:
            return {
                ...state,
                dimension: action.dimension,
            };
        case SET_NEED_TO_CONVERT:
            return {
                ...state,
                needToConvert: action.needToConvert,
            };
        case SET_POINTS:
            return {
                ...state,
                isOfpChanged: true,
            };
        case RESET_IS_OFP_CHANGED:
            return { ...state, isOfpChanged: false };
        case ADD_TO_HISTORY:
            return {
                ...state,
                history: {
                    ...history,
                    [action.payload.legID]: history[action.payload.legID]
                        ? [...history[action.payload.legID], action.payload.weightUnitsState]
                        : [action.payload.weightUnitsState],
                },
            };
        case CLEAR_HISTORY_FOR_LEG:
            return {
                ...state,
                history: {
                    ...Object.keys(state.history).reduce((newHistory, key) => {
                        if (action.legIds.includes(+key)) {
                            return newHistory;
                        }
                        newHistory[key] = state.history[key];

                        return newHistory;
                    }, {}),
                },
            };
        default:
            return state;
    }
};

export { systemOfMeasurement, Weights, MeasureType };
