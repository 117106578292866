import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    document: {
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
        ['@media only screen and (max-width: 640px)']: {
            padding: theme.spacing(1),
        },
    },
    downloadStatus: {
        position: 'static',
        fontSize: '14px',
        order: 1,
        textAlign: 'right',
        flex: '1 1 25%',
        transform: 'none',

        ['@media only screen and (max-width: 640px)']: {
            flexBasis: '100%',
            textAlign: 'left',
        },
    },
    text: {
        flex: '1 1 75%',

        ['@media only screen and (max-width: 640px)']: {
            flexBasis: '100%',
            marginBottom: 0,
        },
    },
}));

export { useStyles };
