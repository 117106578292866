export function checkRequired(declaration, fieldName) {
    const landing = declaration.landing || {};

    return landing[fieldName] && landing[fieldName].required;
}

export function getLabel(declaration, fieldName) {
    const landing = declaration.landing || {};

    return landing[fieldName] && landing[fieldName].title;
}
