import { HIDE_MODAL, SHOW_MODAL } from '../actions/modal';
import { LOGOUT } from '../actions/screen';

const initialModalState = {
    modalType: null,
    modalProps: {},
};

const modalReducer = (state = initialModalState, action) => {
    switch (action.type) {
        case SHOW_MODAL:
            return {
                ...state,
                modalType: action.payload.modalType,
                modalProps: action.payload.modalProps,
            };
        case HIDE_MODAL:
        case LOGOUT:
            return initialModalState;
        default:
            return state;
    }
};

export { modalReducer };
