import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { SnackbarProvider } from 'notistack';

import { LoginPage } from '../components/LoginPage/LoginPage';
import { MainContainer } from './MainContainer';
import { Modal } from '../modals';
import { Notifications } from '../components/Notifications/Notifications';
import { SidebarProvider } from '../components/PageLayout/ContextProvider';
import { PageLayout } from '../components/PageLayout/PageLayout';

const MAX_NOTIFICATIONS = 5;

const RootContainer = ({ history }) => (
    <ConnectedRouter history={history}>
        <>
            <Switch>
                <Route path="/login" component={LoginPage} />
                <SidebarProvider>
                    <PageLayout>
                        <Route path="/" component={MainContainer} />
                    </PageLayout>
                </SidebarProvider>
            </Switch>
            <Modal />
            <SnackbarProvider maxSnack={MAX_NOTIFICATIONS}>
                <Notifications />
            </SnackbarProvider>
        </>
    </ConnectedRouter>
);

export { RootContainer };
