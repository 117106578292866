import React from 'react';

import { getTimeFormat } from '../../../service/NavPointsUtils';
import { getTimeHHmm } from '../../../service/DateTimeUtils';
import { useStyles } from './useStyles';

const SummaryCrewTaskInfo = (props) => {
    const { previousTask, declaration, fields, number } = props;
    const classes = useStyles();
    const fieldList = React.useMemo(() => fields.filter(({ id }) => declaration[id]), [fields, declaration]);

    if (!previousTask) {
        return;
    }

    return (
        <>
            <tr {...(number % 2 !== 1 && { className: classes.highlightRow })}>
                <td className={classes.cell}>Task {previousTask.taskNumber}</td>
                {fieldList.map(({ id }, index) => (
                    <td key={index} className={classes.cell}>
                        {(id === 'work' && getTimeFormat(previousTask.timeWork)) ||
                            (id === 'workBegin' && getTimeHHmm(previousTask.dateWorkBegin)) ||
                            (id === 'workEnd' && getTimeHHmm(previousTask.dateWorkEnd))}
                    </td>
                ))}
            </tr>
        </>
    );
};

export { SummaryCrewTaskInfo };
