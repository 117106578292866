import React from 'react';

import BaseIcon from '../BaseIcon';

const Direct = (props) => (
    <BaseIcon {...props} viewBox="0 0 40 40" strokeWidth="4px" stroke={props.color}>
        <path fill="none" d="M5.5 20l0 -17.5c11.05,0 20.01,7.83 20.01,17.5 0,9.67 -8.96,17.5 -20.01,17.5l0 -17.5z" />
        <line x1="0.35" y1="20" x2="36" y2="20" />
        <polygon points="37.97,19.68 35.13,17.48 32.29,15.28 32.29,19.68 32.29,24.08 35.13,21.88 " />
    </BaseIcon>
);

export default Direct;
