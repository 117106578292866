import makeStyles from '@material-ui/core/styles/makeStyles';
import { darkFullWhite } from '../../../service/getMuiThemeStyle';

const useStyles = makeStyles((theme) => ({
    actions: {
        padding: theme.spacing(1, 2),

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1, 0.5),
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        minWidth: 300,
        padding: theme.spacing(0.5, 0, 0),

        '&:first-child': {
            paddingTop: 0,
        },
    },
    form: {
        paddingTop: 60,
    },
    routes: {
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey['300'] : theme.palette.grey[900],
    },
    title: {
        marginRight: 'auto',
        color: darkFullWhite,
    },
}));

export { useStyles };
