import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { ActionTimeControl } from '../../ActionTimeControl/ActionTimeControl';
import { MoreVertIconContrast } from '../../../icons/MoreVertIconContrast';
import Landing from '../../../icons/image/Landing';
import { getCurrentSendedLanding, getPreviewWorkTime } from '../../../selectors/sended-data';
import { checkTimeIsCorrect } from '../../../service/NavPointsUtils';

const LandingTime = ({ className, onShowModal, onChangeTime }) => {
    const { flightTime, value } = useSelector((state) => {
        const landing = getCurrentSendedLanding(state);
        const previewWorkTime = getPreviewWorkTime(state);

        return {
            value: landing.landingTime,
            flightTime: previewWorkTime.flightTime,
        };
    }, shallowEqual);

    const landingSummary = React.useMemo(() => {
        return [
            {
                title: 'Flight time ',
                value: flightTime,
                isCorrect: checkTimeIsCorrect(flightTime),
            },
        ];
    }, [flightTime]);

    return (
        <ActionTimeControl
            title="Landing time"
            buttonName="Landing"
            iconButton={<Landing width="30" height="30" />}
            iconMenuImg={<MoreVertIconContrast isContrast={true} />}
            text="Aircraft landed on"
            name="landingTime"
            value={value}
            summary={landingSummary}
            onChange={onChangeTime}
            showModal={onShowModal}
            className={className}
        />
    );
};

const LandingTimeMemoized = React.memo(LandingTime);

export { LandingTimeMemoized as LandingTime };
