import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
    const {
        palette: { textColor, borderColor },
        textField,
    } = theme;

    return {
        content: {
            width: '100%',
            maxWidth: '600px',
            fontSize: '18px',
            lineHeight: '24px',
            border: `2px solid ${borderColor}`,
        },
        error: {
            color: theme.palette.error.main,
        },
        textStyle: {
            color: textColor,
            fontSize: textField.fontSize,
        },
        textWrapper: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        title: {
            fontWeight: '600',
        },
        wrapper: {
            display: 'flex',

            '& > div': {
                flex: '1 1 50%',
            },
        },
    };
});

export { useStyles };
