import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    image: {
        position: 'fixed',
        top: 0,
        left: 0,

        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minHeight: '100%',
        zIndex: 9999,
        backgroundColor: theme.palette.background.paper,

        transition: 'all 195ms cubic-bezier(0.4, 0.0, 1, 1)',
    },
    inner: {
        flexGrow: 1,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    title: {
        marginRight: 'auto',
    },
}));

export { useStyles };
