import React from 'react';

import BaseIcon from '../BaseIcon';

const Route = (props) => (
    <BaseIcon {...props} viewBox="0 0 297 297">
        <path
            d="M256.195,7.594c-22.498,0-40.802,18.314-40.802,40.826c0,2.994,0.333,5.912,0.947,8.725l-52.365,26.201
            c-7.488-8.664-18.541-14.164-30.861-14.164c-22.499,0-40.803,18.314-40.803,40.826s18.304,40.826,40.803,40.826
            c3.197,0,6.306-0.382,9.293-1.081l25.062,37.622c-8.351,7.481-13.618,18.34-13.618,30.411c0,2.318,0.205,4.589,0.577,6.802
            l-74.499,12.425c-4.998-16.894-20.641-29.259-39.125-29.259C18.305,207.754,0,226.067,0,248.578
            c0,22.514,18.305,40.828,40.804,40.828c19.531,0,35.893-13.805,39.872-32.174l82.316-13.73c7.487,9.211,18.897,15.109,31.661,15.109
            c22.501,0,40.807-18.314,40.807-40.825c0-22.511-18.306-40.825-40.807-40.825c-3.197,0-6.305,0.383-9.291,1.081L160.3,140.419
            c8.351-7.481,13.617-18.34,13.617-30.411c0-2.994-0.333-5.911-0.947-8.723l52.366-26.202c7.487,8.665,18.54,14.165,30.859,14.165
            c22.5,0,40.805-18.315,40.805-40.828C297,25.908,278.695,7.594,256.195,7.594z M40.804,269.34c-11.435,0-20.737-9.313-20.737-20.762
            c0-11.446,9.303-20.759,20.737-20.759s20.736,9.313,20.736,20.759C61.54,260.026,52.238,269.34,40.804,269.34z M215.394,217.786
            c0,11.447-9.304,20.759-20.74,20.759c-11.435,0-20.736-9.311-20.736-20.759c0-11.446,9.302-20.759,20.736-20.759
            C206.09,197.027,215.394,206.34,215.394,217.786z M112.378,110.008c0-11.447,9.302-20.76,20.736-20.76
            c11.434,0,20.736,9.313,20.736,20.76s-9.302,20.76-20.736,20.76C121.68,130.768,112.378,121.455,112.378,110.008z M256.195,69.182
            c-11.434,0-20.735-9.314-20.735-20.762c0-11.447,9.302-20.76,20.735-20.76c11.436,0,20.738,9.312,20.738,20.76
            C276.934,59.867,267.631,69.182,256.195,69.182z"
        />
    </BaseIcon>
);

export default Route;
