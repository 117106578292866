import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

import { darkFullWhite } from '../../service/getMuiThemeStyle';

const CustomSlider = React.forwardRef((props, ref) => {
    return <Slider ref={ref} {...props} />;
});

const CustomSliderStyled = withStyles({
    root: {
        color: '#52af77',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: darkFullWhite,
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

export { CustomSlider as Slider, CustomSliderStyled as CustomSlider };
