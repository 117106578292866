import moment from 'moment';
import { findLastIndex, findIndex } from 'lodash';

import { NavType } from '../components/NavigationRoutes/constants';
import { getTimeDiff } from './DateTimeUtils';
import { LANDSCAPE_WIDTH } from './constants';

export const MILE_COEF = 0.539957; // Используется морская миля
export const EARTH_RADIUS = 6372.795;

export function isDefined(value) {
    return value !== undefined && value !== null;
}

export const REGEXP_LAT = /^[NSns]\d{0,5},?\d{0,1}$/;
export const REGEXP_LONG = /^[EWew]\d{0,5},?\d{0,1}$/;

export function recalculateTotalFuel(points, total) {
    return points.map((point) => {
        if (!isActivePoint(point)) {
            return point;
        }
        return Object.assign({}, point, {
            est: Math.round((total - point.used) * 1000) / 1000,
        });
    });
}

export const getAllTimeErrorsAsString = (timeWarnings) => {
    return () => {
        if (!timeWarnings) {
            return '';
        }

        return Object.values(timeWarnings).reduce((warningText, timeNames) => {
            if (!timeNames.length) {
                return warningText;
            }

            return `${warningText ? warningText + ', ' : ''}«${timeNames[0]}» and «${timeNames[1]}»`;
        }, '');
    };
};

export function getCoordinates(val, type = 'lat') {
    const letters = {
        lat: ['S', 'N'],
        long: ['W', 'E'],
    };

    const key = +(val >= 0);
    val = Math.abs(val);
    const grad = val - (val % 1);
    let min = (val - grad) * 60 - (((val - grad) * 60) % 1);
    let sec = Math.round(((val - grad) * 60 - min) * 10);
    if (sec === 10) {
        min++;
        sec = 0;
    }
    let strGrad = '';
    if (type === 'long') {
        strGrad = grad < 10 ? '00' + grad : grad < 100 ? '0' + grad : grad;
    } else {
        strGrad = grad < 10 ? '0' + grad : grad;
    }
    return val ? `${letters[type][key]}${strGrad}${min < 10 ? '0' + min : min},${sec}` : '';
}

export function getFloatCoordinates(val) {
    const COORDINATE_PRECISION = 8;
    const letters = { S: -1, W: -1, N: 1, E: 1 };
    const isLongitude = val[0].toUpperCase() === 'W' || val[0].toUpperCase() === 'E';
    const regexp = isLongitude ? REGEXP_LONG : REGEXP_LAT;

    if (!regexp.test(val)) {
        return;
    }
    const maxLength = val.length;
    const commaIndex = val.indexOf(',');
    const decimal = commaIndex !== -1 ? val.substring(commaIndex + 1, maxLength) : '';

    const result =
        isLongitude && val.indexOf(',') - 1 === 5
            ? letters[val[0].toUpperCase()] *
              (parseInt(val.substring(1, 4)) + parseInt(val.substring(4, 6)) / 60 + parseInt(decimal || 0) / 600)
            : letters[val[0].toUpperCase()] *
              (parseInt(val.substring(1, 3)) + parseInt(val.substring(3, 5)) / 60 + parseInt(decimal || 0) / 600);

    return result && Math.round(result * Math.pow(10, COORDINATE_PRECISION)) / Math.pow(10, COORDINATE_PRECISION);
}

export function isActiveEntry(obj) {
    return !obj.routeNumber && !obj.isDeleted;
}

export function isActivePoint(obj) {
    return isActiveEntry(obj) && obj.type && obj.type != NavType.COMMENT && obj.type != NavType.ALTITUDE && obj.type != NavType.HOLD;
}

export function isCheckAltitude(obj) {
    return isActiveEntry(obj) && obj.type && obj.type == NavType.ALTITUDE;
}

export function getTimeFormat(seconds, showEmpty = false, showIncorrect = true) {
    let minutes = Math.floor(seconds / 60);
    if (minutes === undefined || minutes === null || isNaN(minutes) || (!showEmpty && minutes === 0)) {
        return '';
    }
    if (seconds < 0) {
        return showIncorrect ? 'incorrect' : '';
    }
    minutes = Math.round(minutes);
    let mm = minutes % 60;
    let hh = Math.floor(minutes / 60);

    return `${hh < 10 ? '0' + hh : hh}:${mm < 10 ? '0' + mm : mm}`;
}

export function checkTimeIsCorrect(time) {
    return time !== 'incorrect';
}

export function checkTimesIsCorrect(time) {
    const { startWorkTime, startEngineTime, stopEngineTime, takeOffTime, landingTime, endWorkTime } = time;
    const isStartWorkAfterStartEngine = getTimeDiff(startWorkTime, startEngineTime) < 0;
    const isStartEngineAfterTakeOff = getTimeDiff(startEngineTime, takeOffTime) < 0;
    const isTakeOffAfterLanding = getTimeDiff(takeOffTime, landingTime) < 0;
    const isLandingAfterStopEngine = getTimeDiff(landingTime, stopEngineTime) < 0;
    const isStopEngineAfterEndWork = getTimeDiff(stopEngineTime, endWorkTime) < 0;

    return {
        isStartWorkAfterStartEngine,
        isStartEngineAfterTakeOff,
        isTakeOffAfterLanding,
        isLandingAfterStopEngine,
        isStopEngineAfterEndWork,
    };
}

export function recalculatePointsAfterUpdate(points, index, fuelFlowAvg, fl) {
    const { nextPointPos, deltaFuel, deltaTime, deltaTt } = updateNextPointAndCalcDelta(points, index, fuelFlowAvg, fl);

    return recalculatePointsToDelta(points, nextPointPos + 1, deltaFuel, deltaTime, deltaTt, fl);
}

export function getDirectCoordinates(startPoint, endPoint, curentPoint) {
    const ax = startPoint.lat;
    const ay = startPoint.long;
    const bx = endPoint.lat;
    const by = endPoint.long;
    const px = curentPoint.lat;
    const py = curentPoint.long;

    const ab = [bx - ax, by - ay];
    const ap = [px - ax, py - ay];

    const dot = (arr1, arr2) => {
        const result = arr1.reduce((res, item, index) => {
            res += item * arr2[index];

            return res;
        }, 0);

        return result;
    };
    const t = dot(ap, ab) / dot(ab, ab);
    const lat = ab[0] * t + ax;
    const long = ab[1] * t + ay;

    return {
        lat,
        long,
    };
}

export function getNearestPoints(points, posFrom, posTo) {
    const { nearestPoints } = points.reduce(
        (res, point, pos) => {
            const isPointActive = isActivePoint(point);
            if (pos > posFrom && pos <= posTo && isPointActive) {
                const lastEditPos = res.nearestPoints.length - 1;
                if (lastEditPos !== -1) {
                    res.nearestPoints[lastEditPos].nextPointPos = pos;
                }
                // Собираем данные только по спрямленным точкам, а выше <= для того, чтобы найти следующую за последней
                if (pos < posTo) {
                    res.nearestPoints.push({ prevPointPos: res.prevPointPos, pos });
                }
            }

            if (isPointActive) {
                res.prevPointPos = pos;
            }

            return res;
        },
        { nearestPoints: [], prevPointPos: -1 }
    );

    return nearestPoints;
}

export function direct(pointList, posFrom, posTo, fuelFlowAvg, userId) {
    localStorage.setItem('beforeTest', JSON.stringify(pointList));
    const points = [].concat(pointList);
    points[posFrom] = Object.assign({}, points[posFrom], {
        isDirectionFrom: true,
        userId: userId,
    });
    points[posTo] = Object.assign({}, points[posTo], {
        isDirectionTo: true,
        userId: userId,
    });
    const directedPoints = getNearestPoints(points, posFrom, posTo);
    let lastDirectedPoint = 0;
    directedPoints.forEach(({ prevPointPos, pos, nextPointPos }) => {
        const { lat, long } = getDirectCoordinates(points[prevPointPos], points[nextPointPos], points[pos]);
        const { lat: prevLat, long: prevLong } = points[pos];
        points[pos] = {
            ...getNewPointEstimatedData(points, { ...points[pos] }, prevPointPos, fuelFlowAvg),
            lat,
            long,
            isRemoveFromDirecting: true,
            beforeDirection: {
                lat: prevLat,
                long: prevLong,
                eto: points[pos].eto,
                est: points[pos].est,
                used: points[pos].used,
                dist: points[pos].dist,
                et: points[pos].et,
                tt: points[pos].tt,
            },
        };
        lastDirectedPoint = pos;
    });

    const pointTo = points[posTo];
    const { eto, est, used, tt, et, dist } = pointTo;
    points[posTo] = {
        ...getNewPointEstimatedData(points, { ...points[posTo] }, lastDirectedPoint, fuelFlowAvg),
        beforeDirection: {
            eto,
            est,
            dist,
            used,
            tt,
            et,
        },
    };

    const deltaFuel = Math.round((est - points[posTo].est) * 1000) / 1000;
    const deltaTime = moment.utc(eto) - moment.utc(points[posTo].eto);
    const deltaTt = tt - (points[lastDirectedPoint].tt + points[posTo].et);

    const res = recalculatePointsToDelta(points, posTo + 1, deltaFuel, deltaTime, deltaTt);

    localStorage.setItem('afterTest', JSON.stringify(res));

    return res;
}

export function recalculatePointsAfterDirection(points, posFrom, posTo, fuelFlowAvg) {
    const pointFrom = points[posFrom];
    const pointTo = points[posTo];

    let distance = 0;
    if (pointFrom.lat && pointFrom.long && pointTo.lat && pointTo.long) {
        distance = getPointsDistance(pointFrom.lat, pointFrom.long, pointTo.lat, pointTo.long);
    }
    const eto2 = timeToPoint(pointFrom.ato || pointFrom.eto, pointFrom.gs, pointTo.gs, distance);
    const time = moment(eto2).diff(pointFrom.ato || pointFrom.eto, 'seconds');
    const est2 = Math.round(fuelToPoint(pointFrom.act || pointFrom.est, time / 3600, fuelFlowAvg) * 1000) / 1000;

    const { eto, est, used, tt, et, dist } = pointTo;
    const deltaFuel = Math.round((est - est2) * 1000) / 1000;
    const deltaTime = moment.utc(eto) - moment.utc(eto2);
    const deltaTt = tt - (pointFrom.tt + time);
    const et1 = Math.round(time) || 0;

    points[posTo] = Object.assign({}, pointTo, {
        eto: eto2,
        est: est2,
        dist: distance !== undefined ? Math.round(distance) : null,
        used: Math.round((used + deltaFuel) * 1000) / 1000,
        tt: pointFrom.tt + et1,
        et: et1,
        beforeDirection: {
            eto,
            est,
            dist,
            used,
            tt,
            et,
        },
    });

    return recalculatePointsToDelta(points, posTo + 1, deltaFuel, deltaTime, deltaTt);
}

export function getNewPointEstimatedData(points, point, prevPointPos, fuelFlowAvg) {
    const pointFrom = points[prevPointPos];
    let { lat, long } = pointFrom.lat && pointFrom.long ? pointFrom : points[findPrevPointPos(points, prevPointPos)];
    let distance1 = 0;
    if (lat && long && point.lat && point.long) {
        distance1 = getPointsDistance(lat, long, point.lat, point.long);
    }
    const pointFromAto = pointFrom.ato || pointFrom.eto;
    const pointFromEst = pointFrom.act || pointFrom.est;

    const eto = timeToPoint(pointFromAto, pointFrom.gs, point.gs || pointFrom.gs, distance1);
    const time1 = moment.utc(eto).diff(moment.utc(pointFromAto), 'seconds') / 3600;
    const est = Math.round(fuelToPoint(pointFromEst, time1, fuelFlowAvg) * 1000) / 1000;
    const used = Math.round((pointFrom.used + pointFrom.est - est) * 1000) / 1000;
    const et = Math.round(time1 * 60) || 0;
    const tt = +pointFrom.tt + et;

    return {
        ...point,
        eto: eto !== undefined ? eto : null,
        est: est !== undefined ? est : null,
        used: used !== undefined ? used : null,
        dist: distance1 !== undefined ? Math.round(distance1) : null,
        et: et !== undefined ? et : null,
        tt: tt !== undefined ? tt : null,
    };
}

function getAvgCoordinates(points, pointPos1, pointPos2) {
    const point1 = points[pointPos1];
    const point2 = points[pointPos2];
    let { lat: lat1, long: long1 } = point1.lat && point1.long ? point1 : points[findPrevPointPos(points, pointPos1)];
    let { lat: lat2, long: long2 } = point2.lat && point2.long ? point2 : points[findNextPointPos(points, pointPos2)];

    if (!point1.lat || !point1.long) {
        lat1 = (lat1 + lat2) / 2;
        long1 = (long1 + long2) / 2;
    } else if (!point2.lat || !point2.long) {
        lat2 = (lat1 + lat2) / 2;
        long2 = (long1 + long2) / 2;
    }

    return {
        lat1,
        lat2,
        long1,
        long2,
    };
}

function updateNextPointAndCalcDelta(points, pos, fuelFlowAvg, fl) {
    const point = points[pos];
    const prevPointPos = findPrevPointPos(points, pos);
    const nextPointPos = findNextPointPos(points, pos);
    if (pos === nextPointPos) {
        // Если нет следующей точки, то ничего и не считаем
        return {};
    }

    const pointFrom = points[prevPointPos];
    const pointTo = points[nextPointPos];
    const eto = point.ato || point.eto;
    const est = point.act || point.est;

    const { lat1, long1, lat2, long2 } = getAvgCoordinates(points, pos, nextPointPos);
    const distance2 = getPointsDistance(lat1, long1, lat2, long2);
    const eto2 = timeToPoint(eto, point.gs || pointFrom.gs, pointTo.gs, distance2);
    const time2 = moment.utc(eto2).diff(moment.utc(eto), 'seconds') / 3600;
    const est2 = Math.round(fuelToPoint(est, time2, fuelFlowAvg) * 1000) / 1000;
    const et2 = Math.round(time2 * 60) || 0;

    const deltaFuel = pointTo.est - est2;
    const deltaTime = moment.utc(pointTo.eto) - moment.utc(eto2);
    const deltaTt = pointTo.tt - (point.tt + et2);

    points[nextPointPos] = Object.assign({}, pointTo, {
        eto: eto2,
        est: Math.round(est2 * 1000) / 1000,
        dist: distance2 !== undefined ? Math.round(distance2) : null,
        used: Math.round((pointTo.used && deltaFuel ? pointTo.used + deltaFuel : pointFrom.used + pointFrom.est - est2) * 1000) / 1000,
        et: et2,
        tt: point.tt + et2,
        ...(fl && { fl: point.fl }),
    });

    return {
        prevPointPos,
        nextPointPos,
        deltaFuel,
        deltaTime,
        deltaTt,
    };
}

export function recalculatePointsAfterTakeOff(points, takeOffTime) {
    if (!points.some((point) => point.ato)) {
        return points.map((point) => {
            const newPoint = Object.assign({}, point);

            if (isActivePoint(point) && takeOffTime) {
                if (isDefined(point.tt)) {
                    newPoint.eto = moment(takeOffTime).add(newPoint.tt, 'm').toDate();
                }
                if (isDefined(point.tt_default)) {
                    newPoint.eto_default = moment(takeOffTime).add(newPoint.tt_default, 'm').toDate();
                }
            }

            return newPoint;
        });
    }

    return points;
}

export function recalculatePointsAfterAddHold(points, pos) {
    const point = points[pos];
    const prevPointPos = findPrevPointPos(points, pos);
    const prevPoint = points[prevPointPos];
    const nextPointPos = findNextPointPos(points, pos);
    const deltaFuel = point.act ? (prevPoint.act || prevPoint.est) - point.act : 0;
    const deltaTime = moment.utc(point.startHoldTime) - moment.utc(point.endHoldTime);
    const deltaTt = Math.round(-moment.utc(point.endHoldTime).diff(moment.utc(point.startHoldTime), 'seconds') / 60);

    if (point.startHoldTime && point.endHoldTime) {
        points[nextPointPos] = Object.assign({}, points[nextPointPos], {
            et: Math.round(+points[nextPointPos].et - deltaTt),
        });

        return recalculatePointsToDelta(points, nextPointPos, deltaFuel, deltaTime, deltaTt);
    } else if (deltaFuel) {
        return recalculatePointsToDelta(points, nextPointPos, deltaFuel);
    }

    return points;
}

export function recalculatePointsAfterUpdateHold(points, pos, newData) {
    const point = points[pos];
    const nextPointPos = findNextPointPos(points, pos);
    const prevDeltaTt =
        point.startHoldTime && point.endHoldTime ? moment.utc(point.endHoldTime).diff(moment.utc(point.startHoldTime), 'seconds') / 60 : 0;
    const holdDuration = point.startHoldTime && point.endHoldTime ? moment.utc(point.startHoldTime) - moment.utc(point.endHoldTime) : 0;
    const deltaFuel = newData.act ? point.act - newData.act : 0;
    const deltaTime = holdDuration - (moment.utc(newData.endHoldTime) - moment.utc(newData.startHoldTime));
    const deltaTt = prevDeltaTt - Math.round(moment.utc(newData.endHoldTime).diff(moment.utc(newData.startHoldTime), 'seconds') / 60);

    if (newData.startHoldTime && newData.endHoldTime) {
        points[nextPointPos] = Object.assign({}, points[nextPointPos], {
            et: Math.round(+points[nextPointPos].et - deltaTt),
        });

        return recalculatePointsToDelta(points, nextPointPos, deltaFuel, deltaTime, deltaTt);
    } else if (deltaFuel) {
        return recalculatePointsToDelta(points, nextPointPos, deltaFuel);
    }

    return points;
}

export function revertDirection(pointList, action) {
    let directToPos;

    const points = [].concat(pointList);
    for (let i = action.pointPosFrom + 1; i < points.length; i++) {
        if (
            [NavType.DEPART, NavType.CLIMB, NavType.FLIGHT, NavType.DESCENT, NavType.ARRIVE, NavType.TOC, NavType.TOD].includes(
                points[i].type
            ) &&
            !points[i].routeNumber
        ) {
            if (points[i].isDirectionTo) {
                directToPos = i;
                delete points[directToPos].isDirectionTo;
                break;
            }

            const { beforeDirection } = points[i];
            points[i] = {
                ...points[i],
                ...beforeDirection,
                userId: action.userId,
            };
            // Удаляем ato и act для спрямленных участков
            if (!action.editMode) {
                delete points[i].ato;
                delete points[i].act;
            }
            delete points[i].beforeDirection;
            delete points[i].isRemoveFromDirecting;
        }
    }

    points[action.pointPosFrom] = {
        ...points[action.pointPosFrom],

        userId: action.userId,
    };
    delete points[action.pointPosFrom].isDirectionFrom;

    return directToPos !== undefined ? recalculatePointsAfterUndoDirection(points, directToPos) : pointList;
}

export function recalculatePointsAfterUndoDirection(points, posTo) {
    const pointTo = points[posTo];
    const { eto, est, act, ato, tt, beforeDirection } = pointTo;
    const time = ato || eto;
    const fuel = act || est;
    const deltaFuel = fuel - beforeDirection.est;
    const deltaTime = moment.utc(time) - moment.utc(beforeDirection.eto);
    const deltaTt = tt - beforeDirection.tt;

    points[posTo] = {
        ...pointTo,
        ...beforeDirection,
    };
    delete points[posTo].beforeDirection;

    return recalculatePointsToDelta(points, posTo + 1, deltaFuel, deltaTime, deltaTt);
}

export function recalculatePointsToDelta(points, startPos, deltaFuel = 0, deltaTime = 0, deltaTt = 0, fl) {
    return points.map((point, pos) => {
        const newPoint = Object.assign({}, point);

        if (pos >= startPos && isActivePoint(point)) {
            newPoint.used = Math.round((+newPoint.used + deltaFuel) * 1000) / 1000;
            newPoint.est = Math.round((+newPoint.est - deltaFuel) * 1000) / 1000;
            newPoint.tt = +newPoint.tt - deltaTt;

            newPoint.eto = moment.utc(moment.utc(newPoint.eto) - deltaTime).toDate();

            if (newPoint.fl_default && fl) {
                newPoint.fl = fl;
            }
        }

        return newPoint;
    });
}

export function recalculatePointsAfterDivert(points, startPos, fuelFlowAvg) {
    const { nextPointPos, deltaFuel, deltaTime, deltaTt } = updateNextPointAndCalcDelta(points, startPos, fuelFlowAvg);

    return recalculatePointsToDelta(points, nextPointPos + 1, deltaFuel, deltaTime, deltaTt);
}

export function getPointsDistance(lat1, long1, lat2, long2) {
    const radLat1 = lat1 * (Math.PI / 180);
    const radLon1 = long1 * (Math.PI / 180);
    const radLat2 = lat2 * (Math.PI / 180);
    const radLon2 = long2 * (Math.PI / 180);
    // haversine
    const radLonDif = radLon2 - radLon1;
    const atan2top = Math.sqrt(
        Math.pow(Math.cos(radLat2) * Math.sin(radLonDif), 2) +
            Math.pow(Math.cos(radLat1) * Math.sin(radLat2) - Math.sin(radLat1) * Math.cos(radLat2) * Math.cos(radLonDif), 2)
    );
    const atan2bottom = Math.sin(radLat1) * Math.sin(radLat2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radLonDif);
    const deltaAngle = Math.atan2(atan2top, atan2bottom);

    return Math.round(EARTH_RADIUS * deltaAngle * MILE_COEF * 1000) / 1000;

    // haversine second variant
    // const φ1 = radLat1;
    // const φ2 = radLat2;
    // const Δφ = φ2 - φ1;
    // const Δλ = radLon2 - radLon1;
    //
    // let a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    //     Math.cos(φ1) * Math.cos(φ2) *
    //     Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    // let deltaAngle = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    //
    // return EARTH_RADIUS * deltaAngle;

    // aviation
    // const deltaAngle = 2 * Math.asin(Math.sqrt((Math.sin((radLat1 - radLat2) / 2)) ^ 2 +
    //     Math.cos(radLat1) * Math.cos(radLat2) * (Math.sin((radLon1 - radLon2) / 2)) ^ 2));

    // return EARTH_RADIUS * deltaAngle;
}

export function timeToPoint(startTime, gs1, gs2, distance) {
    const avgGs = (+gs1 + +gs2) / 2;
    if (!startTime || (distance != 0 && !distance) || !avgGs) {
        return null;
    }

    const time = moment
        .utc(startTime)
        .add((+distance / avgGs) * 60, 'm')
        .millisecond(0);

    return time.toDate();
}

export function fuelToPoint(est1, timeInHours, fuelFlowAvg) {
    return est1 - (timeInHours * fuelFlowAvg) / 1000;
}

export function findEndPoint(points) {
    let pointIndex = 0;
    points.forEach((item, index) => {
        if (isActivePoint(item)) {
            pointIndex = index;
        }
    }, 0);

    return points[pointIndex] || {};
}

export const MAX_DISTANCE = 32767;

export function calculateDistance(points) {
    let distance = 0;
    points.forEach((item) => {
        if (isActivePoint(item)) {
            distance += item.dist || 0;
        }
    }, 0);

    return distance > 0 && distance < MAX_DISTANCE ? distance : null;
}

export function calcHoldTime(points) {
    return points.reduce((res, item) => {
        const { startHoldTime, endHoldTime } = item;
        let diff = endHoldTime - startHoldTime;
        res += diff > 0 ? diff / 1000 : 0;

        return res;
    }, 0);
}

export function findPrevPointPos(points, pos) {
    const index = findLastIndex(points, (point, index) => isActivePoint(point) && index < pos);

    return index !== -1 ? index : pos;
}

export function findNextPointPos(points, pos) {
    const index = points.findIndex((point, index) => isActivePoint(point) && index > pos);

    return index !== -1 ? index : pos;
}

export function findNextPoint(points, currentPoint) {
    return (
        points &&
        currentPoint &&
        currentPoint.pointNumber &&
        points.find((point) => isActivePoint(point) && point.pointNumber >= currentPoint.pointNumber + 1)
    );
}

export function findDirectionStart(points, pos) {
    const index = findLastIndex(points, (point, index) => isActivePoint(point) && point.isDirectionFrom && index < pos);

    return index !== -1 ? index : pos;
}

export function findDirectionEnd(points, pos) {
    const index = findIndex(points, (point, index) => isActivePoint(point) && point.isDirectionTo && index > pos);

    return index !== -1 ? index : pos;
}

export function findPrevCheckAltitude(points, pos) {
    const index = findLastIndex(points, (point, index) => isCheckAltitude(point) && index < pos);

    return index !== -1 ? index : pos;
}

export function calculateAlternateRoute(leg, action) {
    const { fuelFlowAvg } = leg;
    let used = 0;
    let est = 0;
    let tt = 0;
    let eto;
    let deltaFuel = 0;
    let prevPoint = leg.points[action.pos];
    leg.points = leg.points.map((point, pos) => {
        const newPoint = { ...point };

        if (pos > action.pos && !point.routeNumber) {
            newPoint.isDeleted = true;
            newPoint.userId = action.userId;
        }

        return newPoint;
    });

    const alternatePoints = leg.points
        .filter((point) => +point.routeNumber === +action.route)
        .map((point, pos) => {
            const newPoint = Object.assign({}, point, { pointNumber: action.pos + pos + 1 });
            // стираем признаки того, что это точка из запасного маршрута
            delete newPoint.pointId;
            newPoint.isUserAdd = 0;
            newPoint.routeNumber = undefined;
            newPoint.dateCreate = moment.utc();

            if (pos !== 0) {
                est = Math.round((+newPoint.est + deltaFuel) * 1000) / 1000;
                used = Math.round((newPoint.used || prevPoint.used - deltaFuel) * 1000) / 1000;
                tt += newPoint.et || 0;
                eto = eto.add(newPoint.et, 'm');
                newPoint.used = used;
                newPoint.tt = tt;
                newPoint.est = est;
                newPoint.eto = eto.toDate();
                newPoint.used_default = newPoint.used;
                newPoint.tt_default = newPoint.tt;
                newPoint.est_default = newPoint.est;
                newPoint.eto_default = newPoint.eto;
            } else {
                const calculatePoint = getNewPointEstimatedData(leg.points, point, action.pos + pos, fuelFlowAvg);
                est = calculatePoint.est;
                eto = moment.utc(calculatePoint.eto);
                tt = calculatePoint.tt;

                deltaFuel = Math.round((calculatePoint.est - newPoint.est) * 1000) / 1000;
                newPoint.used = calculatePoint.used;
                newPoint.et = calculatePoint.et;
                newPoint.dist = calculatePoint.dist;
                newPoint.tt = calculatePoint.tt;
                newPoint.eto = calculatePoint.eto;
                newPoint.est = calculatePoint.est;

                newPoint.used_default = newPoint.used;
                newPoint.et_default = newPoint.et;
                newPoint.tt_default = newPoint.tt;
                newPoint.eto_default = newPoint.eto;
                newPoint.est_default = newPoint.est;
            }

            prevPoint = newPoint;

            return newPoint;
        });

    leg.points = leg.points.concat(alternatePoints);

    return leg.points;
}

export function updateLastPoint(points = [], { act, ato }) {
    const pointIndex = points.reduce((res, point, index) => {
        if (isActivePoint(point) && (res === -1 || point.pointNumber >= points[res].pointNumber)) {
            res = index;
        }

        return res;
    }, -1);

    if (pointIndex !== -1) {
        const lastPoint = points[pointIndex];
        points[pointIndex] = { ...lastPoint };
        if (act) {
            points[pointIndex].act = Math.round(act) / 1000;
        }

        if (ato) {
            points[pointIndex].ato = ato;
        }
    }

    return points;
}

export function getRouteFieldsCount(columns, mobileEnrouteMode) {
    const hiddenCount = Object.keys(columns).reduce((res, key) => {
        if (columns[key].hidden || (mobileEnrouteMode && !columns[key].mobile)) {
            res += 1;
        }

        return res;
    }, 0);
    // 1 на пункты меню
    return Object.keys(columns).length - hiddenCount + 1;
}

const MAX_FONT_SIZE = 20;
const MOBILE_WIDTH = 640;
const MAX_COLUMN_COUNT = 17;
const MIN_COLUMN_COUNT = 4;

export function getPointFontSize(mobileEnrouteMode, columns) {
    let minFontSize = 14;
    let maxFontSize = MAX_FONT_SIZE;
    const { clientWidth } = document.querySelector('.page-layout__content') || {};
    if (clientWidth >= LANDSCAPE_WIDTH) {
        minFontSize = 16;
    } else if (clientWidth <= MOBILE_WIDTH) {
        minFontSize = 12;
        maxFontSize = MAX_FONT_SIZE - 4;
    }
    let fieldCount = getRouteFieldsCount(columns, mobileEnrouteMode);
    if (fieldCount < MIN_COLUMN_COUNT) {
        fieldCount = MIN_COLUMN_COUNT;
    }
    let size = minFontSize + ((MAX_COLUMN_COUNT - fieldCount) / MAX_COLUMN_COUNT) * (maxFontSize - minFontSize);
    if (size < minFontSize) {
        size = minFontSize;
    } else if (size > maxFontSize) {
        size = maxFontSize;
    }

    return `${size}px`;
}

export function getKgValue(value) {
    return value && Math.round(value * 1000);
}

export function setKgValue(value) {
    return value && Math.round(value) / 1000;
}

export function pointToString(point) {
    const { type, route, rnt } = point;
    let typeName;
    switch (type) {
        case NavType.TOC:
            typeName = 'TOC';
            break;
        case NavType.TOD:
            typeName = 'TOD';
            break;
        default:
            typeName = '';
    }

    // const coordinates = `(${getCoordinates(lat, 'lat')}; ${getCoordinates(long, 'long')})`;
    const fullRoute = route || typeName || '';
    const shortRoute = (rnt && rnt.replace(/\s+/g, '') !== fullRoute.replace(/\s+/g, '') && rnt) || '';

    return `${fullRoute} ${shortRoute}`;
}
