import { TOGGLE_SWITCHER } from '../actions/flightPage';
import { LOGOUT } from '../actions/screen';

const initialState = {
    isRu: true,
    isLocalTime: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_SWITCHER:
            return { ...state, ...action.value };
        case LOGOUT:
            return { ...initialState };
        default:
            return state;
    }
};
