import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { getTimeFormat } from '../../../service/NavPointsUtils';
import { SummaryLegInfo } from '../SummaryLegInfo/SummaryLegInfo';
import { ErrorBoundary } from '../../ErrorBoundary/ErrorBoundary';
import { useStyles } from './useStyles';
import { Typography } from '../../material-ui/Typography';
import { getSendedDataPreview, getSendedDataPreviewTotal } from '../../../selectors/sended-data';

const SummaryTaskPanel = () => {
    const legs = useSelector(getSendedDataPreview);
    const total = useSelector(getSendedDataPreviewTotal);
    const declaration = useSelector((state) => get(state, 'routesDeclaration.summary.legs', {}));
    const classes = useStyles();
    const {
        night = false,
        biologicalNight = false,
        parking = false,
        delay = false,
        rest = false,
        dist = false,
        landings = false,
    } = declaration || {};
    const flightInfo = `${getTimeFormat(total.flightTime)}${
        total.flightTime !== total.timeOnDevices && total.timeOnDevices ? `(${getTimeFormat(total.timeOnDevices)})` : ''
    }`;
    const landingsInfo = `${total.landings}${
        total.landings !== total.landingsOnDevices && total.landingsOnDevices ? `(${total.landingsOnDevices})` : ''
    }`;
    return (
        <ErrorBoundary>
            {legs ? (
                <table className={classes.table}>
                    <thead>
                        <tr className={classes.header}>
                            <th className={classes.headerCell}>Flight #</th>
                            <th className={classes.headerCell}>Route</th>
                            <th className={classes.headerCell}>Duty</th>
                            <th className={classes.headerCell}>Engine</th>
                            <th className={classes.headerCell}>Block</th>
                            <th className={classes.headerCell}>TO\LA</th>
                            <th className={classes.headerCell}>Flight</th>
                            {night && <th className={classes.headerCell}>Night</th>}
                            {biologicalNight && <th className={classes.headerCell}>Bio</th>}
                            {parking && <th className={classes.headerCell}>Parking</th>}
                            {delay && <th className={classes.headerCell}>Delay</th>}
                            {rest && <th className={classes.headerCell}>Rest</th>}
                            {dist && <th className={classes.headerCell}>Distance</th>}
                            {landings && <th className={classes.headerCell}>Landings</th>}
                        </tr>
                    </thead>
                    {legs &&
                        legs.map((leg, index) => (
                            <SummaryLegInfo leg={leg} key={leg.id} number={index} declaration={declaration} lastLeg={legs.length - 1} />
                        ))}
                    <tfoot>
                        <tr className={classes.footRow}>
                            <td>Total:</td>
                            <td></td>
                            <td>{getTimeFormat(total.workTime)}</td>
                            <td></td>
                            <td>{getTimeFormat(total.blockTime)}</td>
                            <td></td>
                            <td>{flightInfo}</td>
                            {night && <td>{getTimeFormat(total.timeFlightNight)}</td>}
                            {biologicalNight && <td>{getTimeFormat(total.timeBiologicalNight)}</td>}
                            {parking && <td>{getTimeFormat(total.timeWorkParking)}</td>}
                            {delay && <td>{getTimeFormat(total.timeWorkDelay)}</td>}
                            {rest && <td>{getTimeFormat(total.timeWorkRest)}</td>}
                            {dist && <td>{total.distance}</td>}
                            {landings && <td>{landingsInfo}</td>}
                        </tr>
                    </tfoot>
                </table>
            ) : (
                <Typography className={classes.text}>There are not information for leg</Typography>
            )}
        </ErrorBoundary>
    );
};

const SummaryTaskPanelMemoized = React.memo(SummaryTaskPanel);

export { SummaryTaskPanelMemoized as SummaryTaskPanel };
