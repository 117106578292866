import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { NavigationRoutePoint } from './NavigationRoutePoint/NavigationRoutePoint';
import { NavigationRouteComment } from './NavigationRouteComment/NavigationRouteComment';
import { NavigationRouteCheckAltitude } from './NavigationRouteCheckAltitude/NavigationRouteCheckAltitude';
import { NavigationRouteHold } from './NavigationRouteHold/NavigationRouteHold';
import { NavType } from '../constants';
import { getCurrentPoints } from '../../../selectors/sended-data';

const NavigationRouteItem = ({ pos, showMenu, columnsCount, mobileEnrouteMode, offset, onMenuOpen, current, disabled }) => {
    const point = useSelector((state) => {
        const points = getCurrentPoints(state);

        return points[pos];
    }, shallowEqual);
    switch (point.type) {
        case NavType.COMMENT:
            return <NavigationRouteComment point={point} pos={pos} columnsCount={columnsCount} offset={offset} onMenuOpen={onMenuOpen} />;
        case NavType.ALTITUDE:
            return (
                <NavigationRouteCheckAltitude point={point} pos={pos} columnsCount={columnsCount} offset={offset} onMenuOpen={onMenuOpen} />
            );
        case NavType.HOLD:
            return <NavigationRouteHold point={point} pos={pos} columnsCount={columnsCount} offset={offset} onMenuOpen={onMenuOpen} />;
    }
    // case NAV_ENTRY_TYPE_POINT_OTHER:
    // case NavType.DEPART:
    // case NavType.CLIMB:
    // case NavType.DESCENT:
    // case NavType.ARRIVE:
    // case NavType.TOC:
    // case NavType.TOD:
    // case NavType.FLIGHT:
    return (
        <NavigationRoutePoint
            point={point}
            pos={pos}
            showMenu={showMenu}
            columnsCount={columnsCount}
            mobileEnrouteMode={mobileEnrouteMode}
            offset={offset}
            onMenuOpen={onMenuOpen}
            current={current}
            disabled={disabled}
        />
    );
};

const NavigationRouteItemMemoized = React.memo(NavigationRouteItem);

export { NavigationRouteItemMemoized as NavigationRouteItem };
