import React from 'react';

import { PaletteField } from './PaletteField/PaletteField';
import { useStyles } from './useStyles';

const PaletteForm = (props) => {
    const { declaration, informationForm, onEditInformationField, fields } = props;
    const classes = useStyles();

    return (
        <div className={classes.form}>
            {declaration.map(({ id, ...rest }) => (
                <PaletteField
                    {...rest}
                    key={`${informationForm[id]}:${id}`}
                    id={id}
                    fields={fields}
                    onChange={onEditInformationField}
                    informationForm={informationForm[id]}
                    defaultValue={informationForm[id]}
                />
            ))}
        </div>
    );
};

const PaletteFormMemoized = React.memo(PaletteForm);

export { PaletteFormMemoized as PaletteForm };
