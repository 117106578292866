const WizardStep = {
    ARR: 'ARR',
    DEP: 'DEP',
    LOAD_FUEL: 'LOAD_FUEL',
    NAV_ROUTE: 'NAV_ROUTE',
    START: 'START',
    TOTAL: 'TOTAL',
};

const StepName = {
    [WizardStep.ARR]: 'ARR',
    [WizardStep.DEP]: 'DEP',
    [WizardStep.LOAD_FUEL]: 'Load / Fuel',
    [WizardStep.NAV_ROUTE]: 'Nav route',
    [WizardStep.START]: 'Start',
    [WizardStep.TOTAL]: 'Total',
};

const StepNumber = {
    [WizardStep.START]: 0,
    [WizardStep.LOAD_FUEL]: 1,
    [WizardStep.DEP]: 2,
    [WizardStep.NAV_ROUTE]: 3,
    [WizardStep.ARR]: 4,
    [WizardStep.TOTAL]: 5,
};

export { WizardStep, StepName, StepNumber };
