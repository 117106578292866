import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { SummaryBufferCrew } from '../SummaryBufferCrew/SummaryBufferCrew';
import { Panel } from '../../Panel/Panel';
import { SummaryCrewTable } from '../SummaryCrewTable/SummaryCrewTable';
import { sortSummaryPageCrew } from '../../../service/sort';
import { ErrorBoundary } from '../../ErrorBoundary/ErrorBoundary';
import { MoreVertIconContrast } from '../../../icons/MoreVertIconContrast';
import { generateCrewInfo } from '../../../actions/buffer-crew';
import { getBufferCrew } from '../../../selectors/sended-data';
import { Typography } from '../../material-ui/Typography';
import { useStyles } from './useStyles';

const SummaryBufferCrewPanel = () => {
    const dispatch = useDispatch();
    const [fullMode, toggleFullMode] = React.useState(false);
    const bufferCrew = useSelector(getBufferCrew);
    const declaration = useSelector((state) => get(state, 'routesDeclaration.summary.crew', {}));
    const visibility = useSelector((state) => get(state, 'routesDeclaration.options.crewInfoAccess', false));
    const classes = useStyles();

    React.useEffect(() => {
        if (visibility) {
            dispatch(generateCrewInfo());
        }
    }, []);

    const handleResetCrewData = React.useCallback(() => {
        dispatch(generateCrewInfo(true));
    }, []);

    const handleToggleMode = React.useCallback(() => {
        toggleFullMode((prevState) => !prevState);
    }, []);

    const iconMenuItems = React.useMemo(
        () => [
            {
                name: 'Reset for edited leg',
                callback: handleResetCrewData,
            },
        ],
        []
    );

    const details = React.useMemo(
        () =>
            bufferCrew &&
            bufferCrew.length > 0 &&
            bufferCrew
                .sort(sortSummaryPageCrew)
                .map((crew, index) => <SummaryBufferCrew crew={crew} key={index} declaration={declaration} />),
        [bufferCrew]
    );

    return visibility ? (
        <ErrorBoundary>
            <div className={classes.panelWrapper}>
                <Panel
                    title="Crew time info"
                    className={classes.panel}
                    toggle={{
                        label: 'Expand',
                        defaultToggled: fullMode,
                        onToggle: handleToggleMode,
                        toggleStyle: { marginRight: 0 },
                    }}
                    iconMenuItems={iconMenuItems}
                    iconMenuImg={<MoreVertIconContrast isContrast={true} />}
                >
                    {fullMode ? (
                        details || <Typography>There are not crew information</Typography>
                    ) : (
                        <SummaryCrewTable bufferCrew={bufferCrew} />
                    )}
                </Panel>
            </div>
        </ErrorBoundary>
    ) : (
        ''
    );
};

const SummaryBufferCrewPanelMemoized = React.memo(SummaryBufferCrewPanel);

export { SummaryBufferCrewPanelMemoized as SummaryBufferCrewPanel };
