import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
    badWork: {
        color: `${theme.point.labelWarning} !important`,
    },
    badWorkEdited: {
        color: `${theme.point.editLabelWarning} !important`,
    },
    disabledTextFieldStyle: {
        textAlign: 'left',
        color: theme.textField.disabledTextColor,
    },
    editedField: {
        color: theme.textField.textColor,
    },
    helperText: {
        position: 'absolute',
        fontSize: '14px',
        lineHeight: '14px',
        top: '54px',
    },
    field: {
        flex: '0 1 19%',
        marginTop: 6,
        marginBottom: theme.spacing(2),
        ['@media (max-width:440px)']: {
            flex: '1 1 50%',
        },
    },
    hidden: {
        visibility: 'hidden !important',
    },
    wellDone: {
        color: `${theme.point.labelGreen} !important`,
    },
    wellDoneEdited: {
        color: `${theme.point.editLabelGreen} !important`,
    },
}));
