import React from 'react';

import { findPrevCheckAltitude, findPrevPointPos } from '../../service/NavPointsUtils';

export const usePrevCheckAltitude = (points, pos) => {
    return React.useMemo(() => {
        const prevCheckAltitude = findPrevCheckAltitude(points, pos);
        if (prevCheckAltitude && points[prevCheckAltitude]) {
            const { captainAltimeter, standbyAltimeter, firstOfficerAltimeter } = points[prevCheckAltitude];

            return { captainAltimeter, standbyAltimeter, firstOfficerAltimeter };
        }
    }, [points, pos]);
};

export const usePrevPointPos = (points, pos) => {
    return React.useMemo(() => {
        return findPrevPointPos(points, pos);
    }, [points, pos]);
};

export const usePrevPoint = (points, pos) => {
    return React.useMemo(() => {
        const prevPos = findPrevPointPos(points, pos);

        return points[prevPos];
    }, [points, pos]);
};
