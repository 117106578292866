import { makeStyles } from '@material-ui/styles';

const useStyles = (colorName) =>
    makeStyles((theme) => {
        const airportGroup = theme.notice.airportGroup || {};

        return {
            nestedItem: {
                borderLeft: `6px solid ${airportGroup[colorName] || 'inherit'}`,
            },
            listItem: {
                fontSize: '18px',
                lineHeight: '18px',
                fontWeight: '600',
                borderLeft: `6px solid ${airportGroup[colorName] || 'inherit'}`,
            },
            text: {
                fontSize: '18px',
                fontWeight: 600,
            },
        };
    });

export { useStyles };
