import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    col: {
        width: '35px',
        fontSize: '16px',
    },
    col2: {
        width: '70px',
        fontSize: '16px',
    },
    col3: {
        width: '105px',
        fontSize: '16px',
    },
    col4: {
        width: '140px',
        fontSize: '16px',
    },
    header: {
        padding: '4px 0',
        fontSize: '16px',
        fontWeight: 'normal',
        borderBottom: `1px solid ${theme.palette.divider}`,

        '& p': {
            color: theme.palette.info.main,
        },
    },
    table: {
        width: '100%',
        marginTop: 12,
        borderSpacing: 0,
        textAlign: 'center',
    },
    time: {
        fontWeight: 'bold',
    },
    title: {
        textAlign: 'center',
        margin: '32px auto 0',
        fontWeight: 600,
    },
}));
