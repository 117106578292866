import React from 'react';

import BaseIcon from '../BaseIcon';

const Summary = (props) => (
    <BaseIcon {...props} viewBox="0 0 46 46">
        <path
            d="M35.822,0H10.178C4.557,0,0,4.557,0,10.178v25.646C0,41.443,4.557,46,10.178,46h25.645C41.443,46,46,41.443,46,35.822
            V10.178C46,4.557,41.443,0,35.822,0z M40,35.822C40,38.126,38.126,40,35.822,40H10.178C7.874,40,6,38.126,6,35.822V10.178
            C6,7.874,7.874,6,10.178,6h25.645C38.126,6,40,7.874,40,10.178V35.822z"
        />
        <path
            d="M21.925,33.298c-0.609,0.609-1.437,0.952-2.298,0.952s-1.689-0.343-2.298-0.952l-7.122-7.122
            c-1.269-1.269-1.269-3.327,0-4.596c1.27-1.27,3.327-1.27,4.597,0l4.243,4.242c0.321,0.32,0.84,0.32,1.161,0l11.489-11.489
            c1.27-1.27,3.326-1.27,4.597,0s1.271,3.327,0,4.597L21.925,33.298z"
        />
    </BaseIcon>
);

export default Summary;
