import { SET_ONLINE_STATUS } from '../actions/online';

const initialState = {
    status: true,
};
const online = (state = initialState, action) => {
    switch (action.type) {
        case SET_ONLINE_STATUS:
            return action.status;
        default:
            return state;
    }
};

export { online };
