import { useDispatch } from 'react-redux';

const NOTIFICATION_ADD = 'NOTIFICATION_ADD';
const NOTIFICATION_REMOVE = 'NOTIFICATION_REMOVE';
const NotificationTypes = {
    DEFAULT: 'default',
    ERROR: 'error',
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
};

const pushNotification = (notification) => ({
    notification: {
        key: new Date().getTime() + Math.random(),
        ...notification,
    },
    type: NOTIFICATION_ADD,
});

const pushError = (err) => (dispatch) => {
    dispatch(
        pushNotification({
            variant: NotificationTypes.ERROR,
            message: err.header || '',
            details: err.error,
        })
    );
};

const usePushError = () => {
    const dispatch = useDispatch();

    return (error) => dispatch(pushError(error));
};

const usePushNotification = () => {
    const dispatch = useDispatch();

    return (notification) => dispatch(pushNotification(notification));
};

const removeNotification = (key) => ({ key, type: NOTIFICATION_REMOVE });

export {
    NOTIFICATION_ADD,
    NOTIFICATION_REMOVE,
    NotificationTypes,
    pushNotification,
    removeNotification,
    pushError,
    usePushError,
    usePushNotification,
};
