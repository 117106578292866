import { makeStyles } from '@material-ui/styles';

import { getMuiThemeStyle } from '../../../service/getMuiThemeStyle';

const useStyles = makeStyles((theme) => {
    const styles = getMuiThemeStyle(theme.palette.type);
    const {
        textField: { labelFontSize, textFontSize },
        palette,
    } = styles;
    const { textColor } = palette;

    return {
        secondaryText: { color: textColor, fontSize: labelFontSize },
        boldText: { fontWeight: 600 },
        legIconStyle: { top: '8px' },
        innerDivStyle: {
            userSelect: 'none',
            marginLeft: '0',
            padding: '16px 5px 16px 55px',
            borderLeftWidth: '0px',
            borderLeftStyle: 'solid',
            borderLeftColor: 'transparent',
        },
        listIcon: { minWidth: '40px' },
        listItemText: {
            '& span': {
                fontSize: '18px',
            },
        },
        nestedItemStyle: { paddingLeft: '40px', fontSize: textFontSize, lineHeight: textFontSize },
        statusSend: {
            fill: '#4CAF50',
        },
        statusEdit: {
            fill: '#40C4FF',
        },
    };
});

export { useStyles };
