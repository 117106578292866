import React from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';

import { ALTIMETER_WARNING_LIMIT } from '../../../../service/constants';
import { TYPES, useShowModal } from '../../../../actions/modal';
import { useStyles } from './useStyles';
import { Typography } from '../../../material-ui/Typography';
import { MoreVertIconContrast } from '../../../../icons/MoreVertIconContrast';
import { IconButton } from '../../../material-ui/IconButton';

const NavigationRouteCheckAltitude = ({ columnsCount, point, offset, pos, onMenuOpen }) => {
    const dispatch = useDispatch();
    const showModal = useShowModal();
    const classes = useStyles();

    const handleClick = () => {
        dispatch(showModal(TYPES.CHECK_ALTITUDE, { pos, point }));
    };

    const { fl = '-', captainAltimeter = '-', firstOfficerAltimeter = '-', standbyAltimeter = '-', dateCreate, comment } = point;
    let className;
    const dataClassName = `enr__tr${comment ? '' : '  enr__tr--end'}`;

    if (!captainAltimeter || !firstOfficerAltimeter || Math.abs(captainAltimeter - firstOfficerAltimeter) >= ALTIMETER_WARNING_LIMIT) {
        className = classes.caution;
    }

    const handleOpenMenu = (event) => {
        onMenuOpen(event, {
            point,
            offset,
            pos,
        });
    };

    const rows = [
        <tr className="enr__tr  enr__tr--bordered" key={0}>
            <td className="enr__td" colSpan={columnsCount - 1}>
                <Typography className={classes.title}>Checking altitude</Typography>
            </td>
            <td className={classnames('enr__td', 'enr__table-menu', classes.menuWrapper)} rowSpan={2}>
                <IconButton style={{ padding: '9px' }} onClick={handleOpenMenu}>
                    <MoreVertIconContrast />
                </IconButton>
            </td>
        </tr>,
        <tr className={dataClassName} key={1}>
            <td className={classnames('enr__td', classes.text)} colSpan={columnsCount - 1}>
                <div className="enr__td-wrapper">
                    <Typography display="inline" className={classnames('enr__td', classes.simple)}>
                        FL:{' '}
                    </Typography>
                    <Typography display="inline" className={classnames('enr__td', classes.bold)}>
                        {fl}
                    </Typography>
                    <Typography display="inline" className={classnames('enr__td', 'enr__td--start', classes.simple)}>
                        {'Captain: '}
                    </Typography>
                    <Typography display="inline" className={classnames('enr__td', classes.bold)}>
                        {captainAltimeter}
                    </Typography>
                    <Typography display="inline" className={classnames('enr__td', 'enr__td--start', classes.simple)}>
                        {'Standby: '}
                    </Typography>
                    <Typography display="inline" className={classnames('enr__td', classes.bold)}>
                        {standbyAltimeter}
                    </Typography>
                    <Typography display="inline" className={classnames('enr__td', 'enr__td--start', classes.simple)}>
                        {'First officer: '}
                    </Typography>
                    <Typography display="inline" className={classnames('enr__td', classes.bold)}>
                        {firstOfficerAltimeter}
                    </Typography>
                    <Typography display="inline" className={classnames('enr__td', 'enr__td--start', classes.simple)}>
                        {'Time of check: '}
                    </Typography>
                    <Typography display="inline" className={classnames('enr__td', classes.bold)}>
                        {dateCreate ? moment.utc(dateCreate).format('HH:mm') : ''}
                    </Typography>
                </div>
            </td>
        </tr>,
    ];

    if (comment) {
        rows.push(
            <tr className="enr__tr  enr__tr--end" key={2}>
                <td className="enr__td  enr__td--multiple_row" colSpan={columnsCount}>
                    <Typography>{comment}</Typography>
                </td>
            </tr>
        );
    }

    return (
        <tbody className={classnames('enr__item', className)} onClick={handleClick}>
            {rows}
        </tbody>
    );
};

export { NavigationRouteCheckAltitude };
