import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../components/material-ui/Dialog';
import { Typography } from '../../components/material-ui/Typography';
import { KeyboardDatePicker } from '../../components/material-ui/pickers/KeyboardDatePicker';
import { removeSeconds } from '../../service/DateTimeUtils';
import * as takeOff from '../../actions/take-off';
import * as landing from '../../actions/landing';
import { hideModal } from '../../actions/modal';
import { DialogControls } from '../../components/DialogControls/DialogControls';
import { CustomTimePicker } from '../../components/CustomTimePicker/CustomTimePicker';
import { useStyles } from './useStyles';

function convertTime(time) {
    return moment
        .utc(time || undefined)
        .second(0)
        .millisecond(0);
}

const DialogDateTimePicker = React.forwardRef((props, ref) => {
    const { value, name, title, text } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleModalClose = React.useCallback(() => {
        dispatch(hideModal());
    }, []);

    const handleSaveTime = React.useCallback((name, value) => {
        switch (name) {
            case 'startWorkTime':
            case 'startEngineTime':
            case 'takeOffTime':
                dispatch(takeOff.onChangeTime(name, value));
                break;
            case 'landingTime':
            case 'stopEngineTime':
            case 'endWorkTime':
                dispatch(landing.onChangeTime(name, value));
                break;
        }
    }, []);

    const [{ date, time, errorText }, setDateTime] = React.useState({
        date: convertTime(value),
        time: convertTime(value),
    });
    React.useEffect(() => {
        setDateTime({
            date: convertTime(value),
            time: convertTime(value),
        });
    }, [value]);

    const handleChangeDate = React.useCallback((_event, value) => {
        setDateTime((prevDate) => ({ ...prevDate, errorText: '', date: value }));
    }, []);

    const handleChangeTime = React.useCallback((_event, value) => {
        setDateTime((prevDate) => ({ ...prevDate, errorText: '', time: value ? removeSeconds(value) : null }));
    }, []);

    const handleSave = React.useCallback(() => {
        if (!time || !date) {
            setDateTime((prevState) => ({ ...prevState, errorText: 'Date and Time is required fields' }));
        } else {
            const result = moment.utc(date);
            result.hour(time.hour());
            result.minute(time.minute());
            removeSeconds(result);
            handleSaveTime(name, result.local());
            handleModalClose();
        }
    }, [time, date]);

    const actions = React.useMemo(
        () => [
            { label: 'Cancel', primary: false, onClick: handleModalClose },
            { label: 'OK', primary: true, onClick: handleSave },
        ],
        [handleSave]
    );

    return (
        <Dialog open={!!title} onClose={handleModalClose} className="datetime-popup">
            <DialogTitle disableTypography={true} className={classes.title} variant="inherit">
                <Typography className={classes.title} variant="h6">
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent ref={ref}>
                {text && (
                    <Typography className={classes.textWrapper} variant="body1">
                        {text}
                    </Typography>
                )}
                <Typography className={classes.error}>{errorText}</Typography>
                <div className={classes.wrapper}>
                    <KeyboardDatePicker onChange={handleChangeDate} value={date} />
                    <CustomTimePicker name="Time" value={time} onChange={handleChangeTime} className="dialog-datetime__time" />
                </div>
            </DialogContent>
            <DialogActions>
                <DialogControls actions={actions} />
            </DialogActions>
        </Dialog>
    );
});

const DialogDateTimePickerMemoized = React.memo(DialogDateTimePicker);

export { DialogDateTimePickerMemoized as DialogDateTimePicker };
