import React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { List, ListItem, ListItemText } from '../material-ui/List';
import { Panel } from '../Panel/Panel';
import { FlightTaskItem } from './FlightTaskitem/FlightTaskItem';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { ProgressIcon } from '../ProgressIcon/ProgressIcon';
import { getDownloadDateInfo } from './FlightTaskitem/tools';
import { getTasksList } from '../../actions/tasks';
import { setLoadingState } from '../../actions/progressIndicator';
import { getFlightTasks } from '../../selectors/screen';

import './FlightTaskPage.scss';

const FlightTaskPage = () => {
    const dispatch = useDispatch();
    const flightTasks = useSelector(getFlightTasks);
    const lastSync = useSelector((state) => getDownloadDateInfo('Updated', get(state, 'tasks.syncTime')));

    const handleLoadTasksList = React.useCallback(() => {
        dispatch(setLoadingState(true));
        dispatch(getTasksList());
    }, []);

    return (
        <ErrorBoundary>
            <div className="flight-task-list">
                <Panel
                    title="Flight task list"
                    rightText={<span style={{ padding: '0 16px 0 8px', display: 'block' }}>{lastSync}</span>}
                    icon={<ProgressIcon width="24" height="24" />}
                    onIconClick={handleLoadTasksList}
                >
                    <List>
                        {flightTasks && flightTasks.length > 0 ? (
                            flightTasks.map((item) => {
                                return (
                                    <FlightTaskItem
                                        key={item.id}
                                        id={item.id}
                                        primaryText={`${item.flightTask} (${moment(item.taskDate).format('DD.MM.YYYY')})`}
                                        flights={item.flights}
                                        download={get(item, 'syncTime')}
                                    />
                                );
                            })
                        ) : (
                            <ListItem disabled={true}>
                                <ListItemText primary="There are not any flight tasks" />
                            </ListItem>
                        )}
                    </List>
                </Panel>
            </div>
        </ErrorBoundary>
    );
};

const FlightTaskPageMemoized = React.memo(FlightTaskPage);

export { FlightTaskPageMemoized as FlightTaskPage };
