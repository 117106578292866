import React, { memo } from 'react';

import { TextField } from '../../../material-ui/TextField';
import { darkFullWhite } from '../../../../service/getMuiThemeStyle';
import { useStyles } from './useStyles';

export const Auth = memo(({ onChange, login, password, dark }) => {
    const classes = useStyles();

    return (
        <>
            <TextField
                required={true}
                type="login"
                name="login"
                label="Login"
                onChange={onChange}
                value={login}
                fullWidth={true}
                className={classes.field}
                InputProps={{
                    style: {
                        color: darkFullWhite,
                    },
                }}
                {...(dark && {
                    InputLabelProps: {
                        style: {
                            color: darkFullWhite,
                        },
                    },
                })}
            />
            <TextField
                required={true}
                type="password"
                name="password"
                label="Password"
                onChange={onChange}
                value={password}
                fullWidth={true}
                className={classes.field}
                InputProps={{
                    style: {
                        color: darkFullWhite,
                    },
                }}
                {...(dark && {
                    InputLabelProps: {
                        style: {
                            color: darkFullWhite,
                        },
                    },
                })}
            />
        </>
    );
});
