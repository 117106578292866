import { makeStyles } from '@material-ui/styles';
import { darkFullWhite } from '../../../service/getMuiThemeStyle';

const useStyles = makeStyles((theme) => ({
    appBar: {
        display: 'flex',
        zIndex: 1510,
    },
    icon: {
        fill: theme.icon.staticWhite,
    },
    toolbar: {
        paddingLeft: 4,
        paddingRight: theme.spacing(1),
    },
    title: {
        fontSize: '20px',
        marginLeft: theme.spacing(1),
        marginRight: 'auto',
        color: darkFullWhite,
    },
}));

export { useStyles };
