import React from 'react';
import * as moment from 'moment';
import classnames from 'classnames';

import Link from '../../../icons/image/Link';
import Star from '../../../icons/image/Star';
import Check from '../../../icons/image/Check';
import { IconButton } from '../../../components/material-ui/IconButton';
import { useStyles } from './useStyles';
import { Typography } from '../../../components/material-ui/Typography';
import { NotamAction, Purpose, Scope } from '../constants';

function GetAirportNoticeScopes(scope) {
    return Object.keys(Scope).reduce((res, key) => {
        const { code } = Scope[key];
        const itemKey = +key;
        if ((itemKey === 0 && scope === itemKey) || (itemKey > 0 && (scope & itemKey) === itemKey)) {
            res.push(code);
        }
        return res;
    }, []);
}

const AirportNotice = (props) => {
    const { notice = {}, toggleFavoriteNotice, toggleRecentNotice, colorName, isFirst = false } = props;
    const {
        series,
        num,
        year,
        action,
        // prevNoticeID,
        prevNoticeSeries,
        prevNoticeNumber,
        prevNoticeYear,
        // prevNoticeLastChangeTime,
        // nextNoticeID,
        // nextNoticeSeries,
        // nextNoticeNumber,
        // nextNoticeYear,
        // nextNoticeLastChangeTime,
        // fir,
        begin,
        end,
        schedule,
        content,
        scope,
        //subj,
        subjCode,
        subjEng,
        // subjGroup,
        // subjGroupEng,
        // subjGroupCode,
        // cond,
        condEng,
        condCode,
        // condType,
        // condTypeEng,
        // condTypeCode,
        isFavorite = false,
        isNew = false,
    } = notice;
    const { id } = notice;
    const classes = useStyles(colorName)();
    const title = ((subjCode || subjEng) && `${subjCode ? `${subjCode} ` : ''}${subjEng}`) || 'Noname';
    const subTitle = ((condCode || condEng) && `${condCode ? `${condCode} ` : ''}${condEng}`) || '';
    const shortYear = year && year.toString().substring(2);
    const shortYearLink = prevNoticeYear && prevNoticeYear.toString().substring(2);
    const number = `${series}${num}/${shortYear} ${NotamAction[action]}`;
    const numberLinked = (prevNoticeNumber && `${prevNoticeSeries}${prevNoticeNumber}/${shortYearLink}`) || '';
    const dateBegin = (begin && moment.utc(begin).format('DD-MMM-YYYY HH:mm')) || '';
    const dateEnd = (end && moment.utc(end).format('DD-MMM-YYYY HH:mm')) || 'Permanent';
    const className = classnames(
        isFirst && classes.noBorder,
        classes.notice,
        isFavorite && 'notice-item--favorite',
        isNew && '  notice-item--recent'
    );

    const handleToggleFavorite = React.useCallback(() => {
        if (id) {
            toggleFavoriteNotice(id);
        }
    }, [id]);

    const handleToggleRecent = React.useCallback(() => {
        if (id) {
            toggleRecentNotice(id);
        }
    }, [id]);

    const purpose = React.useMemo(
        () =>
            Object.keys(Purpose)
                .reduce((res, key) => {
                    if (notice[key.toLowerCase()]) {
                        res.push(key);
                    }

                    return res;
                }, [])
                .join(''),
        [notice]
    );

    const { ifr, vfr } = React.useMemo(() => {
        return {
            ifr: {
                ...(!notice.ifr && {
                    visibility: 'hidden',
                }),
            },
            vfr: {
                ...(!notice.vfr && {
                    visibility: 'hidden',
                }),
            },
        };
    }, [notice.ifr, notice.vfr]);

    const scopeStyle = React.useMemo(() => {
        return {
            ...(!GetAirportNoticeScopes(scope).length && {
                visibility: 'hidden',
            }),
        };
    }, [notice, scope]);

    const purposeStyle = React.useMemo(() => {
        return {
            ...(!purpose && {
                visibility: 'hidden',
            }),
        };
    }, [purpose]);

    return notice ? (
        <div className={className}>
            <header className={classes.titleWrapper}>
                <div>
                    <Typography className={classes.number}>{number}</Typography>
                    {numberLinked && <Link className={classes.linkIcon} width={16} heigh={16} />}
                    <Typography className={classes.number}>{numberLinked}</Typography>
                </div>
                <Typography className={classes.subtitle}>{title}</Typography>
                <Typography className={classes.subtitle}>{subTitle}</Typography>
                <div className={classes.controls}>
                    <IconButton onClick={handleToggleFavorite}>
                        <Star className={classnames(isFavorite && classes.favoriteIcon)} />
                    </IconButton>
                    <IconButton onClick={handleToggleRecent}>
                        <Check className={classnames(!isNew && classes.newIcon)} />
                    </IconButton>
                </div>
            </header>
            <div className={classes.details}>
                <Typography className={classes.begin}>
                    <span className={classes.periodTitle}>From: </span>
                    {dateBegin}
                </Typography>
                <Typography className={classes.end}>
                    <span className={classes.periodTitle}>To: </span>
                    {dateEnd}
                </Typography>
                <div className={classes.tags}>
                    <Typography className={classnames(classes.tag, classes.purpose)} style={purposeStyle}>
                        {purpose}
                    </Typography>
                    <Typography className={classnames(classes.tag, classes.ifr)} style={ifr}>
                        IFR
                    </Typography>
                    <Typography className={classnames(classes.tag, classes.vfr)} style={vfr}>
                        VFR
                    </Typography>
                    <Typography className={classnames(classes.tag, classes.scope)} style={scopeStyle}>
                        {GetAirportNoticeScopes(scope)}
                    </Typography>
                </div>
            </div>
            <Typography className={classes.text}>{schedule}</Typography>
            <Typography className={classes.text}>{content}</Typography>
        </div>
    ) : (
        <div className={className} />
    );
};

const AirportNoticeMemoized = React.memo(AirportNotice);

export { AirportNoticeMemoized as AirportNotice };
