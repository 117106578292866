import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        height: '100%',
        minHeight: 76,
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '0 12px',
    },
    subHeader: {
        lineHeight: '2em',
    },
    select: {
        width: '100%',
    },
    textStyle: {
        color: theme.palette.textColor,
    },
}));
