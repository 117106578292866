import React from 'react';

import { NestedListItem } from '../../NestedListItem/NestedListItem';
import { TelexItem } from './TelexItem/TelexItem';

const TelexList = (props) => {
    const { telegrams, styles, onOfpClick, onClickTelegram, onAttachmentClick, getStyle } = props;
    const telexCodes = Object.keys(telegrams).sort();

    return (
        <NestedListItem showSign={false} inset={false} style={styles.listItemWithBorder} primary="Telegrams" disabled={!telexCodes.length}>
            {telexCodes.map((code) => (
                <TelexItem
                    key={code}
                    telegram={telegrams[code]}
                    onAttachmentClick={onAttachmentClick}
                    onClick={code === 'CFP' ? onOfpClick : onClickTelegram}
                    getStyle={getStyle}
                />
            ))}
        </NestedListItem>
    );
};

TelexList.defaultProps = {
    telegrams: {},
    styles: {},
    onClickTelegram: () => {},
    onOfpClick: () => {},
    onAttachmentClick: () => {},
};

const MemoizedTelegramList = React.memo(TelexList);
export { MemoizedTelegramList as TelexList };
