import React from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';

import { DialogControls } from '../../../components/DialogControls/DialogControls';
import { useHideModal } from '../../../actions/modal';
import { multieditPoints } from '../../../actions/navigation';
import { getDeclarationFieldName, getFields, PointFields } from '../tools';
import { useMobileWidth } from '../../../components/hooks/useMobileWidth';
import { useForm } from '../../../components/hooks/useForm/useForm';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../../components/material-ui/Dialog';
import { FormControl } from '../../../components/material-ui/FormControl';
import { TextField } from '../../../components/material-ui/TextField';
import { Typography } from '../../../components/material-ui/Typography';
import { useStyles } from './useStyles';
import { useColumnsDeclaration } from '../../../selectors/routesDeclaration';

const fieldList = [PointFields.FL, PointFields.GS];

const MultieditPoint = React.forwardRef((props, ref) => {
    const { isNewPoint = false, pos } = props;
    const classes = useStyles();
    const { isMobilePortrait } = useMobileWidth();
    const columnsDeclaration = useColumnsDeclaration();
    const hideModal = useHideModal();
    const dispatch = useDispatch();
    const handleSuccess = React.useCallback(
        (data) => {
            dispatch(multieditPoints({ fl: data.fl, gs: data.gs }));
            hideModal();
        },
        [pos, isNewPoint]
    );

    const fields = React.useMemo(() => getFields(fieldList), []);
    const { inputs, handleInputChange, handleSubmit, status } = useForm({
        onSuccess: handleSuccess,
        fields,
    });

    const disableSubmit = Boolean(
        fieldList.find((key) => Object.values(status || {}).some((item) => item) || (fields[key].required && !inputs[key]))
    );
    const actions = React.useMemo(
        () => [
            { label: 'CANCEL', onClick: hideModal },
            { label: 'OK', color: 'primary', type: 'submit', disabled: disableSubmit },
        ],
        [disableSubmit]
    );

    return (
        <Dialog ref={ref} open={true} maxWidth="sm" fullScreen={isMobilePortrait}>
            <DialogTitle className={classes.title}>{isNewPoint ? 'Add check altitude' : 'Edit check altitude'}</DialogTitle>
            <form className={classes.fields} onSubmit={handleSubmit}>
                <DialogContent className={classes.content}>
                    <FormControl className={classes.formControl}>
                        <TextField
                            name={PointFields.FL}
                            label={getDeclarationFieldName(PointFields.FL, columnsDeclaration)}
                            placeholder={getDeclarationFieldName(PointFields.FL, columnsDeclaration)}
                            onChange={handleInputChange}
                            helperText={status[PointFields.FL]}
                            error={Boolean(status[PointFields.FL])}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            value={get(inputs, PointFields.FL, '')}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            name={PointFields.GS}
                            label={getDeclarationFieldName(PointFields.GS, columnsDeclaration)}
                            placeholder={getDeclarationFieldName(PointFields.GS, columnsDeclaration)}
                            onChange={handleInputChange}
                            helperText={status[PointFields.GS]}
                            error={Boolean(status[PointFields.GS])}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            value={get(inputs, PointFields.GS, '')}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                    <Typography color="error">{status.message}</Typography>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <DialogControls actions={actions} />
                </DialogActions>
            </form>
        </Dialog>
    );
});

export { MultieditPoint };
