import { useEffect, useRef, useState } from 'react';

export const useTouch = () => {
    const [isTouchDevice, setIsTouchDevice] = useState(false);
    const isTouchDeviceRef = useRef(isTouchDevice);

    useEffect(() => {
        isTouchDeviceRef.current = isTouchDevice;
    }, [isTouchDevice]);

    useEffect(() => {
        const checkTouchSupport = () => {
            const match = window.matchMedia('(hover: none) and (pointer: coarse)').matches;
            if (isTouchDeviceRef.current !== match) {
                setIsTouchDevice(match);
            }
        };

        checkTouchSupport();
        window.addEventListener('resize', checkTouchSupport);

        return () => {
            window.removeEventListener('resize', checkTouchSupport);
        };
    }, []);

    return isTouchDevice;
};
