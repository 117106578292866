import React from 'react';

import MaterialTextField from './MaterialTextField/MaterialTextField';
import { checkExistValue } from '../service/utils';

const TextFieldControlled = (props) => {
    const { onBlur, defaultValue, helperText, required, ...restProps } = props;
    const [errorText, changeError] = React.useState(required && !defaultValue ? 'Field is required' : '');
    const [value, changeValue] = React.useState('');
    React.useEffect(() => {
        if (checkExistValue(defaultValue) || props.helperText) {
            if (checkExistValue(defaultValue)) {
                changeValue(defaultValue);
            }

            if (props.helperText) {
                changeError(props.helperText);
            }
        }
    }, [defaultValue, props.helperText]);

    const handleChange = React.useCallback((event) => {
        changeValue(event.target.value);
    }, []);

    const handleInputFocus = React.useCallback((e) => {
        changeError('');

        if (props.onFocus) {
            props.onFocus(e);
        }
    }, []);

    return (
        <MaterialTextField
            key={name}
            {...restProps}
            onBlur={onBlur}
            onChange={handleChange}
            onFocus={handleInputFocus}
            helperText={errorText || helperText}
            value={value}
        />
    );
};

const TextFieldControlledMemoized = React.memo(TextFieldControlled);

export { TextFieldControlledMemoized as TextFieldControlled };
