import React from 'react';

import { useAnchorEl } from '../../../components/hooks/useAnchorEl';
import { Menu, MenuItem } from '../../../components/material-ui/Menu';
import { AppBar } from '../../../components/material-ui/AppBar';
import { Button } from '../../../components/material-ui/Button';
import { useStyles } from './useStyles';
import { Toolbar } from '../../../components/material-ui/Toolbar';
import { Typography } from '../../../components/material-ui/Typography';

const ReportDropzoneImage = (props) => {
    const { src, height, className, onDeleteImage, enableFullScreenMode = true, pos, isNew, quickShow = false } = props;
    const [anchorEl, handleOpenMenu, handleClose] = useAnchorEl();
    const classes = useStyles();
    const [fullScreen, changeFullScreen] = React.useState(false);
    const handleToggleFullScreen = React.useCallback(
        () =>
            changeFullScreen((prevState) => {
                handleClose();

                return !prevState;
            }),
        []
    );

    const handleDeleteImage = React.useCallback(() => {
        onDeleteImage(pos, isNew);
        handleClose();
    }, [pos, isNew]);

    const handleClickPreview = React.useCallback(
        (event) => {
            event.preventDefault();
            if (quickShow) {
                handleToggleFullScreen();
            } else {
                handleOpenMenu(event);
            }
        },
        [quickShow]
    );

    return (
        <>
            {fullScreen ? (
                <div className={classes.image}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" className={classes.title} color="inherit">
                                Report details
                            </Typography>
                            <Button color="inherit" onClick={handleToggleFullScreen}>
                                OK
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.inner} style={{ backgroundImage: `url(${src})` }}></div>
                </div>
            ) : (
                <img className={className} onClick={handleClickPreview} height={height} src={src} />
            )}
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {enableFullScreenMode && <MenuItem onClick={handleToggleFullScreen}>Show</MenuItem>}
                {onDeleteImage && <MenuItem onClick={handleDeleteImage}>Delete</MenuItem>}
            </Menu>
        </>
    );
};

const ReportDropzoneImageMemoized = React.memo(ReportDropzoneImage);

export { ReportDropzoneImageMemoized as ReportDropzoneImage };
