import * as React from 'react';
import { default as ErrorIcon } from '@material-ui/icons/ErrorOutline';
import { default as WarningIcon } from '@material-ui/icons/Warning';
import { default as InfoIcon } from '@material-ui/icons/Info';
import { default as SuccessIcon } from '@material-ui/icons/CheckCircleOutline';

import { NotificationTypes } from '../../../actions/notifications';

const Icon = {
    success: SuccessIcon,
    error: ErrorIcon,
    warning: WarningIcon,
    info: InfoIcon,
};

const SnackIcon = (props) => {
    const { variant, className } = props;
    if (variant === NotificationTypes.DEFAULT) {
        return <></>;
    }
    const IconComponent = Icon[variant];

    return <IconComponent width={20} className={className} />;
};

export { SnackIcon };
