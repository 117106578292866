import { UPDATE_DATA, GET_TASKS_LIST, SIGN_FLIGHT_TASK } from './../actions/tasks';
import { LOGOUT } from '../actions/screen';
import { REMOVE_DATA } from '../actions/tasks';
import { CLEAR_TASK_SIGNATURE_STATUS } from '../actions/tasks';

const initialState = { list: {} };

export default (state = initialState, action) => {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case UPDATE_DATA:
            return {
                ...state,
                list: {
                    ...state.list,
                    [action.taskId]: {
                        ...action.data,
                    },
                },
            };
        case REMOVE_DATA:
            delete newState.list[action.taskId];

            return newState;
        case GET_TASKS_LIST:
            newState.syncTime = action.syncTime;
            newState.list = Object.assign({}, action.tasks);

            return newState;

        case SIGN_FLIGHT_TASK:
            newState.list[action.taskId].signature = action.signature;
            newState.list[action.taskId].hasSignatureChange = true;

            return newState;
        case CLEAR_TASK_SIGNATURE_STATUS:
            newState.list[action.taskId].hasSignatureChange = true;

            return newState;
        case LOGOUT:
            return Object.assign({}, initialState);

        default:
            return state;
    }
};
