import React from 'react';

import { CheckboxCheck } from '../../../icons/image/CheckboxCheck';
import { CheckboxUncheck } from '../../../icons/image/CheckboxUncheck';
import { IconButton } from '../../../components/material-ui/IconButton';
import { Typography } from '../../../components/material-ui/Typography';
import { Swipeable } from '../../../components/Swipeable/Swipeable';
import { swipeDirections } from '../../../components/Swipeable/constant';
import { useStyles } from './useStyles';

export const CheckListItem = React.memo((props) => {
    const { item, checked = false, toggleCheckItem, isTouch } = props;
    const { templateItemId, name, controlValue } = item || {};
    const classes = useStyles();

    const handleToggleCheck = React.useCallback(() => {
        if (templateItemId) {
            toggleCheckItem(templateItemId);
        }
    }, [templateItemId, toggleCheckItem]);

    if (!item) {
        return null;
    }

    return (
        <Swipeable
            draggable
            maxDragShift="120"
            swipeDirection={swipeDirections.LEFT | swipeDirections.RIGHT}
            onSwiped={handleToggleCheck}
            className={classes.checkListItem}
        >
            <div className={classes.itemCell}>
                <Typography className={checked ? classes.nameChecked : classes.name}>{name}</Typography>
            </div>
            <div className={classes.itemCell}>
                <Typography className={checked ? classes.controlValueChecked : classes.controlValue}>{controlValue}</Typography>
            </div>
            <div className={classes.check}>
                <IconButton onClick={!isTouch ? handleToggleCheck : null} disableRipple={isTouch}>
                    {checked ? <CheckboxCheck width="30" height="30" /> : <CheckboxUncheck width="30" height="30" />}
                </IconButton>
            </div>
        </Swipeable>
    );
});
