import React from 'react';

import { Checkbox } from '../../../components/material-ui/Checkbox';
import { FormControlLabel } from '../../../components/material-ui/FormControlLabel';
import { Typography } from '../../../components/material-ui/Typography';
import { EnrouteSettingRow } from './EnrouteSettingRow';

const styles = {
    cellCheckBox: {
        width: '50px',
    },
    cellSm: {
        width: '50px',
        padding: '10px 0',
        fontSize: '18px',
        textAlign: 'center',
    },
    cell: {
        padding: '10px 0 10px 8px',
        fontSize: '18px',
    },
    checkbox: {
        display: 'block',
        width: '24px',
        marginLeft: '12px',
    },
};

const EnrouteSetting = (props) => {
    const { columns, selected, selectedAll, onToggleAll, onRowSelection, onCellSelection } = props;

    return (
        <>
            <Typography>This settings are used to configuring the short view for navigation route panel</Typography>
            <table className="navroute-settings__table" cellSpacing="0" cellPadding="0">
                <thead>
                    <tr>
                        <td style={styles.cellCheckBox}>
                            <FormControlLabel
                                control={<Checkbox checked={selectedAll} onChange={onToggleAll} color="primary" style={styles.checkbox} />}
                            />
                        </td>
                        <td tooltip="Column number" style={styles.cellSm}>
                            #
                        </td>
                        <td style={styles.cell}>
                            <Typography variant="body1">Row 1</Typography>
                        </td>
                        <td style={styles.cell}>
                            <Typography variant="body1">Row 2</Typography>
                        </td>
                        <td style={styles.cell}>
                            <Typography variant="body1">Row 3</Typography>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {columns.map((column, index) => {
                        const items = column.items || [];

                        return (
                            <EnrouteSettingRow
                                key={index}
                                num={index}
                                data={items}
                                checked={!!selected[index]}
                                {...{ onRowSelection, onCellSelection, styles }}
                            />
                        );
                    })}
                </tbody>
            </table>
        </>
    );
};

const EnrouteSettingMemoized = React.memo(EnrouteSetting);

export { EnrouteSettingMemoized as EnrouteSetting };
