import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import Left from '../../../../icons/image/Left';
import Right from '../../../../icons/image/Right';
import { useStyles } from '../useStyles';
import { FlightWizardStep } from '../FlightWizardStep/FlightWizardStep';
import { wizardSteps } from '../../tools';

function checkNavigationDisability(steps, stepNumber, isNextStepDisabled) {
    const step = steps[stepNumber];

    return !step || (step.checkDisable && isNextStepDisabled) || stepNumber < 0 || stepNumber >= steps.length;
}

function MobileWizardStepper({ isNextStepDisabled }) {
    const classes = useStyles();
    const activeStep = useSelector((state) => get(state, 'wizard.wizardStep', 0));
    const currentStep = wizardSteps[activeStep];
    const prevValue = activeStep > 0 ? activeStep - 1 : activeStep;
    const nextValue = activeStep <= wizardSteps.length - 1 ? activeStep + 1 : activeStep;
    const prevStepDisabled = checkNavigationDisability(wizardSteps, activeStep - 1, isNextStepDisabled);
    const nextStepDisabled = checkNavigationDisability(wizardSteps, activeStep + 1, isNextStepDisabled);

    return (
        <>
            <FlightWizardStep
                key="prev"
                icon={<Left width={24} height={24} {...(prevStepDisabled && { className: classes.disabledIcon })} />}
                index={prevValue}
                disabled={prevStepDisabled}
            />
            <FlightWizardStep
                key="current"
                index={currentStep.number}
                name={currentStep.name}
                disabled={currentStep.checkDisable && isNextStepDisabled}
                active={true}
            />
            <FlightWizardStep
                key="next"
                icon={<Right width={24} height={24} {...(nextStepDisabled && { className: classes.disabledIcon })} />}
                index={nextValue}
                disabled={nextStepDisabled}
            />
        </>
    );
}

const MobileWizardStepperMemoized = React.memo(MobileWizardStepper);

export { MobileWizardStepperMemoized as MobileWizardStepper };
