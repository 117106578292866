import * as React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/styles';
import classnames from 'classnames';

export const useStyles = makeStyles(() => ({
    helperText: {
        position: 'absolute',
        fontSize: '14px',
        lineHeight: '14px',
        top: '38px',
    },
}));

const CustomFormHelperText = (props) => {
    const classes = useStyles();

    return <FormHelperText {...props} className={classnames(classes.helperText, props.className)} />;
};

export { CustomFormHelperText as FormHelperText };
