import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
    info: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
    },
    inner: {
        width: '100%',
        display: 'flex',
        flex: '0 0 auto',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    fuel: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        margin: 6,
        flex: '1 1 45%',
        ['@media (max-width:1000px)']: {
            flexBasis: '100%',
        },
    },
}));
