import React from 'react';

import { Folder, Document } from '../../../icons';
import { sortLibrary } from '../../../service/sort';
import { LibraryItemMenu } from '../LibraryItemMenu/LibraryItemMenu';
import { ListItem, ListItemSecondaryAction, ListItemIcon, ListItemText } from '../../material-ui/List';
import { FolderItem } from '../FolderItem/FolderItem';
import { stopPropagation } from '../../../service/eventHandlers';
import { useStyles } from './useStyles';
import { downloadLibraryFolder, downloadLibraryItems, removeFile, removeFolder, showPdf } from '../../../actions/library';
import { useDispatch } from 'react-redux';
import { DocumentPrefix } from '../../../service/constants';

function LibraryItem({ id, data, libraryItems, primaryText, secondaryText, isSearchMode }) {
    const { type, child, isDownloaded, needUpdate, hasUpdates } = data;
    const dispatch = useDispatch();
    const classes = useStyles(needUpdate, hasUpdates)();
    const [isOpen, setOpen] = React.useState(false);
    const lvl = isSearchMode ? 0 : data.lvl;

    const handleClick = React.useCallback(
        (evt) => {
            stopPropagation(evt);
            if (+type === 0) {
                setOpen((prevState) => !prevState);

                return;
            }

            if (isDownloaded) {
                dispatch(showPdf(`${DocumentPrefix.LIBRARY}${id}`));
            } else {
                dispatch(downloadLibraryItems([id]));
            }
        },
        [id, type, isDownloaded]
    );

    const handleDownloadFolder = React.useCallback(() => {
        if (+type === 0) {
            dispatch(downloadLibraryFolder(id));
        } else {
            dispatch(downloadLibraryItems([id]));
        }
    }, [id, type]);

    const handleRemoveItem = React.useCallback(() => {
        if (+type === 0) {
            dispatch(removeFolder(id));
        } else {
            dispatch(removeFile(id));
        }
    }, [id, type]);

    const folderItem = React.useMemo(() => {
        if (+type !== 0) {
            return '';
        }
        const children = child
            .map((id) => libraryItems[id])
            .sort(sortLibrary)
            .reduce((res, item) => {
                if (item) {
                    res.push(<LibraryItem key={item.id} id={item.id} data={item} primaryText={item.name} libraryItems={libraryItems} />);
                }

                return res;
            }, []);

        return children.length > 0 ? (
            <FolderItem
                showSign={false}
                inset={false}
                style={{ paddingLeft: `${(lvl + 1) * 8}px`, paddingRight: '8px' }}
                className={classes.listStyle}
                primary={primaryText}
                secondary={secondaryText}
                open={isOpen}
                onClick={handleClick}
                icon={<Folder width="24" height="24" isDashed={!isDownloaded} />}
                rightIcon={<LibraryItemMenu onDownload={handleDownloadFolder} onRemove={handleRemoveItem} />}
            >
                {children}
            </FolderItem>
        ) : (
            ''
        );
    }, [id, type, child, libraryItems, isOpen, isDownloaded]);

    return +type === 0 ? (
        folderItem
    ) : (
        <ListItem
            id={id}
            onClick={handleClick}
            className={classes.listStyle}
            style={{ paddingLeft: `${(lvl + 1) * 8}px`, paddingRight: '8px' }}
            button={true}
        >
            <ListItemIcon>
                <Document width="24" height="24" isDashed={!isDownloaded} />
            </ListItemIcon>
            <ListItemText primary={primaryText} secondary={secondaryText} className={classes.text} />
            <ListItemSecondaryAction className={classes.menu}>
                <LibraryItemMenu onDownload={handleDownloadFolder} onRemove={handleRemoveItem} />
            </ListItemSecondaryAction>
        </ListItem>
    );
}

const LibraryItemMemoized = React.memo(LibraryItem);

export { LibraryItemMemoized as LibraryItem };
