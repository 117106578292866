import React, { useContext } from 'react';
import classnames from 'classnames';

import { SwipeableDrawer } from '../material-ui/Drawer';
import { useStyles } from './useStyles';
import { SidebarContent } from './SidebarContent';
import { SidebarContext } from '../PageLayout/ContextProvider';

import './Sidebar.scss';

function Sidebar({ ...restProps }) {
    const {
        sidebarVisibility: { navigationPanelVisibility },
        handleToggleNavigationPanel,
    } = useContext(SidebarContext);

    const classes = useStyles();

    const handleOpen = React.useCallback(() => {
        handleToggleNavigationPanel(true);
    }, [handleToggleNavigationPanel]);

    const handleClose = React.useCallback(() => {
        handleToggleNavigationPanel(false);
    }, [handleToggleNavigationPanel]);

    return (
        <SwipeableDrawer
            open={navigationPanelVisibility}
            anchor="left"
            // variant="permanent"
            disableSwipeToOpen={false}
            onClose={handleClose}
            onOpen={handleOpen}
            className={classnames(classes.drawerClass, {
                [classes.drawerOpenClass]: navigationPanelVisibility,
                [classes.drawerCloseClass]: !navigationPanelVisibility,
            })}
            classes={{
                paper: classnames(
                    {
                        [classes.drawerOpenClass]: navigationPanelVisibility,
                        [classes.drawerCloseClass]: !navigationPanelVisibility,
                    },
                    classes.drawerPaper
                ),
            }}
        >
            <SidebarContent {...restProps} onToggleNavigationPanel={handleToggleNavigationPanel} />
        </SwipeableDrawer>
    );
}

const SidebarMemoized = React.memo(Sidebar);

export { SidebarMemoized as Sidebar };
