import React from 'react';

import { Panel } from '../../Panel/Panel';
import { List, ListItem, ListItemText } from '../../material-ui/List';
import { EmployeeItem } from './../EmployeeItem/EmployeeItem';
import { useStyles } from './useStyles';

const EmployersList = ({ data, title }) => {
    const classes = useStyles();

    return (
        <Panel title={title} className={classes.panel}>
            <List>
                {data && data.length > 0 ? (
                    data.map((emp) => <EmployeeItem key={emp.crewId} {...emp} />)
                ) : (
                    <ListItem disabled={true}>
                        <ListItemText primary="There is no crew information" />
                    </ListItem>
                )}
            </List>
        </Panel>
    );
};

const EmployersListMemoized = React.memo(EmployersList);

export { EmployersListMemoized as EmployersList };
