export const LANDING_FIELDS = [
    { id: 'cgPct', title: 'сg pct', type: 'number', validator: { type: 'decimal', precision: [7, 2] } },
    { id: 'msa', title: 'msa', type: 'text', validator: { type: 'text', length: 20 } },
    { id: 'tl', title: 'tl', type: 'number', validator: { type: 'int' } },
    { id: 'star', title: 'star', type: 'text', validator: { type: 'text', length: 20 } },
    { id: 'rw', title: 'rw', type: 'text', validator: { type: 'text', length: 6 } },
    { id: 'app', title: 'app', type: 'text', validator: { type: 'text', length: 10 } },
    { id: 'minim', title: 'minim', type: 'text', validator: { type: 'text', length: 20 } },
    { id: 'ga_n1', title: 'ga n1', type: 'number', validator: { type: 'decimal', precision: [7, 2] } },
    { id: 'flaps', title: 'flaps', type: 'number', validator: { type: 'decimal', precision: [7, 2] } },
    { id: 'v_ref', title: 'v ref', type: 'number', validator: { type: 'int' } },
    { id: 'v_apr', title: 'v apr', type: 'number', validator: { type: 'int' } },
    { id: 'ld', title: 'ld', type: 'text', validator: { type: 'text', length: 20 } },
    { id: 'wd', type: 'number', validator: { type: 'int', min: 0, max: 360 } },
    { id: 'ws', type: 'text', validator: { type: 'text', length: 10 } },
    { id: 'vis', type: 'text', validator: { type: 'text', length: 20 } },
    { id: 'ceiling', type: 'text', validator: { type: 'text', length: 10 } },
    { id: 'temp', type: 'number', validator: { type: 'int', min: -100, max: 100 } },
    { id: 'dewPoint', type: 'number', validator: { type: 'int', min: -100, max: 100 } },
    { id: 'qnh', type: 'number', validator: { type: 'decimal', precision: [9, 2], min: 0, max: 1100 } },
    { id: 'qfe', type: 'number', validator: { type: 'int', min: 0, max: 10000 } },
    { id: 'ldar', type: 'number', validator: { type: 'int', min: 0, max: 1000000 } },
    { id: 'starTrans', type: 'text', validator: { type: 'text', length: 20 } },
    { id: 'rwFric', type: 'text', validator: { type: 'text', length: 6 } },
    { id: 'rwhdg', type: 'number', validator: { type: 'int', min: 0, max: 360 } },
    { id: 'fapAlt', type: 'number', validator: { type: 'int', min: 0, max: 100000 } },
    { id: 'outMkr', type: 'number', validator: { type: 'int', min: 0, max: 10000 } },
    { id: 'inMkr', type: 'number', validator: { type: 'int', min: 0, max: 10000 } },
    { id: 'destLndDist', type: 'number', validator: { type: 'int', min: 0, max: 100000 } },
    { id: 'qnhAlt', type: 'number', validator: { type: 'decimal', precision: [7, 2] } },
];
