import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { default as NavigationClose } from '@material-ui/icons/Close';
import { default as NavigationMenu } from '@material-ui/icons/Menu';

import { IconButton } from '../material-ui/IconButton';
import { AppBar } from '../material-ui/AppBar';
import { Toolbar } from '../material-ui/Toolbar';
import { BackupStatus } from '../BackupStatus/BackupStatus';
import { AppBarTitle } from './AppBarTitle';
import { AppBarVersion } from './AppBarVersion';
import { AppBarBadge } from './AppBarBadge';
import { ThemeToggler } from './ThemeToggler';
import { AppBarMenu } from './AppBarMenu/AppBarMenu';
import { useStyles } from './useStyles';
import { GpsPanel } from '../GpsPanel/GpsPanel';
import { logout } from '../../actions/screen';
import { showConfirmDialog } from '../../actions/messageDialog';
import { SidebarContext } from '../PageLayout/ContextProvider';

import './index.scss';

const ApplicationBar = () => {
    const dispatch = useDispatch();
    const {
        sidebarVisibility: { navigationPanelVisibility },
        handleToggleNavigationPanel,
        handleToggleNotificationSidebar,
    } = useContext(SidebarContext);

    const classes = useStyles();
    const shortView = useMediaQuery('(max-width: 480px)');

    const handleToggleNavBar = React.useCallback(
        (evt) => {
            evt.preventDefault();
            handleToggleNavigationPanel();
        },
        [handleToggleNavigationPanel]
    );

    const handleClickLogout = React.useCallback(() => {
        dispatch(
            showConfirmDialog({
                title: 'Confirmation dialog',
                message: 'Are you sure you want to logout? All unsaved data will be lost.',
                btnCancelVisibility: true,
                options: {
                    okWarning: true,
                },
            })
        ).then((isOk) => {
            if (isOk) {
                dispatch(logout());
            }
        });
    }, []);

    // const handleRemoveDocs = React.useCallback(() => {
    //     showConfirmDialog({
    //         title: 'Confirmation dialog',
    //         message: "Are you sure you want to remove all saved application's data? After that you must logout.",
    //     }).then(isOk => {
    //         if (isOk) {
    //             dispatch(removeDocs(isOk));
    //         }
    //     });
    // }, []);

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar disableGutters={true} className={classes.toolbar}>
                <IconButton color="inherit" aria-label="menu" onClick={handleToggleNavBar} style={{ marginRight: '6px' }}>
                    {navigationPanelVisibility ? (
                        <NavigationClose className={classes.icon} aria-label="Menu" />
                    ) : (
                        <NavigationMenu className={classes.icon} aria-label="Menu" />
                    )}
                </IconButton>
                <AppBarTitle />
                <AppBarVersion shortView={shortView} onLogout={handleClickLogout} />
                <AppBarBadge onClick={handleToggleNotificationSidebar} />
                <BackupStatus />
                <ThemeToggler />
                <GpsPanel />
                <AppBarMenu onLogout={handleClickLogout} />
            </Toolbar>
        </AppBar>
    );
};

const StyledApplicationBar = React.memo(ApplicationBar);

export { StyledApplicationBar as AppBar };
