import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
    const {
        palette,
        icon: { color },
    } = theme;
    const { textColor } = palette;

    return {
        avatar: {
            width: 240,
            height: 240,
            marginLeft: 'auto',

            [theme.breakpoints.down('xs')]: {
                marginRight: 'auto',
            },
        },
        avatarWrapper: {
            flex: '0 0 256px',
            marginTop: theme.spacing(2),

            [theme.breakpoints.down('xs')]: {
                marginTop: 0,
            },
        },
        content: {
            display: 'flex',
            padding: theme.spacing(0, 1),

            [theme.breakpoints.down('sm')]: {
                padding: 0,
            },

            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
            },
        },
        icon: {
            color,
        },
        fullName: {
            fontWeight: 600,
        },
        link: {
            marginTop: 0,

            '&:last-of-type': {
                marginBottom: 0,
            },
        },
        list: {
            display: 'flex',
            flex: '1 1 240px',
            flexDirection: 'column',
            wordBreak: 'break-all',
        },
        paper: {
            maxWidth: 800,
        },
        staffTree: {
            paddingLeft: theme.spacing(7),
        },
        text: {
            color: textColor,
        },
        title: {
            padding: theme.spacing(1, 1, 1),
        },
    };
});

export { useStyles };
