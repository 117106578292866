import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => {
    return {
        wrapper: {
            marginBottom: theme.spacing(1),
            background: theme.palette.grey[theme.palette.type === 'dark' ? 900 : 200],

            ['@media (max-width: 480px)']: {
                flexWrap: 'wrap',
            },
        },
    };
});

export { useStyles };
