import React from 'react';

import { FormControl } from '../../material-ui/FormControl';
import { FormControlLabel } from '../../material-ui/FormControlLabel';
import { Checkbox } from '../../material-ui/Checkbox';
import { Switch } from '../../material-ui/Switch';
import { SidebarSelect } from './SidebarSelect/SidebarSelect';
import { SidebarRadio } from './SidebarRadio/SidebarRadio';
import { useSwitchStyles } from '../hooks/useSwitchStyles';
import { InputTypes, filterSettings } from '../constants';

export const FilterComponents = React.memo(({ filtersSet, filterInputs, setFilterInputs, selectData, classes }) => {
    const switchStyles = useSwitchStyles({ before: 'incl', after: 'excl' });

    const handleChangeSwitch = React.useCallback(({ target: { name } }) => {
        setFilterInputs((prevState) => ({
            ...prevState,
            inclExclSwitch: { ...prevState.inclExclSwitch, [name]: !prevState.inclExclSwitch?.[name] },
        }));
    }, []);

    const handleChangeCheckbox = React.useCallback((event) => {
        event.persist();
        setFilterInputs((prevState) => ({ ...prevState, [event.target.name]: !prevState[event.target.name] }));
    }, []);

    return (
        <>
            {filtersSet.map((it) => {
                const settings = filterSettings[it.name];
                switch (it.type) {
                    case InputTypes.SELECT_LIST:
                    case InputTypes.SELECT_LIST_MULTI:
                        //Если не получили параметры фильтра или нет предпологаемых значений для отбора фильтра, то Select не показываем
                        if (!settings || Object.keys(selectData).length === 0 || !selectData[it.name] || !selectData[it.name].length) {
                            return null;
                        }

                        return (
                            <FormControl className={classes.select} key={settings.name}>
                                <SidebarSelect
                                    data={selectData[it.name]}
                                    isMulti={it.type === InputTypes.SELECT_LIST_MULTI}
                                    filterInputs={filterInputs}
                                    setFilterInputs={setFilterInputs}
                                    settings={settings}
                                />
                                {filterInputs.inclExclSwitch?.hasOwnProperty(settings.name) && (
                                    <Switch
                                        name={settings.name}
                                        classes={switchStyles}
                                        checked={filterInputs.inclExclSwitch[settings.name] || false}
                                        onChange={handleChangeSwitch}
                                    />
                                )}
                            </FormControl>
                        );

                    case InputTypes.CHECKBOX:
                        return settings ? (
                            <FormControlLabel
                                className={classes.checkbox}
                                name={settings.name}
                                key={settings.name}
                                checked={filterInputs[settings.name] || false}
                                onChange={handleChangeCheckbox}
                                control={<Checkbox edge="start" color="primary" />}
                                label={settings.translation}
                            />
                        ) : null;

                    case InputTypes.RADIO_GROUP:
                        return settings ? (
                            <SidebarRadio
                                key={settings.name}
                                filterValue={filterInputs[settings.name]}
                                {...{ filterInputs, setFilterInputs, settings }}
                            />
                        ) : null;
                    default:
                        return null;
                }
            })}
        </>
    );
});
