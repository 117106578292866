import * as React from 'react';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth/withWidth';

import { Dialog } from './Dialog';

const DialogAdaptive = (props) => {
    const { width, children, fullScreenBreakpoint, ...rest } = props;
    const isMobile = React.useMemo(() => isWidthUp(width, fullScreenBreakpoint || 'xs'), [width]);

    return (
        <Dialog fullScreen={isMobile} {...rest}>
            {children}
        </Dialog>
    );
};

const WithWidthDialogAdaptive = withWidth()(DialogAdaptive);

export { WithWidthDialogAdaptive as DialogAdaptive };
