import React from 'react';
import { useSelector } from 'react-redux';
import { default as NotificationsIcon } from '@material-ui/icons/Notifications';
import { makeStyles } from '@material-ui/core/styles';

import { Badge } from '../material-ui/Badge';
import { IconButton } from '../material-ui/IconButton';
import { getLibraryNotifications } from '../../selectors/library';
import { getDocumentsNotifications } from '../../selectors/documents';
import { getDefectsNotifications } from '../../selectors/defects';

const AppBarBadge = ({ onClick }) => {
    const classes = useStyles();
    const notificationCount = useSelector((state) => {
        const libraryWithWarning = getLibraryNotifications(state) || [];
        const documentsWithWarning = getDocumentsNotifications(state) || [];
        const defectsWithWarning = getDefectsNotifications(state) || [];

        return libraryWithWarning.length + documentsWithWarning.length + defectsWithWarning.length;
    });

    return notificationCount > 0 ? (
        <Badge badgeContent={notificationCount} overlap="circular" color="secondary" max={999}>
            <IconButton tooltip="Notifications" onClick={onClick}>
                <NotificationsIcon className={classes.icon} />
            </IconButton>
        </Badge>
    ) : (
        ''
    );
};

const useStyles = makeStyles((theme) => ({
    icon: {
        fill: theme.icon.staticWhite,
    },
}));

const AppBarBadgeMemoized = React.memo(AppBarBadge);

export { AppBarBadgeMemoized as AppBarBadge };
