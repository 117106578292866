import { createSelector } from 'reselect';

import { getTimeDiff } from '../service/DateTimeUtils';

const GOOD_QUALITY_LIMIT = 30;
const NORMAL_QUALITY_LIMIT = 60;

const GpsQuality = {
    GOOD: 0,
    NORMAL: 1,
    BAD: 2,
};

const getGpsQuality = createSelector(
    (state) => state.gps.successTime,
    (state) => state.gps.lastErrorTime,
    (successTime, lastErrorTime) => {
        if (!lastErrorTime) {
            return GpsQuality.GOOD;
        }
        const timeFrom = getTimeDiff(successTime, lastErrorTime);
        if (timeFrom <= GOOD_QUALITY_LIMIT) {
            return GpsQuality.GOOD;
        } else if (timeFrom <= NORMAL_QUALITY_LIMIT) {
            return GpsQuality.NORMAL;
        }

        return GpsQuality.BAD;
    }
);

export { getGpsQuality, GpsQuality };
