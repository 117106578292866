import React from 'react';
import { get } from 'lodash';
import { useTheme } from '@material-ui/core/styles';

import { getPointFontSize } from '../../../../service/NavPointsUtils';
import { SplitedCell } from '../../../../components/NavigationRoutes/SplittedCell/SplittedCell';
import { Typography } from '../../../../components/material-ui/Typography';

const AlternateRouteHeadRow = (props) => {
    const { mobileEnrouteMode = false, index, columns } = props;
    const fontSize = getPointFontSize(mobileEnrouteMode, columns);
    const theme = useTheme();

    return (
        <tr className="enr__table-row">
            {columns.map(({ items }, pos) => {
                const dataCol = items && items[index];
                const isArray = Array.isArray(dataCol);
                const dataKey = get(dataCol, 'id', pos);
                const title = get(dataCol, 'title', '');
                const className = `enr__table-cell  enr__table-cell_key_${dataKey}`;

                return (
                    <th
                        className={className}
                        key={dataKey}
                        style={{
                            color: theme.palette.primary3Color,
                            fontSize,
                        }}
                    >
                        {isArray ? (
                            <div className="enr__table-cell-inner">
                                {dataCol.map(({ title }, i, arr) => (
                                    <SplitedCell key={i} part={arr.length} data={title} />
                                ))}
                            </div>
                        ) : (
                            <Typography style={{ color: 'inherit' }}>{title}</Typography>
                        )}
                    </th>
                );
            })}
        </tr>
    );
};

export { AlternateRouteHeadRow };
