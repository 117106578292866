import React from 'react';
import classnames from 'classnames';

import { Panel } from '../../Panel/Panel';
import { SummaryCrewTaskInfo } from '../SummaryCrewTaskInfo/SummaryCrewTaskInfo';
import { SummaryCrewLegInfo } from '../SummaryCrewLegInfo/SummaryCrewLegInfo';
import { CrewTypes } from '../../../service/constants';
import { useStyles } from './useStyles';
import { Typography } from '../../material-ui/Typography';
import { getDataFormat, summaryFields } from './tools';
import { getTimeFormat } from '../../../service/NavPointsUtils';

const SummaryBufferCrew = (props) => {
    const { crew = {}, declaration = {} } = props;
    const { legs, total = {}, previousTasks } = crew;
    const { chairEng = '' } = total;
    const classes = useStyles();
    if (!crew) {
        return;
    }

    let rowIndex = 0;

    return (
        <Panel
            toolbarClassName={crew.total.crewType === CrewTypes.PCT_CABINSTAFF ? classes.cabinToolbar : classes.salonToolbar}
            className={classes.panel}
            customToolbar={
                <Typography className={classes.toolbar}>{`${crew.tableNumber || ''}  ${crew.fullNameEng || ''}  ${chairEng}`}</Typography>
            }
        >
            <table className={classes.table}>
                <thead>
                    <tr className={classes.header}>
                        <th className={classnames(classes.headerCell, classes.flight)}>Flight #</th>
                        {summaryFields.map(
                            ({ id, title }, index) =>
                                declaration[id] && (
                                    <th key={index} className={classnames(classes.headerCell, id === 'pf' && classes.pf)}>
                                        {title}
                                    </th>
                                )
                        )}
                    </tr>
                </thead>
                <tbody>
                    {previousTasks &&
                        previousTasks.map((previousTask, index) => {
                            rowIndex = index;
                            return (
                                <SummaryCrewTaskInfo
                                    previousTask={previousTask}
                                    key={index}
                                    number={rowIndex}
                                    declaration={declaration}
                                    fields={summaryFields}
                                />
                            );
                        })}
                    {(() => {
                        rowIndex += previousTasks.length > 0 ? 1 : 0;
                    })()}
                    {legs &&
                        legs.map((leg, index) => {
                            rowIndex += index;
                            return (
                                <SummaryCrewLegInfo
                                    leg={leg}
                                    key={index}
                                    number={rowIndex}
                                    lastLeg={legs.length - 1}
                                    declaration={declaration}
                                    fields={summaryFields}
                                    getDataFormat={getDataFormat}
                                />
                            );
                        })}
                </tbody>
                <tfoot>
                    <tr className={classes.tableFoot}>
                        <td>Total:</td>
                        {summaryFields.map(
                            ({ id, format, totalFormat }, index) =>
                                declaration[id] && (
                                    <td key={index}>
                                        {(id === 'work' && getTimeFormat(total[id] + (total.previousWork || 0))) ||
                                            getDataFormat(id, totalFormat || format, total)}
                                    </td>
                                )
                        )}
                    </tr>
                </tfoot>
            </table>
        </Panel>
    );
};

const SummaryBufferCrewMemoized = React.memo(SummaryBufferCrew);

export { SummaryBufferCrewMemoized as SummaryBufferCrew };
