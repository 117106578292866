import { useState, useCallback } from 'react';

export const LoginStep = {
    LOGIN: 0,
    CHECK_CONFIRMATION: 1,
};

export const useStep = () => {
    const [step, setStep] = useState(LoginStep.LOGIN);

    const handleStepChange = useCallback((newStep) => setStep(newStep), []);

    return { step, showStep: handleStepChange };
};
