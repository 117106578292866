import React from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useTheme } from '@material-ui/core/styles';

import { SplitedCell } from '../../../SplittedCell/SplittedCell';
import { getPointFontSize } from '../../../../../service/NavPointsUtils';
import { IconButton } from '../../../../material-ui/IconButton';
import Target from '../../../../../icons/image/Target';
import { MessageDialogTypes } from '../../../../MessageDialog/MessageDialog';
import { showConfirmDialog } from '../../../../../actions/messageDialog';

const NavigationRouteHeaderRow = (props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { widths, index, mobileEnrouteMode = false } = props;
    const fontSize = getPointFontSize(mobileEnrouteMode, props.columns);
    const handleTargetClick = React.useCallback(() => {
        const currentPoint = document.querySelector('.enr__item--current');
        if (currentPoint) {
            window.scrollTo(currentPoint.offsetLeft, currentPoint.offsetTop - 30);
        } else {
            dispatch(
                showConfirmDialog({
                    title: 'Warning message',
                    message: 'Could not find current position. Turn on the location service or edit the current point!',
                    btnCancelVisibility: false,
                    options: {
                        type: MessageDialogTypes.DEFAULT,
                    },
                })
            );
        }
    }, []);

    const columns = React.useMemo(
        () => props.columns.filter((item) => (!item.hidden && ((item.mobile && mobileEnrouteMode) || !mobileEnrouteMode)) || !item.items),
        [props.columns, mobileEnrouteMode]
    );

    return (
        <tr className="enr__tr">
            {columns.map(({ items, isMenu }, pos) => {
                const col = items && (items[0] || items[1] || items[2]);
                const key = get(col, 'id', pos);
                const dataCol = items && items[index];
                const isArray = Array.isArray(dataCol);
                const dataKey = get(dataCol, 'id', pos);
                const title = get(dataCol, 'title', '');
                const className = `enr__td  enr__td_key_${dataKey}`;

                return (
                    <th
                        className={className}
                        key={dataKey}
                        style={{
                            width: widths[key] || 'inherit',
                            color: theme.palette.primary3Color,
                            fontSize,
                            ...(isMenu && { padding: 0, textAlign: 'center' }),
                        }}
                        data-column-name={dataKey}
                        {...(isMenu && { rowSpan: 3 })}
                    >
                        {!isMenu ? (
                            isArray ? (
                                <div className="enr__td-inner">
                                    {dataCol.map(({ title }, i, arr) => (
                                        <SplitedCell key={i} part={arr.length} data={title} />
                                    ))}
                                </div>
                            ) : (
                                title
                            )
                        ) : (
                            index === 0 && (
                                <IconButton onClick={handleTargetClick} style={{ marginTop: -8 }}>
                                    <Target />
                                </IconButton>
                            )
                        )}
                    </th>
                );
            })}
        </tr>
    );
};

export { NavigationRouteHeaderRow };
