const MAX_POSITION_ERRORS_BEFORE_RESET = 5;
const PositionError = {
    PERMISSION_DENIED: 1,
    POSITION_UNAVAILABLE: 2,
    TIMEOUT: 3,
};

class Geolocation {
    constructor() {
        this.watchpositionErrorCount = 0;
    }

    start = (onSuccess, onError) => {
        if (this._watchId) {
            this.stop();
        }
        this.onSuccess = onSuccess;
        this.onError = onError;

        this._watchId = setInterval(this._onSuccess, 5000);
    };

    stop = () => {
        this.onSuccess = null;
        if (this._watchId) {
            clearInterval(this._watchId);
        }
    };

    _onSuccess = () => {
        this.watchpositionErrorCount = 0;

        if (this.onSuccess) {
            this.onSuccess({
                latitude: `${Math.random() * 8 + 59}`,
                longitude: `${Math.random() * 24 + 29}`,
                speed: 100,
                altitude: 3200,
                accuracy: 89,
                altitudeAccuracy: 167,
                timestamp: +new Date(),
            });
        }
    };

    _onError = (err = {}) => {
        if (err.code == PositionError.TIMEOUT && this.watchpositionErrorCount >= MAX_POSITION_ERRORS_BEFORE_RESET) {
            this.start(this.onSuccess);
            this.watchpositionErrorCount = 0;
        }

        if (this.onError) {
            this.onError();
        }
    };
}

const geolocation = new Geolocation();

export default geolocation;
