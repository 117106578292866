import { getFloatCoordinates, REGEXP_LAT, REGEXP_LONG } from '../../../service/NavPointsUtils';

const MAX_TYNYINT = 255;
const MAX_SMALLINT = 32767;
const MAX_INT = 2147483647;
export const VALIDATION_EMPTY_FIELD = 'Is required';
export const MAX_LATITUDE_INTEGER = 5;
export const MAX_LONGITUDE_INTEGER = 6;

const getRangeFromPrecision = (precision = []) => {
    const [int = 18, fraction = 0] = precision;
    let max = (Math.pow(10, int) - 1) / Math.pow(10, fraction);

    return [-max, max];
};

export const FieldTypes = {
    DATE: 'date',
    MOMENT: 'moment',
    NUMBER: 'number',
    STRING: 'string',
};

export const ValidatorTypes = {
    DECIMAL: 'decimal',
    INT: 'int',
    SMALLINT: 'smallint',
    TEMPLATE: 'template',
    TEXT: 'text',
    TINYINT: 'tinyint',
    LATITUDE: 'latitude',
    LONGITUDE: 'longitude',
};

const checkType = (type, value) => {
    if (type === FieldTypes.DATE) {
        return typeof value.getMonth === 'function';
    }

    if (type === FieldTypes.MOMENT) {
        return typeof value.seconds === 'function';
    }

    if (type === FieldTypes.NUMBER) {
        return Number.isInteger(Number(value)) || !isNaN(parseFloat(value));
    }

    if (type === FieldTypes.STRING) {
        return typeof value === 'string';
    }

    return false;
};

export const checkRange = ({ type, min: minValue, max: maxValue, precision = [] }, fieldValue) => {
    const value = +fieldValue;
    const strValue = value + '';
    const [, maxFraction = 0] = precision;
    let min, max;

    const decimal = (strValue.indexOf('.') !== -1 && strValue.substring(strValue.indexOf('.') + 1, strValue.length)) || '';

    switch (type) {
        case ValidatorTypes.TINYINT:
            min = 0;
            max = MAX_TYNYINT;
            break;
        case ValidatorTypes.SMALLINT:
            min = -MAX_SMALLINT - 1;
            max = MAX_SMALLINT;
            break;
        case ValidatorTypes.INT:
            min = -MAX_INT - 1;
            max = MAX_INT;
            break;
        case ValidatorTypes.DECIMAL:
            [min, max] = getRangeFromPrecision(precision);
            break;
    }

    min = minValue !== undefined ? Math.max(min, minValue) : min;
    max = maxValue !== undefined ? Math.min(max, maxValue) : max;

    if (min !== undefined && max !== undefined && !(value >= min && value <= max)) return `Value range is [${min};${max}]`;

    if (type === 'decimal' && decimal.length > maxFraction) return `Max precision is ${maxFraction}`;

    return null;
};

export const validateField = (validator = {}, fieldValue, required = false) => {
    if (fieldValue === undefined || fieldValue === '' || fieldValue === null) {
        return validator.isRequired || required ? VALIDATION_EMPTY_FIELD : null;
    }

    switch (validator.type) {
        case ValidatorTypes.DECIMAL:
        case ValidatorTypes.TINYINT:
        case ValidatorTypes.SMALLINT:
        case ValidatorTypes.INT:
            return checkRange(validator, fieldValue);
        case ValidatorTypes.TEXT:
            return !validator.length || ('' + fieldValue).length <= validator.length ? null : `Max length is ${validator.length}`;
        case ValidatorTypes.TEMPLATE:
            return !validator.template ? null : validator.template.test(fieldValue) ? null : 'Field is not valid';
        case ValidatorTypes.LATITUDE:
            if (!REGEXP_LAT.test(fieldValue)) {
                return 'Format N1234.1';
            }
            if (fieldValue.length >= MAX_LATITUDE_INTEGER) {
                const floatValue = getFloatCoordinates(fieldValue);
                return checkRange({ ...validator, type: ValidatorTypes.DECIMAL }, floatValue);
            }

            return null;
        case ValidatorTypes.LONGITUDE:
            if (!REGEXP_LONG.test(fieldValue)) {
                return 'Format E12345,1';
            }
            if (fieldValue.length >= MAX_LONGITUDE_INTEGER) {
                const floatValue = getFloatCoordinates(fieldValue);
                return checkRange({ ...validator, type: ValidatorTypes.DECIMAL }, floatValue);
            }

            return null;
        default:
            return null;
    }
};

export const validateState = (validator = {}, fieldValue, required = false) => {
    const errorText = validateField(validator, fieldValue, validator.isRequired || required);
    return errorText ? { errorText } : null;
};

export const checkField = (fields, name, value) => {
    const fieldParams = fields[name];
    if (!fieldParams) {
        return '';
    }
    const { validator, title = '', type, required = true } = fieldParams || {};
    const { min, max, reg } = validator || {};

    if (required && (value === null || value === undefined)) {
        return `Enter the field ${title}`;
    }

    if (value && !checkType(type, value)) {
        return `Field ${title} is not a "${type}"`;
    }

    if (fieldParams.validator) {
        const validationError = validateField(fieldParams.validator, value, fieldParams.required);
        if (validationError) {
            return validationError;
        }
    }

    if (fieldParams.type === ValidatorTypes.LATITUDE || fieldParams.type === ValidatorTypes.LONGITUDE) {
        return '';
    }

    if (min && value && value.length < min) {
        return `Field ${title} must contain at least ${min} symbols`;
    }
    if (max && value && value.length > max) {
        return `Field ${title} must contain no more then ${max} symbols`;
    }

    if (reg && value && !value.match(reg)) {
        return `Field ${title} is incorrect`;
    }

    return '';
};

export const validate = (data, checkField) => {
    return Object.keys(data).reduce((res, key) => {
        const error = checkField(key, data[key]);
        if (error) {
            res[key] = error;
        }

        return res;
    }, {});
};

export const getInitialData = (fields) => {
    return Object.keys(fields).reduce((res, key) => {
        res[key] = undefined;
        return res;
    }, {});
};

export const getValue = (validator = {}, fieldValue) => {
    const type = (validator && validator.type) || 'text';
    let value = fieldValue || value;

    switch (type) {
        case 'decimal':
            value = parseFloat(fieldValue);
            if ((!isNaN(value) && isFinite(value)) || value === 0) {
                return value;
            }

            return undefined;
        case 'tinyint':
        case 'smallint':
        case 'int':
            value = parseInt(fieldValue);
            if ((!isNaN(value) && isFinite(value)) || value === 0) {
                return value;
            }

            return undefined;
    }

    return value || '';
};
