import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
    helperText: {
        position: 'absolute',
        fontSize: '14px',
        lineHeight: '14px',
        top: '54px',
    },
    field: {
        marginTop: 6,
        marginBottom: theme.spacing(2),
    },
}));
