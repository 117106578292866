import React, { memo } from 'react';

import { useStyles } from './useStyles';
import { Typography } from '../../../material-ui/Typography';

export const ErrorContainer = memo(({ error }) => {
    const classes = useStyles();

    return (
        <Typography className={classes.error} color="error">
            {error}
        </Typography>
    );
});
