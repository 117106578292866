import React from 'react';

import { Typography } from '../../material-ui/Typography';

const SplitedCell = (props) => (
    <Typography
        display="inline"
        style={{
            flex: `1 1 ${100 / (props.part || 1)}%`,
            boxSizing: 'border-box',
            paddingRight: '3px',
            color: 'inherit',
        }}
    >
        {props.data}
    </Typography>
);

export { SplitedCell };
