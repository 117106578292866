import React from 'react';

import { checkInputTimeSupport, getStringTimeHHmm } from '../../service/DateTimeUtils';
import { KeyboardTimePicker } from '../material-ui/pickers/KeyboardTimePicker';
import { TimePicker } from '../material-ui/pickers/TimePicker';
import { NativeTimePicker } from '../material-ui/pickers/NativeTimePicker';
import { useTimePickerType } from '../../selectors/routesDeclaration';

import './CustomTimePicker.scss';

export const TimePickerType = {
    NATIVE: 0,
    SPECIAL: 1,
    MATERIAL: 2,
};

const CustomTimePicker = (props) => {
    const { timePickerType: pickerType, ...restProps } = props;
    const defaultTimePickerType = useTimePickerType();
    const isNativeSupport = checkInputTimeSupport();
    const timePickerType = React.useMemo(
        () => (pickerType === null || pickerType === undefined ? defaultTimePickerType : pickerType),
        [pickerType, defaultTimePickerType]
    );

    if (isNativeSupport && timePickerType === TimePickerType.NATIVE) {
        return <NativeTimePicker {...restProps} value={getStringTimeHHmm(props.value)} />;
    }

    if (timePickerType === TimePickerType.SPECIAL) {
        return <KeyboardTimePicker {...restProps} />;
    }

    return <TimePicker {...restProps} />;
};

const CustomTimePickerMemoized = React.memo(CustomTimePicker);

export { CustomTimePickerMemoized as CustomTimePicker };
