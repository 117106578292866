import _ from 'lodash';
const SET_LINEAR_LOADING_STATE = 'SET_LINEAR_LOADING_STATE';
const SET_LOADING_STATE = 'SET_LOADING_STATE';

function setLinearLoadingState(linearLoading) {
    return { type: SET_LINEAR_LOADING_STATE, linearLoading };
}

let linearProgress = {
    _updateLinearProgress: function (visibility, value, max) {
        return (dispatch, getState) => {
            const linearLoading = { ..._.get(getState(), 'linearLoading', {}) };
            linearLoading.visibility = visibility;

            if (value) {
                linearLoading.value = linearLoading.value + value;
            }

            if (max) {
                linearLoading.max = linearLoading.max + max;
            }

            if (linearLoading.value >= linearLoading.max) {
                linearLoading.visibility = false;
                linearLoading.value = 0;
                linearLoading.max = 0;
            }

            dispatch(setLinearLoadingState(linearLoading));
        };
    },
    increaseProgress: function (value = 1) {
        return this._updateLinearProgress(true, value);
    },
    increaseMax: function (diff) {
        return this._updateLinearProgress(true, undefined, diff);
    },
};

function setLoadingState(isLoading, text = '') {
    return { type: SET_LOADING_STATE, isLoading, text };
}

export { SET_LINEAR_LOADING_STATE, SET_LOADING_STATE, setLinearLoadingState, linearProgress, setLoadingState };
