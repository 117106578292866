import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import classnames from 'classnames';

import { Panel } from '../Panel/Panel';
import { Button } from '../material-ui/Button';
import { Fab } from '../material-ui/Fab';
import { List, ListItem, ListItemText } from '../material-ui/List';
import { ReportListItem } from './ReportListItem/ReportListItem';
import { TYPES, useShowModal } from '../../actions/modal';
import { preloadAllReportImageUrl } from '../../actions/reports';
import { useStyles } from './useStyles';
import { getReports } from '../../selectors/reports';
import { Filter } from '../../icons';

const ReportsPage = () => {
    const { reports, showFilter, reportGroupTypes, reportTypes } = useSelector(getReports);
    const showModal = useShowModal();
    const dispatch = useDispatch();
    const classes = useStyles();

    React.useEffect(() => {
        dispatch(preloadAllReportImageUrl());
    }, []);

    const handleReportAdd = React.useCallback(
        () =>
            showModal(TYPES.REPORT, {
                title: 'Add report dialog',
                report: {},
            }),
        []
    );

    const handleFilterClick = React.useCallback(() => {
        showModal(TYPES.REPORT_FILTER, {
            title: 'Add report dialog',
            report: {},
        });
    }, []);

    const handleShowReportsLoadingModal = React.useCallback(() => {
        showModal(TYPES.REPORTS_LOADING, {
            title: 'Select period to download data',
            text: 'The limit for downloading is 30 reports.',
            before: 6,
            after: 0,
        });
    }, []);

    const isDataFiltered = React.useMemo(() => {
        return reportGroupTypes.length !== 0 || reportTypes.length !== 0;
    }, [reportGroupTypes, reportTypes]);

    return (
        <div className={classnames(classes.page, showFilter && classes.wrapper)}>
            <Panel
                title="Reports"
                controls={[
                    <Button key={0} variant="contained" color="primary" size="small" onClick={handleShowReportsLoadingModal}>
                        Download
                    </Button>,
                ]}
                icon={<Filter width="24" height="24" className={classnames(isDataFiltered && classes.filterIconActive)} />}
                onIconClick={handleFilterClick}
            >
                <List>
                    {reports && reports.length > 0 ? (
                        reports.map((item, pos) => {
                            return <ReportListItem key={pos} report={item} />;
                        })
                    ) : (
                        <ListItem>
                            <ListItemText primary="There are not any reports" />
                        </ListItem>
                    )}
                </List>
            </Panel>
            <Fab className={classes.addBtn} color="primary" onClick={handleReportAdd}>
                <AddIcon />
            </Fab>
        </div>
    );
};

const ReportsPageMemoized = React.memo(ReportsPage);

export { ReportsPageMemoized as ReportsPage };
