import React from 'react';
import classnames from 'classnames';

import { ListItem, ListItemText } from '../../../../../components/material-ui/List';
import { Typography } from '../../../../../components/material-ui/Typography';
import { useStyles } from './useStyles';

const NoticeSidebarItem = (props) => {
    const {
        airport: { nameEng, code, codeIata, regionEng, favorites, recent },
    } = props;
    const classes = useStyles();
    const className = classnames(
        classes.listItem,
        classes.notice,
        favorites > 0 && 'notice-airport--favorite',
        recent > 0 && 'notice-airport--recent',
        props.className
    );

    return (
        <ListItem className={className} onClick={() => (location.href = `#${code}`)} button={true}>
            <ListItemText
                inset={false}
                primary={
                    <Typography component="span" className={classes.container}>
                        <span className={classes.icao}>{code}</span>
                        <span className={classes.airport}>{nameEng}</span>
                    </Typography>
                }
                secondary={
                    <Typography component="span" className={classes.container}>
                        <span className={classes.iata}>{codeIata}</span>
                        <span className={classes.region}>{regionEng}</span>
                    </Typography>
                }
            />
        </ListItem>
    );
};

export { NoticeSidebarItem };
