import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    legPanel: {
        flexBasis: '100%',
        margin: '0 0 22px !important',
    },
    summary: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        flex: '1 1 100%',
    },
}));
