import React, { useContext } from 'react';
import { get } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Badge } from '../material-ui/Badge';
import { IconButton } from '../material-ui/IconButton';
import { Toolbar } from '../material-ui/Toolbar';
import { Typography } from '../material-ui/Typography';
import { ReportAdd, Attachment, CheckList, Filter } from '../../icons';
import { CrewTypes } from '../../service/constants';
import { DutyTimeCounter } from '../DutyTimeCounter/DutyTimeCounter';
import { useStyles } from './useStyles';
import { getCurrentFlightInfo, getCurrentLeg, getCurrentTaskSelector } from '../../selectors/screen';
import { getCurrentCheckLists } from '../../selectors/checkList';
import { useIsFilterSet } from '../../selectors/filter';
import { FilterTypes } from '../../reducers/filter';
import { TYPES, useShowModal } from '../../actions/modal';
import { SystemMeasure } from '../SystemMeasure/SystemMeasure';
import { setDimension } from '../../actions/systemOfMeasurement';
import { WeightUnits } from '../WeightUnits/WeightUnits';
import { useMeasureConverter } from '../hooks/useMeasureConverter';
import { useShowSystemMeasureToggler } from './hooks';
import { SidebarContext } from '../PageLayout/ContextProvider';

export const FlightToolbar = React.memo(() => {
    useMeasureConverter();
    const classes = useStyles();
    const dispatch = useDispatch();
    const showModal = useShowModal();
    const currentLeg = useSelector((state) => get(state, 'screen.currentLeg'));
    const currentTask = useSelector(getCurrentTaskSelector);
    const { crewType = 0 } = currentTask || {};
    const leg = useSelector(getCurrentLeg) || {};
    const pathname = useSelector((state) => get(state, 'router.location.pathname'));
    const page = pathname.substring(1);
    const showAddReportBtn = page !== 'reports';
    const showFilterBtn = page == 'passengers';
    const isFilterSet = useIsFilterSet(FilterTypes[page.toUpperCase()]);
    const flight = useSelector(getCurrentFlightInfo);

    const { isCheckList, isCheckListComplited } = useSelector(() => {
        const currentCheckLists = useSelector(getCurrentCheckLists);
        const isCheckList = Boolean(currentCheckLists);
        const isCheckListComplited =
            isCheckList &&
            Object.keys(currentCheckLists).reduce((res, key) => {
                if (!currentCheckLists[key].completed) {
                    res = false;
                }

                return res;
            }, true);

        return {
            isCheckList,
            isCheckListComplited,
        };
    }, shallowEqual);

    const { showSystemMeasureToggler, showWeightUnitsBadge, currentWeightUnits, weightUnitsForBadge } =
        useShowSystemMeasureToggler(pathname);
    const { handleToggleAttachmentSidebar, handleToggleFilterSidebar } = useContext(SidebarContext);

    // This prevents ghost click.
    const handleClick = React.useCallback(
        (event) => {
            event.preventDefault();

            handleToggleAttachmentSidebar(true);
        },
        [handleToggleAttachmentSidebar]
    );

    const handleFilterClick = React.useCallback(
        (event) => {
            event.preventDefault();

            handleToggleFilterSidebar(true);
        },
        [handleToggleFilterSidebar]
    );

    const handleShowReportPopup = React.useCallback(() => {
        showModal(TYPES.REPORT, {
            title: 'Add report dialog',
            report: { planFlightId: currentLeg },
        });
    }, [currentLeg]);

    const handleWeightUnitsChange = React.useCallback((dimension) => {
        dispatch(setDimension({ dimension }));
    }, []);

    const handleClickCheckLists = React.useCallback(() => {
        showModal(TYPES.CHECK_LIST, {
            title: 'CHECK LIST',
        });
    }, [currentLeg, isCheckList]);

    return (
        <div className={classes.toolbarWrapper}>
            <Toolbar className={classes.toolbar}>
                <div className={classes.toolbarTitle}>
                    <Typography className={classes.titleStart} display="inline" color="inherit">
                        Flight{' '}
                    </Typography>
                    <Typography display="inline" color="inherit">
                        {flight.flightNumber}
                    </Typography>
                    <Typography className={classes.titleDate} display="inline" color="inherit">{` (${flight.date})`}</Typography>
                    <Typography className={classes.titleRoute} display="inline" color="inherit">{` ${flight.route}`}</Typography>
                </div>
                {showSystemMeasureToggler && (
                    <SystemMeasure legID={currentLeg} weightUnits={currentWeightUnits} onWeightUnitsChange={handleWeightUnitsChange} />
                )}
                {showWeightUnitsBadge && <WeightUnits weightUnits={weightUnitsForBadge} />}
                {leg ? (
                    <div className={classes.legControls}>
                        <DutyTimeCounter />
                        {showFilterBtn && currentLeg && (
                            <IconButton onClick={handleFilterClick}>
                                <Filter width="24" height="24" className={isFilterSet ? classes.filterIconActive : null} />
                            </IconButton>
                        )}
                        {showAddReportBtn && (
                            <IconButton onClick={handleShowReportPopup} className={classes.icon}>
                                <ReportAdd width="24" height="24" />
                            </IconButton>
                        )}
                        {isCheckList && (
                            <IconButton onClick={handleClickCheckLists} className={classes.icon}>
                                <Badge color="secondary" variant="dot" invisible={isCheckListComplited}>
                                    <CheckList width="24" height="24" />
                                </Badge>
                            </IconButton>
                        )}
                        {crewType === CrewTypes.PCT_CABINSTAFF && currentLeg && (
                            <IconButton onClick={handleClick} className={classes.icon}>
                                <Attachment width="24" height="24" />
                            </IconButton>
                        )}
                    </div>
                ) : (
                    ''
                )}
            </Toolbar>
        </div>
    );
});
