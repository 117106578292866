import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    panel: {
        margin: 0,
        paddingBottom: 5,
    },
    panelWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '100%',
        margin: '0 0 6px !important',
    },
}));
