import React from 'react';
import { useSelector } from 'react-redux';

import { useHideModal } from '../../actions/modal';
import { DialogControls } from '../../components/DialogControls/DialogControls';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../components/material-ui/Dialog';
import { useStyles } from './useStyles';
import { useMobileWidth } from '../../components/hooks/useMobileWidth';
import { FilterTypes } from '../../reducers/filter';
import { useFilter } from '../../selectors/filter';
import { FormControl } from '../../components/material-ui/FormControl';
import { InputLabel } from '../../components/material-ui/InputLabel';
import { Select } from '../../components/material-ui/Select';
import { Input } from '../../components/material-ui/Input';
import { getReportsOptions } from '../../selectors/reports';
import { MenuItem } from '../../components/material-ui/Menu';
import { Checkbox } from '../../components/material-ui/Checkbox';
import { ListItemText } from '../../components/material-ui/List';
import { useSetFilter } from '../../actions/filter';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ReportFilter = React.forwardRef(({ title }, ref) => {
    const classes = useStyles();
    const options = useSelector(getReportsOptions);
    const data = useFilter(FilterTypes.REPORT_FILTER);
    const setFilter = useSetFilter(FilterTypes.REPORT_FILTER);
    const hideModal = useHideModal();
    const { isMobilePortrait } = useMobileWidth();
    const [groups, setGroups] = React.useState([]);
    const [types, setTypes] = React.useState([]);

    React.useEffect(() => {
        setGroups(data.groups);
    }, [data.groups]);

    React.useEffect(() => {
        setTypes(data.types);
    }, [data.types]);

    const handleChangeGroups = React.useCallback((event) => {
        setGroups(event.target.value);
    }, []);

    const handleChangeTypes = React.useCallback((event) => {
        setTypes(event.target.value);
    }, []);

    const groupValues = React.useMemo(
        () =>
            options.groups.reduce((res, item) => {
                res[item.typeId] = item.name;

                return res;
            }, {}),
        [options.groups]
    );

    const typeValues = React.useMemo(
        () =>
            options.types.reduce((res, item) => {
                res[item.typeId] = item.name;

                return res;
            }, {}),
        [options.types]
    );

    const handleClear = React.useCallback(() => {
        setFilter({ groups: [], types: [] });
        hideModal();
    }, []);

    const handleSubmit = React.useCallback(
        (event) => {
            event.preventDefault();
            setFilter({ groups, types });
            hideModal();
        },
        [groups, types]
    );
    const actions = React.useMemo(
        () => [
            { label: 'Clear', onClick: handleClear },
            { label: 'OK', color: 'primary', type: 'submit' },
        ],
        [handleClear]
    );

    return (
        <Dialog ref={ref} open={true} maxWidth="md" fullScreen={isMobilePortrait} classes={{ paper: classes.paper }}>
            <DialogTitle className={classes.title}>{title}</DialogTitle>
            <form className={classes.fields} onSubmit={handleSubmit}>
                <DialogContent className={classes.content}>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="groups">Report group types</InputLabel>
                        <Select
                            value={groups}
                            multiple={true}
                            onChange={handleChangeGroups}
                            input={<Input id="groups" />}
                            MenuProps={MenuProps}
                            renderValue={(selected) => selected.map((id) => groupValues[id]).join(', ')}
                        >
                            {options.groups.map((group) => (
                                <MenuItem key={group.typeId} value={group.typeId}>
                                    <Checkbox checked={groups.indexOf(group.typeId) > -1} />
                                    <ListItemText primary={group.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="types">Report types</InputLabel>
                        <Select
                            value={types}
                            multiple={true}
                            onChange={handleChangeTypes}
                            renderValue={(selected) => selected.map((id) => typeValues[id]).join(', ')}
                            input={<Input id="types" />}
                            MenuProps={MenuProps}
                        >
                            {options.types.map((type) => (
                                <MenuItem key={type.typeId} value={type.typeId}>
                                    <Checkbox checked={types.indexOf(type.typeId) > -1} />
                                    <ListItemText primary={type.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <DialogControls actions={actions} />
                </DialogActions>
            </form>
        </Dialog>
    );
});

const ReportFilterMemoized = React.memo(ReportFilter);

export { ReportFilterMemoized as ReportFilter };
